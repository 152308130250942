import { Input, Component, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';

export enum AppBarItemTypeET {
	Section,
	Spacer,
	Separator
}

@Component({
	selector: 'otris-app-bar-item',
	template: `
		
@switch (itemType) {
			@case (appBarItemTypeET.Section) {
				<ng-template #content>
					<ng-content></ng-content>
				</ng-template>
			}
		}
	`,
	styles: []
})
export class AppBarItemComponent {

	@ViewChild('content') content: TemplateRef<any>;

	@Input() itemType: AppBarItemTypeET;

	@Output() mouseEnter = new EventEmitter();

	@Output() mouseLeave = new EventEmitter();

	@Input() id = "n.a.";

	appBarItemTypeET = AppBarItemTypeET;

	constructor() { }

	raiseMouseEnter(): void {
		this.mouseEnter.emit();
	}

	raiseMouseLeave(): void {
		this.mouseLeave.emit();
	}
}
