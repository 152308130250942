import { Observable } from 'rxjs';
import { IEnumerationItem } from './types';

export abstract class AbstractEnumeration {
	abstract get name(): string;
	abstract get choice(): IEnumerationItem[];
	abstract get valueRaw(): number | string | (number | string)[];
	abstract get value(): IEnumerationItem | undefined | IEnumerationItem[];
	abstract set value(val: IEnumerationItem | undefined | IEnumerationItem[]);
	abstract get rawValueType(): 'string' | 'number';
	abstract getItem(val: number | string): IEnumerationItem | undefined;
	abstract updateItemErgNames(langIndex?: number): Observable<void>;
	abstract getValueErgName(langIndex: number): string | undefined;
	abstract clone(): AbstractEnumeration;
}
