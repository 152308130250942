import { ExpressionOptions, IEventProcessingContext, IFormHandlerService } from "@otris/ng-core-types";
import { ASTToken, IGrammarParser } from "../../services/grammar-parser-factory.service";
import { AssertionExprAction } from "./assertion-expression-actions";

export class ConditionExpressionHandler {

	static evaluateConditionExpression(parser: IGrammarParser, conditionExpr: string,
		ctx: IEventProcessingContext, options?: ExpressionOptions): boolean {
		
		let handler = new ConditionExpressionHandler(parser, conditionExpr, ctx, options);
		return handler.evaluateExpression();
	}

	private static readonly ConditionExprToken = 'ConditionExpr';

	private _astToken: ASTToken;

	private constructor(parser: IGrammarParser, conditionExpr: string,
		private _ctx: IEventProcessingContext, private _options: ExpressionOptions) {
		// todo: prüfen, ob '\n' noch gebraucht wird
		let adaptExpr = (expr: string) => {
			return expr.trim() + '\n';
		}

		this._astToken = parser.parse(adaptExpr(conditionExpr), ConditionExpressionHandler.ConditionExprToken);
		if (!this._astToken) {
			console.warn(`Invalid condition expression '${conditionExpr}'.`)
		}
	}

	private evaluateExpression(): boolean {
		let conditionExprAction = ConditionExprAction.create(this._astToken, this._ctx);
		return conditionExprAction.evaluate(this._options);
	}
}

export class ConditionExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): ConditionExprAction {
		if (astToken.type !== 'ConditionExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new ConditionExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {}

	evaluate(options?: ExpressionOptions): boolean {
		let exprGroupToken = this._astToken.children.find(t => t.type === 'ExpressionGroup'); // todo Konstante
		if (!exprGroupToken) {
			throw new Error('todo');
		}

		let exprGroupAction = ExpressionGroupAction.create(exprGroupToken, this._ctx);
		return exprGroupAction.evaluate(options);
	}
}


export class ExpressionGroupAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): ExpressionGroupAction {
		if (astToken.type !== 'ExpressionGroup') { // todo: Konstante
			throw new Error('todo');
		}
		return new ExpressionGroupAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(options?: ExpressionOptions): boolean {
		
		let result: (string | boolean)[] = [];
		this._astToken.children.forEach(token => {
			switch (token.type) {
				case 'AssertionExpr':
					{
						let action = AssertionExprAction.create(token, this._ctx);
						result.push(action.evaluate(options));
						break;
					}

				case 'BracketExpr':
					{
						let action = BracketExprAction.create(token, this._ctx);
						result.push(action.evaluate(options));
						break;
					}

				case 'LogicalOperator':
					result.push(token.text);
					break;
			}
		});

		let pos = -1
		do {
			pos = result.indexOf('&&');
			if (pos !== -1) {
				let val = <boolean>(result[pos -1] && result[pos + 1]);
				result.splice(pos - 1, 3, val);
			}
		} while (pos !== -1);

		do {
			pos = result.indexOf('||');
			if (pos !== -1) {
				let val = <boolean>(result[pos -1] || result[pos + 1]);
				result.splice(pos - 1, 3, val);
			}
		} while (pos !== -1);

		return <boolean>result[0];
	}
}

export class BracketExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): BracketExprAction {
		if (astToken.type !== 'BracketExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new BracketExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(options?: ExpressionOptions): boolean {
		let exprGroupToken = this._astToken.children.find(t => t.type === 'ExpressionGroup'); // todo Konstante
		if (!exprGroupToken) {
			throw new Error('todo');
		}

		let exprGroupAction = ExpressionGroupAction.create(exprGroupToken, this._ctx);
		return exprGroupAction.evaluate(options);
	}
}
