import { OnInit, OnDestroy, AfterContentInit, Input, ContentChild, ContentChildren, QueryList, AfterViewInit, ChangeDetectorRef, Directive, Output, EventEmitter, HostBinding } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UntypedFormGroup, FormControl, AbstractControl, UntypedFormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IComponent, ICssStyle, IFormStatus } from '@otris/ng-core-types';
import { CustomPDObject, UITemplateDirective } from '@otris/ng-core-shared';
import { UIRootContainerSpec } from '../../model/pd-layout';
import { PDFormHeaderTemplateDirective } from '../../directives/pd-form-header-template.directive';
import { FormHandlerService } from '../../services/form-handler.service';

@Directive()
export abstract class PDRootContainerComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

	@ContentChild(PDFormHeaderTemplateDirective) headerTemplateDirective: PDFormHeaderTemplateDirective;

	@ContentChildren(UITemplateDirective) uiTemplates: QueryList<UITemplateDirective>;

	@Input() pdObject: CustomPDObject;

	@Output() viewInitialized = new EventEmitter<void>();

	@HostBinding('id') get cssId(): string {
		return this._id;
	}
	private _id: string;

	@Input() set id(newId: string){
		this._id = newId;
	}

	theForm: UntypedFormGroup;

	protected abstract get rootContainerSpec(): UIRootContainerSpec;


	get showDefaultHeader(): boolean {
		return this.rootContainerSpec && this.rootContainerSpec.showHeader && !this.headerTemplateDirective;
	}

	// get showBackground(): boolean {
	// 	return this.rootContainerSpec && this.rootContainerSpec.showBackground;
	// }

	get formValue(): any {
		//return this.theForm.value;
		return this.theForm.getRawValue(); /// auch die Werte von disabled controls zurückgeben
	}

	get formStatus(): IFormStatus {
		return this.formHandler.formStatus;
	}

	abstract get mainPanel(): IComponent;

	protected subscription: Subscription;

	constructor(protected router: Router, protected route: ActivatedRoute, protected changeDetector: ChangeDetectorRef, protected formHandler: FormHandlerService) {
	}

	ngOnInit() {
		this.theForm = new UntypedFormGroup({});
		this.formHandler.registerForm(this.theForm);
	}

	ngAfterContentInit() {
		if (this.uiTemplates) {
			this.uiTemplates.forEach(t => this.formHandler.registerUITemplate(t.templateName, t.template));
		}
	}

	ngAfterViewInit() {
		this.viewInitialized.next();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	markAsTouched(childsToo: boolean): void {
		//this.theForm.markAsTouched();
		this.markAsTouchedImpl(this.theForm, childsToo);
		this.formHandler.emitFormStatusChangesEvent();
	}

	markAsUntouched(): void {
		this.theForm.markAsUntouched();
		this.formHandler.emitFormStatusChangesEvent();
		//this.markAsUntouchedImpl(this.theForm, childsToo);
	}

	markAsDirty(childsToo: boolean): void {
		//this.theForm.markAsDirty();
		this.markAsDirtyImpl(this.theForm, childsToo);
		this.formHandler.emitFormStatusChangesEvent();
	}

	markAsPristine(): void {
		this.theForm.markAsPristine();
		this.formHandler.emitFormStatusChangesEvent();
		//this.markAsTouchImpl(this.theForm, childsToo);
	}

	private markAsTouchedImpl(ctrl: AbstractControl, childsToo: boolean): void {
		ctrl.markAsTouched({ onlySelf: true });
		if (childsToo) {
			if (ctrl instanceof UntypedFormGroup) {
				Object.keys((ctrl as UntypedFormGroup).controls).forEach(key => {
					this.markAsTouchedImpl((ctrl as UntypedFormGroup).controls[key], true);
				});
			}
			else if (ctrl instanceof UntypedFormArray) {
				Object.keys((ctrl as UntypedFormArray).controls).forEach(key => {
					this.markAsTouchedImpl((ctrl as UntypedFormArray).controls[key], true);
				});
			}
		}
	}

	private markAsDirtyImpl(ctrl: AbstractControl, childsToo: boolean): void {
		ctrl.markAsDirty({ onlySelf: true });
		if (childsToo) {
			if (ctrl instanceof UntypedFormGroup) {
				Object.keys((ctrl as UntypedFormGroup).controls).forEach(key => {
					this.markAsDirtyImpl((ctrl as UntypedFormGroup).controls[key], true);
				});
			}
			else if (ctrl instanceof UntypedFormArray) {
				Object.keys((ctrl as UntypedFormArray).controls).forEach(key => {
					this.markAsDirtyImpl((ctrl as UntypedFormArray).controls[key], true);
				});
			}
		}
	}


	get formStyle() {
		let style = <ICssStyle>new Object();
		if (this.rootContainerSpec) {
			if (this.rootContainerSpec.minWidth) {
				style['min-width'] = this.rootContainerSpec.minWidth;
			}
			if (this.rootContainerSpec.maxWidth) {
				style['max-width'] = this.rootContainerSpec.maxWidth;
			}
			if (this.rootContainerSpec.minHeight) {
				style['min-height'] = this.rootContainerSpec.minHeight;
			}
			if (this.rootContainerSpec.maxHeight) {
				style['max-height'] = this.rootContainerSpec.maxWidth;
			}
			if (this.rootContainerSpec.width) {
				style['width'] = this.rootContainerSpec.width;
			}
			if (this.rootContainerSpec.minHeight) {
				style['min-height'] = this.rootContainerSpec.minHeight;
			}
			if (this.rootContainerSpec.maxHeight) {
				style['max-height'] = this.rootContainerSpec.maxHeight;
			}
			if (this.rootContainerSpec.height) {
				style['height'] = this.rootContainerSpec.height;
			}
		}
		return style;
	}

	// TODO: Fallback ComponentId
	public getId(): string {
		if (this._id) {
			return this._id;
		}
		this._id = "pdRootContainer.";
		return this._id;
	}
}
