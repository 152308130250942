import { Component,
	OnInit,
	Input,
	ViewChild,
	ContentChild,
	HostBinding,
	TemplateRef,
	Optional,
	Self,
	ElementRef,
	inject
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from "rxjs";

import {
	UIGroupboxSpec,
	FormHandlerService,
	EventProcessingContextManagerService,
	ToNRelationItemContainerDirective,
	ToNRelationItemDirective,
	PDLikeComponent,
	ComponentGroupHeaderDirective,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET,
	IGroupboxComponent,
	IFormStatus,
	IComponentUIState,
	IPanelComponent,
	IToNRelationItemContainer,
	IRelationContext,
	ComponentGroupHeaderContext
} from '@otris/ng-core-types';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'otris-pd-groupbox',
	template: `
		@if (hasHeaderTemplate) {
			<div class="container">
				<div>
					<ng-container *ngTemplateOutlet="groupboxHeaderTemplate;context:headerContext"/>
				</div>
				<ng-container *ngTemplateOutlet="pdPanel"/>
			</div>
		} @else {
			<fieldset class="default-container">
				<legend>{{header$ | async}}</legend>
				<ng-container *ngTemplateOutlet="pdPanel"/>
			</fieldset>
		}

		<ng-template #default>
			<fieldset class="default-container">
				<legend>{{header$ | async}}</legend>
				<ng-container *ngTemplateOutlet="pdPanel"/>
			</fieldset>
		</ng-template>

		<ng-template #pdPanel>
			@if (toNRelationItemContainer && toNRelationItemContainer.relationItemCount == 0) {
				<ng-container *ngTemplateOutlet="noRelationObjectTemplate"/>
			} @else {
				@if (groupboxSpec.content) {
					<otris-pd-panel #panel class="panel"
						[uiItemSpec]="groupboxSpec.content"
						[pdObject]="pdObject"
						[formGroup]="getFormGroupForChildren()"
						[relationContext]="getRelationContextForChildren()"
					/>
				}
			}
		</ng-template>
	`,
	styles: [`
		:host {
			display: flex;
			flex-direction: column; /* IE11 mag das nicht. Ursprünglich für das word-break */ /* Wieder rein gemacht da kein IE11 support mehr */
		}

		.container {
			/* height: 100%; 100p fix */
			display: flex;
			flex: 1 1 100%; /* 100p fix + IE11 text wrap fix */
			flex-direction: column;
		}
		.default-container {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;

			margin-bottom: 1em;
			/* margin-top: 0.5em; */
		}
		.panel {
			display: flex; /* 100p fix */
			flex-direction: column;
			flex: 1 1 auto;
			/* height: 100%; 100p fix */
		}

		/*.header {
			padding: 0.5em;
			margin-bottom: 0.5em;
			background-color: darkgrey;
		}*/
	`],
	/*providers: [
		{ provide: UIAbstractComponent, useExisting: forwardRef(() => PDGroupboxComponent)}
	 ]*/
})
export class PDGroupboxComponent extends PDLikeComponent implements IGroupboxComponent {

	@ViewChild('panel') set panelComponent(comp: IPanelComponent) {
		if (comp != this._panelComponent) {
			this._panelComponent = comp;
			/*if (this._uiStateProviderReg) {
				this._uiStateProviderReg.cancel();
				this._uiStateProviderReg = undefined;
			}*/
			if (this._panelComponent) {
				this.createChildComponentUIStateProviders([this._panelComponent]);
			}
		}
	}

	get panelComponent(): IPanelComponent {
		return this._panelComponent;
	}

	private _panelComponent: IPanelComponent;

	@ContentChild(ComponentGroupHeaderDirective) componentGroupHeaderDirective: ComponentGroupHeaderDirective;

	private _groupboxHeaderTemplate: TemplateRef<any>;

	get groupboxHeaderTemplate(): TemplateRef<any> {
		return this.componentGroupHeaderDirective ? this.componentGroupHeaderDirective.template : this._groupboxHeaderTemplate;
	}

	get hasHeaderTemplate(): boolean {
		return !!this.groupboxSpec.headerTemplateId;
	}

	private _noRelationObjectTemplate: TemplateRef<any>;

	get noRelationObjectTemplate(): TemplateRef<any> {
		return this._noRelationObjectTemplate;
	}

	@HostBinding('class') cssClasses = 'otris-pd-groupbox';

	get propertyName(): string | undefined {
		return undefined;
	}

	get propertyRelationPath(): string | undefined {
		return undefined;
	}

	get control(): AbstractControl | undefined {
		return undefined
	}

	get toNRelationItemContainer(): IToNRelationItemContainer | undefined {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.toNRelationItemContainer : undefined;
	}

	get isContainerComponent(): boolean {
		return true;
	}

	get isRelationItemHostComponent(): boolean {
		return !!this.otrisToNRelationItemContainer || !!this.otrisToNRelationItem;
	}

	private _localizationService = inject(LocalizationServiceToken);

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Optional() @Self() public otrisToNRelationItemContainer: ToNRelationItemContainerDirective,
		@Optional() @Self() public otrisToNRelationItem: ToNRelationItemDirective,
		private _hostElement: ElementRef) {
		super(router, route, formHandler, eventProcessingContextManagerService);

		this.addSubscription(this.formHandler.uiTemplateRegistered$.subscribe(id => {
			if (id === this.groupboxSpec.headerTemplateId) {
				this.updateHeaderTemplate();
			}
			else if (id === this.groupboxSpec.noRelationObjectTemplateId) {
				this.updateNoRelationObjectTemplate();
			}
		}));
	}

	getFormControlStatus(): IFormStatus {
		if (this.panelComponent) {
			return this.panelComponent.getFormControlStatus();
		}
		return <IFormStatus>{ pristine: true, touched: false, valid: true };
	}

	getFormGroupForChildren(): UntypedFormGroup {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.relationItemFormGroup :
			(this.otrisToNRelationItem ? this.otrisToNRelationItem.relationItemFormGroup : this.formGroup);
	}

	getRelationContextForChildren(): IRelationContext {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.getRelationContext() :
			(this.otrisToNRelationItem ? this.otrisToNRelationItem.getRelationContext() : this.relationContext);
	}

	get groupboxSpec(): UIGroupboxSpec {
		if (!(this.uiItemSpec instanceof UIGroupboxSpec)) {
			throw Error('No UIGroupboxSpec assigned.');
		}
		return <UIGroupboxSpec>this.uiItemSpec;
	}

	get header$(): Observable<string> {
		if (this.groupboxSpec) {
			if (this.groupboxSpec.headerId) {
				return this._localizationService.getStringFromId(this.groupboxSpec.headerId);
			}
			if (this.groupboxSpec.headerIdHandler) {
				return this._localizationService.getStringFromId(this.groupboxSpec.headerIdHandler(this, this.createEventProcessingContext()));
			}
			return of(this._localizationService.getFromErgNameData(this.groupboxSpec.header));
		}
		return of('No title text');
	}

	get headerContext(): ComponentGroupHeaderContext {
		return {
			headerLabel$: this.header$,
			componentGroup: {
				toNRelationItemContainer: this.toNRelationItemContainer,
				rootComponent: this
			},
			customData: this._customHeaderData
		}
	}

	private _customHeaderData = {};

	ngOnInit() {
		super.ngOnInit();
		/*if (this.groupboxSpec) {
			if (this.groupboxSpec.headerId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(this.groupboxSpec.headerId).subscribe(res => this._header = res);
			}
			else {
				this._header = this.groupboxSpec.header;
			}
		}*/
		this.onLanguageChanged();
		this.updateHeaderTemplate();
		this.updateNoRelationObjectTemplate();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		//if (this.formHandler.scrollIntoViewOnCreateEnabled) {
		//	this._hostElement.nativeElement.scrollIntoView();
		//}
	}

	ngAfterContentInit() {
		super.ngAfterContentInit();
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (!onlySelf && this.panelComponent) {
			this.panelComponent.updateUIState(false);
		}
	}

	scrollIntoView(): void {
		this._hostElement.nativeElement.scrollIntoView();
	};

	protected createControl(): AbstractControl | undefined {
		return undefined;
	}

	private updateHeaderTemplate() {
		if (this.groupboxSpec.headerTemplateId) {
			this._groupboxHeaderTemplate = this.formHandler.getUITemplate(this.groupboxSpec.headerTemplateId);
		}
	}

	private updateNoRelationObjectTemplate() {
		if (this.groupboxSpec.noRelationObjectTemplateId) {
			this._noRelationObjectTemplate = this.formHandler.getUITemplate(this.groupboxSpec.noRelationObjectTemplateId);
		}
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.GroupBox;
	}

	/*protected onDisabledChanged(): void {
		super.onDisabledChanged();
		if (this.panelComponent) {
			this.panelComponent.systemDisabled = this.isDisabled;
		}
	}*/

	/*protected onVisibleChanged(): void {
		super.onVisibleChanged();
		if (this.panelComponent) {
			this.panelComponent.systemVisible = this.isVisible;
		}
	}*/

	/*protected onReadonlyChanged(): void {
		super.onReadonlyChanged();
		if (this.panelComponent) {
			this.panelComponent.systemReadonly = this.isReadonly;
		}
	}*/

	reset(): void {
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}

	/*registerUIStateProvider(provider: UIStateProvider, providerHost?: IComponent): IUIStateProviderRegistration {
		let registration = super.registerUIStateProvider(provider, providerHost);
		if (this.panelComponent) {
			let ctx = this.createEventProcessingContext();
			let childReg = this.panelComponent.registerUIStateProvider((src) => {
				let state = provider(src, ctx);
				return {
					readonly: state.readonly ? true : undefined,
					disabled: state.disabled ? true : undefined
					// ...
					// todo
				}
			}, this);
			registration.addChildRegistration(childReg);
		}
		return registration;
	}*/
}
