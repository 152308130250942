import { Injectable } from "@angular/core";
import { DefaultPDObjectMeta } from "../model/default-pd-object-meta";
import { IPDObjectMeta } from "../model/pd-object-meta";

@Injectable()
export abstract class IMetaObjectFactory {
	abstract registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void;

	abstract createOrGetMetaObject(className: string, id?: string): IPDObjectMeta | undefined;
}


@Injectable()
export class MetaObjectService {

	private _metaObjects = new Map<string, IPDObjectMeta | [string, IPDObjectMeta][]>();

	constructor(private _metaObjectFactory: IMetaObjectFactory) {}

	registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void {
		this._metaObjectFactory.registerMetaObject(className, id, metaObj);
		this.storeNewMetaObject(metaObj, className, id);
	}

	getMetaObject(className: string, id?: string): IPDObjectMeta {
		let currentMetaObj = this._metaObjects.get(className);
		if (currentMetaObj) {
			//let metaObj = this._metaObjects.get(className);
			if (Array.isArray(currentMetaObj)) {
				if (!id) {
					throw new Error('No id for meta object specified');
				}
				let metaObjForId = currentMetaObj.find(m => m[0] === id);
				if (metaObjForId) {
					return metaObjForId[1];
				}
			}
			else {
				return currentMetaObj;
			}	
		}

		let newMetaObj = this._metaObjectFactory.createOrGetMetaObject(className, id);
		if (newMetaObj) {
			this.storeNewMetaObject(newMetaObj, className, id);
			return newMetaObj;
		}

		return DefaultPDObjectMeta.instance;
	}

	hasMetaObject(className: string, id?: string): boolean {
		let currentMetaObj = this._metaObjects.get(className);
		if (currentMetaObj) {
			if (Array.isArray(currentMetaObj)) {
				if (!id) {
					throw new Error('No id for meta object specified');
				}
				let metaObjForId = currentMetaObj.find(m => m[0] === id);
				if (metaObjForId) {
					return true;
				}
			}
			else {
				return true;
			}	
		}
		return false;
	}

	private storeNewMetaObject(newMetaObj: IPDObjectMeta, className: string, id: string): void {		
		if (id) {
			let currentMetaObj = this._metaObjects.get(className);
			if (currentMetaObj) {
				if (!Array.isArray(currentMetaObj)) {
					throw new Error('Invalid meta object type.')
				}
				let oldMetaObject = currentMetaObj.find(m => m[0] === id);
				if (oldMetaObject) {
					throw new Error(`Meta object for class ${className} with id ${id} already stored.`)
				}
				currentMetaObj.push([id, newMetaObj]);
			}
			else {
				this._metaObjects.set(className, [[id, newMetaObj]]); 
			}
		}
		else {
			this._metaObjects.set(className, newMetaObj); 
		}
	}
}


@Injectable({ providedIn: 'root', useClass: MetaObjectService})
export abstract class IMetaObjectService {

	abstract hasMetaObject(className: string, id?: string): boolean;

	abstract getMetaObject(className: string, id?: string): IPDObjectMeta;

	abstract registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void;
}
