import { AfterViewInit, Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomPDObject, PDObject } from "@otris/ng-core-shared";
import { IComponent, IRelationContext, IToNRelationItemSpec } from '@otris/ng-core-types'
import { FormHandlerService } from "../services/form-handler.service";

@Directive({
	selector: '[otrisToNRelationItem]'
})
export class ToNRelationItemDirective implements OnInit, AfterViewInit, OnDestroy {

	@Input('otrisToNRelationItem') relationSpec: IToNRelationItemSpec;

	@Input('relItemHostComponent') hostComponent: IComponent;

	@Input('relItemFormGroup') formGroup: UntypedFormGroup;

	@Input('relItemRelationContext') relationContext: IRelationContext;

	//@Input('relItemIndex') relationItemIndex = -1;

	@Input('relItemPDObject') relPDObject: PDObject;

	private get hostId(): string | undefined {
		let id = this.relationContext ? this.relationContext.path + '.' : '';
		id += this.relationSpec.relationProperty + '.' + this.relationSpec.locator.value.toString();
		return id;
	}

	private get relationFormArray(): UntypedFormArray | undefined {
		return this.formGroup.get(this.relationSpec.relationProperty) as UntypedFormArray;
	}

	private _relationItemFormGroup: UntypedFormGroup;

	get relationItemFormGroup(): UntypedFormGroup {
		return this._relationItemFormGroup;
	}

	constructor(private formHandler: FormHandlerService) {}

	ngOnInit(): void {
		//if (this.relationObjectExisting) {
		//setTimeout(() => {
			this.createControl();
			this.formHandler.registerComponent(this.hostComponent, this.hostId);
		//});
		//}
	}

	ngAfterViewInit(): void {
		/*if (this.relationObjectExisting && this.relationSpec.relationObjectCreatedHandler) {
			setTimeout(() => {
				this.relationSpec.relationObjectCreatedHandler(this.toNRelationItemContainer, this.pdObject, this.hostComponent.createEventProcessingContext());
			});
		}*/
	}

	ngOnDestroy(): void {
		let formArray = this.relationFormArray;
		let index = formArray.controls.findIndex(c => c === this._relationItemFormGroup)
		formArray.removeAt(index);
		this.formHandler.unregisterComponent(this.hostComponent, this.hostId);
	}

	getRelationContext(): IRelationContext  {
		let relationPath: string;
		if (this.relationContext) {
			if (this.relationContext.isMultiple) {
				relationPath = this.relationContext.path + '.' + this.relationContext.index + '.' + this.relationSpec.relationProperty;
			} else {
				relationPath =  this.relationContext.path + '.' + this.relationSpec.relationProperty
			}
		} else {
			relationPath = this.relationSpec.relationProperty;
		}
		return { source: this.relPDObject, path: relationPath, isMultiple: true, index: this.relationSpec.locator.value.toString() };
	}

	private createControl(): void {
		let formArray = this.relationFormArray;
		if (!formArray) {
			formArray = new UntypedFormArray([]);
			this.formGroup.addControl(this.relationSpec.relationProperty, formArray);
		}
		this._relationItemFormGroup = new UntypedFormGroup({
			_className: new UntypedFormControl(this.relPDObject.metaData.className),
			_objectId: new UntypedFormControl(this.relPDObject.objectId),
			_metaData: new UntypedFormControl(this.relPDObject.metaData),
			_isNew: new UntypedFormControl(this.relPDObject instanceof CustomPDObject ? this.relPDObject.isNew : false)
		});
		formArray.push(this._relationItemFormGroup);
	}
}