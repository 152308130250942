import { IEventProcessingContext } from "@otris/ng-core-types";
import { ASTToken } from "../../services/grammar-parser-factory.service";

export class EventProcessingContextAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): EventProcessingContextAction {
		if (astToken.type !== 'EventProcessingContextExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new EventProcessingContextAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): any {
		let moduleStateServiceExprToken = this._astToken.children.find(t => t.type === 'ModuleStateServiceExpr'); // todo Konstante
		if (moduleStateServiceExprToken) {
			let moduleStateServiceExprAction = ModuleStateServiceExprAction.create(moduleStateServiceExprToken, this._ctx);
			return moduleStateServiceExprAction.evaluate();
		}
		throw new Error('todo');
	}
}

export class ModuleStateServiceExprAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): ModuleStateServiceExprAction {
		if (astToken.type !== 'ModuleStateServiceExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new ModuleStateServiceExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): any {
		let moduleStateServiceGetQueryParamToken = this._astToken.children.find(t => t.type === 'ModuleStateServiceGetQueryParam'); // todo Konstante
		if (moduleStateServiceGetQueryParamToken) {
			let moduleStateServiceGetQueryParamAction = ModuleStateServiceGetQueryParamAction.create(moduleStateServiceGetQueryParamToken, this._ctx);
			return moduleStateServiceGetQueryParamAction.evaluate();
		}
		throw new Error('todo');
	}
}

export class ModuleStateServiceGetQueryParamAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): ModuleStateServiceGetQueryParamAction {
		if (astToken.type !== 'ModuleStateServiceGetQueryParam') { // todo: Konstante
			throw new Error('todo');
		}
		return new ModuleStateServiceGetQueryParamAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): string | undefined {
		let stringToken = this._astToken.children.find(t => t.type === 'String'); // todo Konstante
		if (!stringToken) {
			throw new Error('todo'); 
		}
		let paramName = stringToken.text;
		return this._ctx.moduleStateService.getQueryParameter(paramName);

	}
}