import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { ILocalizationService } from '@otris/ng-core-types';
import { LocalizationServiceToken } from '@otris/ng-core';

@Component({
	selector: 'otris-kendo-localization-handler',
	template: ``,
	styles: []
})
export class KendoLocalizationHandlerComponent implements OnInit, OnDestroy {

	private _subscriptions: Subscription

	constructor(@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		public intlService: IntlService) { }

	ngOnInit() {
		this._subscriptions = this.localizationService.changeHandler.subscribe(
			lang => {
				if (lang) {
					(<CldrIntlService>this.intlService).localeId = lang.shortName;
				}
			}
		);
	}

	ngOnDestroy() {
		if (this._subscriptions) {
			this._subscriptions.unsubscribe();
		}
	}
}


