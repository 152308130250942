import { Injectable } from '@angular/core';
import { ICustomEventProcessingContext } from '@otris/ng-core-types';


// {providedIn: 'root', useClass: KendoFileSystemImplementationService }
@Injectable()
export class EventProcessingContextManagerService {

	private _customContext: ICustomEventProcessingContext; 

	get customContext(): ICustomEventProcessingContext {
		return this._customContext;
	}

	setCustomContext(ctx: ICustomEventProcessingContext): void {
		if (this._customContext) {
			throw new Error('Custom context of EventProcessingContextManagerService already set.');
		}
		this._customContext = ctx;
	}
}