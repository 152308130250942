import { TemplateRef } from "@angular/core";

import { ILayoutSpec, IPDItemSpec, IWidgetInfo } from "./i-pd-layout";
import { IEventProcessingContext } from "./i-event-processing-context";
import { IPDObject } from './i-pd-object';
import { IComponent } from "./i-component";
import { IComponentUIState } from "./i-component-types";

export enum VisibilityET {
	Visible,
	Hidden,
	Collapsed
}

export enum LabelPositionET {
	Top,
	Left,
	Right
}

export enum SelectionModeET {
	Single,
	Multiple
}

// JSON-Schema: kein Schema - Einträge beim entsprechenden Attribut:
// { "enum": ["String", "StringArray", "Boolean", "Integer", "Double", "Date", "Time", "DateTime", "Structure", "Enum", "Document", "DocumentArray", "RelationTo1", "RelationToN"] },
// Noch keine andere Lösung gefunden - $ref ist nur für ein schema da
export enum TypeET {
	String = 'String',
	StringArray = 'StringArray',
	Boolean = 'Boolean',
	Integer = 'Integer',
	Double = 'Double',
	Date = 'Date',
	Time = 'Time',
	DateTime = 'DateTime',
	Structure = 'Structure',
	Enum = 'Enum',
	EnumItemArray = 'EnumItemArray',
	Document = 'Document',
	DocumentArray = 'DocumentArray',
	RelationTo1 = 'RelationTo1',
	RelationToN = 'RelationToN',
	ErgName = 'ErgName',
	Object = 'Object'
}

export enum LanguageCodeET {
	de = 'de',
	en = 'en'
}

export interface ICssStyle {
	[key: string]: any
}

export interface IPDListColumnInfo {
	cellTemplate?: TemplateRef<any>;
	field?: string;
	header?: ErgNameData | string;
	headerId?: string;
	shortDescription?: ErgNameData | string;
	shortDescriptionId?: string;
	/**
	 * Hat der header eine Kurzbeschreibung?
	 * Default: true
	 */
	hasShortDescription?: boolean;
	width?: number;
	type?: TypeET;
	resizable?: boolean;
	editable?: boolean;
	sticky?: boolean;
	hidden?: boolean;
	fullTextSearchRelevant?: boolean;
	style?: ICssStyle;
	editWidgetInfo?: IWidgetInfo; // todo: prüfen!
	objectInfo?: string;
	objectInfoProvider?: (obj: IPDObject, ctx: IEventProcessingContext) => string;
	updateUIStateHandler?: (source: IComponent, currentState: IComponentUIState, ctx: IEventProcessingContext) => IComponentUIState;
}

export type CustomColumnEditModeSpec = {
	layout: ILayoutSpec;
	itemSpecs: IPDItemSpec[];
}

export interface ICustomColumnInfo extends IPDListColumnInfo {
	isCustomColumnInfo: true;
	editModeSpec?: CustomColumnEditModeSpec;
	cellValueProvider: (rowObj: IPDObject, ctx: IEventProcessingContext) => string;
}

export interface IUIDrawerItem {
	id: string;
	icon?: string;
	text?: string; // todo: ErgName
	textId?: string;
	shortDescription?: ErgNameData;
	selected?: boolean;
	hidden?: boolean;
}

// JSON-Schema: schemas/erg-name-schema.json
export type ErgName = [LanguageCodeET, string][];

export type ErgNameData = {
	de: string;
	en: string;
}

export type DynamicLocalizationData = {
	id: string;
	translations: {
		condition: string;
		translation: ErgNameData;
	}[];
}

export interface IFormStatus {
	valid: boolean;
	pristine: boolean;
	touched: boolean;
}

export type EnumerationItemData = {
	enumConst: string,
	ergName: ErgNameData,
	active: boolean
}

export type EnumerationData = {
	name: string,
	items: EnumerationItemData[],
	isMulti: boolean
}

// Lösungsvorschlag HTML -> Pipe
export interface IEnumerationItem {
	enumConst: number | string;
	ergName: string;
	active: boolean;
}

export interface IPDDocument {
	fileName: string;
	data: string;
	size: number;
}

