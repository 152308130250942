import { Component, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { PDLikeComponent, UIRadioButtonGroupSpec, FormHandlerService, EventProcessingContextManagerService, RadioButtonGroupWidgetInfo } from '@otris/ng-core';
import {
	ComponentTypeET, IRadioButtonComponent, IRadioButtonGroupComponent, IFormStatus, IEnumerationItem, IComponent, IComponentUIState, IPanelComponent, UIStateProvider, IUIStateProviderRegistration, RadioButtonGroupComponentEventName
} from '@otris/ng-core-types';
import { PDRadioButtonGroupService } from '../../services/pd-radio-button-group.service';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'otris-pd-radio-button-group',
	template: `
		<label class="label k-form-field" id="label-radio-btn-group" for="">
			@if (isLabelVisible) {
				<div class="label-container" [style.white-space]="wrapLabel ? 'normal' : 'nowrap'">
					<span>{{label}}</span>
					@if (!isDisabled && hasShortDescription) {
						<otris-pd-short-description
							style="margin-left: 0.5em;"
							[highlighted]="mouseIsOver"
							[pdObject]="pdObject"
							[pdComponent]="this"
						/>
					}
					@if (!isDisabled && relatedFormControl && hasErrors) {
						<otris-pd-error-indicator 
							style="margin-left: 0.5em;"
							[relatedFormControl]="relatedFormControl"
							[propertyName]="propertyName"
							[pdObject]="pdObject"
						/>
					}
				</div>
			}
			@if (radioButtonGroupSpec.content) {
				<div 
					class="input-container" 
					for="label-radio-btn-group" 
					[style.display]="isReadonly ? 'none' : undefined"
				>
					<otris-pd-panel 
						#panel
						[uiItemSpec]="radioButtonGroupSpec.content"
						[formGroup]="formGroup"
						[pdObject]="relationBaseObject ? relationBaseObject : pdObject"
						[relationContext]="relationContext"
						[idPostfix]="'radiobuttonGroup'"
					/>
			</div>
			}
			@if (isReadonly) {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</label>
	`,
	styles: [`
		.label {
			margin-top: 0;
		}
		.label-container {
			display: flex;

			margin-bottom: 0.5em;
			/*white-space: nowrap;*/
		}
		.input-container {
			margin: 0.5em;
		}
	`],
	providers: [
		PDRadioButtonGroupService
	]
})
// ToDo Klee: Warum ist das nicht von PDComponent abgeleitet?
// Antwort: Da die dazugehörige UIItemSpec eine UIContainerSpec sein muss, die wiederum nicht von PDItemSpec abgeleitet ist. Eine PDComponent braucht aber eine PDItemSpec!
export class PDRadioButtonGroupComponent extends PDLikeComponent implements IRadioButtonGroupComponent {

	// richtig ?
	/*@HostListener("click", ["$event"])
	public onClick(event: any): void
	{
		 event.stopPropagation();
	}*/

	//@ViewChild('panel') panelComponent: IPanelComponent;

	@ViewChild('panel') set panelComponent(comp: IPanelComponent) {
		if (comp != this._panelComponent) {
			this._panelComponent = comp;
			/*if (this._uiStateProviderReg) {
				this._uiStateProviderReg.cancel();
				this._uiStateProviderReg = undefined;
			}*/
			if (this._panelComponent) {
				this.createChildComponentUIStateProviders([this._panelComponent]);
			}
		}
	}

	get panelComponent(): IPanelComponent {
		return this._panelComponent;
	}

	private _panelComponent: IPanelComponent;

	//private _uiStateProviderReg: IUIStateProviderRegistration;

	isLabelVisible: boolean = true; // todo

	get radioButtonGroupSpec(): UIRadioButtonGroupSpec {
		if (!(this.uiItemSpec instanceof UIRadioButtonGroupSpec)) {
			throw Error('No UIRadioButtonGroupSpec assigned.');
		}
		return <UIRadioButtonGroupSpec>this.uiItemSpec;
	}

	get propertyName(): string | undefined {
		return this.radioButtonGroupSpec.property;
	}

	protected get propertyRelationPath(): string | undefined {
		return this.radioButtonGroupSpec.to1RelationItemSpec?.relationProperty;
	}

	get wrapLabel(): boolean {
		return this.radioButtonGroupSpec.wrapLabel;
	}

	get relatedFormControl(): AbstractControl | undefined {
		if (this.radioButtonGroupService.radioButtons.length > 0) {
			return this.radioButtonGroupService.radioButtons[0].control;
		}
		return undefined;
	}

	get control(): AbstractControl | undefined {
		return undefined;
	}

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	private get radioButtonGroupWidgetInfo(): RadioButtonGroupWidgetInfo | undefined {
		if (this.uiItemSpec.widgetInfo instanceof RadioButtonGroupWidgetInfo) {
			return <RadioButtonGroupWidgetInfo>this.uiItemSpec.widgetInfo;
		}
		return undefined;
	}

	private _selectionChangedSubject$: BehaviorSubject<IRadioButtonComponent | undefined> = new BehaviorSubject(undefined);

	private _selectionChanged$: Observable<IRadioButtonComponent | undefined>;

	private _selectionChangedUIStateSubject$: BehaviorSubject<IRadioButtonComponent | undefined> = new BehaviorSubject(undefined);

	get isContainerComponent(): boolean {
		return true;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		private radioButtonGroupService: PDRadioButtonGroupService,
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();

		let wi = this.radioButtonGroupWidgetInfo;
		this.addSubscription(this.radioButtonGroupService.selectionChanged$.subscribe(val => {
			this._selection = val;
			if (this.pdObject && this.propertyName) {
				this.pdObject.pdObjectRaw[this.propertyName] = val ? val.value : undefined; // Neu
			}
			this._selectionChangedSubject$.next(this._selection);
			if (wi?.selectionChangedHandler) {
				wi.selectionChangedHandler(this, this._selection, this.createEventProcessingContext());
			}
			this._selectionChangedUIStateSubject$.next(this._selection);
		}));
		this.radioButtonGroupService.groupName = this.id;

		if (this.uiItemSpec instanceof UIRadioButtonGroupSpec) {
			let rbGroupSpec = this.uiItemSpec as UIRadioButtonGroupSpec;
			if (rbGroupSpec.label && !this.customLabel) {
				this.customLabel = rbGroupSpec.label;
			}
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.panelComponent) {
			stat = this.panelComponent.getFormControlStatus();
		}
		return stat;
	}

	protected isPDContainer(): boolean {
		return false;
	}

	protected createControl(): AbstractControl | undefined {
		return undefined;
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged()
		if (!this.pdObject) {
			return;
		}
		this.setSelectionFromValue(this.pdObject[this.propertyName]);
	}

	public getId(): string | undefined {
		let prop = this.propertyName;
		if (prop) {
			if (this.propertyRelationPath) {
				prop = `${this.propertyRelationPath}.${prop}`;
			}
			return this.getIdFromPropertyName(prop);
		}
		return super.getId();
	}

	hasId(): boolean {
		if (this.propertyName) {
			return true;

		}
		return super.hasId();
	}

	/*protected getLabelImpl(): string {
		if (this.radioButtonGroupSpec.header) {
			return this.radioButtonGroupSpec.header;
		}
		return super.getLabelImpl();
	}*/

	protected updateLabelImpl(): Observable<string> {
		if (this.radioButtonGroupSpec.header) {
			return of(this.radioButtonGroupSpec.header);
		}
		return super.updateLabelImpl();
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RadioButtonGroup;
	}

	reset(): void {
		super.reset();
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}

	get selection(): IRadioButtonComponent | undefined {
		return this._selection;
	}

	private _selection: IRadioButtonComponent | undefined;

	get selectionChanged$(): Observable<IRadioButtonComponent | undefined> {
		if (!this._selectionChanged$) {
			this._selectionChanged$ = this._selectionChangedSubject$.asObservable();
		}
		return this._selectionChanged$;
	}

	setSelection(sel: IRadioButtonComponent | undefined): void {
		this.radioButtonGroupService.setSelection(sel);
	}

	setSelectionFromValue(sel: IEnumerationItem | boolean | string | undefined): void {
		this.radioButtonGroupService.setSelectionFromValue(sel);
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._selectionChangedUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	subscribeComponentEvents(callback: (source: IComponent, eventName: RadioButtonGroupComponentEventName) => void): Subscription | undefined {
		let res = super.subscribeComponentEvents(callback);
		let sub = this._selectionChangedSubject$.subscribe(() => {
			callback(this, 'selectionChanged');
		});
		if (res) {
			sub.add(res);
		}		
		return sub;
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (!onlySelf && this.panelComponent) {
			this.panelComponent.updateUIState(false);
		}
	}
}
