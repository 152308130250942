import {
	Component, OnInit, AfterViewInit, Inject, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, HostBinding
} from '@angular/core';
import { Observable, Subscription, of, forkJoin, fromEvent, EMPTY } from "rxjs";
import {
	IToolBarItemSpec, IToolBarButtonSpec, IToolBarDropDownButtonSpec, IToolBarItemResult, IToolBarDropDownButtonItem, ToolBarItemTypeET, ICssStyle,
	ILocalizationService
} from '@otris/ng-core-types';
import { LocalizationServiceToken } from '@otris/ng-core'
import { DropDownToolBarButtonComponent } from './drop-down-tool-bar-button.component';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
export { DropDownToolBarButtonComponent };

interface IKendoDropDownItemSpec {
	text?: string;
	[propName: string]: any;
}

//button,kendo-dropdownbutton
// {{ anchor.nativeElement.parentElement.parentElement.getAttribute("class") }}
// + ' otris-pd-toolbar-kendo-toolbar-button-icon-class'
@Component({
	selector: 'otris-tool-bar',
	template: `
		<div class="toolbar-container"
			kendoTooltip
			tooltipClass="otris-tooltip"
			filter="kendo-toolbar-renderer"
			[tooltipTemplate]="template"
			position="bottom"
		>
			<ng-template #template let-anchor>
				<span>{{getShortDescription(anchor.nativeElement) | async}}</span>
			</ng-template>

			@if (showSearchControl) {
				<kendo-textbox #searchExprTextbox
					[placeholder]="placeholder$ | async" class="search-control" rounded="full"
					(otrisAfterValueChanged)="onAfterValueChange($event)"
				>
					<ng-template kendoTextBoxPrefixTemplate>
						<i class="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
					</ng-template>
				</kendo-textbox>
			}

			<!--
			<div *ngIf="showSearchControl" class="search-control-container">
				<i class="fa fa-lg fa-search icon-search" aria-hidden="true"></i>
				<input #searchInputRef
					class="search-control k-textbox"
					kendoTextBox
					type="text"
					[placeholder]="placeholder$ | async"
				/>
			</div>
			-->

			<!--
			<ng-container *ngFor="let spec of itemSpecs">
				<ng-container *ngIf="!spec.hidden || spec.hidden===false">
					<otris-button *ngIf="spec.type===toolBarItemTypeET.Button" class="toolbar-button" [flat]="true" [icon]="spec.iconClass" [label]="spec.text"
						[disabled]="disabled || spec.disabled" (buttonClick)="onClick(spec)">
					</otris-button>
					<otris-drop-down-tool-bar-button *ngIf="spec.type===toolBarItemTypeET.DropDownButton" class="toolbar-button" [toolBarButtonSpec]="spec"
						[disabled]="disabled" (itemClick)="onClick($event)">
					</otris-drop-down-tool-bar-button>
				</ng-container>
			</ng-container>
			-->

			<kendo-toolbar [ngStyle]="style">
				@for (spec of itemSpecs; track spec) {
					<!--
					*ngIf="!spec.hidden || spec.hidden===false"
					!true -> false -> true===false -> false // unsinnig
					!false -> true -> (ende)
					-->
					<!--
						https://angular.io/guide/template-typecheck#disabling-type-checking-using-any
						[iconClass]="$any(spec).iconClass"
						TS-Compiler kennt hier nur IToolBarItemSpec und nicht IToolBarButtonSpec.
						TODO: Eine Funktion wäre vermutlich schöner.
					-->
					@if (!spec.hidden) {
						@if (spec.type===toolBarItemTypeET.Button) {
							<kendo-toolbar-button
								id="{{spec.id}}"
								[iconClass]="$any(spec).iconClass?.replace('fa-lg', 'otris-fa-lg')"
								[disabled]="disabled || spec.disabled"
								[text]="getToolBarButtonText(spec) | async"
								(click)="onButtonClick($event, spec)"
								look="flat"
								[style]="$any(spec).style"
							/>
						}
						@if (spec.type===toolBarItemTypeET.DropDownButton) {
							<kendo-toolbar-dropdownbutton
								id="{{spec.id}}"
								[iconClass]="$any(spec).iconClass"
								[disabled]="disabled || spec.disabled"
								[text]="getToolBarButtonText(spec) | async"
								[buttonClass]="'otris-kendo-toolbar-dropdownbutton'"
								(itemClick)="onDropDownButtonClick($event, spec)"
								look="flat"
								[data]="createKendoDropDownButtonItems($any(spec)) | async"
								[style]="$any(spec).style"
							/>
						}
					}
				}
			</kendo-toolbar>
		</div>
	`,
	styles: [`
		.toolbar-container {
			width: 100%;
			display: flex;
			/*padding: 0 0.5em;*/
			background-color: inherit;
			color: inherit;
		}

		.toolbar-button {
			margin-right: 0.25em;
		}

		/*.search-control-container {
			display: flex;
			min-width: 20em;
			flex: 1 1 100px;
			margin-right: 0.5em;
		}*/

		.search-icon {
			margin-left: 0.5em
		}

		.search-control {
			flex: 1;
		}

		/*.search-control {
			border-radius: 1em;
			flex: 1 1 auto;
			padding-left: 28px;
		}*/

		/*.icon-search {
			position: absolute;
			z-index: 1;
			color: #9d9d9d;
			cursor: text;
			left: 16px;
			top: 15px;
			font-size: 1.25em;
		}*/
	`]
})
export class ToolBarComponent implements OnInit, AfterViewInit {
	private _itemSpecs: IToolBarItemSpec[] = [];

	@Input() placeholder: string;

	@HostBinding('class') classOtrisToolBar = 'otris-tool-bar';

	@HostBinding('style.display') get styleDisplay(): string | undefined {
		return !this.visible ? 'none' : undefined;
	}

	placeholder$: Observable<string>;

	@Input()
	set itemSpecs(specs: IToolBarItemSpec[]) {
		if (specs) {
			this._itemSpecs = specs;
		}
	}

	get itemSpecs(): IToolBarItemSpec[] {
		return this._itemSpecs;
	}

	toolBarItemTypeET = ToolBarItemTypeET;

	@Input() showSearchControl: boolean = false;

	@Input()
	set disabled(flag: boolean) {
		this._disabled = flag;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	_disabled: boolean = false;

	@Input() visible = true;

	//@Input() backgroundColor: string;

	@Output() buttonClick = new EventEmitter<IToolBarItemResult>();

	@Output() searchExpressionChanged = new EventEmitter<string>();

	@ViewChild('searchExprTextbox') searchExprTextbox: TextBoxComponent;

	//@ViewChild('searchInputRef') searchInputRef: ElementRef;

	// get iconClass(): IToolBarDropDownButtonSpec {
	// 	return this.spec.iconClass
	// }

	// class geht nicht, warum auch immer
	get style(): ICssStyle {
		let s = <ICssStyle>{
			padding: 0,
			'background-color': 'inherit',
			border: 0,
			flex: '1 1 auto'
		}
		/*if (this.backgroundColor) {
			s['background-color'] = this.backgroundColor;
		}*/
		return s;
	};

	private subscription: Subscription;


	get searchExpression(): string {
		//return this.searchInputRef ? this.searchInputRef.nativeElement.value : undefined;
		return undefined;
	}

	constructor(
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		private cdRef: ChangeDetectorRef,
		private toolbarRef: ElementRef
	) { }

	ngOnInit() {
		this.localizationService.changeHandler.subscribe(lang => this.updatePlaceholder());
		this.updatePlaceholder();

		/*if (this.selectionService) {
			this.onSelectionChanged(undefined);
			this.subscription = this.selectionService.selectionSubject.subscribe(sel => {
				this.onSelectionChanged(sel);
			});
		}*/
	}

	ngAfterViewInit() {
		/*if (this.searchInputRef) {
			fromEvent(this.searchInputRef.nativeElement, 'keyup').pipe(
				// get value
				map((evt: any) => evt.target.value),
				// text length must be > 2 chars
				//.filter(res => res.length > 2)
				// emit after 1s of silence
				debounceTime(1000),
				// emit only if data changes since the last emit
				distinctUntilChanged(),
				// subscription
			).subscribe((text: string) => this.searchExpressionChanged.emit(text));
		}*/
		if (this.searchExprTextbox) {
			setTimeout(() => this.searchExprTextbox.focus());
		}

		this.setToolbarButtonAriaLabel().subscribe();
		this.localizationService.changeHandler.pipe(
			switchMap(() => this.setToolbarButtonAriaLabel())
		).subscribe();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	 public onAfterValueChange(value: string): void {
		this.searchExpressionChanged.emit(value);
	 }

	private setToolbarButtonAriaLabel(): Observable<void> {
		if (this.itemSpecs.length == 0) {
			return EMPTY;
		}

		let shortDesc: Observable<string>[] = [];
		this.itemSpecs.forEach(item => {
			shortDesc.push(this.localizationService.getString(item.shortDescriptionId));
		});
		return forkJoin(shortDesc).pipe(
			map(shortDescArr => {
				let btns = this.toolbarRef.nativeElement.querySelectorAll('button');
				if (btns) {
					for (let i = 0; i < shortDescArr.length; i++) {
						btns[i]?.setAttribute('aria-label', shortDescArr[i]);
					}
				}
			})
		);
	}

	createKendoDropDownButtonItems(spec: IToolBarDropDownButtonSpec): Observable<IKendoDropDownItemSpec[]> {
		let itemsSpecs$ = spec.items.map(i => {
			let text$: Observable<string>;
			if (i.text){
				text$ = of(i.text);
			}
			else if (i.textId) {
				text$ =  this.localizationService.getString(i.textId);
			}
			else {
				text$ = of('');
			}
			return text$.pipe(
				map(t => {
					let itemSpec: IKendoDropDownItemSpec = Object.assign({}, i);
					itemSpec.text = t;
					return itemSpec;
				})
			);
		});
		return forkJoin(itemsSpecs$);
	}

	getShortDescription(element: any): Observable<string> {
		let searchElement = element;
		for (let i = 0; i < searchElement.parentElement.children.length; i++) {
			if (searchElement.parentElement.children[i] === searchElement) {
				if (i < this._itemSpecs.length) {
					if (this._itemSpecs[i].shortDescriptionId) {
						return this.localizationService.getString(this._itemSpecs[i].shortDescriptionId);
					}
					return of(this._itemSpecs[i].shortDescription);
				}
			}
		}
		return of(undefined);

		/*let searchElement = element.parentElement;
		for (let i = 0; i < searchElement.parentElement.children.length; i++) {
			if (searchElement.parentElement.children[i] === searchElement) {
				if (i < this._itemSpecs.length) {
					if (this._itemSpecs[i].shortDescriptionId) {
						return this.localizationService.getString(this._itemSpecs[i].shortDescriptionId);
					}
					return Observable.of(this._itemSpecs[i].shortDescription);
				}
			}
		}
		return Observable.of(undefined);*/
	}

	/*private onSelectionChanged(sel: Selection) {
		for (let spec of this.itemSpecs) {
			if (spec.enabledWhen) {
				switch (spec.enabledWhen) {
					case EnabledWhenET.MultipleItemsSelected:
						spec.disabled = !sel || !sel.arrayValue || sel.arrayValue.length == 0;
						break;
					case EnabledWhenET.SingleItemSelected:
						spec.disabled = !(sel && sel.isSingle);
						break;
					case EnabledWhenET.ItemsSelected:
						spec.disabled = !sel || !sel.value || (sel.isArray && sel.arrayValue.length == 0);
						break;
				}
			}
		}
	}*/

	getToolBarButtonText(spec: IToolBarItemSpec): Observable<string> | undefined {
		if (spec.text) {
			return of(spec.text);
		}
		if (spec.textId) {
			return this.localizationService.getString(spec.textId);
		}
		return undefined;
	}

	private updatePlaceholder(): void {
		this.placeholder$ = this.placeholder ? of(this.placeholder) :
			this.localizationService.getSystemString('kendo-ui.components.tool-bar.search-control-container-text');
	}

	onButtonClick(event: Event, item: IToolBarButtonSpec): void {
		if (!item.dontStopPropagation) {
			event.stopPropagation();
		}
		this.buttonClick.emit(<IToolBarItemResult>{ id: item.id, tag: item.tag });
	}

	onDropDownButtonClick(event: Event, item: IToolBarDropDownButtonItem): void {
		if (!item.dontStopPropagation) {
			event.stopPropagation();
		}
		this.buttonClick.emit(<IToolBarItemResult>{ id: item.id, tag: item.tag });
	}
}
