import { Component, OnInit, Input, Inject, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectEvent } from '@progress/kendo-angular-layout';

import { UIAbstractComponent, UITabViewSpec, UITabPanelSpec, FormHandlerService, EventProcessingContextManagerService, LocalizationServiceToken } from '@otris/ng-core';
import { ComponentTypeET, ITabViewComponent, IFormStatus, ILocalizationService, IComponentUIState, IPanelComponent, UIStateProvider, IUIStateProviderRegistration, IComponent } from '@otris/ng-core-types';
import { PDObject, ServiceLocator } from '@otris/ng-core-shared';
//import { PDPanelComponent } from '../pd-panel/pd-panel.component'; // Rekursionsproblem!!

class PDTabPanel {
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(flag: boolean) {
		this._disabled = flag;
	}

	private _disabled: boolean = false;

	get hidden(): boolean {
		return this._hidden;
	}

	set hidden(flag: boolean) {
		this._hidden = flag;
	}

	private _hidden: boolean = false;

	get header(): string {
		/*if (this.spec.headerId && this.pdObject) {
			return this.pdObject.metaData.getStringFromId(this.spec.headerId);
		}
		return this.spec.header;*/
		return this._header
	}

	private _header: string;

	get panelSpec(): UITabPanelSpec {
		return this.spec;
	}

	get index(): number {
		return this._index;
	}

	//private _subscriptions: Subscription;

	constructor(private spec: UITabPanelSpec, private _index: number, private pdObject: PDObject) {
		let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
		//this._subscriptions =
		localizationService.changeHandler.subscribe(lang => this.onLanguageChanged());
	}

	private onLanguageChanged() {
		if (this.spec.headerId && this.pdObject) {
			this.pdObject.metaData.getStringFromId(this.spec.headerId).subscribe(res => this._header = res);
		}
		else {
			this._header = this.spec.header;
		}
	}
}

@Component({
	selector: 'otris-pd-tab-view',
	template: `
		<div class="container">
			<kendo-tabstrip class="tabstrip" height="100%" [keepTabContent]="true" (tabSelect)="onTabSelect($event)">
				@for (tabPanel of visibleTabPanels; track tabPanel; let i = $index) {
					<kendo-tabstrip-tab 
						[title]="tabPanel.header" 
						[disabled]="tabPanel.disabled" 
						[selected]="i==selectedVisibleIndex"
					>
						@if (tabPanel.panelSpec.content) {
							<ng-template kendoTabContent>
								<otris-pd-panel #panel 
									class="tab-otris-panel otris-pd-tab-view-pd-panel" 
									[uiItemSpec]="tabPanel.panelSpec.content"
									[formGroup]="formGroup" 
									[pdObject]="pdObject" 
									[relationContext]="relationContext"
									[idPostfix]="'tabView'"
								/>
							</ng-template>
						}
					</kendo-tabstrip-tab>
				}
			</kendo-tabstrip>
		</div>
	`,
	styles: [`
		:host {
			height: 100%;
			overflow-y: hidden; /* Chrome bugfix */
		}
		.container {
			height: 100%;
			display: flex;
		}
		.tabstrip {
			width: 100%;
		}
		/*.tabstriptab {
			display: flex;
		}*/
		.tab-otris-panel {
			flex: 1 1 auto;
			display: flex;
			height: 100%;
		}
	`]
})
export class PDTabViewComponent extends UIAbstractComponent implements ITabViewComponent {

	@Input() pdObject: PDObject;

	@ViewChildren('panel') panels: QueryList<IPanelComponent>;

	get tabPanels(): PDTabPanel[] {
		return this._tabPanels;
	}

	private _tabPanels: PDTabPanel[] = [];

	get visibleTabPanels(): PDTabPanel[] {
		return this._tabPanels.filter(p => !p.hidden);
	}

	get isContainerComponent(): boolean {
		return true;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private changeDetector: ChangeDetectorRef,
		eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	get tabViewSpec(): UITabViewSpec {
		if (!(this.uiItemSpec instanceof UITabViewSpec)) {
			throw Error('No UITabViewSpec assigned.');
		}
		return <UITabViewSpec>this.uiItemSpec;
	}

	get selectedVisibleIndex(): number {
		if (this._selectedIndex !== undefined) {
			return this.visibleTabPanels.findIndex(p => p.index == this._selectedIndex);
		}
		return undefined;
	}

	_selectedIndex: number;

	/*getHeader(spec: UITabPanelSpec): string | undefined {
		if (spec) {
			if (spec.headerId && this.pdObject) {
				return this.pdObject.metaData.getStringFromId(spec.headerId);
			}
			return spec.header;
		}
		return undefined;
	}*/

	ngOnInit() {
		super.ngOnInit();
		this._tabPanels = this.tabViewSpec.panels.map((p, i) => new PDTabPanel(p, i, this.pdObject));
		/*if (this.uiItemSpec.disabled) {
			this.disable(true);
		}*/
		this._selectedIndex = this.visibleTabPanels.length > 0 ? 0 : undefined;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.panels.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<IPanelComponent>);
		});
		this.createChildComponentUIStateProviders(this.panels);
	}

	onTabSelect(evt: SelectEvent) {
		this._selectedIndex = this.visibleTabPanels[evt.index].index;
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.panels) {
			this.panels.forEach(pc => {
				let panelStat = pc.getFormControlStatus();
				if (!panelStat.pristine) {
					stat.pristine = false;
				}
				if (panelStat.touched) {
					stat.touched = true;
				}
				if (!panelStat.valid) {
					stat.valid = false;
				}
			});
		}
		return stat;
	}

	disableTabPanel(index: number, flag: boolean): void {
		if (Number.isInteger(index) && index >= 0 && index < this.tabPanels.length &&
			this.tabPanels[index].disabled != flag) {
			this.tabPanels[index].disabled = flag;
			if (flag && index === this._selectedIndex) {
				let firstEnabled = this.tabPanels.find(p => !p.disabled && !p.hidden);
				this._selectedIndex = firstEnabled ? firstEnabled.index : undefined;
			}
			else if (!flag && !this.tabPanels[index].hidden && this._selectedIndex === undefined) {
				this._selectedIndex = index;
			}
		}
	}

	getTabPanelDisabled(index: number): boolean {
		if (Number.isInteger(index) && index >= 0 && index < this.tabPanels.length) {
			return this.tabPanels[index].disabled;
		}
		return false;
	}

	hideTabPanel(index: number, flag: boolean): void {
		if (Number.isInteger(index) && index >= 0 && index < this.tabPanels.length &&
			this.tabPanels[index].hidden != flag) {
			this.tabPanels[index].hidden = flag;
			if (flag && index === this._selectedIndex) {
				let firstEnabled = this.tabPanels.find(p => !p.disabled && !p.hidden);
				this._selectedIndex = firstEnabled ? firstEnabled.index : undefined;
			}
			else if (!flag && !this.tabPanels[index].disabled && this._selectedIndex === undefined) {
				this._selectedIndex = index;
			}
			this.changeDetector.detectChanges();
		}
	}

	getTabPanelHidden(index: number): boolean {
		if (Number.isInteger(index) && index >= 0 && index < this.tabPanels.length) {
			return this.tabPanels[index].hidden;
		}
		return false;
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (!onlySelf) {
			if (this.panels) {
				this.panels.forEach(p => p.updateUIState(false));
			}
		}
	}

	/*registerUIStateProvider(provider: UIStateProvider, providerHost?: IComponent): IUIStateProviderRegistration {
		let registration = super.registerUIStateProvider(provider, providerHost); 
		if (this.panels.length > 0) {
			let ctx = this.createEventProcessingContext();
			this.panels.forEach(item => {
				let childReg = item.registerUIStateProvider((src) => {
					let state = provider(src, ctx);
					return {
						readonly: state.readonly ? true : undefined,
						disabled: state.disabled ? true : undefined
						// ...
						// todo
					}
				}, this);
				registration.addChildRegistration(childReg);
			});
		}
		return registration;
	}*/

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.TabView;
	}

	/*get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;*/

	/*disable(flag: boolean): void {
		this._disabled = flag;
		// todo: oder müssen die einzelnen Tabs disabled werden?
		for (let tabPanel of this.tabPanels) {
			tabPanel.disabled = flag;
		}
		if (this.panels) {
			this.panels.forEach(item => item.disable(flag));
		}
	}*/

	/*protected onDisabledChanged() {
		super.onDisabledChanged();
		// todo: oder müssen die einzelnen Tabs disabled werden?
		let flag = this.isDisabled;
		for (let tabPanel of this.tabPanels) {
			tabPanel.disabled = flag;
		}
		if (this.panels) {
			this.panels.forEach(item => item.systemDisabled = flag);
		}
	}*/

	/*protected onVisibleChanged() {
		super.onVisibleChanged();
		let flag = this.isVisible;
		// Todo?
		//for (let tabPanel of this.tabPanels) {
		//	tabPanel.visible = flag;
		//}
		if (this.panels) {
			this.panels.forEach(item => item.systemVisible = flag);
		}
	}*/

	reset(): void {
		if (this.panels) {
			this.panels.forEach(item => item.reset());
		}
	}
}
