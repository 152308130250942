import { Injectable } from "@angular/core";
import {
	IColumnSpec,
	IGenericContainerSpec,
	IPDExpansionPanelSpec,
	IPDItemSpec, IPDRelationTabSpec, IToNRelationSuitableContainerSpec, IUIContainerSpec, IUIDrawerSpec, IUIFormSpec,
	IUIGroupboxSpec, IUIItemData, IUIItemDataMutable, IUIItemRef, IUIItemSpec, IUIPanelSpec, IUIRadioButtonGroupSpec, ItemTypeET, UIItemInfo,
	UIItemLocation,
	UIItemLocator,
	UIItemUsage
} from "@otris/ng-core-types";

import { UIItemSpec } from '../model/pd-layout';
import { IFormSpecBuilder } from "./i-form-spec-builder.service";

@Injectable()
export class FormSpecBuilder implements IFormSpecBuilder {

	constructor() {}

	findUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo | undefined {
		switch (locator.type) {
			case 'id':
				return formItems.filter(itemInfo => (itemInfo.item as IUIItemSpec).type && (itemInfo.item as IUIItemSpec).id)
					.find(itemInfo =>{
						let itemSpec = itemInfo.item as IUIItemSpec;
						let relPath = itemInfo.relationPath ? `${itemInfo.relationPath}.${itemSpec.id}` : itemSpec.id;
						return relPath === locator.value && (!locator.itemType || locator.itemType === itemSpec.type);
					});

			case 'property':
				return formItems.filter(itemInfo => (itemInfo.item as IUIItemSpec).type && itemInfo.property)
					.find(itemInfo => {
						let itemSpec = itemInfo.item as IUIItemSpec;
						let relPath = itemInfo.relationPath ? `${itemInfo.relationPath}.${itemInfo.property}` : itemInfo.property;
						return relPath === locator.value && (!locator.itemType || locator.itemType === itemSpec.type);
					});

			case 'idRef':
				return formItems.filter(itemInfo => (itemInfo.item as IUIItemRef).isItemRef)
					.find(itemInfo => {
						let itemRef = itemInfo.item as IUIItemRef;
						let relPath = itemInfo.relationPath ? `${itemInfo.relationPath}.${itemRef.idRef}` : itemRef.idRef;
						return relPath === locator.value;
					});
		}
	}

	insertUIItem(location: UIItemLocation, uiItemSpec: IUIItemSpec, formItems: UIItemInfo[]): UIItemInfo | undefined {
		let targetItem = this.findUIItem(location.target, formItems);
		if (!targetItem) {
			console.warn(`Could not insert UIItem. Target Item ${location.target.value} not found`);
			return undefined;
		}

		let newItemInfo: UIItemInfo;
		switch (location.position) {
			case 'after':
			case 'before': {
				let targetPos = targetItem.parent.items.findIndex(item => item === targetItem.item);
				if (location.position === 'after') {
					targetPos++;
				}
				targetItem.parent.items.splice(targetPos, undefined, uiItemSpec);
				newItemInfo = {
					parent: targetItem.parent,
					item: uiItemSpec,
					property: this.getItemSpecPropertyName(uiItemSpec),
					relationPath: this.getItemLocatorRelationPath(location.target)
				};
				break;
			}

			case 'inside': // todo: inside weg
			case 'end':
			case 'start': {
				if ((targetItem.item as IUIItemSpec).type) {
					let contSpec: IUIContainerSpec;
					let itemSpec = targetItem.item as IUIItemSpec;
					if (itemSpec.type === ItemTypeET.UIGroupbox) {
						if (!((targetItem.item as IUIGroupboxSpec).content as IUIItemRef).isItemRef) {
							contSpec = (targetItem.item as IUIGroupboxSpec).content as IUIPanelSpec;
						}
					}
					else if (itemSpec.type === ItemTypeET.ExpansionPanel) {
						if (!((targetItem.item as IPDExpansionPanelSpec).content as IUIItemRef).isItemRef) {
							contSpec = (targetItem.item as IPDExpansionPanelSpec).content as IUIPanelSpec;
						}
					}
					else if (itemSpec.type === ItemTypeET.GenericContainer) {
						if (!((targetItem.item as IGenericContainerSpec).content as IUIItemRef).isItemRef) {
							contSpec = (targetItem.item as IGenericContainerSpec).content as IUIPanelSpec;
						}
					}
					else if (itemSpec.type === ItemTypeET.UIPanel) {
						contSpec = targetItem.item as IUIContainerSpec;
					}
					if (contSpec) {
						if (!contSpec.items) {
							contSpec.items = [];
						}
						if (location.position === 'start') {
							contSpec.items.splice(0, 0, uiItemSpec);
						}
						else {
							contSpec.items.push(uiItemSpec);
						}
						newItemInfo = {
							parent: contSpec,
							item: uiItemSpec,
							property: this.getItemSpecPropertyName(uiItemSpec),
							relationPath: this.getItemLocatorRelationPath(location.target)
						};
					}
				}
				break;
			}
		}

		if (newItemInfo) {
			formItems.push(newItemInfo);
		}
		return newItemInfo;
	}

	createNewUIItem(newItem: UIItemUsage, uiItemDefs: Map<string, IUIItemSpec>, formItems: UIItemInfo[]): UIItemInfo {
		let uiItemSpec = this.createUIItemSpec(newItem.item, uiItemDefs);
		return this.insertUIItem(newItem.placement, uiItemSpec, formItems);
	}

	deleteUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo[] {
		let uiItemInfo = this.findUIItem(locator, formItems);
		if (!uiItemInfo) {
			return formItems;
		}

		let pos = uiItemInfo.parent.items.findIndex(item => item == uiItemInfo.item);
		uiItemInfo.parent.items.splice(pos, 1);
		pos = formItems.indexOf(uiItemInfo);
		formItems.splice(pos, 1);
		return formItems;
	}

	moveUIItem(source: UIItemLocator, moveTo: UIItemLocation, formItems: UIItemInfo[]): UIItemInfo | undefined {
		let sourceItem = this.findUIItem(source, formItems);
		if (!sourceItem) {
			console.warn(`FormSpecBuilder.moveUIItem(): Source item not found`);
			return undefined;
		}
		let targetItem = this.findUIItem(moveTo.target, formItems);
		if (!targetItem) {
			console.warn(`FormSpecBuilder.moveUIItem(): Target item not found`);
			return undefined;
		}
		if (!this.compareRelationPaths(source, moveTo.target)) {
			console.warn(`FormSpecBuilder.moveUIItem(): Relation path of source an target do not fit`);
			return undefined;
		}

		let sourcePos = sourceItem.parent.items.findIndex(item => item == sourceItem.item);
		sourceItem.parent.items.splice(sourcePos, 1);

		let targetPos = targetItem.parent.items.findIndex(item => item === targetItem.item);
		if (moveTo.position === 'after') {
			targetPos++;
		}
		targetItem.parent.items.splice(targetPos, undefined, sourceItem.item);
		sourceItem.parent = targetItem.parent;
		return sourceItem;
	}

	getItemSpecPropertyName(item: IUIItemSpec | IUIItemRef): string | undefined {
		if ((item as IUIItemRef).isItemRef) {
			let itemRef = item as IUIItemRef;
			return itemRef.idRefType === 'property' ? (item as IUIItemRef).idRef : undefined;
		}

		let itemSpec = item as IUIItemSpec;
		switch (itemSpec.type) {
			case ItemTypeET.PDItem:
			case ItemTypeET.PDRadioButton:
				return (<IPDItemSpec>itemSpec).property;
			case ItemTypeET.UIRadioButtonGroup:
				return (<IUIRadioButtonGroupSpec>itemSpec).property;
			case ItemTypeET.PDRelationTab:
				return (<IPDRelationTabSpec>itemSpec).property;
			default:
				return undefined;
		}
	}

	getItemLocatorRelationPath(locator: UIItemLocator): string | undefined {
		if (locator.type === 'property') {
			let pos = locator.value.lastIndexOf('.');
			return pos >= 0 ? locator.value.substring(0, pos) : undefined;
		}
		return undefined;
	}

	parseFormSpec(formSpec: IUIFormSpec): UIItemInfo[] {
		if (!formSpec.content) {
			return [];
		}
		let formItems: UIItemInfo[] = [];
		formItems.push({ parent: undefined, item: formSpec.content });
		return this.storeItemsForContainer(formItems, formSpec.content);
	}

	createUIItemSpec(data: IUIItemData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec {
		return UIItemSpec.createIUIItemSpec(data, uiItemDefs);
	}

	updateUIItemSpec(spec: IUIItemSpec, data: IUIItemDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec {
		return UIItemSpec.updateIUIItemSpec(spec, data, uiItemDefs);
	}

	private storeItemsForContainer(formItems: UIItemInfo[], container: IUIContainerSpec, relationPath?: string): UIItemInfo[] {
		let addFormItem = (itemInfo: UIItemInfo) => {
			if (!formItems.find(i => i.item === itemInfo.item)) {
				formItems.push(itemInfo);
				return true;
			}
			return false;
		}

		if (container.items) {
			container.items.forEach(item => {
				let property = this.getItemSpecPropertyName(item);
				let newRelPath = this.determineRelationPath(item, false, relationPath);
				//formItems.push({ parent: container, item: item, relationPath: newRelPath, property: property });
				if (addFormItem({ parent: container, item: item, relationPath: newRelPath, property: property })) {
					let childRelPath = this.determineRelationPath(item, true, relationPath);
					if ((item as IUIItemSpec).type) {
						let itemSpec = item as IUIItemSpec;
						switch (itemSpec.type) {
							case ItemTypeET.UIPanel:
								this.storeItemsForContainer(formItems, item as IUIPanelSpec, childRelPath);
								break;
							case ItemTypeET.UIGroupbox:
								if ((item as IUIGroupboxSpec).content && !((item as IUIGroupboxSpec).content as IUIItemRef).isItemRef) {
									/*formItems.push({
										parent: (item as IUIGroupboxSpec),
										item: (item as IUIGroupboxSpec).content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName((item as IUIGroupboxSpec).content)
									});*/
									let formItem = {
										parent: (item as IUIGroupboxSpec),
										item: (item as IUIGroupboxSpec).content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName((item as IUIGroupboxSpec).content)
									}
									if (addFormItem(formItem)) {
										this.storeItemsForContainer(formItems, (item as IUIGroupboxSpec).content as IUIPanelSpec, childRelPath);
									}
								}
								break;
							case ItemTypeET.ExpansionPanel:
								if ((item as IPDExpansionPanelSpec).content && !((item as IPDExpansionPanelSpec).content as IUIItemRef).isItemRef) {
									/*formItems.push({
										parent: (item as IPDExpansionPanelSpec),
										item: (item as IPDExpansionPanelSpec).content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName((item as IPDExpansionPanelSpec).content)
									});*/
									let formItem = {
										parent: (item as IPDExpansionPanelSpec),
										item: (item as IPDExpansionPanelSpec).content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName((item as IPDExpansionPanelSpec).content)
									}
									if (addFormItem(formItem)) {
										this.storeItemsForContainer(formItems, (item as IPDExpansionPanelSpec).content as IUIPanelSpec, childRelPath);
									}
								}
								break;
							case ItemTypeET.GenericContainer:
								if ((item as IGenericContainerSpec).content && !((item as IGenericContainerSpec).content as IUIItemRef).isItemRef) {
									let formItem = {
										parent: (item as IGenericContainerSpec),
										item: (item as IGenericContainerSpec).content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName((item as IGenericContainerSpec).content)
									}
									if (addFormItem(formItem)) {
										this.storeItemsForContainer(formItems, (item as IGenericContainerSpec).content as IUIPanelSpec, childRelPath);
									}
								}
								break;
							case ItemTypeET.PDRelationTab:
								if ((item as IPDRelationTabSpec).content) {
									this.storeItemsForContainer(formItems, (item as IPDRelationTabSpec).content, childRelPath);
								}
								break;
							case ItemTypeET.UIDrawer:
								(item as IUIDrawerSpec).drawerItems.forEach(drawerItem => {
									/*formItems.push({
										parent: (item as IUIDrawerSpec),
										item: drawerItem.content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName(drawerItem.content)
									});*/
									let formItem = {
										parent: (item as IUIDrawerSpec),
										item: drawerItem.content,
										relationPath: relationPath,
										property: this.getItemSpecPropertyName(drawerItem.content)
									}
									if (addFormItem(formItem)) {
										this.storeItemsForContainer(formItems, drawerItem.content, childRelPath);
									}
								});
								break;
							case ItemTypeET.UIRadioButtonGroup:
								if ((item as IUIRadioButtonGroupSpec).content && !((item as IUIRadioButtonGroupSpec).content as IUIItemRef).isItemRef) {
									this.storeItemsForContainer(formItems, (item as IUIRadioButtonGroupSpec).content as IUIPanelSpec, childRelPath);
								}
								break;
						}
					}
				}
			});
		}
		return formItems;
	}

	private compareRelationPaths(locator1: UIItemLocator, locator2: UIItemLocator): boolean {
		let pos1 = locator1.value.lastIndexOf('.');
		let pos2 = locator2.value.lastIndexOf('.');
		if (pos1 == -1 && pos2 == -1) {
			return true;
		}
		if (pos1 == -1 || pos2 == -1) {
			return false;
		}
		return locator1.value.substring(0, pos1) === locator2.value.substring(0, pos2);
	}

	private determineRelationPath(item: IUIItemSpec | IUIItemRef, forChilds: boolean, currentRelationPath?: string): string | undefined {
		if ((item as IUIItemSpec).type) {
			let itemSpec = item as IUIItemSpec;
			let relName: string;
			switch (itemSpec.type) {
				case ItemTypeET.UIGroupbox:
				case ItemTypeET.ExpansionPanel:
				case ItemTypeET.GenericContainer:
				case ItemTypeET.UIPanel:
					{
						let containerSpec = <IToNRelationSuitableContainerSpec>itemSpec;
						if (containerSpec.toNRelationItemSpec) {
							relName = containerSpec.toNRelationItemSpec.relationProperty;
						}
						else if (containerSpec.toNRelationSpec) {
							relName = containerSpec.toNRelationSpec.relationProperty;
						}
						break;
					}
				case ItemTypeET.PDRelationTab:
					if (forChilds) {
						relName = (<IPDRelationTabSpec>itemSpec).property;
					}
					break;
				case ItemTypeET.PDItem:
					if ((itemSpec as IPDItemSpec).to1RelationItemSpec) {
						relName = (itemSpec as IPDItemSpec).to1RelationItemSpec.relationProperty;
					}
					break;
			}

			if (relName) {
				return currentRelationPath ? `${currentRelationPath}.${relName}` : relName;
			}
		}
		return currentRelationPath;
	}
}
