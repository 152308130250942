import { Injectable, InjectionToken, Inject, inject } from '@angular/core';
import { IPDListColumnInfo } from '@otris/ng-core-types';

export const IPDColumnProviderToken = new InjectionToken<IPDColumnProviderService[]>('IPDColumnProviderToken');

export const IPDRootColumnProviderToken = new InjectionToken<IPDColumnProviderService>(
	'IPDRootColumnProviderToken',
	{ factory: () => new PDRootColumnProviderService(inject(IPDColumnProviderToken)), providedIn: 'root' }
);

export interface IPDColumnProviderService {
	getColumns(cls: string): IPDListColumnInfo[] | undefined;
	//getRelationColumns<T>(basisClass: string | (new () => T), relation: string): IPDListColumnInfo[];
}

@Injectable()
export class PDRootColumnProviderService implements IPDColumnProviderService {

	constructor(@Inject(IPDColumnProviderToken) private columnProviders: IPDColumnProviderService[]) {

	}

	getColumns(cls: string): IPDListColumnInfo[] | undefined {
		if (this.columnProviders) {
			for (let provider of this.columnProviders) {
				let colInfos = provider.getColumns(cls);
				if (colInfos) {
					return colInfos;
				}
			}
		}
		return undefined;
	}
}
