import { Injectable } from "@angular/core";
import {
	IUIFormSpec,
	UIItemLocator,
	IFormSpecConversionData,
	IFormSpecConversionDataItem,
	IUIItemDataMutable,
	UIItemChange,
	UIItemInfo,
	IUIItemSpec,
	UIItemRefData,
	IUIItemData
} from "@otris/ng-core-types";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IFormSpecBuilder } from "./i-form-spec-builder.service";
//import { IFormSpecBuilder, UIItemInfo } from "./form-spec-builder.service";

// todo:
// - besser UIItemAdaption?
// - als Interface realisieren und weitere abgeleitete Interfaces, z.B. UIContainerAdaption, UIPanelAdaption, UIDrawerAdaption, PDRadioButtonGroupAdaption
// siehe UIItemChange
/*export type UIItemConversion = {
	locator: UIItemLocator,
	uiState?: {
		hidden?: boolean | UIStateValue;
		mandatory?: boolean | UIStateValue;
		readonly?: boolean | UIStateValue;
		disabled?: boolean | UIStateValue;
	},
	uiStateExpression?: ComponentUIStateExpression;
	moveTo?: UIItemLocation;
	widget?: IWidgetAdaption;
	delete?: boolean;
	omitItem?: boolean;
	shortDescription?: ErgNameData;
}*/

// Neu, Ersatz für UIItemConversion
/*export type UIItemChange = {
	locator: UIItemLocator;
	moveTo?: UIItemLocation;
	delete?: boolean;
	omitItem?: boolean;
	uiItemData?: IUIItemDataMutable;
}*/

// todo: prüfen, ob das noch gebraucht wird
/*export type UIStateValue = {
	value: boolean; // | UIStateExpression (string?)
	overwriteOriginalState?: boolean; // kann eigentlich wieder weg da falls value=false atomatisch overwriteOriginalState=true angenommen werden könnte
}*/

// todo: IWidgetAdaption ersetzten durch IWidgetData aus i-pd-layout-data
// z.B. muss ITextFieldWidgetAdaption durch ITextFieldWidgetData ersetzt und es müssen die fehlenden Properties
// in ITextFieldWidgetData mit denen aus ITextFieldWidgetAdaption ergänzt werden
// Todo prüfen: Wie geht man mit den Properties um, die in XXX_Data Mandatory sind und in XXX_Adaption natürlich nicht mandatory sein dürfen?
// Beispiel: IUIRadioButtonGroupData.dataType
// Ggf. alle Props in XXXData optional und mit Default-werten versehen?
// Oder neues Interface wie z.B. ITextFieldOptWidgetData von dem ITextFieldWidgetData erbt
// ggf. die XXXOptData Interfaces nur bei den WidgetInfos, die mandatory-Felder enthalten

//export interface IWidgetAdaption {
	//type: 'multiSelect' | 'objectReference'
//}

/*export interface ITextFieldWidgetAdaption extends IWidgetAdaption {
	textTemplateSelection?: TextTemplateSelectionData;
	uiStateUpdateType?: PDTextFieldUIStateUpdateType;

	showClearButton?: boolean;

	multiline?: MultilineTextField
	spellcheck?: boolean;
}*/

/*export interface IComboBoxWidgetAdaption extends IWidgetAdaption {
	spellcheck?: boolean;
}*/

/*export interface IDrawerWidgetAdaption extends IWidgetAdaption {
	items?: [
		{
			itemId: string;
			moveTo?: {
				targetId: string;
				position: 'before' | 'after' | 'start' | 'end';
			}
			text?: ErgNameData;
			shortDescription?: ErgNameData;
			icon?: string;
			uiState?: {
				hidden?: boolean | UIStateValue;
				mandatory?: boolean | UIStateValue;
				readonly?: boolean | UIStateValue;
				disabled?: boolean | UIStateValue;
			},
		}
	],
	newItems?: {
		position?: 'start' | 'end' | number;
		drawerItem: IUIDrawerItemData;
	}[];
}*/

/*export interface IRelationWidgetAdaption extends IWidgetAdaption {
	createObject?: {
		enabled: boolean;
		params: object;
	},
	// Todo Typ IPDChoiceSpec | IPDChoiceSpec[]
	choiceSpec?: {
		className: string;
		filterExpr?: string;
		sortExpr?: string;
		objectInfo?: string;
	};
	removeCustomChoiceProvider?: boolean;
	selectionListSpec?: PDObjectSelectionListSpecData;
	editingSpec?: {
		objectInfo?: string;
	},
	selectAction?: boolean;
}*/

/*export interface IMultiSelectWidgetAdaption extends IRelationWidgetAdaption {
	showClearButton?: boolean;
	type?: MultiSelectRelationWidgetTypeET;
	editableRelationProperty?: string;
	textFieldSpec?: MultiSelectTextFieldSpec;
	textTemplateSelection?: TextTemplateSelectionData;
}*/

/*export interface IObjectReferenceWidgetAdaption extends IRelationWidgetAdaption {
	type?: ObjectReferenceTypeET;
	editableRelationProperty?: string;
	textFieldSpec?: ObjectReferenceTextFieldSpec;
}*/

/*export interface IContainerWidgetAdaption extends IWidgetAdaption {
	layout?: ILayoutData;
}

export interface IPanelWidgetAdaption extends IContainerWidgetAdaption {}*/

/*export interface IToNRelationSuitableContainerAdaption extends IContainerWidgetAdaption {
	toNRelationSpec?: {
		maxRelationObjects?: number;
	}
}*/

/*export interface IGroupboxWidgetAdaption extends IToNRelationSuitableContainerAdaption {
}*/

/*export interface IRelationTabWidgetAdaption extends IWidgetAdaption {
	maxTabCount?: number;
	canCreateTab?: boolean;
	canDeleteTab?: boolean;
}*/

/*export interface IRelationGridWidgetAdaption extends IRelationWidgetAdaption {
	quickCreationEnabled?: boolean;
}*/

@Injectable()
export class FormSpecConversionService implements IFormSpecConversionService {

	private _convertedFormSpecs: IUIFormSpec[] = [];

	constructor(private _formSpecBuilder: IFormSpecBuilder) {}

	/*getRelationPath(locator: UIItemLocator): string | undefined {
		let pos = locator.value.lastIndexOf('.');
		return pos >= 0 ? locator.value.substring(0, pos) : undefined;
	}*/

	convertFormSpecs(formSpecs: [string, IUIFormSpec][],
		conversionDataProvider: (param: object) => Observable<IFormSpecConversionData | undefined>,
		conversionProviderParam: object): Observable<[string, IUIFormSpec][]> {

		return conversionDataProvider(conversionProviderParam).pipe(
			map(conversionData => {
				if (!conversionData || !conversionData.forms) { // Workaround, mit der Schemavalidierung sollte das nicht mehr nötig sein.
					return formSpecs;
				}
				formSpecs.filter(spec => !this._convertedFormSpecs.includes(spec[1])).forEach(spec => {
					let formConversion = conversionData.forms.find(data => data.className === spec[0]);
					if (formConversion) {
						this._convertedFormSpecs.push(spec[1]);
						this.convertFormSpecImpl(spec[1], formConversion);
					}
				});
				return formSpecs;
			})
		);
	}

	convertFormSpec(formSpec: IUIFormSpec, className: string,
		conversionDataProvider: (param: object) => Observable<IFormSpecConversionData | undefined>, conversionProviderParam: object): Observable<IUIFormSpec> {

		if (!formSpec.content || this._convertedFormSpecs.includes(formSpec)) {
			return of(formSpec);
		}

		return conversionDataProvider(conversionProviderParam).pipe(
			map(conversionData => {
				if (conversionData) {
					let formConversion = conversionData.forms.find(data => data.className === className);
					if (formConversion) {
						this._convertedFormSpecs.push(formSpec);
						this.convertFormSpecImpl(formSpec, formConversion);
					}
				}
				return formSpec;
			})
		);
	}

	/*determineUIItemInfos(formSpec: IUIFormSpec): UIItemInfo[] {
		return this._formSpecBuilder.parseFormSpec(formSpec);
		//return this.storeItemsForContainer([], formSpec.content);
	}*/

	/*findUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo | undefined {
		switch (locator.type) {
			case 'id':
				//return formItems.find(itemInfo => itemInfo.item.id === locator.value);
				return formItems.filter(itemInfo => itemInfo.item.id).find(itemInfo =>{
					let relPath = itemInfo.relationPath ? `${itemInfo.relationPath}.${itemInfo.item.id}` : itemInfo.item.id;
					return relPath === locator.value && (!locator.itemType || locator.itemType === itemInfo.item.type);
				});

			case 'property':
				return formItems.filter(itemInfo => itemInfo.property).find(itemInfo => {
					let relPath = itemInfo.relationPath ? `${itemInfo.relationPath}.${itemInfo.property}` : itemInfo.property;
					return relPath === locator.value && (!locator.itemType || locator.itemType === itemInfo.item.type);
				});
		}
	}*/

	/*insertUIItem(itemLocator: UIItemLocator, position: UIItemPositioning, uiItemSpec: IUIItemSpec, formItems: UIItemInfo[]): void {
		let targetItem = this._formSpecBuilder.findUIItem(itemLocator, formItems);
		if (!targetItem) {
			return;
		}

		if (position === 'inside') {
			if (targetItem.item.type === ItemTypeET.UIPanel) {
				let contSpec = targetItem.item as IUIContainerSpec;
				if (!contSpec.items) {
					contSpec.items = [];
				}
				contSpec.items.push(uiItemSpec);
				formItems.push({
					parent: targetItem.item,
					item: uiItemSpec,
					property: this.getPropertyName(uiItemSpec),
					relationPath: this._formSpecBuilder.getItemLocatorRelationPath(itemLocator)
				});
			}
		}
		else {
			let targetPos = targetItem.parent.items.findIndex(item => item === targetItem.item);
			if (position === 'after') {
				targetPos++;
			}
			targetItem.parent.items.splice(targetPos, undefined, uiItemSpec);
			formItems.push({
				parent: targetItem.parent,
				item: uiItemSpec,
				property: this.getPropertyName(uiItemSpec),
				relationPath: this._formSpecBuilder.getItemLocatorRelationPath(itemLocator)
			});
		}
	}*/

	private convertFormSpecImpl(formSpec: IUIFormSpec, formConversion: IFormSpecConversionDataItem): void {
		if (!formConversion) {
			return;
		}
		let formItems = this._formSpecBuilder.parseFormSpec(formSpec);

		if (formConversion.form) {
			if (formConversion.form.header) {
				formSpec.header = formConversion.form.header;
				formSpec.headerId = undefined;
			}
		}

		let uiItemDefs: Map<string, IUIItemSpec> = new Map();
		if (formConversion.uiItemDefs) {
			formConversion.uiItemDefs.forEach(itemDef => {
				let spec: IUIItemSpec = this._formSpecBuilder.createUIItemSpec(itemDef.item as IUIItemData, uiItemDefs);
				let id = itemDef.id ? itemDef.id : spec.id;
				if (!id) {
					console.warn(`No id provided for UIItemDefData`);
				}
				else {
					if (uiItemDefs.has(id)) {
						console.warn(`UIItemDefData with id ${id} has already been specified. Item is updated.`);
					}
					uiItemDefs.set(id, spec);
				}
			});
		}

		if (formConversion.uiItems) {
			formConversion.uiItems.forEach(itemChange => {
				if (itemChange.delete === true) {
					if (itemChange.delete) {
						this._formSpecBuilder.deleteUIItem(itemChange.locator, formItems);
					}
					//this.deleteUIItem(itemConversion, formItems);
				}
				else {
					//this.integrateUIState(itemConversion, formItems);
					//this.moveUIItem(itemChange, formItems);
					if (itemChange.moveTo) {
						this._formSpecBuilder.moveUIItem(itemChange.locator, itemChange.moveTo, formItems);
					}
					//this.adaptWidget(itemConversion, formItems);
					this.adaptUIItem(itemChange.uiItemData, itemChange.locator, formItems);
					this.omitUIItem(itemChange, formItems);
					//this.updateUIItemProperties(itemConversion, formItems);
				}
			});
		}

		// TODO: muss als erstes gemacht werden
		if (formConversion.newItems) {
			formConversion.newItems.forEach(item => this._formSpecBuilder.createNewUIItem(item, uiItemDefs, formItems));
		}
	}

	/*private storeItems(formSpec: IUIFormSpec): UIItemInfo[] {
		if (!formSpec.content) {
			return [];
		}
		let formItems: UIItemInfo[] = [];
		formItems.push({ parent: undefined, item: formSpec.content });
		return this.storeItemsForContainer(formItems, formSpec.content);
	}*/

	/*private storeItemsForContainer(formItems: UIItemInfo[], container: IUIContainerSpec, relationPath?: string): UIItemInfo[] {
		if (container.items) {
			container.items.forEach(item => {
				let property = this.getPropertyName(item);
				let newRelPath = this.determineRelationPath(item, relationPath);
				formItems.push({ parent: container, item: item, relationPath: newRelPath, property: property });
				//let newRelPath = this.determineRelationPath(item, relationPath);
				switch (item.type) {
					case ItemTypeET.UIPanel:
						this.storeItemsForContainer(formItems, item as IUIPanelSpec, newRelPath);
						break;
					case ItemTypeET.UIGroupbox:
						if ((item as IUIGroupboxSpec).content) {
							formItems.push({
								parent: (item as IUIGroupboxSpec),
								item: (item as IUIGroupboxSpec).content,
								relationPath: relationPath,
								property: this.getPropertyName((item as IUIGroupboxSpec).content)
							});
							this.storeItemsForContainer(formItems, (item as IUIGroupboxSpec).content, newRelPath);
						}
						break;
					case ItemTypeET.PDRelationTab:
						if ((item as IPDRelationTabSpec).content) {
							this.storeItemsForContainer(formItems, (item as IPDRelationTabSpec).content, newRelPath);
						}
						break;
					case ItemTypeET.UIDrawer:
						(item as IUIDrawerSpec).drawerItems.forEach(drawerItem => {
							formItems.push({
								parent: (item as IUIDrawerSpec),
								item: drawerItem.content,
								relationPath: relationPath,
								property: this.getPropertyName(drawerItem.content)
							});
							this.storeItemsForContainer(formItems, drawerItem.content, newRelPath);
						});
						break;
					case ItemTypeET.UIRadioButtonGroup:
						if ((item as IUIRadioButtonGroupSpec).content) {
							this.storeItemsForContainer(formItems, (item as IUIRadioButtonGroupSpec).content, newRelPath);
						}
						break;
				}
			});
		}
		return formItems;
	}*/

	/*private getPropertyName(itemSpec: IUIItemSpec): string | undefined {
		switch (itemSpec.type) {
			case ItemTypeET.PDItem:
			case ItemTypeET.PDRadioButton:
				return (<IPDItemSpec>itemSpec).property;
			case ItemTypeET.UIRadioButtonGroup:
				return (<IUIRadioButtonGroupSpec>itemSpec).property;
			case ItemTypeET.PDRelationTab:
				return (<IPDRelationTabSpec>itemSpec).property;
			default:
				return undefined;
		}
	}*/

	/*private compareRelationPaths(locator1: UIItemLocator, locator2: UIItemLocator): boolean {
		let pos1 = locator1.value.lastIndexOf('.');
		let pos2 = locator2.value.lastIndexOf('.');
		if (pos1 == -1 && pos2 == -1) {
			return true;
		}
		if (pos1 == -1 || pos2 == -1) {
			return false;
		}
		return locator1.value.substring(0, pos1) === locator2.value.substring(0, pos2);
	}*/

	/*private determineRelationPath(itemSpec: IUIItemSpec, currentRelationPath?: string): string | undefined {
		let relName: string;
		switch (itemSpec.type) {
			case ItemTypeET.UIGroupbox:
			case ItemTypeET.UIPanel:
				{
					let containerSpec = <IToNRelationSuitableContainerSpec>itemSpec;
					if (containerSpec.toNRelationItemSpec) {
						relName = containerSpec.toNRelationItemSpec.relationProperty;
					}
					else if (containerSpec.toNRelationSpec) {
						relName = containerSpec.toNRelationSpec.relationProperty;
					}
					break;
				}
			case ItemTypeET.PDRelationTab:
				relName = (<IPDRelationTabSpec>itemSpec).property;
				break;
			case ItemTypeET.PDItem:
				if ((itemSpec as IPDItemSpec).to1RelationItemSpec) {
					relName = (itemSpec as IPDItemSpec).to1RelationItemSpec.relationProperty;
				}
				break;
		}

		if (relName) {
			return currentRelationPath ? `${currentRelationPath}.${relName}` : relName;
		}
		return currentRelationPath;
	}*/

	/*private deleteUIItem(conversion: UIItemConversion, formItems: UIItemInfo[]): void {
		if (conversion.delete !== true) {
			return;
		}
		let uiItemInfo = this._formSpecBuilder.findUIItem(conversion.locator, formItems);
		if (!uiItemInfo) {
			return;
		}

		let pos = uiItemInfo.parent.items.findIndex(item => item == uiItemInfo.item);
		uiItemInfo.parent.items.splice(pos, 1);
		pos = formItems.indexOf(uiItemInfo);
		formItems.splice(pos, 1);
	}*/

	// Todo: prüfen, ob das irgendwie nach FormSpecBuilder passt
	/*private updateUIItemProperties(conversion: UIItemConversion, formItems: UIItemInfo[]): void {
		let uiItemInfo = this._formSpecBuilder.findUIItem(conversion.locator, formItems);
		if (!uiItemInfo) {
			return;
		}

		if (conversion.uiStateExpression) {
			if (!uiItemInfo.item.uiStateExpressionData) {
				uiItemInfo.item.uiStateExpressionData = conversion.uiStateExpression;
			}
			else {
				uiItemInfo.item.uiStateExpressionData.disabled = conversion.uiStateExpression.disabled ?? uiItemInfo.item.uiStateExpressionData.disabled;
				uiItemInfo.item.uiStateExpressionData.hidden = conversion.uiStateExpression.hidden ?? uiItemInfo.item.uiStateExpressionData.hidden;
				uiItemInfo.item.uiStateExpressionData.mandatory = conversion.uiStateExpression.mandatory ?? uiItemInfo.item.uiStateExpressionData.mandatory;
				uiItemInfo.item.uiStateExpressionData.readonly = conversion.uiStateExpression.readonly ?? uiItemInfo.item.uiStateExpressionData.readonly;
			}
		}

		if (conversion.shortDescription) {
			uiItemInfo.item.shortDescription = conversion.shortDescription;
		}
	}*/

	/*private integrateUIState(conversion: UIItemConversion, formItems: UIItemInfo[]): void {
		if (!conversion.uiState) {
			return;
		}

		let uiItemInfo = this._formSpecBuilder.findUIItem(conversion.locator, formItems);
		if (!uiItemInfo) {
			return;
		}*/

		/**
		 * Entscheidet ob der Wert/State true oder false ist.
		 *
		 * Origin: true, Wanted: false -> true (true gewinnt immer)
		 * Origin: false, Wanted: true -> true (true gewinnt immer)
		 * Origin: true, Wanted: false + Overwrite: true -> false
		 */
		/*let determineUIState = (
			originState: boolean | undefined,
			wantedState: boolean | UIStateValue | undefined
		) => {
			let value = typeof(wantedState) === 'object' ? wantedState.value : wantedState;
			let overwrite = typeof(wantedState) === 'object' ? wantedState.overwriteOriginalState : false;

			if (overwrite) {
				return value;
			}
			return value ? true : originState;
		}

		let currentHandler = uiItemInfo.item.updateUIStateHandler;
		uiItemInfo.item.updateUIStateHandler = (source, currentState, ctx) => {
			let state: IComponentUIState;
			let originalState: IComponentUIState;
			if (currentHandler) {  // todo: overwriteOriginalState berücksichtigen!
				originalState = currentHandler(source, currentState, ctx);
			}

			if (conversion.uiState.hidden !== undefined || conversion.uiState.mandatory !== undefined ||
					conversion.uiState.readonly !== undefined || conversion.uiState.disabled !== undefined)
			{
				state = {};
				state.hidden = determineUIState(originalState?.hidden, conversion.uiState.hidden);
				state.mandatory = determineUIState(originalState?.mandatory, conversion.uiState.mandatory);
				state.readonly = determineUIState(originalState?.readonly, conversion.uiState.readonly);
				state.disabled = determineUIState(originalState?.disabled, conversion.uiState.disabled);
			}
			return state;
		}
	}*/

	/*private addNewUIItem(newItem: UIItemUsage, formItems: UIItemInfo[]) {
		let uiItemSpec = this._formSpecBuilder.createIUIItemSpec(newItem.item);
		this._formSpecBuilder.insertUIItem(newItem.placement, uiItemSpec, formItems);
	}*/

	/*private addNewUIItem(newItem: NewUIItemSpec, formItems: UIItemInfo[]) {
		let targetItem = this.findUIItem(newItem.placement.target, formItems);
		if (!targetItem) {
			return;
		}

		let itemSpec: IUIItemSpec;
		switch (newItem.itemType) {
			case ItemTypeET.PDItem: {
				itemSpec = <IPDItemSpec> {
					type: ItemTypeET.PDItem,
					property: newItem.property
				}
				switch (newItem.widget) {
					case WidgetET.TextField:
						(itemSpec as IPDItemSpec).widget = WidgetET.TextField;
						break;

					default:
						return;
				}
				break;
			}

			default:
				return;
		}

		let targetPos = targetItem.parent.items.findIndex(item => item === targetItem.item);
		if (newItem.placement.position === 'after') {
			targetPos++;
		}
		targetItem.parent.items.splice(targetPos, 0, itemSpec);
	}*/

	/*private moveUIItem(itemChange: UIItemChange, formItems: UIItemInfo[]): void {
		if (!itemChange.moveTo) {
			return;
		}

		let sourceItem = this._formSpecBuilder.findUIItem(itemChange.locator, formItems);
		if (!sourceItem) {
			return;
		}
		let targetItem = this._formSpecBuilder.findUIItem(itemChange.moveTo.target, formItems);
		if (!targetItem) {
			return;
		}
		if (!this.compareRelationPaths(itemChange.locator, itemChange.moveTo.target)) {
			return;
		}

		let sourcePos = sourceItem.parent.items.findIndex(item => item == sourceItem.item);
		sourceItem.parent.items.splice(sourcePos, 1);

		let targetPos = targetItem.parent.items.findIndex(item => item === targetItem.item);
		if (itemChange.moveTo.position === 'after') {
			targetPos++;
		}
		targetItem.parent.items.splice(targetPos, undefined, sourceItem.item);
		sourceItem.parent = targetItem.parent;
	}*/

	private omitUIItem(itemChange: UIItemChange, formItems: UIItemInfo[]): void {
		if (itemChange.omitItem === undefined) {
			return;
		}

		let sourceItem = this._formSpecBuilder.findUIItem(itemChange.locator, formItems);
		if (!sourceItem || !(sourceItem.item as IUIItemSpec).type) {
			return;
		}
		let itemSpec = sourceItem.item as IUIItemSpec;
		//itemSpec.omitItem = itemChange.omitItem;
		itemSpec.omitItemSpec = {
			value: itemChange.omitItem
		};
	}

	private adaptUIItem(itemData: IUIItemDataMutable, locator: UIItemLocator, formItems: UIItemInfo[]): void {
		if (!itemData) {
			return;
		}
		let itemInfo = this._formSpecBuilder.findUIItem(locator, formItems);
		if (!itemInfo) {
			console.warn(`'${locator.value}' not found in FormSpecConversionService.adaptUIItem()`)
			return;
		}
		if (!(itemInfo.item as IUIItemSpec).type) {
			console.warn(`Invalid ItemSpec in FormSpecConversionService.adaptUIItem()`)
			return;
		}
		let itemSpec = itemInfo.item as IUIItemSpec;
		this._formSpecBuilder.updateUIItemSpec(itemSpec, itemData, undefined); // todo: prüfen
	}

	/*private adaptWidget(conversion: UIItemConversion, formItems: UIItemInfo[]): void {
		if (!conversion.widget) {
			return;
		}
		let itemInfo = this._formSpecBuilder.findUIItem(conversion.locator, formItems);
		if (!itemInfo) {
			return;
		}

		let getOrCreateWidgetInfo = (): IWidgetInfo => {
			let wi = itemInfo.item.widgetInfo;
			if (!wi) {
				wi = {};
				itemInfo.item.widgetInfo = wi;
			}
			return wi;
		}*/

		//if (itemInfo.item.type === ItemTypeET.PDItem) {
		//	let widget = (itemInfo.item as IPDItemSpec).widget;
		//	switch (widget) {
				/*case WidgetET.MultiSelect:
				case WidgetET.ObjectReference:
				case WidgetET.RelationGrid:
					this.adaptRelationWidget(conversion, getOrCreateWidgetInfo(), widget);
					break;*/

				/*case WidgetET.TextField: {
					let wiTextField = <ITextFieldWidgetInfo>getOrCreateWidgetInfo();
					let wiAdaption = <ITextFieldWidgetAdaption>conversion.widget;
					if (wiAdaption.textTemplateSelection !== undefined) {
						wiTextField.textTemplateSelection = TextFieldWidgetInfo.createTextTemplateSelection(wiAdaption.textTemplateSelection);
					}
					if (wiAdaption.uiStateUpdateType) {
						wiTextField.uiStateUpdateType = wiAdaption.uiStateUpdateType;
					}
					if (wiAdaption.showClearButton === true || wiAdaption.showClearButton === false) {
						wiTextField.showClearButton = wiAdaption.showClearButton;
					}
					if (wiAdaption.multiline) {
						wiTextField.multiline = true;
						if (typeof(wiAdaption.multiline) === 'object') {
							if (wiAdaption.multiline.resizingType) {
								wiTextField.resizingType = wiAdaption.multiline.resizingType;
							}
							if (wiAdaption.multiline.rows !== undefined) {
								wiTextField.rows = wiAdaption.multiline.rows;
							}
						}
					}
					if (wiAdaption.spellcheck) {
						wiTextField.spellcheck = wiAdaption.spellcheck;
					}
					break;
				}*/

				/*case WidgetET.ComboBox: {
					let wiComboBox = <IComboBoxWidgetInfo>getOrCreateWidgetInfo();
					let wiAdaption = <IComboBoxWidgetAdaption>conversion.widget;
					if (wiAdaption.spellcheck) {
						wiComboBox.spellcheck = wiAdaption.spellcheck;
					}
					break;
				}*/
			//}
		//}
		/*else if (itemInfo.item.type === ItemTypeET.UIDrawer) {
			this.adaptDrawerWidget(<IDrawerWidgetAdaption>conversion.widget, itemInfo.item as IUIDrawerSpec);
		}*/
		/*else if (itemInfo.item.type === ItemTypeET.UIGroupbox) {
			let wiAdaption = <IGroupboxWidgetAdaption>conversion.widget;
			let uiGroupboxSpec = itemInfo.item as IUIGroupboxSpec;
			if (wiAdaption.toNRelationSpec?.maxRelationObjects !== undefined) {
				if (uiGroupboxSpec.toNRelationSpec) {
					uiGroupboxSpec.toNRelationSpec.maxRelationObjects = wiAdaption.toNRelationSpec.maxRelationObjects;
				}
			}
			this.adaptContainerWidget(conversion.widget, uiGroupboxSpec);
		}*/
		/*else if (itemInfo.item.type === ItemTypeET.UIPanel) {
			this.adaptContainerWidget(conversion.widget, itemInfo.item as IUIPanelSpec);
		}*/
		/*else if (itemInfo.item.type === ItemTypeET.PDRelationTab) {
			let wiAdaption = <IRelationTabWidgetAdaption>conversion.widget;
			let wiRelTab = <IRelationTabWidgetInfo>getOrCreateWidgetInfo();
			if (wiAdaption.maxTabCount !== undefined) {
				wiRelTab.maxTabCount = wiAdaption.maxTabCount;
			}
			if (wiAdaption.canCreateTab !== undefined) {
				wiRelTab.canCreateTab = wiAdaption.canCreateTab;
			}
			if (wiAdaption.canDeleteTab !== undefined) {
				wiRelTab.canDeleteTab = wiAdaption.canDeleteTab;
			}
		}*/
	//}

	/*private adaptContainerWidget(wiAdaption: IContainerWidgetAdaption, uiContSpec: IUIContainerSpec): void {
		if (wiAdaption.layout) {
			uiContSpec.layout = LayoutSpec.createILayoutSpec(wiAdaption.layout);
		}
	}*/

	/*private adaptDrawerWidget(wiAdaption: IDrawerWidgetAdaption, uiDrawerSpec: IUIDrawerSpec): void {
		if (wiAdaption.newItems) {
			wiAdaption.newItems.forEach(item => {
				let spec = UIDrawerItemSpec.createIUIDrawerItemSpec(item.drawerItem);
				switch (item.position) {
					case 'start':
						uiDrawerSpec.drawerItems.splice(0, 0, spec);
						break;
					case undefined:
					case 'end':
						uiDrawerSpec.drawerItems.push(spec);
						break;
					default:
						if (item.position >= 0 && item.position < uiDrawerSpec.drawerItems.length) {
							uiDrawerSpec.drawerItems.splice(item.position, 0, spec);
						}
						break;
				}
				if (spec.item.selected) {
					uiDrawerSpec.drawerItems.filter(di => di !== spec).forEach(di => di.item.selected = false);
				}
			});
		}
		if (wiAdaption.items) {
			wiAdaption.items.forEach(itemAdaption => {
				let itemSpecIndex = uiDrawerSpec.drawerItems.findIndex(spec => spec.item.id === itemAdaption.itemId);
				if (itemSpecIndex >= 0) {
					let itemSpec = uiDrawerSpec.drawerItems[itemSpecIndex];
					if (itemAdaption.icon)	{
						itemSpec.item.icon = itemAdaption.icon;
					}
					if (itemAdaption.text) {
						itemSpec.item.text = itemAdaption.text.de; // todo
					}
					if (itemAdaption.moveTo) {
						switch (itemAdaption.moveTo.position) {
							case 'start':
								uiDrawerSpec.drawerItems.splice(itemSpecIndex, 1);
								uiDrawerSpec.drawerItems.unshift(itemSpec);
								break;
							case 'end':
								uiDrawerSpec.drawerItems.splice(itemSpecIndex, 1);
								uiDrawerSpec.drawerItems.push(itemSpec);
								break;
							default: {
								let targetSpecIndex = uiDrawerSpec.drawerItems.findIndex(spec => spec.item.id === itemAdaption.moveTo.targetId);
								if (targetSpecIndex >= 0) {
									uiDrawerSpec.drawerItems.splice(itemSpecIndex, 1);
									targetSpecIndex = uiDrawerSpec.drawerItems.findIndex(spec => spec.item.id === itemAdaption.moveTo.targetId);
									if (itemAdaption.moveTo.position === 'after') {
										targetSpecIndex++;
									}
									uiDrawerSpec.drawerItems.splice(targetSpecIndex, 0, itemSpec);
								}
								break;
							}
						}
					}
					if (itemAdaption.shortDescription) {
						itemSpec.item.shortDescription = itemAdaption.shortDescription;
					}
					if (itemAdaption.uiState) {
						// FIXME: integrateUIState macht sowas eigentlich. Da es allerdings
						// kurz vorm release war, wollte ich den "alten" Code nicht ändern
						const determineUIState = (
							originState: boolean | undefined,
							wantedState: boolean | UIStateValue | undefined
						) => {
							let value = typeof(wantedState) === 'object' ? wantedState.value : wantedState;
							let overwrite = typeof(wantedState) === 'object' ? wantedState.overwriteOriginalState : false;

							if (overwrite) {
								return value;
							}
							return value ? true : originState;
						}

						const currentHandler = itemSpec.content.updateUIStateHandler;
						itemSpec.content.updateUIStateHandler = (source, currentState, ctx) => {
							let state: IComponentUIState;
							let originalState: IComponentUIState;
							if (currentHandler) {  // todo: overwriteOriginalState berücksichtigen!
								originalState = currentHandler(source, currentState, ctx);
							}

							if (itemAdaption.uiState.hidden !== undefined || itemAdaption.uiState.mandatory !== undefined ||
									itemAdaption.uiState.readonly !== undefined || itemAdaption.uiState.disabled !== undefined)
							{
								state = {};
								state.hidden = determineUIState(originalState?.hidden, itemAdaption.uiState.hidden);
								state.mandatory = determineUIState(originalState?.mandatory, itemAdaption.uiState.mandatory);
								state.readonly = determineUIState(originalState?.readonly, itemAdaption.uiState.readonly);
								state.disabled = determineUIState(originalState?.disabled, itemAdaption.uiState.disabled);
							}
							return state;
						}
					}
				}
			});
		}
	}*/

	//private adaptRelationWidget(conversion: UIItemConversion, wiRel: IRelationControlWidgetInfo, widget: WidgetET): void {
		//let wiAdaptionRel = <IRelationWidgetAdaption>conversion.widget;

		/*if (wiAdaptionRel.createObject && wiRel.editingSpec) {
			wiRel.newAction = wiAdaptionRel.createObject.enabled;
			if (wiAdaptionRel.createObject.enabled) {
				wiRel.editingSpec.remoteCreationParams = wiAdaptionRel.createObject.params;
			}
		}

		if (wiAdaptionRel.editingSpec && wiAdaptionRel.editingSpec) {
			if (wiAdaptionRel.editingSpec.objectInfo) {
				wiRel.editingSpec.objectInfo = wiAdaptionRel.editingSpec.objectInfo;
			}
		}
		if (wiAdaptionRel.selectAction !== undefined) {
			wiRel.selectAction = wiAdaptionRel.selectAction === true;
		}*/

		//switch (widget) {
			/*case WidgetET.MultiSelect: {
				let wiMultiSelect = <IMultiSelectRelationWidgetInfo>wiRel;
				let wiAdaptionMultiSelect = <IMultiSelectWidgetAdaption>wiAdaptionRel;
				if (wiAdaptionRel.removeCustomChoiceProvider === true) {
					wiMultiSelect.customChoiceProvider = undefined;
				}
				if (wiAdaptionRel.choiceSpec) {
					//wiMultiSelect.customChoiceFilterProvider = undefined;
					//wiMultiSelect.customChoiceProvider = undefined;
					let choiceSpec: IPDChoiceSpec = {
						className: wiAdaptionRel.choiceSpec.className
					}
					if (wiAdaptionRel.choiceSpec.filterExpr) {
						choiceSpec.filterExpr = wiAdaptionRel.choiceSpec.filterExpr;
					}
					if (wiAdaptionRel.choiceSpec.sortExpr) {
						choiceSpec.sortExpr = wiAdaptionRel.choiceSpec.sortExpr;
					}
					if (wiAdaptionRel.choiceSpec.objectInfo) {
						choiceSpec.relationInfo = wiAdaptionRel.choiceSpec.objectInfo;
					}
					wiMultiSelect.choiceSpec = choiceSpec;
				}
				if (wiAdaptionRel.selectionListSpec) {
					wiMultiSelect.selectionListSpec = RelationControlWidgetInfo.createIPDObjectSelectionListSpec(wiAdaptionRel.selectionListSpec);
				}
				if (wiAdaptionMultiSelect.showClearButton === true || wiAdaptionMultiSelect.showClearButton === false) {
					wiMultiSelect.showClearButton = wiAdaptionMultiSelect.showClearButton;
				}
				if (wiAdaptionMultiSelect.type) {
					wiMultiSelect.type = wiAdaptionMultiSelect.type;
				}
				if (wiAdaptionMultiSelect.editableRelationProperty) {
					wiMultiSelect.editableRelationProperty = wiAdaptionMultiSelect.editableRelationProperty;
				}
				if (wiAdaptionMultiSelect.textFieldSpec) {
					wiMultiSelect.textFieldSpec = wiAdaptionMultiSelect.textFieldSpec;
				}
				if (wiAdaptionMultiSelect.textTemplateSelection !== undefined) {
					wiMultiSelect.textTemplateSelection = TextFieldWidgetInfo.createTextTemplateSelection(wiAdaptionMultiSelect.textTemplateSelection);
				}
				break;
			}*/

			/*case WidgetET.ObjectReference: {
				let wiObjRef = wiRel as IObjectReferenceWidgetInfo;
				let wiAdaptionObjRef = <IObjectReferenceWidgetAdaption>wiAdaptionRel;
				if (wiAdaptionRel.removeCustomChoiceProvider === true) {
					wiObjRef.customChoiceProvider = undefined;
				}
				if (wiAdaptionRel.choiceSpec) {
					//wiObjRef.customChoiceFilterProvider = undefined;
					//wiObjRef.customChoiceProvider = undefined;
					let choiceSpec: IPDChoiceSpec = {
						className: wiAdaptionRel.choiceSpec.className
					}
					if (wiAdaptionRel.choiceSpec.filterExpr) {
						choiceSpec.filterExpr = wiAdaptionRel.choiceSpec.filterExpr;
					}
					if (wiAdaptionRel.choiceSpec.sortExpr) {
						choiceSpec.sortExpr = wiAdaptionRel.choiceSpec.sortExpr;
					}
					if (wiAdaptionRel.choiceSpec.objectInfo) {
						choiceSpec.relationInfo = wiAdaptionRel.choiceSpec.objectInfo;
					}
					wiObjRef.choiceSpec = choiceSpec;
				}
				if (wiAdaptionRel.selectionListSpec) {
					wiObjRef.selectionListSpec = RelationControlWidgetInfo.createIPDObjectSelectionListSpec(wiAdaptionRel.selectionListSpec);
				}
				if (wiAdaptionObjRef.type) {
					wiObjRef.type = wiAdaptionObjRef.type;
				}
				if (wiAdaptionObjRef.editableRelationProperty) {
					wiObjRef.editableRelationProperty = wiAdaptionObjRef.editableRelationProperty;
				}
				if (wiAdaptionObjRef.textFieldSpec) {
					wiObjRef.textFieldSpec = wiAdaptionObjRef.textFieldSpec;
				}
				break;
			}*/

			/*case WidgetET.RelationGrid: {
				let wiRelGrid = <IRelationGridWidgetInfo>wiRel;
				let wiAdaptionRelGrid = <IRelationGridWidgetAdaption>wiAdaptionRel;
				if (wiAdaptionRelGrid.quickCreationEnabled !== undefined) {
					wiRelGrid.quickCreationEnabled = wiAdaptionRelGrid.quickCreationEnabled;
				}
				break;
			}*/
		//}
	//}
}

@Injectable({ providedIn: 'root', useClass: FormSpecConversionService })
export abstract class IFormSpecConversionService {

	//abstract get formSpecConverted(): boolean;

	//abstract getRelationPath(locator: UIItemLocator): string | undefined;

	abstract convertFormSpec(formSpec: IUIFormSpec, className: string,
		conversionDataProvider: (param: object) => Observable<IFormSpecConversionData | undefined> | undefined,
		conversionProviderParam: object): Observable<IUIFormSpec>;

	abstract convertFormSpecs(formSpecs: [string, IUIFormSpec][],
		conversionDataProvider: (param: object) => Observable<IFormSpecConversionData | undefined>,
		conversionProviderParam: object): Observable<[string, IUIFormSpec][]>;

	//abstract determineUIItemInfos(formSpec: IUIFormSpec): UIItemInfo[];

	//abstract findUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo | undefined;

	//abstract insertUIItem(itemLocator: UIItemLocator, position: UIItemPositioning, uiItemSpec: IUIItemSpec, formItems: UIItemInfo[]): void;
}
