import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	ChangeDetectionStrategy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ComponentTypeET,
	IDrawerComponent,
	IFormStatus,
	IUIDrawerItem,
	DrawerModeET,
	DrawerExpandModeT,
	DrawerPositionT,
	IPanelComponent,
	IDrawerItemComponent
} from '@otris/ng-core-types';
import { PDObject } from '@otris/ng-core-shared';
import {
	FormHandlerService,
	UIAbstractComponent,
	UIDrawerSpec,
	UIDrawerItemSpec,
	EventProcessingContextManagerService
} from '@otris/ng-core';
//import * as ngCoreTypesModule from '@otris/ng-core-types';

// { type: 'slide', direction: position=='start' ?  'right' : 'left', duration: 25 }
// (anchorViewportLeave)="expanded=false" (positionChange)="onPopupPositionChange()"
@Component({
	selector: 'otris-pd-drawer',
	template: `
		<div #rootContainer
			class="root-container"
			[style.flex-direction]="position=='start' ? 'row' : 'row-reverse'"
		>
			@if (expandMode=='push' || !expanded) {
				<ng-container *ngTemplateOutlet="drawerItemList"></ng-container>
			} @else {
				@if (drawerItemsVisible) {
					<ul class="item-list">
						@for (item of drawerItems; track item; let i = $index) {
							<otris-pd-drawer-item #drawerItem2
								[drawerComponent]="this"
								[drawerItemSpec]="drawerItemSpecs[i]"
								[drawerItem]="item"
								[mini]="true"
								[id]="getId() + '.itemListPlaceholder' + item.id"
							/>
						}
					</ul>
				}
			}
			<!-- (uiStateChanged)="onPanelUIStateChanged(i, $event)" -->
			<div class="content-container otris-pd-drawer-content">
				@for (itemSpec of drawerItemSpecs; track itemSpec; let i = $index) {
					<otris-pd-panel #itemPanel
						class="pd-panel"
						[ngClass]="{'panel-hidden': selectedIndex!==i}"
						[uiItemSpec]="itemSpec.content"
						[formGroup]="formGroup"
						[pdObject]="pdObject"
						[relationContext]="relationContext"
						[idPostfix]="'content.' + itemSpec.item.id"
					/>
				}
			</div>
		</div>

		@if (this.expandMode=='overlay' && this.expanded) {
			<kendo-popup
				[anchor]="rootContainerRef"
				[animate]="false" [popupClass]="'otris-overlay'"
				[anchorAlign]="{ horizontal: position=='start' ? 'left' : 'right', vertical: 'top' }"
				[popupAlign]="{ horizontal: position=='start' ? 'left' : 'right', vertical: 'top' }"
			>
				<div class='popup-root'
					[style.flex-direction]="position=='start' ? 'row' : 'row-reverse'"
					[style.height.px]="rootContainerHeight"
					[style.width.px]="rootContainerWidth"
				>
					<ng-container *ngTemplateOutlet="drawerItemList"></ng-container>
				</div>
			</kendo-popup>
		}

		<ng-template #drawerItemList>
			@if (drawerItemsVisible) {
				<ul class="item-list"
					[style.margin]="position=='start' ? '0 0.5em 0 0' : '0 0 0 0.5em'"
					(mouseenter)="onItemListMouseEnter()"
					(mouseleave)="onItemListMouseLeave()"
				>
				@for (item of drawerItems; track item; let i = $index) {
  				<otris-pd-drawer-item #drawerItem
						[drawerComponent]="this"
						[drawerItemSpec]="drawerItemSpecs[i]"
						[drawerItem]="item"
						[mini]="mode==drawerModeET.Compact || mode==drawerModeET.CompactAutoExpand"
						[disabled]="isDisabled"
						[expanded]="expanded"
						(itemSelected)="onItemSelected($event)"
						[id]="getId() + '.drawerItemList.' + item.id"
					/>
				}
			</ul>
			}
		</ng-template>

		<ng-template #itemListPlaceholder>
			@if (drawerItemsVisible) {
				<ul class="item-list">
					@for (item of drawerItems; track item; let i = $index) {
  					<otris-pd-drawer-item #drawerItem2
							[drawerComponent]="this"
							[drawerItemSpec]="drawerItemSpecs[i]"
							[drawerItem]="item"
							[mini]="true"
							[id]="getId() + '.itemListPlaceholder' + item.id"
						/>
					}
				</ul>
			}
		</ng-template>
	`,
	styles: [`
		.root-container {
			width: 100%;
			height: 100%;
			display: flex;
		}
		.item-list {
			list-style: none;
			margin: 0 0.5em 0 0;
			padding: 0;
			background-color: white;
		}
		.content-container {
			display: flex;
			flex: 1;
			background-color: gainsboro;
			padding-left: 1em;
		}
		.pd-panel {
			flex: 1;
		}
		.panel-hidden {
			display: none;
		}
		.popup-root {
			display: flex;
		}
	`]
})
export class PDDrawerComponent extends UIAbstractComponent implements IDrawerComponent {

	/*@HostListener('document:click', ['$event'])
	public documentClick(event: any): void {
		 if (!this.contains(event.target)) {
			//this.showPopup = false;
			this.expanded = false;
		 }
	}*/

	@Input() pdObject: PDObject;

	@Input() mode = DrawerModeET.Normal;

	@Input() expanded = false;

	@Input() expandMode: DrawerExpandModeT = 'push';

	@Input() position: DrawerPositionT = 'start';

	@Output() itemSelected = new EventEmitter<IUIDrawerItem>();

	//drawerModeET = (ngCoreTypesModule as any).DrawerModeET;
	drawerModeET = DrawerModeET;

	@ViewChild('rootContainer') rootContainerRef: ElementRef;

	@ViewChildren('itemPanel') itemPanels: QueryList<IPanelComponent>;

	@ViewChildren('drawerItem') drawerItemComponents: QueryList<IDrawerItemComponent>;

	@ViewChildren('drawerItem2') drawerItemComponents2: QueryList<IDrawerItemComponent>;

	get drawerItemSpecs(): UIDrawerItemSpec[] {
		return this.uiDrawerSpec.drawerItems;
	}

	get drawerItemsVisible(): boolean {
		return this.mode != DrawerModeET.Normal || this.expanded;
	}

	get selectedItem(): IUIDrawerItem | undefined {
		//let item = this.drawerItemSpecs.find(i => i.item.selected);
		//return item ? item.item : undefined;

		let item = this._drawerItems.find(i => i.selected);
		return item ? item : undefined;
	}

	get selectedIndex(): number {
		//return this.drawerItemSpecs.findIndex(i => i.item.selected);
		return this._drawerItems.findIndex(i => i.selected);
	}

	get rootContainerHeight(): number {
		if (this.rootContainerRef && this.rootContainerRef.nativeElement) {
			return this.rootContainerRef.nativeElement.offsetHeight;
		}
		return 0;
	}

	get rootContainerWidth(): number {
		if (this.rootContainerRef && this.rootContainerRef.nativeElement) {
			return this.rootContainerRef.nativeElement.offsetWidth;
		}
		return 0;
	}

	private get uiDrawerSpec(): UIDrawerSpec {
		return this.uiItemSpec as UIDrawerSpec;
	}

	get isContainerComponent(): boolean {
		return true;
	}

	//private drawerItems: Map<string, IUIDrawerItem> = new Map();
	private _drawerItems: IUIDrawerItem[] = [];

	get drawerItems(): IUIDrawerItem[] {
		return this._drawerItems;
	}

	constructor(router: Router, route: ActivatedRoute, private _cdref: ChangeDetectorRef,
		formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.uiDrawerSpec.mode) {
			this.mode = this.uiDrawerSpec.mode;
		}
		if (this.uiDrawerSpec.expandMode) {
			this.expandMode = this.uiDrawerSpec.expandMode;
		}
		if (this.uiDrawerSpec.position) {
			this.position = this.uiDrawerSpec.position;
		}
		if (this.uiDrawerSpec.expanded !== undefined && this.mode != DrawerModeET.CompactAutoExpand) {
			this.expanded = this.uiDrawerSpec.expanded;
		}
		this.uiDrawerSpec.drawerItems.forEach(i => {
			//this.drawerItems.set(i.item.id, Object.assign({}, i.item));
			this._drawerItems.push(Object.assign({}, i.item));
		});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.itemPanels.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<IPanelComponent>);
		});

		this.createChildComponentUIStateProviders(this.itemPanels);

		let updatePanelUIStateProvider = () => {
			this.drawerItemComponents.forEach(comp => {
				comp.relatedPanelComponent.registerUIStateProvider(() => {
					return comp.uiState;
				}, true, this);
			});
		}

		this.itemPanels.changes.subscribe(() => this.updateDrawerItemComponents(true));
		this.drawerItemComponents.changes.subscribe(() => {
			this.updateDrawerItemComponents(true);
			updatePanelUIStateProvider();
		});
		this.drawerItemComponents2.changes.subscribe(() => this.updateDrawerItemComponents(true));
		this.updateDrawerItemComponents(false);
		updatePanelUIStateProvider();

		let selItem = this.selectedItem;
		if (selItem) {
			this.emitItemSelectedEvent(selItem);
		}

		this.handelChangeDetectionContent();
	}

	private handelChangeDetectionContent(): void {
		this.itemPanels.forEach((panel, index) => {
			panel.activateChangeDetection(index === this.selectedIndex);
		})
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.itemPanels) {
			this.itemPanels.forEach(pc => {
				let panelStat = pc.getFormControlStatus();
				if (!panelStat.pristine) {
					stat.pristine = false;
				}
				if (panelStat.touched) {
					stat.touched = true;
				}
				if (!panelStat.valid) {
					stat.valid = false;
				}
			});
		}
		return stat;
	}

	onItemListMouseEnter(): void {
		//this.showPopup = true;
		if (this.mode == DrawerModeET.CompactAutoExpand) {
			this.expanded = true;
		}
	}

	onItemListMouseLeave(): void {
		if (this.mode == DrawerModeET.CompactAutoExpand) {
			this.expanded = false;
		}
	}

	onItemSelected(item: IUIDrawerItem) {
		if (item != this.selectedItem) {
			/*this.drawerItemSpecs.filter(i => i.item.selected === true)
				.forEach(i => {
					i.item.selected = false;
				});*/
			this._drawerItems.filter(i => i.selected === true).forEach(i => i.selected = false);
			item.selected = true;
			this.handelChangeDetectionContent();
			this.emitItemSelectedEvent(item);
		}
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (this.drawerItemComponents) {
			this.drawerItemComponents.forEach(comp => {
				comp.updateUIState();
			});
		}
		if (!onlySelf) {
			this.itemPanels.forEach(p => p.updateUIState(false));
		}
	}

	private updateDrawerItemComponents(callDetectChanges: boolean): void {
		this.drawerItemComponents.forEach((item, index) => {
			item.relatedPanelComponent = this.itemPanels.get(index);
		});
		this.drawerItemComponents2.forEach((item, index) => {
			item.relatedPanelComponent = this.itemPanels.get(index);
		});
		if (callDetectChanges) {
			this._cdref.detectChanges();
		}
	}

	/*onPanelUIStateChanged(panelIndex: number, uiStates: [IComponentUIState, IComponentUIState]): void {
		if (uiStates[0].hidden !== uiStates[1].hidden) {
			let drawerItem = this._drawerItems[panelIndex];
			drawerItem.hidden = uiStates[1].hidden !== undefined ? uiStates[1].hidden : undefined;
		}
	}*/

	private emitItemSelectedEvent(item: IUIDrawerItem): void {
		this.itemSelected.emit(item);
		if (this.uiDrawerSpec.onItemSelected) {
			let ctx = this.createEventProcessingContext();
			this.uiDrawerSpec.onItemSelected(ctx, item);
		}
	}

	//----------------------------------------------------------------------------------------------
	// IDrawerComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Drawer;
	}

	reset(): void {
		this.itemPanels.forEach(p => p.reset());
	}
}
