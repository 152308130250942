import { Injectable } from "@angular/core";
import { IUIFormSpec, IUIItemData, IUIItemDataMutable, IUIItemSpec, UIItemInfo, UIItemLocation, UIItemLocator, UIItemUsage } from "@otris/ng-core-types";

@Injectable(/*{ providedIn: 'root', useClass: FormSpecBuilder }*/)
export abstract class IFormSpecBuilder {
	abstract findUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo | undefined;

	abstract insertUIItem(location: UIItemLocation, uiItemSpec: IUIItemSpec, formItems: UIItemInfo[]): UIItemInfo | undefined;

	abstract deleteUIItem(locator: UIItemLocator, formItems: UIItemInfo[]): UIItemInfo[];

	abstract createNewUIItem(newItem: UIItemUsage, uiItemDefs: Map<string, IUIItemSpec>, formItems: UIItemInfo[]): UIItemInfo;

	abstract moveUIItem(source: UIItemLocator, moveTo: UIItemLocation, formItems: UIItemInfo[]): UIItemInfo | undefined;

	abstract getItemSpecPropertyName(itemSpec: IUIItemSpec): string | undefined;

	abstract getItemLocatorRelationPath(locator: UIItemLocator): string | undefined;

	abstract parseFormSpec(formSpec: IUIFormSpec): UIItemInfo[];

	abstract createUIItemSpec(data: IUIItemData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec;

	abstract updateUIItemSpec(spec: IUIItemSpec, data: IUIItemDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec;
}