import { Component, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

import {
	PDLabeledControlComponent,
	FormHandlerService,
	EventProcessingContextManagerService,
	ListviewWidgetInfo
} from '@otris/ng-core';
import {
	ComponentTypeET,
	IComponent,
	IListviewComponent
} from '@otris/ng-core-types';

@Component({
	selector: 'otris-pd-listview',
	template: `
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
			[id]="getId() + '.listview'"
		>

			<kendo-listview
				[data]="items"
				class="listview"
				containerClass="k-d-flex k-flex-col k-flex-nowrap"
			>

				<!-- TODO: Templates austauschbar machen -->
				<ng-template
					 kendoListViewItemTemplate
					 let-dataItem="dataItem"
					 let-index="index"
					 let-isFirst="isFirst"
					 let-isLast="isLast"
				>
					<div class="item-container">
						<i class="fa fa-fw fa-star item-icon"></i>
						<div class="item-name">
							<!-- <span>{{dataItem[valueField]}}</span> -->
							<span>{{getTextField(dataItem)}}</span>
						</div>
					</div>
				</ng-template>

        </kendo-listview>
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		.listview {
			display: flex;
			flex: 1;
			padding: 0.25em;
			max-height: 8em;
		}
		/* ng-template */
		.item-container {
			display: flex;
			padding: 0.25em;
		}
		.item-icon {
			margin-right: 0.5em;
			align-self: center;
		}
	`]
})
export class PDListviewComponent extends PDLabeledControlComponent implements IListviewComponent, AfterViewChecked {

	private _textField = "";

	get textField(): string {
		return this._textField;
	}

	private _valueField = "";

	get valueField(): string {
		return this._valueField;
	}

	private _items: Object[] = [];

	get items(): Object[] {
		return this._items;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		private cdref: ChangeDetectorRef,
		eventProcessingContextManagerService: EventProcessingContextManagerService)
	{
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();

		this._itemsChangedUIStateSubject$ = new BehaviorSubject(this.control?.value);
		if (this.control?.value) {
			this._itemsChangedUIStateSubject$.next(this.control.value)
		}

		let wi = this.listviewWidgetInfo;
		if (wi) {
			if (wi.textField) {
				this._textField = wi.textField;
			}
			if (wi.valueField) {
				this._valueField = wi.valueField;
			}
		}
	}

	ngAfterViewChecked() {
	}

	getTextField(item: Object) {
		return item[this._textField];
	}

	get listviewWidgetInfo(): ListviewWidgetInfo | undefined {
		let wi = this.labeledControlWidgetInfo;
		if (wi instanceof ListviewWidgetInfo) {
			return <ListviewWidgetInfo>wi;
		}
		return undefined;
	}

	// protected onLanguageChanged() {
	// }

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		if (this.pdObject) {
			this._items = this.pdObject.pdObjectRaw[this.propertyName] ?? [];
		}
	}

	private _itemsChangedUIStateSubject$
		= new BehaviorSubject<any>(undefined);

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._itemsChangedUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	reset(): void {
		super.reset();
	}

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Listview;
	}

	get isContainerComponent(): boolean {
		return false;
	}
}
