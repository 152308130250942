import { Injectable } from "@angular/core";
import { MenduzEbnfParser } from "../model/menduz-ebnf-parser";

export type ASTToken = {
	type: string;
	text: string;
	children: ASTToken[];
	start: number;
	end: number;
	errors: ASTTokenError[];
}

export type ASTTokenError = {
	message: string;
	//token: ASTToken; todo
}

export interface IGrammarParser {
	parse(expr: string, targetRule: string): ASTToken | undefined;
}

@Injectable()
export class GrammarParserFactory implements IGrammarParserFactory {
	createEbnfParser(grammar: string): IGrammarParser {
		return new MenduzEbnfParser(grammar);
	}
}

@Injectable({ providedIn: 'root', useClass: GrammarParserFactory })
export abstract class IGrammarParserFactory {
	abstract createEbnfParser(grammar: string): IGrammarParser;
}
