import { Inject, Injectable, InjectionToken, inject } from "@angular/core";
import { IPDAccessServiceToken } from "@otris/ng-core-shared";
import { IPDAccessService, IPDMeta } from "@otris/ng-core-types";
import { Observable, map } from "rxjs";


export const IPDMetaToken = new InjectionToken<IPDMeta>(
	'IPDMeta',
	{
		providedIn: 'root',
		factory: () => new PDMeta(
			inject(IPDMetaImplementationToken),
			inject(IPDAccessServiceToken)
		)
	}
);

export const IPDMetaImplementationToken = new InjectionToken<IPDMetaImplementation>('IPDMetaImplementation');


@Injectable()
export abstract class IPDMetaImplementation {
	abstract getRelationClassName(cls: string, relationPath: string): string | undefined;
}

@Injectable()
export class PDMeta implements IPDMeta {

	private _subClasses: Map<string, string[]> = new Map();

	constructor(
		@Inject(IPDMetaImplementationToken) private _pdMetaImplementation: IPDMetaImplementation,
		@Inject(IPDAccessServiceToken) private _pdAccessService: IPDAccessService
	) {}

	init(): Observable<void> {
		return this._pdAccessService.getSubClasses().pipe(
			map(res => {
				res.forEach(val => {
					this._subClasses.set(val[0], val[1]);
				});
			})
		);
	}

	getSubClasses(baseClasses: string[]): [string, string[]][] {
		let res: [string, string[]][] = [];
		baseClasses.forEach(cls => {
			/*if (!this._subClasses.has(cls)) {
				throw new Error(`Subclasses for class ${cls} not found.`)
			}*/
			if (this._subClasses.has(cls)) {
				res.push([cls, this._subClasses.get(cls)]);
			}
		});
		return res;
	}

	getRelationClassName(cls: string, relationPath: string): string | undefined {
		let relCls = cls;
		let relParts = relationPath.split('.');
		relParts.forEach(relPart => {
			if (relCls) {
				relCls = this._pdMetaImplementation.getRelationClassName(relCls, relPart);
			}
		});
		return relCls;
	}
}
