import { ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import {
	ComponentUIStateExpression,
	CreateRelationObjectCallback,
	//CustomChoiceProvider,
	CustomChoiceProviderData,
	//CustomEventHandler,
	DrawerExpandModeT,
	DrawerModeET,
	DrawerPositionT,
	ErgNameData,
	EventHandlerCommandsData,
	PDExpansionPanelExpandedProvider,
	//ExpressionOptions,
	FlexAlignContentET,
	FlexAlignET,
	FlexDirectionET,
	FlexJustifyContentET,
	FlexWrapET,
	//ForeignComponentEventSpec,
	IAccordionWidgetInfo,
	IButtonWidgetInfo,
	ICheckBoxComponent,
	ICheckboxWidgetInfo,
	IColumnSpec,
	IComboBoxComponent,
	IComboBoxWidgetData,
	//IComboBoxWidgetDataMutable,
	IComboBoxWidgetInfo,
	IComponent,
	IComponentUIState,
	ICssStyle,
	ICustomChoiceProviderResult,
	IDateTimeFieldComponent,
	IDateTimeFieldWidgetData,
	//IDateTimeFieldWidgetDataMutable,
	IDateTimeFieldWidgetInfo,
	//IDrawerItemComponent,
	IDropDownComponent,
	IDropDownWidgetInfo,
	IEnumerationItem,
	IEventProcessingContext,
	IFileUploadWidgetData,
	IFileUploadWidgetDataMutable,
	IFileUploadWidgetInfo,
	IFlexItemPlacement,
	IFlexLayoutData,
	IFlexLayoutSpec,
	IGridItemPlacement,
	IGridLayoutData,
	IGridLayoutSpec,
	IInfoFieldWidgetInfo,
	IInputSwitchWidgetInfo,
	IInteractionService,
	ILabeledControlComponent,
	ILabeledControlWidgetData,
	//ILabeledControlWidgetDataMutable,
	ILabeledControlWidgetInfo,
	//ILabelWidgetData,
	//ILabelWidgetDataMutable,
	ILabelWidgetInfo,
	ILayoutData,
	ILayoutSpec,
	IListviewComponent,
	IListviewWidgetInfo,
	ILocalizationService,
	IMainUIService,
	IMultiSelectComponent,
	IMultiSelectRelationWidgetData,
	//IMultiSelectRelationWidgetDataMutable,
	IMultiSelectRelationWidgetInfo,
	INumericTextFieldComponent,
	//INumericTextFieldWidgetData,
	//INumericTextFieldWidgetDataMutable,
	INumericTextFieldWidgetInfo,
	IObjectReferenceComponent,
	IObjectReferenceWidgetData,
	//IObjectReferenceWidgetDataMutable,
	IObjectReferenceWidgetInfo,
	IPDChoiceSpec,
	IPDExpansionPanelSpec,
	IPDItemData,
	//IPDItemDataMutable,
	IPDItemSpec,
	IPDListColumnInfo,
	IPDObject,
	IPDObjectSelectionListSpec,
	IPDRadioButtonSpec,
	IPDRelationTabComponent,
	//IPDRelationTabDataMutable,
	IPDRelationTabSpec,
	IRadioButtonComponent,
	IRadioButtonGroupComponent,
	IRadioButtonGroupWidgetInfo,
	//IRadioButtonWidgetData,
	//IRadioButtonWidgetDataMutable,
	IRadioButtonWidgetInfo,
	IRelationComponent,
	IRelationControlWidgetData,
	//IRelationControlWidgetDataMutable,
	IRelationControlWidgetInfo,
	IRelationGridComponent,
	//IRelationGridWidgetDataMutable,
	//IRelationGridWidgetInfo,
	IRelationTabHeader,
	//IRelationTabWidgetData,
	//IRelationTabWidgetDataMutable,
	IRelationTabWidgetInfo,
	IRowSpec,
	IStyleSpec,
	ItemTypeET,
	ITextFieldComponent,
	ITextFieldWidgetData,
	//ITextFieldWidgetDataMutable,
	ITextFieldWidgetInfo,
	ITo1RelationItemSpec,
	IToNRelationItemSpec,
	IToNRelationSpec,
	//IToNRelationSuitableContainerData,
	//IToNRelationSuitableContainerDataMutable,
	IToNRelationSuitableContainerSpec,
	IToolBarItemResult,
	IUIContainerData,
	//IUIContainerDataMutable,
	IUIContainerSpec,
	IUIContentSpec,
	//IUIDrawerDataMutable,
	IUIDrawerItem,
	IUIDrawerItemData,
	IUIDrawerItemSpec,
	IUIDrawerSpec,
	IUIFormData,
	IUIFormSpec,
	IUIFormToolbarSpec,
	IUIGroupboxData,
	//IUIGroupboxDataMutable,
	IUIGroupboxSpec,
	IUIItemData,
	//IUIItemDataMutable,
	IUIItemPlacement,
	//IUIItemPlacementData,
	//IUIItemRef,
	//IUIItemRefData,
	IUIItemSpec,
	IUIPanelData,
	//IUIPanelDataMutable,
	IUIPanelSpec,
	IUIPreviewSpec,
	//IUIRadioButtonGroupData,
	//IUIRadioButtonGroupDataMutable,
	IUIRadioButtonGroupSpec,
	IUIRootContainerSpec,
	IUITabPanelSpec,
	IUITabViewSpec,
	IWidgetData,
	//IWidgetDataMutable,
	IWidgetInfo,
	LabelPositionET,
	LayoutTypeET,
	MultilineTextFieldResizingType,
	MultiSelectRelationWidgetTypeET,
	MultiSelectTextFieldSpec,
	ObjectReferenceTextFieldSpec,
	ObjectReferenceTypeET,
	PDObjectEditingSpec,
	PDObjectSelectionListSpecData,
	PDTextFieldUIStateUpdateType,
	RadioButtonBulletLocationET,
	RadioButtonGroupDataTypeET,
	RelationGridQuickCreationSpec,
	TextTemplateSelection,
	TextTemplateSelectionData,
	ToolbarSpec,
	UIContainerSpecProperty,
	INumericTextFieldWidgetData,
	IRadioButtonWidgetData,
	IUIRadioButtonGroupData,
	CustomChoiceProvider,
	ForeignComponentEventSpec,
	ExpressionOptions,
	IDrawerItemComponent,
	IDrawerComponent,
	ILabelWidgetData,
	IWidgetDataMutable,
	ILabeledControlWidgetDataMutable,
	ITextFieldWidgetDataMutable,
	IUIItemDataMutable,
	CustomEventHandler,
	IPDItemDataMutable,
	INumericTextFieldWidgetDataMutable,
	IDateTimeFieldWidgetDataMutable,
	IComboBoxWidgetDataMutable,
	IRelationControlWidgetDataMutable,
	IObjectReferenceWidgetDataMutable,
	IMultiSelectRelationWidgetDataMutable,
	IRelationGridWidgetInfo,
	IUIItemPlacementData,
	IRadioButtonWidgetDataMutable,
	ILabelWidgetDataMutable,
	IUIContainerDataMutable,
	IUIGroupboxDataMutable,
	IUIRadioButtonGroupDataMutable,
	IUIPanelDataMutable,
	IRelationGridWidgetDataMutable,
	IRelationTabWidgetData,
	IRelationTabWidgetDataMutable,
	IPDRelationTabDataMutable,
	IUIDrawerDataMutable,
	//UIStateValue,
	IToNRelationSuitableContainerDataMutable,
	IToNRelationSuitableContainerData,
	UIItemRefData,
	IUIItemRef,
	//UIItemLocator,
	//UIItemTypeData,
	//UIItemLocation,
	//UIItemInfo,
	TypeET,
	OmitItemSpec,
	UIItemInfo,
	UIItemLocation,
	UIItemLocator,
	UIItemSpecProperty,
	UIItemTypeData,
	UIStateValue,
	VerticalSizeTypeET,
	VisibilityET,
	WidgetET,
	IPDExpansionPanelData,
	IPDExpansionPanelDataMutable,
	ComponentEventSpec,
	IGenericContainerSpec,
	GenericContainerType,
	IGenericContainerData,
	IGenericContainerDataMutable,
	GenericContainerInstanceSpec,
	ILayoutProvider,
	UIItemSpecPropertyValueProvider,
	UIContainerSpecPropertyValueProvider,
	ToNRelationSuitableContainerRelObjectCreatedHandler
} from '@otris/ng-core-types';
import { IFormSpecBuilder } from '../services/i-form-spec-builder.service';

interface IContainerLike {
	container: IContainer | UIRootContainerSpec;
	allItems: UIItemSpec[];
	childItemMargin: string | undefined;
	childItemMarginTop: string | undefined;
	childItemMarginRight: string | undefined;
	childItemMarginBottom: string | undefined;
	childItemMarginLeft: string | undefined;
	disableChildItemMarginsInContainer: boolean;
}

interface IContainer {
	layout?: LayoutSpec;
}

export type LayoutProvider = (source: IComponent, ctx: IEventProcessingContext) => LayoutSpec | undefined;

//-------------------------------------------------------------------------------------------------
// ToolbarSpec
//-------------------------------------------------------------------------------------------------

/*export class ToolbarSpec {
	private _items: IToolBarItemSpec[] = [];

	constructor(spec: IToolbarSpec) {
		if (spec.items) {
			this._items = spec.items;
		}
	}

	get items(): IToolBarItemSpec[] {
		return this._items;
	}
}*/

//-------------------------------------------------------------------------------------------------
// RootContainerSpec
//-------------------------------------------------------------------------------------------------

export abstract class UIRootContainerSpec implements IContainerLike {
	private _header?: ErgNameData;
	private _headerId?: string;
	private _content?: UIPanelSpec;
	private _width?: string;
	private _minWidth?: string;
	private _maxWidth?: string;
	private _height?: string;
	private _minHeight?: string;
	private _maxHeight?: string;
	private _showHeader: boolean = true;
	// private _transparent: boolean = true; // über get()
	// private _backgroundColor: string;

	constructor(protected spec: IUIRootContainerSpec) {
		if (spec.header) {
			this._header = spec.header;
		}
		if (spec.headerId) {
			this._headerId = spec.headerId;
		}
		if (spec.content) {
			this._content = new UIPanelSpec(spec.content, this);
		}
		if (spec.width) {
			this._width = spec.width;
		}
		if (spec.minWidth) {
			this._minWidth = spec.minWidth;
		}
		if (spec.maxWidth) {
			this._maxWidth = spec.maxWidth;
		}
		if (spec.height) {
			this._height = spec.height;
		}
		if (spec.minHeight) {
			this._minHeight = spec.minHeight;
		}
		if (spec.maxHeight) {
			this._maxHeight = spec.maxHeight;
		}
		if (spec.showHeader != undefined) {
			this._showHeader = spec.showHeader;
		}
		// if (spec.showBackground != undefined) {
		// 	this._showBackground = spec.showBackground;
		// }
		// if (spec.backgroundColor) {
		// 	this._backgroundColor = spec.backgroundColor;
		// }
	}

	get transparent(): boolean {
		return this.spec.transparent !== false;
	}

	get container(): UIRootContainerSpec {
		return this;
	}

	get header(): ErgNameData {
		return this._header;
	}

	get headerId(): string {
		return this._headerId;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get width(): string {
		return this._width;
	}

	get minWidth(): string {
		return this._minWidth;
	}

	get maxWidth(): string {
		return this._maxWidth;
	}

	get height(): string {
		return this._height;
	}

	get minHeight(): string {
		return this._minHeight;
	}

	get maxHeight(): string {
		return this._maxHeight;
	}

	get showHeader(): boolean {
		return this._showHeader;
	}

	// get showBackground(): boolean {
	// 	return this._showBackground;
	// }

	// get backgroundColor(): string {
	// 	return this._backgroundColor;
	// }

	get allItems(): UIItemSpec[] {
		return this._content ? this._content.allItems : [];
	}

	get childItemMargin(): string | undefined {
		return this.spec.childItemMargin;
	}

	get childItemMarginTop(): string | undefined {
		return this.spec.childItemMarginTop;
	}

	get childItemMarginRight(): string | undefined {
		return this.spec.childItemMarginRight;
	}

	get childItemMarginBottom(): string | undefined {
		return this.spec.childItemMarginBottom;
	}

	get childItemMarginLeft(): string | undefined {
		return this.spec.childItemMarginLeft;
	}

	get disableChildItemMarginsInContainer(): boolean {
		return this.spec.disableChildItemMarginsInContainer === true;
	}
}


//-------------------------------------------------------------------------------------------------
// FormSpec
//-------------------------------------------------------------------------------------------------

export class UIFormToolbarSpec {
	constructor(private spec: IUIFormToolbarSpec) {}

	get save(): boolean {
		return this.spec.save !== false;
	}

	get reset(): boolean {
		return this.spec.reset !== false;
	}

	get language(): boolean {
		return this.spec.language !== false;
	}
}

export class UIFormSpec extends UIRootContainerSpec {
	private _showToolbar: boolean = true;

	private _toolbarSpec: UIFormToolbarSpec;

	static adaptIUIFormSpec(data: IUIFormData, baseSpec: IUIFormSpec): IUIFormSpec {

		baseSpec.content = UIPanelSpec.createIUIPanelSpec(data.content, undefined); // todo
		if (data.header) {
			baseSpec.header = data.header;
		}
		if (data.height) {
			baseSpec.height = data.height;
		}
		if (data.minHeight) {
			baseSpec.minHeight = data.minHeight;
		}
		if (data.maxHeight) {
			baseSpec.maxHeight = data.maxHeight;
		}
		if (data.width) {
			baseSpec.width = data.width;
		}
		if (data.minWidth) {
			baseSpec.minWidth = data.minWidth;
		}
		if (data.maxWidth) {
			baseSpec.maxWidth = data.maxWidth;
		}
		return baseSpec;
	}

	static create(spec: IUIFormSpec, formSpecBuilder: IFormSpecBuilder/*, uiItemDefs: Map<string, IUIItemSpec> | undefined*/) {
		// IUIItemRefs ersetzen
		let formItems = formSpecBuilder.parseFormSpec(spec);
		let itemRefs = formItems.filter(i => (i.item as IUIItemRef).isItemRef);
		itemRefs.forEach(item => {
			let itemRef = item.item as IUIItemRef;
			let source: UIItemLocator = {
				type: itemRef.idRefType === 'property' ? 'property' : 'id',
				value: itemRef.idRef,
				itemType: itemRef.referencedItemType
			}
			let target: UIItemLocation = {
				target: {
					type: 'idRef',
					value: itemRef.idRef,
					itemType: itemRef.referencedItemType
				},
				position: 'after'
			}
			let sourceItem: UIItemInfo;
			if (itemRef.removeReferencedItem) {
				sourceItem = formSpecBuilder.moveUIItem(source, target, formItems);
			}
			else {
				// todo
				// Problem beide Items haben danach die gleiche Id
				//formSpecBuilder.copyUIItem(source, target, formItems);
			}

			// IUIItemRef entfernen
			formSpecBuilder.deleteUIItem(target.target, formItems);

			// UIItem ggf. aktualisieren
			if (itemRef.itemData && (sourceItem?.item as IUIItemSpec)?.type) {
				UIItemSpec.updateIUIItemSpec(sourceItem.item as IUIItemSpec, itemRef.itemData, undefined /*uiItemDefs*/);
			}
		});

		return new UIFormSpec(spec);
	}

	private constructor(spec: IUIFormSpec) {
		super(spec);
		if (spec.showToolbar != undefined) {
			this._showToolbar = spec.showToolbar;
		}
		if (spec.toolbarSpec) {
			this._toolbarSpec = new UIFormToolbarSpec(spec.toolbarSpec);
		}
	}

	/*private*/ get uiFormSpec(): IUIFormSpec {
		return <IUIFormSpec>this.spec;
	}

	get showToolbar(): boolean {
		return this._showToolbar;
	}

	get toolbarSpec(): UIFormToolbarSpec | undefined {
		return this._toolbarSpec;
	}

	get savingHandler(): ((obj: IPDObject, formData: any, mainUIService: IMainUIService, interactionService: IInteractionService, localizationService: ILocalizationService) => Observable<boolean>) | undefined {
		return this.uiFormSpec.savingHandler;
	}

	get submitOnEnter(): boolean | undefined {
		return this.uiFormSpec.submitOnEnter;
	}
}

//-------------------------------------------------------------------------------------------------
// PreviewSpec
//-------------------------------------------------------------------------------------------------

export class UIPreviewSpec extends UIRootContainerSpec {

	constructor(spec: IUIPreviewSpec) {
		super(spec);
	}
}

//-------------------------------------------------------------------------------------------------
// Items
//-------------------------------------------------------------------------------------------------

/*export class UIItemRef {

	constructor(private _itemRef: IUIItemRef) {}

	get property(): string {
		return this._itemRef.property;
	}

	get removeReferencedItem(): boolean {
		return this._itemRef.removeReferencedItem ?? false;
	}
}*/

export abstract class UIItemSpec {
	protected static createUIItemSpec(spec: IUIItemSpec, parent: IContainerLike): UIItemSpec {

		/*if ((spec as IUIItemRef).isItemRef) {
			return new UIItemRef(spec as IUIItemRef);
		}*/

		switch (spec.type) {
			case ItemTypeET.UIPanel:
				return new UIPanelSpec(<IUIPanelSpec>spec, parent);
			case ItemTypeET.PDItem:
				return new PDItemSpec(<IPDItemSpec>spec, parent);
			case ItemTypeET.UIContent:
				return new UIContentSpec(<IUIContentSpec>spec, parent);
			case ItemTypeET.PDRadioButton:
				return new PDRadioButtonSpec(<IPDRadioButtonSpec>spec, parent);
			case ItemTypeET.UIGroupbox:
				return new UIGroupboxSpec(<IUIGroupboxSpec>spec, parent);
			case ItemTypeET.UIRadioButtonGroup:
				return new UIRadioButtonGroupSpec(<IUIRadioButtonGroupSpec>spec, parent);
			//case ItemTypeET.UIAccordion:
			//	return new UIAccordionSpec(<IUIAccordionSpec>spec, parent);
			case ItemTypeET.UITabView:
				return new UITabViewSpec(<IUITabViewSpec>spec, parent);
			case ItemTypeET.UIDrawer:
				return new UIDrawerSpec(<IUIDrawerSpec>spec, parent);
			case ItemTypeET.PDRelationTab:
				return new PDRelationTabSpec(<IPDRelationTabSpec>spec, parent);
			case ItemTypeET.ExpansionPanel:
				return new PDExpansionPanelSpec(<IPDExpansionPanelSpec>spec, parent);
			case ItemTypeET.GenericContainer:
				return new GenericContainerSpec(<IGenericContainerSpec>spec, parent);
			default:
				throw Error(`Unknown item type ${(spec as IUIItemSpec).type}`);
		}
	}

	static createIUIItemSpec(data: IUIItemData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec {
		switch (data.type) {
			case 'pdItem':
				return PDItemSpec.createIPDItemSpec(data as IPDItemData);
			case 'uiGroupbox':
				return UIGroupboxSpec.createIUIGroupboxSpec(data as IUIGroupboxData, uiItemDefs);
			case 'pdExpansionPanel':
				return PDExpansionPanelSpec.createIPDExpansionPanelSpec(data as IPDExpansionPanelData, uiItemDefs);
			case 'uiPanel':
				return UIPanelSpec.createIUIPanelSpec(data as IUIPanelData, uiItemDefs);
			case 'uiRadioButtonGroup':
				return UIRadioButtonGroupSpec.createIUIRadioButtonGroupSpec(data as IUIRadioButtonGroupData, uiItemDefs);
			case 'genericContainer':
				return GenericContainerSpec.createIGenericContainerSpec(data as IGenericContainerData, uiItemDefs);
			default:
				throw new Error(`Error in createIUIItemSpec(): unknown item type '${data.type}'`);
		}
	}

	static updateIUIItemSpec(spec: IUIItemSpec, data: IUIItemDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIItemSpec {
		switch (spec.type) {
			case ItemTypeET.PDItem:
				return PDItemSpec.updateIPDtemSpec(spec as IPDItemSpec, data as IPDItemDataMutable);
			case ItemTypeET.UIGroupbox:
				return UIGroupboxSpec.updateIUIGroupboxSpec(spec as IUIGroupboxSpec, data as IUIGroupboxDataMutable, uiItemDefs);
			case ItemTypeET.ExpansionPanel:
				return PDExpansionPanelSpec.updateIPDExpansionPanelSpec(spec as IPDExpansionPanelSpec, data as IPDExpansionPanelDataMutable, uiItemDefs);
			case ItemTypeET.UIPanel:
				return UIPanelSpec.updateIUIPanelSpec(spec as IUIPanelSpec, data as IUIPanelDataMutable);
			case ItemTypeET.UIRadioButtonGroup:
				return UIRadioButtonGroupSpec.updateIUIRadioButtonGroupSpec(spec as IUIRadioButtonGroupSpec, data as IUIRadioButtonGroupDataMutable, uiItemDefs);
			case ItemTypeET.GenericContainer:
				return GenericContainerSpec.updateIGenericContainerSpec(spec as IGenericContainerSpec, data as IGenericContainerDataMutable, uiItemDefs);
			case ItemTypeET.PDRelationTab:
				return PDRelationTabSpec.updateIPDRelationTabSpec(spec as IPDRelationTabSpec, data as IPDRelationTabDataMutable);
			case ItemTypeET.UIDrawer:
				return UIDrawerSpec.updateIUIDrawerSpec(spec as IUIDrawerSpec, data, uiItemDefs);
			default:
				console.warn(`Unknown item type '${spec.type}' in UIItemSpec.updateIUIItemSpec().`);
				return spec;
		}
	}

	static createIUIItemRef(itemRefData: UIItemRefData): IUIItemRef {
		let itemRef: IUIItemRef = Object.assign({},
			itemRefData,
			{
				referencedItemType: itemRefData.referencedItemType ? this.determineItemType(itemRefData.referencedItemType) : undefined
			}
		);
		return itemRef;
	}

	static determineItemType(itemTypeData: UIItemTypeData): ItemTypeET {
		switch (itemTypeData) {
			case 'pdItem':
				return ItemTypeET.PDItem;
			case 'uiGroupbox':
				return ItemTypeET.UIGroupbox;
			case 'pdExpansionPanel':
				return ItemTypeET.ExpansionPanel;
			case 'uiPanel':
				return ItemTypeET.UIPanel;
			case 'uiRadioButtonGroup':
				return ItemTypeET.UIRadioButtonGroup;
			case 'pdRadioButton':
				return ItemTypeET.PDRadioButton;
			case 'pdRelationTab':
				return ItemTypeET.PDRelationTab;
			case 'uiContent':
				return ItemTypeET.UIContent;
			case 'uiDrawer':
				return ItemTypeET.UIDrawer;
			case 'uiTabView':
				return ItemTypeET.UITabView;
			case 'genericContainer':
				return ItemTypeET.GenericContainer;
			default:
				throw new Error(`Invalid item type ${itemTypeData}`);
		}
	}

	// Todo: UIStateValue prüfen
	protected static updateIUIItemSpecImpl(spec: IUIItemSpec, data: IUIItemDataMutable): IUIItemSpec {
		let determineUIState = (
			originState: boolean | undefined,
			wantedState: boolean | undefined
			//wantedState: boolean | UIStateValue | undefined
		) => {
			/*let value = typeof(wantedState) === 'object' ? wantedState.value : wantedState;
			let overwrite = typeof(wantedState) === 'object' ? wantedState.overwriteOriginalState : false;
			if (overwrite) {
				return value;
			}
			return value ? true : originState;*/
			return wantedState !== undefined ? wantedState : originState
		};

		let determineOmitItemSpec = (): OmitItemSpec | undefined => {
			if (data.omitItem) {
				if (data.omitItem.value) {
					return { value: data.omitItem.value }
				}
				if (data.omitItem.expr) {
					return { expr: data.omitItem.expr }
				}
			}
			return spec.omitItemSpec;
		}

		let currentHandler = spec.updateUIStateHandler;
		let uiState = data.uiState;
		delete data.uiState;
		spec.updateUIStateHandler = (source, currentState, ctx) => {
			if (!uiState) {
				if (currentHandler) {
					return currentHandler(source, currentState, ctx);
				}
				return undefined;
			}
			let state: IComponentUIState;
			let originalState: IComponentUIState;
			if (currentHandler) {  // todo: overwriteOriginalState berücksichtigen!
				originalState = currentHandler(source, currentState, ctx);
			}

			if (uiState.hidden !== undefined || uiState.mandatory !== undefined ||
					uiState.readonly !== undefined || uiState.disabled !== undefined)
			{
				state = {};
				state.hidden = determineUIState(originalState?.hidden, uiState.hidden);
				state.mandatory = determineUIState(originalState?.mandatory, uiState.mandatory);
				state.readonly = determineUIState(originalState?.readonly, uiState.readonly);
				state.disabled = determineUIState(originalState?.disabled, uiState.disabled);
			}
			return state;
		}

		let uiStateExpressionData = data.uiStateExpression ? data.uiStateExpression : spec.uiStateExpressionData;
		let initialUIStateExpressionData = data.initialUIStateExpression ? data.initialUIStateExpression : spec.initialUIStateExpressionData;
		let omitItemSpec = determineOmitItemSpec();
		let propertyValueProviderData = data.propertyValueProvider ? data.propertyValueProvider : spec.propertyValueProviderData;
		delete data.propertyValueProvider;
		delete data.uiStateExpression;
		delete data.initialUIStateExpression;
		delete data.omitItem;
		delete (data as IUIItemData).type;
		let dataAdapted = Object.assign(
			{},
			data,
			{
				placement: data.placement ? UIItemPlacement.createIUIItemPlacement(data.placement) : spec.placement,
				customEventHandlerData: data.customEventHandler ? data.customEventHandler : spec.customEventHandlerData,
				componentEventData: data.componentEvents ? data.componentEvents : spec.componentEventData,
				foreignComponentEventData: data.foreignComponentEvents ? data.foreignComponentEvents : spec.foreignComponentEventData,
				customEventHandler: spec.customEventHandler,
				foreignComponentEvents: spec.foreignComponentEvents,
				uiStateExpressionData: uiStateExpressionData,
				initialUIStateExpressionData: initialUIStateExpressionData,
				omitItemSpec: omitItemSpec,
				propertyValueProviderData: propertyValueProviderData
			}
		);
		delete data.placement;
		delete data.customEventHandler;
		delete data.foreignComponentEvents;
		delete data.componentEvents;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	protected static createIUIItemSpecImpl(data: IUIItemData): IUIItemSpec {
		let spec: IUIItemSpec = {
			type: this.determineItemType(data.type),
		}
		delete data.type;
		return UIItemSpec.updateIUIItemSpecImpl(spec, data);
	}

	protected spec: IUIItemSpec;
	protected _parent: IContainerLike;
	protected _widget: WidgetET;
	protected _widgetInfo?: WidgetInfo;
	private _type: ItemTypeET;
	private _id: string;
	private _placement?: UIItemPlacement;
	private _width?: string;
	private _height?: string;
	private _minWidth?: string;
	private _maxWidth?: string;
	private _minHeight?: string;
	private _maxHeight?: string;
	private _margin?: string;
	private _marginLeft?: string;
	private _marginTop?: string;
	private _marginRight?: string;
	private _marginBottom?: string;
	private _padding?: string;
	private _paddingLeft?: string;
	private _paddingTop?: string;
	private _paddingRight?: string;
	private _paddingBottom?: string;
	private _pdClassContext?: string[];
	private _editContext?: string[];
	private _invalidEditContext?: string[];
	private _customData: any;
	private _customEventHandler: CustomEventHandler;
	private _foreignComponentEvents: ForeignComponentEventSpec[] = [];
	private _componentEvents: ComponentEventSpec[] = [];
	private _propertyValueProvider: UIItemSpecPropertyValueProvider;

	// todo: widget als Parameter
	constructor(itemSpec: IUIItemSpec, parent: IContainerLike) {
		this.spec = itemSpec;
		if (parent) {
			this._parent = parent;
		}
	}

	protected create() {
		this._id = this.spec.id;
		this._type = this.spec.type;
		if (this.parentContainer) {
			this._placement = UIItemPlacement.createUIItemPlacement(this.parentContainer.layout, this.spec.placement);
		}
		if (this.spec.width) {
			this._width = this.spec.width;
		}
		if (this.spec.height) {
			this._height = this.spec.height;
		}
		if (this.spec.minWidth) {
			this._minWidth = this.spec.minWidth;
		}
		if (this.spec.maxWidth) {
			this._maxWidth = this.spec.maxWidth;
		}
		if (this.spec.minHeight) {
			this._minHeight = this.spec.minHeight;
		}
		if (this.spec.maxHeight) {
			this._maxHeight = this.spec.maxHeight;
		}
		if (this.spec.margin) {
			this._margin = this.spec.margin;
		}
		if (this.spec.marginLeft) {
			this._marginLeft = this.spec.marginLeft;
		}
		if (this.spec.marginTop) {
			this._marginTop = this.spec.marginTop;
		}
		if (this.spec.marginRight) {
			this._marginRight = this.spec.marginRight;
		}
		if (this.spec.marginBottom) {
			this._marginBottom = this.spec.marginBottom;
		}
		if (this.spec.padding) {
			this._padding = this.spec.padding;
		}
		if (this.spec.paddingLeft) {
			this._paddingLeft = this.spec.paddingLeft;
		}
		if (this.spec.paddingTop) {
			this._paddingTop = this.spec.paddingTop;
		}
		if (this.spec.paddingRight) {
			this._paddingRight = this.spec.paddingRight;
		}
		if (this.spec.paddingBottom) {
			this._paddingBottom = this.spec.paddingBottom;
		}
		if (this.spec.pdClassContext) {
			this._pdClassContext = this.spec.pdClassContext;
		}
		if (this.spec.editContext) {
			this._editContext = this.spec.editContext;
		}
		if (this.spec.invalidEditContext) {
			this._invalidEditContext = this.spec.invalidEditContext;
		}
		if (this.spec.widgetInfo) {
			this._widgetInfo = WidgetInfo.createWidgetInfo(this.spec.widgetInfo, this._widget);
		}
		if (this.spec.customData) {
			this._customData= this.spec.customData;
		}

		this.createCustomEventHandler();

		this.createForeignComponentEvents();

		this.createComponentEvents();

		if (Array.isArray(this.spec.propertyValueProviderData) && this.spec.propertyValueProviderData.length > 0) {
			this._propertyValueProvider = (prop, src, ctx) => {
				let data = this.spec.propertyValueProviderData.find(d => d.propName === prop);
				if (!data) {
					return undefined;
				}
				if (data.value !== undefined) {
					return data.value;
				}
				for (let condVal of data.conditionalValues) {
					if (ctx.formExpressionGrammarProcessor.evaluateConditionExpression(condVal.condition, ctx)) {
						return condVal.value;
					}
					if (condVal.fallbackValue !== undefined) {
						return condVal.fallbackValue;
					}
				}
				return undefined;
			}	
		}
		else {
			this._propertyValueProvider = this.spec.propertyValueProvider;
		}
	}

	getAncestorOfType(type: ItemTypeET): UIItemSpec {
		if (this.parentContainer) {
			if (this.parentContainer.type === type) {
				return this.parentContainer;
			}
			return this.parentContainer.getAncestorOfType(type);
		}
		return undefined;
	}

	private createCustomEventHandler(): void {
		if (this.spec.customEventHandlerData) {
			this._customEventHandler = (custEvt, source, ctx) => {
				if (this.spec.customEventHandler && this.spec.detachDefaultCustomEventHandler !== true) {
					this.spec.customEventHandler(custEvt, source, ctx);
				}
				let exprOptions: ExpressionOptions = {
					sourceComp: source
				}
				let eventName = custEvt.eventToken.eventName;
				this.spec.customEventHandlerData.forEach(handler => {
					if (eventName === handler.customEvent) {
						handler.commands.forEach(cmdData => {
							// todo: mit commands unten zusammenfassen?
							if (!cmdData.condition ||
								ctx.formExpressionGrammarProcessor.evaluateConditionExpression(cmdData.condition, ctx, exprOptions)) {
								let params = {};
								if (cmdData.params) {
									for (const [key, value] of Object.entries(cmdData.params)) {
										if (value.paramType === 'expression') {
											throw new Error('Not implemented!');
											//params[key] = ctx.formExpressionGrammarProcessor.todo(value);
										}
										else {
											params[key] = value;
										}
									}
								}
								source.executeCommand(cmdData.command, params)
							}
							// todo: condition, params!
							//source.executeCommand(cmdData.command);
						});
					}
				});
			}
		}
		else {
			this._customEventHandler = this.spec.customEventHandler;
		}
	}

	private createForeignComponentEvents(): void {
		if (this.spec.foreignComponentEvents &&
			(!this.spec.foreignComponentEventsModification || !this.spec.foreignComponentEventsModification.removeAll)) {
			this.spec.foreignComponentEvents.forEach(event => {
				let removeItem = false;
				if (this.spec.foreignComponentEventsModification?.removeItems) {
					removeItem = this.spec.foreignComponentEventsModification.removeItems.findIndex(
						i => i.compId === event.foreignCompId && i.eventName === event.eventName) != -1;
				}
				if (!removeItem) {
					this._foreignComponentEvents.push(event);
				}
			});
		}
		if (this.spec.foreignComponentEventData?.length > 0) {
			this.spec.foreignComponentEventData.forEach(data => {
				let eventSpec: ForeignComponentEventSpec = {
					foreignCompId: data.foreignComp,
					eventName: data.event,
					eventHandler: (source, foreignComp, event, ctx) => {
						if (data.event !== event) {
							return;
						}
						let exprOptions: ExpressionOptions = {
							foreignComp: foreignComp,
							sourceComp: source
						}
						if (!data.condition || ctx.formExpressionGrammarProcessor.evaluateConditionExpression(data.condition, ctx, exprOptions)) {
							data.commands.forEach(cmdData => {
								if (!cmdData.condition ||
									ctx.formExpressionGrammarProcessor.evaluateConditionExpression(cmdData.condition, ctx, exprOptions)) {
									let params = {};
									if (cmdData.params) {
										for (const [key, value] of Object.entries(cmdData.params)) {
											if (value.paramType === 'expression') {
												throw new Error('Not implemented!');
												//params[key] = ctx.formExpressionGrammarProcessor.todo(value);
											}
											else {
												params[key] = value;
											}
										}
									}
									source.executeCommand(cmdData.command, params)
								}
							});
						}
					}
				}
				this._foreignComponentEvents.push(eventSpec);
			});
		}
	}

	private createComponentEvents(): void {
		if (this.spec.componentEventData?.length > 0) {
			this.spec.componentEventData.forEach(data => {
				let eventSpec: ComponentEventSpec = {
					eventName: data.event,
					eventHandler: (source, event, ctx) => {
						if (data.event !== event) {
							return;
						}
						let exprOptions: ExpressionOptions = {
							sourceComp: source
						}
						if (!data.condition || ctx.formExpressionGrammarProcessor.evaluateConditionExpression(data.condition, ctx, exprOptions)) {
							data.commands.forEach(cmdData => {
								if (!cmdData.condition ||
									ctx.formExpressionGrammarProcessor.evaluateConditionExpression(cmdData.condition, ctx, exprOptions)) {
									let params = {};
									if (cmdData.params) {
										for (const [key, value] of Object.entries(cmdData.params)) {
											if (value.paramType === 'expression') {
												throw new Error('Not implemented!');
												//params[key] = ctx.formExpressionGrammarProcessor.todo(value);
											}
											else {
												params[key] = value;
											}
										}
									}
									source.executeCommand(cmdData.command, params)
								}
							});
						}
					}
				}
				this._componentEvents.push(eventSpec);
			});
		}
	}

	get isContainerLike(): boolean {
		return false;
	}

	get id(): string {
		return this._id;
	}

	/*get omitItem(): boolean | undefined {
		return this.spec.omitItem;
	}

	get omitItemHandler(): ((ctx: IEventProcessingContext) => boolean) | undefined {
		return this.spec.omitItemHandler;
	}*/

	get omitItemSpec(): OmitItemSpec | undefined {
		return this.spec.omitItemSpec;
	}

	get type(): ItemTypeET {
		return this._type;
	}

	get placement(): UIItemPlacement {
		return this._placement;
	}

	get widget(): WidgetET {
		return this._widget;
	}

	get width(): string {
		return this._width;
	}

	get height(): string {
		return this._height;
	}

	get minWidth(): string {
		return this._minWidth;
	}

	get maxWidth(): string {
		return this._maxWidth;
	}

	get minHeight(): string {
		return this._minHeight;
	}

	get maxHeight(): string {
		return this._maxHeight;
	}

	get margin(): string {
		return this._margin;
	}

	get marginLeft(): string {
		return this._marginLeft;
	}

	get marginTop(): string {
		return this._marginTop;
	}

	get marginRight(): string {
		return this._marginRight;
	}

	get marginBottom(): string {
		return this._marginBottom;
	}

	get padding(): string {
		return this._padding;
	}

	get paddingLeft(): string {
		return this._paddingLeft;
	}

	get paddingTop(): string {
		return this._paddingTop;
	}

	get paddingRight(): string {
		return this._paddingRight;
	}

	get paddingBottom(): string {
		return this._paddingBottom;
	}

	get propertyValueProvider(): UIItemSpecPropertyValueProvider | undefined {
		// todo
		//return this.spec.propertyValueProvider;
		return this._propertyValueProvider;
	}

	get widgetInfo(): WidgetInfo {
		return this._widgetInfo;
	}

	get parentContainer(): UIContainerSpec | undefined {
		return this._parent?.container instanceof UIContainerSpec ? <UIContainerSpec>(this._parent.container) : undefined;
	}

	get rootForm(): UIFormSpec | undefined {
		if (this._parent?.container instanceof UIFormSpec) {
			return <UIFormSpec>(this._parent.container)
		}
		return this.parentContainer?.rootForm ?? undefined;
	}

	get pdClassContext(): string[] | undefined {
		return this._pdClassContext;
	}

	get editContext(): string[] | undefined {
		return this._editContext;
	}

	get invalidEditContext(): string[] | undefined {
		return this._invalidEditContext;
	}

	get customData(): any {
		return this._customData;
	}

	get shortDescriptionId(): string | undefined {
		return this.spec.shortDescriptionId;
	}

	get shortDescriptionIdHandler():(source: IComponent, ctx: IEventProcessingContext) => string | undefined {
		return this.spec.shortDescriptionIdHandler;
	}

	get shortDescription(): ErgNameData | undefined {
		return this.spec.shortDescription;
	}

	get uiStateExpressionData(): ComponentUIStateExpression | undefined {
		return this.spec.uiStateExpressionData;
	}

	get initialUIStateExpressionData(): ComponentUIStateExpression | undefined {
		return this.spec.initialUIStateExpressionData;
	}

	get initialUIStateProvider(): ((source: IComponent, ctx: IEventProcessingContext) => IComponentUIState | undefined) | undefined {
		return this.spec.initialUIStateProvider;
	}

	get updateUIStateHandler(): ((source: IComponent, currentState: IComponentUIState, ctx: IEventProcessingContext) => IComponentUIState | undefined) | undefined {
		return this.spec.updateUIStateHandler;
	}

	get customEventHandler(): CustomEventHandler | undefined {
		return this._customEventHandler;
		//return this.spec.customEventHandler;
	}

	get customValidators(): ((source: IComponent, ctx: IEventProcessingContext) => ValidatorFn)[] | undefined {
		return this.spec.customValidators;
	}

	get foreignComponentEvents(): ForeignComponentEventSpec[] | undefined {
		return this._foreignComponentEvents.length > 0 ? this._foreignComponentEvents : undefined;
	}

	get componentEvents(): ComponentEventSpec[] | undefined {
		return this._componentEvents.length > 0 ? this._componentEvents : undefined;
	}
}

export class UIContentSpec extends UIItemSpec {

	private _template: string;

	constructor(uiContentSpec: IUIContentSpec, parent: IContainerLike) {
		super(uiContentSpec, parent);
		this.create();
	}

	protected create() {
		super.create();
		this._widget = WidgetET.ContentTemplate;
		if (this.uiContentSpec.template) {
			this._template = this.uiContentSpec.template;
		}
	}

	get uiContentSpec(): IUIContentSpec {
		return <IUIContentSpec>this.spec;
	}

	get template(): string {
		return this._template;
	}
}

export class PDItemSpec extends UIItemSpec {
	private _property: string;

	static createIPDItemSpec(data: IPDItemData): IPDItemSpec {
		let determineWidget = () => {
			switch (data.widget.widgetType) {
				case 'objectReference':
					return WidgetET.ObjectReference;
				case 'multiSelect':
					return WidgetET.MultiSelect;
				case 'textField':
					return WidgetET.TextField;
				case 'comboBox':
					return WidgetET.ComboBox;
				case 'dateTimeField':
					return WidgetET.DateTimeField;
				case 'numericTextField':
					return WidgetET.NumericTextField;
				case 'radioButton':
					return WidgetET.RadioButton;
				case 'label':
					return WidgetET.Label;
				default:
					throw new Error(`Unknown widget type ${data.widget.widgetType}`);
			}
		}

		let spec: IPDItemSpec = Object.assign(
			UIItemSpec.createIUIItemSpecImpl(data),
			{
				widget: determineWidget(),
				widgetInfo: this.createWidgetInfoFromData(determineWidget(), data.widget),
				property: data.property
			},
		);
		// delete data.widget;
		return this.updateIPDtemSpec(spec, data, false);
	}

	static updateIPDtemSpec(spec: IPDItemSpec, data: IPDItemDataMutable, updateBase = true): IPDItemSpec {
		let widget = data.widget;
		delete data.widget;
		if (updateBase) {
			this.updateIUIItemSpecImpl(spec, data);
		}
		let dataAdapted = Object.assign(
			{},
			data,
			{
				widget: spec.widget
			}
		);
		// ggf. Widget-Info aktualisieren
		if (widget) {
			if (!spec.widgetInfo) {
				spec.widgetInfo = this.createWidgetInfoFromData(spec.widget, undefined);
			}
			switch (spec.widget) {
				case WidgetET.ObjectReference:
					ObjectReferenceWidgetInfo.updateIObjectReferenceWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.MultiSelect:
					MultiSelectRelationWidgetInfo.updateIMultiSelectRelationWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.TextField:
					TextFieldWidgetInfo.updateITextFieldWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.ComboBox:
					ComboBoxWidgetInfo.updateIComboBoxWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.DateTimeField:
					DateTimeFieldWidgetInfo.updateIDateTimeFieldWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.NumericTextField:
					NumericTextFieldWidgetInfo.updateINumericTextFieldWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.RadioButton:
					RadioButtonWidgetInfo.updateIRadioButtonWidgetInfo(spec.widgetInfo as IRadioButtonWidgetInfo, widget);
					break;

				case WidgetET.Label:
					LabelWidgetInfo.updateILabelWidgetInfo(spec.widgetInfo, widget);
					break;

				case WidgetET.RelationGrid:
					RelationGridWidgetInfo.updateIRelationGridWidgetInfo(spec.widgetInfo as IRelationGridWidgetInfo, widget);
					break;

				case WidgetET.FileUpload:
					FileUploadWidgetInfo.updateIFileUploadWidgetInfo(spec.widgetInfo as IFileUploadWidgetInfo, widget);
					break;
			}
		}
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	private static createWidgetInfoFromData(widget: WidgetET, data: IWidgetData | undefined): IWidgetInfo | undefined {
		switch (widget) {
			case WidgetET.ObjectReference:
				return ObjectReferenceWidgetInfo.createIObjectReferenceWidgetInfo(data);

			case WidgetET.MultiSelect:
				return MultiSelectRelationWidgetInfo.createIMultiSelectRelationWidgetInfo(data);

			case WidgetET.TextField:
				return TextFieldWidgetInfo.createITextFieldWidgetInfo(data);

			case WidgetET.ComboBox:
				return ComboBoxWidgetInfo.createIComboBoxWidgetInfo(data);

			case WidgetET.DateTimeField:
				return DateTimeFieldWidgetInfo.createIDateTimeFieldWidgetInfo(data);

			case WidgetET.NumericTextField:
				return NumericTextFieldWidgetInfo.createINumericTextFieldWidgetInfo(data);

			case WidgetET.RadioButton:
				if (!data) {
					throw new Error(`Can not create IRadioButtonWidgetInfo without IRadioButtonWidgetData.`);
				}
				return RadioButtonWidgetInfo.createIRadioButtonWidgetInfo(data as IRadioButtonWidgetData);

			case WidgetET.Label:
				return LabelWidgetInfo.createILabelWidgetInfo(data);

			case WidgetET.RelationTab:
				return RelationTabWidgetInfo.createIRelationTabWidgetInfo(data);

			case WidgetET.FileUpload:
				return FileUploadWidgetInfo.createIFileUploadWidgetInfo(data);
		}
		return undefined;
	}

	constructor(pdItemSpec: IPDItemSpec, parent: IContainerLike) {
		super(pdItemSpec, parent);
		this.create();
	}

	protected create() {
		if (this.pdItemSpec.property) {
			this._property = this.pdItemSpec.property;
		}
		this._widget = this.pdItemSpec.widget;
		super.create();
	}

	get pdItemSpec(): IPDItemSpec {
		return <IPDItemSpec>this.spec;
	}

	get property(): string {
		return this._property;
	}

	get label(): ErgNameData | undefined {
		return this.pdItemSpec.label;
	}

	get labelId(): string | undefined {
		return this.pdItemSpec.labelId;
	}

	get to1RelationItemSpec(): ITo1RelationItemSpec | undefined {
		return this.pdItemSpec.to1RelationItemSpec;
	}

	get defaultValue(): string | number | boolean | Date | undefined {
		return this.pdItemSpec.defaultValue;
	}
}

export class PDRadioButtonSpec extends PDItemSpec implements IContainer, IContainerLike {
	private _content: UIPanelSpec;

	constructor(radioButtonSpec: IPDRadioButtonSpec, parent: IContainerLike) {
		super(radioButtonSpec, parent);
		this.create();
	}

	protected create() {
		if (this.radioButtonSpec.widget !== WidgetET.RadioButton) {
			throw Error('Invalid widget.');
		}
		super.create();
		if (!!this.radioButtonSpec.content) {
			this._content = new UIPanelSpec(this.radioButtonSpec.content, this);
		}
	}

	get isContainerLike(): boolean {
		return !!this.content;
	}

	get layout(): LayoutSpec {
		return !this.content ? undefined : this.content.layout;
	}

	get container(): IContainer | UIFormSpec {
		return this;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (!!this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get radioButtonSpec(): IPDRadioButtonSpec {
		return <IPDRadioButtonSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get childItemMargin(): string | undefined {
		return undefined;
	}

	get childItemMarginTop(): string | undefined {
		return undefined;
	}

	get childItemMarginRight(): string | undefined {
		return undefined;
	}

	get childItemMarginBottom(): string | undefined {
		return undefined;
	}

	get childItemMarginLeft(): string | undefined {
		return undefined;
	}

	get disableChildItemMarginsInContainer(): boolean {
		return false;
	}
}

export abstract class UIContainerSpec extends UIItemSpec implements IContainer, IContainerLike {

	static createIUIContainerSpec(data: IUIContainerData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIContainerSpec {
		let createItems = () => {
			if (data.items) {
				let items: (IUIItemSpec | IUIItemRef)[] = [];
				data.items.forEach(item => {
					if ((item as UIItemRefData).isItemRef) {
						items.push(UIItemSpec.createIUIItemRef(item as UIItemRefData))
					}
					else {
						items.push(UIItemSpec.createIUIItemSpec(item as IUIItemData, uiItemDefs))
					}
				});
				return items;
			}
			return undefined
		};

		let spec: IUIContainerSpec = Object.assign(
			UIItemSpec.createIUIItemSpecImpl(data),
			{
				items: createItems()
			},
		);
		delete data.items;
		return this.updateIUIContainerSpec(spec, data, false);
	}

	static updateIUIContainerSpec(spec: IUIContainerSpec, data: IUIContainerDataMutable, updateBase = true): IUIContainerSpec {
		if (updateBase) {
			this.updateIUIItemSpecImpl(spec, data);
		}

		let layoutProviderData = data.layoutProvider;
		delete data.layoutProvider;
		let dataAdapted = Object.assign(
			{},
			data,
			{
				layout: data.layout ? LayoutSpec.createILayoutSpec(data.layout) : spec.layout,
				layoutProviderData: layoutProviderData
			}
		);
		delete data.layout;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	private _layout?: LayoutSpec;
	private _items: UIItemSpec[] = [];
	private _backgroundColor: string;
	private _layoutProvider: LayoutProvider;

	constructor(containerSpec: IUIContainerSpec, parent: IContainerLike) {
		super(containerSpec, parent);
	}

	protected create() {
		super.create();
		if (this.containerSpec.layout) {
			this._layout = LayoutSpec.createLayoutSpec(this.containerSpec.layout);
		}
		if (this.containerSpec.backgroundColor) {
			this._backgroundColor = this.containerSpec.backgroundColor;
		}
		if (this.containerSpec.items) {
			for (let item of this.containerSpec.items) {
				if ((item as IUIItemRef).isItemRef) {
					console.warn(`UIContainerSpec.create(): Item of type 'IUIItemRef' is omitted.`);
					continue;
				}
				this._items.push(UIItemSpec.createUIItemSpec(item as IUIItemSpec, this));
			}
		}

		let createLayoutProvider = (): LayoutProvider | undefined => {
			if (this.containerSpec.layoutProviderData) {
				return (source, ctx) => {
					for (let provider of this.containerSpec.layoutProviderData) {
						if (provider.condition) {
							if (!ctx.formExpressionGrammarProcessor.evaluateConditionExpression(provider.condition, ctx)) {
								continue;
							}
						}
						let iLayoutSpec = LayoutSpec.createILayoutSpec(provider.layout);
						return LayoutSpec.createLayoutSpec(iLayoutSpec);
					}
					return undefined;
				}
			}
			if (this.containerSpec.layoutProvider) {
				return (source, ctx) => {
					return LayoutSpec.createLayoutSpec(this.containerSpec.layoutProvider(source, ctx));
				}
			}
			return undefined;
		};
		this._layoutProvider = createLayoutProvider();
	}

	get isContainerLike(): boolean {
		return true;
	}

	get container(): IContainer | UIFormSpec {
		return this;
	}

	get containerSpec(): IUIContainerSpec {
		return <IUIContainerSpec>this.spec;
	}

	get layout(): LayoutSpec {
		return this._layout;
	}

	get layoutProvider(): ((source: IComponent, ctx: IEventProcessingContext) => LayoutSpec | undefined) | undefined {
		return this._layoutProvider;
	}

	get items(): UIItemSpec[] {
		return this._items;
	}

	get backgroundColor(): string | undefined {
		return this._backgroundColor;
	}

	get borderRadius(): string | undefined {
		return this.containerSpec.borderRadius;
	}

	get verticalSize(): VerticalSizeTypeET | undefined {
		return this.containerSpec.verticalSize;
	}

	get childItemMargin(): string | undefined {
		if (this.containerSpec.childItemMargin) {
			return this.containerSpec.childItemMargin;
		}
		return this._parent?.childItemMargin ?? undefined;
	}

	get childItemMarginTop(): string | undefined {
		if (this.containerSpec.childItemMarginTop) {
			return this.containerSpec.childItemMarginTop;
		}
		return this._parent?.childItemMarginTop ?? undefined;
	}

	get childItemMarginRight(): string | undefined {
		if (this.containerSpec.childItemMarginRight) {
			return this.containerSpec.childItemMarginRight;
		}
		return this._parent?.childItemMarginRight ?? undefined;
	}

	get childItemMarginBottom(): string | undefined {
		if (this.containerSpec.childItemMarginBottom) {
			return this.containerSpec.childItemMarginBottom;
		}
		return this._parent?.childItemMarginBottom ?? undefined;
	}

	get childItemMarginLeft(): string | undefined {
		if (this.containerSpec.childItemMarginLeft) {
			return this.containerSpec.childItemMarginLeft;
		}
		return this._parent?.childItemMarginLeft ?? undefined;
	}
	get disableChildItemMarginsInContainer(): boolean {
		if (this.containerSpec.disableChildItemMarginsInContainer === true) {
			return true;
		}
		return this._parent?.disableChildItemMarginsInContainer ?? false;
	}

	get toNRelationItemSpec(): IToNRelationItemSpec | undefined {
		return this.containerSpec.toNRelationItemSpec;
	}

	get to1RelationItemSpec(): ITo1RelationItemSpec | undefined {
		return this.containerSpec.to1RelationItemSpec;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let item of this.items) {
			list.push(item);
			//if (item instanceof UIContainerSpec) {
			if (item.isContainerLike) {
				let childs = (<IContainerLike>(<any>item)).allItems;
				list.push(...childs);
			}
		}
		return list;
	}

	get propertyValueProvider(): UIContainerSpecPropertyValueProvider | undefined {
		return super.propertyValueProvider as UIContainerSpecPropertyValueProvider
		//return this.spec.propertyValueProvider;
	}
}

export abstract class UIToNRelationSuitableContainerSpec extends UIContainerSpec {

	static createIToNRelationSuitableContainerSpec(data: IToNRelationSuitableContainerData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IToNRelationSuitableContainerSpec {
		let spec = UIContainerSpec.createIUIContainerSpec(data, uiItemDefs);
		return this.updateIToNRelationSuitableContainerSpec(spec, data, false);
	}

	static updateIToNRelationSuitableContainerSpec(spec: IToNRelationSuitableContainerSpec, data: IToNRelationSuitableContainerDataMutable, updateBase = true): IToNRelationSuitableContainerSpec {
		let toNRelationSpec = data.toNRelationSpec;
		delete data.toNRelationSpec;
		if (updateBase) {
			this.updateIUIContainerSpec(spec, data);
		}

		if (spec.toNRelationSpec && toNRelationSpec) {
			Object.assign(
				spec.toNRelationSpec,
				toNRelationSpec
			);
		}

		return spec;
	}

	constructor(containerSpec: IToNRelationSuitableContainerSpec, parent: IContainerLike) {
		super(containerSpec, parent);
	}

	private get uiToNRelationSuitableContainerSpec(): IToNRelationSuitableContainerSpec {
		return <IToNRelationSuitableContainerSpec>this.spec;
	}

	get toNRelationSpec(): IToNRelationSpec | undefined {
		return this.uiToNRelationSuitableContainerSpec.toNRelationSpec;
	}

	get relationObjectCreatedHandler(): ToNRelationSuitableContainerRelObjectCreatedHandler | undefined {
		return this.uiToNRelationSuitableContainerSpec.relationObjectCreatedHandler;
	}
}

export class UIPanelSpec extends UIToNRelationSuitableContainerSpec {

	static createIUIPanelSpec(data: IUIPanelData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIPanelSpec {
		/*let spec: IUIPanelSpec = Object.assign(
			UIToNRelationSuitableContainerSpec.createIUIContainerSpec(data)
		);*/
		let spec: IUIPanelSpec = UIToNRelationSuitableContainerSpec.createIUIContainerSpec(data, uiItemDefs);
		return this.updateIUIPanelSpec(spec, data, false)

		/*let spec: IUIPanelSpec = UIToNRelationSuitableContainerSpec.createIUIContainerSpec(data);
		spec.header = data.header;
		spec.headerId = data.headerId;
		spec.headerTemplateId = data.headerTemplateId;
		return spec;*/
	}

	static updateIUIPanelSpec(spec: IUIPanelSpec, data: IUIPanelDataMutable, updateBase = true): IUIPanelSpec {
		if (updateBase) {
			this.updateIToNRelationSuitableContainerSpec(spec, data);
		}
		return Object.assign(
			spec,
			data
		);
	}

	static findIUIPanelSpec(panelId: string, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIPanelSpec | undefined {
		if (!uiItemDefs) {
			console.log('No uiItemDefs provided in UIGroupboxSpec.updateIUIGroupboxSpec()');
		} else {
			//let id = content as string;
			if (uiItemDefs.has(panelId)) {
				let panelSpec = uiItemDefs.get(panelId);
				if (panelSpec.type === ItemTypeET.UIPanel) {
					return panelSpec as IUIPanelSpec;
				}
				console.warn('Groupbox content is not of type panel in UIGroupboxSpec.updateIUIGroupboxSpec()');
			}
			else {
				console.warn(`No panel with id ${panelId} found in UIGroupboxSpec.updateIUIGroupboxSpec()`);
			}
		}
		return undefined;
	}

	constructor(panelSpec: IUIPanelSpec, parent: IContainerLike) {
		super(panelSpec, parent);
		this._widget = WidgetET.Panel;
		this.create();
	}

	protected create() {
		super.create();
	}

	private get uiPanelSpec(): IUIPanelSpec {
		return <IUIPanelSpec>this.spec;
	}

	get transparent(): boolean {
		// TODO: Logik testen!!!
		return this.uiPanelSpec.transparent !== false;
	}

	get headerTemplateId(): string | undefined {
		return this.uiPanelSpec.headerTemplateId;
	}

	get noRelationObjectTemplateId(): string | undefined {
		return this.uiPanelSpec.noRelationObjectTemplateId;
	}

	get header(): string | undefined {
		return this.uiPanelSpec.header;
	}

	get headerId(): string | undefined {
		return this.uiPanelSpec.headerId;
	}

	get panelContentStyle(): ICssStyle | undefined {
		return this.uiPanelSpec.panelContentStyle;
	}
}

export class UIGroupboxSpec extends UIToNRelationSuitableContainerSpec {
	private _content: UIPanelSpec;
	private _headerId: string;
	private _headerTemplateId: string;

	static createIUIGroupboxSpec(data: IUIGroupboxData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIGroupboxSpec {
		let spec: IUIGroupboxSpec = UIToNRelationSuitableContainerSpec.createIToNRelationSuitableContainerSpec(data, uiItemDefs);
		return this.updateIUIGroupboxSpec(spec, data, uiItemDefs, false);
	}

	static updateIUIGroupboxSpec(spec: IUIGroupboxSpec, data: IUIGroupboxDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined, updateBase = true): IUIGroupboxSpec {
		if (updateBase) {
			this.updateIToNRelationSuitableContainerSpec(spec, data);
		}
		let createContent = () => {
			if (data.content) {
				if (typeof(data.content) === 'string') {
					let spec = UIPanelSpec.findIUIPanelSpec(data.content as string, uiItemDefs);
					if (spec) {
						return spec;
					}
				}
				else if ((data.content as UIItemRefData).isItemRef) {
					return UIItemSpec.createIUIItemRef(data.content as UIItemRefData);
				}
				else {
					return UIPanelSpec.createIUIPanelSpec(data.content as IUIPanelData, uiItemDefs);
				}
			}
			if (!spec.content) {
				return UIPanelSpec.createIUIPanelSpec(
					{
						type: 'uiPanel',
						layout: 	<IFlexLayoutData>{
							type: 'flex',
							direction: 'column'
						}
					},
					undefined
				);
			}
			return spec.content;
		};
		let dataAdapted = Object.assign(
			{},
			data,
			{
				content: createContent()
			}
		);
		delete data.content;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	constructor(spec: IUIGroupboxSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.Groupbox;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiGroupboxSpec.items || this.uiGroupboxSpec.layout) {
			throw Error('Invalid GroupboxSpec.');
		}

		if (this.uiGroupboxSpec.headerId) {
			this._headerId = this.uiGroupboxSpec.headerId;
		}
		if (this.uiGroupboxSpec.headerTemplateId) {
			this._headerTemplateId = this.uiGroupboxSpec.headerTemplateId;
		}
		if (this.uiGroupboxSpec.content) {
			if ((this.uiGroupboxSpec.content as IUIItemRef).isItemRef) {
				console.warn(`UIGroupboxSpec.create(): Content of type 'IUIItemRef' is omitted.`);
			}
			else {
				this._content = new UIPanelSpec(this.uiGroupboxSpec.content as IUIPanelSpec, this);
			}
		}
	}

	private get uiGroupboxSpec(): IUIGroupboxSpec {
		return <IUIGroupboxSpec>this.spec;
	}

	get content(): UIPanelSpec | undefined {
		return this._content;
	}

	get header(): ErgNameData | undefined {
		return this.uiGroupboxSpec.header;
	}

	get headerId(): string | undefined {
		return this._headerId;
	}

	get headerIdHandler(): ((source: IComponent, ctx: IEventProcessingContext) => string) | undefined {
		return this.uiGroupboxSpec.headerIdHandler;
	}

	get headerTemplateId(): string | undefined {
		return this._headerTemplateId;
	}

	get noRelationObjectTemplateId(): string | undefined {
		return this.uiGroupboxSpec.noRelationObjectTemplateId;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class GenericContainerSpec extends UIToNRelationSuitableContainerSpec {

	static createIGenericContainerSpec(data: IGenericContainerData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IGenericContainerSpec {
		let spec: IGenericContainerSpec = UIToNRelationSuitableContainerSpec.createIToNRelationSuitableContainerSpec(data, uiItemDefs);
		return this.updateIGenericContainerSpec(spec, data, uiItemDefs, false);
	}

	static updateIGenericContainerSpec(spec: IGenericContainerSpec, data: IGenericContainerDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined, updateBase = true): IGenericContainerSpec {
		if (updateBase) {
			this.updateIToNRelationSuitableContainerSpec(spec, data);
		}
		let createContent = () => {
			if (data.content) {
				if (typeof(data.content) === 'string') {
					let spec = UIPanelSpec.findIUIPanelSpec(data.content as string, uiItemDefs);
					if (spec) {
						return spec;
					}
				}
				else if ((data.content as UIItemRefData).isItemRef) {
					return UIItemSpec.createIUIItemRef(data.content as UIItemRefData);
				}
				else {
					return UIPanelSpec.createIUIPanelSpec(data.content as IUIPanelData, uiItemDefs);
				}
			}
			if (!spec.content) {
				return UIPanelSpec.createIUIPanelSpec(
					{
						type: 'uiPanel',
						layout: 	<IFlexLayoutData>{
							type: 'flex',
							direction: 'column'
						}
					},
					undefined
				);
			}
			return spec.content;
		};
		let createContainerInstanceSpec = () => {
			if (data.containerInstanceSpec) {
				let contInstanceSpec: GenericContainerInstanceSpec = {};
				if (data.containerInstanceSpec.groupbox) {
					contInstanceSpec.groupbox = UIItemSpec.createIUIItemSpec(data.containerInstanceSpec.groupbox, undefined);
				}
				if (data.containerInstanceSpec.expansionPanel) {
					contInstanceSpec.expansionPanel = UIItemSpec.createIUIItemSpec(data.containerInstanceSpec.expansionPanel, undefined);
				}
				return contInstanceSpec;
			}
			return spec.containerInstanceSpec;
		};
		let visibleContainerTypeProviderData = data.visibleContainerTypeProvider;
		delete data.visibleContainerTypeProvider;
		let dataAdapted = Object.assign(
			{},
			data,
			{
				content: createContent(),
				visibleContainerTypeProviderData: visibleContainerTypeProviderData ? visibleContainerTypeProviderData : spec.visibleContainerTypeProviderData,
				containerInstanceSpec: createContainerInstanceSpec()
			}
		);
		delete data.content;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	private _uiGroupboxSpec: UIGroupboxSpec;

	private _pdExpansionPanelSpec: PDExpansionPanelSpec;

	constructor(spec: IGenericContainerSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.GenericContainer;
		this.create();
	}

	protected create() {
		super.create();

		if (this.genericContainerSpec.items || this.genericContainerSpec.layout) {
			throw Error('Invalid GenericContainerSpec.');
		}

		if (this.genericContainerSpec.visibleContainerTypeProviderData) {
			this.genericContainerSpec.visibleContainerTypeProvider = ctx => {
				let data = this.genericContainerSpec.visibleContainerTypeProviderData;
				if (data.expansionPanelCondition) {
					if (ctx.formExpressionGrammarProcessor.evaluateConditionExpression(data.expansionPanelCondition, ctx)) {
						return 'expansionPanel';
					}
				}
				if (data.groupboxCondition) {
					if (ctx.formExpressionGrammarProcessor.evaluateConditionExpression(data.groupboxCondition, ctx)) {
						return 'groupbox';
					}
				}
				return undefined;
			}
		}
	}

	private get genericContainerSpec(): IGenericContainerSpec {
		return <IGenericContainerSpec>this.spec;
	}

	createContainerSpec(ctx: IEventProcessingContext): UIToNRelationSuitableContainerSpec | undefined {
		let visibleContainerType = this.genericContainerSpec.visibleContainerType;
		if (!visibleContainerType && this.genericContainerSpec.visibleContainerTypeProvider) {
			visibleContainerType = this.genericContainerSpec.visibleContainerTypeProvider(ctx);
		}

		let adaptGenericContainerSpec = () => {
			let genericContainerSpecAdapted = Object.assign({}, this.genericContainerSpec);
			delete genericContainerSpecAdapted.visibleContainerType;
			delete genericContainerSpecAdapted.visibleContainerTypeProvider;
			delete genericContainerSpecAdapted.visibleContainerTypeProviderData;
			delete genericContainerSpecAdapted.containerInstanceSpec;
			return genericContainerSpecAdapted;
		};

		let createPDExpansionPanelSpec = () => {
			let spec: IPDExpansionPanelSpec = Object.assign(
				this.genericContainerSpec.containerInstanceSpec?.expansionPanel ?? {},
				adaptGenericContainerSpec(),
				{
					type: ItemTypeET.ExpansionPanel
				}
			);

			/*let genericContainerSpecAdapted = Object.assign({}, this.genericContainerSpec);
			delete genericContainerSpecAdapted.visibleContainerType;
			delete genericContainerSpecAdapted.visibleContainerTypeProvider;
			delete genericContainerSpecAdapted.visibleContainerTypeProviderData;
			delete genericContainerSpecAdapted.containerInstanceSpec;
			let spec: IPDExpansionPanelSpec = Object.assign(genericContainerSpecAdapted, {
				type: ItemTypeET.ExpansionPanel
			});
			if (this.genericContainerSpec.containerInstanceSpec?.expansionPanel) {
				Object.assign(spec, this.genericContainerSpec.containerInstanceSpec?.expansionPanel);
			}*/

			this._pdExpansionPanelSpec = new PDExpansionPanelSpec(spec, this._parent);
			return this._pdExpansionPanelSpec;
		}

		let createUIGroupboxSpec = () => {

			let spec: IUIGroupboxSpec = Object.assign(
				this.genericContainerSpec.containerInstanceSpec?.groupbox ?? {},
				adaptGenericContainerSpec(),
				{
					type: ItemTypeET.UIGroupbox
				}
			);

			/*let genericContainerSpecAdapted = Object.assign({}, this.genericContainerSpec);
			delete genericContainerSpecAdapted.visibleContainerType;
			delete genericContainerSpecAdapted.visibleContainerTypeProvider;
			delete genericContainerSpecAdapted.visibleContainerTypeProviderData;
			delete genericContainerSpecAdapted.containerInstanceSpec;
			let spec: IUIGroupboxSpec = Object.assign(genericContainerSpecAdapted, {
				type: ItemTypeET.UIGroupbox
			});
			if (this.genericContainerSpec.containerInstanceSpec?.groupbox) {
				Object.assign(spec, this.genericContainerSpec.containerInstanceSpec?.groupbox);
			}*/
			
			this._uiGroupboxSpec = new UIGroupboxSpec(spec, this._parent);
			return this._uiGroupboxSpec;
		}

		switch (visibleContainerType) {
			case 'expansionPanel':
				return this._pdExpansionPanelSpec ? this._pdExpansionPanelSpec : createPDExpansionPanelSpec();

			case 'groupbox':
				return this._uiGroupboxSpec ? this._uiGroupboxSpec : createUIGroupboxSpec();

			default:
				return undefined;
		}
	}

	getVisibleContainerType(ctx: IEventProcessingContext): GenericContainerType | undefined {
		if (this.genericContainerSpec.visibleContainerType) {
			return this.genericContainerSpec.visibleContainerType;
		}

		if (this.genericContainerSpec.visibleContainerTypeProvider) {
			return this.genericContainerSpec.visibleContainerTypeProvider(ctx);
		}

		return undefined;
	}
}

export class PDExpansionPanelSpec extends UIToNRelationSuitableContainerSpec {

	static createIPDExpansionPanelSpec(data: IPDExpansionPanelData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IPDExpansionPanelSpec {
		let spec: IPDExpansionPanelSpec = UIToNRelationSuitableContainerSpec.createIToNRelationSuitableContainerSpec(data, uiItemDefs);
		return this.updateIPDExpansionPanelSpec(spec, data, uiItemDefs, false);
	}

	static updateIPDExpansionPanelSpec(spec: IPDExpansionPanelSpec, data: IPDExpansionPanelDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined, updateBase = true): IPDExpansionPanelSpec {
		if (updateBase) {
			this.updateIToNRelationSuitableContainerSpec(spec, data);
		}
		let createContent = () => {
			if (data.content) {
				if (typeof(data.content) === 'string') {
					let spec = UIPanelSpec.findIUIPanelSpec(data.content as string, uiItemDefs);
					if (spec) {
						return spec;
					}
				}
				else if ((data.content as UIItemRefData).isItemRef) {
					return UIItemSpec.createIUIItemRef(data.content as UIItemRefData);
				}
				else {
					return UIPanelSpec.createIUIPanelSpec(data.content as IUIPanelData, uiItemDefs);
				}
			}
			if (!spec.content) {
				return UIPanelSpec.createIUIPanelSpec(
					{
						type: 'uiPanel',
						layout: 	<IFlexLayoutData>{
							type: 'flex',
							direction: 'column'
						}
					},
					undefined
				);
			}
			return spec.content;
		};
		let dataAdapted = Object.assign(
			{},
			data,
			{
				content: createContent()
			}
		);
		delete data.content;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	private _content: UIPanelSpec;

	constructor(spec: IPDExpansionPanelSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.ExpansionPanel;
		this.create();
	}

	protected create() {
		super.create();

		if (this.expansionPanelSpec.items || this.expansionPanelSpec.layout) {
			throw Error('Invalid ExpansionPanelSpec.');
		}

		if (this.expansionPanelSpec.content) {
			if ((this.expansionPanelSpec.content as IUIItemRef).isItemRef) {
				console.warn(`PDExpansionPanelSpec.create(): Content of type 'IUIItemRef' is omitted.`);
			}
			else {
				this._content = new UIPanelSpec(this.expansionPanelSpec.content as IUIPanelSpec, this);
			}
		}
	}

	private get expansionPanelSpec(): IPDExpansionPanelSpec {
		return <IPDExpansionPanelSpec>this.spec;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get content(): UIPanelSpec | undefined {
		return this._content;
	}

	get headerTemplateId(): string | undefined {
		return this.expansionPanelSpec.headerTemplateId;
	}

	get header(): ErgNameData | undefined {
		return this.expansionPanelSpec.header;
	}

	get headerId(): string | undefined {
		return this.expansionPanelSpec.headerId;
	}

	get headerIdHandler(): ((source: IComponent, ctx: IEventProcessingContext) => string) | undefined {
		return this.expansionPanelSpec.headerIdHandler;
	}

	get noRelationObjectTemplateId(): string | undefined {
		return this.expansionPanelSpec.noRelationObjectTemplateId;
	}

	get expanded(): boolean | undefined {
		return this.expansionPanelSpec.expanded;
	}

	get expandedProvider(): PDExpansionPanelExpandedProvider | undefined {
		return this.expansionPanelSpec.expandedProvider;
	}
}

export class UIRadioButtonGroupSpec extends UIContainerSpec {
	private _dataType: RadioButtonGroupDataTypeET;
	private _content?: UIPanelSpec;
	private _header?: string;
	private _property?: string;

	static createIUIRadioButtonGroupSpec(data: IUIRadioButtonGroupData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIRadioButtonGroupSpec {
		let spec: IUIRadioButtonGroupSpec = Object.assign(
			this.createIUIContainerSpec(data, uiItemDefs),
			{
				dataType: data.dataType
			}
		);
		delete data.dataType;
		return this.updateIUIRadioButtonGroupSpec(spec, data, uiItemDefs, false);
		/*let spec: IUIRadioButtonGroupSpec = Object.assign(
			{
				dataType: data.dataType,
				bulletLocation: data.bulletLocation, // todo: bulletLocation fehlt bei IUIRadioButtonGroupSpec
				content: data.content ? UIPanelSpec.createIUIPanelSpec(data.content) : undefined,
				header: data.header, // todo: ErgNameData
				label: data.label,
				property: data.property,
				wrapLabel: data.wrapLabel
			},
			UIContainerSpec.createIUIContainerSpec(data)
		);
		spec.items = undefined;
		if (data.radioButtons) {
			spec.radioButtons = data.radioButtons.map(rb => PDItemSpec.createIPDItemSpec(rb));
		}

		return spec;*/
	}

	static updateIUIRadioButtonGroupSpec(spec: IUIRadioButtonGroupSpec, data: IUIRadioButtonGroupDataMutable,
			uiItemDefs: Map<string, IUIItemSpec> | undefined, updateBase = true): IUIRadioButtonGroupSpec {
		if (updateBase) {
			this.updateIUIContainerSpec(spec, data);
		}
		let createContent = () => {
			if (data.content) {
				if (typeof(data.content) === 'string') {
					let spec = UIPanelSpec.findIUIPanelSpec(data.content as string, uiItemDefs);
					if (spec) {
						return spec;
					}
				}
				else if ((data.content as UIItemRefData).isItemRef) {
					return UIItemSpec.createIUIItemRef(data.content as UIItemRefData);
				}
				else {
					return UIPanelSpec.createIUIPanelSpec(data.content as IUIPanelData, uiItemDefs);
				}
			}
			/*if (!spec.content) {
				return UIPanelSpec.createIUIPanelSpec(
					{
						type: 'uiPanel',
						layout: 	<IFlexLayoutData>{
							type: 'flex',
							direction: 'column'
						}
					},
					undefined
				);
			}*/
			return spec.content;
		};

		let dataAdapted = Object.assign(
			{},
			data,
			{
				//content: data.content ? UIPanelSpec.createIUIPanelSpec(data.content, undefined) : spec.content,
				content: createContent(),
				radioButtons: data.radioButtons ? data.radioButtons.map(rb => PDItemSpec.createIPDItemSpec(rb)) : spec.radioButtons,
				items: undefined

			}
		);
		delete data.content;
		delete data.radioButtons;
		return Object.assign(
			spec,
			dataAdapted
		);
	}

	constructor(spec: IUIRadioButtonGroupSpec, parent: IContainerLike) {
		super(spec, parent);

		/*if (spec.content) {
			for (let item of spec.content.items) {
				if ((item.type != ItemTypeET.PDItem && item.type != ItemTypeET.PDRadioButton) ||
					(<IPDRadioButtonSpec>item).widget != WidgetET.RadioButton) {
					throw Error('Invalid RadioButtonGroupSpec.');
				}
			}
		}*/
		this._widget = WidgetET.RadioButtonGroup;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiRadioButtonGroupSpec.items/* || this.uiRadioButtonGroupSpec.layout*/) {
			throw Error('Invalid RadioButtonGroupSpec.');
		}

		this._dataType = this.uiRadioButtonGroupSpec.dataType;
		if (this.uiRadioButtonGroupSpec.header) {
			this._header = this.uiRadioButtonGroupSpec.header;
		}
		if (this.uiRadioButtonGroupSpec.property) {
			this._property = this.uiRadioButtonGroupSpec.property;
		}

		//let content: IUIPanelSpec = this.uiRadioButtonGroupSpec.content;
		let content: IUIPanelSpec;
		if (this.uiRadioButtonGroupSpec.content) {
			if ((this.uiRadioButtonGroupSpec.content as IUIItemRef).isItemRef) {
				console.warn(`UIRadioButtonGroupSpec.create(): Content of type 'IUIItemRef' is omitted.`);
			}
			else {
				content = this.uiRadioButtonGroupSpec.content as IUIPanelSpec;
			}
		}

		if (!content) {
			content = {
				type: ItemTypeET.UIPanel
			}
		}
		if (!content.layout) {
			content.layout = this.uiRadioButtonGroupSpec.layout ?
				this.uiRadioButtonGroupSpec.layout :
				<IFlexLayoutSpec>{
					type: LayoutTypeET.Flex,
					direction: FlexDirectionET.Column
				}
		}
		if (!content.items) {
			content.items = [];
		}

		if (this.uiRadioButtonGroupSpec.radioButtons) {
			this.uiRadioButtonGroupSpec.radioButtons.forEach(rb => {
				if (rb.widget !== WidgetET.RadioButton) {
					throw Error('Invalid radio buttons in RadioButtonGroupSpec.');
				}
				rb.property = this._property;
				content.items.push(rb);
			});
		}
		this._content = new UIPanelSpec(content, this);

		/*if (this.uiRadioButtonGroupSpec.content) {
			if (this.uiRadioButtonGroupSpec.layout && !this.uiRadioButtonGroupSpec.content.layout) {
				this.uiRadioButtonGroupSpec.content.layout = this.uiRadioButtonGroupSpec.layout;
			}
			this._content = new UIPanelSpec(this.uiRadioButtonGroupSpec.content, this);
		}
		else {
			let defaultContent: IUIPanelSpec = {
				type: ItemTypeET.UIPanel,
				layout: this.uiRadioButtonGroupSpec.layout ?
					this.uiRadioButtonGroupSpec.layout :
					<IFlexLayoutSpec>{
						type: LayoutTypeET.Flex,
						direction: FlexDirectionET.Column
					}
			}
			this._content = new UIPanelSpec(defaultContent, this);
		}*/

		if (this.to1RelationItemSpec) {
			let radioButtons: PDItemSpec[] = [];
			this.getRadioButtons(this.content, radioButtons);
			radioButtons.forEach(rb => {
				rb.pdItemSpec.to1RelationItemSpec = Object.assign({}, this.to1RelationItemSpec);
				//rb.to1RelationItemSpec = Object.assign({}, this.to1RelationItemSpec);
			});
		}


	}

	private getRadioButtons(cont: UIContainerSpec, radioButtons: PDItemSpec[]): void {
		if (cont.items) {
			cont.items.filter(item => item instanceof UIItemSpec)
				.map(item => item as UIItemSpec)
				.forEach(item => {
				switch (item.type) {
					case ItemTypeET.PDItem:
						if ((item as IPDItemSpec).widget == WidgetET.RadioButton) {
							radioButtons.push(item as PDItemSpec);
						}
						break;

					case ItemTypeET.UIPanel:
						this.getRadioButtons(item as UIContainerSpec, radioButtons);
						break;
				}
			});
		}
	}


	private get uiRadioButtonGroupSpec(): IUIRadioButtonGroupSpec {
		return <IUIRadioButtonGroupSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}

	get property(): string {
		return this._property;
	}

	get dataType(): RadioButtonGroupDataTypeET {
		return this._dataType;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get wrapLabel(): boolean {
		return this.uiRadioButtonGroupSpec.wrapLabel !== false;
	}

	get bulletLocation(): RadioButtonBulletLocationET | undefined {
		return this.uiRadioButtonGroupSpec.bulletLocation;
	}

	get label(): ErgNameData | undefined {
		return this.uiRadioButtonGroupSpec.label;
	}
}

export class UITabViewSpec extends UIContainerSpec {
	private _panels: UITabPanelSpec[] = [];

	constructor(spec: IUITabViewSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.TabView;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiTabViewSpec.items || this.uiTabViewSpec.layout) {
			throw Error('Invalid UITabViewSpec.');
		}

		if (this.uiTabViewSpec.panels) {
			for (let panel of this.uiTabViewSpec.panels) {
				this.panels.push(new UITabPanelSpec(panel, this));
			}
		}
	}

	private get uiTabViewSpec(): IUITabViewSpec {
		return <IUITabViewSpec>this.spec;
	}

	get panels(): UITabPanelSpec[] {
		return this._panels;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let panel of this.panels) {
			list.push(panel);
			let childs = panel.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class UITabPanelSpec extends UIContainerSpec {
	private _content?: UIPanelSpec;
	private _header?: string;
	private _headerId: string;

	constructor(spec: IUITabPanelSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.TabPanel;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiTabPanelSpec.items || this.uiTabPanelSpec.layout) {
			throw Error('Invalid UITabPanelSpec.');
		}

		if (this.uiTabPanelSpec.header) {
			this._header = this.uiTabPanelSpec.header;
		}
		if (this.uiTabPanelSpec.headerId) {
			this._headerId = this.uiTabPanelSpec.headerId;
		}
		if (this.uiTabPanelSpec.content) {
			this._content = new UIPanelSpec(this.uiTabPanelSpec.content, this);
		}
	}

	private get uiTabPanelSpec(): IUITabPanelSpec {
		return <IUITabPanelSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}

	get headerId(): string {
		return this._headerId;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class UIDrawerItemSpec {

	static createIUIDrawerItemSpec(data: IUIDrawerItemData, uiItemDefs: Map<string, IUIItemSpec> | undefined): IUIDrawerItemSpec {
		return {
			item: data.item,
			content: UIPanelSpec.createIUIPanelSpec(data.content, uiItemDefs)
		};
	}

	get item(): IUIDrawerItem {
		return this._itemSpec.item;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	private _content: UIPanelSpec;

	get updateUIStateHandler(): ((source: IDrawerItemComponent, ctx: IEventProcessingContext) => IComponentUIState | undefined) | undefined {
		return this._itemSpec.updateUIStateHandler;
	}

	/*constructor(private _item: IUIDrawerItem, private _content: UIPanelSpec) {
	}*/
	constructor(private _itemSpec: IUIDrawerItemSpec, drawerSpec: UIDrawerSpec) {
		this._content = new UIPanelSpec(_itemSpec.content, drawerSpec)
	}
}

export class UIDrawerSpec extends UIContainerSpec {

	static updateIUIDrawerSpec(spec: IUIDrawerSpec, data: IUIDrawerDataMutable, uiItemDefs: Map<string, IUIItemSpec> | undefined, updateBase = true): IUIDrawerSpec {
		const newDrawerItems =  data.newDrawerItems;
		delete data.newDrawerItems;
		const drawerItems = data.drawerItems;
		delete data.drawerItems;

		if (updateBase) {
			this.updateIUIContainerSpec(spec, data);
		}

		if (newDrawerItems) {
			newDrawerItems.forEach(item => {
				let itemSpec = UIDrawerItemSpec.createIUIDrawerItemSpec(item.drawerItem, uiItemDefs);
				switch (item.position) {
					case 'start':
						spec.drawerItems.splice(0, 0, itemSpec);
						break;
					case undefined:
					case 'end':
						spec.drawerItems.push(itemSpec);
						break;
					default:
						if (item.position >= 0 && item.position < spec.drawerItems.length) {
							spec.drawerItems.splice(item.position, 0, itemSpec);
						}
						break;
				}
				if (itemSpec.item.selected) {
					spec.drawerItems.filter(di => di !== itemSpec).forEach(di => di.item.selected = false);
				}
			});
		}
		if (drawerItems) {
			drawerItems.forEach(item => {
				let itemSpecIndex = spec.drawerItems.findIndex(spec => spec.item.id === item.itemId);
				if (itemSpecIndex >= 0) {
					let itemSpec = spec.drawerItems[itemSpecIndex];
					if (item.icon)	{
						itemSpec.item.icon = item.icon;
					}
					if (item.text) {
						itemSpec.item.text = item.text.de; // todo
					}
					if (item.moveTo) {
						switch (item.moveTo.position) {
							case 'start':
								spec.drawerItems.splice(itemSpecIndex, 1);
								spec.drawerItems.unshift(itemSpec);
								break;
							case 'end':
								spec.drawerItems.splice(itemSpecIndex, 1);
								spec.drawerItems.push(itemSpec);
								break;
							default: {
								let targetSpecIndex = spec.drawerItems.findIndex(spec => spec.item.id === item.moveTo.targetId);
								if (targetSpecIndex >= 0) {
									spec.drawerItems.splice(itemSpecIndex, 1);
									targetSpecIndex = spec.drawerItems.findIndex(spec => spec.item.id === item.moveTo.targetId);
									if (item.moveTo.position === 'after') {
										targetSpecIndex++;
									}
									spec.drawerItems.splice(targetSpecIndex, 0, itemSpec);
								}
								break;
							}
						}
					}
					if (item.shortDescription) {
						itemSpec.item.shortDescription = item.shortDescription;
					}
					if (item.uiState) {
						// FIXME: integrateUIState macht sowas eigentlich. Da es allerdings
						// kurz vorm release war, wollte ich den "alten" Code nicht ändern
						const determineUIState = (
							originState: boolean | undefined,
							wantedState: boolean | UIStateValue | undefined
						) => {
							let value = typeof(wantedState) === 'object' ? wantedState.value : wantedState;
							let overwrite = typeof(wantedState) === 'object' ? wantedState.overwriteOriginalState : false;

							if (overwrite) {
								return value;
							}
							return value ? true : originState;
						}

						const handleState = (originalState: IComponentUIState): IComponentUIState => {
							let state: IComponentUIState;

							if (item.uiState.hidden !== undefined || item.uiState.mandatory !== undefined ||
									item.uiState.readonly !== undefined || item.uiState.disabled !== undefined)
							{
								state = {};
								state.hidden = determineUIState(originalState?.hidden, item.uiState.hidden);
								state.mandatory = determineUIState(originalState?.mandatory, item.uiState.mandatory);
								state.readonly = determineUIState(originalState?.readonly, item.uiState.readonly);
								state.disabled = determineUIState(originalState?.disabled, item.uiState.disabled);
							}
							return state;
						}

						const currentHandlerItem = itemSpec.updateUIStateHandler;
						itemSpec.updateUIStateHandler = (source, ctx) => {
							const originalState = currentHandlerItem ? currentHandlerItem(source, ctx) : undefined;
							return handleState(originalState);
						}

						const currentHandlerContent = itemSpec.content.updateUIStateHandler;
						itemSpec.content.updateUIStateHandler = (source, currentState, ctx) => {
							const originalState = currentHandlerContent ? currentHandlerContent(source, currentState, ctx) : undefined;
							return handleState(originalState);
						}
					}
				}
			});
		}
		return spec;
	}


	private _uiDrawerItemSpecs: UIDrawerItemSpec[] = [];

	constructor(spec: IUIDrawerSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.Drawer;
		this.create();
	}

	protected create() {
		super.create();

		for (let item of this.uiDrawerSpec.drawerItems) {
			//this._uiDrawerItemSpecs.push(new UIDrawerItemSpec(item.item, new UIPanelSpec(item.content, this)));
			this._uiDrawerItemSpecs.push(new UIDrawerItemSpec(item, this));
		}
	}

	private get uiDrawerSpec(): IUIDrawerSpec {
		return <IUIDrawerSpec>this.spec;
	}

	get drawerItems(): UIDrawerItemSpec[] {
		return this._uiDrawerItemSpecs;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let item of this._uiDrawerItemSpecs) {
			list.push(item.content);
			let childs = item.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get mode(): DrawerModeET | undefined {
		return this.uiDrawerSpec.mode;
	}

	get expandMode(): DrawerExpandModeT | undefined {
		return this.uiDrawerSpec.expandMode;
	}

	get position(): DrawerPositionT | undefined {
		return this.uiDrawerSpec.position;
	}

	get expanded(): boolean | undefined {
		return this.uiDrawerSpec.expanded;
	}

	get onItemSelected(): ((ctx: IEventProcessingContext, item: IUIDrawerItem) => void) | undefined {
		return this.uiDrawerSpec.onItemSelected;
	}
}

export class PDRelationTabSpec extends UIContainerSpec {

	static updateIPDRelationTabSpec(spec: IPDRelationTabSpec, data: IPDRelationTabDataMutable, updateBase = true): IPDRelationTabSpec {
		if (updateBase) {
			this.updateIUIContainerSpec(spec, data);
		}
		if (!spec.widgetInfo) {
			spec.widgetInfo = RelationTabWidgetInfo.createIRelationTabWidgetInfo(undefined)
		}
		RelationTabWidgetInfo.updateIRelationTabWidgetInfo(spec.widgetInfo, data.widget);
		return spec;
	}

	private _content: UIPanelSpec;
	private _property: string;

	constructor(spec: IPDRelationTabSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = WidgetET.RelationTab;
		this.create();
	}

	protected create() {
		super.create();

		if (this.pdRelationTabSpec.items || this.pdRelationTabSpec.layout) {
			throw Error('Invalid PDRelationTabSpec.');
		}

		this._property = this.pdRelationTabSpec.property;

		if (this.pdRelationTabSpec.content) {
			this._content = new UIPanelSpec(this.pdRelationTabSpec.content, this);
		}
		/*if (this.pdRelationTabSpec.objCreator) {
			this._objCreator = this.pdRelationTabSpec.objCreator;
		}*/
	}

	private get pdRelationTabSpec(): IPDRelationTabSpec {
		return <IPDRelationTabSpec>this.spec;
	}

	get content(): UIPanelSpec | undefined {
		return this._content;
	}

	get property(): string {
		return this._property;
	}

	/*get objCreator(): new () => PDObject | undefined  {
		return this._objCreator;
	}*/

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}


//-------------------------------------------------------------------------------------------------
// Layout
//-------------------------------------------------------------------------------------------------

export abstract class LayoutSpec {
	static createLayoutSpec(layout: ILayoutSpec): LayoutSpec {
		switch (layout.type) {
			case LayoutTypeET.Grid:
				return new GridLayoutSpec(<IGridLayoutSpec>layout);
			case LayoutTypeET.Flex:
				return new FlexLayoutSpec(<IFlexLayoutSpec>layout);
		}
		throw Error(`Unknown layout type ${layout.type}`);
	}

	static createILayoutSpec(data: ILayoutData): ILayoutSpec {
		switch (data.type) {
			case 'grid':
				return GridLayoutSpec.createILayoutSpec(<IGridLayoutData>data);
			case 'flex':
				return FlexLayoutSpec.createILayoutSpec(<IFlexLayoutData>data);
			default:
				throw Error(`Unknown layout data ${data.type}`);
		}
	}

	protected spec: ILayoutSpec;

	constructor(spec: ILayoutSpec) {
		this.spec = spec;
	}

	protected create() {
	}

	get rawStyles(): object | undefined {
		return this.spec.rawStyles;
	}
}

export class GridLayoutSpec extends LayoutSpec {

	static createILayoutSpec(data: IGridLayoutData): IGridLayoutSpec {
		let spec: IGridLayoutSpec = {
			type: LayoutTypeET.Grid
		}
		if (data.columns) {
			spec.columns = [];
			data.columns.forEach(c => {
				spec.columns.push({ width: c.width});
			});
		}
		if (data.rows) {
			spec.rows = [];
			data.rows.forEach(r => {
				spec.rows.push({ height: r.height});
			});
		}
		return Object.assign({}, data, spec);
	}

	private _columns?: IColumnSpec[];
	private _rows?: IRowSpec[];

	constructor(spec: IGridLayoutSpec) {
		super(spec);
		this.create();
	}

	get gridLayoutSpec(): IGridLayoutSpec {
		return <IGridLayoutSpec>this.spec;
	}

	get columns(): IColumnSpec[] | undefined {
		return this._columns;
	}

	get rows(): IRowSpec[] | undefined {
		return this._rows;
	}

	get gridGap(): string | undefined {
		return this.gridLayoutSpec.gridGap;
	}

	get alignItems(): string | undefined {
		return this.gridLayoutSpec.alignItems;
	}

	get justifyItems(): string | undefined {
		return this.gridLayoutSpec.justifyItems
	}

	protected create() {
		super.create();
		this._columns = this.gridLayoutSpec.columns;
		this._rows = this.gridLayoutSpec.rows;
	}
}

export class FlexLayoutSpec extends LayoutSpec {

	static createILayoutSpec(data: IFlexLayoutData): IFlexLayoutSpec {
		let spec: IFlexLayoutSpec = {
			type: LayoutTypeET.Flex
		}
		switch (data.direction) {
			case 'column':
				spec.direction = FlexDirectionET.Column;
				break;
			case 'row': {
				spec.direction = FlexDirectionET.Row
				break;
			}
		}
		return Object.assign({}, data, spec);
	}

	private _direction: FlexDirectionET = FlexDirectionET.Row;
	private _wrap: FlexWrapET | string;
	private _justifyContent: FlexJustifyContentET | string;
	private _alignItems: FlexAlignET | string;
	private _alignContent: FlexAlignContentET | string;
	//private _alignSelf: FlexAlignSelfET | string;
	//private _flex: FlexFlexET | string;

	constructor(spec: IFlexLayoutSpec) {
		super(spec);
		this.create();
	}

	get flexLayoutSpec(): IFlexLayoutSpec {
		return <IFlexLayoutSpec>this.spec;
	}

	protected create() {
		super.create();

		if (this.flexLayoutSpec.direction) {
			this._direction = this.flexLayoutSpec.direction;
		}
		if (this.flexLayoutSpec.wrap) {
			this._wrap = this.flexLayoutSpec.wrap;
		}
		if (this.flexLayoutSpec.justifyContent) {
			this._justifyContent = this.flexLayoutSpec.justifyContent;
		}
		if (this.flexLayoutSpec.alignItems) {
			this._alignItems = this.flexLayoutSpec.alignItems;
		}
		if (this.flexLayoutSpec.alignContent) {
			this._alignContent = this.flexLayoutSpec.alignContent;
		}
		/*if (this.flexLayoutSpec.alignSelf) {
			this._alignSelf = this.flexLayoutSpec.alignSelf;
		}
		if (this.flexLayoutSpec.flex) {
			this._flex = this.flexLayoutSpec.flex;
		}*/
	}

	get direction(): FlexDirectionET {
		return this._direction;
	}

	get wrap(): string {
		return this._wrap;
	}

	get justifyContent(): string {
		return this._justifyContent;
	}

	get alignItems(): string {
		return this._alignItems;
	}

	get alignContent(): string {
		return this._alignContent;
	}

	get gap(): string | undefined {
		return this.flexLayoutSpec.gap;
	}

	/*get alignSelf(): string {
		return this._alignSelf;
	}*/

	/*get flex(): string {
		return this._flex;
	}*/
}

export abstract class UIItemPlacement {
	static createUIItemPlacement(layout: LayoutSpec, placement: IUIItemPlacement): UIItemPlacement {
		if (layout instanceof GridLayoutSpec) {
			return new GridItemPlacement(<IGridItemPlacement>placement);
		}
		if (layout instanceof FlexLayoutSpec) {
			return new FlexItemPlacement(<IFlexItemPlacement>placement);
		}
		return undefined;
	}

	static createIUIItemPlacement(data: IUIItemPlacementData): IUIItemPlacement {
		return Object.assign({}, data);
	}

	protected placement: IUIItemPlacement;

	constructor(placement: IUIItemPlacement) {
		this.placement = placement;
	}

	protected create() {
	}
}

export class GridItemPlacement extends UIItemPlacement {
	private _row?: number;
	private _col?: number;
	private _colspan?: number;
	private _rowspan?: number;

	constructor(placement: IGridItemPlacement) {
		super(placement);
		this.create();
	}

	protected create() {
		super.create();

		if (this.gridItemPlacement) {
			if (this.gridItemPlacement.row) {
				this._row = this.gridItemPlacement.row;
			}
			if (this.gridItemPlacement.col) {
				this._col = this.gridItemPlacement.col;
			}
			if (this.gridItemPlacement.rowspan) {
				this._rowspan = this.gridItemPlacement.rowspan;
			}
			if (this.gridItemPlacement.colspan) {
				this._colspan = this.gridItemPlacement.colspan;
			}
		}
	}

	get gridItemPlacement(): IGridItemPlacement {
		return <IGridItemPlacement>this.placement;
	}

	get row(): number {
		return this._row;
	}

	get col(): number {
		return this._col;
	}

	get colspan(): number {
		return this._colspan;
	}

	get rowspan(): number {
		return this._rowspan;
	}
}

export class FlexItemPlacement extends UIItemPlacement {
	private _flex: string;
	private _align: FlexAlignET;

	constructor(placement: IFlexItemPlacement) {
		super(placement);
		this.create();
	}

	protected create() {
		super.create();

		if (this.flexItemPlacement) {
			if (this.flexItemPlacement.flex) {
				this._flex = this.flexItemPlacement.flex;
			}
			if (this.flexItemPlacement.align) {
				this._align = this.flexItemPlacement.align;
			}
		}
	}

	private get flexItemPlacement(): IFlexItemPlacement {
		return <IFlexItemPlacement>this.placement;
	}

	get flex(): string {
		return this._flex;
	}

	get align(): string {
		return this._align;
	}
}


//-------------------------------------------------------------------------------------------------
// Widgets
//-------------------------------------------------------------------------------------------------

export abstract class WidgetInfo {
	static createWidgetInfo(spec: IWidgetInfo, type: WidgetET): WidgetInfo {
		switch (type) {
			case WidgetET.RadioButton:
				return new RadioButtonWidgetInfo(<IRadioButtonWidgetInfo>spec);
			case WidgetET.RadioButtonGroup:
				return new RadioButtonGroupWidgetInfo(<IRadioButtonGroupWidgetInfo>spec);
			case WidgetET.DateTimeField:
				return new DateTimeFieldWidgetInfo(<IDateTimeFieldWidgetInfo>spec);
			case WidgetET.TextField:
				return new TextFieldWidgetInfo(<ITextFieldWidgetInfo>spec);
			case WidgetET.NumericTextField:
				return new NumericTextFieldWidgetInfo(<INumericTextFieldWidgetInfo>spec);
			case WidgetET.InfoField:
				return new InfoFieldWidgetInfo(<IInfoFieldWidgetInfo>spec);
			case WidgetET.DropDown:
				return new DropDownWidgetInfo(<IDropDownWidgetInfo>spec);
			case WidgetET.Listview:
				return new ListviewWidgetInfo(<IListviewWidgetInfo>spec);
			case WidgetET.ComboBox:
				return new ComboBoxWidgetInfo(<IComboBoxWidgetInfo>spec);
			case WidgetET.InputSwitch:
				return new InputSwitchWidgetInfo(<IInputSwitchWidgetInfo>spec);
			case WidgetET.ObjectReference:
				return new ObjectReferenceWidgetInfo(<IObjectReferenceWidgetInfo>spec);
			case WidgetET.RelationTab:
				return new RelationTabWidgetInfo(<IRelationTabWidgetInfo>spec);
			case WidgetET.MultiSelect:
				return new MultiSelectRelationWidgetInfo(<IMultiSelectRelationWidgetInfo>spec);
			//case WidgetET.RelationList:
			//	return new RelationListWidgetInfo(<IRelationListWidgetInfo<T>>spec);
			case WidgetET.Accordion:
				return new AccordionWidgetInfo(<IAccordionWidgetInfo>spec);
			case WidgetET.Label:
				return new LabelWidgetInfo(<ILabelWidgetInfo>spec);
			case WidgetET.Button:
				return new ButtonWidgetInfo(<IButtonWidgetInfo>spec);
			case WidgetET.Checkbox:
				return new CheckboxWidgetInfo(<ICheckboxWidgetInfo>spec);
			case WidgetET.FileUpload:
				return new FileUploadWidgetInfo(<IFileUploadWidgetInfo>spec);
			case WidgetET.RelationGrid:
				return new RelationGridWidgetInfo(<IRelationGridWidgetInfo>spec);
			default:
				throw Error(`Unknown widget info for type ${type}`);
		}
	}

	static createIWidgetInfo(data: IWidgetData | undefined): IWidgetInfo {
		let wi: IWidgetInfo = {};
		return data ? this.updateIWidgetInfo(wi, data) : wi;
	}

	static updateIWidgetInfo(wi: IWidgetInfo, data: IWidgetDataMutable): IWidgetInfo {
		return wi;
	}

	private _guiReadonly: boolean = false;
	protected widgetInfo: IWidgetInfo;

	constructor(widgetInfo: IWidgetInfo) {
		this.widgetInfo = widgetInfo;
	}

	protected create() {
		if (this.widgetInfo.guiReadonly) {
			this._guiReadonly = this.widgetInfo.guiReadonly;
		}
	}

	get guiReadonly(): boolean {
		return this._guiReadonly;
	}
}

export class LabelWidgetInfo extends WidgetInfo {

	static createILabelWidgetInfo(data: ILabelWidgetData | undefined): ILabelWidgetInfo {
		let wi: ILabelWidgetInfo = WidgetInfo.createIWidgetInfo(data)
		return data ? this.updateILabelWidgetInfo(wi, data, false) : wi;

		/*let wi: ILabelWidgetInfo = WidgetInfo.createIWidgetInfo(data);
		wi.styleSpec = data.styleSpec;
		return wi;*/
	}

	static updateILabelWidgetInfo(wi: ILabelWidgetInfo, data: ILabelWidgetDataMutable, updateBase = true): ILabelWidgetInfo {
		if (updateBase) {
			WidgetInfo.updateIWidgetInfo(wi, data);
		}
		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: ILabelWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get labelWidgetInfo(): ILabelWidgetInfo {
		return <ILabelWidgetInfo>this.widgetInfo;
	}

	get styleSpec(): IStyleSpec | undefined {
		return this.labelWidgetInfo.styleSpec;
	}
}

export class ButtonWidgetInfo extends WidgetInfo {
	private _label: string;
	private _labelId: string;
	private _iconClass: string;

	constructor(widgetInfo: IButtonWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.buttonWidgetInfo.label) {
			this._label = this.buttonWidgetInfo.label;
		}
		if (this.buttonWidgetInfo.labelId) {
			this._labelId = this.buttonWidgetInfo.labelId;
		}
		if (this.buttonWidgetInfo.iconClass) {
			this._iconClass = this.buttonWidgetInfo.iconClass;
		}
	}

	private get buttonWidgetInfo(): IButtonWidgetInfo {
		return <IButtonWidgetInfo>this.widgetInfo;
	}

	get label(): string | undefined {
		return this._label;
	}

	get labelId(): string | undefined {
		return this._labelId;
	}

	get iconClass(): string | undefined {
		return this._iconClass;
	}

	get clickHandler(): (() => void) | undefined {
		return this.buttonWidgetInfo.clickHandler;
	}
}

export class CheckboxWidgetInfo extends WidgetInfo {
	constructor(widgetInfo: ICheckboxWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	private get checkboxWidgetInfo(): ICheckboxWidgetInfo {
		return <ICheckboxWidgetInfo>this.widgetInfo;
	}

	get requiredTrue(): boolean {
		return this.checkboxWidgetInfo.requiredTrue === true;
	}

	get valueChangedHandler(): ((source: ICheckBoxComponent, value: boolean, ctx: IEventProcessingContext) => void) | undefined {
		return this.checkboxWidgetInfo.valueChangedHandler;
	}

	get labelVisibility(): VisibilityET {
		return this.checkboxWidgetInfo.labelVisibility;
	}

	get canIndeterminate(): boolean {
		return this.checkboxWidgetInfo.canIndeterminate;
	}
}

export class RadioButtonWidgetInfo extends WidgetInfo {
	static createIRadioButtonWidgetInfo(data: IRadioButtonWidgetData): IRadioButtonWidgetInfo {
		let wi: IRadioButtonWidgetInfo = Object.assign(
			{ value: data.value },
			WidgetInfo.createIWidgetInfo(data)
		);
		return data ? this.updateIRadioButtonWidgetInfo(wi, data, false) : wi;
	}

	static updateIRadioButtonWidgetInfo(wi: IRadioButtonWidgetInfo, data: IRadioButtonWidgetDataMutable, updateBase = true): IRadioButtonWidgetInfo {
		if (updateBase) {
			WidgetInfo.updateIWidgetInfo(wi, data);
		}
		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: IRadioButtonWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get radioButtonWidgetInfo(): IRadioButtonWidgetInfo {
		return <IRadioButtonWidgetInfo>this.widgetInfo;
	}

	get value(): boolean | number | string {
		return this.radioButtonWidgetInfo.value;
	}

	get bulletLocation(): RadioButtonBulletLocationET | undefined {
		return this.radioButtonWidgetInfo.bulletLocation;
	}
}

export class RadioButtonGroupWidgetInfo extends WidgetInfo {

	constructor(widgetInfo: IRadioButtonGroupWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get radioButtonGroupWidgetInfo(): IRadioButtonGroupWidgetInfo {
		return <IRadioButtonWidgetInfo>this.widgetInfo;
	}

	get selectionChangedHandler(): ((source: IRadioButtonGroupComponent, selection: IRadioButtonComponent, ctx: IEventProcessingContext) => void) | undefined {
		return this.radioButtonGroupWidgetInfo.selectionChangedHandler;
	}
}

export abstract class LabeledControlWidgetInfo extends WidgetInfo {
	private _prefix?: string;
	private _prefixWidth?: string;
	private _labelVisibility: VisibilityET = VisibilityET.Visible;
	private _labelPosition: LabelPositionET = LabelPositionET.Top;
	private _toolbar: ToolbarSpec;

	static createILabeledControlWidgetInfo(data: ILabeledControlWidgetData | undefined): ILabeledControlWidgetInfo {
		let wi: ILabeledControlWidgetInfo = WidgetInfo.createIWidgetInfo(data);
		/*if (data.labelVisibility) {
			switch (data.labelVisibility) {
				case 'collapsed':
					wi.labelVisibility = VisibilityET.Collapsed;
					break;
				case 'hidden':
					wi.labelVisibility = VisibilityET.Hidden;
					break;
				case 'visible':
					wi.labelVisibility = VisibilityET.Visible;
					break;
			}
		}*/
		return data ? this.updateILabeledControlWidgetInfo(wi, data, false) : wi;
	}

	static updateILabeledControlWidgetInfo(wi: ILabeledControlWidgetInfo, data: ILabeledControlWidgetDataMutable, updateBase = true): ILabeledControlWidgetInfo {
		if (updateBase) {
			WidgetInfo.updateIWidgetInfo(wi, data);
		}
		switch (data?.labelVisibility) {
			case 'collapsed':
				wi.labelVisibility = VisibilityET.Collapsed;
				break;
			case 'hidden':
				wi.labelVisibility = VisibilityET.Hidden;
				break;
			case 'visible':
				wi.labelVisibility = VisibilityET.Visible;
				break;
		}
		return wi;
	}

	constructor(widgetInfo: ILabeledControlWidgetInfo) {
		super(widgetInfo);
	}

	protected create() {
		super.create();
		if (this.labeledControlWidgetInfo.prefix) {
			this._prefix = this.labeledControlWidgetInfo.prefix;
		}
		if (this.labeledControlWidgetInfo.prefixWidth) {
			this._prefixWidth = this.labeledControlWidgetInfo.prefixWidth;
		}
		if (this.labeledControlWidgetInfo.labelVisibility) {
			this._labelVisibility = this.labeledControlWidgetInfo.labelVisibility;
		}
		if (this.labeledControlWidgetInfo.labelPosition) {
			this._labelPosition = this.labeledControlWidgetInfo.labelPosition;
		}
		if (this.labeledControlWidgetInfo.toolbar) {
			this._toolbar = new ToolbarSpec(this.labeledControlWidgetInfo.toolbar.items);
		}
	}

	get labeledControlWidgetInfo(): ILabeledControlWidgetInfo {
		return <ILabeledControlWidgetInfo>this.widgetInfo;
	}

	get prefix(): string | undefined {
		return this._prefix;
	}

	get prefixWidth(): string | undefined {
		return this._prefixWidth;
	}

	get labelVisibility(): VisibilityET {
		return this._labelVisibility;
	}

	get labelPosition(): LabelPositionET {
		return this._labelPosition;
	}

	get toolbar(): ToolbarSpec | undefined {
		return this._toolbar;
	}

	get wrapLabel(): boolean {
		return this.labeledControlWidgetInfo.wrapLabel !== false;
	}

	get labelStyle(): IStyleSpec | undefined {
		return this.labeledControlWidgetInfo.labelStyle;
	}

	get shortDescriptionVisible(): boolean {
		return this.labeledControlWidgetInfo.shortDescriptionVisible ?? true;
	}

	get toolbarButtonClickHandler(): ((source: ILabeledControlComponent, item: IToolBarItemResult, ctx: IEventProcessingContext) => void) | undefined {
		return this.labeledControlWidgetInfo.toolbarButtonClickHandler;
	}
}

export class TextFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _multiline: boolean = false;
	private _rows?: number;

	static createTextTemplateSelection(data: TextTemplateSelectionData): TextTemplateSelection {
		return Object.assign({}, data);
	}

	static createITextFieldWidgetInfo(data: ITextFieldWidgetData | undefined): ITextFieldWidgetInfo {
		let wi: ITextFieldWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data)
		return data ? this.updateITextFieldWidgetInfo(wi, data, false) : wi;

		/*let wi: ITextFieldWidgetInfo = Object.assign(
			{},
			data,
			LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data),
			{
				multiline:!!data.multiline,
				textTemplateSelection: this.createTextTemplateSelection(data.textTemplateSelection),
				resizingType: typeof(data.multiline) === 'object' ? data.multiline.resizingType : undefined,
				rows: typeof(data.multiline) === 'object' ? data.multiline.rows : undefined
			},
		);
		return wi;*/
	}

	static updateITextFieldWidgetInfo(wi: ITextFieldWidgetInfo, data: ITextFieldWidgetDataMutable, updateBase = true): ITextFieldWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}

		// data.textTemplateSelection kann null haben, siehe Bahn
		let textTemplateSelection: null | TextTemplateSelection = null
		if (data.textTemplateSelection) {
			textTemplateSelection = this.createTextTemplateSelection(data.textTemplateSelection);
		} else if (data.textTemplateSelection === undefined) {
			textTemplateSelection = wi.textTemplateSelection
		}

		let dataAdapted = Object.assign({}, data, {
			multiline: data.multiline !== undefined ? data.multiline : wi.multiline,
			textTemplateSelection: textTemplateSelection,
			resizingType: typeof(data.multiline) === 'object' ? data.multiline.resizingType : wi.resizingType,
			rows: typeof(data.multiline) === 'object' ? data.multiline.rows : wi.resizingType
		});
		delete data.multiline;
		delete data.textTemplateSelection;
		return Object.assign(
			wi,
			dataAdapted
		);
	}

	constructor(widgetInfo: ITextFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.textFieldWidgetInfo.rows) {
			this._rows = this.textFieldWidgetInfo.rows;
		}
		if (this.textFieldWidgetInfo.multiline) {
			this._multiline = this.textFieldWidgetInfo.multiline;
		}
	}

	get textFieldWidgetInfo(): ITextFieldWidgetInfo {
		return <ITextFieldWidgetInfo>this.widgetInfo;
	}

	get rows(): number | undefined {
		return this._rows;
	}

	get multiline(): boolean {
		return this._multiline;
	}

	get valueChangesHandler(): ((source: ITextFieldComponent, value: string, ctx: IEventProcessingContext) => void) | undefined {
		return this.textFieldWidgetInfo.valueChangesHandler;
	}

	get resizingType(): MultilineTextFieldResizingType | undefined {
		return this.textFieldWidgetInfo.resizingType;
	}

	get clearIfDisabled(): boolean {
		return this.textFieldWidgetInfo.clearIfDisabled ?? false;
	}

	get textTemplateSelection(): TextTemplateSelection | undefined {
		return this.textFieldWidgetInfo.textTemplateSelection;
	}

	get uiStateUpdateType(): PDTextFieldUIStateUpdateType | undefined {
		return this.textFieldWidgetInfo.uiStateUpdateType;
	}

	get showClearButton(): boolean | undefined {
		return this.textFieldWidgetInfo.showClearButton;
	}

	get spellcheck(): boolean | undefined {
		return this.textFieldWidgetInfo.spellcheck;
	}
}

export class NumericTextFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _step: number;
	private _decimals: number;
	private _min: number;
	private _max: number;
	private _showSpinners: boolean = true;
	private _format: string | any;

	static createINumericTextFieldWidgetInfo(data: INumericTextFieldWidgetData | undefined): INumericTextFieldWidgetInfo {
		let wi: INumericTextFieldWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data);
		return data ? this.updateINumericTextFieldWidgetInfo(wi, data) : wi;
		/*wi.step = data.step;
		wi.decimals = data.decimals;
		wi.min = data.min;
		wi.max = data.max;
		wi.showSpinners = data.showSpinners;
		wi.format = data.format;
		return wi;*/
	}

	static updateINumericTextFieldWidgetInfo(wi: INumericTextFieldWidgetInfo, data: INumericTextFieldWidgetDataMutable, updateBase = true): INumericTextFieldWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}
		//let dataAdapted = Object.assign({}, data);
		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: INumericTextFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.numericTextFieldWidgetInfo.step) {
			this._step = this.numericTextFieldWidgetInfo.step;
		}
		if (this.numericTextFieldWidgetInfo.decimals) {
			this._decimals = this.numericTextFieldWidgetInfo.decimals;
		}
		if (this.numericTextFieldWidgetInfo.min) {
			this._min = this.numericTextFieldWidgetInfo.min;
		}
		if (this.numericTextFieldWidgetInfo.max) {
			this._max = this.numericTextFieldWidgetInfo.max;
		}
		if (this.numericTextFieldWidgetInfo.showSpinners === true || this.numericTextFieldWidgetInfo.showSpinners === false) {
			this._showSpinners = this.numericTextFieldWidgetInfo.showSpinners;
		}
		if (this.numericTextFieldWidgetInfo.format) {
			this._format = this.numericTextFieldWidgetInfo.format;
		}
	}

	get numericTextFieldWidgetInfo(): INumericTextFieldWidgetInfo {
		return <INumericTextFieldWidgetInfo>this.widgetInfo;
	}

	get step(): number | undefined {
		return this._step;
	}

	get decimals(): number | undefined {
		return this._decimals;
	}

	get min(): number | undefined {
		return this._min;
	}

	get max(): number | undefined {
		return this._max;
	}

	get showSpinners(): boolean {
		return this._showSpinners;
	}

	get format(): string | any | undefined {
		return this._format;
	}

	get valueChangesHandler(): ((source: INumericTextFieldComponent, value: number | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.numericTextFieldWidgetInfo.valueChangesHandler;
	}
}

export class DateTimeFieldWidgetInfo extends LabeledControlWidgetInfo {

	static createIDateTimeFieldWidgetInfo(data: IDateTimeFieldWidgetData | undefined): IDateTimeFieldWidgetInfo {
		let wi: IDateTimeFieldWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data);
		return data ? this.updateIDateTimeFieldWidgetInfo(wi, data) : wi;
		//wi.showNavigationBar = data.showNavigationBar;
		//return wi;
	}

	static updateIDateTimeFieldWidgetInfo(wi: IDateTimeFieldWidgetInfo, data: IDateTimeFieldWidgetDataMutable, updateBase = true): IDateTimeFieldWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}
		//let dataAdapted = Object.assign({}, data);
		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: IDateTimeFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	get dateTimeFieldWidgetInfo(): IDateTimeFieldWidgetInfo {
		return <IDateTimeFieldWidgetInfo>this.widgetInfo;
	}

	get showNavigationBar(): boolean | undefined {
		return this.dateTimeFieldWidgetInfo.showNavigationBar;
	}

	get valueChangesHandler(): ((source: IDateTimeFieldComponent, value: Date | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.dateTimeFieldWidgetInfo.valueChangesHandler;
	}
}

export class DropDownWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: IDropDownWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	get dropDownWidgetInfo(): IDropDownWidgetInfo {
		return <IDropDownWidgetInfo>this.widgetInfo;
	}

	get selectedItemChangedHandler(): ((source: IDropDownComponent, value: string | IEnumerationItem | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.dropDownWidgetInfo.selectedItemChangedHandler;
	}
}

export class ListviewWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: IListviewWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	get listviewWidgetInfo(): IListviewWidgetInfo {
		return <IListviewWidgetInfo>this.widgetInfo;
	}

	get itemChangedHandler(): ((source: IListviewComponent, value: any | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.listviewWidgetInfo.itemsChangedHandler;
	}

	get textField(): string | undefined {
		return this.listviewWidgetInfo.textField ?? undefined;
	}

	get valueField(): string | undefined {
		return this.listviewWidgetInfo.valueField ?? undefined;
	}
}

export class ComboBoxWidgetInfo extends LabeledControlWidgetInfo {
	//private _active: boolean = true;
	private _displayTemplate: string;
	private _valueTemplate: string;
	private _className: string;
	private _choiceFilterExpr: string;
	private _choiceSortExpr: string;
	private _spellcheck: boolean;

	static createIComboBoxWidgetInfo(data: IComboBoxWidgetData | undefined): IComboBoxWidgetInfo {
		let wi: IComboBoxWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data);
		return data ? this.updateIComboBoxWidgetInfo(wi, data) : wi;
		/*wi.displayTemplate = data.displayTemplate;
		wi.valueTemplate = data.valueTemplate;
		wi.className = data.className;
		wi.choiceFilterExpr = data.choiceFilterExpr;
		wi.choiceSortExpr = data.choiceSortExpr;
		return wi;*/
	}

	static updateIComboBoxWidgetInfo(wi: IComboBoxWidgetInfo, data: IComboBoxWidgetDataMutable, updateBase = true): IComboBoxWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}
		//let dataAdapted = Object.assign({}, data);
		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: IComboBoxWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		/*if (this.comboBoxWidgetInfo.active !== undefined) {
			this._active = this.comboBoxWidgetInfo.active;
		}*/
		if (this.comboBoxWidgetInfo.displayTemplate) {
			this._displayTemplate = this.comboBoxWidgetInfo.displayTemplate;
		}
		if (this.comboBoxWidgetInfo.valueTemplate) {
			this._valueTemplate = this.comboBoxWidgetInfo.valueTemplate;
		}
		if (this.comboBoxWidgetInfo.className) {
			this._className = this.comboBoxWidgetInfo.className;
		}
		if (this.comboBoxWidgetInfo.choiceFilterExpr) {
			this._choiceFilterExpr = this.comboBoxWidgetInfo.choiceFilterExpr;
		}
		if (this.comboBoxWidgetInfo.choiceSortExpr) {
			this._choiceSortExpr = this.comboBoxWidgetInfo.choiceSortExpr;
		}
		if (this.comboBoxWidgetInfo.spellcheck !== undefined) {
			this._spellcheck = this.comboBoxWidgetInfo.spellcheck;
		}
	}

	private get comboBoxWidgetInfo(): IComboBoxWidgetInfo {
		return <IComboBoxWidgetInfo>this.widgetInfo;
	}

	get active(): boolean | undefined {
		return this.comboBoxWidgetInfo.active;
	}

	get className(): string | undefined {
		return this._className;
	}

	get valueTemplate(): string | undefined {
		return this._valueTemplate;
	}

	get displayTemplate(): string | undefined {
		return this._displayTemplate;
	}

	get choiceFilterExpr(): string | undefined {
		return this._choiceFilterExpr;
	}

	get choiceSortExpr(): string | undefined {
		return this._choiceSortExpr;
	}

	get customChoiceProvider(): ((source: IComboBoxComponent, ctx: IEventProcessingContext) => Observable<IPDObject[]>) | undefined {
		return this.comboBoxWidgetInfo.customChoiceProvider;
	}

	get customChoiceFilterProvider(): ((source: IComboBoxComponent, ctx: IEventProcessingContext) => string | undefined) | undefined {
		return this.comboBoxWidgetInfo.customChoiceFilterProvider;
	}

	get valueChangedHandler(): ((source: IComboBoxComponent, value: string | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.comboBoxWidgetInfo.valueChangedHandler;
	}

	get spellcheck(): boolean | undefined {
		return this._spellcheck;
	}
}

export class InputSwitchWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: IInputSwitchWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get inputSwitchWidgetInfo(): IInputSwitchWidgetInfo {
		return <IInputSwitchWidgetInfo>this.widgetInfo;
	}
}

export class FileUploadWidgetInfo extends LabeledControlWidgetInfo {

	static createIFileUploadWidgetInfo(data: IFileUploadWidgetData | undefined): IFileUploadWidgetInfo {
		const wi: IFileUploadWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data);
		return data ? this.updateIFileUploadWidgetInfo(wi, data) : wi;
	}

	static updateIFileUploadWidgetInfo(wi: IFileUploadWidgetInfo, data: IFileUploadWidgetDataMutable, updateBase = true): IFileUploadWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}
		return Object.assign(
			wi,
			data
		)
	}

	constructor(widgetInfo: IFileUploadWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get fileUploadWidgetInfo(): IFileUploadWidgetInfo {
		return <IFileUploadWidgetInfo>this.widgetInfo;
	}

	get maxFiles(): number | undefined {
		return this.fileUploadWidgetInfo.maxFiles;
	}

	get allowedFileTypes(): string[] | undefined {
		return this.fileUploadWidgetInfo.allowedFileTypes && this.fileUploadWidgetInfo.allowedFileTypes.length > 0 ?
			this.fileUploadWidgetInfo.allowedFileTypes : undefined;
	}
}

export abstract class RelationControlWidgetInfo extends LabeledControlWidgetInfo {

	static createIPDObjectSelectionListSpec(data: PDObjectSelectionListSpecData): IPDObjectSelectionListSpec {
		/*let spec: IPDObjectSelectionListSpec = {}
		if (data.columns) {
			spec.columns = data.columns; //.map(colData => )
		}
		return Object.assign({}, data, spec);*/
		return Object.assign({}, data);
	}

	static createIRelationControlWidgetInfo(data: IRelationControlWidgetData): IRelationControlWidgetInfo {
		let wi: IRelationControlWidgetInfo = LabeledControlWidgetInfo.createILabeledControlWidgetInfo(data);
		return data ? this.updateIRelationControlWidgetInfo(wi, data) : wi;
	}

	static updateIRelationControlWidgetInfo(wi: IRelationControlWidgetInfo, data: IRelationControlWidgetDataMutable, updateBase = true): IRelationControlWidgetInfo {
		if (updateBase) {
			LabeledControlWidgetInfo.updateILabeledControlWidgetInfo(wi, data);
		}
		let determineNewAction = () => {
			if (data.createObject && wi.editingSpec) {
				return data.createObject.enabled;
			}
			return undefined;
		};
		let updateEditingSpec = () => {
			if (wi.editingSpec) {
				if (data.createObject?.enabled) {
					wi.editingSpec.remoteCreationParams = data.createObject.params;
				}
				if (data.editingSpec?.objectInfo) {
					wi.editingSpec.objectInfo = data.editingSpec?.objectInfo;
				}
			}
			return wi.editingSpec;
		};
		let dataAdapted = Object.assign({}, data,
			{
				newAction: determineNewAction() ?? wi.newAction,
				editingSpec: updateEditingSpec(),
				selectAction: data.selectAction ?? wi.selectAction
			}
		);
		delete data.createObject;
		delete data.editingSpec;
		delete data.selectAction;
		return Object.assign(
			wi,
			dataAdapted
		);
	}

	protected static determineChoiceSpec(
		wi: IObjectReferenceWidgetInfo | IMultiSelectRelationWidgetInfo,
		data: IObjectReferenceWidgetDataMutable | IMultiSelectRelationWidgetDataMutable
	): IPDChoiceSpec | IPDChoiceSpec[] | undefined {
		if (!data.choiceSpec) {
			return wi.choiceSpec;
		}
		if (Array.isArray(data.choiceSpec)) {
			return data.choiceSpec.reduce((p, c) => {
				p.push(Object.assign({}, c));
				return p;
			}, <IPDChoiceSpec[]>[])
		}
		else {
			return Object.assign({}, data.choiceSpec);
		}
	}

	protected static determineSelectionListSpec(
			wi: IObjectReferenceWidgetInfo | IMultiSelectRelationWidgetInfo,
			data: IObjectReferenceWidgetDataMutable | IMultiSelectRelationWidgetDataMutable
		): IPDObjectSelectionListSpec // todo
	{
		if (data.selectionListSpec) {
			return RelationControlWidgetInfo.createIPDObjectSelectionListSpec(data.selectionListSpec);
		}
		return wi.selectionListSpec;
	}


	constructor(widgetInfo: IRelationControlWidgetInfo) {
		super(widgetInfo);
	}

	protected create() {
		super.create();
	}

	private get relationControlWidgetInfo(): IRelationControlWidgetInfo {
		return <IRelationControlWidgetInfo>this.widgetInfo;
	}

	get newAction(): boolean | undefined {
		return this.relationControlWidgetInfo.newAction;
	}

	get editAction(): boolean | undefined {
		return this.relationControlWidgetInfo.editAction;
	}

	get deleteAction(): boolean | undefined {
		return this.relationControlWidgetInfo.deleteAction;
	}

	get selectAction(): boolean | undefined {
		return this.relationControlWidgetInfo.selectAction;
	}

	get disconnectAction(): boolean | undefined {
		return this.relationControlWidgetInfo.disconnectAction;
	}

	get editingSpec(): PDObjectEditingSpec | undefined {
		return this.relationControlWidgetInfo.editingSpec;
	}
}

/*export class RelationListWidgetInfo<T extends PDObject> extends RelationControlWidgetInfo {
	private _objCreator: new () => T;

	constructor(widgetInfo: IRelationListWidgetInfo<T>) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		this._objCreator = this.relationListWidgetInfo.objCreator;
	}

	get relationListWidgetInfo(): IRelationListWidgetInfo<T> {
		return <IRelationListWidgetInfo<T>>this.widgetInfo;
	}

	get objCreator(): new () => T {
		return this._objCreator;
	}
}*/

export class ObjectReferenceWidgetInfo extends RelationControlWidgetInfo {
	private _noSelectionItemText: string;

	static createIObjectReferenceWidgetInfo(data: IObjectReferenceWidgetData | undefined): IObjectReferenceWidgetInfo {
		let wi: IObjectReferenceWidgetInfo = RelationControlWidgetInfo.createIRelationControlWidgetInfo(data);
		return data ? this.updateIObjectReferenceWidgetInfo(wi, data) : wi;
		/*let determineType = () => {
			switch (data.type) {
				case 'dropDownList':
					return ObjectReferenceTypeET.DropDownList;
				case 'selectionList':
					return ObjectReferenceTypeET.SelectionList;
			}
		};
		let determineChoiceSpec = (): IPDChoiceSpec | IPDChoiceSpec[] | undefined => {
			if (!data.choiceSpec) {
				return undefined;
			}
			if (Array.isArray(data.choiceSpec)) {
				return data.choiceSpec.reduce((p, c) => {
					p.push(Object.assign({}, c));
					return p;
				}, <IPDChoiceSpec[]>[])
			}
			else {
				return Object.assign({}, data.choiceSpec);
			}
		}

		wi.type = determineType();
		wi.choiceSpec = determineChoiceSpec();
		if (data.customChoiceProvider) {
			wi.customChoiceProviderData = data.customChoiceProvider;
		}
		if (data.selectionChangedHandler) {
			wi.selectionChangedHandlerData = data.selectionChangedHandler;
		}
		return wi;*/
	}

	static updateIObjectReferenceWidgetInfo(wi: IObjectReferenceWidgetInfo, data: IObjectReferenceWidgetDataMutable, updateBase = true): IObjectReferenceWidgetInfo {
		if (updateBase) {
			RelationControlWidgetInfo.updateIRelationControlWidgetInfo(wi, data);
		}
		let determineType = () => {
			switch (data.type) {
				case 'dropDownList':
					return ObjectReferenceTypeET.DropDownList;
				case 'selectionList':
					return ObjectReferenceTypeET.SelectionList;
				case 'textField':
					return ObjectReferenceTypeET.TextField;
				default:
					return wi.type;
			}
		};

		/*let determineChoiceSpec = (): IPDChoiceSpec | IPDChoiceSpec[] | undefined => {
			if (!data.choiceSpec) {
				return wi.choiceSpec;
			}
			if (Array.isArray(data.choiceSpec)) {
				return data.choiceSpec.reduce((p, c) => {
					p.push(Object.assign({}, c));
					return p;
				}, <IPDChoiceSpec[]>[])
			}
			else {
				return Object.assign({}, data.choiceSpec);
			}
		};
		let determineSelectionListSpec = () => {
			if (data.selectionListSpec) {
				return RelationControlWidgetInfo.createIPDObjectSelectionListSpec(data.selectionListSpec);
			}
			return wi.selectionListSpec;
		}*/

		let dataAdapted = Object.assign({}, data, {
			type: determineType(),
			choiceSpec: this.determineChoiceSpec(wi, data),
			customChoiceProviderData: data.customChoiceProvider ? data.customChoiceProvider : wi.customChoiceProviderData,
			selectionChangedHandlerData: data.selectionChangedHandler ? data.selectionChangedHandler : wi.selectionChangedHandlerData,
			customChoiceProvider: data.removeCustomChoiceProvider ? undefined : wi.customChoiceProvider,
			selectionListSpec: this.determineSelectionListSpec(wi, data),
			textFieldSpec: data.textFieldSpec ? Object.assign({}, data.textFieldSpec) : wi.textFieldSpec
		});
		delete data.customChoiceProvider;
		delete data.selectionChangedHandler;
		delete data.removeCustomChoiceProvider;
		delete data.textFieldSpec;
		delete data.choiceSpec;
		delete data.selectionListSpec;

		return Object.assign(
			wi,
			dataAdapted
		);
	}

	constructor(widgetInfo: IObjectReferenceWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.objectReferenceWidgetInfo.noSelectionItemText) {
			this._noSelectionItemText = this.objectReferenceWidgetInfo.noSelectionItemText;
		}
	}

	private get objectReferenceWidgetInfo(): IObjectReferenceWidgetInfo {
		return <IObjectReferenceWidgetInfo>this.widgetInfo;
	}

	get choiceSpec(): IPDChoiceSpec | IPDChoiceSpec[] | undefined {
		return this.objectReferenceWidgetInfo.choiceSpec;
	}

	get noSelectionItemText(): string | undefined {
		return this._noSelectionItemText;
	}

	get filteringEnabled(): boolean {
		return this.objectReferenceWidgetInfo.filteringEnabled === true;
	}

	get type(): ObjectReferenceTypeET {
		return this.objectReferenceWidgetInfo.type ? this.objectReferenceWidgetInfo.type : ObjectReferenceTypeET.DropDownList;
	}

	get selectionListSpec(): IPDObjectSelectionListSpec {
		return this.objectReferenceWidgetInfo.selectionListSpec;
	}

	get customChoiceProvider(): CustomChoiceProvider | undefined {
		return this.objectReferenceWidgetInfo.customChoiceProvider;
	}

	get customChoiceFilterProvider(): ((source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>) | undefined {
		return this.objectReferenceWidgetInfo.customChoiceFilterProvider;
	}

	get customChoiceProviderData(): CustomChoiceProviderData[] | undefined {
		return this.objectReferenceWidgetInfo.customChoiceProviderData;
	}

	get selectionChangedHandler(): ((source: IObjectReferenceComponent, selection: IPDObject | undefined | null, ctx: IEventProcessingContext) => void) | undefined {
		return this.objectReferenceWidgetInfo.selectionChangedHandler;
	}

	get selectionChangedHandlerData(): EventHandlerCommandsData | undefined {
		return this.objectReferenceWidgetInfo.selectionChangedHandlerData;
	}

	get triggerLanguageUpdate(): boolean {
		if (this.objectReferenceWidgetInfo.triggerLanguageUpdate !== undefined) {
			return this.objectReferenceWidgetInfo.triggerLanguageUpdate === true;
		}
		return true;
	}

	get editableRelationProperty(): string | undefined {
		return this.objectReferenceWidgetInfo.editableRelationProperty;
	}

	get textFieldSpec(): ObjectReferenceTextFieldSpec | undefined {
		return this.objectReferenceWidgetInfo.textFieldSpec;
	}

	get showStickyHeader(): boolean | undefined {
		return this.objectReferenceWidgetInfo.showStickyHeader;
	}
}

export class RelationTabWidgetInfo extends RelationControlWidgetInfo {

	static createIRelationTabWidgetInfo(data: IRelationTabWidgetData | undefined): IRelationTabWidgetInfo {
		let wi: IRelationTabWidgetInfo = RelationControlWidgetInfo.createIRelationControlWidgetInfo(data);
		return data ? this.updateIRelationTabWidgetInfo(wi, data) : wi;
	}

	static updateIRelationTabWidgetInfo(wi: IRelationTabWidgetInfo, data: IRelationTabWidgetDataMutable, updateBase = true): IObjectReferenceWidgetInfo {
		if (updateBase) {
			RelationControlWidgetInfo.updateIRelationControlWidgetInfo(wi, data);
		}

		return Object.assign(
			wi,
			data
		);
	}

	private _relationInfo: string;

	private _tabPanelStyle: ICssStyle;

	constructor(widgetInfo: IRelationTabWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		//this._relationInfo = this.relationTabWidgetInfo.relationInfo;
		if (this.relationTabWidgetInfo.tabPanelStyle) {
			this._tabPanelStyle = this.relationTabWidgetInfo.tabPanelStyle;
		}
	}

	private get relationTabWidgetInfo(): IRelationTabWidgetInfo {
		return <IRelationTabWidgetInfo>this.widgetInfo;
	}

	/*get relationInfo(): string {
		return this._relationInfo;
	}*/

	get tabPanelStyle(): ICssStyle |undefined {
		return this._tabPanelStyle;
	}

	get maxTabCount(): number | undefined {
		if (Number.isInteger(this.relationTabWidgetInfo.maxTabCount) && this.relationTabWidgetInfo.maxTabCount > 0) {
			return this.relationTabWidgetInfo.maxTabCount;
		}
		return undefined;
	}

	get minTabCount(): number | undefined {
		if (Number.isInteger(this.relationTabWidgetInfo.minTabCount) && this.relationTabWidgetInfo.minTabCount >= 0) {
			return this.relationTabWidgetInfo.minTabCount;
		}
		return undefined;
	}

	get tabHeaderProvider(): ((tabIndex: number, relObj: IPDObject, localizationService: ILocalizationService) =>IRelationTabHeader) | undefined {
		return this.relationTabWidgetInfo.tabHeaderProvider;
	}

	get canCreateTab(): boolean {
		return this.relationTabWidgetInfo.canCreateTab !== false;
	}

	get canDeleteTab(): boolean {
		return this.relationTabWidgetInfo.canDeleteTab !== false;
	}

	get createRelationObjectCallback(): CreateRelationObjectCallback | undefined {
		return this.relationTabWidgetInfo.createRelationObjectCallback;
	}

	get relationObjectCreatedCallback(): ((source: IPDRelationTabComponent, obj: IPDObject, tabId: string, ctx: IEventProcessingContext) => void) | undefined {
		return this.relationTabWidgetInfo.relationObjectCreatedCallback;
	}
}

export class MultiSelectRelationWidgetInfo extends RelationControlWidgetInfo {

	static createIMultiSelectRelationWidgetInfo(data: IMultiSelectRelationWidgetData | undefined): IMultiSelectRelationWidgetInfo {
		let wi: IMultiSelectRelationWidgetInfo = RelationControlWidgetInfo.createIRelationControlWidgetInfo(data);
		return data ? this.updateIMultiSelectRelationWidgetInfo(wi, data) : wi;
		/*let determineType = () => {
			switch (data.type) {
				case 'dropDownList':
					return MultiSelectRelationWidgetTypeET.DropDownList;
				case 'selectionList':
					return MultiSelectRelationWidgetTypeET.SelectionList;
			}
		};
		let determineChoiceSpec = (): IPDChoiceSpec | IPDChoiceSpec[] | undefined => {
			if (!data.choiceSpec) {
				return undefined;
			}
			if (Array.isArray(data.choiceSpec)) {
				return data.choiceSpec.reduce((p, c) => {
					p.push(Object.assign({}, c));
					return p;
				}, <IPDChoiceSpec[]>[])
			}
			else {
				return Object.assign({}, data.choiceSpec);
			}
		}
		let wi: IMultiSelectRelationWidgetInfo = RelationControlWidgetInfo.createIRelationControlWidgetInfo(data);
		wi.type = determineType();
		wi.choiceSpec = determineChoiceSpec();
		if (data.customChoiceProvider) {
			wi.customChoiceProviderData = data.customChoiceProvider;
		}
		return wi;*/
	}

	static updateIMultiSelectRelationWidgetInfo(wi: IMultiSelectRelationWidgetInfo, data: IMultiSelectRelationWidgetDataMutable, updateBase = true): IMultiSelectRelationWidgetInfo {
		if (updateBase) {
			RelationControlWidgetInfo.updateIRelationControlWidgetInfo(wi, data);
		}
		let determineType = () => {
			switch (data.type) {
				case 'dropDownList':
					return MultiSelectRelationWidgetTypeET.DropDownList;
				case 'selectionList':
					return MultiSelectRelationWidgetTypeET.SelectionList;
				case 'textField':
					return MultiSelectRelationWidgetTypeET.TextField;
				default:
					return wi.type;
			}
		};
		let determineTextTemplateSelection = () => {
			if (data.textTemplateSelection) {
				return TextFieldWidgetInfo.createTextTemplateSelection(data.textTemplateSelection);
			}
			return wi.textTemplateSelection;
		}

		let dataAdapted = Object.assign({}, data, {
			type: determineType(),
			choiceSpec: this.determineChoiceSpec(wi, data),
			customChoiceProviderData: data.customChoiceProvider ? data.customChoiceProvider : wi.customChoiceProviderData,
			selectionChangedHandlerData: data.selectionChangedHandler ? data.selectionChangedHandler : wi.selectionChangedHandlerData,
			customChoiceProvider: data.removeCustomChoiceProvider ? undefined : wi.customChoiceProvider,
			selectionListSpec: this.determineSelectionListSpec(wi, data),
			textFieldSpec: data.textFieldSpec ? Object.assign({}, data.textFieldSpec) : wi.textFieldSpec,
			textTemplateSelection: determineTextTemplateSelection()
		});
		delete data.choiceSpec;
		delete data.customChoiceProvider;
		delete data.selectionChangedHandler;
		delete data.removeCustomChoiceProvider;
		delete data.textFieldSpec;
		delete data.selectionListSpec;
		delete data.textTemplateSelection;

		return Object.assign(
			wi,
			dataAdapted
		);
	}

	constructor(widgetInfo: IMultiSelectRelationWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get multiSelectRelationWidgetInfo(): IMultiSelectRelationWidgetInfo {
		return <IMultiSelectRelationWidgetInfo>this.widgetInfo;
	}

	get choiceSpec(): IPDChoiceSpec | IPDChoiceSpec[] | undefined {
		return this.multiSelectRelationWidgetInfo.choiceSpec;
	}

	get type(): MultiSelectRelationWidgetTypeET {
		return this.multiSelectRelationWidgetInfo.type ? this.multiSelectRelationWidgetInfo.type : MultiSelectRelationWidgetTypeET.DropDownList;
	}

	get selectionListSpec(): IPDObjectSelectionListSpec {
		return this.multiSelectRelationWidgetInfo.selectionListSpec;
	}

	get showCheckboxes(): boolean | undefined {
		return this.multiSelectRelationWidgetInfo.showCheckboxes ?? undefined;
	}

	get customChoiceProvider(): CustomChoiceProvider | undefined {
		return this.multiSelectRelationWidgetInfo.customChoiceProvider;
	}

	get customChoiceFilterProvider(): ((source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>) | undefined {
		return this.multiSelectRelationWidgetInfo.customChoiceFilterProvider;
	}

	get customChoiceProviderData(): CustomChoiceProviderData[] | undefined {
		return this.multiSelectRelationWidgetInfo.customChoiceProviderData;
	}

	get selectionChangedHandler(): ((source: IMultiSelectComponent, selection: IPDObject[] | string[] | IEnumerationItem[] | undefined, ctx: IEventProcessingContext) => void) | undefined {
		return this.multiSelectRelationWidgetInfo.selectionChangedHandler;
	}

	get selectionChangedHandlerData(): EventHandlerCommandsData | undefined {
		return this.multiSelectRelationWidgetInfo.selectionChangedHandlerData;
	}

	get showClearButton(): boolean | undefined {
		return this.multiSelectRelationWidgetInfo.showClearButton;
	}

	get editableRelationProperty(): string | undefined {
		return this.multiSelectRelationWidgetInfo.editableRelationProperty;
	}

	get textFieldSpec(): MultiSelectTextFieldSpec | undefined {
		return this.multiSelectRelationWidgetInfo.textFieldSpec;
	}

	get textTemplateSelection(): TextTemplateSelection | undefined {
		return this.multiSelectRelationWidgetInfo.textTemplateSelection;
	}

	get showStickyHeader(): boolean | undefined {
		return this.multiSelectRelationWidgetInfo.showStickyHeader;
	}
}

export class RelationGridWidgetInfo extends RelationControlWidgetInfo {
	/*static createIRelationGridWidgetInfo(data: IRelationGridWidgetData | undefined): IRelationGridWidgetInfo {
		let wi: IRelationGridWidgetInfo = Object.assign(
			{},
			RelationControlWidgetInfo.createIRelationControlWidgetInfo(data),
			{
				toNRelationSpec: data.t
			}
		);
		return data ? this.updateIMultiSelectRelationWidgetInfo(wi, data) : wi;
	}*/

	static updateIRelationGridWidgetInfo(wi: IRelationGridWidgetInfo, data: IRelationGridWidgetDataMutable, updateBase = true): IRelationGridWidgetInfo {
		if (updateBase) {
			RelationControlWidgetInfo.updateIRelationControlWidgetInfo(wi, data);
		}

		/*let dataAdapted = Object.assign({}, data, {
		});*/

		return Object.assign(
			wi,
			data
		);
	}

	constructor(widgetInfo: IRelationGridWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	private get relationGridWidgetInfo(): IRelationGridWidgetInfo {
		return <IRelationGridWidgetInfo>this.widgetInfo;
	}

	get toNRelationSpec(): IToNRelationSpec {
		return this.relationGridWidgetInfo.toNRelationSpec;
	}

	get columns(): IPDListColumnInfo[] | undefined {
		return this.relationGridWidgetInfo.columns;
	}

	get maxHeight(): string | undefined {
		return this.relationGridWidgetInfo.maxHeight;
	}

	get selectionListSpec(): IPDObjectSelectionListSpec {
		return this.relationGridWidgetInfo.selectionListSpec;
	}

	get fieldNameToPropertyNameAdapter(): ((obj: IPDObject, field: string, ctx: IEventProcessingContext) => string) | undefined {
		return this.relationGridWidgetInfo.fieldNameToPropertyNameAdapter;
	}

	get deleteSelectedAction(): boolean | undefined {
		return this.relationGridWidgetInfo.deleteSelectedAction;
	}

	get customChoiceFilterProvider(): ((source: IRelationGridComponent, ctx: IEventProcessingContext) => string | undefined) | undefined {
		return this.relationGridWidgetInfo.customChoiceFilterProvider;
	}

	get customChoiceProvider(): ((source: IRelationComponent, ctx: IEventProcessingContext) => ICustomChoiceProviderResult[]) | undefined {
		return this.relationGridWidgetInfo.customChoiceProvider;
	}

	get quickCreationSpec(): RelationGridQuickCreationSpec | undefined {
		return this.relationGridWidgetInfo.quickCreationSpec;
	}

	get quickCreationEnabled(): boolean | undefined {
		return this.relationGridWidgetInfo.quickCreationEnabled;
	}

	get selectionClassNameProvider(): ((source: IRelationComponent, ctx: IEventProcessingContext) => string) | undefined {
		return this.relationGridWidgetInfo.selectionClassNameProvider;
	}

	get onSaveRowHandler(): ((obj: IPDObject, source: IRelationGridComponent, ctx: IEventProcessingContext) => void) | undefined {
		return this.relationGridWidgetInfo.onSaveRowHandler;
	}
}

//
export class AccordionWidgetInfo extends WidgetInfo {
	private _multiple: boolean = false;

	constructor(widgetInfo: IAccordionWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.accordionWidgetInfo.multiple) {
			this._multiple = this.accordionWidgetInfo.multiple;
		}
	}

	private get accordionWidgetInfo(): IAccordionWidgetInfo {
		return <IAccordionWidgetInfo>this.widgetInfo;
	}

	get multiple(): boolean {
		return this._multiple;
	}
}

export class InfoFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _template: string;

	constructor(widgetInfo: IInfoFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.inputFielhWidgetInfo.template) {
			this._template = this.inputFielhWidgetInfo.template;
		}
	}

	private get inputFielhWidgetInfo(): IInfoFieldWidgetInfo {
		return <IInfoFieldWidgetInfo>this.widgetInfo;
	}

	get template(): string {
		return this._template;
	}
}

