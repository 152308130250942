import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";

@Injectable()
export class RemoteFileService implements IRemoteFileService {

	constructor(private _httpClient: HttpClient) {

	} 

	loadFileContent(url: string): Observable<string> {
		/*return this._httpClient.head(url, { responseType: 'text', observe: 'response' }).pipe(
			switchMap(res1 => {
				return this._httpClient.get(url, { responseType: 'text', observe: 'response' });
			}),
			switchMap(res2 => {
				return this._httpClient.get(url, { responseType: 'text' });
			})
		);*/

		return this._httpClient.get(url, { responseType: 'text' });
	}
 
}

@Injectable({ providedIn: 'root', useClass: RemoteFileService })
export abstract class IRemoteFileService {

	abstract loadFileContent(url: string): Observable<string>;

}