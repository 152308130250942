import { Inject, Injectable, InjectionToken, Injector, inject } from "@angular/core";
import { ComponentUIStateExpression, CustomChoiceProvider, CustomChoiceProviderData, ExpressionOptions, ICustomChoiceProviderResult, IEventProcessingContext, IFormHandlerService, UIStateProvider } from "@otris/ng-core-types";
import { ASTToken, IGrammarParser, IGrammarParserFactory } from "./grammar-parser-factory.service";
import { UIStateExpressionHandler } from "../model/expression-grammar/ui-state-expression-handler";
import { EMPTY, Observable, catchError, forkJoin, map, of, switchMap, tap, throwError } from "rxjs";
import { IRemoteFileService } from "./remote-file.service";
import { IFormExpressionGrammarProcessor } from '@otris/ng-core-types';
import { DataProviderOperationExpressionHandler } from "../model/expression-grammar/data-provider-operation-expresssion-handler";
import { ConditionExpressionHandler } from "../model/expression-grammar/condition-expression-actions";


export const FormExpressionGrammarProcessorToken = new InjectionToken<IFormExpressionGrammarProcessor>(
	'FormExpressionGrammarProcessorToken',
	{
		factory: () => new FormExpressionGrammarProcessor(
			inject(IGrammarParserFactory), inject(IRemoteFileService)
		),
		providedIn: 'root' 
	}
);


@Injectable()
export class FormExpressionGrammarProcessor implements IFormExpressionGrammarProcessor {

	private _uiStateExprParser: IGrammarParser; // Todo: durch _conditionExprParser ersetzen

	private _dataProviderExprParser: IGrammarParser;

	private _conditionExprParser: IGrammarParser;

	// @Inject(IGrammarRule[]) todo ?
	constructor(private _grammarParserFactory: IGrammarParserFactory,
		private _remoteFileService: IRemoteFileService
		/*@Inject(IErrorHandlerToken) private _errorHandler: IErrorHandler*/) {
	}

	loadGrammar(): Observable<void> {
		let uiStateExprGrammarFile = 'ui-state-expression-grammar.ebnf';
		let dataProviderExprGrammarFile = 'data-provider-expression-grammar.ebnf';
		let conditionExprGrammarFile = 'condition-expression-grammar.ebnf';

		let loadGrammarFiles$ = [
			this.loadGrammarFile(uiStateExprGrammarFile, []),
			this.loadGrammarFile(dataProviderExprGrammarFile, []),
			this.loadGrammarFile(conditionExprGrammarFile, [])
		];

		return forkJoin(loadGrammarFiles$).pipe(
			map(res => {
				this._uiStateExprParser = this._grammarParserFactory.createEbnfParser(res[0]);
				this._dataProviderExprParser = this._grammarParserFactory.createEbnfParser(res[1]);
				this._conditionExprParser = this._grammarParserFactory.createEbnfParser(res[2]);
			}),
			catchError(err => {
				let msg = `Error in FormExpressionGrammarProcessor.loadGrammar(). Details: ${err}`;
				//this._errorHandler.handleError(<IError>{ details: msg } );
				console.error(msg);
				return EMPTY;
			})
		);

		/*return this.loadGrammarFile(uiStateExprGrammarFile).pipe(
			map(content => {
				this._uiStateExprParser = this._grammarParserFactory.createEbnfParser(content);
			}),
			catchError(err => {
				let msg = `Error in FormExpressionGrammarProcessor.loadGrammar(). Details: ${err}`;
				//this._errorHandler.handleError(<IError>{ details: msg } );
				console.error(msg);
				return EMPTY;
			})
		);*/
	}

	evaluateUIStateExpression(expr: ComponentUIStateExpression, options?: ExpressionOptions): UIStateProvider | undefined {
		if (!this._uiStateExprParser) {
			return undefined;
		}
		return UIStateExpressionHandler.createUIStateProvider(this._uiStateExprParser, expr, options);
	}

	createCustomChoiceProvider(data: CustomChoiceProviderData[], formHandler: IFormHandlerService, injector: Injector): CustomChoiceProvider | undefined {
		if (!this._dataProviderExprParser) {
			return undefined;
		}
		
		return (relComp, ctx) => {
			let result: ICustomChoiceProviderResult[] = [];
			data.forEach(dataItem => {
				let dataProvider: Observable<any> = of([]);				
				for (let provider of dataItem.dataProvider) {
					if (provider.conditionExpr) {
						if (!this.evaluateConditionExpression(provider.conditionExpr, ctx, { sourceComp: relComp })) {
							continue;
						}
					}
					dataProvider = DataProviderOperationExpressionHandler.createDataProviderOperation(
						this._dataProviderExprParser, provider.dataProviderExpr, ctx, injector, 'PDObjectArray');
					break;
				}
				
				/*if (dataItem.dataProvider.length != 1) {
					throw new Error('Error in createCustomChoiceProvider. dataProvider.length != 1 not imlemented');
				}*/
				// todo: injector in ctx integrieren!
				//let dataProvider = DataProviderOperationExpressionHandler.createDataProviderOperation(
				//	this._dataProviderExprParser, dataItem.dataProvider[0].dataProviderExpr, ctx, injector, 'PDObjectArray');
				let resultItem: ICustomChoiceProviderResult = {
					className: dataItem.className,
					objectInfo: dataItem.objectInfo,
					data: dataProvider
				};
				result.push(resultItem);
			});
			return result;
		};

		// Todo
		//return undefined;
	}

	evaluateConditionExpression(expr: string, ctx: IEventProcessingContext, options?: ExpressionOptions): boolean {
		return ConditionExpressionHandler.evaluateConditionExpression(this._conditionExprParser, expr, ctx, options);
	}

	private loadGrammarFile(grammarFile: string, handledFiles: string[]): Observable<string> {
		const fileUrl = `assets/ng-core/expression-grammar/${grammarFile}`;
		if (handledFiles.includes(fileUrl)) {
			return of('');
		}
		handledFiles.push(fileUrl);
		let includeFiles: Map<string, string> = new Map();
		return this._remoteFileService.loadFileContent(fileUrl).pipe(
			switchMap(content => {
				const sInclude = '#include ';
				let posInclude = -1;
				do {
					posInclude = content.indexOf(sInclude, posInclude);
					if (posInclude == -1) {
						break;
					}
					let posQuotesStart = content.indexOf('"', posInclude);
					if (posQuotesStart == -1) {
						throwError(() => new Error(`Error in input directive '#include'`));
					}
					let posQuotesEnd = content.indexOf('"', posQuotesStart + 1);
					if (posQuotesEnd == -1) {
						throwError(() => new Error(`Error in input directive '#include'`));
					}
					let includeFile = content.substring(posQuotesStart + 1, posQuotesEnd).trim();
					if (!includeFile) {
						throwError(() => new Error(`Error in input directive '#include'`));
					}
					let includeStatement = content.substring(posInclude, posQuotesEnd + 1);
					includeFiles.set(includeFile, includeStatement);
					posInclude += sInclude.length;
				} while (posInclude !== -1);

				if (includeFiles.size == 0) {
					return of(content);
				}
				let loadGrammarCalls$: Observable<[string, string]>[] = []; 
				includeFiles.forEach((v, k) => {
					let call$ = this.loadGrammarFile(k, handledFiles).pipe(
						map(res => [k, res] as [string, string])
					);
					loadGrammarCalls$.push(call$);
				});
				return forkJoin(loadGrammarCalls$).pipe(
					tap(res => {
						res.forEach(include => {
							let includeStatement = includeFiles.get(include[0]);
							content = content.replace(includeStatement, include[1]);
						});
					}),
					map(() => content)
				);
			})
		);
	}
}
