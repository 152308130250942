import { Injectable } from "@angular/core";
import { IPDAccessImplementationService, PDAccessMethodNotImplementedError } from "@otris/ng-core-shared";
import { AbstractEnumeration, ICallOperationResult, IGetExtentByPageResult, IPDAccessService, IPDObject, IPDObjectId, IPDObjectRaw } from "@otris/ng-core-types";
import { Observable, throwError } from "rxjs";

@Injectable()
export abstract class PDAccessImplementationBase extends IPDAccessImplementationService {

	changeUser(login: string, pwd: string, principal: string): Observable<{msg: string, errorCode: number}> {
		return throwError(() => new PDAccessMethodNotImplementedError('changeUser'));
	}

	disconnect(): Observable<boolean> {
		return throwError(() => new PDAccessMethodNotImplementedError('disconnect'));
	}

	getObject(id: string, attrs?: string[]): Observable<IPDObject> {
		return throwError(() => new PDAccessMethodNotImplementedError('getObject'));
	}

	getObjects(ids: string[], attrs?: string[]): Observable<IPDObject[]> {
		return throwError(() => new PDAccessMethodNotImplementedError('getObjects'));
	}

	getExtent(
		pdclass: string | number,
		filter?: string, sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]> {
		return throwError(() => new PDAccessMethodNotImplementedError('getExtent'));
	}

	getExtentByPage(
		pdclass: string | number,
		pageIndex: number,
		pageSize: number,
		ignoreErrors?: boolean,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IGetExtentByPageResult> {
		return throwError(() => new PDAccessMethodNotImplementedError('getExtentByPage'));
	}

	callOperation(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>> {
		return throwError(() => new PDAccessMethodNotImplementedError('callOperation'));
	}

	callOperationTyped<T extends IPDObject>(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		pdObjectCreator: (rawObj: IPDObjectRaw) => T,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<T>> {
		return throwError(() => new PDAccessMethodNotImplementedError('callOperationTyped'));
	}

	getRelationObjects(
		objWrapper: IPDObject,
		relationName: string,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]> {
		return throwError(() => new PDAccessMethodNotImplementedError('getRelationObjects'));
	}

	callObjectOperation(
		objWrapper: IPDObject,
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>> {
		return throwError(() => new PDAccessMethodNotImplementedError('callObjectOperation'));
	}

	downloadObjectDocument(objWrapper: IPDObject, attr: string): Observable<void> {
		return throwError(() => new PDAccessMethodNotImplementedError('downloadObjectDocument'));
	}

	getEnumeration(enumName: string, langIndex: number, includeDeleted?: boolean): Observable<AbstractEnumeration> {
		return throwError(() => new PDAccessMethodNotImplementedError('getEnumeration'));
	}

	createPDObjectRaw(oid: string, className: string): IPDObjectRaw {
		throw new PDAccessMethodNotImplementedError('createPDObjectRaw');
	}

	getSubClasses(): Observable<[string, string[]][]> {
		return throwError(() => new PDAccessMethodNotImplementedError('getSubClasses'));
	}

}