import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { IBusyProvider, IModuleStateService } from "@otris/ng-core-types";

@Injectable()
export class ModuleStateService implements IModuleStateService {

	private _queryParams: Params = {};

	private _busyProviders: IBusyProvider[] = [];

	constructor(route: ActivatedRoute) {
		route.queryParams.subscribe(params => {
			this._queryParams = Object.assign(this._queryParams, params);
			/*for (const [key, value] of Object.entries(params)) {
				console.log(`${key}: ${value}`);
			}*/
		})
	}

	getQueryParameter(name: string): string | undefined {
		return this._queryParams[name];
	}

	registerBusyProvider(provider: IBusyProvider): void {
		const indexOfProvider = this._busyProviders.indexOf(provider);
		if (indexOfProvider === -1) {
			this._busyProviders.push(provider)
		} else {
			console.warn(`Couldn't register busy provider. Provider already registered.`);
		}
	}

	unregisterBusyProvider(provider: IBusyProvider): void {
		const indexOfProvider = this._busyProviders.indexOf(provider);
		if (indexOfProvider !== -1) {
			this._busyProviders.splice(indexOfProvider, 1);
		} else {
			console.error(`Couldn't unregister busy provider. Provider not found.`);
		}
	}

	get busy(): boolean {
		return this._busyProviders.some(provider => provider.busy);
	}
}
