export class CustomEventToken<T> {
	constructor(private _eventName: string) {
	}

	get eventName(): string {
		return this._eventName;
	}

	createEvent(value: T): CustomEvent<T> {
		return new CustomEvent(value, this);
	} 
}

export class CustomEvent<T> {
	constructor(private _args: T, private _eventToken: CustomEventToken<T>) {

	}

	get args(): T {
		return this._args;
	}

	get eventToken(): CustomEventToken<T> {
		return this._eventToken;
	}
}
