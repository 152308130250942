import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";

/**
 * Dies ist ein Workaround! #58499
 */
@Injectable()
export class ExpansionPanelService {
	private _expansionPanelHandlers: Map<string, BehaviorSubject<boolean | undefined>> = new Map();

	getExpansionPanelHandler$(expansionPanelId: string): Observable<boolean | undefined> | undefined {
		return this._expansionPanelHandlers.get(expansionPanelId)?.asObservable();
	}

	registerNewExpansionPanelHandler(expansionPanelId: string, startValue = false): BehaviorSubject<boolean> {
		if (this._expansionPanelHandlers.has(expansionPanelId)) {
			return this._expansionPanelHandlers.get(expansionPanelId);
		}
		const newSubject = new BehaviorSubject(startValue);
		this._expansionPanelHandlers.set(expansionPanelId, newSubject);
		return newSubject;
	}

	deleteExpansionPanelhandler(expansionPanelId: string): void {
		this._expansionPanelHandlers.delete(expansionPanelId);
	}
}
