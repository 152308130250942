import { AfterViewInit, Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomPDObject, PDObject } from "@otris/ng-core-shared";
import { IRelationContext, IComponent, ITo1RelationItemSpec } from '@otris/ng-core-types'
import { FormHandlerService } from "../services/form-handler.service";

@Directive({
	selector: '[otrisTo1RelationItem]'
})
export class To1RelationItemDirective implements OnInit, OnDestroy {

	@Input('otrisTo1RelationItem') relationSpec: ITo1RelationItemSpec;

	@Input('relItemHostComponent') hostComponent: IComponent;

	//@Input('relItemFormGroup') formGroup: FormGroup;

	private _formGroup: UntypedFormGroup;

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}

	@Input('relItemFormGroup')
	set formGroup(fg: UntypedFormGroup) {
		this._formGroup = fg;
	}

	@Input('relItemRelationContext') relationContext: IRelationContext;

	@Input('relItemPDObject') relPDObject: PDObject;

	private _relationItemFormGroup: UntypedFormGroup;

	get relationItemFormGroup(): UntypedFormGroup {
		return this._relationItemFormGroup;
	}

	private get hostId(): string | undefined {
		let id = this.relationContext ? this.relationContext.path + '.' : '';
		return id + this.relationSpec.relationProperty;
	}

	constructor(private formHandler: FormHandlerService) {}

	ngOnInit(): void {
		//if (this.relationObjectExisting) {
		//setTimeout(() => {
			this.createControl();
			this.formHandler.registerComponent(this.hostComponent, this.hostId);
		//}, 0);
		//}
	}

	ngOnDestroy(): void {
		/*let formArray = this.relationFormArray;
		let index = formArray.controls.findIndex(c => c === this._relationItemFormGroup)
		formArray.removeAt(index);*/
		this.formHandler.unregisterComponent(this.hostComponent, this.hostId);
	}

	getRelationContext(): IRelationContext  {
		let relationPath: string;
		if (this.relationContext) {
			if (this.relationContext.isMultiple) {
				relationPath = this.relationContext.path + '.' + this.relationContext.index + '.' + this.relationSpec.relationProperty;
			} else {
				relationPath =  this.relationContext.path + '.' + this.relationSpec.relationProperty
			}
		} else {
			relationPath = this.relationSpec.relationProperty;
		}
		return { source: this.relPDObject, path: relationPath, isMultiple: false };
	}

	private createControl(): void {
		this._relationItemFormGroup = new UntypedFormGroup({
			_className: new UntypedFormControl(this.relPDObject.metaData.className),
			_objectId: new UntypedFormControl(this.relPDObject.objectId),
			_metaData: new UntypedFormControl(this.relPDObject.metaData),
			_isNew: new UntypedFormControl(this.relPDObject instanceof CustomPDObject ? this.relPDObject.isNew : false)
		});
		this.formGroup.addControl(this.relationSpec.relationProperty, this._relationItemFormGroup);
	}
}
