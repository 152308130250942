import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { IPDAccessService, IPDClass, IUIFormSpec } from '@otris/ng-core-types';
import { CustomPDObject, IPDAccessServiceToken, IPDClassToken } from '@otris/ng-core-shared';
import { IPDObjectEditContextManager, IPDObjectEditContext } from './i-pd-object-edit-context';
import { IPDObjectEditContextManagerToken } from './pd-object-edit-context-manager.service';
import { IRootFormSpecProvider, IRootFormSpecProviderToken } from './i-form-spec-provider';

export enum PDObjectResolverModeET {
	EditRelationObject,
	CreateRelationObject
}

export interface IPDObjectResolverData {
	mode: PDObjectResolverModeET;
}

export interface IPDObjectResolveResult {
	pdObject: CustomPDObject;
	formSpec: Observable<IUIFormSpec>; // todo: Observable weg?
	isNewObject: boolean;
	roleName: string;
	parentId: string;
}

@Injectable({ providedIn: 'root' })
export class PDObjectResolver  { // todo: nur noch IPDObjectResolveResult zurückgeben

	constructor(private router: Router, private route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IPDObjectEditContextManagerToken) private editContextManager: IPDObjectEditContextManager,
		@Inject(IRootFormSpecProviderToken) private rootFormSpecProvider: IRootFormSpecProvider,
		@Inject(IPDClassToken) private pdClass: IPDClass) {
		console.log('Resolver created.');
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPDObjectResolveResult> {
		let resolveData = route.data['resolveData'] as IPDObjectResolverData;
		if (!resolveData) {
			throw new Error("No resolveData found!");
		}

		switch (resolveData.mode) {
			case PDObjectResolverModeET.EditRelationObject:
				{
					let role = route.paramMap.get('role');
					if (!role) {
						console.error('No Parameter role.');
						return EMPTY;
					}
					let relObjOid = route.paramMap.get('relObjOid');
					if (!relObjOid) {
						console.error('No Parameter relObjOid.');
						return EMPTY;
					}
					let parentId = route.paramMap.get('parentId');
					if (!parentId) {
						console.error('No Parameter parentId.');
						return EMPTY;
					}
					let relObj = this.editContextManager.currentContext.getRelationObject(role, relObjOid);
					if (!relObj) {
						console.error(`Relation object with oid '${relObjOid}' not existing.`);
						return EMPTY;
					}
					let clonedRelObj = relObj.clone();
					let res: IPDObjectResolveResult =  {
						pdObject: clonedRelObj,
						formSpec: of(this.rootFormSpecProvider.getFormSpec(clonedRelObj.getClassName())),
						isNewObject: false,
						roleName: role,
						parentId: parentId
					};
					return of(res);

					// OLD  todo: Fehlerbehandlung, falls
					/*let role = route.paramMap.get('role');
					if (role) {
						let relObj = this.editContextManager.currentContext.getRelationObject(role);
						if (relObj) {
							return of(relObj);
						}
					}
					return EMPTY;*/
				}
			case PDObjectResolverModeET.CreateRelationObject:
				{
					let role = route.paramMap.get('role');
					if (!role) {
						console.log('No Parameter role.');
						return EMPTY;
					}
					let relClass = route.paramMap.get('relationClass');
					if (!relClass) {
						console.log('No Parameter relClass.');
						return EMPTY;
					}
					// todo: Fehler falls parentId nicht vorhanden
					let parentId = route.paramMap.get('parentId');

					//let basisClass = this.editContextManager.currentContext.contextObject.getClassName();
					/*let relationClass = this.metaDataService.getRelationClass(basisClass, role);
					if (!relationClass) {
						console.log(`Relation class for role '${role}' not found.`);
						return EMPTY;
					}*/
					//let relObj = this.pdClass.createInstance(relClass) as CustomPDObject;
					//let formSpec = this.formSpecProvider.getFormSpec(relClass);
					let res:IPDObjectResolveResult =  {
						pdObject: this.pdClass.createInstance(relClass) as CustomPDObject,
						formSpec: of(this.rootFormSpecProvider.getFormSpec(relClass)),
						isNewObject: true,
						roleName: role,
						parentId: parentId
					};
					return of(res);
				}
		}
	}

	/* ALT
	 * resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PDObject> {
		let id = route.paramMap.get('id');

		return this.pdAccessService.getObject(id).pipe(
			take(1),
			mergeMap(obj => {
				if (obj) {
					return of(obj);
				}
				this.router.navigate(['.']);
				return EMPTY;
			})
		);
	}*/
}
