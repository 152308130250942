import { Component, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDLabeledControlComponent, InfoFieldWidgetInfo, FormHandlerService, EventProcessingContextManagerService } from '@otris/ng-core';
import { ComponentTypeET } from '@otris/ng-core-types';

@Component({
	selector: 'otris-pd-info-field',
	template: `
		<otris-pd-labeled-control-frame 
			[labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" 
			[pdObject]="pdObject" 
			[relatedFormControl]="this.control"
			[id]="getId() + '.infofield'"
		>
			<div class="info-container">
				@if (template) {
					<div>
						<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
					</div>
				} @else {
					<div>{{formGroup.controls[propertyName].value}}</div>
				}
				<ng-template #templateElse>
					<div>{{formGroup.controls[propertyName].value}}</div>
				</ng-template>
			</div>
		</otris-pd-labeled-control-frame>

		<!--
		<label style="display: flex; flex-direction: column; flex: 1 1 auto; overflow-x: hidden;" class="k-form-field">
			<div class="label-container">
				<div>{{label}}</div>
			</div>
			<div class="info-container">
				<div *ngIf="template; else templateElse">
					<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
				</div>
				<ng-template #templateElse>
					<div>{{formGroup.controls[propertyName].value}}</div>
				</ng-template>
			</div>
		</label>
		-->
	`,
	styles: [`
		.info-container {
			flex: 1 1 auto;
			margin-top: 0.5em;
			background-color: gray;
			color: lightgray;
			padding: 0.5em;
			font-style: italic;
		}
	`]
})
export class PDInfoFieldComponent extends PDLabeledControlComponent {

	get templateContext() {
		return {
			$implicit: (this.pdObject && this.propertyName) ? this.pdObject.pdObjectRaw[this.propertyName] : undefined,
			'pdObject': this.pdObject
		};
	}

	get template(): TemplateRef<any> {
		let wi = this.infoFieldWidgetInfo;
		return wi && wi.template ? this.formHandler.getUITemplate(wi.template) : undefined;
	}

	get infoFieldWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof InfoFieldWidgetInfo ?
			<InfoFieldWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		/*let widgetInfo = this.textFieldWidgetInfo;
		if (widgetInfo && widgetInfo.multiline) {
			this.multiline = true;
			if (widgetInfo.rows) {
				this.textAreaRows = widgetInfo.rows;
			}
		}*/
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}

}
