import { InjectionToken, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomPDObject } from '@otris/ng-core-shared';
import { Observable } from 'rxjs';

export const IPDObjectEditContextToken = new InjectionToken<IPDObjectEditContext>('IPDObjectEditContextToken');

//export const IPDObjectEditContextManagerToken = new InjectionToken<IPDObjectEditContextManager>('IPDObjectEditContextManagerToken');

@Injectable()
export abstract class IPDObjectEditContextManager {
	currentContext: IPDObjectEditContext;
	abstract switchContext(ctx: IPDObjectEditContext): void;
	abstract releaseCurrentContext(): void;
}

@Injectable()
export abstract class IPDObjectEditContext {
	baseRoute: ActivatedRoute;
	contextObject: CustomPDObject;
	parentObject: CustomPDObject | undefined;
	isNewObject: boolean;
	abstract setContextObject(obj: CustomPDObject, isNew?: boolean, roleName?: string, parentId?: string): void;
	abstract getRelationObject(path: string, oid?: string): CustomPDObject | undefined;
	abstract release(): void;
	abstract notifyContextObjectSaved(): void;
	abstract notifyRelationObjectCreated(obj: CustomPDObject, roleName: string, parentId: string): void;
	abstract getRelationObjectCreated(role: string): Observable<[string, CustomPDObject, string]>;
	abstract notifyRelationObjectEdited(obj: CustomPDObject, roleName: string, parentId: string): void;
	abstract getRelationObjectEdited(role: string): Observable<[string, CustomPDObject, string]>;
	abstract registerRelationObject(role: string, obj: CustomPDObject): void;
	abstract unregisterRelationObject(role: string): boolean;
}
