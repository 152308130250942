import { ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IUIDrawerItem, IDrawerItemComponent, IPanelComponent, ICssStyle, IComponentUIState, IDrawerComponent } from '@otris/ng-core-types';
import { LocalizationService, LocalizationServiceToken, UIDrawerItemSpec } from '@otris/ng-core';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'otris-pd-drawer-item',
	template: `
		<li class="item-root otris-pd-drawer-item"
			[ngClass]="{ 'otris-state-selected': drawerItem.selected, 'otris-pd-drawer-item-disabled': disabled }"
			(click)="onClick()">

			@if (mini || expanded) {
				<div>
					<span [ngClass]="drawerItem.icon + ' item-icon'"></span>
				</div>
			}
			@if (expanded) {
				<div [@expandCollapse] class="item-text">
					<span>{{text}}</span>
				</div>
			}
			@if (drawerItem.shortDescription || !isValid) {
				<div style="width: 0.5em">
				</div>
			}
			@if (drawerItem.shortDescription || !isValid) {
				<div class="tool-container" [style]="toolContainerStyle">
					@if (drawerItem.shortDescription) {
						<otris-pd-short-description class="short-description" [highlighted]="mouseIsOver"
							[customShortDescription]="drawerItem.shortDescription">
						</otris-pd-short-description>
					}
					<!-- [style.display]="isValid ? 'none' : undefined" -->
					@if (!isValid) {
						<otris-pd-error-indicator class="error-indicator" [customErrorMessage$]="errorMessage$">
						</otris-pd-error-indicator>
					}
				</div>
			}
		</li>
	`,
	styles: [`
		.item-root {
			display: flex;
			align-items: center;
			padding: 0.5em;
			user-select: none;
		}
		.item-container {
			display: flex;
			align-items: center;
			padding: 0.5em;
		}
		.item-icon {

		}
		.item-text {
			margin: 0 0.5em;
			white-space: nowrap;
			overflow-x: hidden;
			flex: 1;
		}
		.short-description {
			margin-left: 0.25em;
			margin-right: 0.25em;
		}
		.error-indicator {
			/*margin: 0 0.5em 0 1em;*/
			margin-left: 0.25em;
			margin-right: 0.25em;
		}
		.tool-container {
			display: flex;
			font-size: 0.8em;
			margin-left: auto;
		}
	`],
	animations: [
		trigger(
		  'expandCollapse',
			[
				transition(':enter',
					[
					  style({ width: 0 }),
					  animate('0.25s', style({ width: '*' }))
					]
				 ),
				 transition(':leave',
					[
					  style({ width: '*' }),
					  animate('0.25s', style({ width: 0 }))
					]
				 )
		  	]
		)
	 ]
})
export class PDDrawerItemComponent implements OnInit, OnDestroy, IDrawerItemComponent {

	@Input() drawerComponent: IDrawerComponent;

	@Input() drawerItem: IUIDrawerItem;

	@Input() mini = false;

	@Input() expanded = false;

	@Input() disabled = false;

	@Input() drawerItemSpec: UIDrawerItemSpec;

	@Output() itemSelected = new EventEmitter<IUIDrawerItem>();

	/*@HostBinding('style.display') get styleDisplay(): string | undefined {
		return this.drawerItem.hidden ? 'none' : undefined;
	}*/

	@HostBinding('style.display') get styleDisplay(): string | undefined {
		return this.uiState.hidden === true ? 'none' : undefined;
	}

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	text: string;

	private _subscription: Subscription;

	get isValid(): boolean {
		let status = this._relatedPanelComponent?.getFormControlStatus();
		return status ? status.valid : true;
	}

	get errorMessage$(): Observable<string> {
		return this.localizationService.getSystemString('kendo-ui.components.pd-drawer.drawer-item.empty-required-fields');
	}

	get toolContainerStyle(): ICssStyle {
		if (this.drawerItem.selected) {
			return {
				'background-color': 'white',
				'border-radius': '3px',
				'padding': '0.1em'
			};
		}
		else {
			return {
				'padding': '0.1em'
			};
		}
	}

	get uiState(): IComponentUIState {
		return this._uiState;
	}

	private _uiState: IComponentUIState = {}

	constructor(private cdref: ChangeDetectorRef, @Inject(LocalizationServiceToken) private localizationService: LocalizationService) {
		this._subscription = localizationService.changeHandler.subscribe(
			() => {
				this.updateText();
			}
		);
	}

	ngOnInit(): void {
		/*if (this.drawerItem?.text) {
			this.text = this.drawerItem.text;
		}*/
		this.updateText();
		this.updateUIState();
	}

	ngOnDestroy(): void {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	onClick(): void {
		if (!this.disabled && !this.drawerItem.selected) {
			//this.drawerItem.selected = true;
			this.itemSelected.emit(this.drawerItem);
		}
	}

	updateUIState(): void {
		let cb = this.drawerItemSpec.updateUIStateHandler;
		if (cb) {
			this._uiState = cb(this, this.drawerComponent.createEventProcessingContext());
		}
		else {
			this._uiState = {}
		}
		if (this.drawerItem.hidden) {
			this._uiState.hidden = true;
		}
	}

	private _relatedPanelComponent: IPanelComponent;

	get relatedPanelComponent(): IPanelComponent {
		return this._relatedPanelComponent
	}

	set relatedPanelComponent(comp: IPanelComponent) {
		if (!comp) {
			console.warn('PDDrawerItemComponent.set relatedPanelComponent(): comp is undefined.');
		}
		this._relatedPanelComponent = comp;
	}

	private updateText(): void {
		if (this.drawerItem?.text) {
			// todo: ErgNameData!
			this.text = this.drawerItem.text;
		}
		else if (this.drawerItem?.textId) {
			this.localizationService.getString(this.drawerItem.textId).subscribe(s => {
				this.text = s;
				this.cdref.detectChanges();
			});
		}
	}
}
