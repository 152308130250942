import { Observable } from "rxjs";
import { IPDClass } from "../services/i-pd-class.service";
import { IComponent, IRelationComponent } from "./i-component";
import { IEventProcessingContext } from "./i-event-processing-context";
import { IPDObject } from "./i-pd-object";
import { CustomEvent } from './custom-event';
import { ErgNameData } from "./types";

export interface ICustomChoiceProviderResult {
	/**
	 * Observable der die Auswahl-Daten liefert.
	 * Hier sollten die Daten auch gefiltert werden.
	 * @example
		data: ctx.pdAccessService.callObjectOperation(
			custCtx.principal,
			'holeDL',
			undefined,
			filterUnternehmen
		).pipe(
			map(res => Array.isArray(res.outObjs) ? res.outObjs : [res.outObjs])
		)
	 */
	data: Observable<IPDObject[] | string[]>;
	/**
	 * Mit dem Klassennamen wird der ergonomische Name der Gruppierung geliefert, der Auswahlliste.
	 */
	className?: string; // Todo: prüfen, ob das nicht mandatory ist, wegen ChoiceSpecs!

	classErgName?: ErgNameData;
	/**
	 * Gibt an welches Attribut angezeigt werden soll.
	 * Verändert nicht die Anfrage an dem Server (Lädt nur Attribut x)
	 */
	objectInfo?: string;
	sortExpr?: string;
	title?: string;
	titleId?: string;
}

export enum ComponentTypeET {
	TextField,
	ObjectReference,
	DateTime,
	ComboBox,
	DropDownList,
	Checkbox,
	InputSwitch,
	GroupBox,
	Panel,
	TabView,
	MultiSelect,
	RelationTab,
	RadioButton,
	RadioButtonGroup,
	FileUpload,
	Drawer,
	NumericText,
	Listview,
	RelationGrid,
	ExpansionPanel,
	Undefined
}

export enum DrawerModeET {
	Normal,
	Compact,
	CompactAutoExpand
}

export enum MultiSelectValueTypeET {
	String,
	PDObject,
	EnumerationItem
}

export type DrawerExpandModeT = 'push' | 'overlay';

export type DrawerPositionT = 'start' | 'end';

export type CreateRelationObjectCallback = (pdClass: IPDClass) => IPDObject;

// todo: Ändern in export type ComponentUIState = { ... }
export interface IComponentUIState {
	hidden?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	mandatory?: boolean;
}

export type UIStateProvider = (source: IComponent, evtCtx: IEventProcessingContext) => IComponentUIState;

export type CustomChoiceProvider = (source: IRelationComponent, ctx: IEventProcessingContext) => ICustomChoiceProviderResult[];

export interface IUIStateProviderRegistration {
	cancel(updateUIState: boolean, updateChilds: boolean): void;
}

export type ComponentUIStateExpression = {
	hidden?: string;
	disabled?: string;
	readonly?: string;
	mandatory?: string;
}

export type ComponentEventSpec = {
	eventName: string;
	eventHandler: ComponentEventHandler
}

export type ForeignComponentEventSpec = {
	foreignCompId: string,
	eventName: string,
	eventHandler: ForeignComponentEventHandler
}

export type ComponentEventHandler = (source: IComponent, event: string, ctx: IEventProcessingContext) => void;

export type ForeignComponentEventHandler = (source: IComponent, foreignComp: IComponent, event: string, ctx: IEventProcessingContext) => void;

export type CustomEventHandler = (customEvent: CustomEvent<any>, source: IComponent, ctx: IEventProcessingContext) => void;

export type PDTextFieldUIStateUpdateType = 'never' | 'emptyToNotEmpty' | 'always';

export type LoaderType = 'pulsing' | 'infinite-spinner' | 'converging-spinner';

export type LoaderSize = 'small' | 'medium' | 'large';
