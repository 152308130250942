import { Observable } from 'rxjs';
import { IPDObject } from '../model/i-pd-object';
import { IPDListColumnInfo } from '../model/types';

export enum DialogResultET {
	Ok,
	Cancel,
	Yes,
	No
}

export interface ISelectPDObjectResult {
	selection?: IPDObject;
}

export interface ISelectPDObjectsResult {
	selection: IPDObject[];
}

export interface IActionButton {
	text: string;
	themeColor?: string;
	id: DialogResultET;
	cssClass: string; // Für E2E-Test Identifizierung
}

export interface IDialogSettings {
	width?: string;
	height?: string;
	minWidth?: string;
	maxWidth?: string;
	minHeight?: string;
	maxHeight?: string;
}

export interface ISelectObjectOptions extends IDialogSettings {
	filterExpr?: string;
	sortExpr?: string;
	columnInfos?: IPDListColumnInfo[];
	choiceObjects?: Observable<IPDObject[]>;
}


export abstract class IInteractionService {
	/**
	 * Öffnet ein Hinweis-Popup, welches man nur mit "ok" bestätigen kann.
	 * @param title Titel des popups
	 * @param message Nachricht des popups
	 * @param settings Layout-Einstellungen zum Popup
	 * @returns Nichts - Observable für Schließen nur
	 */
	abstract showOkMessage(
		title: string,
		message: string,
		settings?: IDialogSettings
	): Observable<void>;

		/**
	 * Öffnet ein Popup, welches man bestätigen muss oder abbricht.
	 * @param title Titel des popups
	 * @param message Nachricht des popups
	 * @param settings Layout-Einstellungen zum Popup
	 * @returns Ergebnis des Dialogs
	 */
	abstract showConfirmMessage(
		title: string,
		message: string,
		settings?: IDialogSettings
	): Observable<DialogResultET>;

		/**
	 * Öffnet ein Popup, welches man mit "ja" oder "nein" bestätigt.
	 * @param title Titel des popups
	 * @param message Nachricht des popups
	 * @param settings Layout-Einstellungen zum Popup
	 * @returns Ergebnis des Dialogs
	 */
	abstract showYesNoMessage(
		title: string,
		message: string,
		settings?: IDialogSettings
	): Observable<DialogResultET>;

	/**
	 * Öffnet ein Popup, welches eine Liste anzeigt, bei der man ein pdObjects auswählen kann.
	 * @param className Name der Klasse, die zur Auswahl steht
	 * @param title Titel des Popups
	 * @param options Einstellungen für die Auswahl
	 * @param customContent ?
	 * @return Liefert das ausgewählte pdObject zurück
	 */
	abstract selectPDObject(
		className: string,
		title: string,
		preSelectedObject?: IPDObject,
		options?: ISelectObjectOptions,
		customContent?: any
		): Observable<ISelectPDObjectResult>;

	/**
	 * Öffnet ein Popup, welches eine Liste anzeigt, bei der man mehrere pdObjects auswählen kann.
	 * @param className Name der Klasse, die zur Auswahl steht
	 * @param title Titel des Popups
	 * @param preSelectedOids Oids der initilal zu seletierenden Objekte
	 * @param options Einstellungen für die Auswahl
	 * @param customContent ?
	 * @return Liefert die ausgewählten pdObjects zurück
	 */
	abstract selectPDObjects(
		className: string,
		title: string,
		preSelectedObjects?: IPDObject[],
		options?: ISelectObjectOptions,
		customContent?: any
	): Observable<ISelectPDObjectsResult>;

	/**
	 * Öffnet ein selbst definiertes Popup.
	 * @param title Titel des Popups
	 * @param buttons Selbst definierte Buttons die angezeigt werden sollen
	 * @param content Inhalt des Popups
	 */
	abstract showCustomMessage(
		title: string,
		buttons: IActionButton[],
		content: any
		): Observable<DialogResultET>;

	/**
	 * 
	 */
	abstract get isDialogVisible(): boolean;
}
