import { Component, Input, Output, EventEmitter,
	HostListener, ViewChild, ElementRef, HostBinding, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { IToolBarItemResult, ILabeledControlComponent, VisibilityET, LabelPositionET, ICssStyle } from '@otris/ng-core-types';
import { PDObject } from '@otris/ng-core-shared';
import { FormHandlerService, LabeledControlWidgetInfo, UIItemSpec } from '@otris/ng-core';

@Component({
	selector: 'otris-pd-labeled-control-frame',
	template: `
		<label #label class="label k-form-field" style="display: flex;" (click)="onClick($event)" [attr.for]="labelFor">
			@if (labeledControl?.labelPosition===labelPositionET.Top) {
				@if (labeledControl?.labelVisible) {
					<div class="top-label-container" [style.white-space]="wrapLabel ? 'normal' : 'nowrap'">
						<div class="label-text" [ngStyle]="labelStyle" style="margin: auto 0; overflow-wrap: break-word;"> <!-- word-break: break-all; entfernt -->
							{{labeledControl?.label}}
						</div>
						@if (labeledControl?.isEnabled && hasShortDescription) {
							<otris-pd-short-description 
								style="margin-left: 0.5em;" 
								[highlighted]="mouseIsOver"
								[pdObject]="pdObject"	
								[pdComponent]="labeledControl"
							/>
						}
						@if (labeledControl?.isEnabled && hasErrors) {
							<otris-pd-error-indicator 
								style="margin-left: 0.5em;"
								[relatedFormControl]="relatedFormControl"
								[propertyName]="labeledControl.propertyName"
								[pdObject]="pdObject"
							/>
						}
						@if (toolbarVisible) {
							<otris-tool-bar 
							class="toolbar" 
							(buttonClick)="onToolBarButtonClick($event)" 
							[itemSpecs]="labeledControl?.toolbarSpec.items"
							[disabled]="!labeledControl?.isEnabled"
						/>
						}
					</div>
				} @else {
					@if (labeledControl?.labelVisibility===visibilityET.Hidden) {
						<div class="top-label-container-empty">
							@if (labeledControl?.isEnabled && hasShortDescription) {
								<otris-pd-short-description 
									style="margin-left: 0.5em;" 
									[highlighted]="mouseIsOver"
									[pdObject]="pdObject"
									[pdComponent]="labeledControl"
								/>
							}
							@if (labeledControl?.isEnabled && hasErrors) {
								<otris-pd-error-indicator 
									style="margin-left: 0.5em;" 
									[relatedFormControl]="relatedFormControl"
									[propertyName]="labeledControl.propertyName" 
									[pdObject]="pdObject"
								/>
							}
							@if (toolbarVisible) {
								<otris-tool-bar
									class="toolbar"
									(buttonClick)="onToolBarButtonClick($event)"
									[itemSpecs]="labeledControl?.toolbarSpec.items"
									[disabled]="!labeledControl?.isEnabled"
								/>
							}
						</div>
					}
				}
			}
			<div class="content-root">
				@if (labeledControl?.labelPosition===labelPositionET.Left) {
					@if (labeledControl?.labelVisible) {
						<div 
							[ngStyle]="labelStyle"
							style="margin: auto 0.5em auto 0"
						>
							{{labeledControl?.label}}
						</div>
					}
				}
				<div #content [ngClass]="{ 'horizontal-content': labeledControl?.labelPosition!==labelPositionET.Top, 'vertical-content': labeledControl?.labelPosition===labelPositionET.Top }">
					<ng-content></ng-content>
				</div>

				@if (labeledControl?.labelPosition===labelPositionET.Left) {
					@if (labeledControl?.isEnabled && hasShortDescription) {
						<otris-pd-short-description
							style="margin-left: 0.5em;"
							[highlighted]="mouseIsOver"
							style="margin: auto 0;"
							[pdObject]="pdObject"
							[pdComponent]="labeledControl"
						/>
					}
					@if (labeledControl?.isEnabled && hasErrors) {
						<otris-pd-error-indicator
							style="margin-left: 0.5em;"
							[relatedFormControl]="relatedFormControl"
							[propertyName]="labeledControl.propertyName"
							[pdObject]="pdObject"
						/>
					}
					@if (toolbarVisible) {
						<otris-tool-bar
							class="toolbar"
							(buttonClick)="onToolBarButtonClick($event)"
							[itemSpecs]="labeledControl?.toolbarSpec.items"
							[disabled]="!labeledControl?.isEnabled"
						/>
					}
				}
				@if (labeledControl?.labelPosition===labelPositionET.Right) {
					<div
						[ngStyle]="labelStyle"
						style="margin: auto 0 auto 0.5em;"
					>
						{{labeledControl?.label}}
					</div>
					@if (labeledControl?.isEnabled && hasShortDescription) {
						<otris-pd-short-description
							style="margin-left: 0.5em;"
							[highlighted]="mouseIsOver"
							style="margin: auto 0;"
							[pdObject]="pdObject"
							[pdComponent]="labeledControl"
						/>
					}
					@if (labeledControl?.isEnabled && hasErrors) {
						<otris-pd-error-indicator 
							style="margin-left: 0.5em;"
							[relatedFormControl]="relatedFormControl"
							[propertyName]="labeledControl.propertyName"
							[pdObject]="pdObject"
						/>
					}
					@if (toolbarVisible) {
						<otris-tool-bar 
							class="toolbar"
							(buttonClick)="onToolBarButtonClick($event)"
							[itemSpecs]="labeledControl?.toolbarSpec.items"
							[disabled]="!labeledControl?.isEnabled"
						/>
					}
				}
			</div>
		</label>
	`,
	styles: [`
		:host {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
		}
		.label {
			flex-direction: column;
			flex: 1 1 auto;
			margin-top: 0;
		}
		.top-label-container {
			display: flex;
			align-items: center;

			margin-bottom: 0.5em;
			/*white-space: nowrap;*/
		}
		.top-label-container-empty {
			display: flex;
			margin-bottom: 0.5em;
			height: 1.5em;
			white-space: nowrap;
		}
		.content-root {
			display: flex;
			flex: 1;
		}
		.horizontal-content {
			flex: 1;
			display: flex;
		}
		.vertical-content {
			flex: 1;
			display: grid;
			grid-template-columns: minmax(1px, 1fr);
			grid-auto-flow: column;
		}
		.toolbar {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	`]
})
export class PDLabeledControlFrameComponent implements OnInit {
	// notwendig, da sonst z.B. bei einer ObjectReference mit Toolbar beim Clicken auf das Label der 1. Toolbarbutton gedrückt wird
	// todo: ggf. konfigurierbar machen, bei Textfeldern macht das automatische aktivieren des Textfeldes ja Sinn.
	onClick(event: any) {
		this.labelClick.emit();


		// TEST
		//(this.labeledControl as UIAbstractComponent).formHandler.updateUIState();
		//this._formHandler.updateUIState();

		// if (event.currentTarget == this._labelElement.nativeElement) {
		// 	event.preventDefault();
		// 	return;
		// }
		//event.stopPropagation();
		/*if (!this.propagateClickEvent) {
			event.preventDefault();
		}*/
	}


	/**
	 * Ziel welches Element aktiviert werden soll beim label click
	 * Default: Das erste Element wird aktiviert.
	 *
	 * Soll keins "aktiviert" werden, benutze [disableFirstElementClick]="true"
	 */
	@Input() for: string;

	/**
	 * Soll das erste Element im label-tag "aktiviert" werden?
	 *
	 * Wenn ein bestimmtes Element "aktiviert" werden soll, dann
	 * nutze [for]="deineId"
	 */
	@Input() disableFirstElementClick: boolean = false;

	get labelFor(): string | null {
		if (this.disableFirstElementClick) {
			return "";
		}
		if (this.for) {
			return this.for;
		}
		// Wenn "for" gesetzt ist darf "toolbarVisible" diesen Wert nicht überschreiben.
		// Deswegen keine "Zusammenfassung" im ersten if
		if (this.toolbarVisible) {
			return "";
		}
		// Default
		return null;
	}

	// Nicht zuverlässig mit label
	// @Input() propagateClickEvent: boolean = false;

	@Input() labeledControl: ILabeledControlComponent;

	@Input() relatedFormControl: AbstractControl;

	@Input() pdObject: PDObject;

	@Input() uiItemSpec: UIItemSpec;

	@Output() toolBarButtonClick = new EventEmitter<IToolBarItemResult>();

	@Output() labelClick = new EventEmitter();

	@HostBinding('id') get cssId(): string {
		return this.getId();
	}

	mouseIsOver: boolean = false;

	get hasShortDescription(): boolean {
		return this.labeledControl ?
			this.labeledControl.hasShortDescription && this.labeledControl.shortDescriptionVisible :
			false;
	}

	get hasErrors(): boolean {
		return this.relatedFormControl /*&& this.relatedFormControl.dirty*/ && !this.relatedFormControl.valid;
	}

	//visibilityET = (vis as any);

	//visibilityET = (ngCoreTypesModule as any).VisibilityET;
	visibilityET = VisibilityET;

	//labelPositionET = (ngCoreTypesModule as any).LabelPositionET;
	labelPositionET = LabelPositionET;

	@ViewChild('label') _labelElement: ElementRef;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	get wrapLabel(): boolean {
		if (this.labeledControl && this.labeledControl.wrapLabel === false) {
			return false;
		}
		return true;
		//return this.labeledControl && this.labeledControl.wrapLabel;
	}

	get toolbarVisible(): boolean {
		if (this.labeledControl) {
			return this.labeledControl.toolbarSpec && !this.labeledControl.isReadonly;
		}
		return false;
	}

	/*get mandatoryIndicatorVisible(): boolean {
		if (this.labeledControl) {
			return this.labeledControl.isMandatory && !this.labeledControl.isDisabled && !this.labeledControl.isReadonly && !this.labeledControl.getFormControlStatus().valid;
		}
		return false;
	}*/

	get labelStyle(): ICssStyle | undefined {
		return this.labeledControlWidgetInfo?.labelStyle?.style;
	}

	private get labeledControlWidgetInfo(): LabeledControlWidgetInfo {
		return this.uiItemSpec?.widgetInfo instanceof LabeledControlWidgetInfo ?
			<LabeledControlWidgetInfo>this.uiItemSpec.widgetInfo : undefined;
	}

	//private _toolbarButtonClickSubject: Subject<IToolBarItemResult> = new Subject();

	//private _toolbarButtonClick: Observable<IToolBarItemResult>;

	/*get toolbarButtonClick(): Observable<IToolBarItemResult> {
		if (!this._toolbarButtonClick) {
			this._toolbarButtonClick = this._toolbarButtonClickSubject.asObservable();
		}
		return this._toolbarButtonClick;
	}*/

	constructor(private _formHandler: FormHandlerService) {
	}

	ngOnInit(): void {
	}

	getId(): string {
		if (this.labeledControl) {
			return this.labeledControl.id + '.pdLabeledControlFrame';
		}
		return "pdLabelControllFrame";
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		this.toolBarButtonClick.emit(item);
		//this._toolbarButtonClickSubject.next(item);
	}
}
