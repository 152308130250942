import { Component, OnInit, ViewChild, ElementRef, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { IToolBarItemResult, ILabeledControlComponent, ToolbarSpec, VisibilityET, LabelPositionET, IToolBarItemSpec } from '@otris/ng-core-types';
import { PDComponent } from '../pd/pd.component';
import { LabeledControlWidgetInfo } from '../../model/pd-layout';
//import { VisibilityET, LabelPositionET } from '../../model/i-pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';
import { EventProcessingContextManagerService } from '../../services/event-processing-context-manager.service';
//import * as ngCoreTypesModule from '@otris/ng-core-types'

/*@Component({
	selector: 'otris-pd-labeled-control',
	template: ``,
	styles: []
})*/
@Directive()
export abstract class PDLabeledControlComponent extends PDComponent implements ILabeledControlComponent {
	@ViewChild('prefixLabel') prefixLabel: ElementRef;

	visibility = VisibilityET;

	labelPositionET = LabelPositionET;

	// todo: umbenennen in customToolbarButtonClick
	private _toolbarButtonClick: Subject<IToolBarItemResult> = new Subject();

	private _toolbarSpec: ToolbarSpec;

	get toolbarButtonClick(): Observable<IToolBarItemResult> {
		return this._toolbarButtonClick.asObservable();
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	get labeledControlWidgetInfo(): LabeledControlWidgetInfo {
		return this.pdItemSpec.widgetInfo instanceof LabeledControlWidgetInfo ?
			<LabeledControlWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	get prefixWidth(): string | undefined {
		let widgetInfo = this.labeledControlWidgetInfo;
		if (widgetInfo) {
			return widgetInfo.prefixWidth;
		}
		return undefined;
	}

	get prefix(): string | undefined {
		let widgetInfo = this.labeledControlWidgetInfo;
		if (widgetInfo) {
			return widgetInfo.prefix;
		}
		return undefined;
	}

	get labelVisible(): boolean {
		return !this.labeledControlWidgetInfo || this.labeledControlWidgetInfo.labelVisibility == VisibilityET.Visible;
	}

	get labelVisibility(): VisibilityET {
		let wi = this.labeledControlWidgetInfo;
		return wi ? wi.labelVisibility : VisibilityET.Visible;
	}

	get labelPosition(): LabelPositionET {
		let widgetInfo = this.labeledControlWidgetInfo;
		if (widgetInfo) {
			return widgetInfo.labelPosition;
		}
		return LabelPositionET.Top;
	}

	get wrapLabel(): boolean {
		let wi = this.labeledControlWidgetInfo;
		return wi ? wi.wrapLabel : true;
	}

	get toolbarSpec(): ToolbarSpec | undefined {
		return this._toolbarSpec;
		/*let wi = this.labeledControlWidgetInfo;
		if (wi) {
			return wi.toolbar;
		}
		return undefined;*/
	}

	get shortDescriptionVisible(): boolean {
		let wi = this.labeledControlWidgetInfo;
		return wi?.shortDescriptionVisible ?? true;
	}

	ngOnInit() {
		super.ngOnInit();

		let widgetInfo = this.labeledControlWidgetInfo;
		if (widgetInfo) {
			/*if (widgetInfo.useCustomLabel != undefined) {
				this.useCustomLabel = widgetInfo.useCustomLabel;
			}
			if (widgetInfo.customLabel) {
				this.customLabel = widgetInfo.customLabel;
			}
			else if (widgetInfo.customLabelId) {
				this.pdObject.metaData.getStringFromId(widgetInfo.customLabelId).subscribe(res => this.customLabel = res);
			}*/
			if (widgetInfo.toolbar) {
				this._toolbarSpec = widgetInfo.toolbar;
			}
			if (widgetInfo.toolbarButtonClickHandler) {
				this.addSubscription(this.toolbarButtonClick.subscribe(
					res => widgetInfo.toolbarButtonClickHandler(this, res, this.createEventProcessingContext())
				));
			}
		}
	}

	/*protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.labeledControlWidgetInfo?.customLabelId) {
			this.pdObject.metaData.getStringFromId(this.labeledControlWidgetInfo.customLabelId).subscribe(res => this.customLabel = res);
		}
	}*/

	ngAfterViewInit() {
		super.ngAfterViewInit();
		let pw = this.prefixWidth;
		if (this.prefixLabel && pw) {
			this.prefixLabel.nativeElement.style.width = pw;
			this.prefixLabel.nativeElement.style.textAlign = 'right';
			this.prefixLabel.nativeElement.style.flex = '0 0 auto';
		}
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		this._toolbarButtonClick.next(item);
	}

	protected addToolbarItems(position: 'start' | 'end', ...items: IToolBarItemSpec[]): void {
		if (!this._toolbarSpec) {
			this._toolbarSpec = new ToolbarSpec(items);
		}
		else {
			this._toolbarSpec.addItems(position, ...items);
		}	
	}
}
