
import { PDObject } from '@otris/ng-core-shared';
import { IPDObjectRaw } from '@otris/ng-core-types';

export class JafWebPDObject extends PDObject {

	get pdObjectRaw(): IPDObjectRaw {
		return this._pdObjectRaw;
	}

	constructor(private _pdObjectRaw: IPDObjectRaw) {
		super(_pdObjectRaw.oid);
	}

	getClassName(): string {
		return this.pdObjectRaw.classname;
	}
}
