import { InjectionToken } from '@angular/core';
import { IInteractionService, IModuleStateService, IPDAccessService, IPDClass } from '@otris/ng-core-types';
import { PDObject } from './pd-object';

/*export interface ICssStyle {
	[key: string]: any
}*/

/*export interface IEnumerationItem {
	enumConst: number;
	ergName: string;
}*/

/*export interface IRelationContext {
	source: PDObject;
	path: string;
	isMultiple?: boolean;
	index?: string;
	//metaData?: IRelationMetaData;
}*/

/*export interface IPDListColumnInfo {
	field: string;
	header: string;
	width?: number;
	type?: TypeET;
}*/

// JSON-Schema: schemas/pd-document-schema-json
/*export enum TypeET {
	String,
	StringArray,
	Boolean,
	Integer,
	Double,
	Date,
	Time,
	DateTime,
	Structure,
	Enum,
	Document,
	DocumentArray,
	RelationTo1,
	RelationToN,
	//RelationTo1Editable,
	RelationToNEditable // ist das nicht schon mittlerweile mit RelationToN abgedeckt
}*/

/*export enum ComponentTypeET {
	TextField,
	ObjectReference,
	DateTime,
	ComboBox,
	DropDownList,
	Boolean,
	GroupBox,
	Panel,
	TabView,
	MultiSelect,
	RelationTab,
	RadioButton,
	RadioButtonGroup,
	FileUpload,
	Drawer,
	NumericText,
	Undefined
}*/

/*export enum SelectionModeET {
	Single,
	Multiple
}*/

/*export enum VisibilityET {
	Visible,
	Hidden,
	Collapsed
}*/

/*export enum LabelPositionET {
	Top,
	Left,
	Right
}*/

/*export interface IFormStatus {
	valid: boolean;
	pristine: boolean;
	touched: boolean;
};*/

export enum ShortDescriptionFormatET {
	Text,
	Html
}

/*export enum LanguageCodeET {
	de = 'de',
	en = 'en'
}*/

/*export interface IErgName {
	names: [LanguageCodeET, string][];
}*/

//export type ErgName = [LanguageCodeET, string][];

export const IPDAccessServiceToken = new InjectionToken<IPDAccessService>('IPDAccessService');

export const IPDClassToken = new InjectionToken<IPDClass>('IPDClassToken');

export const IInteractionServiceToken = new InjectionToken<IInteractionService>('IInteractionServiceToken');

export const IModuleStateServiceToken = new InjectionToken<IModuleStateService>('IModuleStateServiceToken');


//export type ErgName = [LanguageCodeET, string][];

//
// JSON Validator
//

export const JsonSchemaToken = new InjectionToken<ISchema>('Schema');

export enum SchemaType {
	string,
	object,
	JSONSchemaType, // UNSUPPORTED - erfordert strictNullChecks=true
	JTD // UNSUPPORTED - erfordert strictNullChecks=true
}

/**
 * Wrapper für die unterschiedliche Arten der Schemas
 */
export interface ISchema {
	type: SchemaType,
	data: object | string;
}

/**
 * Ergebnis einer Validierung
 */
export interface JsonValidatorResult {
	isValid: boolean;
	errorMessage?: string | object | object[];
	errorSchema?: object;
	errorAsString?: string;
}

