import { Component, Input, Inject, ViewChild, ChangeDetectorRef, AfterViewChecked, HostBinding, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription, of, BehaviorSubject, asyncScheduler, forkJoin, defer, throwError, EMPTY } from "rxjs";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { DropDownListComponent, PreventableEvent } from '@progress/kendo-angular-dropdowns';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TextAreaComponent, TextBoxComponent } from '@progress/kendo-angular-inputs';
import { xIcon } from "@progress/kendo-svg-icons";

import {
	PDLabeledControlComponent,
	ObjectReferenceWidgetInfo,
	FormHandlerService,
	IPopupManagerServiceToken,
	IPopupManagerService,
	IPDObjectEditContextToken,
	IPDObjectEditContext,
	EventProcessingContextManagerService,
	IChangeDetectionController,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET,
	IPDAccessService,
	IPDChoiceSpec,
	ObjectReferenceTypeET,
	IObjectReferenceComponent,
	ICustomChoiceProviderResult,
	IToolBarItemResult,
	IToolBarItemSpec,
	IToolBarButtonSpec,
	ToolBarItemTypeET,
	IInteractionService,
	ISelectObjectOptions,
	ISelectPDObjectResult,
	IPDListColumnInfo,
	ILocalizationService,
	SelectionChangingArgs,
	IEventProcessingContext,
	IComponent,
	IRelationComponent,
	IComponentUIState,
	IPDObject,
	CustomChoiceProvider,
	ObjectReferenceComponentCommand,
	ObjectReferenceComponentEventName,
	CommandParameter,
	ErgNameData
} from '@otris/ng-core-types';

import {
	CustomPDObject,
	PDObject,
	IPDAccessServiceToken,
	IInteractionServiceToken,
	ServiceLocator,
	IPDClassToken
} from '@otris/ng-core-shared';

interface IChoiceData {
	ergName: string;
	value: PDObject;
	type: 'choiceObject' | 'newObject' | 'relationObject' | 'noSelectionItem'; // 'customPDObject' statt 'newObject'
	//isNew?: boolean; // todo
	className?: string;
	classErgName?: string;
}

enum ToolBarButtonIdET {
	Select = 'idSelect',
	New = 'idNew'
}

export function objectReferenceTextFieldMaxLengthValidator(maxLength: number, textField: TextAreaComponent | TextBoxComponent): ValidatorFn {
	return (): ValidationErrors | null => {
		const textFieldMaxLength = textField.value?.length > maxLength;
		return textFieldMaxLength ? { objectReferenceTextFieldMaxLength: { value: maxLength } } : null;
	};
 }

/*
	Todos:
	- Falls ein CustomChoiceProvider angegeben ist, sollte es nicht notwendig sein eine choiceSpec anzugeben
*/
@Component({
	selector: 'otris-pd-object-reference',
	templateUrl: './pd-object-reference.component.html',
	styles: [`
		.selected-value-template {
			display: flex;
		}
		.selected-value-template-icon {
			margin-right: 0.5em;
		}
		.selected-value-template-icon > i {
			vertical-align: middle;
		}
		.selected-value-template-button {
			margin-left: 0.5em;
			color: gray;
		}
		.selected-value-template-button:hover {
			color: unset;
		}
		.selected-value-template-button > i {
			vertical-align: middle;
		}
		.selected-value-template-text {
			white-space: break-spaces;
			margin: 0.125em 0.3em 0.125em 0;
		}
		.drop-down-list {
			flex: 1;
		}
		.drop-down-list-item-icon {
			margin-left: auto;
		}
		.input-text {
			flex: 1;
			display: flex;
		}
		.toolbar {
			margin: auto 0;
		}
		.selection-list-input {
			flex: 1;
			padding: 4px 8px;
			font-size: 12px; /* TODO: hard coded */
			color: #424242;
			border: none;
			outline: none;
		}
		.selection-list-clear {
			margin-left: -1.5em;
		}
		.drop-down-item {

		}
		.textarea-clear-button {
			background: none;
			border: none;
			color: grey;
		}

		.textarea-clear-button:hover {
			color: black;
		}

		.no-entry-value {
			opacity: 0.75;
			font-style: italic;
		}

		.selection-list-value-container {
			background-color: white;
			display: inline-flex;
			width: 100%;
			min-height: 2em;
			border: solid;
			border-color: #ebebeb;
			border-radius: 0.25em;
			padding: 0 0.5em;
			border-width: 1px;
		}

		.selection-list-value-container > * {
			margin-top: auto;
			margin-bottom: auto;
		}

	`]
})
export class PDObjectReferenceComponent extends PDLabeledControlComponent implements IObjectReferenceComponent, AfterViewChecked {

	@ViewChild('kendoDropDownList') dropDownList: DropDownListComponent;

	//@ViewChild('textField') textFieldComponent: TextAreaComponent | TextBoxComponent;

	@ViewChild('textField')
	set textFieldComponent(comp: TextAreaComponent | TextBoxComponent) {
		if (comp != this._textFieldComponent) {
			this._textFieldComponent = comp;
			this.updateValidators();
		}
	}

	get textFieldComponent(): TextAreaComponent | TextBoxComponent {
		return this._textFieldComponent;
	}

	private _textFieldComponent: TextAreaComponent | TextBoxComponent;

	@Input() noSelectionItemText: string;

	@Input() selectEnabled = true;

	private _selectionListVisible = false;

	get selectionListVisible(): boolean {
		return this._selectionListVisible;
	}

	noSelectionItem$: Observable<IChoiceData>;

	private _choiceSpecs: IPDChoiceSpec[] = [];

	private _choiceData: IChoiceData[] = [];

	private _choiceDataValid = false;

	private get choiceDataValid(): boolean {
		return this._choiceDataValid;
	}

	private set choiceDataValid(val: boolean) {
		if (val != this._choiceDataValid) {
			this._choiceDataValid = val;
			if (!val) {
				this._choiceData = [];
				this._choiceDataMap.clear();
			}
		}
	}

	private _groupChoiceData = false;

	protected filteredChoiceData: GroupResult[] | IChoiceData[] = [];

	private _choiceFilter: string;

	private _choiceDataMap: Map<string, IChoiceData> = new Map<string, IChoiceData>();

	get objectReferenceWidgetInfo(): ObjectReferenceWidgetInfo | undefined {
		if (this.pdItemSpec.widgetInfo instanceof ObjectReferenceWidgetInfo) {
			return <ObjectReferenceWidgetInfo>this.pdItemSpec.widgetInfo;
		}
		return undefined;
	}

	protected get selectedValue(): IChoiceData {
		return this._selectedValue;
	}

	protected set selectedValue(val: IChoiceData) {
		let ctrl = this.control;
		if (!ctrl) {
			return;
		}
		if (this._selectedValue !== val && (this._cancelSelectionPending || !this._setSelectionPending)) {
			this._setSelectionPending = true;
			let cancel$ = of(false);
			if (!this._cancelSelectionPending) {
				let args: SelectionChangingArgs = {
					oldSelection: this._selectedValue ? this._selectedValue.value : undefined,
					newSelection: val ? val.value : undefined
				};
				this._selectionChangingSubject$.next(args);
				if (args.cancel$) {
					cancel$ = args.cancel$;
				}
			}
			cancel$.subscribe(res => {
				// cancel
				if (res) {
					let oldVal = this._selectedValue;
					this._selectedValue = val;
					this._cancelSelectionPending = true;
					ctrl.setValue(val ? val.value : undefined);

					asyncScheduler.schedule(() => {
						this._selectedValue = oldVal;
						ctrl.setValue(oldVal);
						ctrl.markAsDirty();
						this.cdref.detectChanges();
						this._cancelSelectionPending = false;
					});
					this._setSelectionPending = false;
					return;
				}
				this._selectedValue = val;
				if (!!this._selectedValue){
					if (!(this._selectedValue.value instanceof CustomPDObject)) {
						this.removeCustomObjectFromChoice();
					}
				}
				ctrl.markAsDirty();
				ctrl.setValue(val ? val.value : undefined);
				// bei val === null darf das Objekt nicht verändert werden. Dies tritt z.B. auf, wenn updateChoice() noch nicht abgeschlossen ist.
				if (val !== null) {
					this.pdObject.pdObjectRaw[this.propertyName] = val ? val.value : undefined;
				}
				if (!this._cancelSelectionPending) {
					this._selectionChangedSubject$.next(this._selectedValue ? this._selectedValue.value : undefined); // undefined auf null geändert
					this._selectionChangedUIStateSubject$.next(this._selectedValue ? this._selectedValue.value : undefined);
				}
				this._setSelectionPending = false;
			});
		}
	}

	private _selectedValue: IChoiceData | null | undefined = undefined;

	private _cancelSelectionPending = false;

	private _setSelectionPending = false;

	objectReferenceTypeET = ObjectReferenceTypeET;

	toolBarItems: IToolBarItemSpec[];

	get objectReferenceType(): ObjectReferenceTypeET {
		let wi = this.objectReferenceWidgetInfo;
		return wi ? wi.type : ObjectReferenceTypeET.DropDownList;
	}

	private _choiceUpdatedSubject$: Subject<void> = new Subject();

	private _choiceUpdatedSubjectUiState$: Subject<void> = new Subject();

	private _choiceUpdated$: Observable<void>;

	get choiceUpdated$(): Observable<void> {
		if (!this._choiceUpdated$) {
			this._choiceUpdated$ = this._choiceUpdatedSubject$.asObservable();
		}
		return this._choiceUpdated$;
	}

	private _newObject: IChoiceData;

	private _choiceDataAction$: Observable<IChoiceData[]>;

	canChange: boolean;

	get isChangeable(): boolean {
		return this.isReadonly !== true && this.canChange !== false;
	}

	private get newObjectPossible(): boolean {
		let wi = this.objectReferenceWidgetInfo;
		return wi && wi.newAction && wi.editingSpec && !Array.isArray(wi.editingSpec.className);
	}

	get isContainerComponent(): boolean {
		return false;
	}

	noDataTextMessage = "No Data Found";

	protected textFieldFormControl: FormControl;

	protected isLoading = false;

	//private _initialRelationObject: PDObject; // todo

	private _classErgNames: Map<string, string> = new Map();

	private _subClasses: Map<string, string[]> = new Map();

	@HostBinding('attr.ot-selected-value') get getSelectedValueForHtml() {
		return this.selectedValue?.value?.objectId.oid ?? '';
	}

	closeSVG = xIcon;

	showStickyHeader = false;

	constructor(router: Router, route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService,
		formHandler: FormHandlerService,
		@Inject(IPopupManagerServiceToken) private popupManagerService: IPopupManagerService,
		@Inject(IPDObjectEditContextToken) private pdObjectEditContext: IPDObjectEditContext,
		private cdref: ChangeDetectorRef,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		private _changeDetectionController: IChangeDetectionController,
		@Inject(Injector) private _injector: Injector) {
			super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		let wi = this.objectReferenceWidgetInfo;
		if (wi) {
			if (wi.noSelectionItemText) {
				this.noSelectionItemText = wi.noSelectionItemText;
			}
			if (wi.selectionChangedHandler) {
				this.addSubscription(this.selectionChanged.subscribe(res => {
					wi.selectionChangedHandler(this, res, this.createEventProcessingContext());
				}));
			}
			if (wi.customChoiceFilterProvider) {
				this.customChoiceFilterProvider = wi.customChoiceFilterProvider;
			}
			if (wi.customChoiceProviderData?.length > 0) {
				//let formExpressionGrammarProcessor = ServiceLocator.injector.get(FormExpressionGrammarProcessorToken);
				this.customChoiceProvider = this.formExpressionGrammarProcessor.createCustomChoiceProvider(
					wi.customChoiceProviderData, this.formHandler, this._injector
				);
			}
			/* TODO
				if (wi.customChoiceProviderData) {
				let uiFormExpressionEvaluator = ServiceLocator.injector.get(IUIFormExpressionEvaluator);
				this.customChoiceProvider = (src, ctx) => {
					return uiFormExpressionEvaluator.evaluateCustomChoiceProviderData(
						this.formHandler, wi.customChoiceProviderData, ctx.customContext
					)
				}
			}*/
			else if (wi.customChoiceProvider) {
				this.customChoiceProvider = wi.customChoiceProvider;
			}
			if (wi.triggerLanguageUpdate !== undefined) {
				this._triggerLanguageUpdate = wi.triggerLanguageUpdate;
			}
			if (wi.selectAction !== undefined) {
				this.selectEnabled = wi.selectAction === true;
			}
			/* TODO
			if (wi.selectionChangedHandlerData) {
				this.selectionChanged.subscribe(val => {
					if (val !== undefined) {
						let uiFormExpressionEvaluator = ServiceLocator.injector.get(IUIFormExpressionEvaluator);
						uiFormExpressionEvaluator.evaluateEventHandlerCommandsData(this.formHandler, wi.selectionChangedHandlerData);
					}
				});
			}*/
			if (wi.showStickyHeader !== undefined) {
				this.showStickyHeader = wi.showStickyHeader === true;
			}
		}

		switch (this.objectReferenceType) {
			case ObjectReferenceTypeET.SelectionList:
				{
					this.toolBarItems = this.selectEnabled ? [
						<IToolBarButtonSpec>{
							id: ToolBarButtonIdET.Select, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-list',
							shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-select'
						}
					] : [];
					let wi = this.objectReferenceWidgetInfo;
					if (wi) {
						if (wi.newAction) {
							this.toolBarItems.push(
								<IToolBarButtonSpec>{
									id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-star',
									shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-new'
								}
							);
						}
					}
					break;
				}

			case ObjectReferenceTypeET.DropDownList:
				if (wi) {
					this.toolBarItems = [];
					if (wi.newAction) {
						this.toolBarItems.push(
							<IToolBarButtonSpec>{
								id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-star',
								shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-new'
							}
						);
					}
				}
				break;

			case ObjectReferenceTypeET.TextField:
				if (!(wi?.editableRelationProperty) || !this.newObjectPossible) {
					throw new Error(`Invalid spec file.`);
				}
				break;
		}

		this.addSubscription(this.control.valueChanges.subscribe(v => {
			if (!v) {
				this.selectedValue = null;
			}
		}));

		let langChanges$ = this.localizationService.changeHandler.pipe(
			tap(() => {
				// Sprachänderungen für Inhalt
				if (this.triggerLanguageUpdate) {
					// #45680 Verursacht onUnternehmen changed!
					this.updateNoSelectionItem();
					//this._choiceDataValid = false;
					this.updateSelectedValueErgName();
					this.notifyCustomChoiceChanged(false);
				}
			}),
			// Sprachänderung für das Widget
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-object-reference.no-data-found-message'
			])),
			tap(trans => {
				this.noDataTextMessage = trans[0]
			})
		);
		this.addSubscription(langChanges$.subscribe());

		this.updateNoSelectionItem();

		if (this.newObjectPossible) {
			this.addSubscription(this.pdObjectEditContext.getRelationObjectCreated(this.propertyName).subscribe(
				data => {
					if (data[2] == this.pdObject.objectId.oid) {
						this.addNewCustomObject(data[1]);
					}
				}
			));
			this.addSubscription(this.pdObjectEditContext.getRelationObjectEdited(this.id).subscribe(
				data => {
					if (data[2] == this.pdObject.objectId.oid) {
						let custObj = data[1];
						(this._newObject.value as CustomPDObject).assign(custObj);
						this._newObject.ergName = custObj.getStatus(wi.editingSpec.objectInfo);
						this.formControl.markAsDirty();
						this.updateSelectedValueErgName();
						//this.cdref.detectChanges();

						/*let item = this.customPDObjects.find(i => {
							return (i.value as CustomPDObject).objectId.oid === custObj.objectId.oid;
						})
						if (item) {
							(item.value as CustomPDObject).assign(custObj);
							item.ergName = custObj.getStatus(wi.editingSpec.objectInfo)
						}*/
					}
				}
			));
		}
		this.applyAccessRights();
		this.updateToolbar();
		this.updateChoiceSpecs().subscribe();
		//this.updateChoiceData();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		if (this.dropDownList?.wrapper) {
			this.dropDownList.wrapper.nativeElement.setAttribute('aria-labelledby', this.id + ".pdLabeledControlFrame");
		}
	}

	ngAfterViewChecked() {
		if (this.dropDownList?.wrapper) {
			this.dropDownList.wrapper.nativeElement.setAttribute('aria-labelledby', this.id + ".pdLabeledControlFrame");
		}
	}

	ngOnDestroy() {
		if (this._choiceDataAction$) {
			this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
		}

		/*if (this.subscription) {
			this.subscription.unsubscribe();
		}*/
		super.ngOnDestroy();
	}

	onEditCustomObjectClick(event: MouseEvent, custObj: CustomPDObject): void {
		//event.preventDefault();
		event.stopPropagation();
		this.editItem(custObj);
	}

	onSelectedValueTemplateButtonEnter(): void {
		//this._popupDisabled = true;
	}

	onSelectedValueTemplateButtonLeave(): void {
		//this._popupDisabled = false;
	}

	onClickClearSelectionList() {
		this.selection = undefined;
		// console.log("Clear List: " + this.propertyName);
	}

	onFilterChange(value: string) {
		this._choiceFilter = !value ? undefined : value;
		this.updateFilteredChoiceData();
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.Select:
				this.selectObject();
				break;

			case ToolBarButtonIdET.New:
				this.createNewObject();
				break;

			default:
				super.onToolBarButtonClick(item);
				break;
		}
	}

	onTextboxChange(val: string): void {
		if (!val) {
			this.pdObject[this.propertyName] = undefined;
		}
		else {
			let wi = this.objectReferenceWidgetInfo;
			if (this.pdObject[this.propertyName] instanceof CustomPDObject) {
				this.pdObject[this.propertyName][wi.editableRelationProperty] = val;
			}
			else {
				let pdClass = ServiceLocator.injector.get(IPDClassToken);
				let newObj = pdClass.createInstance(wi.editingSpec.className as string) as CustomPDObject;
				newObj.remoteCreationParams = wi.editingSpec.remoteCreationParams;
				newObj[wi.editableRelationProperty] = val;
				this.pdObject[this.propertyName] = newObj;
			}
		}
		this.updateSelectedValue();

		let ctrl = this.control;
		if (ctrl) {
			ctrl.updateValueAndValidity();
		}
	}

	onKeyUp(args: any): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(false);
	}

	onKeyDown(args: any): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(true);
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		this.updateToolbar();
	}

	protected determineDefaultUIState(ctx: IEventProcessingContext, ...states: IComponentUIState[]): IComponentUIState {
		let state: IComponentUIState = {};
		return state = super.determineDefaultUIState(ctx, state, ...states);
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		return super.getCustomValidators(systemValidators).pipe(
			map(res => {
				let wi = this.objectReferenceWidgetInfo;
				if (this.textFieldComponent && wi?.textFieldSpec?.maxTextLength) {
					let vals = res ? res : [];
					vals.push(objectReferenceTextFieldMaxLengthValidator(wi?.textFieldSpec?.maxTextLength, this.textFieldComponent));
					return vals;
				}
				return res;
			})
		);
	}

	protected onOpen(args: PreventableEvent): void {
		if (this.isLoading) {
			args.preventDefault();
			return;
		}
		if (!this.choiceDataValid) {
			args.preventDefault();
			this.isLoading = true;
			this.updateChoiceImpl().pipe(
				tap(() => {
					this.dropDownList.toggle(true);
				}),
				finalize(() => this.isLoading = false)
			).subscribe()
		}
	}

	// Ist nun nicht mehr async!
	private updateChoiceSpecs(): Observable<void> {
		return new Observable<IPDChoiceSpec[]>(observer => {
			// ChoiceSpecs aktualisieren
			this._choiceSpecs = [];
			if (this.objectReferenceWidgetInfo?.choiceSpec) {
				this._choiceSpecs = Array.isArray(this.objectReferenceWidgetInfo.choiceSpec) ?
					this.objectReferenceWidgetInfo.choiceSpec :
					[this.objectReferenceWidgetInfo.choiceSpec];
			}
			if (this._customChoiceProvider) {
				const res = this._customChoiceProvider(this, this.createEventProcessingContext());
				const customChoiceSpecs = res.map((r, i) => {
					return <IPDChoiceSpec>{
						className: r.className,
						relationInfo: r.objectInfo,
						sortExpr: r.sortExpr
					}
				});
				this._choiceSpecs.push(...customChoiceSpecs.filter(custSpec => !this._choiceSpecs.find(spec => spec.className === custSpec.className)));
			}
			observer.next(this._choiceSpecs)
			observer.complete()
		}).pipe(
			map(specs => {
				// SubClasses aktualisieren
				let choiceClassNames = specs.map(s => s.className);

				// Wenn bereits alle bekannt sind, dann keinen Request absetzen
				if (choiceClassNames.length === this._subClasses.size) {
					const classNamesNotFetched = choiceClassNames.filter(className => {
						return !this._subClasses.has(className);
					})
					if (classNamesNotFetched.length === 0) {
						return; // EMPTY;
					}
				}

				this._subClasses = new Map();
				this.pdMeta.getSubClasses(choiceClassNames).forEach(val => {
					this._subClasses.set(val[0], val[1]);
				});
				this.updateChoiceData();

				/*return this.pdAccessService.getSubClasses(choiceClassNames).pipe(
					tap(() => {

					}),
					map(res => {
						res.forEach(val => this._subClasses.set(val[0], val[1]));
						this.updateChoiceData();
					}),
				);*/
			})
		);
	}

	private updateChoiceData(data?: IChoiceData[]): void {

		let getClassErgName = (className: string) => {
			if (className && this._classErgNames.has(className)) {
				return this._classErgNames.get(className);
			}
			return 'n.a.';
		}

		if (this.objectReferenceType !== ObjectReferenceTypeET.DropDownList) {
			this.updateSelectedValue();
			return;
		}

		if (data) {
			this._choiceData = data;
		}

		// New object
		let currentNewObj = this._choiceData.find(d => d.value instanceof CustomPDObject && d.value.isNew);
		if (currentNewObj?.value != this._newObject?.value) {
			if (currentNewObj) {
				let pos = this._choiceData.indexOf(currentNewObj);
				this._choiceData.splice(pos, 1);
			}
			if (this._newObject) {
				let choiceClassName = this.determineChoiceDataClassName(this._newObject.className)
				this._newObject.classErgName = getClassErgName(choiceClassName);
				this._choiceData.unshift(this._newObject);
			}
		}

		// Relation-Object ggf. in Choice integrieren, falls es dort (noch) nicht enthalten ist
		let indexRelObjChoiceData = this._choiceData.findIndex(d => d.type === 'relationObject');
		let currentRelObj: PDObject = this.propertyName ? this.pdObject?.pdObjectRaw[this.propertyName] : undefined;
		if (currentRelObj) {
			// relation object vorhanden
			let relObjChoiceData = this._choiceData.find(d => d.value.objectId.oid === currentRelObj.objectId.oid);
			if (relObjChoiceData) {
				// ChoiceData für relation object bereits vorhanden
				switch (relObjChoiceData.type) {
					case 'choiceObject':
						// falls relation object schon in der normalen choice enthalten ist, choice item vom Typ 'relationObject' ggf. entfernen
						if (indexRelObjChoiceData >= 0) {
							this._choiceData.splice(indexRelObjChoiceData, 1)
						}
						break;

					case 'relationObject': {
						let clsName = this.determineChoiceDataClassName(currentRelObj.getClassName());

						this._choiceData.splice(indexRelObjChoiceData, 1);
						const newRelObjectChoiceData: IChoiceData = {
							type: 'relationObject',
							value: currentRelObj,
							className: clsName,
							classErgName: getClassErgName(clsName),
							ergName: currentRelObj.getStatus(this.getRelationInfo(currentRelObj))
						}
						this._choiceData.unshift(newRelObjectChoiceData);
						break;
					}

					case 'newObject':
						// falls relation object ein neues Objekt ist, choice item vom Typ 'relationObject' ggf. entfernen
						if (indexRelObjChoiceData >= 0) {
							this._choiceData.splice(indexRelObjChoiceData, 1)
						}
						break;
				}
			}
			else if (!(currentRelObj instanceof CustomPDObject) || !currentRelObj.isNew) {
				// ChoiceData für relation object noch nicht vorhanden
				let clsName = this.determineChoiceDataClassName(currentRelObj.getClassName());
				this._choiceData.unshift({
					type: 'relationObject',
					value: currentRelObj,
					ergName: currentRelObj.getStatus(this.getRelationInfo(currentRelObj)),
					//className: currentRelObj.getClassName(),
					className: clsName,
					classErgName: getClassErgName(clsName)
				});
			}
		}
		else {
			// relation object nicht vorhanden
			if (indexRelObjChoiceData >= 0) {
				this._choiceData.splice(indexRelObjChoiceData, 1);
			}
		}

		// update choiceDataMap
		this._choiceDataMap.clear();
		this._choiceData.reduce(
			(p, c) => {
				p.set(c.value.objectId.oid, c);
				return p;
			},
			this._choiceDataMap
		);
		this.updateFilteredChoiceData();
		this.updateSelectedValue();
		this._choiceUpdatedSubject$.next();
	}

	private determineChoiceDataClassName(className: string): string {
		if (this._subClasses.has(className)) {
			return className;
		}

		// Todo: nochmal prüfen, wird wegen Ergname Thematik bei NewObject benötigt
		if (this._choiceSpecs.find(spec => spec.className === className)) {
			return className;
		}

		for (let key of this._subClasses.keys()) {
			if (this._subClasses.get(key).includes(className)) {
				return key;
			}
		}
		return 'n.a.';
	}

	private addNewCustomObject(obj: CustomPDObject) {
		// || this._choiceSpecs.length > 1
		if (!this.newObjectPossible || !obj.isNew) {
			return;
		}

		let wi = this.objectReferenceWidgetInfo;
		let editingSpec = wi.editingSpec;
		obj.remoteCreationParams = wi.editingSpec.remoteCreationParams;
		let newData: IChoiceData = {
			ergName: obj.getStatus(editingSpec.objectInfo),
			value: obj,
			className: <string>editingSpec.className,
			classErgName: 'n.a.',
			type: 'newObject'
		};

		switch (this.objectReferenceType) {
			case ObjectReferenceTypeET.DropDownList: {
				this._newObject = newData;
				this.updateChoiceData();
				this.selection = obj;
				break;
			}

			case ObjectReferenceTypeET.SelectionList: {
				this.selection = obj;
				this._newObject = newData;
				break;
			}
		}
	}

	private removeCustomObjectFromChoice() {
		if (!this._newObject || this.objectReferenceType !== ObjectReferenceTypeET.DropDownList) {
			return;
		}

		this.updateChoiceData();
		this._newObject = undefined;
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		//this._initialRelationObject = undefined;
		if (this.pdObject && this.propertyName) {
			if (this.pdObject[this.propertyName] instanceof CustomPDObject) {
				let custObj = this.pdObject[this.propertyName] as CustomPDObject;
				if (custObj.isNew) {
					this.addNewCustomObject(custObj);
				}
			}
		}
		this.updateChoiceData();
	}

	private updateFilteredChoiceData() {
		let data = this._choiceData;
		if (data.length > 0 && this._choiceFilter) {
			let filter = this._choiceFilter.toLowerCase();
			data = data.filter(d => d.ergName.toLowerCase().indexOf(filter) !== -1);
		}
		this.filteredChoiceData = this._groupChoiceData ? groupBy(data, [{ field: "classErgName" }]) : data;
		this._choiceUpdatedSubjectUiState$.next();
	}

	private updateNoSelectionItem(): void {
		// Lokalisierung muss immer erfolgen, auch wenn mandatory.
		// Dass das default-item nicht zur Auswahl stehen darf, wenn es mandatory ist, muss anders gelöst werden.
		// if (this.isMandatory) {
		// 	this.noSelectionItem$ = of(undefined);
		// 	return;
		// }

		if (this.noSelectionItemText) {
			this.noSelectionItem$ = of({ ergName: this.noSelectionItemText, value: null, type: 'noSelectionItem' });
		} else {
			this.noSelectionItem$ = this.localizationService.getSystemString('kendo-ui.components.pd-object-reference.no-selection-item-text').pipe(
				map(str => {
					return { ergName: str, value: null, type: 'noSelectionItem' };
				})
			);
		}
	}

	private updateSelectedValue() {
		if (this.pdObject && this.propertyName) {
			let sel = this.pdObject.pdObjectRaw[this.propertyName];
			let wi = this.objectReferenceWidgetInfo;
			//if (sel instanceof PDObject) {
				switch (this.objectReferenceType) {
					case ObjectReferenceTypeET.DropDownList:
						if (sel instanceof PDObject && this._choiceDataMap.has(sel.objectId.oid)) {
							this.selectedValue = this._choiceDataMap.get(sel.objectId.oid);
							return;
						}
						this.selectedValue = null;
						return;

					case ObjectReferenceTypeET.SelectionList:
						if (sel instanceof CustomPDObject) {
							if (this.newObjectPossible) {
								this.selectedValue = {
									className: <string>wi.editingSpec.className,
									ergName: sel.getStatus(wi.editingSpec.objectInfo),
									value: sel,
									type: 'newObject'
								}
								return;
							}
						}
						else if (sel instanceof PDObject) {
							if (this._choiceSpecs.length == 1) {
								this.selectedValue = {
									className: this._choiceSpecs[0].className,
									ergName: sel.getStatus(this.getRelationInfo(sel)),
									value: sel,
									type: 'choiceObject'
								}
								return;
							} else { // Die choiceSpec ist eventuell zu diesem Zeitpunkt noch nicht geladen.
								return;
							}
						}
						this.selectedValue = null;
						return;

					case ObjectReferenceTypeET.TextField:
						if (sel instanceof CustomPDObject) {
							this.selectedValue = {
								className: <string>wi.editingSpec.className,
								ergName: sel.pdObjectRaw[wi.editableRelationProperty],
								value: sel,
								type: 'newObject'
							}
							return;
						}
						else if (sel instanceof PDObject) {
							if (this._choiceSpecs.length != 1) {
								return; // die choiceSpec ist eventuell zu diesem Zeitpunkt noch nicht geladen.
							}
							this.selectedValue = {
								className: this._choiceSpecs[0].className,
								ergName: sel.getStatus(this.getRelationInfo(sel)),
								value: sel,
								type: 'choiceObject'
							};
							return;
						}
						this.selectedValue = null;
						return;

				}
			//}
		}
		//this.selectedValue = null;
	}

	private getChoiceFilterExpression(spec: IPDChoiceSpec): string | undefined {
		if (this._customChoiceFilterProvider) {
			let res = this._customChoiceFilterProvider(this, this.createEventProcessingContext());
			if (this._choiceSpecs.length == 1) {
				if (typeof (res) === 'string') {
					return res;
				}
			}
			else if (res instanceof Map && res.has(spec.className)) {
				return res.get(spec.className);
			}
		}

		return spec.filterExpr;
	}

	private updateSelectedValueErgName(): void {
		// if (!(this.objectReferenceType === ObjectReferenceTypeET.SelectionList || this.objectReferenceType === ObjectReferenceTypeET.TextField) ||
		// 	!this.selectedValue || this._choiceSpecs.length != 1) { // todo
		if (!this.selectedValue) { // || this._choiceSpecs.length != 1) { Warum wird hier auf this._choiceSpecs.length != 1 geprüft?
			return;
		}

		if (this.objectReferenceType === ObjectReferenceTypeET.DropDownList) {
			this.updateChoiceImpl().subscribe();
			return;
		}

		if (this.selectedValue.value instanceof CustomPDObject) {
			if (this.newObjectPossible) {
				let wi = this.objectReferenceWidgetInfo;
				this.selectedValue.ergName = this.selectedValue.value.getStatus(wi.editingSpec.objectInfo);
			}
		}
		else {
			this.selectedValue.ergName = this.selectedValue.value.getStatus(this.getRelationInfo(this.selectedValue.value));
		}
	}

	private updateChoiceImpl(): Observable<boolean> {
		if (this.objectReferenceType !== ObjectReferenceTypeET.DropDownList) {
			return throwError(() => 'Invalid operation call.');
		}
		let choiceClassNames: string[];
		let choiceClassErgNames: Map<string, string> = new Map();
		let data$: Observable<[string, string, PDObject[]][]>;
		if (this._customChoiceProvider) {
			let res = this._customChoiceProvider(this, this.createEventProcessingContext());
			choiceClassNames = res.map(res => {
				if (res.classErgName) {
					let ergName = this.localizationService.getFromErgNameData(res.classErgName);
					choiceClassErgNames.set(res.className, ergName);
				}
				return res.className; 
			});
			let extents$ = res.map((r, i) => r.data.pipe(
				map(d => <[string, string, PDObject[]]>[r.className, r.objectInfo ? r.objectInfo : 'n.a.', d])
			));
			data$ = forkJoin(extents$);
		}
		else if (this._choiceSpecs.length > 0) {
			choiceClassNames = this._choiceSpecs.map(s => {
				if (s.classErgName) {
					let ergName = this.localizationService.getFromErgNameData(s.classErgName);
					choiceClassErgNames.set(s.className, ergName);
				}
				return s.className;
			});
			let getExtentCalls: Observable<[string, string, PDObject[]]>[] = [];
			this._choiceSpecs.forEach((spec, iSpec) => {
				let filterExpr = this.getChoiceFilterExpression(spec);
				let call: Observable<[string, string, PDObject[]]> = this.pdAccessService.getExtent(spec.className, filterExpr, spec.sortExpr, spec.properties).pipe(
					map(res =>
						<[string, string, PDObject[]]>[spec.className, this.getRelationInfo(spec.className), res]
					)
				);
				getExtentCalls.push(call);
			});
			data$ = forkJoin(getExtentCalls);
		}

		if (data$) {
			let getClassErgNames$ = choiceClassNames.map(cls => {
				if (choiceClassErgNames.has(cls)) {
					return of(<[string, string]>[cls, choiceClassErgNames.get(cls)]);
				}
				return this.metaDataService.getClassErgName(cls).pipe(
					map(erg => <[string, string]>[cls, erg])
				)
			});
			let action$ = forkJoin(getClassErgNames$).pipe(
				switchMap(ergNames => {
					this._classErgNames = ergNames.reduce(
						(map, val) => {
							map.set(val[0], val[1]);
							return map;
						},
						new Map<string, string>()
					);
					return data$.pipe(
						map(results => {
							let result: IChoiceData[] = [];
							for (let res of results) {
								for (let obj of res[2]) {
									let classErgName = this._classErgNames.has(res[0]) ? this._classErgNames.get(res[0]) : res[0];
									let data: IChoiceData = {
										ergName: obj.getStatus(res[1]),
										value: obj,
										className: res[0],
										classErgName: classErgName,
										type: 'choiceObject'
									};
									result.push(data);
								}
							}
							return result;
						}),
						tap(res => {
							this._groupChoiceData = res.length > 1 && res.findIndex(item => item.className !== res[0].className) != -1;
							this.updateChoiceData(res);
							this.choiceDataValid = true;
						})
					)
				})
			);
			return defer(() => {
				if (this._choiceDataAction$) {
					this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
				}
				this.asyncActionManagerService.notifyActionStarted(action$);
				this._choiceDataAction$ = action$;
				return action$;//.pipe(delay(3000)); // TEST
			})
			.pipe(
				map(() => true),
				finalize(() => {
					this.asyncActionManagerService.notifyActionFinished(action$);
					this._choiceDataAction$ = undefined;
				})
			);
		}

		this.updateChoiceData([]);
		this.choiceDataValid = false;
		this._classErgNames = new Map();
		this._subClasses = new Map();
		return of(false);
	}

	updateChoice(clearSelection = true): Observable<void> {
		return new Observable((observer) => {
			if (clearSelection) {
				this.selectedValue = undefined;
			}
			this.choiceDataValid = false;
			this.isLoading = true;
			observer.next();
			observer.complete();
		}).pipe(
			switchMap(() => this.updateChoiceImpl()),
			map(() => undefined),
			finalize(() => this.isLoading = false)
		);

		/*if (clearSelection) {
			this.selectedValue = undefined;
		}
		this.choiceDataValid = false;
		this.isLoading = true;
		return this.updateChoiceImpl().pipe(
			map(() => undefined),
			finalize(() => this.isLoading = false)
		);*/
	}

	private selectObject() {
		if (this.objectReferenceType !== ObjectReferenceTypeET.SelectionList || this._choiceSpecs.length != 1) {
			return;
		}

		let className = this._choiceSpecs[0].className;
		let listSpec = (this.objectReferenceWidgetInfo && this.objectReferenceWidgetInfo.selectionListSpec) ? this.objectReferenceWidgetInfo.selectionListSpec : undefined;
		let options: ISelectObjectOptions = {
			width: listSpec && listSpec.width ? listSpec.width : '60vw',
			height: listSpec && listSpec.width ? listSpec.width : '90vh',
			//columnInfos: listSpec && listSpec.columns ? listSpec.columns : undefined,
			sortExpr: listSpec?.sortExpr ?? this._choiceSpecs[0].sortExpr
		}

		if (this._selectionListColumnProvider) {
			options.columnInfos = this._selectionListColumnProvider();
		}
		else if (listSpec && listSpec.columns) {
			options.columnInfos = listSpec.columns;
		}

		if (this._customChoiceFilterProvider) {
			let filter = this._customChoiceFilterProvider(this, this.createEventProcessingContext());
			if (typeof (filter) === 'string') {
				options.filterExpr = filter;
			}
		}
		else if (this._choiceSpecs[0].filterExpr) {
			options.filterExpr = this._choiceSpecs[0].filterExpr;
		}

		let evalResult: (res: ISelectPDObjectResult) => void =
			(res) => {
				if (res.selection) {
					this.selectedValue = {
						className: className,
						ergName: res.selection.getStatus(this.getRelationInfo(res.selection)),
						value: res.selection as PDObject,
						type: 'choiceObject'
					}
				}
			};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectResult> =
			(title) => {
				let selectedObj = this._selectedValue?.value;
				if (this._customChoiceProvider) {
					let res = this._customChoiceProvider(this, this.createEventProcessingContext());
					if (res && res.length == 1) {
						options.choiceObjects = <Observable<PDObject[]>>res[0].data;
						return this.interactionService.selectPDObject(className, title, selectedObj, options).pipe(
							tap(res => evalResult(res)) // todo: mit evalResult zusammenfassen
						);
					}
					return of(<ISelectPDObjectResult>{ selection: undefined })
				}
				else if (listSpec && listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this.pdAccessService, this.createEventProcessingContext());
					return this.interactionService.selectPDObject(className, title, selectedObj, options).pipe(
						tap(res => evalResult(res))  // todo: mit evalResult zusammenfassen
					);
				}
				else {
					return this.interactionService.selectPDObject(className, title, selectedObj, options).pipe(
						tap(res => evalResult(res))  // todo: mit evalResult zusammenfassen
					);
				}
			};

		let selectDialogHandler: (title: string) => Observable<ISelectPDObjectResult> =
		(title) => {
			this._selectionListVisible = true;
			return showSelectDialog(title).pipe(
				finalize(() => this._selectionListVisible = false)
			);
		};

		if (listSpec) {
			if (listSpec.title) {
				let title = this.localizationService.getFromErgNameData(listSpec.title);
				selectDialogHandler(title).subscribe();
				return;
			}
			else if (listSpec.titleId) {
				this.localizationService.getString(listSpec.titleId, { value: className }).pipe(
					switchMap(title => selectDialogHandler(title))
				).subscribe();
				return;
			}
		}

		//let pdMeta: PDMetaAbstract = ServiceLocator.injector.get(PDMetaAbstract);
		this.metaDataService.getClassErgName(className).pipe(
			switchMap(erg => {
				return this.localizationService.getSystemString('kendo-ui.components.pd-object-reference.selection-list-title', { value: erg }).pipe(
					switchMap(title => selectDialogHandler(title))
				);
			})
		).subscribe();
	}

	private createNewObject(): void {
		let wi = this.objectReferenceWidgetInfo;
		if (!wi || !wi.newAction || !wi.editingSpec?.urlCreate) {
			return;
		}
		let editingSpec = wi.editingSpec;
		let clsName = wi.editingSpec.className
		let url = [];
		url.push(...editingSpec.urlCreate);
		url.push(this.propertyName);
		url.push(clsName);
		url.push(this.pdObject.objectId.oid);
		this.popupManagerService.showPopup(editingSpec.outletName, url, this.pdObjectEditContext.baseRoute, 'Popup_' + this.propertyName);
	}

	/**
	 * Öffnet ein Overlay-Formular mit der angegebenen formSpec aus der widgetInfo (editingSpec)
	 */
		private editItem(custObj: CustomPDObject): void {
			let wi = this.objectReferenceWidgetInfo;
			if (!wi?.editingSpec?.urlEdit) {
				throw new Error('No valid editing spec.');
			}
		
			let url = [];
			url.push(...wi.editingSpec.urlEdit);
			url.push(this.id);
			url.push(custObj.oid);
			url.push(this.pdObject.objectId.oid);
			this.popupManagerService.showPopup(wi.editingSpec.outletName, url, this.pdObjectEditContext.baseRoute, 'Popup_' + this.propertyName);
		}

	private getRelationInfo(objOrClass: IPDObject | string): string {
		let classname = typeof(objOrClass) === 'string' ? objOrClass : this.determineChoiceDataClassName(objOrClass.getClassName());
		let spec = this._choiceSpecs.find(s => s.className === classname);
		if (spec) {
			return spec.relationInfoProvider ? spec.relationInfoProvider(this.localizationService) :
				(spec.relationInfo ? spec.relationInfo : 'n.a.');
		}
		return 'n.a.';
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		// todo: neuer UIState canChange!
		this.canChange = (relAccessRights && relAccessRights.change !== undefined) ? relAccessRights.change : undefined;
	}

	private updateToolbar(): void {
		if (this.objectReferenceType != ObjectReferenceTypeET.SelectionList || !this.toolBarItems) {
			return;
		}

		let tbItemSelect = this.toolBarItems.find(item => item.id == ToolBarButtonIdET.Select);
		if (tbItemSelect) {
			tbItemSelect.disabled = this.canChange === false;
		}
	}

	//
	// IComponent overrides
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.ObjectReference;
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._selectionChangedUIStateSubject$.subscribe(() => {
			callback(this); // Was ist "this"? Sollte ObjectRef sein an der Stelle
		});
		if (res) {
			res.add(sub);
			// return res;
		} else {
			res = sub;
		}
		res.add(this._choiceUpdatedSubjectUiState$.subscribe(() => callback(this)));
		return sub;
	}

	subscribeComponentEvents(callback: (source: IComponent, eventName: ObjectReferenceComponentEventName) => void): Subscription | undefined {
		let res = super.subscribeComponentEvents(callback);
		let sub = this._selectionChangedSubject$.subscribe(() => {
			callback(this, 'selectionChanged');
		});
		sub.add(this._selectionChangingSubject$.subscribe(() => {
			callback(this, 'selectionChanging');
		}));
		sub.add(this._choiceUpdatedSubject$.subscribe(() => {
			callback(this, 'choiceUpdated');
		}));
		if (res) {
			sub.add(res);
		}
		return sub;
	}

	//
	// IObjectReferenceComponent
	//

	get selection(): PDObject | undefined | null {
		return this._selectedValue ? this._selectedValue.value : undefined;
	}

	/**
	 * Die Selektion über die Komponente setzten. Unter der Haube wird der
	 * Wert direkt in das PDObject geschrieben. Entspricht also der Funktion
	 * @see onPDObjectChanged
	 */
	set selection(obj: PDObject | undefined | null) {
		// Wenn die schon gleich sind, gibt es nichts zu machen
		if (!obj && !this.selectedValue || obj?.oid === this.selectedValue?.value.oid) {
			return;
		}

		if (obj === undefined) {
			this.selectedValue = undefined;
			return;
		}
		else if (!obj || !obj.objectId) {
			//this.selectedValue = null;
			this.selectedValue = undefined; // null auf undefined geändert
			return;
		}

		this.pdObject.pdObjectRaw[this.propertyName] = obj;
		this.updateChoiceData();
	}

	get selectionAsText(): string | undefined {
		return this.selectedValue?.ergName ?? undefined;
	}

	private _selectionChangedSubject$: BehaviorSubject<PDObject | undefined | null> = new BehaviorSubject(undefined);

	private _selectionChanged$: Observable<PDObject | undefined | null>;

	/**
	 * undefined ist der Initialwert von selectedValue, es hat also noch keine Selektion stattgefunden
	 * null bedeutet, dass kein PDObject ausgewählt wurde
	 */
	get selectionChanged(): Observable<PDObject | undefined | null> {
		if (!this._selectionChanged$) {
			this._selectionChanged$ = this._selectionChangedSubject$.asObservable();
		}
		return this._selectionChanged$;
	}

	private _selectionChangedUIStateSubject$: BehaviorSubject<PDObject | undefined | null> = new BehaviorSubject(undefined);

	private _selectionChangingSubject$: Subject<SelectionChangingArgs> = new Subject();

	private _triggerLanguageUpdate = true;

	set triggerLanguageUpdate(flag: boolean) {
		this._triggerLanguageUpdate = flag;
	}

	get triggerLanguageUpdate(): boolean {
		return this._triggerLanguageUpdate;
	}

	private _selectionChanging$: Observable<SelectionChangingArgs>;

	get selectionChanging$(): Observable<SelectionChangingArgs> {
		if (!this._selectionChanging$) {
			this._selectionChanging$ = this._selectionChangingSubject$.asObservable();
		}
		return this._selectionChanging$;
	}

	set customChoiceProvider(cb: CustomChoiceProvider) {
		this.setCustomChoiceProviderImpl(cb, true);
	}

	private _customChoiceProvider: CustomChoiceProvider;

	private setCustomChoiceProviderImpl(cb: CustomChoiceProvider, updateChoiceSpecs: boolean): void {
		if (this._customChoiceProvider != cb) {
			this._customChoiceProvider = cb;
			this.choiceDataValid = false;
			if (updateChoiceSpecs) {
				this.updateChoiceSpecs().subscribe();
			}
		}
	}

	notifyCustomChoiceChanged(clearSelection = true): void {
		this.choiceDataValid = false;
		if (clearSelection) {
			this.selectedValue = undefined;
		}
		this.updateChoiceSpecs().subscribe();
	}

	set customChoiceFilterProvider(cb: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>) {
		this._customChoiceFilterProvider = cb;
		this.choiceDataValid = false;
	}

	private _customChoiceFilterProvider: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>;

	set selectionListColumnProvider(cb: () => IPDListColumnInfo[]) {
		this._selectionListColumnProvider = cb;
	}

	private _selectionListColumnProvider: () => IPDListColumnInfo[];

	get choiceObjects(): PDObject[] {
		if (this.objectReferenceType !== ObjectReferenceTypeET.DropDownList || this._choiceDataMap.size == 0) {
			return [];
		}
		return Array.from(this._choiceDataMap.values()).map(item => item.value);
	}

	selectItemFromIndex(index: number): boolean {
		if (index < 0 || index >= this.choiceObjects.length) {
			return false;
		}
		this.selection = this.choiceObjects[index];
		return true;
	}

	/*protected getCommandNamesImpl(): string[] {
		let names = super.getCommandNamesImpl();
		return [];
	}*/

	executeCommand(name: ObjectReferenceComponentCommand, params: CommandParameter): void {
		switch (name) {
			case 'updateChoice':
				this.updateChoice().subscribe();
				break;

			case 'clearSelection':
				this.selection = null;
				break;

			case 'selectItemFromIndex':
				if (typeof(params['index']) == 'number') {
					let index = params['index'];
					this.selectItemFromIndex(index);
				}
				break;

			default:
				super.executeCommand(name, params);
		}
	}
}
