import { ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { IPDObjectMeta } from './pd-object-meta';
import { ShortDescriptionFormatET } from './types';
import { TypeET } from '@otris/ng-core-types';

export class DefaultPDObjectMeta extends IPDObjectMeta {

	public static readonly instance: DefaultPDObjectMeta = new DefaultPDObjectMeta();

	private constructor() {
		super();
	}

	get propertyNames(): string[] {
		return [];
	}

	/*getPropertyMeta(prop: string): IPropertyMeta {
		return undefined;
	}*/

	getPropertyErgname(prop: string): Observable<string> {
		return of(prop);
	}

	getPropertyType(propName: string): TypeET {
		return TypeET.String;
	}

	getEnumPropertyEnumName(propName: string): string | undefined {
		return undefined;
	}

	getPropertyDeclarationClass(propName: string): string | undefined {
		return undefined;
	}

	isMandatory(prop: string): boolean {
		return false;
	}

	getMaxStringLength(prop: string): Observable<number | undefined> {
		return of(undefined);
	}

	getValidators(propName: string): ValidatorFn[] | undefined {
		return undefined;
	}

	hasShortDescription(prop: string): boolean {
		return false;
	}

	getShortDescription(prop: string): Observable<string> {
		return of('');
	}

	getShortDescriptionId(prop: string): string | undefined {
		return undefined;
	}

	getShortDescriptionFormat(prop: string): Observable<ShortDescriptionFormatET> {
		return of(ShortDescriptionFormatET.Text);
	}

	getStringFromId(id: string): Observable<string> {
		return of(id);
	}

	get className(): string | undefined {
		return undefined;
	}

	getRelationClassName(relation: string): string | undefined {
		return undefined;
	}
}
