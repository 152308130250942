import { Component, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import {
	PDComponent,
	FormHandlerService,
	CheckboxWidgetInfo,
	EventProcessingContextManagerService
} from '@otris/ng-core';

import { ComponentTypeET, ICheckBoxComponent, IComponent, VisibilityET } from '@otris/ng-core-types';
import { ValidatorFn, Validators } from '@angular/forms';

@Component({
	selector: 'otris-pd-checkbox',
	template: `
		<div class="main-container">
			<div class="checkbox-container">
				@if (!isReadonly) {
					<!-- "else" nach unten ausgelagert wegen Reihenfolge -->
					<input
						type="checkbox"
						kendoCheckBox
						[formControl]="formControl"
						[indeterminate]="isIndeterminate()"
						[id]="checkboxId"
						(change)="onChange($event)"
					/>
				}
				@if (isLabelVisible) {
					<label class="k-checkbox-label checkbox-label" [for]="checkboxId">{{label}}</label>
				}
				@if (!isDisabled && hasShortDescription) {
					<otris-pd-short-description [highlighted]="mouseIsOver" [pdObject]="pdObject" [pdComponent]="this">
					</otris-pd-short-description>
				}
				@if (!isDisabled && control && !control.valid) {
					<otris-pd-error-indicator [relatedFormControl]="control" [propertyName]="propertyName" [pdObject]="pdObject">
					</otris-pd-error-indicator>
				}
			</div>

			@if (isReadonly) {
				<div class="readonly"> <!-- Abstand zum label-->
					<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
				</div>
			}
		</div> <!--  main-container -->
	`,
	styles: [`
		.main-container {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.label-place-holder {
			margin-bottom: 0.5em;
			height: 1.2em;
		}

		.checkbox-container {
			display: flex;
			flex: 1 1 auto;
			align-items: flex-start;
		}

		.checkbox-label {
			/*margin: auto 0 auto 0.5em;*/
			margin-left: 0.5em;
			white-space: nowrap;
		}

		.readonly {
			margin-top: 0.5em;
		}
	`]
})
export class PDCheckboxComponent extends PDComponent implements ICheckBoxComponent {

	private static counter: number = 0;

	private _checkboxId: string;

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	@Input()
	requiredTrue: boolean;

	private _canIndeterminate = false;

	isLabelVisible = true;

	get checkboxWidgetInfo(): CheckboxWidgetInfo | undefined {
		return this.pdItemSpec.widgetInfo instanceof CheckboxWidgetInfo ?
			this.pdItemSpec.widgetInfo as CheckboxWidgetInfo : undefined;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
		this._checkboxId = "pdCheckbox_" + PDCheckboxComponent.counter.toString();
		PDCheckboxComponent.counter++;
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangedSubject$ = new BehaviorSubject(this.control?.value);
		if (this.control?.value) {
			this._valueChangedUIStateSubject$.next(this.control.value)
		}

		let wi = this.checkboxWidgetInfo;
		if (wi?.valueChangedHandler) {
			this.addSubscription(this.valueChanged$.subscribe(val => {
				wi.valueChangedHandler(this, val, this.createEventProcessingContext());
			}));
		}
		if (wi?.labelVisibility) {
			if (wi.labelVisibility === VisibilityET.Hidden || wi.labelVisibility === VisibilityET.Collapsed) {
				this.isLabelVisible = false;
			}
		}
		if (wi?.canIndeterminate) {
			this._canIndeterminate = wi.canIndeterminate;
		}
	}

	isIndeterminate() {
		if (!this._canIndeterminate) {
			return false;
		}

		if (typeof this.pdObject[this.propertyName] === 'boolean') {
			return false;
		}
		return true;
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		let wi = this.checkboxWidgetInfo;
		return (this.requiredTrue || (wi && wi.requiredTrue)) ? of([Validators.requiredTrue]) : of(undefined);
	}

	get checkboxId(): string {
		return this._checkboxId;
	}

	onChange(event: any) {
		if (this.pdObject && this.propertyName) {
			this.pdObject.pdObjectRaw[this.propertyName] = this.value; // Neu
		}
		this._valueChangedSubject$.next(this.value);
		this._valueChangedUIStateSubject$.next(this.value);
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._valueChangedUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Checkbox;
	}

	//private _currentValue: boolean;

	get value(): boolean {
		return this.control.value === true;
		//return this._currentValue;
	}

	set value(val: boolean) {
		if (val !== this.value) {
			//this._currentValue = val;
			if (this.control) {
				this.control.setValue(val);
			}
			// Notwendig, da onChange hier nicht aufgerufen wird
			if (this.pdObject && this.propertyName) {
				this.pdObject.pdObjectRaw[this.propertyName] = val;
			}
		}
	}

	private _valueChangedSubject$: BehaviorSubject<boolean>;// = new Subject();

	private _valueChanged$: Observable<boolean>;

	get valueChanged$(): Observable<boolean> {
		if (!this._valueChanged$) {
			this._valueChanged$ = this._valueChangedSubject$.asObservable();
		}
		return this._valueChanged$;
	}

	private _valueChangedUIStateSubject$ = new BehaviorSubject<boolean>(undefined);

	//----------------------------------------------------------------------------------------------
	// IBooleanComponent
	//----------------------------------------------------------------------------------------------

	/*get checked(): boolean {
		//return this.formGroup.get(this.propertyName).value;
		return this.control ? this.control.value : false;
	}*/
}
