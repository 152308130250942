import { ExpressionOptions, IEventProcessingContext, IFormHandlerService } from "@otris/ng-core-types";
import { ASTToken } from "../../services/grammar-parser-factory.service";
import { ComponentExprAction } from "./component-expression-actions";
import { CustomContextExprAction } from "./custom-context-expressions-actions";
import { EventProcessingContextAction } from "./event-processing-context-action";


export class AssertionExprAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): AssertionExprAction {
		if (astToken.type !== 'AssertionExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(options?: ExpressionOptions): boolean {
		let componentExpr = this._astToken.children.find(t => t.type === 'ComponentExpr'); // todo Konstante
		let customContextExpr = this._astToken.children.find(t => t.type === 'CustomContextExpr'); // todo Konstante
		let eventProcessingContextExpr = this._astToken.children.find(t => t.type === 'EventProcessingContextExpr'); // todo Konstante
		
		let value: any;
		if (componentExpr) {
			let compExprAction = ComponentExprAction.create(componentExpr, this._ctx);
			value = compExprAction.evaluate(options);
		}
		else if (customContextExpr) {
			let customContextExprAction = CustomContextExprAction.create(customContextExpr, this._ctx);
			value = customContextExprAction.evaluate();
		}
		else if (eventProcessingContextExpr) {
			let eventProcessingContextExprAction = EventProcessingContextAction.create(eventProcessingContextExpr, this._ctx);
			value = eventProcessingContextExprAction.evaluate();
		}
		else {
			throw new Error('todo');
		}

		let assertion = this._astToken.children.find(t => t.type === 'Assertion'); // todo Konstante
		if (!assertion) {
			throw new Error('todo');
		}
		let assertionAction = AssertionAction.create(assertion);
		let res = assertionAction.evaluate(value);

		let assertionNot = this._astToken.children.find(t => t.type === 'AssertionNotToken'); // todo Konstante
		return assertionNot ? !res : res;
	}
}


export class AssertionAction {

	static create(astToken: ASTToken): AssertionAction {
		if (astToken.type !== 'Assertion') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: any): boolean {
		if (this._astToken.children.length != 1) {
			throw new Error('todo');
		}
		
		switch (this._astToken.children[0].type) {
			case 'AssertionIsNullToken': { // todo: Konstante
				return value === null;
			}

			/*case 'AssertionIsNotNullToken': { // todo: Konstante
				return value !== null;
			}*/

			case 'AssertionIsUndefinedToken': { // todo: Konstante
				return value === undefined;
			}

			/*case 'AssertionIsNotUndefinedToken': { // todo: Konstante
				return value !== undefined;
			}*/

			case 'AssertionIsEmptyToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'string') {
					return value ? value.length === 0 : true;
				}
				else {
					console.warn(`IsEmpty assertion is only allowed for the type string.`)
					return false;
				}
			}

			/*case 'AssertionIsNotEmptyToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'string') {
					return value ? value.length > 0 : false;
				}
				else {
					console.warn(`IsNotEmpty assertion is only allowed for the type string.`)
					return false;
				}
			}*/

			case 'AssertionIsTrueToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'boolean') {
					return value === true;
				}
				else {
					console.warn(`IsTrue assertion is only allowed for the type boolean.`)
					return false;
				}
			}

			/*case 'AssertionIsNotTrueToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'boolean') {
					return value !== true;
				}
				else {
					console.warn(`IsNotTrue assertion is only allowed for the type boolean.`)
					return false;
				}
			}*/

			case 'AssertionIsFalseToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'boolean') {
					return value === false;
				}
				else {
					console.warn(`IsFalse assertion is only allowed for the type boolean.`)
					return false;
				}
			}

			/*case 'AssertionIsNotFalseToken': { // todo: Konstante
				if (value === null || value === undefined || typeof(value) === 'boolean') {
					return value !== false;
				}
				else {
					console.warn(`IsNotFalse assertion is only allowed for the type boolean.`)
					return false;
				}
			}*/

			case 'AssertionIsEqual': { // todo: Konstante
				let assertionIsEqualAction = AssertionIsEqualAction.create(this._astToken.children[0]);
				return assertionIsEqualAction.evaluate(value);
			}

			/*case 'AssertionIsNotEqual': { // todo: Konstante
				let assertionIsNotEqualAction = AssertionIsNotEqualAction.create(this._astToken.children[0]);
				return assertionIsNotEqualAction.evaluate(value);
			}*/

			case 'AssertionContains': { // todo: Konstante
				let assertionContainsAction = AssertionContainsAction.create(this._astToken.children[0]);
				return assertionContainsAction.evaluate(value);
			}

			default:
				throw new Error('todo');
		}
	}
}

export class AssertionIsEqualAction {

	static create(astToken: ASTToken): AssertionIsEqualAction {
		if (astToken.type !== 'AssertionIsEqual') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionIsEqualAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string | number | boolean): boolean {
		let comparativeElementaryValue = this._astToken.children.find(t => t.type === 'AssertionComparativeElementaryValue'); // todo Konstante
		if (comparativeElementaryValue) {
			let comparativeElementaryValueAction = AssertionComparativeElementaryValueAction.create(comparativeElementaryValue);
			return comparativeElementaryValueAction.evaluate() === value;
		}
		
		let stringEqualComparisonExpr = this._astToken.children.find(t => t.type === 'AssertionStringEqualComparisonExpr'); // todo Konstante
		if (stringEqualComparisonExpr && (value === undefined || typeof(value) === 'string')) {
			let stringEqualComparisonExprAction = AssertionStringEqualComparisonExprAction.create(stringEqualComparisonExpr);
			return stringEqualComparisonExprAction.evaluate(value as string);
		}
		throw new Error('todo');
	}
}

/*export class AssertionIsNotEqualAction {

	static create(astToken: ASTToken): AssertionIsNotEqualAction {
		if (astToken.type !== 'AssertionIsNotEqual') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionIsNotEqualAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string | number | boolean): boolean {
		let comparativeElementaryValue = this._astToken.children.find(t => t.type === 'AssertionComparativeElementaryValue'); // todo Konstante
		if (comparativeElementaryValue) {
			let comparativeElementaryValueAction = AssertionComparativeElementaryValueAction.create(comparativeElementaryValue);
			return comparativeElementaryValueAction.evaluate() !== value;
		}
		
		let stringUnequalComparisonExpr = this._astToken.children.find(t => t.type === 'AssertionStringUnequalComparisonExpr'); // todo Konstante
		if (stringUnequalComparisonExpr && (value === undefined || typeof(value) === 'string')) {
			let stringUnequalComparisonExprAction = AssertionStringUnequalComparisonExprAction.create(stringUnequalComparisonExpr);
			return stringUnequalComparisonExprAction.evaluate(value as string);
		}
		throw new Error('todo');
	}
}*/

export class AssertionComparativeElementaryValueAction {

	static create(astToken: ASTToken): AssertionComparativeElementaryValueAction {
		if (astToken.type !== 'AssertionComparativeElementaryValue') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionComparativeElementaryValueAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(): boolean | number {
		if (this._astToken.children.length === 1) {
			switch (this._astToken.children[0].type) {
				case 'Number':
					return Number.parseInt(this._astToken.children[0].text);

				case 'TrueToken':
					return true;

				case 'FalseToken':
					return false;

				default:
					throw new Error('todo');
			} 	
		}
		throw new Error('todo'); 
	}
}

export class AssertionStringComparativeValueAction {
	static create(astToken: ASTToken): AssertionStringComparativeValueAction {
		if (astToken.type !== 'AssertionStringComparativeValue') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionStringComparativeValueAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(): string {
		let stringToken = this._astToken.children.find(t => t.type === 'String'); // todo Konstante
		if (!stringToken) {
			throw new Error('todo'); 
		}
		return stringToken.text;
	}
}

export class AssertionStringEqualComparisonExprAction {
	static create(astToken: ASTToken): AssertionStringEqualComparisonExprAction {
		if (astToken.type !== 'AssertionStringEqualComparisonExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionStringEqualComparisonExprAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string | undefined): boolean {
		let stringComparativeValue = this._astToken.children.find(t => t.type === 'AssertionStringComparativeValue'); // todo Konstante
		if (!stringComparativeValue) {
			throw new Error('todo'); 
		}
		let stringComparativeValueAction = AssertionStringComparativeValueAction.create(stringComparativeValue);
		let comparativeValue = stringComparativeValueAction.evaluate();
		if (value === comparativeValue) {
			return true;
		}

		let assertionStringEqualComparisonExpr = this._astToken.children.find(t => t.type === 'AssertionStringEqualComparisonExpr'); // todo Konstante
		if (assertionStringEqualComparisonExpr) {
			let assertionStringEqualComparisonExprAction = AssertionStringEqualComparisonExprAction.create(assertionStringEqualComparisonExpr);
			return assertionStringEqualComparisonExprAction.evaluate(value);
		}
		return false;
	}
}


/*export class AssertionStringUnequalComparisonExprAction {
	static create(astToken: ASTToken): AssertionStringUnequalComparisonExprAction {
		if (astToken.type !== 'AssertionStringUnequalComparisonExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionStringUnequalComparisonExprAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string | undefined): boolean {
		let stringComparativeValue = this._astToken.children.find(t => t.type === 'AssertionStringComparativeValue'); // todo Konstante
		if (!stringComparativeValue) {
			throw new Error('todo'); 
		}
		let stringComparativeValueAction = AssertionStringComparativeValueAction.create(stringComparativeValue);
		let comparativeValue = stringComparativeValueAction.evaluate();

		let assertionStringUnequalComparisonExpr = this._astToken.children.find(t => t.type === 'AssertionStringUnequalComparisonExpr'); // todo Konstante
		if (assertionStringUnequalComparisonExpr) {
			let assertionStringUnequalComparisonExprAction = AssertionStringUnequalComparisonExprAction.create(assertionStringUnequalComparisonExpr);
			let res =  assertionStringUnequalComparisonExprAction.evaluate(value);
			return res && value !== comparativeValue;
		}
		
		return value !== comparativeValue;
	}
}*/


export class AssertionContainsAction {

	static create(astToken: ASTToken): AssertionContainsAction {
		if (astToken.type !== 'AssertionContains') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionContainsAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string): boolean {
		//let isNotContains = !this._astToken.children.find(t => t.type === 'AssertionContainsToken');
		if (!value) {
			//return isNotContains ? true : false;
			return false;
		}
		let assertionContainsExpr = this._astToken.children.find(t => t.type === 'AssertionContainsExpr'); // todo Konstante
		if (!assertionContainsExpr) {
			throw new Error('todo');
		}
		let assertionContainsExprAction = AssertionContainsExprAction.create(assertionContainsExpr);
		return assertionContainsExprAction.evaluate(value);
		//return isNotContains ? !res : res;
	}
}

export class AssertionContainsExprAction {

	static create(astToken: ASTToken): AssertionContainsExprAction {
		if (astToken.type !== 'AssertionContainsExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionContainsExprAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string): boolean {
		let result: (string | boolean)[] = [];
		this._astToken.children.forEach(token => {
			switch (token.type) {
				case 'AssertionStringComparativeValue':
					{
						let action = AssertionStringComparativeValueAction.create(token);
						let comparativeValue = action.evaluate();
						result.push(value.includes(comparativeValue));
						break;
					}

				case 'AssertionContainsBracketExpr':
					{
						let action = AssertionContainsBracketExprAction.create(token);
						result.push(action.evaluate(value));
						break;
					}

				case 'LogicalOperator':
					result.push(token.text);
					break;
			}
		});

		let pos = -1
		do {
			pos = result.indexOf('&&');
			if (pos !== -1) {
				let val = <boolean>(result[pos -1] && result[pos + 1]);
				result.splice(pos - 1, 3, val);
			}
		} while (pos !== -1);

		do {
			pos = result.indexOf('||');
			if (pos !== -1) {
				let val = <boolean>(result[pos -1] || result[pos + 1]);
				result.splice(pos - 1, 3, val);
			}
		} while (pos !== -1);

		return <boolean>result[0];

	}
}

export class AssertionContainsBracketExprAction {

	static create(astToken: ASTToken): AssertionContainsBracketExprAction {
		if (astToken.type !== 'AssertionContainsBracketExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new AssertionContainsBracketExprAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {
	}

	evaluate(value: string): boolean {		
		let assertionContainsExpr = this._astToken.children.find(t => t.type === 'AssertionContainsExpr'); // todo Konstante
		if (!assertionContainsExpr) {
			throw new Error('todo');
		}
		let assertionContainsExprAction = AssertionContainsExprAction.create(assertionContainsExpr);
		return assertionContainsExprAction.evaluate(value);
	}
}
