import {
	AbstractEnumeration,
	IEnumerationItem,
	LanguageCodeET,
	ILocalizationService,
	EnumerationItemData,
	EnumerationData
} from '@otris/ng-core-types';
import { EMPTY, Observable, of } from 'rxjs';
// import { ServiceLocator } from "@otris/ng-core-shared";
// import { LocalizationServiceToken } from "../../../ng-core/src/services/localization.service";

export type PDEnumerationSelectionType = 'single' | 'multiple';

export class PDEnumeration extends AbstractEnumeration {

	private _value: IEnumerationItem | IEnumerationItem[];
	private _choice: IEnumerationItem[] = [];

	constructor(
		private _enumData: EnumerationData,
		langIndex: number
	) {
		super();
		if (_enumData.isMulti) {
			this._value = [];
		}
		// Man kann PDEnumration nicht sofort hoch ziehen, nach ng-core.
		// jaf-web braucht PDEnumeration und ist von der Abhängigkeit (noch) vor ng-core
		// const localizationService = ServiceLocator.injector.get(LocalizationServiceToken);
		// const langIndex = localizationService.currentLanguageIndex;
		_enumData.items.forEach(enumItem => {
			this._choice.push({
				enumConst: enumItem.enumConst,
				ergName: langIndex === 0 ? enumItem.ergName.de : enumItem.ergName.en,
				active: enumItem.active
			})
		});
	}

	get rawValueType(): 'string' | 'number' {
		return this._choice.length === 0 ? 'number' :
			(typeof(this._choice[0].enumConst) === 'string' ? 'string' : 'number');
	}

	get name(): string {
		return this._enumData.name;
	}

	get choice(): IEnumerationItem[] {
		return this._choice;
	}

	get valueRaw(): number | string | (number | string)[] {
		if (!this._value) {
			return -1;
		}
		if (this._enumData.isMulti) {
			return (<IEnumerationItem[]>this._value).map(v => v.enumConst);
		} else {
			return (<IEnumerationItem>this._value).enumConst;
		}
	}

	get value(): IEnumerationItem | undefined | IEnumerationItem[] {
		return this._value;
	}

	set value(val: IEnumerationItem | undefined | IEnumerationItem[]) {
		if (this._enumData.isMulti) {
			if (!val) {
					this._value = undefined;
			} else {
				let valArray: IEnumerationItem[];
				if (!Array.isArray(val)) {
					valArray = [val];
				}
				else {
					valArray = val;
				}
				this._value = [];
				valArray.forEach(va => {
					let valChoice = this._choice.find(v => v.enumConst === va.enumConst);
					if (!valChoice) {
						throw new Error('EnumConst not found in choice');
					}
					(<IEnumerationItem[]>this._value).push(valChoice);
				});
			}
		} else {
			if (Array.isArray(val)) {
				throw new Error('Invalid value type array.');
			}
			if (!val) {
				this._value = undefined;
			}
			else {
				let valChoice = this._choice.find(v => v.enumConst === val.enumConst);
				if (!valChoice) {
					throw new Error('EnumConst not found in choice');
				}
				this._value = valChoice;
			}
		}
	}

	getItem(val: number | string): IEnumerationItem | undefined {
		return this._choice.find(i => i.enumConst === val);
	}

	override updateItemErgNames(langIndex: number): Observable<void> {
		// TODO: Asynchron unnötig hier aber wird von Modul-Enums benötigt
		// const localizationService = ServiceLocator.injector.get(LocalizationServiceToken);
		// const langIndex = localizationService.currentLanguageIndex;
		this._choice.forEach((choice, index) => {
			choice.ergName = langIndex === 0 ? this._enumData.items[index].ergName.de : this._enumData.items[index].ergName.en;
		});
		return of(undefined);
	}

	override getValueErgName(langIndex: number): string | undefined {
		if (!this._value) {
			return undefined;
		}
		if (this._enumData.isMulti) {
			return undefined
		}
		const enumItem = this._enumData.items.find(item => item.enumConst === (this._value as IEnumerationItem).enumConst);
		return langIndex === 0 ? enumItem?.ergName.de : enumItem?.ergName.en;
	}

	override clone(): AbstractEnumeration {
		const newItems: EnumerationItemData[] = this._enumData.items.map(item => ({
			ergName: { de: item.ergName.de, en: item.ergName.en },
			active: item.active,
			enumConst: item.enumConst
		}));
		const newEnumObject = new PDEnumeration(
			<EnumerationData>{
				name: this._enumData.name,
				items: newItems,
				isMulti: this._enumData.isMulti
			},
			0 // TODO: landindex
		);
		newEnumObject.value = this.value;
		return newEnumObject;

	}
}

