import { Component, OnInit, Input, Directive, TemplateRef, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { Observable, of, Subscription } from 'rxjs';
//import { PDObject, PropertyAccessRightET, IMetaDataService, ServiceLocator, RelationAccessRightET } from '@otris/ng-core-shared';
//import { IPDComponent, IFormStatus, IComponent, IComponentUIState } from '@otris/ng-core-types';
//import { UIAbstractComponent } from '../ui-abstract/ui-abstract.component';
import { PDItemSpec } from '../../model/pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';
import { EventProcessingContextManagerService } from '../../services/event-processing-context-manager.service';
import { PDLikeComponent } from '../pd-like/pd-like.component';

@Directive()
export abstract class PDComponent extends PDLikeComponent {

	// Todo: ist das nicht das gleiche wie control?
	get formControl(): UntypedFormControl {
		return this.relationObjectFormGroup ? this.relationObjectFormGroup.controls[this.propertyName] as UntypedFormControl :
			this.formGroup.controls[this.propertyName] as UntypedFormControl;
		//return this.formGroup.controls[this.propertyName] as UntypedFormControl;
	}

	// Todo: siehe formControl
	get control(): AbstractControl | undefined {
		if (!this.propertyName) {
			return undefined;
		}
		return this.relationObjectFormGroup ? this.relationObjectFormGroup.controls[this.propertyName] :
			(this.formGroup ? this.formGroup.controls[this.propertyName] : undefined); 
		//return (this.propertyName && this.formGroup) ? this.formGroup.controls[this.propertyName] : undefined;
	}
	

	get propertyName(): string | undefined {
		return this.pdItemSpec?.property;
	}

	protected get propertyRelationPath(): string | undefined {
		return this.pdItemSpec?.to1RelationItemSpec?.relationProperty;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		if (!(this.uiItemSpec instanceof PDItemSpec)) {
			throw Error(`uiItemSpec is not instanceof PDItemSpec.`);
		}

		super.ngOnInit();
	}

	protected get pdItemSpec(): PDItemSpec {
		return <PDItemSpec>this.uiItemSpec;
	}

	protected createControl(): AbstractControl | undefined {
		if (this.control || !this.propertyName) {
			return undefined;
		}
		return new UntypedFormControl();
	}

	public getId(): string | undefined {
		if (this.pdItemSpec.id) {
			return this.pdItemSpec.id;
		}
		if (this.pdItemSpec.property) {
			let prop = this.pdItemSpec.property;
			if (this.propertyRelationPath) {
				prop = `${this.propertyRelationPath}.${prop}`;
			}
			return this.getIdFromPropertyName(prop);
		}
		return undefined;
	}

	hasId(): boolean {
		return this.pdItemSpec && (!!this.pdItemSpec.id || !!this.pdItemSpec.property || !!this.pdItemSpec.to1RelationItemSpec);
	}
}
