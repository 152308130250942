import { Injectable } from '@angular/core';

import { AbstractEnumeration, ICustomPDObject } from '@otris/ng-core-types';
import { CustomPDObject, IPDObjectMeta } from '@otris/ng-core-shared';
import { PDClassBase } from '@otris/ng-core';


@Injectable()
export class PDClass extends PDClassBase {

	constructor(
		// private _metaObjectService: IMetaObjectService,
		// private _extraInformationManagerService: IExtraInformationManagerService,
		// private _customPropertyManagerService: ICustomPropertyManagerService
	) {
			super();
		}

	createInstance(
		className: string,
		relationPath?: string[],
		oid?: string,
		isNew?: boolean,
		metaData?: IPDObjectMeta
	): CustomPDObject {
		let newObj: CustomPDObject;
		switch (className) {
			// case 'Verfahren':
			// case 'VerfahrenGemeldet':
			// case 'BeitrittsfaehigesVerfahren': // TODO: #48527
			// 	newObj = new VerfahrenGemeldet(oid, isNew);
			// 	break;
		}
		if (newObj) {
			this.initCustomPDObject(newObj);
			return newObj;
		}
		throw new Error(`Unknown className '${className}'`);
	}

	override createEnumInstance(obj: CustomPDObject, propName: string, value: number | string): AbstractEnumeration | undefined {
		let errorMsgBase = 'Error in PDClass.createEnumInstance(). Details:';
		let res = super.createEnumInstance(obj, propName, value);
		if (res) {
			return res;
		}
		if (value !== undefined && typeof(value) !== 'number') {
			throw new Error(`${errorMsgBase} Invalid type '${typeof(value)}' for parameter 'value'.`);
		}
		switch (obj.classname) {
			// case 'Verfahren':
			// case 'VerfahrenGemeldet':
			// case 'BeitrittsfaehigesVerfahren': { // TODO: #48527
			// 	switch (propName) {
			// 		case VerfahrenGemeldetMetaConstants.Property_Zeitraum:
			// 			return new ZeitraumET(value as number);
			// 	}
			// 	break;
			// }
		}
		throw new Error(`${errorMsgBase} Unknown enumClassName or propName '${obj.classname}', '${propName}'`);
	}

	// Todo: auslagern nach privacy-ng-lib
	initCustomPDObject(obj: ICustomPDObject): void {
		// if (obj instanceof VerfahrenGemeldet) {
		// 	this._extraInformationManagerService.createDefaultObjects(VerfahrenGemeldetMetaConstants.ClassName, obj);
		// 	this._customPropertyManagerService.createDefaultObject(VerfahrenGemeldetMetaConstants.ClassName, obj);
		// }
		// else if (obj instanceof DatenkategorieContainer) {
		// 	this._extraInformationManagerService.createDefaultObjects(VerfahrenGemeldetMetaConstants.ClassName, obj,
		// 		[VerfahrenGemeldetMetaConstants.Property_to_DatenkategorieContainer]);
		// 	this._customPropertyManagerService.createDefaultObject(DatenkategorieContainerMetaConstants.ClassName, obj);
		// }
	}
}
