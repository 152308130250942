import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';

export const IPopupManagerServiceToken = new InjectionToken<IPopupManagerService>(
	'IPopupManagerServiceToken',
	{
		providedIn: 'root',
		factory: () => new PopupManagerService(inject(Router))
	}
);

export const PopupIdQueryParam = 'PopupIdQueryParam';

@Injectable()
export abstract class IPopupManagerService {
	abstract showPopup(outletName: string, url: any[], relativeTo: ActivatedRoute, popupId: string): Promise<boolean>;
	abstract closePopup(popupId: string): Promise<boolean>;

	abstract get popupClosed$(): Observable<void>;
	abstract get popupDisplayed$(): Observable<void>;
	abstract get popupsVisible(): boolean; 
}

interface IPopupInfo {
	popupId: string;
	outletName: string;
	relativeTo: ActivatedRoute;
}

@Injectable()
class PopupManagerService implements IPopupManagerService {

	private _popups: Map<string, IPopupInfo> = new Map <string, IPopupInfo>();

	constructor(private router: Router) {}

	showPopup(outletName: string, url: any[], relativeTo: ActivatedRoute, popupId: string): Promise<boolean> {
		if (this._popups.has(popupId)) {
			throw Error(`Popup with id ${popupId} is currently visible.`);
		}

		this._popups.set(popupId, <IPopupInfo>{ popupId: popupId, outletName: outletName, relativeTo: relativeTo });

		let outlets = new Object();
		outlets[outletName] = url;
		//this.router.navigate(['/main', { outlets: { 'overlay': ['createVorgang'] } }]); // geht
		//this.router.navigate([{ outlets: { 'overlay': [this.newActionSpec.url] } }], { relativeTo: this.route.parent }); // geht
		let queryParams: Params = {};
		queryParams[PopupIdQueryParam] = popupId;
		this._popupDisplayedSubject$.next();
		return this.router.navigate([{ outlets: outlets }],
			{ relativeTo: relativeTo, queryParams: queryParams, queryParamsHandling: 'merge' });
	}

	closePopup(popupId: string): Promise<boolean> {
		if (!this._popups.has(popupId)) {
			throw Error(`Popup with id ${popupId} not visible.`);
		}
		let popupInfo = this._popups.get(popupId);
		this._popups.delete(popupId);
		let outlets = new Object();
		outlets[popupInfo.outletName] = null;

		let queryParams: Params = {};
		queryParams[PopupIdQueryParam] = null;
		this._popupClosedSubject$.next();
		return this.router.navigate([{ outlets: outlets }],
			{ relativeTo: popupInfo.relativeTo, queryParams: queryParams, queryParamsHandling: 'merge' });
	}

	private _popupClosedSubject$ = new Subject<void>();

	private _popupCloseed$: Observable<void>;

	get popupClosed$(): Observable<void> {
		if (!this._popupCloseed$) {
			this._popupCloseed$ = this._popupClosedSubject$.asObservable();
		}
		return this._popupCloseed$;
	}

	private _popupDisplayedSubject$ = new Subject<void>();

	private _popupDisplayed$: Observable<void>;

	get popupDisplayed$(): Observable<void> {
		if (!this._popupDisplayed$) {
			this._popupDisplayed$ = this._popupDisplayedSubject$.asObservable();
		}
		return this._popupDisplayed$;
	}
	
	get popupsVisible(): boolean {
		return this._popups.size > 0;
	}
}
