<kendo-grid #kendoGrid
	[data]="dataResult"
	[style.height]="'100%'"
	scrollable="scrollable"
	[resizable]="true"
	[reorderable]="true"
	[autoSize]="autoSize"
	[selectable]="selectionSettings"
	[loading]="loading"
	(selectionChange)="onSelectionChange($event)"
	[pageable]="pagerSettings"
	[pageSize]="pageSize"
	[skip]="skip"
	(pageChange)="onPageChange($event)"
	[sortable]="sortSettings"
	[sort]="sortDescriptors"
	(sortChange)="onSortChange($event)"
	[(selectedKeys)]="_selection"
	[kendoGridSelectBy]="selectionKeySelector"
>
	<!-- 
		[pageable]="pagerSettings"
		(selectionChange)="onSelectionChange($event)"-->

	<ng-template kendoGridToolbarTemplate [position]="'top'">
		<otris-tool-bar style="width: 100%;"
			(buttonClick)="onToolBarButtonClick($event)"
			[showSearchControl]="showSearchControl"
			[itemSpecs]="toolbarItems"
			(searchExpressionChanged)="onSearchExpressionChanged($event)"
		>
		</otris-tool-bar>
	</ng-template>

	@if (selectionMode == selectionModeET.Multiple) {
		<kendo-grid-checkbox-column [width]="50" [resizable]="false">
			<ng-template kendoGridCellTemplate let-idx="rowIndex">
				<input [kendoGridSelectionCheckbox]="idx" />
			</ng-template>
		</kendo-grid-checkbox-column>
	}

	@for (col of columnInfos; track col) {
		<kendo-grid-column
			[minResizableWidth]="50"
			[field]="col.field"
			[title]="getColHeader(col)"
			[width]="col.width"
			[filter]="col.type | typeET_to_kendoGridFilter"
			[resizable]="col.resizable === false ? false : true"
			[sticky]="col.sticky === true"
			[hidden]="col.hidden === true"
		>
			<ng-template kendoGridCellTemplate let-obj>
				@if (!col.cellTemplate) {
					<!--
					<ng-container [ngSwitch]="col.type">
						<ng-container *ngSwitchCase="typeET.Boolean">
							<div *ngIf="obj.getPropertyValueFromPath(col.field); else elseBoolean">
								<i class="fa fa-lg fa-check" style="color: green;"></i>
							</div>
							<ng-template #elseBoolean>
								<i class="fa fa-lg fa-times" style="color: red;"></i>
							</ng-template>
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Date">
							{{obj.getPropertyValueFromPath(col.field) | date:'dd. MMM yyyy'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Time">
							{{obj.getPropertyValueFromPath(col.field) | date:'HH:mm:ss'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.DateTime">
							{{obj.getPropertyValueFromPath(col.field) | date:'dd. MMM yyyy HH:mm:ss'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Integer">
							{{obj.getPropertyValueFromPath(col.field) | number:'1.0-0'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Double">
							{{obj.getPropertyValueFromPath(col.field) | number:'1.2-2'}}
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div>{{obj.getPropertyValueFromPath(col.field)}}</div>
						</ng-container>
					</ng-container>-->
					@switch (col.type) {
						@case (typeET.Boolean) {		
							@if (obj[col.field]) {
								<div>
									<i class="fa fa-lg fa-check"></i>
								</div>
							} @else {
								<i class="fa fa-lg fa-times"></i>
							}
							<ng-template #elseBoolean>
								<i class="fa fa-lg fa-times"></i>
							</ng-template>
						}
						@case (typeET.Date) {
							{{obj[col.field] | date:'dd. MMM yyyy'}}
						}
						@case (typeET.Time) {
							{{obj[col.field] | date:'HH:mm:ss'}}
						}
						@case (typeET.DateTime) {
							{{obj[col.field] | date:'dd. MMM yyyy HH:mm:ss'}}
						}
						@case (typeET.Integer) {
							{{obj.getPropertyValueFromPath(col.field) | number:'1.0-0'}}
						}
						@case (typeET.Double) {
							{{obj.getPropertyValueFromPath(col.field) | number:'1.2-2'}}
						}
						@default {
							{{obj[col.field]}}
						}
					}
				} @else {
					<ng-container *ngTemplateOutlet="col.cellTemplate; context: { colInfo: col, object: data.get(obj.oid) }"></ng-container>
				}
			</ng-template>
		</kendo-grid-column>
	}

	<!--
	<kendo-grid-column>
	</kendo-grid-column>
	-->
	<kendo-grid-messages
		[pagerOf]="pagerOfMessage"
		[pagerItems]="pagerItemsMessage"
		[noRecords]="noRecordsMessage"
	/>
</kendo-grid>
