import {
	Component,
	Input,
	ViewChild,
	ElementRef,
	Inject,
	ChangeDetectorRef
} from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subscription, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { xIcon } from '@progress/kendo-svg-icons';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';

import { IInteractionServiceToken } from '@otris/ng-core-shared';
import {
	TextFieldWidgetInfo,
	PDLabeledControlComponent,
	FormHandlerService,
	EventProcessingContextManagerService,
	IChangeDetectionController,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET,
	ITextFieldComponent,
	MultilineTextFieldResizingType,
	IComponentUIState,
	IToolBarButtonSpec,
	ToolBarItemTypeET,
	IInteractionService,
	ISelectObjectOptions,
	ILocalizationService,
	PDTextFieldUIStateUpdateType,
	IComponent,
	TextFieldComponentEventName,
	TextFieldComponentCommand,
	CommandParameter,
	ItemTypeET
} from '@otris/ng-core-types';

import { ExpansionPanelService } from "../../services/expansion-panel.service";

enum ToolbarItemIds {
	SelectTextTemplate = 'SelectTextTemplate'
}

@Component({
	selector: 'otris-pd-text-field',
	template: `
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			(labelClick)="onLabelClick()"
			[relatedFormControl]="this.control"
			[pdObject]="pdObject"
			[uiItemSpec]="uiItemSpec"
		>
			@if (!isReadonly) {
				@if (multiline) {
					<!-- Das isHidden ist nötig, da beim "normalen" ausblenden mit display: none
					die Höhe nach dem wieder Einschalten nicht berechnet wird. Mit ngIf geschieht
					die Neuberechnung wieder korrekt-->
					@if (!isHidden && isExpansionPanelExpanded) {
						<kendo-textarea #textArea
							class="kendo-textarea-style"
							[resizable]="resizingType"
							[formControl]="formControl"
							[rows]="textAreaRows"
							[readonly]="isReadonly"
							[id]="getId() + '.kendoTextarea'"
							flow="horizontal"
							(keyup)="onKeyUp($event)"
							(keydown)="onKeyDown($event)"
							[spellcheck]="spellcheck"
						>
							@if (showClearButton) {
								<kendo-textarea-suffix>
									<button
										kendoButton
										type="button"
										class="suffix-clear-button"
										fillMode="clear"
										[svgIcon]="closeSVG"
										(click)="clearText()"
										[title]="clearTitle"
									></button>
								</kendo-textarea-suffix>
							}
						</kendo-textarea>
					}
				} @else {
					<div class="input-container" kendoTooltip filter="span">
						<kendo-textbox #input
							[clearButton]="true"
							[formControl]="formControl"
							[id]="getId() + '.input'"
							(keyup)="onKeyUp($event)"
							(keydown)="onKeyDown($event)"
							[spellcheck]="spellcheck"
						/>
						<!-- <input #input
							class="k-textbox input-ctrl"
							[formControl]="formControl"
							[readonly]="isReadonly"
							[id]="getId() + '.input'"
							(keyup)="onKeyUp($event)"
							(keydown)="onKeyDown($event)"
						/> -->
					</div>
				}
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
			<ng-template #readonly>
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			</ng-template>
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		:host {
			display: flex;
		}
		.errorImage {
			margin: auto 0 auto 3px;
			color: red;
		}
		.input-container {
			/*display: flex;*/ /* Wird nur bei NICHT multiline verwendet, wieso sollte es vergrößert werden? */
			flex: 1 1 auto;
		}
		.input-ctrl {
			flex: 1 1 auto;
		}

		/* Die textarea unterhalb von kendo wird ansonsten nicht richtig
			gestreckt: https://stackblitz.com/edit/angular-kew5j5-hud8rj?file=app/app.component.ts */
		.kendo-textarea-style {
			display: flex;
			flex: 1;
			cursor: text; /* Eigentlich ist das Fake - Da das tatsächliche Input-Feld wesentlich kleiner ist */
		}

		.kendo-textarea-style .k-input { /* Dies dürfte gar keinen Effekt haben, wegen ViewEncapsulation (?) */
			flex: 1;
		}

		.suffix-clear-button {
			background: none;
			border: none;
			color: grey;
		}

		.suffix-clear-button:hover {
			color: black;
		}

		.display-none {
			visibility: hidden;
		}
	`]
})
export class PDTextFieldComponent extends PDLabeledControlComponent implements ITextFieldComponent {
	@ViewChild('input') inputElement: ElementRef;
	@ViewChild('textArea') textAreaComponent: TextAreaComponent;

	@Input() showClearButton = true;

	clearTitle = 'Clear';

	multiline: boolean = false;

	textAreaRows: number = 1;

	closeSVG = xIcon;

	spellcheck = true;

	// Workaround for kendo-textarea auto-sizing bug #58499
	private _isExpansionPanelExpanded = false;

	set isExpansionPanelExpanded(flag: boolean) {
		this._isExpansionPanelExpanded = flag;
	}

	get isExpansionPanelExpanded(): boolean {
		return this._isExpansionPanelExpanded;
	}

	get textFieldWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof TextFieldWidgetInfo ?
			<TextFieldWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	resizingType: MultilineTextFieldResizingType = 'none';

	get isContainerComponent(): boolean {
		return false;
	}

	private _uiStateUpdateType: PDTextFieldUIStateUpdateType = 'emptyToNotEmpty';

	private _previousValue: string;

	protected override get defaultValue(): string | undefined {
		return typeof(this.pdItemSpec.defaultValue) === 'string' ? this.pdItemSpec.defaultValue : undefined;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Inject(IInteractionServiceToken) private _interactionService: IInteractionService,
		@Inject(LocalizationServiceToken) private _localizationService: ILocalizationService,
		private _changeDetectionController: IChangeDetectionController,
		private cdref: ChangeDetectorRef,
		private _expansionPanelService: ExpansionPanelService
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		let wi = this.textFieldWidgetInfo;
		if (wi) {
			if (wi.multiline) {
				this.multiline = true;
				if (wi.rows) {
					this.textAreaRows = wi.rows;
				}
				if (wi.resizingType) {
					this.resizingType = wi.resizingType;
				}
			}
			if (wi.valueChangesHandler) {
				this.addSubscription(this.valueChanges$.subscribe(res => {
					wi.valueChangesHandler(this, res, this.createEventProcessingContext());
				}));
			}
			if (wi.clearIfDisabled) {
				this.clearIfDisabled = true;
			}
			if (wi.textTemplateSelection) {
				let tbItemSelectTemplate: IToolBarButtonSpec = {
					type: ToolBarItemTypeET.Button,
					id: ToolbarItemIds.SelectTextTemplate,
					shortDescriptionId: 'system.kendo-ui.components.pd-text-field.button-text-template-selection.short-description',
					iconClass: 'fa fa-lg fa-list',
				}
				this.addToolbarItems('start', tbItemSelectTemplate);
			}
			if (wi.uiStateUpdateType) {
				this._uiStateUpdateType = wi.uiStateUpdateType;
			}
			if (wi.showClearButton === true || wi.showClearButton === false) {
				this.showClearButton = wi.showClearButton;
			}
			if (wi.spellcheck === true || wi.spellcheck === false) {
				this.spellcheck = wi.spellcheck;
			}
		}

		// this.toolbarButtonClick.subscribe(item => {
		// 	if (item.id === ToolbarItemIds.SelectTextTemplate) {
		// 		let options: ISelectObjectOptions = {
		// 			width: '70vw',
		// 			height: '90vh'
		// 		};
		// 		if (wi.textTemplateSelection.filterExpr) {
		// 			options.filterExpr = wi.textTemplateSelection.filterExpr;
		// 		}
		// 		if (wi.textTemplateSelection.selectionListSpec.columns)  {
		// 			options.columnInfos = wi.textTemplateSelection.selectionListSpec.columns;
		// 		}
		// 		let title = wi.textTemplateSelection.selectionListSpec.title ?
		// 			this._localizationService.getFromErgNameData(wi.textTemplateSelection.selectionListSpec.title) :
		// 			'Datensatz auswählen'; // todo!
		// 		this._interactionService.selectPDObjects(wi.textTemplateSelection.className, title, options).subscribe(
		// 			res => {
		// 				if (res.selection) {
		// 					let separator = wi.textTemplateSelection.separator ?? '\n\n'
		// 					this.text = res.selection.reduce(
		// 						(text, vorlage) => {
		// 							if (text) {
		// 								text += separator;
		// 							}
		// 							text += vorlage.pdObjectRaw[wi.textTemplateSelection.property];
		// 							return text;
		// 						},
		// 						this.text
		// 					);
		// 					// this.cdref.detectChanges();
		// 				}
		// 			}
		// 		);
		// 	}
		// })
		this.toolbarButtonClick.pipe(
			switchMap(item => {
				if (item.id === ToolbarItemIds.SelectTextTemplate) {
					let options: ISelectObjectOptions = {
						width: '70vw',
						height: '90vh'
					};
					if (wi.textTemplateSelection.filterExpr) {
						options.filterExpr = wi.textTemplateSelection.filterExpr;
					}
					if (wi.textTemplateSelection.filterExprProvider) {
						options.filterExpr = wi.textTemplateSelection.filterExprProvider(this.createEventProcessingContext());
					}
					if (wi.textTemplateSelection.selectionListSpec.columns)  {
						options.columnInfos = wi.textTemplateSelection.selectionListSpec.columns;
					}
					if (wi.textTemplateSelection.selectionListSpec.sortExpr)  {
						options.sortExpr = wi.textTemplateSelection.selectionListSpec.sortExpr;
					}
					let title = wi.textTemplateSelection.selectionListSpec.title ?
						this._localizationService.getFromErgNameData(wi.textTemplateSelection.selectionListSpec.title) :
						'Datensatz auswählen'; // todo!
					return this._interactionService.selectPDObjects(wi.textTemplateSelection.className, title, undefined, options).pipe(
						tap(res => {
							if (res.selection) {
								let separator = wi.textTemplateSelection.separator ?? '\n\n'
								this.text = res.selection.reduce(
									(text, vorlage) => {
										if (text) {
											text += separator;
										}
										text += vorlage.pdObjectRaw[wi.textTemplateSelection.property];
										return text;
									},
									this.text ? this.text : ''
								);
								this.control.markAsDirty();
								this.control.updateValueAndValidity();
							}
						})
					);
				}
				return of(undefined);
			})
		).subscribe();

		this._localizationService.changeHandler.pipe(
			switchMap(() => this._localizationService.getSystemStrings([
				'kendo-ui.components.pd-text-field.clear-title',
			])),
			tap(trans => {
				this.clearTitle = trans[0];
			})
		).subscribe();

		// Workaround for kendo-textarea auto-sizing bug #58499
		const expansionPanelId = this.pdItemSpec.getAncestorOfType(ItemTypeET.ExpansionPanel)?.id;
		if (expansionPanelId) {
			const sub = this._expansionPanelService.getExpansionPanelHandler$(expansionPanelId).pipe(
				tap(isExpanded => {
					setTimeout(() => this.isExpansionPanelExpanded = isExpanded, 50);
				})
			).subscribe();
			this.addSubscription(sub);
		}

		// Workaround for kendo-textarea auto-sizing bug #58499
		setTimeout(() => this.isExpansionPanelExpanded = true);
	}

	ngOnDestroy(): void {
		this._changeDetectionController.disableChangeDetection(false);
		super.ngOnDestroy();
	}

	onLabelClick() {
		if (this.inputElement) {
			this.inputElement.nativeElement?.focus();
		}
		else if (this.textAreaComponent) {
			this.textAreaComponent.focus();
		}
	}

	onKeyUp(args: any): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(false);
	}

	onKeyDown(args: KeyboardEvent): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(true);
	}

	subscribeComponentEvents(callback: (source: IComponent, eventName: TextFieldComponentEventName) => void): Subscription | undefined {
		let res = super.subscribeComponentEvents(callback);
		let sub = this._valueChangesSubject$.subscribe(() => {
			callback(this, 'valueChanges');
		});
		if (res) {
			sub.add(res);
		}
		return sub;
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._valueChangesUIStateSubject$.subscribe(() => {
			// TEST
			//console.debug(`Update UIState because of '${this.propertyName}'`);

			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		if (this.pdObject && this.propertyName) {
			this.pdObject.pdObjectRaw[this.propertyName] = val; // Neu
		}
		if (this._valueChangesSubject$ && !this.formHandler.uiStateUpdatesSuspended) { // TEST
			this._valueChangesSubject$.next(val);
			let updateUIState = true;
			switch (this._uiStateUpdateType) {
				case 'never':
					updateUIState = false;
					break;
				case 'emptyToNotEmpty':
					updateUIState = (!this._previousValue && val) || (this._previousValue && !val);
					break;
			}
			this._previousValue = val;
			if (updateUIState) {
				this._valueChangesUIStateSubject$.next(val);
			}
		}
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
			map(res => {
				if (Number.isInteger(res)) {
					return [Validators.maxLength(res)];
				}
				return undefined;
			})
		);
	}

	protected setControlValue(value: any): void {
		if (!value) {
			value = '';
		}
		super.setControlValue(value);
	}

	protected onUIStateChanged(oldState: IComponentUIState, newState: IComponentUIState): void {
		super.onUIStateChanged(oldState, newState);
		if (this.clearIfDisabled && oldState.disabled !== newState.disabled && newState.disabled) {
			this.text = ''
		}
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.TextField;
	}

	//
	// Interface ITextFieldComponent
	//

	private _clearIfDisabled = false;

	get clearIfDisabled(): boolean {
		return this._clearIfDisabled;
	}

	set clearIfDisabled(val: boolean) {
		this._clearIfDisabled = val;
	}

	get text(): string {
		return this.control ? this.control.value : '';
	}

	set text(val: string) {
		if (!this.control) {
			return;
		}
		this.control.setValue(val);
		this.control.markAsDirty();
	}

	clearText(): void {
		this.text = '';
	}

	private _valueChangesSubject$ = new BehaviorSubject<string>(undefined);

	private _valueChanges$: Observable<string>;

	get valueChanges$(): Observable<string> {
		if (!this._valueChanges$) {
			this._valueChanges$ = this._valueChangesSubject$.asObservable();
		}
		return this._valueChanges$;
	}

	private _valueChangesUIStateSubject$ = new BehaviorSubject<string>(undefined);

	executeCommand(name: TextFieldComponentCommand, params: CommandParameter): void {
		switch (name) {
			case ('clearText'):
				this.clearText();
				break;

			case ('setText'):
				if (params.length === 1 && typeof(params[0] === 'string')) {
					this.text = params[0]
				}
				else {
					console.warn(`Invalid parameter for command 'setText'`);
				}
				break;

			default:
				super.executeCommand(name, params);
		}
	}
}
