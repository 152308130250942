import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

export interface CanComponentDeactivate {
	canDeactivate: (currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) => Observable<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard 
{
	canDeactivate(
		component: CanComponentDeactivate,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean> | boolean {
		return component.canDeactivate ? component.canDeactivate(currentState, nextState) : true;
	}
}
