import { Component, OnInit, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { PDListComponent, ListTypeET } from '../pd-list/pd-list.component';
import {
	SelectionModeET,
	ISelectObjectOptions,
	ICssStyle,
	IPDListColumnInfo,
	ILocalizationService
} from '@otris/ng-core-types';
import { PDObject } from '@otris/ng-core-shared';
import { IPDColumnProviderService, IPDRootColumnProviderToken, LocalizationServiceToken } from '@otris/ng-core';
import { Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';


export interface ISelectionResult {
	selection: PDObject | PDObject[];
}

// [listType]="listTypeET.SelectionList"
/**
 * PDObjectSelection wird mittels einer pd-list realisiert. Diese besorgt
 * sich dann die PDObjects.
 */
@Component({
	selector: 'otris-pd-object-selection',
	template: `
		<div class="root-container" [ngStyle]="rootContainerStyle">
			<div>
				@if (customContent) {
					<ng-container *ngTemplateOutlet="customContent">
					</ng-container>
				}
			</div>
			<otris-pd-list
				#pdList
				[className]="className"
				[columnInfos]="columnInfos"
				[selectionMode]="selectionMode"
				[staticFilterExpr]="options?.filterExpr"
				[sortExpr]="options?.sortExpr"
				[listType]="listTypeET.SelectionList"
				[objects$]="choiceObjects"
				[preSelectedObjects]="preSelectedObjects"
				[preSelectedObject]="preSelectedObject"
			/>
			<kendo-dialog-actions>
				<button
					kendoButton
					class="otris-action-button-ok"
					[disabled]="!hasSelection"
					(click)="onOkClick()"
					[primary]="true"
				>
					{{ selectText }}
				</button>
				<button
					kendoButton
					class="otris-action-button-cancel"
					(click)="onCancelClick()"
				>
					{{ cancelText }}
				</button>
			</kendo-dialog-actions>
		</div>
	`,
	styles: [`
		.root-container {
			/* todo: im IE11 gängig machen, evtl spezielle CSS-KLasse für IE11 mittels ngClass, UserAgent setzten */
			display: grid;
			grid-template-rows: max-content minmax(0px, 1fr);
			grid-template-columns: minmax(0px, 1fr);
		}
	`]
})
export class PDObjectSelectionComponent extends DialogContentBase implements OnInit {
	@Input() className: string;

	@Input() columnInfos: IPDListColumnInfo[];

	@Input() choiceObjects: Observable<PDObject[]>;

	@Input() selectionMode: SelectionModeET = SelectionModeET.Single;

	@Input() preSelectedObjects: PDObject[];

	@Input() preSelectedObject: PDObject;

	@Input() options: ISelectObjectOptions;

	@ViewChild('pdList', { static: true }) pdList: PDListComponent;

	customContent: TemplateRef<any>;

	listTypeET = ListTypeET;

	/*private rootContainerStyle: ICssStyle = <ICssStyle>{
		padding: 0,
		'background-color': 'transparent',
		border: 0,
		flex: '3 3 100px'
	};*/

	get rootContainerStyle(): ICssStyle {
		let style = <ICssStyle>{
			'max-height': '80vh',
			'max-width': '90vw'
		}

		if (this.options) {
			if (this.options.width) {
				style['width'] = this.options.width;
			}
			if (this.options.height) {
				style['height'] = this.options.height;
			}
			if (this.options.minWidth) {
				style['min-width'] = this.options.minWidth;
			}
			if (this.options.maxWidth) {
				style['max-width'] = this.options.maxWidth;
			}
			if (this.options.minHeight) {
				style['min-height'] = this.options.minHeight;
			}
			if (this.options.maxHeight) {
				style['max-height'] = this.options.maxHeight;
			}
		}
		return style;
	}

	get selection(): PDObject {
		return this.pdList.selectedObject;
	}

	get hasSelection(): boolean {
		switch (this.selectionMode) {
			case SelectionModeET.Single:
				return !!this.pdList.selectedObject;
			case SelectionModeET.Multiple:
				return this.pdList.selectedObjects.length > 0;
		}
	}

	protected selectText = 'Select';
	protected cancelText = 'Cancel';

	constructor(
		@Inject(IPDRootColumnProviderToken) private columnProvider: IPDColumnProviderService,
		dialog: DialogRef,
		@Inject(LocalizationServiceToken) private _localizationService: ILocalizationService,
	) {
		super(dialog);
	}

	ngOnInit() {
		if (this.options) {
			if (this.options.columnInfos) {
				this.columnInfos = this.options.columnInfos;
			}
			if (this.options.choiceObjects) {
				this.choiceObjects = this.options.choiceObjects.pipe(map(res => res as PDObject[]));
			}
		}

		if (!this.columnInfos) {
			this.columnInfos = this.columnProvider.getColumns(this.className);
		}

		this._localizationService.changeHandler.pipe(
			switchMap(() => this._localizationService.getSystemStrings([
				'kendo-ui.components.pd-object-selection.button-select',
				'kendo-ui.components.pd-object-selection.button-cancel'
			])),
			tap(trans => {
				this.selectText = trans[0];
				this.cancelText = trans[1];
			})
		).subscribe();
	}

	onOkClick() {
		this.dialog.close(<ISelectionResult>{ selection: this.pdList.selection });
	}

	onCancelClick() {
		this.dialog.close();
	}
}
