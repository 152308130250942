import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, Inject, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

import {
	PrivacyNgLibModule,
	AppBaseModule,
	REQUIRED_PRIVACY_VERSION,
	PRIVACY_MODULE_TYPE,
	PrivacyModuleTypeET,
	IPrivacyNgModuleSettingsService,
	MetaObjectFactoryService,
	IPrivacyBackendServiceHookToken
} from '@otris/privacy-ng-lib';
import {
	IPDClassToken,
	IMetaObjectFactory
} from '@otris/ng-core-shared';
import { ILocalizationService } from '@otris/ng-core-types';
import { LocalizationServiceToken } from '@otris/ng-core';

import { AppRoutingModule } from './app-routing.module';
import { LandingPageSettingsService } from './services/landing-page-settings.service';
import { PrivacyBackendServiceHook } from './services/privacy-backend-service-hook.service';
import { MainComponent } from './components/main/main.component';
import { AppComponent } from './components/app/app.component';
import { PDClass } from "./model/pd-class";

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		MainComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		PrivacyNgLibModule.forRoot(),
		HttpClientModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ProgressBarModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de' },
		{ provide: PRIVACY_MODULE_TYPE, useValue: PrivacyModuleTypeET.Custom },
		{ provide: IPDClassToken, useClass: PDClass },
		// { provide: IPDClassToken, useClass: PDClassService },
		//{ provide: IFormSpecProvider, useClass: FormSpecProvider },
		{ provide: IPrivacyNgModuleSettingsService, useClass: LandingPageSettingsService },
		{ provide: IMetaObjectFactory, useClass: MetaObjectFactoryService },
		// { provide: IPrivacyBackendServiceHookToken, useClass: PrivacyBackendServiceHook }
		//MainUIService
	],
	bootstrap: [AppComponent]
})
export class AppModule extends AppBaseModule {
	constructor(injector: Injector,
		@Inject(LocalizationServiceToken) localizationService: ILocalizationService) {
		super(injector, localizationService);
	}
}
