import { InjectionToken, Injectable, Inject, inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import {
	TypeET,
	IPDAccessService,
	IPDClass,
	ErgName,
	LanguageCodeET,
	CustomPDObjectFragmentData
} from '@otris/ng-core-types'
import {
	CustomPDObject, IPDAccessServiceToken, IPDClassToken, IMetaObjectService, CustomPDObjectFragment
} from '@otris/ng-core-shared';
import { map, tap, switchMap, defaultIfEmpty } from 'rxjs/operators';

export const IPDObjectFactoryServiceToken = new InjectionToken<IPDObjectFactoryService>(
	'IPDObjectFactoryServiceToken',
	{
		providedIn: 'root',
		factory: () => new PDObjectFactoryService(inject(IPDAccessServiceToken), inject(IPDClassToken), inject(IMetaObjectService))
	}
);

type ImmutableRelationObject = {
	oid: string;
	propName: string;
	baseObject: CustomPDObject;
	//relationObject: PDObject;
}

type RemoteEnumSpec = {
	enumName: string;
	enumValue: string;
	propName: string;
	baseObject: CustomPDObject;
}

@Injectable()
export abstract class IPDObjectFactoryService {
	abstract createPDObject<T extends CustomPDObject>(jsonObj: string | any, relationPath?: string[]): Observable<T>;
	abstract createPDObjects<T extends CustomPDObject>(objs: any[]): Observable<T[]>;
	abstract createPDObjectFragment(rawObj: CustomPDObjectFragmentData, className: string): Observable<CustomPDObjectFragment>;
}

@Injectable()
class PDObjectFactoryService implements IPDObjectFactoryService {

	constructor(@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IPDClassToken) private pdClass: IPDClass,
		private _metaObjectService: IMetaObjectService) {
	}


	// Todo: getObjects() nur auf oberster Ebene 1 einmalig aufrufen!
	createPDObjectFragment(rawObj: CustomPDObjectFragmentData, className: string): Observable<CustomPDObjectFragment> {
		let relObjCalls$: Observable<void>[] = [];
		let relObjs: [string, string][] = [];
		let metaData = this._metaObjectService.getMetaObject(className)
		let objFragment = new CustomPDObjectFragment(metaData);
		for (const [key, value] of Object.entries(rawObj)) {
			if (metaData.propertyNames.includes(key)) {
				let propName = key.startsWith('_') ? key : '_' + key;
				switch (metaData.getPropertyType(key)) {
					case TypeET.String:
						if (typeof(value) === 'string') {
							objFragment.setProperty(propName, value);
						}
						break;

					case TypeET.Integer:
					case TypeET.Double:
						if (typeof(value) === 'number') {
							objFragment.setProperty(propName, value);
						}
						break;

					case TypeET.Boolean:
						if (typeof(value) === 'boolean') {
							objFragment.setProperty(propName, value);
						}

					case TypeET.Enum:
						if (typeof(value) === 'string' || typeof(value) === 'number') {
							objFragment.setProperty(propName, value);
						}
						break;

					case TypeET.RelationTo1:
						if (typeof(value) ===  'string') {
							relObjs.push([propName, value]);
						}
						else if (typeof(value) ===  'object') {
							// todo ...
							// Notwendig für to_CustomPropertyContainer!
							relObjCalls$.push(
								this.createPDObjectFragment(value as CustomPDObjectFragmentData, metaData.getRelationClassName(key)).pipe(
									map(res => {
										objFragment.setProperty(propName, res);
									})
								)
							);
						}
						break;
				}
			}
		}
		let getObjectsCall$ = relObjs.length > 0 ?
			this.pdAccessService.getObjects(relObjs.map(obj => obj[1])).pipe(
				map(objs => {
					objs.forEach((o, i) => {
						if (o) {
							let propName = relObjs[i][0].startsWith('_') ? relObjs[i][0] : '_' + relObjs[i][0];
							switch (metaData.getPropertyType(relObjs[i][0])) {
								case TypeET.RelationTo1:
									objFragment.setProperty(propName, o);
									break;
							}
						}
					});
					return objFragment;
				})
			) :
			of(undefined);

		return getObjectsCall$.pipe(
			switchMap(() => forkJoin(relObjCalls$).pipe(defaultIfEmpty(undefined))),
			map(() => objFragment)
		);

		/*if (relObjs.length > 0) {
			return this.pdAccessService.getObjects(relObjs.map(obj => obj[1])).pipe(
				map(objs => {
					objs.forEach((o, i) => {
						if (o) {
							let propName = relObjs[i][0].startsWith('_') ? relObjs[i][0] : '_' + relObjs[i][0];
							switch (metaData.getPropertyType(relObjs[i][0])) {
								case TypeET.RelationTo1:
									objFragment.setProperty(propName, o);
									break;
							}
						}
					});
					return objFragment;
				})
			);
		}*/
		return of(objFragment);
	}

	createPDObjects<T extends CustomPDObject>(objs: any[]): Observable<T[]> {
		let calls = objs.map(o => this.createPDObject(o));
		return forkJoin(calls).pipe(map(res => res as T[]));
	}

	createPDObject<T extends CustomPDObject>(jsonObj: string | any): Observable<T> {
		const relationObjects: ImmutableRelationObject[] = [];
		const relationEnumObjects: RemoteEnumSpec[] = [];
		let rawObj = typeof(jsonObj) === 'string' ? JSON.parse(jsonObj, this.jsonReviver) : jsonObj;
		let newObj: T = this.createPDObjectImpl(rawObj, relationObjects, relationEnumObjects);

		const fetchBatch: Observable<any>[] = [];

		if (relationObjects.length > 0) {
			const oids = [...new Set(relationObjects.map(o => o.oid))];
			fetchBatch.push(this.pdAccessService.getObjects(oids).pipe(
				map(objs => {
					relationObjects.forEach(relObj => {
						let obj = objs.find(o => o?.oid === relObj.oid);
						if (obj) {
							const baseObj = relObj.baseObject;
							const propName = relObj.propName;
							const propNameWU = relObj.propName.substring(1);
							switch (baseObj.metaData.getPropertyType(propNameWU)) {
								case TypeET.RelationTo1:
									baseObj[propName] = obj;
									break;
								case TypeET.RelationToN:
									{
										if (!baseObj[propName]) {
											baseObj[propName] = [];
										}
										baseObj[propName].push(obj);
									}
									break;
							}
						}
					});
					return newObj; // Mit forkjoin nicht nötig - siehe unten
				})
			));
		}

		if (relationEnumObjects.length > 0) {
			const uniqueEnumNames =  [...new Set(relationEnumObjects.map(e => e.enumName))];
			uniqueEnumNames.forEach(enumName => {
				fetchBatch.push(this.pdAccessService.getEnumeration(enumName, 0).pipe(
					tap(enumObj => {
						relationEnumObjects.forEach(relEnum => {
							if(relEnum.enumName === enumObj.name) {
								const baseObj = relEnum.baseObject;
								const propName = relEnum.propName.startsWith('_') ? relEnum.propName.substr(1) : relEnum.propName;
								enumObj.value = enumObj.getItem(relEnum.enumValue);
								baseObj[propName + 'Object'] = enumObj;
							}
						});
					})
				));
			});
		}

		if(fetchBatch.length > 0) {
			return forkJoin(fetchBatch).pipe(map(() => newObj));
		}

		return of(newObj);
	}

	private createPDObjectImpl<T extends CustomPDObject>(rawObj: object, relationObjects: ImmutableRelationObject[], relationEnumObjects: RemoteEnumSpec[], relationPath?: string[]): T {

		let className: string = rawObj['_className'];
		let isNew = rawObj['_isNew'] !== false;
		let oid = rawObj['_oid'] ? rawObj['_oid'] : (rawObj['_objectId'] ? rawObj['_objectId'] : undefined);
		let metaData = this._metaObjectService.getMetaObject(className, rawObj['_metaObjectId']);
		let newObj: T = <T>this.pdClass.createInstance(className, relationPath, oid, isNew, metaData); // klee: vorher _objectId

		for (let prop of metaData.propertyNames
			.filter(p => {
				let n = p.startsWith('_') ? p : '_' + p;
				return rawObj.hasOwnProperty(n);
			}
			)) {
			let propName = prop.startsWith('_') ? prop : '_' + prop;
			let val = rawObj[propName];
			switch (metaData.getPropertyType(prop)) {
				case TypeET.RelationTo1:
					if (val) {
						if (typeof(val) ===  'object' && val.hasOwnProperty('_className')) {
							let newRelPath = relationPath ? [...relationPath, propName] : [propName];
							newObj[propName] = this.createPDObjectImpl(val, relationObjects, relationEnumObjects, newRelPath);
						}
						else if (typeof(val) === 'string') { // todo: check auf gültiges OID-Format
							relationObjects.push({
								oid: val,
								propName: propName,
								baseObject: newObj
							});
						}
					}
					break;
				case TypeET.RelationToN:
					if (Array.isArray(val)) {
						newObj[propName] = [];
						val.forEach(v => {
							if (typeof(v) ===  'object' && v.hasOwnProperty('_className')) {
								let newRelPath = relationPath ? [...relationPath, propName] : [propName];
								newObj[propName].push(this.createPDObjectImpl(v, relationObjects, relationEnumObjects, newRelPath));
							}
							else if (typeof(v) === 'string') { // todo: check auf gültiges OID-Format
								relationObjects.push({
									oid: v,
									propName: propName,
									baseObject: newObj
								});
							}
						});
					}
					break;
				case TypeET.Date:
				case TypeET.Time:
				case TypeET.DateTime:
					newObj[propName] = val ? new Date(val) : undefined;
					break;
				case TypeET.Enum:
					//newObj[propName] = this.pdClass.createEnumInstance(className, prop, val);
					const adaptedPropName = propName.startsWith('_') ? propName.substr(1) : propName;
					const enumName = metaData.getEnumPropertyEnumName(adaptedPropName);
					if (!enumName) { // TODO: Durch einen EnumService wird diese Unterscheidung so nicht mehr nötig sein
						newObj[propName] = this.pdClass.createEnumInstance(newObj, prop, val);
					} else {
						relationEnumObjects.push({
							propName: propName,
							enumName: enumName,
							enumValue: val,
							baseObject: newObj,
						});
					}
					break;
				/*case TypeET.EnumItemArray:
					if (Array.isArray(val)) {
						newObj[propName] = val.map(s => <IEnumerationItem>{ enumConst: s, ergName: s });
					}
					else {
						newObj[propName] = [];
					}
					break;*/
				case TypeET.ErgName:
					if (!val) {
						break;
					}
					let ergName: ErgName = [];
					if (typeof val === 'object') {
						if (val.de) {
							ergName.push([LanguageCodeET.de, val.de]);
						}
						if (val.en) {
							ergName.push([LanguageCodeET.en, val.en]);
						}
					}
					newObj[propName] = ergName;
					break;
				default:
					newObj[propName] = val;
					break;
			}
		}

		// AuxiliaryProperties
		if (rawObj['_auxiliaryProperties']) {
			newObj.auxiliaryProperties = rawObj['_auxiliaryProperties'];
		}

		// Unused raw properties
		for (const [key, value] of Object.entries(rawObj)) {
			if (
				!['_className', '_oid', '_objectId', '_isNew', '_objectIndex', '_auxiliaryProperties', '_rawWeiterleitungOids', 'rawWeiterleitungOidsAuxProperty'].includes(key)
					&& !metaData.propertyNames.includes(key.charAt(0) === "_" ? key.substring(1) : key)
			) {
				newObj.addUnusedRawProperty(key, value);
			}
		}

		return newObj;
	}

	// todo: brauchen wir das?
	private jsonReviver(key: string, value: any): any {
		return value;
	}
}

