import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidatorFn, Validators} from '@angular/forms';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComponentTypeET, IComboBoxComponent, IComponent, IComponentUIState, IEventProcessingContext, IPDAccessService, IPDObject } from '@otris/ng-core-types';
import { PDObject, IPDAccessServiceToken } from '@otris/ng-core-shared';
import { ComboBoxWidgetInfo, PDLabeledControlComponent, FormHandlerService, EventProcessingContextManagerService } from '@otris/ng-core';

interface IChoiceData {
	ergName: string;
	valueAsString: string;
	value: PDObject;
}

// as choiceData
@Component({
	selector: 'otris-pd-combo-box',
	template: `
		@if (active) {
			<otris-pd-labeled-control-frame 
				[labeledControl]="this" 
				(toolBarButtonClick)="onToolBarButtonClick($event)"
				[relatedFormControl]="this.control" 
				[pdObject]="pdObject"
			>
				@if (!isReadonly) {
						<div class="input-container">
							<kendo-combobox
								class="combo-box"
								[data]="choiceData"
								[loading]="isLoading"
								[textField]="'ergName'"
								[valueField]="'valueAsString'"
								[valuePrimitive]="false"
								[allowCustom]="true"
								[value]="currentValue"
								(valueChange)="onValueChange($event)"
								(selectionChange)="onSelectionChange($event)"
								[valueNormalizer]="valueConverter"
								[id]="getId() + '.combobox'"
								[spellcheck]="spellcheck"
							/>
						</div>
				} @else {
					<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
				}
			</otris-pd-labeled-control-frame>
		} @else {
			<otris-pd-labeled-control-frame [labeledControl]="this">
				<kendo-dropdownlist
					class="drop-down-list"
					[disabled]="true"
					[id]="getId() + '.dropdownlist'"
				/>
			</otris-pd-labeled-control-frame>
		}

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		.combo-box {
			/*width: 100%;*/
			flex: 1;
		}
		.drop-down-list {
			/*width: 100%;*/
			/*margin-top: 0.5em;*/
			flex: 1;
		}
		.errorImage {
			margin: auto 0 auto 3px;
			color: red;
		}
		.input-container {
			/*margin-top: 0.5em;*/
			display: flex;
			flex: 1;
		}
	`]
})
export class PDComboBoxComponent extends PDLabeledControlComponent implements IComboBoxComponent {

	choiceData: IChoiceData[];

	isLoading = false;

	private _className: string;

	private _displayTemplate: string;

	private _valueTemplate: string;

	private get comboBoxWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof ComboBoxWidgetInfo ?
			<ComboBoxWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	private _currentValue: IChoiceData;

	get currentValue(): IChoiceData {
		return this._currentValue;
	}

	valueConverter = (text: Observable<string>) => text.pipe(
		map(txt => {
			return <IChoiceData>{ ergName: txt, valueAsString: txt }
		})
	);

	get isContainerComponent(): boolean {
		return false;
	}

	spellcheck = true;

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService, private cdRef: ChangeDetectorRef,
		eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		/*let val = this._currentValue ? this._currentValue.valueAsString : undefined;
		this._valueChangedSubject$ = new BehaviorSubject(val);
		this._valueChangedUIStateSubject$ = new BehaviorSubject(val);*/

		let wi = this.comboBoxWidgetInfo;
		if (wi) {
			this._displayTemplate = wi.displayTemplate;
			this._valueTemplate = wi.valueTemplate;
			this._className = wi.className;
			if (wi.active !== undefined) {
				this.activate(wi.active)
			}
			if (wi.customChoiceProvider) {
				this.customChoiceProvider = wi.customChoiceProvider;
			}
			if (wi.valueChangedHandler) {
				this.addSubscription(this.valueChanged.subscribe(res => {
					wi.valueChangedHandler(this, res, this.createEventProcessingContext());
				}));
			}
			if (wi.spellcheck === true || wi.spellcheck === false) {
				this.spellcheck = wi.spellcheck;
			}
		}

		this.updateChoice();
	}

	onValueChange(val: IChoiceData) {
		this.setCurrentValue(val);
	}

	onSelectionChange(sel: IChoiceData) {
		console.log(sel);
	}

	/**
	 * Merkt den currentValue und speichert den Wert (valueAsString) im zugehörigen FormControl
	 * @param val
	 */
	private setCurrentValue(val: IChoiceData): void {
		if (val !== this._currentValue) {
			this._currentValue = val;
			let ctrl = this.control;
			let stringVal = val ? val.valueAsString : undefined;
			if (ctrl) {
				ctrl.setValue(stringVal);
				ctrl.markAsDirty();
			}
			if (this._valueChangedSubject$) {
				this._valueChangedSubject$.next(stringVal);
			}
			if (this._valueChangedUIStateSubject$) {
				this._valueChangedUIStateSubject$.next(stringVal);
			}
		}
	}

	/**
	 * Sucht in choiceData einen Eintrag bei dem valuesAsString dem aktuellen value des FormControls entspricht und speichert diesen
	 * in _currentValue. Falls kein Eintrag gefunden wurde, wird ein neues IChoiceData erzeugt und in _currentValue gespeichert
	 */
	private updateCurrentValue(): void {
		let ctrl = this.control;
		if (!ctrl) {
			return;
		}

		let newVal: IChoiceData;
		if (ctrl.value && this.choiceData) {
			newVal = this.choiceData.find(c => c.valueAsString === ctrl.value);
			if (!newVal) {
				newVal = <IChoiceData>{ ergName: ctrl.value, valueAsString: ctrl.value }
			}
		}
		this._currentValue = newVal;
		this.cdRef.detectChanges();
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		if (this.pdObject && this.propertyName) {
			this.pdObject.pdObjectRaw[this.propertyName] = val;
		}
		this.updateCurrentValue();
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		// this.updateChoice();
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
			map(res => {
				if (Number.isInteger(res)) {
					return [Validators.maxLength(res)];
				}
				return undefined;
			})
		);
	}

	protected determineDefaultUIState(ctx: IEventProcessingContext, ...states: IComponentUIState[]): IComponentUIState {
		let state: IComponentUIState = {};
		if (!this.active) {
			state.disabled = true;
		}
		return state = super.determineDefaultUIState(ctx, state, ...states);
	}

	private get choiceFilterExpr(): string | undefined {
		if (this._customChoiceFilterProvider) {
			return this._customChoiceFilterProvider(this, this.createEventProcessingContext());
		}
		if (this.comboBoxWidgetInfo) {
			return this.comboBoxWidgetInfo.choiceFilterExpr;
		}
		return undefined;
	}

	private get choiceSortExpr(): string | undefined {
		if (this.comboBoxWidgetInfo) {
			return this.comboBoxWidgetInfo.choiceSortExpr;
		}
		return undefined;
	}

	updateChoice() {
		if (this.active) {
			this.isLoading = true;
			let data$: Observable<PDObject[]>;
			if (this._customChoiceProvider) {
				data$ = this._customChoiceProvider(this, this.createEventProcessingContext()).pipe(map(res => res as PDObject[]));
			}
			else if (this._className) {
				data$ = this.pdAccessService.getExtent(this._className, this.choiceFilterExpr, this.choiceSortExpr).pipe(map(res => res as PDObject[]));
			}
			if (data$) {
				data$.pipe(
					map(objs => {
						this.choiceData = [];
						if (objs) {
							for (let obj of objs) {
								let info = obj.getStatus(this._displayTemplate);
								let value = obj.getStatus(this._valueTemplate ? this._valueTemplate : this._displayTemplate);
								this.choiceData.push(<IChoiceData>{ ergName: info, valueAsString: value, value: obj });
							}
						}
						this.cdRef.detectChanges();
						this.updateCurrentValue();
					}),
					//delay(5000), // TEST
					tap(() => this.isLoading = false)
				).subscribe();
				return;
			}
		}
		this.choiceData = [];
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.ComboBox;
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._valueChangedUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	//
	// Interface IComboxBoxComponent
	//

	get value(): string | undefined {
		return this._currentValue ? this._currentValue.valueAsString : undefined;
	}

	set value(val: string | undefined) {
		if (!val || typeof(val) !== 'string') {
			this.setCurrentValue(undefined);
			return;
		}

		let newVal = this.choiceData ? this.choiceData.find(c => c.valueAsString === val) : undefined;
		this.setCurrentValue(newVal ? newVal : <IChoiceData>{ ergName: val, valueAsString: val });
	}

	private _valueChangedSubject$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);

	private _valueChanged$: Observable<string | undefined>;

	get valueChanged(): Observable<string | undefined> {
		if (!this._valueChanged$) {
			this._valueChanged$ = this._valueChangedSubject$.asObservable();
		}
		return this._valueChanged$;
	}

	private _valueChangedUIStateSubject$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);

	get active(): boolean {
		return this._active;
	}

	/*set active(val: boolean) {
		if (val !== this._active) {
			this._active = val;
			this.disable(!val);
			this.updateChoice();
		}
	}*/

	/**
	 * @param {boolean} val - Flag ob Component aktiv oder inaktiv gesetzt werden soll
	 * @param {boolean} [forceUpdateChoice=true] - Gibt an, ob bei bereits aktiver Komponente updateChoice() aufgerufen werden soll.
	 * @param {boolean} [forceEnable=true] - Gibt an, ob die Komponente automatisch auf enabled gesetzt werden soll, falls sie aktiv ist.
	 */
	activate(val: boolean, forceUpdateChoice = true): void {
		if (val !== this._active) {
			this._active = val;
			if (val) {
				this.updateChoice();
			}
			this.updateUIState();
		}
		else if (this._active && forceUpdateChoice) {
			this.updateChoice();
		}
	}

	private _active: boolean = true;

	set customChoiceProvider(cb: (source: IComboBoxComponent, ctx: IEventProcessingContext) => Observable<IPDObject[]>) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private _customChoiceProvider: (source: IComboBoxComponent, ctx: IEventProcessingContext) => Observable<IPDObject[]>;

	set customChoiceFilterProvider(cb: (source: IComboBoxComponent, ctx: IEventProcessingContext) => string | undefined) {
		this._customChoiceFilterProvider = cb;
		this.updateChoice();
	}

	private _customChoiceFilterProvider: (source: IComboBoxComponent, ctx: IEventProcessingContext) => string | undefined;
}
