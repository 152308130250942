import { Component, HostBinding, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDLabeledControlComponent, FormHandlerService, DateTimeFieldWidgetInfo, EventProcessingContextManagerService } from '@otris/ng-core';
import { ComponentTypeET, IComponent, IDateTimeFieldComponent } from '@otris/ng-core-types';
import { LocalizationServiceToken, LocalizationService } from '@otris/ng-core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'otris-pd-date-time-field',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
			@if (!isReadonly) {
					<kendo-datepicker
						class="datepicker"
						[format]="'dd.MM.yyyy'"
						[formControl]="formControl"
						[placeholder]="placeholder$ | async" [navigation]="showNavigationBar"
						[readonly]="isReadonly"
						[id]="getId() + '.datepicker'"
					>
						<!-- https://www.telerik.com/forums/datepicker-timepicker-grid---translations-missing -->
						<kendo-datepicker-messages [today]="todayMessage" [toggle]="toggleMessage"></kendo-datepicker-messages>
					</kendo-datepicker>
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		.datepicker {
			flex: 1;
		}
	`]
})
export class PDDateTimeFieldComponent extends PDLabeledControlComponent implements IDateTimeFieldComponent {

	@Input() placeholder: string;

	placeholder$: Observable<string>;

	todayMessage = "Today";
	toggleMessage = "Toggle Calender";

	get dateTimeFieldWidgetInfo(): DateTimeFieldWidgetInfo {
		let wi = this.pdItemSpec.widgetInfo;
		if (wi instanceof DateTimeFieldWidgetInfo) {
			return wi as DateTimeFieldWidgetInfo;
		}
		return undefined;
	}

	get showNavigationBar(): boolean {
		let wi = this.dateTimeFieldWidgetInfo;
		return wi ? wi.showNavigationBar !== false : true;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	@HostBinding('attr.ot-value') get getValueForHtml() {
		return this.value?.toString() ?? '';
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(LocalizationServiceToken) private localizationService: LocalizationService,
		eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();

		this._valueChangesSubject$ = new BehaviorSubject(this.control?.value);
		if (this.control?.value) {
			this._valueChangesUIStateSubject$.next(this.control.value)
		}

		this.localizationService.changeHandler.pipe(
			tap(() => this.updatePlaceholder()),
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-date-time-field.today-message',
				'kendo-ui.components.pd-date-time-field.toggle-message'
			])),
			tap(trans => {
				this.todayMessage = trans[0],
				this.toggleMessage = trans[1]
			})
		).subscribe();
		this.updatePlaceholder();
		let wi = this.dateTimeFieldWidgetInfo;
		if (wi) {
			if (wi.valueChangesHandler) {
				this.addSubscription(this.valueChanges$.subscribe(res => {
					wi.valueChangesHandler(this, res, this.createEventProcessingContext());
				}));
			}
		}
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._valueChangesUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	//protected onReadonlyChanged(): void {}

	protected onControlValueChanges(val: any) {
		if (this.pdObject && this.propertyName) {
			this.pdObject.pdObjectRaw[this.propertyName] = val;
		}
		super.onControlValueChanges(val);
		if (this._valueChangesSubject$) {
			this._valueChangesSubject$.next(val);
			this._valueChangesUIStateSubject$.next(val);
		}
	}

	//protected onReadonlyChanged(): void {}

	private updatePlaceholder(): void {
		this.placeholder$ = this.placeholder ? of(this.placeholder) :
			this.localizationService.getSystemString('kendo-ui.components.pd-date-time-field.placeholder');
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.DateTime;
	}

	//
	// Interface IDateTimeFieldComponent
	//

	get value(): Date | undefined {
		return this.control?.value;
	}

	set value(val: Date | undefined) {
		this.control.setValue(val);
	}

	private _valueChangesSubject$: BehaviorSubject<Date | undefined>;

	private _valueChanges$: Observable<Date | undefined>;

	get valueChanges$(): Observable<Date | undefined> {
		if (!this._valueChanges$) {
			this._valueChanges$ = this._valueChangesSubject$.asObservable();
		}
		return this._valueChanges$;
	}

	private _valueChangesUIStateSubject$ = new BehaviorSubject<Date | undefined>(undefined);
}
