import { ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { IToolBarItemResult, IToolbarSpec } from './i-toolbar-spec';
import { ICssStyle, VisibilityET, LabelPositionET, IPDListColumnInfo,
	IUIDrawerItem, IEnumerationItem, ErgNameData } from './types';
import { IPDAccessService } from '../services/i-pd-access-service';
import { ICustomPDObject, IPDObject } from './i-pd-object';
import {
	DrawerModeET, DrawerExpandModeT, DrawerPositionT, CreateRelationObjectCallback,
	ICustomChoiceProviderResult, IComponentUIState, PDTextFieldUIStateUpdateType, ComponentUIStateExpression, CustomChoiceProvider, ForeignComponentEventSpec, CustomEventHandler, ComponentEventSpec
} from './i-component-types';
import { IInteractionService } from '../services/i-interaction-service';
import { ILocalizationService } from '../services/i-localization-service';
import { IEventProcessingContext } from './i-event-processing-context';
import { IMainUIService } from '../services/i-main-ui-service';
import {
	ICheckBoxComponent, IComboBoxComponent, IComponent, IDateTimeFieldComponent, ILabeledControlComponent,
	IMultiSelectComponent, IObjectReferenceComponent, IPDRelationTabComponent, IRadioButtonComponent,
	IRadioButtonGroupComponent, ITextFieldComponent, INumericTextFieldComponent, IDropDownComponent,
	IToNRelationItemContainer, IRelationComponent, IListviewComponent, IRelationGridComponent, IDrawerItemComponent, IDrawerComponent, IExpansionPanelComponent, IPDComponent
} from './i-component';
import { IPDClass } from '../services/i-pd-class.service';
import { ComponentEventData, CustomChoiceProviderData, CustomEventHandlerData, EventHandlerCommandsData, ForeignComponentEventData, ForeignComponentEventsModification, IUIItemDataMutable, LayoutProviderData, PropertyValueProviderData, VisibleContainerTypeProviderData } from './i-pd-layout-data';

/*export abstract class IFormSpecProvider {
	abstract getFormSpec(className: string): Observable<IUIFormSpec>;
	abstract registerFormSpec(className: string, formSpec: Observable<IUIFormSpec>);
}*/

export enum WidgetET {
	TextField = 'TextField',
	NumericTextField = 'NumericTextField',
	DateTimeField = 'DateTimeField',
	Checkbox = 'Checkbox',
	FileUpload = 'FileUpload',
	RadioButton = 'RadioButton',
	RadioButtonGroup = 'RadioButtonGroup',
	InputSwitch = 'InputSwitch',
	DropDown = 'DropDown',
	MultiSelect = 'MultiSelect',
	AutoComplete = 'AutoComplete',
	ComboBox = 'ComboBox',
	Listview = 'Listview',
	ObjectReference = 'ObjectReference',
	RelationList = 'RelationList',
	RelationTab = 'RelationTab',
	Panel = 'Panel',
	Groupbox = 'Groupbox',
	Accordion = 'Accordion',
	AccordionTab = 'AccordionTab',
	TabView = 'TabView',
	TabPanel = 'TabPanel',
	Drawer = 'Drawer',
	Label = 'Label',
	Button = 'Button',
	ContentTemplate = 'ContentTemplate',
	InfoField = 'InfoField',
	RelationGrid = 'RelationGrid',
	ExpansionPanel = 'ExpansionPanel',
	GenericContainer = 'GenericContainer'
}

export enum VerticalSizeTypeET {
	Auto = 'Auto',
	Scrollable = 'scrollable'
}

export enum LayoutTypeET {
	Grid = 'Grid',
	Flex = 'Flex'
}

export enum ItemTypeET {
	PDItem = 'PDItem',
	PDRadioButton = 'PDRadioButton',
	UIPanel = 'UIPanel',
	UIGroupbox = 'UIGroupbox',
	UIRadioButtonGroup = 'UIRadioButtonGroup',
	UITabView = 'UITabView',
	UIDrawer = 'UIDrawer',
	PDRelationTab = 'PDRelationTab',
	UIContent = 'UIContent',
	ExpansionPanel = 'ExpansionPanel',
	GenericContainer = 'GenericContainer'
}

export enum FlexDirectionET {
	Row = 'Row',
	Column = 'Column'
}

export enum FlexAlignSelfET {
	Stretch = 'stretch',
	Unset = 'unset'
}

export enum FlexWrapET {
	Wrap = 'wrap',
	NoWrap = 'nowrap',
	WrapReverse = 'wrap-reverse'
}

export enum FlexJustifyContentET {
	Start = 'start',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	SpaceEvenly = 'space-evenly'
}

export enum FlexAlignContentET {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	Stretch = 'stretch',
	SpaceEvenly = 'space-evenly'
}

export enum FlexAlignET {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
	Baseline = 'baseline',
	Stretch = 'stretch'
}

//export type PDChoiceSpecRelationInfoSource = 'property' | 'callback';

/**
 * Spezifiziert die Auswahlmöglichkeit eines Widgets. Z.B. gibt sie vor,
 * welche Fachklasse zur Auswahl stehen.
 */
export interface IPDChoiceSpec {
	className: string;
	classErgName?: ErgNameData;
	relationInfo?: string;
	relationInfoProvider?: (localizationService: ILocalizationService) => string;
	filterExpr?: string;
	sortExpr?: string;
	properties?: string[];
	//relationInfoSource?: PDChoiceSpecRelationInfoSource;
}

export interface IPDObjectSelectionListSpec {
	className?: string;
	columns?: IPDListColumnInfo[];
	width?: string;
	height?: string;
	title?: ErgNameData;
	titleId?: string;
	sortExpr?: string;
	pdObjectProvider?: (pdAccess: IPDAccessService, ctx: IEventProcessingContext) => Observable<IPDObject[]>;
}

export interface IStyleSpec {
	style: ICssStyle,
	theme?: string
}

export type NewRelationObjectPositionT = 'before' | 'after' | 'end';

export interface IToNRelationSpec {
	relationProperty: string,
	maxRelationObjects?: number;
	minRelationObjects?: number;
	newRelationObjectPosition?: NewRelationObjectPositionT;
	createRelationObjectCallback: (pdClass: IPDClass) => IPDObject,
	/**
	 * Wird aufgerufen in ngAfterViewInit() von der [otrisToNRelationItemContainer] direktive
	 */
	relationObjectCreatedHandler?: (relationItemContainer: IToNRelationItemContainer, relObj: IPDObject, ctx: IEventProcessingContext) => void
}

export interface IToNRelationItemSpec {
	relationProperty: string;
	//index: number
	locator: {
		key: string;
		value: any;
	}
}

export interface ITo1RelationItemSpec {
	relationProperty: string;
}

/*export type TextFieldTo1RelationSpec = {
	relationClass: string;
	relationProperty: string;
}*/

export enum RadioButtonGroupDataTypeET {
	Boolean = 'Boolean',
	Enum = 'Enum',
	String = 'String'
}

export enum RadioButtonBulletLocationET {
	Start = 'Start',
	Center = 'Center',
	End = 'End'
}

export enum ObjectReferenceTypeET {
	DropDownList = 'DropDownList',
	SelectionList = 'SelectionList',
	TextField = 'TextField'
}

export enum MultiSelectRelationWidgetTypeET {
	/**
	 * Mehrfach-Selektion erfolgt über ein Dropdown.
	 * In dem mehrere Objekte selektiert werden können.
	 */
	DropDownList = 'DropDownList',
	/**
	 * Mehrfach-Selektion erfolgt über ein Popup-Fenster
	 */
	SelectionList = 'SelectionList',

	/** */
	TextField = 'TextField'
}

export type MultilineTextField = boolean | {
	resizingType?: MultilineTextFieldResizingType;
	rows?: number;
}

export type MultilineTextFieldResizingType = 'none' | 'vertical' | 'horizontal' | 'both' | 'auto';

export type TextTemplateSelection = {
	className: string;
	property: string;
	filterExpr?: string;
	filterExprProvider?: (ctx: IEventProcessingContext) => string;
	selectionListSpec?: IPDObjectSelectionListSpec;
	separator?: string;
}

export type ObjectReferenceTextFieldSpec = {
	multiline?: boolean;
	showClearButton?: boolean;
	maxTextLength?: number;
}

export type MultiSelectTextFieldSpec = {
	multiline?: MultilineTextField;
	showClearButton?: boolean;
	maxTextLength?: number;
}

export type RelationGridQuickCreationSpec = {
	to1RelationProperty: string;
	selectionListSpec: IPDObjectSelectionListSpec;
	defaultValues?: {
		[propName: string]: any;
	}
}

export interface IUIRootContainerSpec {
	header?: ErgNameData;
	headerId?: string;
	content?: IUIPanelSpec;
	width?: string;
	minWidth?: string;
	maxWidth?: string;
	height?: string;
	minHeight?: string;
	maxHeight?: string;
	showHeader?: boolean;
	transparent?: boolean;
	// backgroundColor?: string; // Nur über themes bearbeitbar
	childItemMargin?: string;
	childItemMarginTop?: string;
	childItemMarginRight?: string;
	childItemMarginBottom?: string;
	childItemMarginLeft?: string;
	disableChildItemMarginsInContainer?: boolean;
}

export interface IUIFormToolbarSpec {
	save?: boolean;
	reset?: boolean;
	language?: boolean;
}

export interface IUIFormSpec extends IUIRootContainerSpec{
	showToolbar?: boolean;
	toolbarSpec?: IUIFormToolbarSpec;
	savingHandler?: (obj: IPDObject, formData: any, mainUIService: IMainUIService, interactionService: IInteractionService, localizationService: ILocalizationService) => Observable<boolean>;
	submitOnEnter?: boolean;
}

export interface IUIPreviewSpec extends IUIRootContainerSpec {}

export interface IUIItemRef {
	isItemRef: true,
	idRef: string,
	idRefType: 'property' | 'id',
	removeReferencedItem?: boolean,
	referencedItemType?: ItemTypeET,
	itemData?: IUIItemDataMutable
}

export type UIItemSpecProperty = 'width' | 'minWidth' | 'maxWidth' |
	'height' | 'minHeight' | 'maxHeight' |
	'margin' | 'marginLeft' | 'marginTop' | 'marginRight' | 'marginBottom' |
	'padding' | 'paddingLeft' | 'paddingTop' | 'paddingRight' | 'paddingBottom';

export type OmitItemSpec = {
	value?: boolean
	handler?: (ctx: IEventProcessingContext) => boolean;
	expr?: string;
}

export type UIItemSpecPropertyValueProvider = (prop: UIItemSpecProperty, source: IComponent, ctx: IEventProcessingContext) => string | boolean | number | undefined;
 
export interface IUIItemSpec {
	id?: string;
	omitItemSpec?: OmitItemSpec;
	placement?: IUIItemPlacement;
	type: ItemTypeET;
	width?: string;
	height?: string;
	minWidth?: string;
	maxWidth?: string;
	minHeight?: string;
	maxHeight?: string;
	margin?: string;
	marginLeft?: string;
	marginTop?: string;
	marginRight?: string;
	marginBottom?: string;
	padding?: string;
	paddingLeft?: string;
	paddingTop?: string;
	paddingRight?: string;
	paddingBottom?: string;
	propertyValueProvider?: UIItemSpecPropertyValueProvider;
	propertyValueProviderData?: PropertyValueProviderData[];
	pdClassContext?: string[];
	editContext?: string[];
	invalidEditContext?: string[];
	widgetInfo?: IWidgetInfo;
	customData?: any;
	shortDescriptionId?: string;
	shortDescriptionIdHandler?: (source: IComponent, ctx: IEventProcessingContext) => string | undefined;
	shortDescription?: ErgNameData;
	initialUIStateExpressionData?: ComponentUIStateExpression;
	uiStateExpressionData?: ComponentUIStateExpression;
	initialUIStateProvider?:  (source: IComponent, ctx: IEventProcessingContext) => IComponentUIState | undefined;
	updateUIStateHandler?: (source: IComponent, currentState: IComponentUIState, ctx: IEventProcessingContext) => IComponentUIState | undefined;
	customEventHandler?: CustomEventHandler;
	detachDefaultCustomEventHandler?: boolean;
	customEventHandlerData?: CustomEventHandlerData[];
	//customEventHandlerModification?: CustomEventHandlerModification;
	customValidators?: ((source: IComponent, ctx: IEventProcessingContext) => ValidatorFn)[];
	componentEvents?: ComponentEventSpec[];
	componentEventData?: ComponentEventData[];
	foreignComponentEvents?: ForeignComponentEventSpec[];
	foreignComponentEventData?: ForeignComponentEventData[];
	foreignComponentEventsModification?: ForeignComponentEventsModification;
}

export interface IUIContentSpec extends IUIItemSpec {
	template: string;
}

export type UIContainerSpecProperty = UIItemSpecProperty | 'childItemMargin' | 'childItemMarginTop' |
	'childItemMarginRight' | 'childItemMarginBottom' | 'childItemMarginLeft';

export type ILayoutProvider = (source: IComponent, ctx: IEventProcessingContext) => ILayoutSpec | undefined;

export type UIContainerSpecPropertyValueProvider = (prop: UIContainerSpecProperty, source: IComponent, ctx: IEventProcessingContext) => string | boolean | number | undefined;

export interface IUIContainerSpec extends IUIItemSpec {
	layout?: ILayoutSpec;
	layoutProvider?: ILayoutProvider;
	layoutProviderData?: LayoutProviderData[];
	items?: (IUIItemSpec | IUIItemRef)[];
	backgroundColor?: string;
	borderRadius?: string;
	childItemMargin?: string;
	childItemMarginTop?: string;
	childItemMarginRight?: string;
	childItemMarginBottom?: string;
	childItemMarginLeft?: string;
	propertyValueProvider?: UIContainerSpecPropertyValueProvider;
	disableChildItemMarginsInContainer?: boolean;
	verticalSize?: VerticalSizeTypeET; // TODO: Name überdenken
	toNRelationItemSpec?: IToNRelationItemSpec; // TODO: sinngemäß besser nach IToNRelationSuitableContainerSpec?
	to1RelationItemSpec?: ITo1RelationItemSpec;
}

export type ToNRelationSuitableContainerRelObjectCreatedHandler = 
	(src: IPDComponent, ctx: IEventProcessingContext) => void;

export interface IToNRelationSuitableContainerSpec extends IUIContainerSpec {
	toNRelationSpec?: IToNRelationSpec;
	relationObjectCreatedHandler?: ToNRelationSuitableContainerRelObjectCreatedHandler;
}

export interface IPDItemSpec extends IUIItemSpec {
	property?: string;
	widget: WidgetET;
	label?: ErgNameData;
	labelId?: string;
	to1RelationItemSpec?: ITo1RelationItemSpec;

	defaultValue?: string | number | boolean | Date;
	//defaultValueProvider?: (source: IComponent, ctx: IEventProcessingContext) => Observable<any>; // ???
}

// Todo: wird das verwendet?
export interface IPDRadioButtonSpec extends IPDItemSpec {
	content?: IUIPanelSpec;
}

export interface IUIPanelSpec extends IToNRelationSuitableContainerSpec {
	transparent?: boolean;
	header?: string;
	headerId?: string;
	headerTemplateId?: string;
	noRelationObjectTemplateId?: string;
	panelContentStyle?: ICssStyle;
}

export interface IUIGroupboxSpec extends IToNRelationSuitableContainerSpec {
	content?: IUIPanelSpec | IUIItemRef;
	header?: ErgNameData;
	headerId?: string;
	headerIdHandler?: (source: IComponent, ctx: IEventProcessingContext) => string | undefined;
	headerTemplateId?: string;
	noRelationObjectTemplateId?: string;
}

export interface IUIRadioButtonGroupSpec extends IUIContainerSpec {
	dataType: RadioButtonGroupDataTypeET;
	radioButtons?: IPDItemSpec[];
	content?: IUIPanelSpec | IUIItemRef;
	header?: string;  // todo: raus wg. label
	label?: ErgNameData;
	property?: string;
	wrapLabel?: boolean;
	bulletLocation?: RadioButtonBulletLocationET;
}

export type PDExpansionPanelExpandedProvider =
	(source: IExpansionPanelComponent, ctx: IEventProcessingContext) => boolean | undefined;

export interface IPDExpansionPanelSpec extends IToNRelationSuitableContainerSpec {
	header?: ErgNameData;
	headerId?: string;
	headerIdHandler?: (source: IComponent, ctx: IEventProcessingContext) => string | undefined;
	headerTemplateId?: string;
	noRelationObjectTemplateId?: string;
	content?: IUIPanelSpec | IUIItemRef;
	expanded?: boolean;
	expandedProvider?: PDExpansionPanelExpandedProvider;
}

export type GenericContainerType = 'groupbox' | 'expansionPanel';

export type GenericContainerInstanceSpec = {
	groupbox?: IUIGroupboxSpec;
	expansionPanel?: IPDExpansionPanelSpec;	
}

export interface IGenericContainerSpec extends IToNRelationSuitableContainerSpec {
	visibleContainerType?: GenericContainerType;
	visibleContainerTypeProvider?: (ctx: IEventProcessingContext) => GenericContainerType | undefined;
	visibleContainerTypeProviderData?: VisibleContainerTypeProviderData;
	content?: IUIPanelSpec | IUIItemRef;
	header?: ErgNameData;
	headerId?: string;
	headerIdHandler?: (source: IComponent, ctx: IEventProcessingContext) => string | undefined;
	headerTemplateId?: string;
	noRelationObjectTemplateId?: string;
	containerInstanceSpec?: GenericContainerInstanceSpec;
}

export interface IUITabViewSpec extends IUIContainerSpec {
	panels: IUITabPanelSpec[];
}

export interface IUITabPanelSpec extends IUIContainerSpec {
	content?: IUIPanelSpec;
	header?: string;
	headerId?: string;
}

export interface IUIDrawerItemSpec {
	item: IUIDrawerItem;
	content: IUIPanelSpec;
	updateUIStateHandler?: (source: IDrawerItemComponent, ctx: IEventProcessingContext) => IComponentUIState | undefined;
}

export interface IUIDrawerSpec extends IUIContainerSpec {
	drawerItems: IUIDrawerItemSpec[];
	mode?: DrawerModeET;
	expandMode?: DrawerExpandModeT;
	position?: DrawerPositionT;
	expanded?: boolean;
	onItemSelected?: (ctx: IEventProcessingContext, item: IUIDrawerItem) => void;
}

export interface IPDRelationTabSpec extends IUIContainerSpec {
	property: string;
	content?: IUIPanelSpec;
	//objCreator?: new () => IPDObject;
}


export interface ILayoutSpec {
	type: LayoutTypeET,
	rawStyles?: {
		[propName: string]: any;
	}
}

export interface IColumnSpec {
	width: string;
}

export interface IRowSpec {
	height: string;
}

export interface IGridLayoutSpec extends ILayoutSpec {
	columns?: IColumnSpec[];
	rows?: IRowSpec[];
	gridGap?: string;
	alignItems?: string;
	justifyItems?: string;
}

export interface IFlexLayoutSpec extends ILayoutSpec {
	direction?: FlexDirectionET;
	wrap?: FlexWrapET | string;
	justifyContent?: FlexJustifyContentET | string;
	alignItems?: FlexAlignET | string;
	alignContent?: FlexAlignContentET | string;
	gap?: string;
	/**
	 * Override the align-items property of the parents flexbox align-items.
	 * @use Core.FlexAlignSelfET
	 */
	//alignSelf?: FlexAlignSelfET | string;
	/**
	 * Defines how the flexbox itself stretch inside of the parent flexbox.
	 * @use Core.FlexFlexET
	 */
	//flex?: FlexFlexET | string;
}

export interface IUIItemPlacement {

}

export interface IGridItemPlacement extends IUIItemPlacement {
	row?: number;
	col?: number;
	colspan?: number;
	rowspan?: number;
}

export interface IFlexItemPlacement extends IUIItemPlacement {
	flex?: string;
	align?: FlexAlignET;
}

export interface IWidgetInfo {
	guiReadonly?: boolean; // todo: weg wegen UIStates ?
}

export interface ILabelWidgetInfo extends IWidgetInfo {
	// label?: ErgNameData; bereits in PDItemSpec enthalten!
	// labelId?: string;
	styleSpec?: IStyleSpec;
}

export interface IButtonWidgetInfo extends IWidgetInfo {
	label?: string;
	labelId?: string;
	iconClass?: string;
	clickHandler?: () => void;
}

export interface IRadioButtonWidgetInfo extends IWidgetInfo {
	value: boolean | number | string;
	//label?: ErgNameData;
	//labelId?: string;
	bulletLocation?: RadioButtonBulletLocationET;
}

// Das passt doch besser in IUIRadioButtonGroupSpec!
export interface IRadioButtonGroupWidgetInfo extends IWidgetInfo {
	selectionChangedHandler?: (source: IRadioButtonGroupComponent, selection: IRadioButtonComponent, ctx: IEventProcessingContext) => void;
}

export interface ILabeledControlWidgetInfo extends IWidgetInfo {
	prefix?: string;
	prefixWidth?: string;
	labelVisibility?: VisibilityET;
	labelPosition?: LabelPositionET;
	toolbar?: IToolbarSpec;
	wrapLabel?: boolean;
	//useCustomLabel?: boolean;
	//customLabel?: string;
	//customLabelId?: string;
	labelStyle?: IStyleSpec;
	shortDescriptionVisible?: boolean;
	toolbarButtonClickHandler?: (source: ILabeledControlComponent, item: IToolBarItemResult, ctx: IEventProcessingContext) => void;
}

export interface ITextFieldWidgetInfo extends ILabeledControlWidgetInfo {
	multiline?: boolean;
	/**
	 * Anzahl an Linien die mindestens angezeigt werden.
	 */
	rows?: number;
	/**
	 * Streckt das Element innerhalb seines Containers.
	 * Todo: prüfen, ob das noch benötigt wird
	 */
	//stretch?: boolean;

	valueChangesHandler?: (source: ITextFieldComponent, value: string, ctx: IEventProcessingContext) => void;
	/**
	 * Definiere in welche Richtung Scrollbars erscheinen sollen oder gar keine erscheinen lassen.
	 */
	resizingType?: MultilineTextFieldResizingType;
	/**
	 * Textfeld löschen, falls UIState disabled ist
	 */
	clearIfDisabled?: boolean;

	/**
	 * Einfügemöglichkeit von Text-Templates.
	 * Über ein Toolbarbutton kann ein Selektionsdialog für eine Klasse angezeigt werden.
	 * Von den selektierten Objekten wird die objectInfo in das Textfeld übernommen.
	 */
	textTemplateSelection?: TextTemplateSelection;

	uiStateUpdateType?: PDTextFieldUIStateUpdateType;

	showClearButton?: boolean;

	//to1RelationSpec?: TextFieldTo1RelationSpec;

	/**
	 * Darf die Rechtschreibprüfung des Browsers verwendet werden, für dieses Widget?
	 */
	spellcheck?: boolean;
}

export interface INumericTextFieldWidgetInfo extends ILabeledControlWidgetInfo {
	step?: number;
	decimals?: number;
	min?: number;
	max?: number;
	showSpinners?: boolean;
	format?: string | any;
	valueChangesHandler?: (source: INumericTextFieldComponent, value: number | undefined, ctx: IEventProcessingContext) => void;
}

export interface IDateTimeFieldWidgetInfo extends ILabeledControlWidgetInfo {
	showNavigationBar?: boolean;
	valueChangesHandler?: (source: IDateTimeFieldComponent, value: Date | undefined, ctx: IEventProcessingContext) => void;
}

export interface IDropDownWidgetInfo extends ILabeledControlWidgetInfo {
	selectedItemChangedHandler?: (source: IDropDownComponent, value: string | IEnumerationItem | undefined, ctx: IEventProcessingContext) => void;
}

export interface IListviewWidgetInfo extends ILabeledControlWidgetInfo {
	textField: string;
	valueField: string;
	itemsChangedHandler?: (source: IListviewComponent, value: any | undefined, ctx: IEventProcessingContext) => void;
}

export interface IComboBoxWidgetInfo extends ILabeledControlWidgetInfo {
	active?: boolean;
	displayTemplate?: string;
	valueTemplate?: string;
	className?: string;
	choiceFilterExpr?: string;
	choiceSortExpr?: string;
	customChoiceProvider?: (source: IComboBoxComponent, ctx: IEventProcessingContext) => Observable<IPDObject[]>;
	customChoiceFilterProvider?: (source: IComboBoxComponent, ctx: IEventProcessingContext) => string | undefined;
	valueChangedHandler?: (source: IComboBoxComponent, value: string | undefined, ctx: IEventProcessingContext) => void;
	spellcheck?: boolean;
}

export interface IDetailFormHandling {
	outletName: string;
	url: any[];
}

export interface PDObjectEditingSpec {
	outletName: string;
	urlCreate?: any[];
	urlEdit?: any[];
	className: string | string[];
	/**
	 * Property muss klein geschrieben werden!
	 * @example objectInfo: "%aabsender%" nicht objectInfo: "%aAbsender%"
	 */
	objectInfo: string;

	remoteCreationParams?: object;
}


export interface IRelationControlWidgetInfo extends ILabeledControlWidgetInfo {
	//active?: boolean;
	/**
	 * Lässt einen Button anzeigen, der ein Objekt erstellen kann für die Auswahl.
	 * Mit der editingSpec kann ein form zum Bearbeiten angegeben werden.
	 */
	newAction?: boolean;
	editAction?: boolean;
	deleteAction?: boolean;
	selectAction?: boolean;
	disconnectAction?: boolean;
	/**
	 * Spec der beim "newAction" und editAction verwendet wird.
	 */
	editingSpec?: PDObjectEditingSpec;

	//newActionFormHandling?: IDetailFormHandling;
	//newObjectClassname?: string;

}

export interface IInputSwitchWidgetInfo extends ILabeledControlWidgetInfo {

}

export interface ICheckboxWidgetInfo extends IWidgetInfo {
	requiredTrue?: boolean;
	labelVisibility?: VisibilityET;
	canIndeterminate?: boolean;
	valueChangedHandler?: (source: ICheckBoxComponent, value: boolean, ctx: IEventProcessingContext) => void;
}

export interface IObjectReferenceWidgetInfo extends IRelationControlWidgetInfo {
	choiceSpec?: IPDChoiceSpec | IPDChoiceSpec[];
	noSelectionItemText?: string;
	filteringEnabled?: boolean;
	type?: ObjectReferenceTypeET;
	selectionListSpec?: IPDObjectSelectionListSpec;
	customChoiceProvider?: CustomChoiceProvider;
	customChoiceFilterProvider?: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>;
	selectionChangedHandler?: (source: IObjectReferenceComponent, selection: IPDObject | undefined | null, ctx: IEventProcessingContext) => void;

	customChoiceProviderData?: CustomChoiceProviderData[];
	selectionChangedHandlerData?: EventHandlerCommandsData;

	/**
	 * Soll localizationService.changeHandler innerhalb der Komponente ausgeführt werden?
	 * Das Unternehmen z.B. ist Sprachunabhängig und triggert onUnternehmenChanged,
	 * was beim Sprachwechsel nicht erwünscht ist. #45680
	 * TODO: Evtl. profitieren andere Komponente auch davon? -> Elternklasse
	 * @default true
	 */
	triggerLanguageUpdate?: boolean;

	editableRelationProperty?: string;

	textFieldSpec?: ObjectReferenceTextFieldSpec;

	/**
	 * Soll beim Gruppieren ein zusätzlicher sticky-header/Überschrift angezeigt werden?
	 */
	showStickyHeader?: boolean;
}

export interface IRelationTabHeader {
	text$: Observable<string>;
	maxVisibleTextLength?: number;
}

export interface IRelationTabWidgetInfo extends IRelationControlWidgetInfo {
	//relationInfo: string; // todo: prüfen, ob das wg. tabHeaderProvider noch gebraucht wird
	tabPanelStyle?: ICssStyle;
	maxTabCount?: number;
	minTabCount?: number;
	tabHeaderProvider?: (tabIndex: number, relObj: IPDObject, localizationService: ILocalizationService) => IRelationTabHeader;
	canCreateTab?: boolean;
	canDeleteTab?: boolean;
	createRelationObjectCallback?: CreateRelationObjectCallback;
	relationObjectCreatedCallback?: (source: IPDRelationTabComponent, obj: IPDObject, tabId: string, ctx: IEventProcessingContext) => void;
}

export interface IMultiSelectRelationWidgetInfo extends IRelationControlWidgetInfo {
	choiceSpec?: IPDChoiceSpec | IPDChoiceSpec[];
	/**
	 * Typ der Selektion.
	 * Wird kein Typ ausgewählt, so wird DropDownList als Typ verwendet.
	 */
	type?: MultiSelectRelationWidgetTypeET;
	selectionListSpec?: IPDObjectSelectionListSpec;
	showCheckboxes?: boolean;
	/**
	 * Liefert die Daten für die Auswahl-Liste.
	 */
	customChoiceProvider?: CustomChoiceProvider;
	/**
	 * Liefert die Filter-Bedingung für die Auswahl-Liste.
	 */
	customChoiceFilterProvider?: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>;
	/**
	 * Wird die Selektion geändert, so wird diese Funktion aufgerufen.
	 */
	selectionChangedHandler?: (source: IMultiSelectComponent, selection: IPDObject[] | string[] | IEnumerationItem[] | undefined, ctx: IEventProcessingContext) => void;

	customChoiceProviderData?: CustomChoiceProviderData[];
	selectionChangedHandlerData?: EventHandlerCommandsData;

	/**
	 * Sollen inaktive Enum-Objekte geladen werden? Im default können diese nicht selektiert/hinzugefügt werden.
	 * Bereits selektierte bleiben weiterhin selektiert.
	 * Mit dem flag `selectableInactiveEnum` können diese auch selektiert werden.
	 * TODO: Implementieren
	 */
	loadInactiveEnum?: boolean;
	/**
	 * Sollen inaktive Enum-Objekte auch selektiert werden können?
	 * Default false. Bereits selektierte bleiben weiterhin selektiert.
	 * TODO: Implementieren
	 */
	selectableInactiveEnum?: boolean;

	/**
	 *
	 */
	showClearButton?: boolean;

	editableRelationProperty?: string;

	textFieldSpec?: MultiSelectTextFieldSpec;

	textTemplateSelection?: TextTemplateSelection;

	/**
	 * Soll beim Gruppieren ein zusätzlicher sticky-header/Überschrift angezeigt werden?
	 */
	showStickyHeader?: boolean;
}

export interface IAccordionWidgetInfo extends IWidgetInfo {
	multiple?: boolean;
}

export interface IInfoFieldWidgetInfo extends ILabeledControlWidgetInfo {
	template?: string;
}

export interface IFileUploadWidgetInfo extends ILabeledControlWidgetInfo {
	maxFiles?: number;
	allowedFileTypes?: string[];
}

export interface IRelationGridWidgetInfo extends IRelationControlWidgetInfo {
	toNRelationSpec: IToNRelationSpec;
	/**
	 * Spalten für das relation-grid, nicht für die Selektion (Popup).
	 */
	columns?: IPDListColumnInfo[];
	maxHeight?: string;
	deleteSelectedAction?: boolean;
	quickCreationEnabled?: boolean;
	selectionListSpec?: IPDObjectSelectionListSpec;
	quickCreationSpec?: RelationGridQuickCreationSpec;
	fieldNameToPropertyNameAdapter?: (obj: IPDObject, field: string, ctx: IEventProcessingContext) => string;
	customChoiceFilterProvider?: (source: IRelationGridComponent, ctx: IEventProcessingContext) => string | undefined;
	customChoiceProvider?: (source: IRelationGridComponent, ctx: IEventProcessingContext) => ICustomChoiceProviderResult[];
	selectionClassNameProvider?: (source: IRelationGridComponent, ctx: IEventProcessingContext) => string;
	onSaveRowHandler?: (obj: IPDObject, source: IRelationGridComponent, ctx: IEventProcessingContext) => void;
}

