import { Component, Inject, Input, OnInit } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { ErgNameData, ILocalizationService, IPDComponent, LanguageCodeET } from '@otris/ng-core-types';
import { PDObject, ShortDescriptionFormatET } from '@otris/ng-core-shared';
import { LocalizationServiceToken } from '@otris/ng-core';

// [style]="style | safeStyle"
@Component({
	selector: 'otris-pd-short-description',
	template: `
		<!--<ng-template #template let-anchor>
			<div class="otris-pd-short-description-tooltip-container">{{ shortDescription }}</div>
		</ng-template>-->

		<ng-template #template let-anchor>
			@switch (format | async) {
				@case (shortDescriptionFormatET.Html) {
  				<div class="otris-pd-short-description-tooltip-container"
						[innerHtml]="shortDescription | safeHtml">
					</div>
				}
				@case (shortDescriptionFormatET.Text) {
  				<div class="otris-pd-short-description-tooltip-container">{{ shortDescription }}</div>
				}
			}
		</ng-template>
		
		<div class="root-container" kendoTooltip tooltipClass="otris-tooltip" [tooltipTemplate]="template" filter="span" [style.color]="imageColor">
			<span
				class="image fa fa-fw fa-info-circle fa-lg"
				[title]="(format | async) === shortDescriptionFormatET.Text ? shortDescription : shortDescription | safeHtml"
			></span>
		</div>
	`,
	styles: [`
		:host {
			display: flex;
			justify-content: center;
			align-items: center;
			/*align-self: flex-start;*/
		}
		.root-container {
			/*margin-left: 0.5em;*/
		}
		.image {
			cursor: help;
		}
		/*.image:hover {
			opacity: 1;
		}*/
	`]
})
export class PDShortDescriptionComponent implements OnInit {

	//@Input() shortDescription: string;

	@Input() pdComponent: IPDComponent;

	@Input() highlighted: boolean = false;

	@Input() pdObject: PDObject;

	@Input() customShortDescription: ErgNameData;

	@Input() customShortDescriptionId: string;

	shortDescriptionFormatET = ShortDescriptionFormatET;

	//@Input() format: ShortDescritionFormatET;

	//@Input() style: string;

	/*get safeStyle(): SafeStyle {
		if (this.format !== ShortDescritionFormatET.Html || !this.style) {
			return undefined;
		}
		let safeStylePipe = <SafeStylePipe>ServiceLocator.injector.get(SafeStylePipe);
		return safeStylePipe.transform(this.style);
	}*/

	get imageColor(): string {
		return this.highlighted ? 'dodgerblue' : 'lightgray';
	}

	get format(): Observable<ShortDescriptionFormatET> {
		return (this.pdObject && this.pdComponent.propertyName) ?
			this.pdObject.metaData.getShortDescriptionFormat(this.pdComponent.propertyName) :
			of(ShortDescriptionFormatET.Text);
	}

	/*get shortDescription(): string {
		if (this.customShortDescription) {
			switch (this.localizationService.currentLanguage.code) {
				case LanguageCodeET.de:
					return this.customShortDescription.de;
				case LanguageCodeET.en:
					return this.customShortDescription.en;
			}
		}
		return this.pdComponent?.shortDescription;
	}*/

	get shortDescription(): string {
		return this._shortDescription;
	}

	private _shortDescription: string;

	constructor(
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService
	) {
	}

	ngOnInit(): void {
		if (this.customShortDescription) {
			switch (this.localizationService.currentLanguage.code) {
				case LanguageCodeET.de:
					this._shortDescription = this.customShortDescription.de;
					break;
				case LanguageCodeET.en:
					this._shortDescription = this.customShortDescription.en;
					break;
			}
		}
		else if (this.customShortDescriptionId) {
			this.localizationService.changeHandler.pipe(
				switchMap(() => {
					return this.localizationService.getString(this.customShortDescriptionId);
				})
			).subscribe(res => this._shortDescription = res);
		}
		else {
			this.localizationService.changeHandler.pipe(
				map(() => {
					this._shortDescription = this.pdComponent?.shortDescription;
				})
			).subscribe();
		}
	}
}
