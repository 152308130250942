import { Inject, Injectable } from "@angular/core";
import Ajv from 'ajv';

import {
	IError,
	IErrorHandler,
	IErrorHandlerToken,
	JsonSchemaToken,
	SchemaType,
	ISchema,
	JsonValidatorResult
} from "@otris/ng-core-shared";
import { AnyValidateFunction } from "ajv/dist/core";
import { catchError } from "rxjs/operators";

// export const JsonSchemaToken = new InjectionToken<ISchema>('Schema');

// export enum SchemaType {
// 	string,
// 	object,
// 	JSONSchemaType, // UNSUPPORTED - erfordert strictNullChecks=true
// 	JTD // UNSUPPORTED - erfordert strictNullChecks=true
// }

// /**
//  * Wrapper für die unterschiedliche arten der Schemas
//  */
// export interface ISchema {
// 	type: SchemaType,
// 	data: object | string;
// 	// asString?: string;
// 	// asObject?: object;
// 	// asJsonSchemaType?: any;
// 	// asJTDSchemaType?: any;
// }

// /**
//  * Ergebnis einer Validierung
//  */
// export interface JsonValidatorResult {
// 	isValid: boolean;
// 	errorMessage?: string;
// }
@Injectable()
export class JsonValidatorService implements IJsonValidatorService {

	private ajv: Ajv;

	constructor(
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler,
		@Inject(JsonSchemaToken) private schemas: ISchema[]
	) {
		this.ajv = new Ajv();
		this.addSchemaArray(schemas);
	}

	addSchema(schema: ISchema): void {
		if (schema.type === SchemaType.object) {
			this.ajv.addSchema(<object>schema.data);
			// console.log(`Added schema with id: ${schema.data['$id']}`);
			// console.dir(schema);
		} else if (schema.type === SchemaType.string) {
			const jSchema = JSON.parse(<string>schema.data);
			this.ajv.addSchema(jSchema);
			// console.log(`Added schema with id: ${jSchema['$id']}`);
			// console.dir(jSchema);
		} else {
			this.errorHandler.handleError(<IError>{ message: "Can't use addSchema if schema is not an object or string!", canReconnect: false });
		}
	}

	addSchemaArray(schemaArray: ISchema[]): void {
		schemaArray.forEach(schema => {
			// Wegen der Schema-Type Überprüfung, auf ajv.addSchema([...]) verzichtet.
			this.addSchema(schema);
		})
	}

	validateWithSchema(schema: ISchema, jsonData: string | object): JsonValidatorResult {

		switch(schema.type) {
			case SchemaType.string:
				if (typeof schema.data !== 'string') {
					this.errorHandler.handleError(<IError>{ message: 'Schema is not a string', canReconnect: false });
				}
				return this.validateWithString(schema.data as string, jsonData);
			case SchemaType.object:
				if (typeof schema.data !== 'object') {
					this.errorHandler.handleError(<IError>{ message: 'schema has no object data', canReconnect: false });
				}
				return this.validateWithObject(schema.data as object, jsonData);
			case SchemaType.JSONSchemaType:
				this.errorHandler.handleError(<IError>{ message: 'Schema type: JSONSchemaType is not supported', canReconnect: false });
				break; // UNSUPPORTED
			case SchemaType.JTD:
				this.errorHandler.handleError(<IError>{ message: 'Schema type: JSONSchemaType is not supported', canReconnect: false });
				break; // UNSUPPORTED
		}

		return <JsonValidatorResult>{ isValid: false, errorMessage: "SchemaType.JSONSchemaType | SchemaType.JTD is not implemented" };
	}

	validateWithSchemaID(schemaId: string, jsonData: string | object): JsonValidatorResult {
		let schema: AnyValidateFunction<unknown>;
		try {
			 schema = this.ajv.getSchema(schemaId);
		} catch (err) {
			return <JsonValidatorResult>{
				isValid: false,
				errorSchema: {},
				errorMessage: err,
				errorAsString: `Couldn't retrieve schema: ${schemaId} - Error details: ${JSON.stringify(err)}`
			};
		}

		// Schema konnte mit der ID nicht gefunden werden.
		if (!schema) {
			return <JsonValidatorResult>{
				isValid: false,
				errorSchema: {},
				errorMessage: `Couldn't find schema with id: ${schemaId}`,
				errorAsString: `Couldn't find schema with id: ${schemaId}`
			};
		}

		const result = schema(jsonData);
		if (result) {
			return <JsonValidatorResult>{ isValid: true };
		}
		return <JsonValidatorResult>{
			isValid: false,
			errorMessage: schema.errors,
			errorSchema: schema.schema,
			errorAsString: this.errorToString(schema)
		};
	}

	private errorToString(schemaResult: AnyValidateFunction<unknown>) {
		if (schemaResult.errors && schemaResult.errors.length > 0) {
			let errMsg = `Schema: "${schemaResult.schema['$id']}" ErrList: `;
			errMsg += '[';
			schemaResult.errors.forEach(err => {
				errMsg += `instancePath: ${err['instancePath'] === '' ? '(root)' : err['instancePath']}`;
				// errMsg += ', keyword: ' + err.keyword; // Steckt auch in der message
				errMsg += ', message: ' + err.message;
				errMsg += ', params: ' + JSON.stringify(err.params);
				// errMsg += ', schemaPath: ' + err.schemaPath; // Scheint mir eine Abkürzung von message zu sein
				errMsg += ';';
			});
			errMsg += ']';
			return errMsg;
		}
		return 'No error message';
	}

	// FIXME: Mit validateWithObject zusammenführen
	private validateWithString(schema: string, jsonData: string | object): JsonValidatorResult {

		const result = this.ajv.validate(schema, jsonData);
		if (result) {
			return <JsonValidatorResult>{ isValid: true };
		} else {
			let msg = `Schema used: ${JSON.stringify(schema)}\n`
			+ `Tested json: ${JSON.stringify(jsonData)}\n`
			+ `Validation error: ${this.ajv.errorsText()}`;
			return <JsonValidatorResult>{ isValid: false, errorMessage: msg };
		}
	}

	private validateWithObject(schema: object, jsonData: string | object): JsonValidatorResult {

		const result = this.ajv.validate(schema, jsonData);

		if (result) {
			return <JsonValidatorResult>{ isValid: true };
		} else {
			let msg = `Schema used: ${JSON.stringify(schema)}\n`
			+ `Tested json: ${JSON.stringify(jsonData)}\n`
			+ `Validation error: ${this.ajv.errorsText()}`;
			return <JsonValidatorResult>{ isValid: false, errorMessage: msg };
		}
	}

	// Not implemented
	private validateWithJSONSchemaType(schema: any, jsonData: string | object): JsonValidatorResult {
		return <JsonValidatorResult>{ isValid: false, errorMessage: "Not implemented" };
	}

	// Not implemented
	private validateWithJTDSchemaType(schema: any, jsonData: string | object): JsonValidatorResult {
		return <JsonValidatorResult>{ isValid: false, errorMessage: "Not implemented" };
	}
}

@Injectable({providedIn: 'root', useClass: JsonValidatorService })
export abstract class IJsonValidatorService {

	/**
	 * Fügt ein Schema hinzu der dann zu einem späteren zeitpunkt benutzt werden kann.
	 * Dies ist auch wichtig für Referenzierung innerhalb der schemas.
	 * @param schema (JSON)-Object
	 */
	abstract addSchema(schema: ISchema): void;

	/**
	 * Fügt mehrere Schema hinzu die dann zu einem späteren zeitpunkt benutzt werden können.
	 * Dies ist auch wichtig für Referenzierung innerhalb der schemas.
	 * @param schemaArray (JSON)-Objects
	 */
	 abstract addSchemaArray(schemaArray: ISchema[]): void;

	/**
	 * Validiert die Json-Datei/Objekt
	 * @param schema Schema das zur Validierung verwendet wird
	 * @param jsonData Der zu überprüfende Json-String(Objekt)
	 */
	abstract validateWithSchema(schema: ISchema, jsonData: string | object): JsonValidatorResult;

	/**
	 * Validiert die Json-Datei/Objekt mit einem gespeicherten Schema
	 * @param schemaId ID eines gespeicherten Schemas
	 * @param jsonData Der zu überprüfende Json-String(Objekt)
	 */
	abstract validateWithSchemaID(schemaId: string, jsonData: string | object): JsonValidatorResult;
}
