import { Component, OnInit, Input, ViewChildren, QueryList, HostBinding, Self, Optional, ContentChild, TemplateRef, ChangeDetectorRef, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
	PDContainerComponent, UIContentComponent, FormHandlerService, UIAbstractComponent, FlexLayoutSpec, UIPanelSpec, EventProcessingContextManagerService, ToNRelationItemContainerDirective, UIItemSpec, UIToNRelationSuitableContainerSpec, UIContainerSpec, ToNRelationItemDirective, To1RelationItemDirective, GenericContainerSpec, PDContainerItem
} from '@otris/ng-core';
import { ComponentTypeET, IComponentUIState, IPanelComponent, IToNRelationItemContainer, IRelationContext, IPDObject, ICssStyle, IToNRelationSpec, IToNRelationItemSpec, IPDClass, UIStateProvider, IUIStateProviderRegistration, IComponent, GenericContainerType, ItemTypeET } from '@otris/ng-core-types';
import { PDTextFieldComponent } from '../pd-text-field/pd-text-field.component';
import { PDNumericTextFieldComponent } from '../pd-numeric-text-field/pd-numeric-text-field.component';
import { PDCheckboxComponent } from '../pd-checkbox/pd-checkbox.component';
import { PDRadioButtonComponent } from '../pd-radio-button/pd-radio-button.component';
import { PDInputSwitchComponent } from '../pd-input-switch/pd-input-switch.component';
import { PDDateTimeFieldComponent } from '../pd-date-time-field/pd-date-time-field.component';
import { PDDropDownComponent } from '../pd-drop-down/pd-drop-down.component';
import { PDMultiSelectComponent } from '../pd-multi-select/pd-multi-select.component';
import { PDAutoCompleteComponent } from '../pd-auto-complete/pd-auto-complete.component';
import { PDObjectReferenceComponent } from '../pd-object-reference/pd-object-reference.component';
import { PDGroupboxComponent } from '../pd-groupbox/pd-groupbox.component';
import { PDRadioButtonGroupComponent } from '../pd-radio-button-group/pd-radio-button-group.component';
import { PDTabViewComponent } from '../pd-tab-view/pd-tab-view.component';
import { PDComboBoxComponent } from '../pd-combo-box/pd-combo-box.component';
import { PDRelationTabComponent } from '../pd-relation-tab/pd-relation-tab.component';
import { PDButtonComponent } from '../pd-button/pd-button.component';
import { PDInfoFieldComponent } from '../pd-info-field/pd-info-field.component';
import { PDFileUploadComponent } from '../pd-file-upload/pd-file-upload.component';
import { PDDrawerComponent } from '../pd-drawer/pd-drawer.component';
import { PDPanelHeaderDirective } from './pd-panel-header.directive';
import { UntypedFormGroup } from '@angular/forms';
import { IPDClassToken, PDObject } from '@otris/ng-core-shared';
import { PDListviewComponent} from "../pd-listview/pd-listview.component";
import { PDRelationGridComponent } from '../pd-relation-grid/pd-relation-grid.component';
import { PDExpansionPanelComponent } from '../pd-expansion-panel/pd-expansion-panel.component';

@Component({
	selector: 'otris-pd-panel',
	templateUrl: './pd-panel.component.html',
	styles: [`
		:host {
			display: flex;
		}
		.root {
			flex: 1;
			display: grid;
			grid-template-rows: max-content minmax(1px, 1fr);
		}
	`]
})
export class PDPanelComponent extends PDContainerComponent implements IPanelComponent {
	@ViewChildren(PDTextFieldComponent) textFields: QueryList<PDTextFieldComponent>;
	@ViewChildren(PDNumericTextFieldComponent) numericTextFields: QueryList<PDTextFieldComponent>;
	@ViewChildren(PDCheckboxComponent) checkBoxes: QueryList<PDCheckboxComponent>;
	@ViewChildren(PDRadioButtonComponent) radioButtons: QueryList<PDRadioButtonComponent>;
	@ViewChildren(PDInputSwitchComponent) inputSwitches: QueryList<PDInputSwitchComponent>;
	@ViewChildren(PDDateTimeFieldComponent) dateTimeFields: QueryList<PDDateTimeFieldComponent>;
	@ViewChildren(PDDropDownComponent) dropDowns: QueryList<PDDropDownComponent>;
	@ViewChildren(PDListviewComponent) listview: QueryList<PDListviewComponent>;
	@ViewChildren(PDMultiSelectComponent) multiSelects: QueryList<PDMultiSelectComponent>;
	@ViewChildren(PDAutoCompleteComponent) autoCompletes: QueryList<PDAutoCompleteComponent>;
	@ViewChildren(PDObjectReferenceComponent) objectReferences: QueryList<PDObjectReferenceComponent>;
	@ViewChildren(PDGroupboxComponent) groupboxes: QueryList<PDGroupboxComponent>;
	@ViewChildren(PDRadioButtonGroupComponent) radioButtonGroups: QueryList<PDRadioButtonGroupComponent>;
	@ViewChildren(PDTabViewComponent) tabViews: QueryList<PDTabViewComponent>;
	@ViewChildren(PDComboBoxComponent) comboBoxes: QueryList<PDComboBoxComponent>;
	@ViewChildren(PDRelationTabComponent) relationTabs: QueryList<PDRelationTabComponent>;
	@ViewChildren(PDPanelComponent) panels: QueryList<PDPanelComponent>;
	@ViewChildren(PDButtonComponent) buttons: QueryList<PDButtonComponent>;
	@ViewChildren(UIContentComponent) contentComps: QueryList<UIContentComponent>;
	@ViewChildren(PDInfoFieldComponent) infoFields: QueryList<PDInfoFieldComponent>;
	@ViewChildren(PDFileUploadComponent) fileUploads: QueryList<PDFileUploadComponent>;
	@ViewChildren(PDDrawerComponent) drawers: QueryList<PDDrawerComponent>;
	@ViewChildren(PDRelationGridComponent) relationGrids: QueryList<PDRelationGridComponent>;
	@ViewChildren(PDExpansionPanelComponent) expansionPanels: QueryList<PDExpansionPanelComponent>;

	activateChangeDetection(active: boolean): void {
		if (active) {
			this.cdRef.reattach();
			this.cdRef.markForCheck(); // For safety
		} else {
			this.cdRef.detach();
		}
	}

	@HostBinding('class.otris-pd-panel-background-color') get bgColor() {
		return !this.panelSpec.transparent;
	}

	protected get childComponents(): UIAbstractComponent[] {
		let comps: UIAbstractComponent[] = [];
		if (this.textFields) {
			comps.push(...this.textFields.toArray());
		}
		if (this.numericTextFields) {
			comps.push(...this.numericTextFields.toArray());
		}
		if (this.checkBoxes) {
			comps.push(...this.checkBoxes.toArray());
		}
		if (this.radioButtons) {
			comps.push(...this.radioButtons.toArray());
		}
		if (this.inputSwitches) {
			comps.push(...this.inputSwitches.toArray());
		}
		if (this.dateTimeFields) {
			comps.push(...this.dateTimeFields.toArray());
		}
		if (this.dropDowns) {
			comps.push(...this.dropDowns.toArray());
		}
		if (this.listview) {
			comps.push(...this.listview.toArray());
		}
		if (this.multiSelects) {
			comps.push(...this.multiSelects.toArray());
		}
		if (this.autoCompletes) {
			comps.push(...this.autoCompletes.toArray());
		}
		if (this.objectReferences) {
			comps.push(...this.objectReferences.toArray());
		}
		if (this.groupboxes) {
			comps.push(...this.groupboxes.toArray());
		}
		if (this.radioButtonGroups) {
			comps.push(...this.radioButtonGroups.toArray());
		}
		if (this.relationTabs) {
			comps.push(...this.relationTabs.toArray());
		}
		if (this.comboBoxes) {
			comps.push(...this.comboBoxes.toArray());
		}
		if (this.tabViews) {
			comps.push(...this.tabViews.toArray());
		}
		if (this.panels) {
			comps.push(...this.panels.toArray());
		}
		if (this.buttons) {
			comps.push(...this.buttons.toArray());
		}
		if (this.contentComps) {
			comps.push(...this.contentComps.toArray());
		}
		if (this.infoFields) {
			comps.push(...this.infoFields.toArray());
		}
		if (this.fileUploads) {
			comps.push(...this.fileUploads.toArray());
		}
		if (this.drawers) {
			comps.push(...this.drawers.toArray());
		}
		if (this.relationGrids) {
			comps.push(...this.relationGrids.toArray());
		}
		if (this.expansionPanels) {
			comps.push(...this.expansionPanels.toArray());
		}
		return comps;
	}

	get toNRelationItemContainer(): IToNRelationItemContainer | undefined {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.toNRelationItemContainer : undefined;
	}

	get hasHeaderTemplate(): boolean {
		return !!this.panelSpec.headerTemplateId;
	}

	@ContentChild(PDPanelHeaderDirective) pdPanelHeaderDirective: PDPanelHeaderDirective;

	private _panelHeaderTemplate: TemplateRef<any>;

	get panelHeaderTemplate(): TemplateRef<any> {
		return this.pdPanelHeaderDirective ? this.pdPanelHeaderDirective.template : this._panelHeaderTemplate;
	}

	//private _componentsChangedSubject$: Subject<ComponentTypeET> = new Subject();

	getNoRelationObjectTemplate(spec: UIItemSpec): TemplateRef<any> {
		if (!(spec instanceof UIPanelSpec)) {
			throw new Error('Invalid operation call: getNoRelationObjectTemplate()');
		}

		return this.formHandler.getUITemplate(spec.noRelationObjectTemplateId);
	}

	getRelationItemObject(item: PDContainerItem): PDObject {
		if (!(item.spec instanceof UIContainerSpec)) {
			throw new Error('Invalid operation call: getRelationItemObject()');
		}
		let contSpec = item.spec as UIContainerSpec;
		if (!contSpec.toNRelationItemSpec) {
			throw new Error('No toNRelationItemSpec specified.');
		}
		let relObjects = item.pdObject[item.spec.toNRelationItemSpec.relationProperty];
		if (!Array.isArray(relObjects)) {
			throw new Error(`Invalid relationProperty '${item.spec.toNRelationItemSpec.relationProperty}'`);
		}
		/*if (item.spec.relationItemSpec.index >= relObjects.length) {
			throw new Error(`Invalid relation index '${item.spec.relationItemSpec.index}'`);
		}*/
		let locator = item.spec.toNRelationItemSpec.locator;
		let obj = relObjects.find(obj => obj[locator.key] === locator.value);
		if (!obj) {
			throw new Error(`No relation item found with key '${locator.key}' an value '${locator.value}'`);
		}
		return obj;
		//return relObjects[item.spec.relationItemSpec.index];
	}

	getTo1RelationItemObject(item: PDContainerItem): PDObject | undefined {
		if (!(item.spec instanceof UIContainerSpec)) {
			throw new Error('Invalid operation call: getTo1RelationItemObject()');
		}
		let contSpec = item.spec as UIContainerSpec;
		if (!contSpec.to1RelationItemSpec) {
			throw new Error('No to1RelationItemSpec specified.');
		}
		let relObject = item.pdObject[item.spec.to1RelationItemSpec.relationProperty];
		if (relObject !== undefined && !(relObject instanceof PDObject)) {
			throw new Error('Relation object not of type PDObject');
		}
		return relObject;
	}

	get headerContext(): { header: string, panel: IPanelComponent, spec: UIPanelSpec, customData: object } {
		return {
			header: this.header,
			panel: this,
			spec: this.panelSpec,
			customData: this._customHeaderData
		}
	}

	/*get noRelationObjectTemplateContext(): { createRelationObjectCallback: () => void } {
		return {
			createRelationObjectCallback: () => {
				console.log('createRelationObjectCallback');
			}
		}
	}*/

	getNoRelationObjectTemplateContext(spec: UIItemSpec): { createRelationObjectCallback: () => void } {
		if (!(spec instanceof UIPanelSpec)) {
			throw new Error('Invalid operation call: getNoRelationObjectTemplateContext()');
		}

		return {
			createRelationObjectCallback: () => {
				let relObj = spec.toNRelationSpec.createRelationObjectCallback(this.pdClass);
				if (!relObj) {
					throw new Error('Relation object could not be created.');
				}
				this.pdObject[spec.toNRelationSpec.relationProperty] = [relObj];
				this.cdRef.detectChanges();
				/*if (spec.relationSpec.relationObjectCreatedHandler) {
					spec.relationSpec.relationObjectCreatedHandler(this.toNRelationItemContainer, relObj, this.hostComponent.createEventProcessingContext());
				}*/
			}
		}
	}

	header: string;

	private _customHeaderData = {};

	getFormGroupForChilds(): UntypedFormGroup {
		if (this.otrisToNRelationItemContainer) {
			return this.otrisToNRelationItemContainer.relationItemFormGroup;
		}
		if (this.otrisToNRelationItem) {
			return this.otrisToNRelationItem.relationItemFormGroup;
		}
		if (this.otrisTo1RelationItem) {
			return this.otrisTo1RelationItem.relationItemFormGroup;
		}
		return this.formGroup;

		//return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.relationItemFormGroup :
		//	(this.otrisToNRelationItem ? this.otrisToNRelationItem.relationItemFormGroup : this.formGroup);
	}

	getRelationContextForChilds(): IRelationContext {
		if (this.otrisToNRelationItemContainer) {
			return this.otrisToNRelationItemContainer.getRelationContext();
		}
		if (this.otrisToNRelationItem) {
			return this.otrisToNRelationItem.getRelationContext();
		}
		if (this.otrisTo1RelationItem) {
			return this.otrisTo1RelationItem.getRelationContext();
		}
		return this.relationContext;

		//return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.getRelationContext() :
		//	(this.otrisToNRelationItem ? this.otrisToNRelationItem.getRelationContext() : this.relationContext);
	}

	get isContainerComponent(): boolean {
		return true;
	}

	getToNRelationSpec(spec: UIItemSpec): IToNRelationSpec | undefined {
		if (!(spec instanceof UIToNRelationSuitableContainerSpec)) {
			throw new Error('Invalid operation call: getToNRelationSpec()');
		}

		return (spec as UIToNRelationSuitableContainerSpec).toNRelationSpec;
	}

	hasRelationItems(item: any): boolean {
		let relationProp = this.getToNRelationSpec(item.spec)?.relationProperty;
		if (relationProp) {
			return item.pdObject[relationProp]?.length > 0;
		}
		return false;
	}

	get isRelationItemHostComponent(): boolean {
		return !!this.otrisToNRelationItemContainer || !!this.otrisToNRelationItem || !!this.otrisTo1RelationItem;
	}

	protected getGenericContainerType(spec: UIItemSpec): GenericContainerType {
		if (spec.type !== ItemTypeET.GenericContainer) {
			throw new Error('Invalid operation call');
		}
		return (spec as GenericContainerSpec).getVisibleContainerType(this.createEventProcessingContext());
	}

	//private _pdContainerTemplateContext: Map<PDContainerItem, [GenericContainerType, { $implicit: any }]> = new Map();

	/*protected getPDContainerTemplateContext(item: PDContainerItem): any {
		if (item.spec.type !== ItemTypeET.GenericContainer){
			return { $implicit: item };
		}
		let genericContSpec = item.spec as GenericContainerSpec;
		let genericContainerType = genericContSpec.getVisibleContainerType(this.createEventProcessingContext());
		if (this._pdContainerTemplateContext.has(item) && this._pdContainerTemplateContext.get(item)[0] === genericContainerType) {
			return this._pdContainerTemplateContext.get(item)[1];
		}
		let adaptedItem: PDContainerItem = {
			pdObject: item.pdObject,
			fg: item.fg,
			spec: genericContSpec.createContainerSpec(this.createEventProcessingContext())
		}
		let context = { $implicit: adaptedItem };
		this._pdContainerTemplateContext.set(item, [genericContainerType, context]);
		return context;
	}*/

	protected getPDContainerTemplateContext(item: PDContainerItem): any {
		if (item.spec.type !== ItemTypeET.GenericContainer){
			//throw new Error('Invalid operation call.')
			return { $implicit: item };
		}
		let genericContSpec = item.spec as GenericContainerSpec;
		let adaptedItem: PDContainerItem = {
			pdObject: item.pdObject,
			fg: item.fg,
			spec: genericContSpec.createContainerSpec(this.createEventProcessingContext())
		}
		return { $implicit: adaptedItem };
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Optional() @Self() private otrisToNRelationItemContainer: ToNRelationItemContainerDirective,
		@Optional() @Self() private otrisToNRelationItem: ToNRelationItemDirective,
		@Optional() @Self() private otrisTo1RelationItem: To1RelationItemDirective,
		private cdRef: ChangeDetectorRef,
		@Inject(IPDClassToken) private pdClass: IPDClass,
		private _hostElement: ElementRef
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);

		this.addSubscription(this.formHandler.uiTemplateRegistered$.subscribe(id => {
			switch (id) {
				case this.panelSpec.headerTemplateId:
					this.updateHeaderTemplate();
					break;
				/*case this.panelSpec.noRelationObjectTemplateId:
					this.updateNoRelationObjectTemplate();
					break;*/
			}
			/*if (id === this.panelSpec.headerTemplateId) {
				this.updateHeaderTemplate();
				this.updateNoRelationObjectTemplate();
			}*/
		}));
	}

	ngOnInit() {
		super.ngOnInit();
		this.updateHeaderTemplate();
		//this.updateNoRelationObjectTemplate();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		this.panels.changes.subscribe(res => {
			this.formHandler.updateUIState(); // todo: prüfen
			this.createChildComponentUIStateProviders(res as QueryList<PDPanelComponent>);
		});
		this.groupboxes.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDGroupboxComponent>);
			this.componentsChangedSubject$.next(ComponentTypeET.GroupBox);
		});
		this.multiSelects.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDMultiSelectComponent>);
		});
		this.textFields.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDTextFieldComponent>);
		});
		this.numericTextFields.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDNumericTextFieldComponent>);
		});
		this.checkBoxes.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDCheckboxComponent>);
		});
		this.radioButtons.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDRadioButtonComponent>);
		});
		this.inputSwitches.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDInputSwitchComponent>);
		});
		this.dateTimeFields.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDDateTimeFieldComponent>);
		});
		this.dropDowns.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDDropDownComponent>);
		});
		this.listview.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDListviewComponent>);
		});
		this.autoCompletes.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDAutoCompleteComponent>);
		});
		this.objectReferences.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDObjectReferenceComponent>);
		});
		this.radioButtonGroups.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDRadioButtonGroupComponent>);
		});
		this.tabViews.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDTabViewComponent>);
		});
		this.comboBoxes.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDComboBoxComponent>);
		});
		this.relationTabs.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDRelationTabComponent>);
		});
		this.buttons.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDButtonComponent>);
		});
		this.contentComps.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<UIContentComponent>);
		});
		this.infoFields.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDInfoFieldComponent>);
		});
		this.fileUploads.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDFileUploadComponent>);
		});
		this.drawers.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDDrawerComponent>);
		});
		this.relationGrids.changes.subscribe(res => {
			this.createChildComponentUIStateProviders(res as QueryList<PDRelationGridComponent>);
		});
		this.expansionPanels.changes.subscribe(
			res => {
				this.createChildComponentUIStateProviders(res as QueryList<PDExpansionPanelComponent>);
			}
		)

		this.createChildComponentUIStateProviders(this.panels);
		this.createChildComponentUIStateProviders(this.groupboxes);
		this.createChildComponentUIStateProviders(this.multiSelects);
		this.createChildComponentUIStateProviders(this.textFields);
		this.createChildComponentUIStateProviders(this.numericTextFields);
		this.createChildComponentUIStateProviders(this.checkBoxes);
		this.createChildComponentUIStateProviders(this.radioButtons);
		this.createChildComponentUIStateProviders(this.inputSwitches);
		this.createChildComponentUIStateProviders(this.dateTimeFields);
		this.createChildComponentUIStateProviders(this.dropDowns);
		this.createChildComponentUIStateProviders(this.listview);
		this.createChildComponentUIStateProviders(this.autoCompletes);
		this.createChildComponentUIStateProviders(this.objectReferences);
		this.createChildComponentUIStateProviders(this.radioButtonGroups);
		this.createChildComponentUIStateProviders(this.tabViews);
		this.createChildComponentUIStateProviders(this.comboBoxes);
		this.createChildComponentUIStateProviders(this.relationTabs);
		this.createChildComponentUIStateProviders(this.buttons);
		this.createChildComponentUIStateProviders(this.contentComps);
		this.createChildComponentUIStateProviders(this.infoFields);
		this.createChildComponentUIStateProviders(this.fileUploads);
		this.createChildComponentUIStateProviders(this.drawers);
		this.createChildComponentUIStateProviders(this.relationGrids);
		this.createChildComponentUIStateProviders(this.expansionPanels);

		this.componentsChangedSubject$.next(ComponentTypeET.GroupBox);
		this.componentsChangedSubject$.next(ComponentTypeET.ExpansionPanel);
		// ...

		/*if (this.formHandler.scrollIntoViewOnCreateEnabled) {
			this._hostElement.nativeElement.scrollIntoView();
		}*/
	}

	scrollIntoView(): void {
		// todo: siehe #49073
		//this._hostElement.nativeElement.scrollIntoView();
	};

	getContainerStyle(): ICssStyle {
		let style = super.getContainerStyle();
		let spec = this.panelSpec;
		if (spec.panelContentStyle) {
			return Object.assign({}, spec.panelContentStyle, style);
		}
		return style;
	}

	get panelSpec(): UIPanelSpec {
		if (!(this.uiItemSpec instanceof UIPanelSpec)) {
			throw Error('No UIPanelSpec assigned.');
		}
		return <UIPanelSpec>this.uiItemSpec;
	}

	relationObjectTracker(index: number, obj: IPDObject): string {
		return index + '|' + obj.objectId.oid;
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.panelSpec) {
			if (this.panelSpec.headerId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(this.panelSpec.headerId).subscribe(res => this.header = res);
			}
			else {
				this.header = this.panelSpec.header;
			}
		}
	}

	private updateHeaderTemplate() {
		if (this.panelSpec.headerTemplateId) {
			this._panelHeaderTemplate = this.formHandler.getUITemplate(this.panelSpec.headerTemplateId);
		}
	}


	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Panel;
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (!onlySelf) {
			if (this.textFields) {
				this.textFields.forEach(item => item.updateUIState(false));
			}
			if (this.numericTextFields) {
				this.numericTextFields.forEach(item => item.updateUIState(false));
			}
			if (this.checkBoxes) {
				this.checkBoxes.forEach(item => item.updateUIState(false));
			}
			if (this.radioButtons) {
				this.radioButtons.forEach(item => item.updateUIState(false));
			}
			if (this.inputSwitches) {
				this.inputSwitches.forEach(item => item.updateUIState(false));
			}
			if (this.dateTimeFields) {
				this.dateTimeFields.forEach(item => item.updateUIState(false));
			}
			if (this.dropDowns) {
				this.dropDowns.forEach(item => item.updateUIState(false));
			}
			if (this.listview) {
				this.listview.forEach(item => item.updateUIState(false));
			}
			if (this.multiSelects) {
				this.multiSelects.forEach(item => item.updateUIState(false));
			}
			if (this.autoCompletes) {
				this.autoCompletes.forEach(item => item.updateUIState(false));
			}
			if (this.objectReferences) {
				this.objectReferences.forEach(item => item.updateUIState(false));
			}
			if (this.groupboxes) {
				this.groupboxes.forEach(item => item.updateUIState(false));
			}
			if (this.radioButtonGroups) {
				this.radioButtonGroups.forEach(item => item.updateUIState(false));
			}
			if (this.relationTabs) {
				this.relationTabs.forEach(item => item.updateUIState(false));
			}
			if (this.comboBoxes) {
				this.comboBoxes.forEach(item => item.updateUIState(false));
			}
			if (this.tabViews) {
				this.tabViews.forEach(item => item.updateUIState(false));
			}
			if (this.panels) {
				this.panels.forEach(item => item.updateUIState(false));
			}
			if (this.buttons) {
				this.buttons.forEach(item => item.updateUIState(false));
			}
			if (this.contentComps) {
				this.contentComps.forEach(item => item.updateUIState(false));
			}
			if (this.infoFields) {
				this.infoFields.forEach(item => item.updateUIState(false));
			}
			if (this.fileUploads) {
				this.fileUploads.forEach(item => item.updateUIState(false));
			}
			if (this.drawers) {
				this.drawers.forEach(item => item.updateUIState(false));
			}
			if (this.relationGrids) {
				this.relationGrids.forEach(item => item.updateUIState(false));
			}
			if (this.expansionPanels) {
				this.expansionPanels.forEach(item => item.updateUIState(false));
			}
		}
	}

	reset(): void {
		if (this.textFields) {
			this.textFields.forEach(item => item.reset());
		}
		if (this.numericTextFields) {
			this.numericTextFields.forEach(item => item.reset());
		}
		if (this.checkBoxes) {
			this.checkBoxes.forEach(item => item.reset());
		}
		if (this.radioButtons) {
			this.radioButtons.forEach(item => item.reset());
		}
		if (this.inputSwitches) {
			this.inputSwitches.forEach(item => item.reset());
		}
		if (this.dateTimeFields) {
			this.dateTimeFields.forEach(item => item.reset());
		}
		if (this.dropDowns) {
			this.dropDowns.forEach(item => item.reset());
		}
		if (this.listview) {
			this.listview.forEach(item => item.reset());
		}
		if (this.multiSelects) {
			this.multiSelects.forEach(item => item.reset());
		}
		if (this.autoCompletes) {
			this.autoCompletes.forEach(item => item.reset());
		}
		if (this.objectReferences) {
			this.objectReferences.forEach(item => item.reset());
		}
		if (this.groupboxes) {
			this.groupboxes.forEach(item => item.reset());
		}
		if (this.radioButtonGroups) {
			this.radioButtonGroups.forEach(item => item.reset());
		}
		if (this.relationTabs) {
			this.relationTabs.forEach(item => item.reset());
		}
		if (this.comboBoxes) {
			this.comboBoxes.forEach(item => item.reset());
		}
		if (this.tabViews) {
			this.tabViews.forEach(item => item.reset());
		}
		if (this.panels) {
			this.panels.forEach(item => item.reset());
		}
		if (this.buttons) {
			this.buttons.forEach(item => item.reset());
		}
		if (this.contentComps) {
			this.contentComps.forEach(item => item.reset());
		}
		if (this.infoFields) {
			this.infoFields.forEach(item => item.reset());
		}
		if (this.fileUploads) {
			this.fileUploads.forEach(item => item.reset());
		}
		if (this.drawers) {
			this.drawers.forEach(item => item.reset());
		}
		if (this.relationGrids) {
			this.relationGrids.forEach(item => item.reset());
		}
		if (this.expansionPanels) {
			this.expansionPanels.forEach(item => item.reset());
		}
	}

	getComponents(type?: ComponentTypeET): IComponent[] {
		switch (type) {
			case ComponentTypeET.GroupBox:
				return this.groupboxes ? this.groupboxes.toArray() : [];

			case ComponentTypeET.ExpansionPanel:
				return this.expansionPanels ? this.expansionPanels.toArray() : [];

			// case ...
			// todo

			default:
				return this.childComponents;
		}
	}
}
