import { Injectable } from "@angular/core";
import { Params, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

/*export class AfterSuccessfullLoginEvent {

	get loginError(): boolean {
		return this._loginError;
	}

	setLoginError(): void {
		this._loginError = true;
	}

	private _loginError = false;

	constructor() {}
}*/

export interface BeforeLoginCallback {
	beforeLogin(queryParams: Params): Observable<boolean | UrlTree>;
}

export type LoginResult = {
	success: boolean;
	message?: string;
}

export interface AfterLoginCallback {
	afterLogin(user: string): Observable<LoginResult>;
}

@Injectable()
export abstract class ILoginService {
	abstract get loginFailed(): boolean;
	abstract checkBeforeLogin(queryParams: Params): Observable<boolean | UrlTree>;
	abstract login(): Observable<boolean>;
	abstract handleSuccessfulLogin(): Observable<LoginResult>;
	abstract setBeforeLoginCallback(callback: BeforeLoginCallback): void;
	abstract setAfterLoginCallback(callback: AfterLoginCallback): void;

	/**
	 * Beendet die Pings zur erhaltung der Session.
	 * `startKeepSessionAlivePing` wird nur nach einem erfolgreichen Ping ausgeführt.
	 * Es gibt allerdings mehrere Quellen, die es unterbrechen können/müssen. Zum
	 * Beispiel bei Fehlern bei Abfragen etc. deswegen ist die Methode von außen
	 * verfügbar.
	 */
	abstract endKeepSessionAlivePingInterval(): void;
}
