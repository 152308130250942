import {
	Component,
	ContentChild,
	ElementRef,
	Input,
	Optional,
	Self,
	TemplateRef,
	ViewChild,
	inject,
	OnDestroy
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of, switchMap, BehaviorSubject } from "rxjs";

import {
	ComponentGroupHeaderDirective,
	EventProcessingContextManagerService,
	FormHandlerService,
	PDExpansionPanelSpec,
	PDLikeComponent,
	ToNRelationItemContainerDirective,
	ToNRelationItemDirective,
	LocalizationServiceToken
} from "@otris/ng-core";
import {
	ComponentGroupHeaderContext,
	ComponentTypeET,
	IComponentUIState,
	IExpansionPanelComponent,
	IFormStatus,
	IPanelComponent,
	IRelationContext,
	IToNRelationItemContainer,
	ToNRelationSuitableContainerRelObjectCreatedHandler
} from "@otris/ng-core-types";

import { ExpansionPanelService } from "../../services/expansion-panel.service";

// todo: umbenennen in PDExpansionPanelComponent wg. eventueller Beziehungsfähigkeit?

@Component({
	selector: 'otris-pd-expansion-panel',
	template: `
		<kendo-expansionpanel
			[title]="'Titel'"
			[subtitle]="'Sub-Titel'"
			[expanded]="expanded"
			(expand)="onExpand()"
			(collapse)="onCollapse()"
 		>
			@if (hasHeaderTemplate) {
				<ng-template kendoExpansionPanelTitleDirective>
					<ng-container *ngTemplateOutlet="headerTemplate;context:headerContext"/>
				</ng-template>
			}
			@if (toNRelationItemContainer && toNRelationItemContainer.relationItemCount == 0) {
				<ng-container *ngTemplateOutlet="noRelationObjectTemplate"/>
			} @else {
				@if (expansionPanelSpec.content) {
					<otris-pd-panel
						#panel class="panel"
						[uiItemSpec]="expansionPanelSpec.content"
						[pdObject]="pdObject"
						[formGroup]="getFormGroupForChildren()"
						[relationContext]="getRelationContextForChildren()"
					/>
				}
			}
		</kendo-expansionpanel>
	`,
	styles: [`
	`]
})
export class PDExpansionPanelComponent extends PDLikeComponent implements IExpansionPanelComponent, OnDestroy {

	// ist das notwendig?
	@ContentChild(ComponentGroupHeaderDirective) componentGroupHeaderDirective: ComponentGroupHeaderDirective;

	@ViewChild('panel') set panelComponent(comp: IPanelComponent) {
		if (comp != this._panelComponent) {
			this._panelComponent = comp;
			if (this._panelComponent) {
				this.createChildComponentUIStateProviders([this._panelComponent]);
			}
		}
	}

	@Input() expanded = false;

	get headerContext(): ComponentGroupHeaderContext {
		return this._headerContext;
	}

	private _headerContext: ComponentGroupHeaderContext;

	get panelComponent(): IPanelComponent {
		return this._panelComponent;
	}

	private _panelComponent: IPanelComponent;

	get header$(): Observable<string> {
		return this._localizationService.changeHandler.pipe(
			switchMap(() => {
				if (this.expansionPanelSpec) {
					if (this.expansionPanelSpec.headerId) {
						return this._localizationService.getStringFromId(this.expansionPanelSpec.headerId);
					}
					if (this.expansionPanelSpec.headerIdHandler) {
						return this._localizationService.getStringFromId(this.expansionPanelSpec.headerIdHandler(this, this.createEventProcessingContext()));
					}
					return of(this._localizationService.getFromErgNameData(this.expansionPanelSpec.header));
				}
				return of('No title text');
			})
		);
	}

	get isRelationItemHostComponent(): boolean {
		return !!this.otrisToNRelationItemContainer || !!this.otrisToNRelationItem;
	}

	private _localizationService = inject(LocalizationServiceToken);

	get relationObjectCreatedHandler(): ToNRelationSuitableContainerRelObjectCreatedHandler | undefined {
		return this.expansionPanelSpec.relationObjectCreatedHandler;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Optional() @Self() public otrisToNRelationItemContainer: ToNRelationItemContainerDirective,
		@Optional() @Self() public otrisToNRelationItem: ToNRelationItemDirective,
		private _hostElement: ElementRef,
		private _expansionPanelService: ExpansionPanelService
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);

		this.addSubscription(this.formHandler.uiTemplateRegistered$.subscribe(id => {
			if (id === this.expansionPanelSpec.headerTemplateId) {
				this.updateHeaderTemplate();
			}
			else if (id === this.expansionPanelSpec.noRelationObjectTemplateId) {
				this.updateNoRelationObjectTemplate();
			}
		}));

		if (otrisToNRelationItemContainer) {
			otrisToNRelationItemContainer.relationObjectCreated$.subscribe(value => {
				let expansionPanel = (value as IExpansionPanelComponent);
				if (expansionPanel.relationObjectCreatedHandler) {
					let ctx = this.createEventProcessingContext();
					expansionPanel.relationObjectCreatedHandler(expansionPanel, ctx);
				}
				//(value as IExpansionPanelComponent).expanded = true;
			});
		}
	}

	private _expansionPanelExpandedNotifier: BehaviorSubject<boolean> | undefined;

	ngOnInit(): void {
		super.ngOnInit();

		//console.log(`ngOnInit: ${this.isHidden}, ${this.id}`);

		this._headerContext = {
			headerLabel$: this.header$,
			componentGroup: {
				toNRelationItemContainer: this.toNRelationItemContainer,
				rootComponent: this
			},
			customData: {} //this._customHeaderData
		};

		if (this.expansionPanelSpec.expanded === true || this.expansionPanelSpec.expanded === false) {
			this.expanded = this.expansionPanelSpec.expanded;
		}
		else if (this.expansionPanelSpec.expandedProvider) {
			let ctx = this.createEventProcessingContext();
			let res = this.expansionPanelSpec.expandedProvider(this, ctx);
			if (res !== undefined) {
				this.expanded = res;
			}
		}

		this.updateHeaderTemplate();
		this.updateNoRelationObjectTemplate();

		this._expansionPanelExpandedNotifier = this._expansionPanelService.registerNewExpansionPanelHandler(this.id, this.expanded);
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		//console.log(`ngAfterViewInit: ${this.isHidden}, ${this.id}`);
	}

	ngOnDestroy() {
		this._expansionPanelService.deleteExpansionPanelhandler(this.id);
	}

	onExpand() {
		this._expansionPanelExpandedNotifier.next(true);
	}

	onCollapse() {
		this._expansionPanelExpandedNotifier.next(false);
	}

	protected onUIStateChanged(oldState: IComponentUIState, newState: IComponentUIState): void {
		return super.onUIStateChanged(oldState, newState);
	}

	protected get hasHeaderTemplate(): boolean {
		return !!this.expansionPanelSpec.headerTemplateId;
	}

	private _headerTemplate: TemplateRef<any>;

	get headerTemplate(): TemplateRef<any> {
		return this.componentGroupHeaderDirective ? this.componentGroupHeaderDirective.template : this._headerTemplate;
	}

	private _noRelationObjectTemplate: TemplateRef<any>;

	get noRelationObjectTemplate(): TemplateRef<any> {
		return this._noRelationObjectTemplate;
	}

	protected get expansionPanelSpec(): PDExpansionPanelSpec {
		if (!(this.uiItemSpec instanceof PDExpansionPanelSpec)) {
			throw Error('No PDExpansionPanelSpec assigned.');
		}
		return <PDExpansionPanelSpec>this.uiItemSpec;
	}

	scrollIntoView(): void {
		this._hostElement.nativeElement.scrollIntoView();
	};

	getFormGroupForChildren(): UntypedFormGroup {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.relationItemFormGroup :
			(this.otrisToNRelationItem ? this.otrisToNRelationItem.relationItemFormGroup : this.formGroup);
	}

	getRelationContextForChildren(): IRelationContext {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.getRelationContext() :
			(this.otrisToNRelationItem ? this.otrisToNRelationItem.getRelationContext() : this.relationContext);
	}

	/*protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.expansionPanelSpec) {
			if (this.expansionPanelSpec.headerId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(this.expansionPanelSpec.headerId).subscribe(res => this.header = res);
			}
			else {
				this.header = this._localizationService.getFromErgNameData(this.expansionPanelSpec.header);
			}
		}
	}*/

	protected createControl(): AbstractControl | undefined {
		return undefined;
	}

	private updateHeaderTemplate() {
		if (this.expansionPanelSpec.headerTemplateId) {
			this._headerTemplate = this.formHandler.getUITemplate(this.expansionPanelSpec.headerTemplateId);
		}
	}

	private updateNoRelationObjectTemplate() {
		if (this.expansionPanelSpec.noRelationObjectTemplateId) {
			this._noRelationObjectTemplate = this.formHandler.getUITemplate(this.expansionPanelSpec.noRelationObjectTemplateId);
		}
	}

	get isContainerComponent(): boolean {
		return true;
	}

	get propertyName(): string | undefined {
		return undefined;
	}

	get propertyRelationPath(): string | undefined {
		return undefined;
	}

	get control(): AbstractControl | undefined {
		return undefined;
	}

	get toNRelationItemContainer(): IToNRelationItemContainer | undefined {
		return this.otrisToNRelationItemContainer ? this.otrisToNRelationItemContainer.toNRelationItemContainer : undefined;
	}

	getFormControlStatus(): IFormStatus {
		if (this.panelComponent) {
			return this.panelComponent.getFormControlStatus();
		}
		return <IFormStatus>{ pristine: true, touched: false, valid: true };
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.ExpansionPanel;
	}

	reset(): void {
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}
}
