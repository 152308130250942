import { Injectable, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, Observable, Subscription } from "rxjs";
import { IComponent, ComponentTypeET, IFormStatus, MandatoryCheckCallback, IFormHandlerService, CustomEvent, IPDObject, IPDComponent, ForeignComponentEventHandler } from '@otris/ng-core-types';

@Injectable()
export class FormHandlerService implements IFormHandlerService {
	private _formStatusChanges: Subject<IFormStatus> = new Subject();

	private _registeredComponents: Map<string, Map<ComponentTypeET, [IComponent, Subscription, Subscription]>> = new Map();

	private _form: UntypedFormGroup;

	get formStatusChanges(): Observable<IFormStatus> {
		return this._formStatusChanges.asObservable();
	}

	get formStatus(): IFormStatus {
		return <IFormStatus>{
			valid: this._form ? this._form.valid : false,
			pristine: this._form ? this._form.pristine : true,
			touched: this._form ? this._form.touched: false
		};
	}

	private _registeredUITemplates: Map<string, TemplateRef<any>> = new Map<string, TemplateRef<any>>();

	private _uiTemplateRegistered$: Subject<string> = new Subject();

	get uiTemplateRegistered$(): Observable<string> {
		return this._uiTemplateRegistered$.asObservable();
	}

	private _uiStateUpdatesSuspended = 0;

	private _foreigenComponentEventSpec: Map<string, Map<string, [IComponent, ForeignComponentEventHandler][]>> = new Map();

	constructor() { }

	registerForm(form: UntypedFormGroup) {
		if (this._form) {
			throw new Error('Form already registered.');
		}
		this._form = form;
		this._form.statusChanges.subscribe(() => {
			this.emitFormStatusChangesEvent();
		});
		this._form.valueChanges.subscribe(() => {
			this.emitFormStatusChangesEvent();
		});
	}

	registerComponent(comp: IComponent, compId?: string) {
		let id = compId ? compId : comp.id;
		if (id) {
			if (!this._registeredComponents.has(id)) {
				this._registeredComponents.set(id, new Map<ComponentTypeET, [IComponent, Subscription, Subscription]>());
			}
			
			let stateSubscriptions = comp.subscribeUIStateEvents((source) => {
				this.updateUIState();
			});
		
			// ComponentEvents
			let compEventSubscriptions = comp.subscribeComponentEvents((source, event) => { //oder doch comp?
				this.handleForeignComponentEvent(source, event);
			});
			
			
			let map = this._registeredComponents.get(id);
			map.set(comp.componentType, [comp, stateSubscriptions, compEventSubscriptions]);

			if (comp.foreignComponentEvents) {
				comp.foreignComponentEvents.forEach(spec => {
					/*let eventSubscriptions = foreignComp.subscribeComponentEvents((source, event) => { oder doch comp?
						this.handleForeignComponentEvent(source, event);
					});*/
					//if (eventSubscriptions) {
						if (!this._foreigenComponentEventSpec.has(spec.foreignCompId)) {
							this._foreigenComponentEventSpec.set(spec.foreignCompId, new Map());
						}
						let foreignCompSpec = this._foreigenComponentEventSpec.get(spec.foreignCompId);
						if (!foreignCompSpec.has(spec.eventName)) {
							foreignCompSpec.set(spec.eventName, []);
						}
						let eventSpec = foreignCompSpec.get(spec.eventName);
						eventSpec.push([comp, spec.eventHandler/*, eventSubscriptions*/]);
					//}
				});
			}
		}
	}

	unregisterComponent(comp: IComponent, compId?: string) {
		let id = compId ? compId : comp.id;
		if (id) {
			if (this._registeredComponents.has(id)) {
				let map = this._registeredComponents.get(id);
				if (map.has(comp.componentType)) {
					let stateSubscription = map.get(comp.componentType)[1];
					if (stateSubscription) {
						stateSubscription.unsubscribe();
					}
					let compEventsSubscription = map.get(comp.componentType)[2];
					if (compEventsSubscription) {
						compEventsSubscription.unsubscribe();
					}
					map.delete(comp.componentType);
					if (map.size == 0) {
						this._registeredComponents.delete(id);
					}
				}
			}

			// ForeignComponentEvents
			if (comp.foreignComponentEvents) {
				comp.foreignComponentEvents.forEach(spec => {
					let foreignCompSpec = this._foreigenComponentEventSpec.get(spec.foreignCompId);
					if (foreignCompSpec) {
						let eventSpec = foreignCompSpec.get(spec.eventName);
						if (eventSpec) {
							let iComp = eventSpec.findIndex(val => val[0] === comp);
							if (iComp) {
								//eventSpec[iComp][2].unsubscribe()
								eventSpec.splice(iComp, 1);
							}
						}
					}
				});
			}
		}
	}

	getComponent<T extends IComponent>(id: string, type?: ComponentTypeET): T {
		if (this._registeredComponents.has(id)) {
			let map = this._registeredComponents.get(id);
			if (!type) {
				return map.size == 1 ? <T>map.values().next().value[0] : undefined;
			}
			if (map.has(type)) {
				return <T>map.get(type)[0];
			}
		}
		return undefined;
	}

	getIndexInRelation(comp: IComponent): number | undefined {
		let parts = comp.id.split('.');
		if (parts.length < 3) {
			return undefined;
		}
		let index = Number.parseInt(parts[parts.length - 2]);
		if (!Number.isNaN(index)) {
			return index;
		}
		return undefined;
	}

	getComponentFromRelationItemContainer<T extends IComponent>(relatedComp: IComponent | string, propId: string, type?: ComponentTypeET): T | undefined {
		let id = '';
		if (typeof(relatedComp) === 'string') {
			id += relatedComp + '.' + propId;
		}
		else {
			// Vollständige ID zum relatedComp
			let parts = relatedComp.id.split('.');
			if (parts.length <= 1) {
				return undefined;
			}
			
			// Pfad zum relatedComp
			parts.slice(undefined, -1).forEach(p => id += p + '.');

			// Das gesuchte Property hinzufügen
			id += propId;
		}
		return this.getComponent(id, type);
	}

	getComponentFromAncestorRelationItemContainer<T extends IComponent>(relatedComp: IComponent | string, propId: string, level = 1, type?: ComponentTypeET): T | undefined {
		// Bsp "to_DatenkategorieContainer.0.to_DatenweiterleitungContainer.0.relatedCompID"
		let id = '';
		if (typeof(relatedComp) === 'string') {
			id += relatedComp + '.' + propId;
		}
		else {
			// Vollständige ID zum relatedComp
			let parts = relatedComp.id.split('.');

			// Für erste eigene Komponente "3"; Weitere Ebenen "2" (relation und index)
			// "to_DatenweiterleitungContainer.0.relatedCompID" -> 3
			// "to_DatenkategorieContainer.0" -> 2
			if (parts.length < level * 2 + 3) {
				return undefined;
			}
			
			// Pfad zum relatedComp
			// ID von relatedComp weg schneiden -> 1
			// Ebenen weg schneiden -> 2 (prop und index) * level
			// Das ganze von rechts gezählt -> -1
			parts.slice(undefined, (1 + level * 2) * -1).forEach(p => id += p + '.');

			// Das gesuchte Property hinzufügen
			id += propId;
		}
		return this.getComponent(id, type);
	}

	getAllComponentsForRelation<T extends IComponent>(relatedComp: IComponent, prop: string, type?: ComponentTypeET): T[] {
		let parts = relatedComp.id.split('.');
		if (parts.length <= 2) {
			throw new Error('relatedComp is not within a relation');
		}
		let comps = [];
		let basePath = '';
		parts.slice(undefined, -2).forEach(p => basePath += p + '.');
		let index = 0;
		let comp;
		do {
			let comp = this.getComponent(`${basePath}${index++}.${prop}`, type);
			if (comp) {
				comps.push(comp);
			}
		} while(comp);
		return comps;
	}

	updateUIState(): void {
		if (this.uiStateUpdatesSuspended) {
			return;
		}
		this._registeredComponents.forEach(map => {
			map.forEach(comp => {
				comp[0].updateUIState()
			});
		});
	}

	registerUITemplate(name: string, templ: TemplateRef<any>): void  {
		if (this._registeredUITemplates.has(name)) {
			throw new Error(`UI template with name '${name}' already registered.`);
		}
		this._registeredUITemplates.set(name, templ);
		this._uiTemplateRegistered$.next(name);
	}

	hasUITemplate(name: string): boolean {
		return this._registeredUITemplates.has(name);
	}

	getUITemplate(name: string): TemplateRef<any> | undefined {
		if (this._registeredUITemplates.has(name)) {
			return this._registeredUITemplates.get(name);
		}
		return undefined;
	}

	suspendUIStateUpdates(): void {
		this._uiStateUpdatesSuspended++;
	}

	resumeUIStateUpdates(update: boolean): void {
		if (this._uiStateUpdatesSuspended == 0) {
			throw new Error('UIState updates not suspended.');
		}
		this._uiStateUpdatesSuspended--;
		if (this._uiStateUpdatesSuspended == 0) {
			this.updateUIState();
		}
	}

	get uiStateUpdatesSuspended(): boolean {
		return this._uiStateUpdatesSuspended > 0;
	}

	emitCustomEvent(customEvent: CustomEvent<any>): void {
		this._registeredComponents.forEach(map => {
			map.forEach(comp => {
				comp[0].handleCustomEvent(customEvent);
			});
		});
	}

	emitFormStatusChangesEvent(): void {
		this._formStatusChanges.next(<IFormStatus>{
			valid: this._form.valid,
			pristine: this._form.pristine,
			touched: this._form.touched
		});
	}

	getRelationItemHostComponent(obj: IPDObject): IPDComponent | undefined {
		let hostComp: IPDComponent;
		this._registeredComponents.forEach(map => {
			map.forEach(comp => {
				if (comp[0].isRelationItemHostComponent && (<IPDComponent>comp[0]).pdObject === obj) {
					hostComp = <IPDComponent>comp[0];
				}
			});
		});
		return hostComp;
	}

	private handleForeignComponentEvent(foreignComp: IComponent, eventName: string): void {
		let foreignCompSpec = this._foreigenComponentEventSpec.get(foreignComp.id);
		if (foreignCompSpec) {
			let eventSpec = foreignCompSpec.get(eventName);
			if (eventSpec) {
				eventSpec.forEach(spec => {
					spec[1](spec[0], foreignComp, eventName, spec[0].createEventProcessingContext());
				});
			}
		}
	}
}
