import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TypeET } from '@otris/ng-core-types';
import { IClassMetaData, IPropertyMetaData, IRelationMetaData, IStringPropertyMetaData, ShortDescriptionFormatET } from '@otris/ng-core-shared';
import { LocalizationService, LocalizationServiceToken } from './localization.service';

/*@Injectable()
export abstract class IMetaObjectFactory {
	abstract registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void;

	abstract createOrGetMetaObject(className: string, id?: string): IPDObjectMeta | undefined;
}*/

export type DefaultShortDescriptionFormat = () => Observable<ShortDescriptionFormatET>;

@Injectable()
export class MetaDataService {

	// Unterschied zu metaObjects? Bitte beschreiben - IClassMetaData | IPDObjectMeta
	private _metaDataMap = new Map<string, IClassMetaData>();

	private _metaDataChangedSubject$ = new Subject<string>();

	//private _metaObjects = new Map<string, IPDObjectMeta | [string, IPDObjectMeta][]>();

	private _defaultShortDescriptionFormatCallback: DefaultShortDescriptionFormat;

	private _classNameAdapter: (cls: string) => string;

	constructor(/*private _metaObjectFactory: IMetaObjectFactory,*/
		@Inject(LocalizationServiceToken) private localizationService: LocalizationService) {}

	getMetaDataChanged$(className: string): Observable<IClassMetaData | undefined> {
		return this._metaDataChangedSubject$.pipe(
			filter(res => res === className),
			map(() => this._metaDataMap.has(className) ? this._metaDataMap.get(className) : undefined )
		);
	}

	addMetaData(metaData: IClassMetaData): void {
		if (!metaData.className) {
			throw new Error(`No className in IClassMetaData object.`);
		}
		/*if (!this._metaDataMap.has(metaData.className)) {
			this._metaDataMap.set(metaData.className, metaData)
		}
		else {
			this.updateClassMetaData(metaData);
		}*/
		let updatedClasses = [];
		this.updateClassMetaData(metaData, updatedClasses);
		updatedClasses.forEach(cls => this._metaDataChangedSubject$.next(cls));
	}

	/*removeMetaData(className: string): void {
		if (!this._metaDataMap.has(className)) {
			throw new Error(`No meta data for class ${className} registered.`);
		}
		this._metaDataMap.delete(className);
		this._metaDataChangedSubject$.next(className);
	}*/

	clearMetaData(): void {
		let classes = Array.from(this._metaDataMap.keys());
		this._metaDataMap.clear();
		classes.forEach(cls => this._metaDataChangedSubject$.next(cls));
	}

	getMetaData(className: string): IClassMetaData | undefined {
		return this._metaDataMap.has(className) ? this._metaDataMap.get(className) : undefined;
	}

	/*registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void {
		this._metaObjectFactory.registerMetaObject(className, id, metaObj);
		this.storeNewMetaObject(metaObj, className, id);
	}*/

	/*getMetaObject(className: string, id?: string): IPDObjectMeta {
		let currentMetaObj = this._metaObjects.get(className);
		if (currentMetaObj) {
			//let metaObj = this._metaObjects.get(className);
			if (Array.isArray(currentMetaObj)) {
				if (!id) {
					throw new Error('No id for meta object specified');
				}
				let metaObjForId = currentMetaObj.find(m => m[0] === id);
				if (metaObjForId) {
					return metaObjForId[1];
				}
			}
			else {
				return currentMetaObj;
			}
		}

		let newMetaObj = this._metaObjectFactory.createOrGetMetaObject(className, id);
		if (newMetaObj) {
			this.storeNewMetaObject(newMetaObj, className, id);
			return newMetaObj;
		}

		return DefaultPDObjectMeta.instance;
	}*/

	/*hasMetaObject(className: string, id?: string): boolean {
		let currentMetaObj = this._metaObjects.get(className);
		if (currentMetaObj) {
			if (Array.isArray(currentMetaObj)) {
				if (!id) {
					throw new Error('No id for meta object specified');
				}
				let metaObjForId = currentMetaObj.find(m => m[0] === id);
				if (metaObjForId) {
					return true;
				}
			}
			else {
				return true;
			}
		}
		return false;
	}*/

	/*setMetaObject(className: string, metaObj: IPDObjectMeta): void {
		this._metaObjects.set(className, metaObj);
	}*/

	getClassErgName(cls: string): Observable<string> {
		let metaData = this.getMetaData(cls);
		if (metaData && metaData.classErgName) {
			let ergName = metaData.classErgName.find(n => n[0] == this.localizationService.currentLanguage.code);
			if (ergName) {
				return of(ergName[1]);
			}
		}
		return this.localizationService.getClassErgName(cls);
	}

	getEnumLiteralErgName(enumName: string, literal: number | string): Observable<string> {
		//let enumName = typeof name === 'string' ? name : (<new () => T>name).name;
		return this.localizationService.getEnumLiteralErgName(enumName, literal);
	}

	getDefaultShortDescriptionFormat(): Observable<ShortDescriptionFormatET> {
		if (!this._defaultShortDescriptionFormatCallback) {
			throw new Error('DefaultShortDescriptionFormatCallback not set.');
		}

		return this._defaultShortDescriptionFormatCallback();
		//return this.privacyBackendService.getDefaultShortDescriptionFormat();
	}

	setDefaultShortDescriptionFormatCallback(cb: DefaultShortDescriptionFormat): void {
		this._defaultShortDescriptionFormatCallback = cb;
	}

	getRelationClass(basisClass: string, role: string): string | undefined {
		if (!this._metaDataMap.has(basisClass)) {
			return undefined;
		}

		let clsMeta = this._metaDataMap.get(basisClass);
		if (!clsMeta.relations) {
			return undefined;
		}

		let relMeta = clsMeta.relations.find(r => r.relationName === role);
		return relMeta ? relMeta.className : undefined;
	}

	set classNameAdapter(adapter: (cls: string) => string) {
		this._classNameAdapter = adapter;
	}

	// TODO: classNameAdapter für properties -- Ticket #51785

	/*private storeNewMetaObject(newMetaObj: IPDObjectMeta, className: string, id: string): void {
		if (id) {
			let currentMetaObj = this._metaObjects.get(className);
			if (currentMetaObj) {
				if (!Array.isArray(currentMetaObj)) {
					throw new Error('Invalid meta object type.')
				}
				let oldMetaObject = currentMetaObj.find(m => m[0] === id);
				if (oldMetaObject) {
					throw new Error(`Meta object for class ${className} with id ${id} already stored.`)
				}
				currentMetaObj.push([id, newMetaObj]);
			}
			else {
				this._metaObjects.set(className, [[id, newMetaObj]]);
			}
		}
		else {
			this._metaObjects.set(className, newMetaObj);
		}
	}*/

	private updateClassMetaData(newMetaData: IClassMetaData, updatedClasses: string[]): void {

		let className = this._classNameAdapter ? this._classNameAdapter(newMetaData.className) : newMetaData.className;

		if  (!updatedClasses.includes(className)) {
			updatedClasses.push(className);
		}
		let current = this._metaDataMap.get(className);
		if (!current) {
			current = <IClassMetaData>{
				className: className
			}
			this._metaDataMap.set(className, current);
		}
		if (newMetaData.classErgName) {
			current.classErgName = newMetaData.classErgName;
		}
		if (newMetaData.properties) {
			this.updatePropertyMetaData(newMetaData.properties, current);
		}
		if (newMetaData.relations) {
			this.updateRelationMetaData(newMetaData.relations, current, updatedClasses);
		}
	}

	private updatePropertyMetaData(props: IPropertyMetaData[], current: IClassMetaData): void {
		if (!props || props.length == 0) {
			return;
		}
		if (!current.properties || current.properties.length == 0) {
			current.properties = props;
			return;
		}
		let currentProps = current.properties.reduce((map, prop) => map.set(prop.name, prop), new Map<string, IPropertyMetaData>());
		props.forEach(prop => {
			if (prop.accessRights && prop.accessRights.write === false) {
				prop.mandatory = false;
			}

			if (prop.name.startsWith('_')) {
				prop.name = prop.name.substr(1);
			}

			if (!currentProps.has(prop.name)) {
				current.properties.push(prop);
			}
			else {
				let currentProp = currentProps.get(prop.name);
				if (prop.accessRights) {
					currentProp.accessRights = prop.accessRights;
				}
				if (prop.mandatory !== undefined) {
					currentProp.mandatory = prop.mandatory;
				}
				if (prop.label) {
					currentProp.label = prop.label;
				}
				if (prop.shortDescription) {
					currentProp.shortDescription = prop.shortDescription;
				}
				if (prop.type == TypeET.String) {
					let stringProp: IStringPropertyMetaData = prop;
					if (stringProp.maxLength !== undefined) {
						(<IStringPropertyMetaData>currentProp).maxLength = stringProp.maxLength;
					}
				}
			}
		});
	}

	private updateRelationMetaData(relations: IRelationMetaData[], current: IClassMetaData, updatedClasses: string[]): void {
		if (!relations || relations.length == 0) {
			return;
		}
		if (!current.relations) {
			current.relations = [];
		}
		let currentRels = current.relations.reduce((map, rel) => map.set(rel.relationName, rel), new Map<string, IRelationMetaData>());
		relations.forEach(rel => {
			if (rel.accessRights && rel.accessRights.change === false) {
				rel.mandatory = false;
			}

			if (rel.className) {
				this.updateClassMetaData(rel, updatedClasses);
			}
			if (!currentRels.has(rel.relationName)) {
				let relNew = Object.assign(<IRelationMetaData>{}, rel);
				relNew.relations = undefined;
				relNew.properties = undefined;
				current.relations.push(relNew);
			}
			else {
				let currentRel = currentRels.get(rel.relationName);
				if (rel.accessRights) {
					currentRel.accessRights = rel.accessRights;
				}
			}
		});
	}
}

@Injectable({ providedIn: 'root', useClass: MetaDataService})
export abstract class IMetaDataService {
	abstract getMetaDataChanged$(className: string): Observable<IClassMetaData | undefined>;

	abstract addMetaData(metaData: IClassMetaData): void;

	//abstract removeMetaData(className: string): void;

	abstract clearMetaData(): void;

	abstract getMetaData(className: string): IClassMetaData | undefined;

	//abstract hasMetaObject(className: string, id?: string): boolean;

	//abstract getMetaObject(className: string, id?: string): IPDObjectMeta;

	//abstract registerMetaObject(className: string, id: string, metaObj: IPDObjectMeta): void;

	//abstract setMetaObject(className: string, metaObj: IPDObjectMeta): void;

	abstract getClassErgName(cls: string): Observable<string>;

	abstract getDefaultShortDescriptionFormat(): Observable<ShortDescriptionFormatET>;

	abstract getEnumLiteralErgName(enumName: string, literal: number | string): Observable<string>;

	abstract setDefaultShortDescriptionFormatCallback(cb: DefaultShortDescriptionFormat): void;

	abstract getRelationClass(basisClass: string, role: string): string | undefined;

	abstract set classNameAdapter(adapter: (cls: string) => string);
}
