import { ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ShortDescriptionFormatET } from './types';
import { ErgName, LanguageCodeET, TypeET } from '@otris/ng-core-types';

export enum PropertyAccessRightET {
	Write
}

export enum RelationAccessRightET {
	Create,
	Delete,
	Change,
	Edit
}

// JSON-Schema: schemas/property-access-rights-schema.json
export interface IPropertyAccessRights {
	write?: boolean;
}

// JSON-Schema: schemas/property-meta-data-schema.json
export interface IPropertyMetaData {
	name: string;
	type?: TypeET;
	accessRights?: IPropertyAccessRights;
	mandatory?: boolean;
	label?: ErgName;
	shortDescription?: ErgName;
	//type: TypeET; // Typ ist wohl eher implizit
	//declarationClass: string; // auch 
	// todo prüfen was von IPDObjectMeta noch hier her gehört
}

export interface IStringPropertyMetaData extends IPropertyMetaData {
	maxLength?: number;
}

// JSON-Schema: schemas/relation-access-rights-schema.json
export interface IRelationAccessRights {
	create?: boolean;
	delete?: boolean;
	change?: boolean;
	edit?: boolean;
}

// JSON-Schema: schemas/relation-meta-data-schema.json
// + Properties aus class-meta-data-schema.json kopiert!
export interface IRelationMetaData extends IClassMetaData {
	relationName: string;
	accessRights?: IRelationAccessRights;
	mandatory?: boolean;
}

// JSON-Schema: schemas/class-meta-data-schema.json
// relation-meta-data-schema.json kopiert hiervon!
export interface IClassMetaData {
	className?: string; // optional wegen IRelationMetaData!
	classErgName?: ErgName;
	properties?: IPropertyMetaData[];
	relations?: IRelationMetaData[];
}

export function cloneClassMetaData(cls1: IClassMetaData): IClassMetaData {

	let cloneErgName = (ergName1: ErgName) => {
		let ergName2: ErgName = [];
		ergName1.forEach(item1 => {
			ergName2.push([item1[0], item1[1]]);
		});
		return ergName2;
	};

	let cls2 = Object.assign({}, cls1);
	if (cls1.classErgName) {
		cls2.classErgName = cloneErgName(cls1.classErgName);
	}
	
	if (cls1.properties) {
		cls2.properties = [];
		cls1.properties.forEach(prop1 => {
			let prop2 = Object.assign({}, prop1);
			if (prop1.accessRights) {
				prop2.accessRights = Object.assign({}, prop1.accessRights);
			}
			if (prop1.label) {
				prop2.label = cloneErgName(prop1.label);
			}
			if (prop1.shortDescription) {
				prop2.shortDescription = cloneErgName(prop1.shortDescription);
			}
			cls2.properties.push(prop2);
		});
	}

	if (cls1.relations) {
		cls2.relations = [];
		cls1.relations.forEach(rel1 => {
			let rel2 = Object.assign({}, rel1);
			if (rel1.accessRights) {
				rel2.accessRights = Object.assign({}, rel1.accessRights);
			}
			cls2.relations.push(rel2);
		});
	}

	return cls2;
}

/*function cloneErgName(ergName1: ErgName): ErgName {
	let ergName2: ErgName = [];
	ergName1.forEach(item1 => {
		ergName2.push([item1[0], item1[1]]);
	});
	return ergName2;
}*/

class RelationMeta {

	constructor(private _relationMetaData: IRelationMetaData) {
	}

	get relationMetaData(): IRelationMetaData {
		return this._relationMetaData;
	}

	get relationName(): string {
		return this._relationMetaData.relationName;
	}

	get accessRights(): IRelationAccessRights | undefined {
		return this._relationMetaData.accessRights;
	}
}

// todo: umbenennen in PDObjectMetaAbstract und neues interface IPDObjectMeta
// Probelem: getValidators(): ValidatorFn => Angular Abhängigkeit
export abstract class IPDObjectMeta {

	private _relations = new Map<string, RelationMeta>();

	private _properties = new Map<string, IPropertyMetaData>();

	private _classMetaData: IClassMetaData;

	private _metaDataChangedSubject$ = new Subject<void>();

	private _metaDataChanged$: Observable<void>;

	get metaDataChanged$(): Observable<void> {
		if (!this._metaDataChanged$) {
			this._metaDataChanged$ = this._metaDataChangedSubject$.asObservable();
		}
		return this._metaDataChanged$;
	}

	constructor() {
	}

	abstract get propertyNames(): string[];
	abstract get className(): string | undefined;
	abstract getPropertyErgname(prop: string): Observable<string>;
	abstract getPropertyType(propName: string): TypeET;
	abstract getEnumPropertyEnumName(propName: string): string | undefined;
	abstract getPropertyDeclarationClass(propName: string): string | undefined;
	abstract isMandatory(prop: string): boolean | undefined;
	abstract getMaxStringLength(prop: string): Observable<number | undefined>;
	//abstract getValidators(propName: string): ValidatorFn[] | undefined;
	abstract hasShortDescription(prop: string): boolean;
	abstract getShortDescription(prop: string): Observable<string>;
	abstract getShortDescriptionId(prop: string): string | undefined;
	abstract getShortDescriptionFormat(prop: string): Observable<ShortDescriptionFormatET>;

	abstract getRelationClassName(relation: string): string | undefined;

	/**
	 * Besorgt sich mithilfe des localizationService über getClassString(class, id) den gewünschten String
	 * Schema: "class.string-id"
	 * @param id klasse mit id - Punkt getrennt
	 */
	abstract getStringFromId(id: string): Observable<string>;

	isRelationProperty(prop: string): boolean {
		return this.getPropertyType(prop) == TypeET.RelationTo1 || this.getPropertyType(prop) == TypeET.RelationToN;
		// || this.getPropertyType(prop) == TypeET.RelationToNEditable;
	}

	getRelationMeta(relProp: string): IRelationMetaData | undefined {
		if (relProp.startsWith('_')) {
			relProp = relProp.substr(1);
		}
		return this._relations.has(relProp) ? this._relations.get(relProp).relationMetaData : undefined;		
	}

	getPropertyMeta(prop: string): IPropertyMetaData | undefined {
		if (prop.startsWith('_')) {
			prop = prop.substr(1);
		}
		let propMeta = this._properties.has(prop) ? this._properties.get(prop) : undefined;
		if (propMeta) {
			propMeta.type = this.getPropertyType(prop);
		}
		return propMeta;
	}

	getClassErgName(lang: LanguageCodeET): string | undefined {
		if (this._classMetaData && this._classMetaData.classErgName) {
			let name = this._classMetaData.classErgName.find(n => n[0] == lang);
			if (name) {
				return name[1];
			}
			return undefined;
		}
		return undefined;
	}

	getValidators(propName: string): ValidatorFn[] | undefined {
		if (this.isMandatory(propName) === true) {
			return [Validators.required];
		}
		return undefined;
	}

	protected setMetaData(metaData: IClassMetaData): void {
		this._classMetaData = metaData;
		this._properties = new Map<string, IPropertyMetaData>();
		this._relations = new Map<string, RelationMeta>();
		if (metaData) {
			if (metaData.relations && metaData.relations.length > 0) {
				metaData.relations.reduce((p, c) => {
					let name = c.relationName.startsWith('_') ? c.relationName.substr(1) : c.relationName;
					c.relationName = name;
					p.set(name, new RelationMeta(c));
					return p;
				}, this._relations);
			}
			if (metaData.properties && metaData.properties.length > 0) {
				metaData.properties.reduce((p, c) => {
					let name = c.name.startsWith('_') ? c.name.substr(1) : c.name;
					c.name = name;
					p.set(name, c);
					return p;
				}, this._properties)
			}
		}
		this._metaDataChangedSubject$.next();
	}

	getPropertyAccessRight(accessRight: PropertyAccessRightET, prop: string/*, relationPath?: string*/): boolean | undefined {
		/*let propMeta: IPropertyMetaData;
		if (relationPath) {
			let relMeta = this.getRelationMeta(relationPath);
			if (relMeta) {
				propMeta = relMeta.getPropertyMeta(prop);
			}
			return undefined;
		}
		else {
			propMeta = this.getPropertyMeta(prop);
		}*/
		let propMeta = this.getPropertyMeta(prop);
		if (propMeta && propMeta.accessRights) {
			switch (accessRight) {
				case PropertyAccessRightET.Write:
					return propMeta.accessRights.write;
			}
		}
		return undefined;
	}

	getRelationAccessRight(accessRight: RelationAccessRightET, relation: string): boolean | undefined {
		let relMeta = this.getRelationMeta(relation);
		if (relMeta && relMeta.accessRights) {
			switch (accessRight) {
				case RelationAccessRightET.Create:
					return relMeta.accessRights.create;
				case RelationAccessRightET.Delete:
					return relMeta.accessRights.delete;
				case RelationAccessRightET.Edit:
					return relMeta.accessRights.edit;
				case RelationAccessRightET.Change:
					return relMeta.accessRights.change;
			}
		}		
		return undefined;
	}
}
