import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IRadioButtonComponent, IEnumerationItem } from '@otris/ng-core-types';

@Injectable()
export class PDRadioButtonGroupService {

	get radioButtons(): IRadioButtonComponent[] {
		return this._radioButtons;
	}

	private _radioButtons: IRadioButtonComponent[] = [];
	
	private _selectionChangedSubject$: Subject<IRadioButtonComponent | undefined> = new Subject();

	get selectionChanged$(): Observable<IRadioButtonComponent | undefined> {
		return this._selectionChangedSubject$.asObservable();
	}

	private _groupName: string;

	get groupName(): string {
		return this._groupName;
	}

	set groupName(name: string) {
		this._groupName = name;
	}

	setSelection(sel: IRadioButtonComponent | undefined) {
		if (sel) {
			if (this._radioButtons.includes(sel)) {
				sel.checked = true;
			}
		}
		else {
			this._radioButtons.forEach(rb => rb.checked = false);
			this._selectionChangedSubject$.next(undefined);
		}
	}
		
	setSelectionFromValue(sel: IEnumerationItem | boolean | string | undefined) {
		if (sel === undefined || sel === null) {
			this.setSelection(undefined);
			return;
		}
		this.setSelection(this._radioButtons.find(rb => {
			if (typeof(sel) === 'string' || typeof(sel) === 'boolean') {
				return rb.value === sel;
			}
			return (rb.value as IEnumerationItem).enumConst === sel.enumConst;
		}));
	}

	registerRadioButton(radioButton: IRadioButtonComponent) {
		// todo: prüfen, ob unsubscribe notwendig. Wohl aber eher nicht, weil Child-Component
		this._radioButtons.push(radioButton);
		radioButton.checkedChanged.subscribe(rb => {
			//this._radioButtons.filter(rb2 => rb2.id != rb.id).forEach(rb => rb.onUncheck());

			// falls ein RadioButton selektiert wurde alle anderen deselektieren
			if (rb.checked) {
				this._radioButtons.filter(r => r.id != rb.id).forEach(r => r.checked = false);
				// selectionChanged wird nur noch bei Radio-Buttons gefeuert, bei denen checked = true
				this._selectionChangedSubject$.next(rb);
			}
			//this._selectionChanged.next(rb);
		});
	}
}
