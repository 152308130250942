import { Directive, Input, TemplateRef } from '@angular/core';
import { UIAbstractDirective } from './ui-abstract.directive';

@Directive({
	selector: '[otrisComponentGroupHeader]'
})
export class ComponentGroupHeaderDirective extends UIAbstractDirective {
	@Input('otrisComponentGroupHeader') _id: string;

	get id(): string {
		return this._id;
	}

	constructor(template: TemplateRef<any>) {
		super(template);
	}
}
