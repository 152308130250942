import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { bindCallback, Observable, of, EMPTY, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
	IPDObjectId,
	IGetExtentByPageResult,
	ICallOperationResult,
	IPDObjectRaw,
	IPDObject,
	IErrorInfoResult,
	AbstractEnumeration,
	EnumerationItemData,
	EnumerationData
} from '@otris/ng-core-types';
import {
	IAuthService,
	ServiceLocator,
	PDObject as CorePDObject,
	IErrorHandler,
	IErrorHandlerToken,
	IError,
	PDAccessMethodNotImplementedError,
	IPDAccessImplementationService,
	PDEnumeration
} from '@otris/ng-core-shared';

import { JafWebPDObject } from '../model/jaf-web-pd-object';

declare var JafWeb: any;
declare var JParamPacker, JResponse, PDClass, PDObject, ClientInfo: any;

export const JafWebServicePriorityToken = new InjectionToken<number>('JafWebServicePriorityToken');

class CustomHttpParamEncoder extends HttpUrlEncodingCodec {
	encodeKey(value: string): string {
		return encodeURIComponent(value);
	}
	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}

JafWeb.isDocumentReady = function () { }

JafWeb.ajaxRequest = function (cfg) {
	console.debug(`JafWeb.ajaxRequest(callerName: ${cfg.callerName})`);

	JafWebService.doHttpRequest(cfg);

	/*console.log(`cfg: ${cfg}`);
	PDAccessService.doHttpGet(cfg).subscribe(res => {
		if (cfg.success) {
			cfg.success({responseText: res});
		}
	});*/
}

function isMultiLangWrapper(url: string, params: any, callback: ((res: boolean) => void)) {

	JafWeb.ajaxRequest({
		url: url,
		method: 'GET',
		asynchronous: true,
		encoding: 'UTF-8',
		params: params,
		disableCaching: true,
		success: (req, options) => {
			var resp = new JResponse(req, options);
			let res = resp.getBool('multilang', false);
			return callback(res);
		},
		failure: () => {
			// todo
		}
	});
}

function getExtentWrapper(pdclass: string | number, filter: string, sort: string, attrs: string[] | undefined, observableCallback: (result: any, errorInfo: IErrorInfoResult) => void) {
	PDClass.getExtent(pdclass, filter, sort, null, 0, 0, (result, errorInfo) => {
		observableCallback(result, errorInfo);
	}, false, attrs ? attrs : null);
}

function getExtentByPageWrapper(pdclass: string | number, pageIndex: number, pageSize: number, filter: string, sort: string,
	attrs: string[] | undefined, observableCallback: ((res: any, errorInfo: IErrorInfoResult) => void)) {
	PDClass.getExtent(pdclass, filter, sort, null, pageSize, pageIndex, (result, errorInfo) => {
		observableCallback(result, errorInfo);
	}, false, attrs ? attrs : null);
}

function disconnectClientWrapper(observableCallback: ((res: boolean) => void)) {
	ClientInfo.disconnectClient(result => observableCallback(result));
}

function changeUserWrapper(login: string, pwd: string, principal: string, observableCallback: ({ msg, errorCode } : { msg: string, errorCode: number }) => void) {
	// Den principal über changeUser zu setzten funktioniert nicht - Scheint veraltet zu sein.
	let loginName = login;
	if (principal) {
		loginName += `.${principal}`;
	}
	PDClass.changeUser(loginName, pwd, undefined, undefined, undefined, result => observableCallback({ msg: result.msg, errorCode: result.errCode ? result.errCode : 0 }));
}

function callOperationWrapper<T extends IPDObject>(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[] | undefined, pdObjectCreator: (rawObj: IPDObjectRaw) => T,
	observableCallback: (result: ICallOperationResult<T>) => void) {

	let strs;
	if (Array.isArray(inParams)) {
		strs = inParams;
	}
	else if (inParams != null && inParams!= undefined) {
		strs = [inParams];
	}
	else {
		strs = [];
	}

	let oids;
	if (Array.isArray(inObjs)) {
		oids = inObjs.map(oid => oid.oid)
	}
	else if (inObjs) {
		oids = [inObjs.oid];
	}
	else {
		oids = [];
	}

	PDClass.callOperation(opName, true, strs, oids,
		(outStrs: string | string[], outObjs: any, res: number, errInfo: any) => {
			let outObjWrapper = [];
			if (Array.isArray(outObjs)) {
				//outObjWrapper = outObjs.map(obj => obj ? new JafWebPDObject(obj) : undefined );
				outObjWrapper = outObjs.map(obj => obj ? pdObjectCreator(obj) : undefined );
			}
			else if (outObjs) {
				outObjWrapper = [outObjs ? pdObjectCreator(outObjs) : undefined];
			}
			observableCallback(<ICallOperationResult<T>>{ result: res, outStrs: outStrs, outObjs: outObjWrapper, errInfo: errInfo })
		});
}

function callObjectOperationWrapper(objWrapper: CorePDObject, opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[] | undefined,
	observableCallback: (result: ICallOperationResult<CorePDObject>) => void) {

	let strs;
	if (Array.isArray(inParams)) {
		strs = inParams;
	}
	else if (inParams != null && inParams!= undefined) {
		strs = [inParams];
	}
	else {
		strs = [];
	}

	let oids;
	if (Array.isArray(inObjs)) {
		oids = inObjs.map(oid => oid?.oid ?? null);
	}
	else if (inObjs !== undefined) {
		oids = [inObjs?.oid ?? null];
	}
	else {
		oids = [];
	}

	objWrapper.pdObjectRaw.callOperation(opName, true, strs, oids, [''],
		(outStrs: string | string[], outObjs: any, res: number, errInfo: any) => {
			let outObjWrapper = [];
			if (Array.isArray(outObjs)) {
				outObjWrapper = outObjs.map(obj => obj ? new JafWebPDObject(obj) : undefined );
			}
			else if (outObjs) {
				outObjWrapper = [outObjs ? new JafWebPDObject(outObjs) : undefined];
			}
			observableCallback(<ICallOperationResult<CorePDObject>>{ result: res, outStrs: outStrs, outObjs: outObjWrapper, errInfo: errInfo })
		});
}

function getRelationObjectsWrapper(objWrapper: CorePDObject, roleName: string, filter: string, sort: string, attrs: string[] | undefined,
	observableCallback: ((res: any, errInfo: IErrorInfoResult) => void)) {
		objWrapper.pdObjectRaw.getExtent(roleName, filter, sort, null, 0, 0,
			(result, errInfo) => {
				observableCallback(result, errInfo);
			},
			false, attrs ? attrs : null
		);
}

function getObjectWrapper(oid: string, attrs: string[] | undefined, observableCallback: ((res: any, errInfo: IErrorInfoResult) => void)) {
	PDClass.ptr(oid, true, attrs ? attrs : true, false, (result, errInfo) => {
		observableCallback(result, errInfo);
	});
}

function getObjectsWrapper(oids: string[], attrs: string[] | undefined, observableCallback: ((res: any, errInfo: IErrorInfoResult) => void)) {
	PDClass.ptrs(oids, attrs ? attrs : true, false, (result, errInfo) => {
		observableCallback(result, errInfo);
	});
}

function getEnumerationWrapper(enumName: string, includeDeleted: boolean, observableCallback: ((res: any, errInfo: IErrorInfoResult) => void)) {
	/**
	 * TODO: Eintragen wieso wir `PDClass.getGlobalEnumConst` (deprecated!) benutzen und nicht `PDClass.getEnumConsts`
	 * TODO: Für includeDeleted muss jafWeb geupdated werden
	 */
	// PDClass.getGlobalEnumConst(enumName, false, includeDeleted, (result, errInfo) => {
	PDClass.getGlobalEnumConst(enumName, true, (result, errInfo) => {
		observableCallback(result, errInfo);
	});
}

@Injectable()
export class JafWebService extends IPDAccessImplementationService {

	// todos:
	// synchrone Aufrufe?
	// Content-Type immer text?
	static doHttpRequest(cfg) {
		let pars = (cfg.params || {});
		pars.fmt = 'json';

		let http = <HttpClient>ServiceLocator.injector.get(HttpClient);
		let headers = new HttpHeaders();

		switch (cfg.method) {
			case 'GET': {
				switch (cfg.dataType) {
					case 'text':
						headers = headers.set('Content-Type', 'text/plain');
						break;
					default:
						headers = headers.set('Content-Type', 'application/json');
						break;
					/*case 'html':
						headers = headers.set('Content-Type', 'text/plain');
						break;*/
				}

				// UB 05.11.2019: Bugfix IE
				headers = headers.set('Cache-Control', 'no-cache');
				headers = headers.set('Pragma', 'no-cache');
				headers = headers.set('Expires', '0');

				http.get(cfg.url, { params: pars, headers: headers }).subscribe(
					res => {
						if (cfg.success) {
							try {
								cfg.success(res);
							}
							catch (err) {
								if (cfg.failure) {
									cfg.failure(res);
								}
							}
						}
					},
					err => {
						console.log(err);
						if (cfg.failure) {
							cfg.failure();
						}
					});
				break;
			}

			case 'POST': {
				headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
				let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder(), fromObject: pars });
				http.post(cfg.url, httpParams, { headers: headers }).subscribe(
					res => {
						if (cfg.success) {
							try {
								cfg.success(res);
							}
							catch (err) {
								if (cfg.failure) {
									cfg.failure(res);
								}
							}
						}
					},
					err => {
						console.log(err);
						if (cfg.failure) {
							cfg.failure();
						}
					});

					// todo
					break;
			}


		}
	}

	/*static doHttpGet(cfg): Observable<any> {
		let http = <HttpClient>ServiceLocator.injector.get(HttpClient);
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'text/plain');
		return http.get<boolean>(cfg.url, { params: cfg.params, headers: headers });
	}*/

	private get authService(): IAuthService {
		return <IAuthService>ServiceLocator.injector.get(IAuthService);
	}

	private get errorHandler(): IErrorHandler {
		return <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
	}

	constructor(@Inject(JafWebServicePriorityToken) private _priority: number) {
		super();
		JafWeb.loadPD({
			rootURL: 'janus',
			logoutEvent: 'logout',
			authToken: '123456789', // ???
			downloadURL: 'download',
			uploadURL: 'upload',
			useEnumCache: false
		});
	}

	// test
	isMultiLang(): Observable<boolean> {
		var pars = new JParamPacker('Multilang');
		var params = {
			JanusApplicationName: 'GenericJanusApplication',
			janusWebEvent: 'serverEvent',
			serverEvent: pars.getEventString(false)
		};
		console.log(params);

		/*let successFn = function(req, options) {
			var resp = new JResponse(req, options);
			let res = resp.getBool('multilang', false);
			console.log(res);
			callback(res)
		} */

		let f = bindCallback(isMultiLangWrapper);
		return f("janus", params);

		//doAjaxRequest("", () => {})

		/*var successFn = function(req, options)
		{
			var resp = new JResponse(req, options);
			//window._multilang = resp.getBool('multilang', false);
			// in window._multilang steht jetzt das Ergebnis
			console.log(resp.getBool('multilang', false));
		};*/

		/*JafWeb.ajaxRequest({
			//url: getRootUrl(), UIApplication
			url: '',
			method: 'GET',
			asynchronous: true,
			encoding: 'UTF-8',
			params: params,
			disableCaching: true,
			success: successFn,
			failure: () => {
				// todo
			}
		});*/
	}

	get priority(): number {
		return this._priority;
	}

	changeUser(login: string, pwd: string, principal: string): Observable<{ msg: string, errorCode: number }> {
		console.debug(`JafWebService.changeUser(login: ${login})`);

		let wrapperFunc = bindCallback(changeUserWrapper);
		return wrapperFunc(login, pwd, principal);
	}

	disconnect(): Observable<boolean> {
		console.debug(`JafWebService.disconnect()`);

		let wrapperFunc = bindCallback(disconnectClientWrapper);
		return wrapperFunc();
	}

	getExtent(pdclass: string | number, filter?: string, sort?: string, attrs?: string[]): Observable<CorePDObject[]> {
		console.debug(`JafWebService.getExtent(pdClass: ${pdclass})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtent(${pdclass}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getExtentWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(pdclass, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(resObj => {

				// Fehlerfall: resObj[1] enthält einen Wert
				if (resObj[1]) {
					let errMsgArr = resObj[1]['_errMsgs'];
					let errMsgString = errMsgArr.join(', ');
					errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtent(): ${errMsgString}` });
					return [];
				}

				// Erfolg: resObj[0] hat einen oder mehrere PDObject(s)
				if (resObj[0] && resObj[0].retCode === 0 && Array.isArray(resObj[0].rows)) {
					let result: CorePDObject[] = [];
					for (let obj of (resObj[0].rows as [])) {
						result.push(new JafWebPDObject(obj));
					}
					return result;
				}

				// Fehlerfall: Fatal
				errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.getExtent()` });
				return [];
			})
		);
	}

	getExtentByPage(pdclass: string | number, pageIndex: number, pageSize: number, ignoreErrors: boolean = false,
		filter?: string, sort?: string, attrs?: string[]): Observable<IGetExtentByPageResult> {
		console.debug(`JafWebService.getExtentByPage(pdClass: ${pdclass})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtentByPage(${pdclass}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getExtentByPageWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(pdclass, pageIndex, pageSize, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(resObj => { // = [result, errorInfo]

				// Fehlerfall: resObj[1] enthält einen Wert
				if (resObj[1]) {
					let errMsgArr = resObj[1]['_errMsgs'];
					let errMsgString = errMsgArr.join(', ');
					if (!ignoreErrors) {
						errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtentByPage(): ${errMsgString}` });
					}
					return <IGetExtentByPageResult>{ data: [], itemCount: 0 };
				}

				// Erfolg: resObj[0] hat einen oder mehrere PDObject(s)
				if (resObj[0] && resObj[0].retCode === 0 && Array.isArray(resObj[0].rows)) {
					let data: CorePDObject[] = [];
					if (Array.isArray(resObj[0].rows)) {
						for (let obj of resObj[0].rows) {
							data.push(new JafWebPDObject(obj)); // todo
						}
					}
					return <IGetExtentByPageResult>{ data: data, itemCount: resObj[0].total };
				}

				// Fehlerfall: Fatal
				if (!ignoreErrors) {
					errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.getExtentByPage()` });
				}
				return <IGetExtentByPageResult>{ data: [], itemCount: 0 };
			})
		);
	}

	callOperation(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode = 1,
		handleErrors = true
	): Observable<ICallOperationResult<CorePDObject>> {
		console.debug(`JafWebService.callOperation(${opName})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callOperationWrapper);
		return wrapperFunc(opName, inParams, inObjs, (rawObj) => <CorePDObject>new JafWebPDObject(rawObj)).pipe(
			switchMap(resObj => {

				// Fehlerfall: errInfo enthält einen Wert
				if (handleErrors && resObj.errInfo) {
					let errMsgArr = resObj.errInfo._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): ${errMsgString}`});
					return of(<ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined });
				}

				// Erfolg
				if (resObj.result === successRetCode && resObj.outObjs && resObj.outStrs) {
					return of(resObj);
				}

				// Fehlerfall
				if (handleErrors === false) {
					return of(resObj);
				}
				this.errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callOperation(${opName}). Error code: ${resObj.result}`});
				return EMPTY;
				//return <ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined };
			})
		);
	}

	callOperationTyped<T extends IPDObject>(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		pdObjectCreator: (rawObj: IPDObjectRaw) => T,
		successRetCode = 1,
		handleErrors = true
	): Observable<ICallOperationResult<T>> {
		console.debug(`JafWebService.callOperationTyped(${opName})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callOperationWrapper);
		return wrapperFunc(opName, inParams, inObjs, pdObjectCreator).pipe(
			switchMap(resObj => {

				// Fehlerfall: errInfo enthält einen Wert
				if (handleErrors && resObj.errInfo) {
					let errMsgArr = resObj.errInfo._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): ${errMsgString}`});
					return of(<ICallOperationResult<T>>{ result: -1, outStrs: undefined, outObjs: undefined, errInfo: resObj.errInfo });
				}

				// Erfolg
				if (resObj.result === successRetCode && resObj.outObjs && resObj.outStrs) {
					return of(resObj);
				}

				// Fehlerfall
				if (handleErrors === false) {
					return of(resObj);
				}
				this.errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callOperation(${opName}). Error code: ${resObj.result}`});
				return EMPTY;
				//return <ICallOperationResult<T>>{ result: -1, outStrs: undefined, outObjs: undefined };
			})
		);
	}

	getRelationObjects(objWrapper: CorePDObject, relationName: string, filter?: string, sort?: string, attrs?: string[]): Observable<CorePDObject[]> {

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getRelationObjects(${relationName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getRelationObjectsWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(objWrapper, relationName, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(res => {

				// Fehlerfall: errInfo enthält einen Wert
				if (res[1]) {
					let errMsgArr = res[1]._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getRelationObjects(): ${errMsgString}`});
					return [];
				}

				// Erfolg: Werte sind nicht undefined
				if (res[0]?.rows && Array.isArray(res[0].rows)) {
					let result: CorePDObject[] = [];
					for (let obj of res[0].rows) {
						result.push(new JafWebPDObject(obj));
					}
					return result;
				}

				// Fehlerfall: Fatal
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getRelationObjects()' });
				return [];
			})
		);
	}

	callObjectOperation(
		objWrapper: CorePDObject,
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode = 1,
		handleErrors = true
	): Observable<ICallOperationResult<CorePDObject>> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callObjectOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callObjectOperationWrapper);
		return wrapperFunc(objWrapper, opName, inParams, inObjs).pipe(
			switchMap(resObj => {

				// Fehlerfall: errInfo enthält einen Wert
				if (handleErrors && resObj.errInfo) {
					let errMsgArr = resObj.errInfo._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callObjectOperation(${opName}): ${errMsgString}`});
					return of(<ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined, errInfo: resObj.errInfo });
				}
				// Todo simon: prüfen
				// Fehlerfall2 retCode -1 in outStrs
				// if (resObj.outStrs['retCode'] === -1 || resObj.outStrs['_errMsgs']?.length > 0) {
				// 	let errMsgArr = resObj.outStrs['_errMsgs'];
				// 	let errMsgString = errMsgArr.join(', ');
				// 	this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callObjectOperation(${opName}): ${errMsgString}`});
				// 	return of(<ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined, errInfo: errMsgString });
				// }

				// Erfolg
				if (resObj.result === successRetCode && resObj.outObjs && resObj.outStrs) {
					return of(resObj);
				}

				// Fehlerfall
				if (handleErrors === false) {
					return of(resObj);
				}
				this.errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callObjectOperation(${opName}). Error code: ${resObj.result}`});
				return EMPTY;
				//return <ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined };
			})
		);
	}

	getObject(id: string, attrs?: string[]): Observable<CorePDObject> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObject(): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getObjectWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(id, attrs).pipe(
			map(res => {

				// Fehlerfall: errInfo enthält einen Wert
				if (res[1]) {
					let errMsgArr = res[1]._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObject(): ${errMsgString}`});
					return undefined;
				}

				// Erfolg: Werte sind nicht undefined
				if (res[0]) {
					return new JafWebPDObject(res[0]);
				}

				// Fehlerfall: Fatal
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getObject()' });
				return undefined;
			})
		);
	}

	getObjects(ids: string[], attrs?: string[]): Observable<CorePDObject[]> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObjects(): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getObjectsWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(ids, attrs).pipe(
			map(res => {

				// Fehlerfall: errInfo enthält einen Wert
				if (res[1]) {
					let errMsgArr = res[1]._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObjects(): ${errMsgString}`});
					return [];
				}

				// Erfolg: Werte sind nicht undefined
				if (res[0] && Array.isArray(res[0]) ) {
					return res[0].map(r => r ? new JafWebPDObject(r) : undefined);
				}

				// Fehlerfall: Fatal
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getObjects()' });
				return [];
			})
		)
	}

	downloadObjectDocument(objWrapper: CorePDObject, attr: string): Observable<void> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.downloadObjectDocument(): Not logged in` });
			return EMPTY;
		}

		objWrapper.pdObjectRaw.downloadDocument(attr);
		return of(undefined);
	}

	getEnumeration(enumName: string, langIndex: number, includeDeleted = false): Observable<AbstractEnumeration> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObjects(): Not logged in` });
			return EMPTY;
		}

		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		let wrapperFunc = bindCallback(getEnumerationWrapper);
		return wrapperFunc(enumName, includeDeleted).pipe(
			switchMap(res => {
				// Fehlerfall: errInfo enthält einen Wert
				if (res[1]) {
					let errMsgArr = res[1]._errMsgs;
					let errMsgString = errMsgArr.join(', ');
					this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getEnumeration(): ${errMsgString}`});
					return EMPTY;
				}

				// Erfolg
				let langCount = res[0].tech.length === 0 ? 0 : res[0].vals.length / res[0].tech.length;
				let langCountError = res[0].tech.length === 0 || (res[0].vals.length % res[0].tech.length) !== 0;

				if (
					!langCountError && langIndex < langCount &&
					res[0] &&
					Array.isArray(res[0].active) && Array.isArray(res[0].tech) && Array.isArray(res[0].vals) &&
					res[0].active.length === res[0].tech.length && res[0].vals.length === langCount * res[0].tech.length
				) {
					const enumItemData: EnumerationItemData[] = [];
					for (let i = 0; i < res[0].tech.length; i++) {
						enumItemData.push({
							enumConst: res[0].tech[i],
							ergName: {
								de: res[0].vals[i * langCount],
								en: res[0].vals[i * langCount + 1]
							},
							active: res[0].active[i]
						})
					}
					const enumData = <EnumerationData>{
						name: enumName,
						items: enumItemData,
						isMulti: res[0].multi
					}
					return of(new PDEnumeration(enumData, langIndex));
				}

				// Fehlerfall: Fatal1
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getEnumeration()' });
				return EMPTY;
			})
		);
	}

	getSubClasses(): Observable<[string, string[]][]> {
		return throwError(() => new PDAccessMethodNotImplementedError('getSubClasses'));
	}

	createPDObjectRaw(oid: string, className: string): IPDObjectRaw {
		let parts = oid.split(':');
		let invalidOid = false;
		if (parts.length != 2) {
			invalidOid = true;
		}
		let clsId = Number.parseInt(parts[0]);
		if (isNaN(clsId)) {
			invalidOid = true;
		}
		if (invalidOid) {
			throw new Error(`Invalid oid '${oid}'`);
		}
		/*let tmp = window['PDClass'];
		window['PDClass'] = undefined;
		let obj = new PDObject(tmp, clsId, oid);
		window['PDClass'] = tmp;
		return obj;*/

		let obj = {
			_isnew: false,
			oid: oid,
			cid: clsId,
			classname: className
		}
		return PDClass.toPDObject(obj);
	}
}
