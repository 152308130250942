import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ChangeDetectionController implements IChangeDetectionController {

	private _changedDetectionStatusChangedSubject$: Subject<boolean> = new Subject();

	private _changedDetectionStatusChanged$: Observable<boolean>;

	private _changeDetectionDisabled = false;

	constructor() {}

	disableChangeDetection(disabled: boolean): void {
		// todo: mehrfache Aufrufe von disable richtig behandeln?
		if (disabled !== this._changeDetectionDisabled) {
			this._changeDetectionDisabled = disabled;
			this._changedDetectionStatusChangedSubject$.next(disabled);
		}
	}

	get changeDetectionDisabled(): boolean {
		return this._changeDetectionDisabled;
	}

	get changeDetectionStatusChanged$(): Observable<boolean> {
		if (!this._changedDetectionStatusChanged$) {
			this._changedDetectionStatusChanged$ = this._changedDetectionStatusChangedSubject$.asObservable();
		}
		return this._changedDetectionStatusChanged$;
	}
}

@Injectable({ providedIn: 'root', useClass: ChangeDetectionController })
export abstract class IChangeDetectionController {
	abstract disableChangeDetection(disabled: boolean): void;
	abstract get changeDetectionDisabled(): boolean;
	abstract get changeDetectionStatusChanged$(): Observable<boolean>;
}