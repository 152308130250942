@if (hasHeaderTemplate) {
	<div class="root">
		<div>
			<ng-container *ngTemplateOutlet="panelHeaderTemplate;context:headerContext"/>
		</div>
		<ng-container *ngTemplateOutlet="pdPanelContent"/>
	</div>
} @else {
	<ng-container *ngTemplateOutlet="pdPanelContent"/>
}
<ng-template #pdPanelContent>
	<div [ngStyle]="getContainerStyle()">
		@for (item of itemSpecs; track item) {
			@switch (item.spec.widget) {
				@case (widgetET.TextField) {
					<otris-pd-text-field
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.NumericTextField) {
					<otris-pd-numeric-text-field
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Checkbox) {
					<otris-pd-checkbox
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.RadioButton) {
					<otris-pd-radio-button
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.InputSwitch) {
					<otris-pd-input-switch
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.DateTimeField) {
					<otris-pd-date-time-field
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.DropDown) {
					<otris-pd-drop-down
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Listview) {
					<otris-pd-listview
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.ComboBox) {
					<otris-pd-combo-box
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.MultiSelect) {
					<otris-pd-multi-select
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.AutoComplete) {
					<otris-pd-auto-complete
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.ObjectReference) {
					<otris-pd-object-reference
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.RelationTab) {
					<otris-pd-relation-tab
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.RelationGrid) {
					<otris-pd-relation-grid
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Panel) {
					@if (getToNRelationSpec(item.spec)) {
						@if (hasRelationItems(item)) {
							@for (relObj of item.pdObject[getToNRelationSpec(item.spec).relationProperty]; track relationObjectTracker(i, relObj); let i = $index; let count = $count) {
							  <otris-pd-panel #pdPanelRelationHost
									[uiItemSpec]="item.spec"
									[formGroup]="item.fg"
									[pdObject]="relObj"
									[relationContext]="relationContext"
									[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
									[relItemContainerItemIndex]="i"
									[relItemContainerItemCount]="count"
									[relItemContainerFormGroup]="item.fg"
									[relItemContainerPDObject]="relObj"
									[relItemContainerBaseObject]="item.pdObject"
									[relItemContainerRelationContext]="relationContext"
									[relItemContainerHostComponent]="pdPanelRelationHost"
								/>
							}
						} @else {
							<ng-container *ngTemplateOutlet="getNoRelationObjectTemplate(item.spec); context:getNoRelationObjectTemplateContext(item.spec)"/>
						}
						<ng-template #pdPanelToNRelationNoRelationObject>
							<ng-container *ngTemplateOutlet="getNoRelationObjectTemplate(item.spec); context:getNoRelationObjectTemplateContext(item.spec)"/>
						</ng-template>
					} @else {
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-panel #pdPanelRelationItemHost
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdPanelRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							@if (getTo1RelationItemSpec(item.spec)) {
								@if (getTo1RelationItemObject(item)) {
									<otris-pd-panel #pdPanelTo1RelationItemHost
										[uiItemSpec]="item.spec"
										[formGroup]="item.fg"
										[pdObject]="getTo1RelationItemObject(item)"
										[relationContext]="relationContext"
										[otrisTo1RelationItem]="getTo1RelationItemSpec(item.spec)"
										[relItemHostComponent]="pdPanelTo1RelationItemHost"
										[relItemFormGroup]="item.fg"
										[relItemRelationContext]="relationContext"
										[relItemPDObject]="getTo1RelationItemObject(item)"
									/>
								}
							} @else {
								<otris-pd-panel
									[uiItemSpec]="item.spec"
									[formGroup]="getFormGroupForChilds()"
									[pdObject]="item.pdObject"
									[relationContext]="getRelationContextForChilds()"
								/>
							}
						}
					}
					<ng-template #pdPanelNoToNRelationSpec>
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-panel #pdPanelRelationItemHost
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdPanelRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							@if (getTo1RelationItemSpec(item.spec)) {
								@if (getTo1RelationItemObject(item)) {
									<otris-pd-panel #pdPanelTo1RelationItemHost
										[uiItemSpec]="item.spec"
										[formGroup]="item.fg"
										[pdObject]="getTo1RelationItemObject(item)"
										[relationContext]="relationContext"
										[otrisTo1RelationItem]="getTo1RelationItemSpec(item.spec)"
										[relItemHostComponent]="pdPanelTo1RelationItemHost"
										[relItemFormGroup]="item.fg"
										[relItemRelationContext]="relationContext"
										[relItemPDObject]="getTo1RelationItemObject(item)"
									/>
								}
							} @else {
								<otris-pd-panel
									[uiItemSpec]="item.spec"
									[formGroup]="getFormGroupForChilds()"
									[pdObject]="item.pdObject"
									[relationContext]="getRelationContextForChilds()"
								/>
							}
						}
					</ng-template>

					<ng-template #pdPanelNoToNRelationItemSpec>
						@if (getTo1RelationItemSpec(item.spec)) {
							@if (getTo1RelationItemObject(item)) {
								<otris-pd-panel #pdPanelTo1RelationItemHost
									[uiItemSpec]="item.spec"
									[formGroup]="item.fg"
									[pdObject]="getTo1RelationItemObject(item)"
									[relationContext]="relationContext"
									[otrisTo1RelationItem]="getTo1RelationItemSpec(item.spec)"
									[relItemHostComponent]="pdPanelTo1RelationItemHost"
									[relItemFormGroup]="item.fg"
									[relItemRelationContext]="relationContext"
									[relItemPDObject]="getTo1RelationItemObject(item)"
								/>
							}
						} @else {
							<otris-pd-panel
								[uiItemSpec]="item.spec"
								[formGroup]="getFormGroupForChilds()"
								[pdObject]="item.pdObject"
								[relationContext]="getRelationContextForChilds()"
							/>
						}
					</ng-template>

					<ng-template #defaultPDPanel>
						<otris-pd-panel
							[uiItemSpec]="item.spec" [formGroup]="getFormGroupForChilds()" [pdObject]="item.pdObject" [relationContext]="getRelationContextForChilds()">
						</otris-pd-panel>
					</ng-template>
				}

				@case (widgetET.Groupbox) {
					<ng-container *ngTemplateOutlet="groupbox; context: getPDContainerTemplateContext(item)"/>
					<!--
					@if (getToNRelationSpec(item.spec)) {
						@if (hasRelationItems(item)) {
							@for (relObj of item.pdObject[getToNRelationSpec(item.spec).relationProperty]; track relationObjectTracker(i, relObj); let i = $index; let count = $count) {
							  <otris-pd-groupbox #pdGroupBoxRelationHost
									[parentContainer]="this"
									[uiItemSpec]="item.spec"
									[formGroup]="item.fg"
									[pdObject]="relObj"
									[relationContext]="relationContext"
									[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
									[relItemContainerItemIndex]="i"
									[relItemContainerItemCount]="count"
									[relItemContainerFormGroup]="item.fg"
									[relItemContainerPDObject]="relObj"
									[relItemContainerBaseObject]="item.pdObject"
									[relItemContainerRelationContext]="relationContext"
									[relItemContainerHostComponent]="pdGroupBoxRelationHost"
								/>
							}
						} @else {
							<otris-pd-groupbox #pdGroupBoxRelationHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
								[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
								[relItemContainerFormGroup]="item.fg"
								[relItemContainerBaseObject]="item.pdObject"
								[relItemContainerHostComponent]="pdGroupBoxRelationHost"
							/>
						}
						<ng-template #pdGroupboxToNRelationNoRelationObject>
							<otris-pd-groupbox #pdGroupBoxRelationHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
								[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
								[relItemContainerFormGroup]="item.fg"
								[relItemContainerBaseObject]="item.pdObject"
								[relItemContainerHostComponent]="pdGroupBoxRelationHost"
							/>
						</ng-template>
					} @else {
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-groupbox #pdGroupBoxRelationItemHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdGroupBoxRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							<otris-pd-groupbox
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
							/>
						}
					}
					<ng-template #pdGroupboxNoRelationSpec>
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-groupbox #pdGroupBoxRelationItemHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdGroupBoxRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							<otris-pd-groupbox
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
							/>
						}
					</ng-template>
					<ng-template #defaultPDGroupbox>
						<otris-pd-groupbox
							[parentContainer]="this"
							[uiItemSpec]="item.spec"
							[formGroup]="item.fg"
							[pdObject]="item.pdObject"
							[relationContext]="relationContext">
						</otris-pd-groupbox>
					</ng-template>
					-->
				}

				@case (widgetET.ExpansionPanel) {
					<ng-container *ngTemplateOutlet="expansionPanel; context: getPDContainerTemplateContext(item)"/>
					<!--
					@if (getToNRelationSpec(item.spec)) {
						@if (hasRelationItems(item)) {
							@for (relObj of item.pdObject[getToNRelationSpec(item.spec).relationProperty]; track relationObjectTracker(i, relObj); let i = $index; let count = $count) {
							  <otris-pd-expansion-panel #pdExpansionPanelRelationHost
									[parentContainer]="this"
									[uiItemSpec]="item.spec"
									[formGroup]="item.fg"
									[pdObject]="relObj"
									[relationContext]="relationContext"
									[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
									[relItemContainerItemIndex]="i"
									[relItemContainerItemCount]="count"
									[relItemContainerFormGroup]="item.fg"
									[relItemContainerPDObject]="relObj"
									[relItemContainerBaseObject]="item.pdObject"
									[relItemContainerRelationContext]="relationContext"
									[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
								/>
							}
						} @else {
							<otris-pd-expansion-panel #pdExpansionPanelRelationHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
								[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
								[relItemContainerFormGroup]="item.fg"
								[relItemContainerBaseObject]="item.pdObject"
								[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
							/>
						}
						<ng-template #pdExpansionPanelToNRelationNoRelationObject>
							<otris-pd-expansion-panel #pdExpansionPanelRelationHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
								[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
								[relItemContainerFormGroup]="item.fg"
								[relItemContainerBaseObject]="item.pdObject"
								[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
							/>
						</ng-template>
					} @else {
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-expansion-panel #pdExpansionPanelRelationItemHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdExpansionPanelRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							<otris-pd-expansion-panel
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
							/>
						}
					}
					<ng-template #pdExpansionPanelNoRelationSpec>
						@if (getToNRelationItemSpec(item.spec)) {
							<otris-pd-expansion-panel #pdExpansionPanelRelationItemHost
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="getRelationItemObject(item)"
								[relationContext]="relationContext"
								[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
								[relItemHostComponent]="pdExpansionPanelRelationItemHost"
								[relItemFormGroup]="item.fg"
								[relItemRelationContext]="relationContext"
								[relItemPDObject]="getRelationItemObject(item)"
							/>
						} @else {
							<otris-pd-expansion-panel
								[parentContainer]="this"
								[uiItemSpec]="item.spec"
								[formGroup]="item.fg"
								[pdObject]="item.pdObject"
								[relationContext]="relationContext"
							/>
						}
					</ng-template>
					<ng-template #defaultPDExpansionPanel>
						<otris-pd-expansion-panel
							[parentContainer]="this"
							[uiItemSpec]="item.spec"
							[formGroup]="item.fg"
							[pdObject]="item.pdObject"
							[relationContext]="relationContext"
						/>
					</ng-template>
					-->
				}

				@case (widgetET.RadioButtonGroup) {
					<otris-pd-radio-button-group
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.TabView) {
					<otris-pd-tab-view
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Drawer) {
					<otris-pd-drawer
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Label) {
					<otris-pd-label
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.Button) {
					<otris-pd-button
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.ContentTemplate) {
					<otris-ui-content
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.InfoField) {
					<otris-pd-info-field
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.FileUpload) {
					<otris-pd-file-upload
						[uiItemSpec]="item.spec"
						[formGroup]="getFormGroupForChilds()"
						[pdObject]="item.pdObject"
						[relationContext]="getRelationContextForChilds()"
					/>
				}
				@case (widgetET.GenericContainer) {
					@switch (getGenericContainerType(item.spec)) {
						@case ('groupbox') {
							<ng-container *ngTemplateOutlet="groupbox; context: getPDContainerTemplateContext(item)"/>
						}

						@case ('expansionPanel') {
							<ng-container *ngTemplateOutlet="expansionPanel; context: getPDContainerTemplateContext(item)"/>
						}
					}
				}
				@default {
					<div>unknown widget '{{item.spec.widget}}'</div>
				}
			}
		}
	</div>
</ng-template>

<ng-template #groupbox let-item>
	@if (getToNRelationSpec(item.spec)) {
		@if (hasRelationItems(item)) {
			@for (relObj of item.pdObject[getToNRelationSpec(item.spec).relationProperty]; track relationObjectTracker(i, relObj); let i = $index; let count = $count) {
			  <otris-pd-groupbox #pdGroupBoxRelationHost
					[parentContainer]="this"
					[uiItemSpec]="item.spec"
					[formGroup]="item.fg"
					[pdObject]="relObj"
					[relationContext]="relationContext"
					[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
					[relItemContainerItemIndex]="i"
					[relItemContainerItemCount]="count"
					[relItemContainerFormGroup]="item.fg"
					[relItemContainerPDObject]="relObj"
					[relItemContainerBaseObject]="item.pdObject"
					[relItemContainerRelationContext]="relationContext"
					[relItemContainerHostComponent]="pdGroupBoxRelationHost"
				/>
			}
		} @else {
			<otris-pd-groupbox #pdGroupBoxRelationHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
				[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
				[relItemContainerFormGroup]="item.fg"
				[relItemContainerBaseObject]="item.pdObject"
				[relItemContainerHostComponent]="pdGroupBoxRelationHost"
			/>
		}
		<ng-template #pdGroupboxToNRelationNoRelationObject>
			<otris-pd-groupbox #pdGroupBoxRelationHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
				[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
				[relItemContainerFormGroup]="item.fg"
				[relItemContainerBaseObject]="item.pdObject"
				[relItemContainerHostComponent]="pdGroupBoxRelationHost"
			/>
		</ng-template>
	} @else {
		@if (getToNRelationItemSpec(item.spec)) {
			<otris-pd-groupbox #pdGroupBoxRelationItemHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="getRelationItemObject(item)"
				[relationContext]="relationContext"
				[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
				[relItemHostComponent]="pdGroupBoxRelationItemHost"
				[relItemFormGroup]="item.fg"
				[relItemRelationContext]="relationContext"
				[relItemPDObject]="getRelationItemObject(item)"
			/>
		} @else {
			<otris-pd-groupbox
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
			/>
		}
	}
	<ng-template #pdGroupboxNoRelationSpec>
		@if (getToNRelationItemSpec(item.spec)) {
			<otris-pd-groupbox #pdGroupBoxRelationItemHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="getRelationItemObject(item)"
				[relationContext]="relationContext"
				[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
				[relItemHostComponent]="pdGroupBoxRelationItemHost"
				[relItemFormGroup]="item.fg"
				[relItemRelationContext]="relationContext"
				[relItemPDObject]="getRelationItemObject(item)"
			/>
		} @else {
			<otris-pd-groupbox
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
			/>
		}
	</ng-template>
	<ng-template #defaultPDGroupbox>
		<otris-pd-groupbox
			[parentContainer]="this"
			[uiItemSpec]="item.spec"
			[formGroup]="item.fg"
			[pdObject]="item.pdObject"
			[relationContext]="relationContext">
		</otris-pd-groupbox>
	</ng-template>
</ng-template>

<ng-template #expansionPanel let-item>
	@if (getToNRelationSpec(item.spec)) {
		@if (hasRelationItems(item)) {
			@for (relObj of item.pdObject[getToNRelationSpec(item.spec).relationProperty]; track relationObjectTracker(i, relObj); let i = $index; let count = $count) {
				<otris-pd-expansion-panel #pdExpansionPanelRelationHost
					[parentContainer]="this"
					[uiItemSpec]="item.spec"
					[formGroup]="item.fg"
					[pdObject]="relObj"
					[relationContext]="relationContext"
					[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
					[relItemContainerItemIndex]="i"
					[relItemContainerItemCount]="count"
					[relItemContainerFormGroup]="item.fg"
					[relItemContainerPDObject]="relObj"
					[relItemContainerBaseObject]="item.pdObject"
					[relItemContainerRelationContext]="relationContext"
					[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
				/>
			}
		} @else {
			<otris-pd-expansion-panel #pdExpansionPanelRelationHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
				[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
				[relItemContainerFormGroup]="item.fg"
				[relItemContainerBaseObject]="item.pdObject"
				[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
			/>
		}
		<ng-template #pdExpansionPanelToNRelationNoRelationObject>
			<otris-pd-expansion-panel #pdExpansionPanelRelationHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
				[otrisToNRelationItemContainer]="getToNRelationSpec(item.spec)"
				[relItemContainerFormGroup]="item.fg"
				[relItemContainerBaseObject]="item.pdObject"
				[relItemContainerHostComponent]="pdExpansionPanelRelationHost"
			/>
		</ng-template>
	} @else {
		@if (getToNRelationItemSpec(item.spec)) {
			<otris-pd-expansion-panel #pdExpansionPanelRelationItemHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="getRelationItemObject(item)"
				[relationContext]="relationContext"
				[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
				[relItemHostComponent]="pdExpansionPanelRelationItemHost"
				[relItemFormGroup]="item.fg"
				[relItemRelationContext]="relationContext"
				[relItemPDObject]="getRelationItemObject(item)"
			/>
		} @else {
			<otris-pd-expansion-panel
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
			/>
		}
	}
	<ng-template #pdExpansionPanelNoRelationSpec>
		@if (getToNRelationItemSpec(item.spec)) {
			<otris-pd-expansion-panel #pdExpansionPanelRelationItemHost
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="getRelationItemObject(item)"
				[relationContext]="relationContext"
				[otrisToNRelationItem]="getToNRelationItemSpec(item.spec)"
				[relItemHostComponent]="pdExpansionPanelRelationItemHost"
				[relItemFormGroup]="item.fg"
				[relItemRelationContext]="relationContext"
				[relItemPDObject]="getRelationItemObject(item)"
			/>
		} @else {
			<otris-pd-expansion-panel
				[parentContainer]="this"
				[uiItemSpec]="item.spec"
				[formGroup]="item.fg"
				[pdObject]="item.pdObject"
				[relationContext]="relationContext"
			/>
		}
	</ng-template>
	<ng-template #defaultPDExpansionPanel>
		<otris-pd-expansion-panel
			[parentContainer]="this"
			[uiItemSpec]="item.spec"
			[formGroup]="item.fg"
			[pdObject]="item.pdObject"
			[relationContext]="relationContext"
		/>
	</ng-template>
</ng-template>
