import { Injectable } from "@angular/core";
import { CustomPDObject } from "@otris/ng-core-shared";
import { IMainUIService } from '@otris/ng-core-types';

@Injectable()
export class MainUIService implements IMainUIService {
	private _createdObjects: CustomPDObject[] = [];

	constructor() {}

	addCreatedObject(obj: CustomPDObject): void {
		if (!this._createdObjects.includes(obj)) {
			this._createdObjects.push(obj);
		}
	}

	removeCreatedObject(obj: CustomPDObject): void {
		let index = this._createdObjects.indexOf(obj);
		if (index >= 0) {
			this._createdObjects.splice(index, 1);
		}
	}

	clearCreatedObjects(): void {
		this._createdObjects = [];
	}

	get createdObjects(): CustomPDObject[] {
		return this._createdObjects;
	}
}