import { Injectable, Inject, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TypeET } from '@otris/ng-core-types';
import { CustomPDObject } from '@otris/ng-core-shared';
import { IPDObjectEditContext, IPDObjectEditContextToken, IPDObjectEditContextManager } from './i-pd-object-edit-context';
import { IPDObjectEditContextManagerToken } from './pd-object-edit-context-manager.service';
import { ActivatedRoute } from '@angular/router';

export const pdObjectEditContextFactory = (editContextManager: IPDObjectEditContextManager, route: ActivatedRoute, parentContext?: IPDObjectEditContext) => {
	let ctx = new PDObjectEditContext(editContextManager, parentContext);
	ctx.baseRoute = route;
	return ctx;
}

@Injectable()
export class PDObjectEditContext implements IPDObjectEditContext, OnDestroy {
	private _contextObject: CustomPDObject;

	get contextObject(): CustomPDObject {
		return this._contextObject;
	}

	/*set contextObject(obj: CustomPDObject) {
		this._contextObject = obj;
	}*/

	//private _parentObject: PDObject;

	get parentObject(): CustomPDObject | undefined {
		return this.parentEditContext ? this.parentEditContext.contextObject : undefined;
	}

	private _isNewObject: boolean = false;

	get isNewObject(): boolean {
		return this._isNewObject;
	}

	get baseRoute(): ActivatedRoute {
		if (!this._baseRoute) {
			throw new Error('baseRoute not set.')
		}
		return this._baseRoute;
	}

	set baseRoute(route: ActivatedRoute) {
		if (this._baseRoute) {
			throw new Error('baseRoute already set.')
		}
		this._baseRoute = route;
	}

	private _baseRoute: ActivatedRoute;

	private _roleName: string;

	private _parentId: string;

	private _relationObjectCreatedSubject$: Subject<[string, CustomPDObject, string]> = new Subject();

	private _relationObjectEditedSubject$: Subject<[string, CustomPDObject, string]> = new Subject();

	private _registeredRelationObjects: Map<string, CustomPDObject> = new Map();

	constructor(@Inject(IPDObjectEditContextManagerToken) private editContextManager: IPDObjectEditContextManager,
		@Inject(IPDObjectEditContextToken) @Optional() @SkipSelf() private parentEditContext: IPDObjectEditContext) {

		//console.log(`parentEditContext: ${parentEditContext}`)
		editContextManager.switchContext(this);

		// todo: switch back!!!
	}

	ngOnDestroy() {
		// wird nicht aufgerufen!
		console.log('PDObjectEditContext.ngOnDestroy()');
		//this.editContextManager.releaseCurrentContext();

	}

	setContextObject(obj: CustomPDObject, isNew?: boolean, roleName?: string, parentId?: string): void {
		this._contextObject = obj;
		this._isNewObject = isNew === true;
		this._roleName = roleName;
		this._parentId = parentId;
	}

	/*getRelationObject(path: string, oid?: string): CustomPDObject | undefined {
		let val = this._contextObject.getPropertyValueFromPath(path);
		switch (this._contextObject.getPropertyTypeFromPath(path)) {
			case TypeET.RelationTo1:
				if (val instanceof CustomPDObject) {
					return val;
				}
				break;
			case TypeET.RelationToN:
				if (!oid) {
					return undefined;
				}
				if (Array.isArray(val)) {
					return val.find(o => o instanceof CustomPDObject && o.oid === oid);
				}
				break;
		}
		return undefined;
	}*/

	getRelationObject(relPath: string, oid?: string): CustomPDObject | undefined {
		let getRelObjImpl = (obj: CustomPDObject, path: string) => {
			let val = obj.getPropertyValueFromPath(path);
			switch (obj.getPropertyTypeFromPath(path)) {
				case TypeET.RelationTo1:
					if (val instanceof CustomPDObject) {
						return val;
					}
					break;
				case TypeET.RelationToN:
					if (!oid) {
						return undefined;
					}
					if (Array.isArray(val)) {
						return val.find(o => o instanceof CustomPDObject && o.oid === oid);
					}
					break;
			}
			return undefined;
		}

		//
		// check registered relation objects
		//
		let checkPath = relPath;
		let remainingPath: string;
		while (checkPath) {
			if (this._registeredRelationObjects.has(checkPath)) {
				let registeredObj = this._registeredRelationObjects.get(checkPath);
				return remainingPath ? getRelObjImpl(registeredObj, remainingPath) : registeredObj;
			}
			let pos = checkPath.lastIndexOf('.');
			if (pos > 0 && pos < checkPath.length - 1) {
				remainingPath = remainingPath ? `${checkPath.substring(pos + 1)}.${remainingPath}` : checkPath.substring(pos + 1); 
				checkPath = checkPath.substring(0, pos);
			}
			else {
				checkPath = undefined;
			}
		}

		return getRelObjImpl(this._contextObject, relPath);
	}

	release(): void {
		this.editContextManager.releaseCurrentContext();
	}

	notifyContextObjectSaved(): void {
		if (this.parentEditContext) {
			if (this.isNewObject) {
				this.parentEditContext.notifyRelationObjectCreated(this._contextObject, this._roleName, this._parentId);
			}
			else {
				this.parentEditContext.notifyRelationObjectEdited(this._contextObject, this._roleName, this._parentId);
			}
		}
	}

	notifyRelationObjectCreated(obj: CustomPDObject, roleName: string, parentId: string): void {
		this._relationObjectCreatedSubject$.next([roleName, obj, parentId]);
	}

	getRelationObjectCreated(role: string): Observable<[string, CustomPDObject, string]> {
		return this._relationObjectCreatedSubject$.asObservable().pipe(filter(res => res[0] == role));//.map(res => res[1]);
	}

	notifyRelationObjectEdited(obj: CustomPDObject, roleName: string, parentId: string): void {
		this._relationObjectEditedSubject$.next([roleName, obj, parentId]);
	}

	getRelationObjectEdited(role: string): Observable<[string, CustomPDObject, string]> {
		return this._relationObjectEditedSubject$.asObservable().pipe(filter(res => res[0] == role));
	}

	registerRelationObject(role: string, obj: CustomPDObject): void {
		this._registeredRelationObjects.set(role, obj);
	}

	unregisterRelationObject(role: string): boolean {
		return this._registeredRelationObjects.delete(role);
	}
}
