import { Component, Inject, Input, Renderer2, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
	CancelEvent,
	CellClickEvent,
	CheckboxColumnComponent,
	ColumnBase,
	CommandColumnComponent,
	GridComponent,
	RemoveEvent,
	RowArgs,
	SaveEvent,
	SelectableSettings,
	SelectionEvent
} from "@progress/kendo-angular-grid";
import {
	EMPTY,
	map,
	Observable,
	of,
	Subscription,
	switchMap,
	tap,
	forkJoin,
} from "rxjs";

import {
	EventProcessingContextManagerService,
	FormHandlerService,
	IPopupManagerService,
	IPopupManagerServiceToken,
	PDItemSpec,
	PDLabeledControlComponent,
	RelationGridWidgetInfo,
	UIItemSpec,
	UIPanelSpec,
	LocalizationServiceToken,
	IPDObjectEditContext,
	IPDObjectEditContextToken
} from "@otris/ng-core";
import {
	CustomPDObject,
	IInteractionServiceToken,
	IPDAccessServiceToken,
	IPDClassToken,
	PDObject,
	PropertyAccessRightET,
	IErrorHandlerToken,
	IErrorHandler,
	IError
} from "@otris/ng-core-shared";
import {
	AbstractEnumeration,
	ComponentTypeET,
	ErgNameData,
	ICheckboxWidgetInfo,
	ICssStyle,
	ICustomChoiceProviderResult,
	ICustomColumnInfo,
	IDateTimeFieldWidgetInfo,
	IDropDownWidgetInfo,
	IEventProcessingContext,
	IInteractionService,
	ILabeledControlWidgetInfo,
	ILocalizationService,
	IMultiSelectRelationWidgetInfo,
	INumericTextFieldWidgetInfo,
	IObjectReferenceWidgetInfo,
	IPDAccessService,
	IPDClass,
	IPDItemSpec,
	IPDListColumnInfo,
	IPDObject,
	IRelationComponent,
	IRelationContext,
	IRelationGridComponent,
	ISelectObjectOptions,
	ISelectPDObjectsResult,
	ItemTypeET,
	ITextFieldWidgetInfo,
	IToolBarButtonSpec,
	IUIPanelSpec,
	LabelPositionET,
	LanguageCodeET,
	ToolBarItemTypeET,
	TypeET,
	VisibilityET,
	WidgetET
} from "@otris/ng-core-types";

//const matches = (el, selector) => el.matches.call(el, selector);


/* [id]="getId() + '.listview'"
[selectable]="selectableSettings" [kendoGridSelectBy]="selectionKeyProperty"
				[(selectedKeys)]="selectedKeys"
				(cancel)="cancelHandler($event)"
				(save)="saveHandler($event)"
				(cellClick)="cellClickHandler($event)"

	<ng-container *ngSwitchCase="typeET.Structure">
		{{obj[col.field] | structure}}
	</ng-container>
	<ng-container *ngSwitchCase="typeET.Enum">
		{{obj[col.field] | abstractEnumeration}}
	</ng-container>
	[width]="100"
	[style.height]="'100%'"
*/

enum ToolbarItemIds {
	CreateObject = 'CreateObject',
	SelectObjects = 'SelectObject',
	DeleteSelectedObjects = 'DeleteSelectedObjects',
	CreateObjectsQuick = 'CreateObjectsQuick'
}

// todo: andere Stelle
export function toNRelationArrayLengthValidator(minLength: number, relationObjects: PDObject[] | undefined): ValidatorFn {
	return (): ValidationErrors | null => {
		if (minLength <= 0) {
			return null;
		}
		return (relationObjects?.length ?? 0) < minLength ?
			{ toNRelationArrayLength: { minLength: minLength }} :
			null;
	};
 }

@Component({
	selector: 'otris-pd-relation-grid',
	template: `
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
		>
			<div class="relation-grid-container">
				@if (isReadonly) {
					<div class="lock-container" kendoTooltip>
						<i class="fa fa-lg fa-fw fa-lock lock-cursor" title="{{getReadonlyTitle() | async}}"></i>
					</div>
				}
				<kendo-grid
					[id]="gridId" #kendoGrid class="grid"
					[style.height]="'100%'"
					[style.maxHeight]="relationGridWidgetInfo.maxHeight"
					[data]="gridData"
					[scrollable]="'scrollable'"
					[resizable]="true"
					[selectable]="selectableSettings"
					[(selectedKeys)]="selection"
					[kendoGridSelectBy]="selectionKeySelector"
					(cellClick)="onCellClick($event)"
					(keydown.esc)="onKeydownEsc($event)"
					(keydown.enter)="onKeydownEnter($event)"
					(keydown.arrowdown)="onKeydownArrowDown($event)"
					(keydown.arrowup)="onKeydownArrowUp($event)"
					(cancel)="cancelHandler($event)"
					(remove)="removeHandler($event)"
					(save)="saveHandler($event)"
					(selectionChange)="onSelectionChange($event)"
				>
					<!--
					 <ng-container *ngIf="showToolbar">
						  <ng-template kendoGridToolbarTemplate [position]="'top'">
								<otris-kendo-tool-bar (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolbarItems" [showSearchControl]="showSearchControl"
									 (searchExpressionChanged)="onSearchExpressionChanged($event)">
								</otris-kendo-tool-bar>
						  </ng-template>
					 </ng-container>
					 [width]="col.width"
					 -->

					<!-- Checkbox zum löschen -->
					@if (!isReadonly && deleteSelectedObjectsEnabled && canDelete) {
						<kendo-grid-checkbox-column
							[showSelectAll]="true"
							[width]="25"
							[sticky]="true"
							[resizable]="false"
							[hidden]="isInEditMode"
							[autoSize]="false"
						/>
					}
					<kendo-grid-command-column
						[resizable]="false"
						[sticky]="true"
						[width]="16"
						[style]="getColumnStyle()"
					>
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="command-button-icon">
								<span>
									@if (dataItem.isNew) {
										<i class="fa fa-fw fa-star" [title]="titleNewlyAddedRow"></i>
									}
								</span>
								<span>
								@if (!canEdit) {
									<i class="fa fa-fw fa-lock" [title]="titleReadonlyRow"></i>
								}
								</span>
							</div>
						</ng-template>
					</kendo-grid-command-column>

					<!-- Spalte(n) für die einzelnen Felder -->
					@for (col of columnInfos; track col; let iCol = $index) {
  					<kendo-grid-column
							[field]="col.field"
							[title]="getColHeader(col) | async"
							[width]="col.width"
							[editable]="!isReadonly && col.editable === true"
							[style]="getColumnStyle(col.style, canEdit)"
							[hidden]="isColumnHidden(col)"
						>
							<!-- Header-Template -->
							<ng-template
								kendoGridHeaderTemplate
								let-column
								let-columnIndex="columnIndex"
							>
								<div #colHeaderRoot class="col-header-root" (mouseenter)="onMouseEnterColHeader(colHeaderRoot)" (mouseleave)="onMouseLeaveColHeader(colHeaderRoot)">
									<div>{{ getColHeader(col) | async }}</div>
									@if (col.hasShortDescription ?? true) {
										<otris-pd-short-description
											class="col-header-short-description"
											[highlighted]="headerUnderMouse==colHeaderRoot"
											[customShortDescription]="getColShortDescription(col)"
											[customShortDescriptionId]="getColShortDescriptionId(col)"
										/>
									}
								</div>
							</ng-template>

							<!-- Cell-Template -->
							<ng-template #cellTemplate kendoGridCellTemplate let-obj="dataItem">
								@if (!col.cellTemplate) {
									@if (isCustomColumn(col)) {
										<span>{{ getCustomColumnCellValue(col, obj) }}</span>
									} @else {
										@switch (col.type) {
											@case (typeET.Boolean) {
												@if (getPropertyValue(obj, col.field, createEventProcessingContext())) {
													<div>
														<i class="fa fa-lg fa-check" style="color: green;"></i>
													</div>
												} @else {
													@if (getPropertyValue(obj, col.field, createEventProcessingContext()) === false) {
														<i class="fa fa-lg fa-times" style="color: red;"></i>
													} @else {
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													}
													<ng-template #elseIndeterminate>
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													</ng-template>
												}
												<ng-template #elseBoolean>
													@if (getPropertyValue(obj, col.field, createEventProcessingContext()) === false) {
														<i class="fa fa-lg fa-times" style="color: red;"></i>
													} @else {
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													}
													<ng-template #elseIndeterminate>
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													</ng-template>
												</ng-template>
											}
											@case (typeET.Date) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'dd. MMM yyyy'}}
											}
											@case (typeET.Time) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'HH:mm:ss'}}
											}
											@case (typeET.DateTime) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'dd. MMM yyyy HH:mm:ss'}}
											}
											@case (typeET.Integer) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | number:'1.0-0'}}
											}
											@case (typeET.Double) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | number:'1.2-2'}}
											}
											@case (typeET.RelationTo1) {
												{{getTo1RelationObjectInfo(obj, col)}}
											}
											@case (typeET.RelationToN) {
												{{getToNRelationObjectInfo(obj, col)}}
											}
											@case (typeET.Enum) {
												{{getEnumDropdownErgName$(obj, col) | async}}
											}
											@default {
												{{getPropertyValue(obj, col.field, createEventProcessingContext())}}
											}
										}
}
									<!-- <ng-template #defaultColumnInfo> -->
									<ng-template #defaultColumnInfo>
										@switch (col.type) {
											@case (typeET.Boolean) {
												@if (getPropertyValue(obj, col.field, createEventProcessingContext())) {
													<div>
														<i class="fa fa-lg fa-check" style="color: green;"></i>
													</div>
												} @else {
													@if (getPropertyValue(obj, col.field, createEventProcessingContext()) === false) {
														<i class="fa fa-lg fa-times" style="color: red;"></i>
													} @else {
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													}
													<ng-template #elseIndeterminate>
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													</ng-template>
												}
												<ng-template #elseBoolean>
													@if (getPropertyValue(obj, col.field, createEventProcessingContext()) === false) {
														<i class="fa fa-lg fa-times" style="color: red;"></i>
													} @else {
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													}
													<ng-template #elseIndeterminate>
														<i class="fa fa-lg fa-question" style="color: #4e4e4e;"></i>
													</ng-template>
												</ng-template>
											}
											@case (typeET.Date) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'dd. MMM yyyy'}}
											}
											@case (typeET.Time) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'HH:mm:ss'}}
											}
											@case (typeET.DateTime) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | date:'dd. MMM yyyy HH:mm:ss'}}
											}
											@case (typeET.Integer) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | number:'1.0-0'}}
											}
											@case (typeET.Double) {
												{{getPropertyValue(obj, col.field, createEventProcessingContext()) | number:'1.2-2'}}
											}
											@case (typeET.RelationTo1) {
												{{getTo1RelationObjectInfo(obj, col)}}
											}
											@case (typeET.RelationToN) {
												{{getToNRelationObjectInfo(obj, col)}}
											}
											@case (typeET.Enum) {
												{{getEnumDropdownErgName$(obj, col)}}
											}
											@default {
												{{getPropertyValue(obj, col.field, createEventProcessingContext())}}
											}
										}
									</ng-template>
									<!-- </ng-template> -->
								} @else {
									<ng-container *ngTemplateOutlet="col.cellTemplate; context: { colInfo: col, object: obj }"></ng-container>
								}
						</ng-template>

							<!-- Edit-Template -->
							<ng-template kendoGridEditTemplate let-formGroup="formGroup" let-obj="dataItem">
								<!-- <ng-container> -->
								@if (isCustomColumn(col)) {
									<!-- [relationContext]="relationContext" -->
									<otris-pd-panel
										[uiItemSpec]="getGridRowItemSpec(iCol)"
										[formGroup]="formGroup"
										[pdObject]="editedRowObject"
										[relationContext]="getRelationContextForEdit()"
									/>
								} @else {
									@switch (true) {
										@case (col.type === typeET.String) {
											<otris-pd-text-field
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.RelationTo1) {
											<otris-pd-object-reference
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.RelationToN) {
											<otris-pd-multi-select
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.Integer || col.type === typeET.Double) {
											<otris-pd-numeric-text-field
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.Date || col.type === typeET.Time || col.type === typeET.DateTime) {
											<otris-pd-date-time-field
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.Boolean) {
											<otris-pd-checkbox
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
										@case (col.type === typeET.Enum) {
											<otris-pd-drop-down
												[uiItemSpec]="getGridRowItemSpec(iCol)"
												[formGroup]="formGroup"
												[pdObject]="editedRowObject"
												[relationContext]="getRelationContextForEdit()"
											/>
										}
									}
								}
								<!-- </ng-container> -->
							</ng-template>
						</kendo-grid-column>
					}

					<!-- Rechte Spalte für Änderung speichern/verwerfen + löschen -->
					<!-- [locked]="true" wird an der linken Seite festgeklebt -->
					@if (!isReadonly) {
						<kendo-grid-command-column
							#commandColumn
							editable="false"
							[sticky]="true"
							[width]="40"
							[style]="getColumnStyle()"
						>
							<ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
								<div class="command-column-container">
									<button
										kendoGridSaveCommand
										tabindex="-1"
										style="color: green;"
										look="flat"
										class="command-button-icon"
										iconClass="fa fa-lg fa-check"
										[disabled]="!canSave"
										[title]="titleSaveRow"
									>
									</button>
									<button
										kendoGridCancelCommand
										tabindex="-1"
										style="color: red;"
										look="flat"
										class="command-button-icon"
										iconClass="fa fa-lg fa-times"
										[title]="titleCancelRow"
									>
									</button>
									@if (deleteObjectEnabled) {
										<button
											kendoGridRemoveCommand
											tabindex="-1"
											look="flat"
											class="command-button-icon"
											iconClass="fa fa-lg fa-trash-o"
											[disabled]="!canDelete || isInEditMode"
											[title]="titleRemoveRow"
										>
										</button>
									}
								</div>
							</ng-template>
						</kendo-grid-command-column>
					}

					<!-- i18n für Kendo-Texte -->
					<kendo-grid-messages
						[noRecords]="noRecordsTextMessage"
					/>
				</kendo-grid>
			</div>
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		.relation-grid-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		.grid {
			width: 100%;
		}
		.command-column-container {
			display: flex;
			justify-content: center;
		}
		.command-button-icon > span {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.col-header-root {
			display: flex;
			width: 100%;
		}
		.col-header-short-description {
			margin-left: 0.25em;
		}
		.lock-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-right: 0.5em;
			color: dimgray;
		}
		.lock-cursor {
			cursor: help;
		}
	`]
})
export class PDRelationGridComponent extends PDLabeledControlComponent implements IRelationGridComponent {

	@ViewChild(GridComponent) private _grid: GridComponent;

	@ViewChild('commandColumn') private _commandColumn: ColumnBase;

	@Input() columnInfos: IPDListColumnInfo[];

	@Input() selectObjectsEnabled = false;

	@Input() createObjectEnabled = false;

	@Input() deleteObjectEnabled = false;

	@Input() deleteSelectedObjectsEnabled = false;

	typeET = TypeET;

	noRecordsTextMessage = "No records available";
	titleSaveRow = "Save record"
	titleCancelRow = "Cancel record"
	titleRemoveRow = "Remove record"
	titleNewlyAddedRow = "Newly added record"
	titleReadonlyRow = "Readonly record"

	/*get gridData(): PDObject[] {
		return this._gridData;
	}

	@Input()
	set gridData(data: PDObject[]) {
		this._gridData = data;
		this.dataResult = { data: data, total: data ? data.length : 0 };
		//this.updateGridDataMap();
	}

	private _gridData: PDObject[];*/

	//dataResult: GridDataResult;

	/**
	 * Dies sollte IMMER eine Referenz auf das PDObject-Array sein. Das FormControl verwendet ebenso dieselbe Referenz.
	 * Ein neues Objekt/Array, wie z.B. durch `this._gridData = this._gridData.filter()`, würde diese Referenzierung zerstören!
	 * Also bitte immer direkt mit dem Array arbeiten. `array.splice(); array.push();` etc.
	 *
	 * Die Referenzierung entsteht in der Methode onPDObjectChanged()
	 */
	private _gridData: PDObject[];

	get gridData(): PDObject[] {
		return this._gridData;
	}

	get gridId(): string {
		return this.getId() + '.kendo-grid'
	}

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RelationGrid;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	private _active: boolean = true;

	get active(): boolean {
		return this._active;
	}

	activate(val: boolean, forceUpdateChoice = true): void {
	}


	set customChoiceProvider(cb: (source: IRelationComponent, ctx: IEventProcessingContext) => ICustomChoiceProviderResult[]) {
		this._customChoiceProvider = cb;
		//this.updateChoice();
	}

	private _customChoiceProvider: (source: IRelationComponent, ctx: IEventProcessingContext) => ICustomChoiceProviderResult[];

	notifyCustomChoiceChanged(clearSelection?: boolean): void {}

	set customChoiceFilterProvider(cb: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>) {
		this._customChoiceFilterProvider = cb;
		//this.updateChoice();
	}

	private _customChoiceFilterProvider: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>;

	set selectionListColumnProvider(cb: () => IPDListColumnInfo[]) {
		this._selectionListColumnProvider = cb;
	}

	private _selectionListColumnProvider: () => IPDListColumnInfo[];

	private _onSaveRowHandler: (obj: IPDObject, source: IRelationGridComponent, ctx: IEventProcessingContext) => void;

	set onSaveRowHandler(cb: (obj: IPDObject, source: IRelationGridComponent, ctx: IEventProcessingContext) => void) {
		this._onSaveRowHandler = cb;
	}

	protected get relationGridWidgetInfo(): RelationGridWidgetInfo {
		if (!(this.widgetInfo instanceof RelationGridWidgetInfo)) {
			throw new Error('Invalid widget info');
		}
		return this.widgetInfo as RelationGridWidgetInfo
	}

	private _gridRowFormGroup: FormGroup;

	protected get gridRowFormGroup(): FormGroup {
		return this._gridRowFormGroup;
	}

	protected set gridRowFormGroup(g: FormGroup) {
		if (g !== this._gridRowFormGroup) {
			this._gridRowFormGroup = g;
			this.updateToolbar();
		}
	}

	protected gridRowItemSpecs: UIItemSpec[] = [];

	private _isNew = false;

	private _editedRowIndex: number;

	private _editedColIndex: number;

	protected editedRowObject: CustomPDObject;

	// Das Erstellen liefert `() => void`, womit der Listener wieder entfernt wird.
	private _docClickListener: () => void | undefined;

	protected headerUnderMouse: any;

	protected get canSave(): boolean {
		return this.gridRowFormGroup?.valid && this.gridRowFormGroup?.pristine === false;
	}

	protected canChange = true;

	protected canCreate = true;

	protected canDelete = true;

	protected canEdit = true;

	protected get selectableSettings(): SelectableSettings {
		return {
			enabled: this.deleteSelectedObjectsEnabled,
			checkboxOnly: true,
     		mode: 'multiple'
		}
	}

	protected selection: string[] = [];

	protected get isInEditMode(): boolean {
		return !!this.gridRowFormGroup;
	}

	constructor(router: Router, route: ActivatedRoute,
		private _renderer: Renderer2,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Inject(IPDAccessServiceToken) private _pdAccessService: IPDAccessService,
		@Inject(LocalizationServiceToken) private _localizationService: ILocalizationService,
		@Inject(IPDClassToken) private _pdClass: IPDClass,
		@Inject(IInteractionServiceToken) private _interactionService: IInteractionService,
		@Inject(IPopupManagerServiceToken) private _popupManagerService: IPopupManagerService,
		@Inject(IErrorHandlerToken) private _errorHandler: IErrorHandler,
		@Inject(IPDObjectEditContextToken) private _pdObjectEditContext: IPDObjectEditContext,
	) {
			super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit(): void {
		super.ngOnInit();
		let wi = this.relationGridWidgetInfo;
		if (wi.columns) {
			this.columnInfos = wi.columns;
		}
		if (this.columnInfos) {
			let adaptWidgetInfo = (wi: ILabeledControlWidgetInfo): void => {
				wi.labelVisibility = VisibilityET.Collapsed;
				wi.labelPosition = LabelPositionET.Left;
				wi.shortDescriptionVisible = false;
			}
			this.columnInfos.forEach(colInfo => {
				let uiItemSpec: UIItemSpec;
				if (colInfo.editable) {
					if ((colInfo as ICustomColumnInfo).isCustomColumnInfo) {
						uiItemSpec = this.createCustomColumnPanelSpec(colInfo as ICustomColumnInfo);
					}
					else {
						switch (colInfo.type) {
							case TypeET.String: {
								let wi: ITextFieldWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								let spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.TextField,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								};
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}

							case TypeET.Double:
							case TypeET.Integer: {
								let wi: INumericTextFieldWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								let spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.NumericTextField,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								};
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}

							case TypeET.Date:
							case TypeET.Time:
							case TypeET.DateTime: {
								let wi: IDateTimeFieldWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								let spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.TextField,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								};
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}

							case TypeET.RelationTo1: {
								let wi: IObjectReferenceWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								let spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.ObjectReference,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								};
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}
							case TypeET.RelationToN: {
								let wi: IMultiSelectRelationWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								let spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.MultiSelect,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								};
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}
							case TypeET.Boolean: {
								let wi: ICheckboxWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								wi.labelVisibility = VisibilityET.Hidden
								const spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.Checkbox,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								}
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}
							case TypeET.Enum: {
								let wi: IDropDownWidgetInfo = colInfo.editWidgetInfo ?? {};
								adaptWidgetInfo(wi);
								const spec: IPDItemSpec = {
									type: ItemTypeET.PDItem,
									property: colInfo.field,
									widget: WidgetET.DropDown,
									widgetInfo: wi,
									updateUIStateHandler: colInfo.updateUIStateHandler
								}
								uiItemSpec = new PDItemSpec(spec, undefined);
								break;
							}
						}
					}
				}
				this.gridRowItemSpecs.push(uiItemSpec);
			});
		}

		if (wi.selectAction !== undefined) {
			this.selectObjectsEnabled = wi.selectAction === true;
		}
		if (wi.newAction !== undefined) {
			this.createObjectEnabled = wi.newAction === true;
		}
		if (wi.deleteAction !== undefined) {
			this.deleteObjectEnabled = wi.deleteAction === true;
		}
		if (wi.deleteSelectedAction !== undefined) {
			this.deleteSelectedObjectsEnabled = wi.deleteSelectedAction === true;
		}
		if (wi.customChoiceFilterProvider !== undefined) {
			this.customChoiceFilterProvider = wi.customChoiceFilterProvider;
		}
		if (wi.customChoiceProvider !== undefined) {
			this.customChoiceProvider = wi.customChoiceProvider;
		}
		if (wi.onSaveRowHandler !== undefined) {
			this.onSaveRowHandler = wi.onSaveRowHandler;
		}

		let tbItems: IToolBarButtonSpec[] = [];
		if (this.createObjectEnabled) {
			tbItems.push(
				{
					type: ToolBarItemTypeET.Button,
					id: ToolbarItemIds.CreateObject,
					iconClass: 'fa fa-lg fa-fw fa-star',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-grid.tool-bar-button-new'
				}
			);
		}
		if (this.selectObjectsEnabled) {
			tbItems.push(
				{
					type: ToolBarItemTypeET.Button,
					id: ToolbarItemIds.SelectObjects,
					iconClass: 'fa fa-lg fa-fw fa-list',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-grid.tool-bar-button-select'
				}
			);
		}
		if (this.deleteSelectedObjectsEnabled) {
			tbItems.push(
				{
					type: ToolBarItemTypeET.Button,
					id: ToolbarItemIds.DeleteSelectedObjects,
					iconClass: 'fa fa-lg fa-fw fa-trash-o',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-grid.tool-bar-button-delete-selected'
				}
			);
		}
		if (wi.quickCreationSpec && wi.quickCreationEnabled) {
			tbItems.push(
				{
					type: ToolBarItemTypeET.Button,
					id: ToolbarItemIds.CreateObjectsQuick,
					iconClass: 'fa fa-lg fa-fw fa-magic',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-grid.tool-bar-button-create-objects-quick'
				}
			);
		}
		if (tbItems.length > 0) {
			this.addToolbarItems('start', ...tbItems);
		}
		this.toolbarButtonClick.subscribe(item => {
			switch(item.id) {
				case ToolbarItemIds.CreateObject:
					this.createObject();
					break;
				case ToolbarItemIds.SelectObjects:
					this.selectObjects();
					break;
				case ToolbarItemIds.DeleteSelectedObjects:
					this.removeObjects(this.selection);
					break;
				case ToolbarItemIds.CreateObjectsQuick:
					this.createObjectsQuick();
					break;
			}
		});
		this.updateToolbar();

		this._localizationService.changeHandler.pipe(
			switchMap(() => this._localizationService.getSystemStrings([
				'kendo-ui.components.pd-relation-grid.no-records-message',
				'kendo-ui.components.pd-relation-grid.title-save-row',
				'kendo-ui.components.pd-relation-grid.title-cancel-row',
				'kendo-ui.components.pd-relation-grid.title-remove-row',
				'kendo-ui.components.pd-relation-grid.title-newly-added-row',
				'kendo-ui.components.pd-relation-grid.title-readonly-row'
			])),
			tap(trans => {
				this.noRecordsTextMessage = trans[0];
				this.titleSaveRow = trans[1];
				this.titleCancelRow = trans[2];
				this.titleRemoveRow = trans[3];
				this.titleNewlyAddedRow = trans[4];
				this.titleReadonlyRow = trans[5];
			})
		).subscribe();
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		/*this._grid.dataStateChange.subscribe(() => {
			console.log('dataStateChange');
		})*/

		/*setTimeout(() => {
			this._grid.autoFitColumn(this._commandColumn);
		});*/
	}

	public ngOnDestroy(): void {
		this.docClickListener(false);
		super.ngOnDestroy();
	}

	protected selectionKeySelector(context: RowArgs): any {
		return context.dataItem.oid;
	}

	protected onSelectionChange(e: SelectionEvent) {
		this.updateToolbar();
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		// todo: neuer UIState canChange!
		this.canChange = relAccessRights?.change ?? true;
		this.canCreate = relAccessRights?.create ?? true;
		this.canDelete = relAccessRights?.delete ?? true;
		this.canEdit = relAccessRights?.edit ?? true;
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		//this.applyAccessRights();
		this.updateToolbar();
	}

	protected onPDObjectChanged(): void {
		super.onPDObjectChanged();
		this._gridData = [];
		if (this.pdObject && Array.isArray(this.pdObject.pdObjectRaw[this.propertyName])) {
			this._gridData = this.pdObject.pdObjectRaw[this.propertyName];
		}
	}

	protected getColShortDescriptionId(colInfo: IPDListColumnInfo): string {
		if (colInfo.shortDescriptionId && this.pdObject) {
			const clsName = this.pdObject.metaData.getRelationClassName(this.propertyName);
			if (clsName) {
				// Aus der _localizationService bekommt man kein ErgName oder ID
				return `app.class.${clsName}.properties.${colInfo.shortDescriptionId}.short-description`;
			}
		}
		if (colInfo.field && this.pdObject) {
			const clsName = this.pdObject.metaData.getRelationClassName(this.propertyName);
			if (clsName) {
				// Aus der _localizationService bekommt man kein ErgName oder ID
				return `app.class.${clsName}.properties.${colInfo.field}.short-description`;
			}
		}
		return 'n.a';
	}

	protected getColShortDescription(colInfo: IPDListColumnInfo): ErgNameData | undefined {
		if (colInfo.shortDescription) {
			if (typeof(colInfo.shortDescription) === 'string') {
				return <ErgNameData>{de: colInfo.shortDescription, en: colInfo.shortDescription};
			}
			return colInfo.shortDescription;
		}
		return undefined;
	}

	protected onMouseEnterColHeader(element: any): void {
		this.headerUnderMouse = element;
	}

	protected onMouseLeaveColHeader(element: any): void {
		this.headerUnderMouse = undefined;
	}

	protected isColumnHidden(col: IPDListColumnInfo): boolean {
		if (col.hidden === true) {
			return true;
		}
		/*if (col.hiddenCallback) {
			return col.hiddenCallback(col);
		}*/
		return false;
	}

	protected getColHeader(colInfo: IPDListColumnInfo): Observable<string> {
		if (colInfo.header) {
			if (typeof(colInfo.header) === 'string') {
				return of(colInfo.header);
			}
			switch (this._localizationService.currentLanguage.code) {
				case LanguageCodeET.de:
					return of(colInfo.header.de);
				case LanguageCodeET.en:
					return of(colInfo.header.en);
			}
		}
		if (colInfo.headerId && this.pdObject) {
			let clsName = this.pdObject.metaData.getRelationClassName(this.propertyName);
			if (clsName) {
				return this._localizationService.getPropertyLabel(clsName, colInfo.headerId);
			}
		}
		if (colInfo.field && this.pdObject) {
			let clsName = this.pdObject.metaData.getRelationClassName(this.propertyName);
			if (clsName) {
				return this._localizationService.getPropertyLabel(clsName, colInfo.field);
			}
		}
		return of("n.a.");
	}

	protected getEnumDropdownErgName$(obj: PDObject, colInfo: IPDListColumnInfo): Observable<string> {
		if (colInfo.type != TypeET.Enum) {
			console.warn(`Invalid column info type '${colInfo.type}' for enum column.`);
			return of('');
		}
		let relObj = obj[colInfo.field + "Object"];
		if (!relObj) {
			return of('');
		}
		if (!(relObj instanceof AbstractEnumeration)) {
			console.warn(`Invalid value type for enum column.`);
			return of('');
		}
		return this._localizationService.changeHandler.pipe(
			switchMap(() => relObj.updateItemErgNames()),
			map(() => relObj.value['ergName']),
		)
	}

	private getObjectInfo(colInfo: IPDListColumnInfo, obj: PDObject): string {
		//const objCol = <IPDObjectColumnInfo>colInfo;
		if (colInfo.objectInfo) {
			return colInfo.objectInfo;
		} else if (colInfo.objectInfoProvider) {
			return colInfo.objectInfoProvider(obj, this.createEventProcessingContext());
		}
		return 'n.a.';
	}

	protected getTo1RelationObjectInfo(obj: PDObject, colInfo: IPDListColumnInfo): string {
		if (colInfo.type != TypeET.RelationTo1) {
			console.warn(`Invalid column info type '${colInfo.type}' for to 1 relation column.`);
			return '';
		}
		let relObj = obj[colInfo.field];
		if (!relObj) {
			return '';
		}
		if (!(relObj instanceof PDObject)) {
			console.warn(`Invalid value type for to 1 relation column.`);
			return '';
		}
		return relObj.getStatus(this.getObjectInfo(colInfo, obj));
	}

	protected getToNRelationObjectInfo(obj: PDObject, colInfo: IPDListColumnInfo): string {
		if (colInfo.type != TypeET.RelationToN) {
			console.warn(`Invalid column info type '${colInfo.type}' for to N relation column.`);
			return '';
		}
		let relObjs = obj[colInfo.field];
		if (!relObjs) {
			return '';
		}
		if (!Array.isArray(relObjs)) {
			console.warn(`Invalid value type for to N relation column.`);
			return '';
		}

		if (relObjs.length > 0) {
			if (!(relObjs[0] instanceof PDObject)) {
				console.warn(`Invalid value type for to N relation column.`);
				return '';
			}
		}
		const selectedText: string[] = [];
		 (relObjs as PDObject[]).forEach(obj => {
			 selectedText.push(obj.getStatus(this.getObjectInfo(colInfo, obj)))
		})

		return selectedText.join(', ');
	}

	protected isCustomColumn(colInfo: IPDListColumnInfo): boolean {
		return (colInfo as ICustomColumnInfo).isCustomColumnInfo;
	}

	protected createCustomColumnPanelSpec(colInfo: ICustomColumnInfo): UIPanelSpec {
		if (!colInfo.editModeSpec) {
			return undefined;
		}
		let panelSpec: IUIPanelSpec = {
			type: ItemTypeET.UIPanel,
			layout: colInfo.editModeSpec.layout,
			items: colInfo.editModeSpec.itemSpecs,
		};
		return new UIPanelSpec(panelSpec, undefined);
	}

	protected onCellClick({ isEdited, dataItem, rowIndex, column, columnIndex }: CellClickEvent): void {
		if (
			isEdited
			|| column instanceof CommandColumnComponent
			|| column instanceof CheckboxColumnComponent
			|| !this.canEdit
		) {
			return;
		 }

		this.editRow(rowIndex, columnIndex);
	}

	protected onKeydownEsc(event: any): void {
		this.closeEditor();
	}

	protected onKeydownEnter(event: any): void {
		//event.stopPropagation();
		event.preventDefault();
		this.saveCurrent();
	}

	protected onKeydownArrowDown(event: any): void {
		if (this._editedRowIndex === undefined || this._editedRowIndex === this._gridData.length-1) {
			return;
		}
		//event.preventDefault();
		//event.stopPropagation();
		this.editRow(this._editedRowIndex+1, this._editedColIndex);
		//this.closeEditor();
		/*setTimeout(() => {
			this.editRow(this._editedRowIndex+1, this._editedColIndex);
			//this.saveCurrent();
		});*/
	}

	protected onKeydownArrowUp(event: any): void {
		if (this._editedRowIndex === undefined || this._editedRowIndex === 0) {
			return;
		}
		this.editRow(this._editedRowIndex-1, this._editedColIndex);
	}

	protected getGridRowItemSpec(iCol: number): UIItemSpec {
		if (iCol < 0 || iCol >= this.gridRowItemSpecs.length || !this.gridRowItemSpecs[iCol]) {
			throw new Error(`No ItemSpec for column ${iCol}`);
		}
		return this.gridRowItemSpecs[iCol];
	}

	protected cancelHandler({ sender, rowIndex }: CancelEvent): void {
		this.closeEditor();
	}

	protected removeHandler({ rowIndex }: RemoveEvent): void {
		if (this._editedRowIndex !== undefined || rowIndex < 0 || rowIndex >= this._gridData.length) {
			return;
		}
		// todo: Sicherheitsabfrage
		this._gridData.splice(rowIndex, 1);
		this.updateValidators();
	}

	protected saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
		/*const product = formGroup.value;
		this.service.save(product, isNew);
		sender.closeRow(rowIndex);*/
		this.saveCurrent();
	}

	protected getColumnStyle(colStyle?: ICssStyle, showCursorPointer?: boolean): ICssStyle {
		let style = colStyle ? Object.assign({}, colStyle) : {};
		style.padding = '3px 6px';
		if (showCursorPointer) {
			style.cursor = 'pointer'
		}
		return style;
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		return super.getCustomValidators(systemValidators).pipe(
			map(res => {
				if (this.isMandatory) {
					let pos = systemValidators.indexOf(Validators.required)
					if (pos >= 0) {
						systemValidators.splice(pos);
					}
					let vals = res ? res : [];
					vals.push(toNRelationArrayLengthValidator(1, this.pdObject.pdObjectRaw[this.propertyName]));
					return vals;
				}
				return res;
			})
		);
	}

	protected getCustomColumnCellValue(colInfo: IPDListColumnInfo, obj: PDObject): string {

		let custColInfo = colInfo as ICustomColumnInfo

		if (custColInfo.isCustomColumnInfo && custColInfo.cellValueProvider) {
			return custColInfo.cellValueProvider(obj, this.createEventProcessingContext());
		}
		return 'n.a.';

		/*let relObj = obj[colInfo.field];
		if (!relObj) {
			return '';
		}
		if (!(relObj instanceof PDObject)) {
			console.warn(`Invalid value type for custom column.`);
			return '';
		}
		return obj.getStatus(this.getObjectInfo(colInfo));*/

		/*if (col.cellValueObjectInfo) {
			return obj.getStatus(col.cellValueObjectInfo);
		}
		if (col.cellValueProvider) {
			return col.cellValueProvider(obj, this.createEventProcessingContext());
		}
		return 'n.a.';*/
	}

	protected getPropertyValue(obj: PDObject, prop: string, ctx: IEventProcessingContext): any {
		let wi = this.relationGridWidgetInfo;
		if (wi.fieldNameToPropertyNameAdapter) {
			prop = wi.fieldNameToPropertyNameAdapter(obj, prop, ctx);
		}
		return obj.pdObjectRaw[prop];
	}

	private updateToolbar(): void {
		const tbItemSelect = this.toolbarSpec.items.find(item => item.id == ToolbarItemIds.SelectObjects);
		if (tbItemSelect) {
			tbItemSelect.disabled = this.canChange === false; // || this.isInEditMode;
		}
		const tbItemCreate = this.toolbarSpec.items.find(item => item.id == ToolbarItemIds.CreateObject);
		if (tbItemCreate) {
			tbItemCreate.disabled = this.canCreate === false || this.isInEditMode;
		}
		const tbItemDeleteSelected = this.toolbarSpec.items.find(item => item.id == ToolbarItemIds.DeleteSelectedObjects);
		if (tbItemDeleteSelected) {
			tbItemDeleteSelected.disabled = this.canDelete === false || this.selection.length == 0  || this.isInEditMode;
		}
		const tbItemCreateQuick = this.toolbarSpec.items.find(item => item.id == ToolbarItemIds.CreateObjectsQuick);
		if (tbItemCreateQuick) {
			tbItemCreateQuick.disabled = this.canChange === false || this.isInEditMode;
		}
	}

	private editRow(rowIndex: number, columnIndex: number): void {
		if (!(this._gridData[rowIndex] instanceof CustomPDObject) || this.gridRowFormGroup && !this.gridRowFormGroup.valid) {
			return;
		}

		this.docClickListener(true);

		let custObj = this._gridData[rowIndex] as CustomPDObject;
		this.saveCurrent();
		this.editedRowObject = custObj.clone();
		this._pdObjectEditContext.registerRelationObject(this.id, this.editedRowObject);
		this.gridRowFormGroup = this.createFormGroup();
		this._editedRowIndex = rowIndex;
		this._editedColIndex = columnIndex;
		this._grid.scrollTo({ row: rowIndex, column: columnIndex })
		this._grid.editRow(rowIndex, this.gridRowFormGroup, { columnIndex: columnIndex });
		setTimeout(() => {
			this.gridRowFormGroup.markAsPristine();
		});
	}

	protected isCellEditable(prop: string): boolean {
		let canWrite = this.editedRowObject.metaData.getPropertyAccessRight(PropertyAccessRightET.Write, prop);
		return canWrite !== false;
	}

	private createObject(): void {
		if (this.gridRowFormGroup) {
			if (!this.gridRowFormGroup.valid) {
				return;
			}
			this.closeEditor();
		}
		this.editedRowObject = this.relationGridWidgetInfo.toNRelationSpec.createRelationObjectCallback(this._pdClass) as CustomPDObject;
		this._pdObjectEditContext.registerRelationObject(this.id, this.editedRowObject);
		this.gridRowFormGroup = this.createFormGroup();
		this._grid.addRow(this.gridRowFormGroup);
		this._isNew = true;
		this.docClickListener(true);
	}

	private selectObjects(): void {
		// const relClassName = this.pdObject.metaData.getRelationClassName(this.propertyName);
		const relClassName = this.relationGridWidgetInfo?.selectionClassNameProvider(this, this.createEventProcessingContext());
		if (!relClassName) {
			console.error('Konnte relClassName nicht ermitteln.');
			return;
		}
		let listSpec = this.relationGridWidgetInfo?.selectionListSpec;
		let options: ISelectObjectOptions = {
			width: listSpec && listSpec.width ? listSpec.width : '60vw',
			height: listSpec && listSpec.width ? listSpec.width : '90vh',
			sortExpr: listSpec?.sortExpr
		}

		if (this._selectionListColumnProvider) {
			options.columnInfos = this._selectionListColumnProvider();
		}
		else if (listSpec && listSpec.columns) {
			options.columnInfos = listSpec.columns;
		}

		if (this._customChoiceFilterProvider) {
			let filter = this._customChoiceFilterProvider(this, this.createEventProcessingContext());
			if (typeof(filter) === 'string') {
				options.filterExpr = filter;
			}
		}

		let evalResult: (res: ISelectPDObjectsResult) => void =
			(res) => {
				if (res.selection) {
					let newSelObjs = res.selection.filter(selObj => this._gridData.find(o => o.objectId.oid === selObj.objectId.oid) === undefined)
						.map(o => o as PDObject);
					let currentSelObjs = this._gridData.filter(o => !(o instanceof CustomPDObject));
					let selObjsToRemove = currentSelObjs.filter(o => !res.selection.includes(o))
					selObjsToRemove.forEach(o => {
						let pos = this._gridData.indexOf(o);
						this._gridData.splice(pos, 1);
					});
					this._gridData.push(...newSelObjs);
					this.updateValidators();
				}
			};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectsResult> =
			(title) => {
				let selectedObjs = [...this._gridData];
				if (this._customChoiceProvider) {
					const providerRes = this._customChoiceProvider(this, this.createEventProcessingContext());
					if (providerRes && providerRes.length == 1) {
						options.choiceObjects = <Observable<PDObject[]>>providerRes[0].data;
						return this._interactionService.selectPDObjects(relClassName, title, selectedObjs, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
					}
					// FIXME fehlt komplett, mehrere Quellen
					// const requests$ = providerRes.map(ob => <Observable<PDObject[]>>ob.data);
					//
					// options.choiceObjects = from(requests$).pipe(
					// 	concatAll(),
					// 	toArray(),
					// 	map(two => {
					// 		const newArr: PDObject[] = [];
					// 		two.forEach(a => newArr.push(...a))
					// 		return newArr
					// 	})
					// );
					// return this._interactionService.selectPDObjects(relClassName, title, options).pipe(tap(res => evalResult(res)));
					return of(<ISelectPDObjectsResult>{ selection: [] })
				}
				else if (listSpec && listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this._pdAccessService, this.createEventProcessingContext());
					return this._interactionService.selectPDObjects(relClassName, title, selectedObjs, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
				else {
					return this._interactionService.selectPDObjects(relClassName, title, selectedObjs, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
			};

		if (listSpec) {
			if (listSpec.title) {
				let title = this._localizationService.getFromErgNameData(listSpec.title);
				showSelectDialog(title).subscribe();
				return;
			}
			else if (listSpec.titleId) {
				this._localizationService.getString(listSpec.titleId, { value: relClassName }).pipe(
					switchMap(title => showSelectDialog(title))
				).subscribe();
				return;
			}
		}

		this.metaDataService.getClassErgName(relClassName).pipe(
			switchMap(erg => {
				return this._localizationService.getSystemString('kendo-ui.components.pd-relation-grid.selection-list-title', { value: erg }).pipe(
					switchMap(title => showSelectDialog(title))
				)
			})
		).subscribe();
	}

	removeObjects(oids?: string[]): void {
		if (this.isInEditMode) {
			this.closeEditor();
		}

		if (oids) {
			const newGridData = this._gridData.filter(obj => oids.findIndex(id => obj.objectId.oid === id) == -1);
			this._gridData.splice(0, this._gridData.length, ...newGridData);
		}
		else {
			this._gridData.splice(0);
		}
		this.updateValidators();

	}

	private createObjectsQuick(): void {
		let listSpec = this.relationGridWidgetInfo.quickCreationSpec.selectionListSpec;

		let evalResult: (res: ISelectPDObjectsResult) => void =
		(res) => {
			if (res.selection) {
				let newSelObjs = res.selection.filter(selObj => this._gridData.find(o => o.objectId.oid === selObj.objectId.oid) === undefined)
					.map(o => o as PDObject);
				let relProp = this.relationGridWidgetInfo.quickCreationSpec.to1RelationProperty;
				newSelObjs.forEach(selObj => {
					let relObj = this.relationGridWidgetInfo.toNRelationSpec.createRelationObjectCallback(this._pdClass) as CustomPDObject;
					relObj[relProp] = selObj;
					let defaultValues = this.relationGridWidgetInfo.quickCreationSpec.defaultValues;
					if (defaultValues) {
						// todo: prüfen, ob key ein Property von relObj ist => object.metaData.hasProperty(key)
						for (const [key, value] of Object.entries(defaultValues)) {
							relObj[key] = value;
						}
					}
					this._gridData.push(relObj);
				});
				this.updateValidators();			}
		};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectsResult> =
			(title) => {
				let options: ISelectObjectOptions = {
					width: listSpec && listSpec.width ? listSpec.width : '60vw',
					height: listSpec && listSpec.width ? listSpec.width : '90vh',
					sortExpr: listSpec?.sortExpr
				}
				if (listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this._pdAccessService, this.createEventProcessingContext());
					return this._interactionService.selectPDObjects(listSpec.className, title, undefined, options).pipe(
						tap(res => evalResult(res))
					);
				}
				console.warn('createObjectsQuick(): no pdObjectProvider in listSpec.');
				return EMPTY;
			};

		this.metaDataService.getClassErgName(listSpec.className).pipe(
			switchMap(erg => {
				return this._localizationService.getSystemString('kendo-ui.components.pd-relation-grid.selection-list-title', { value: erg }).pipe(
					switchMap(title => showSelectDialog(title))
				)
			})
		).subscribe();
		//showSelectDialog('Test').subscribe();
	}

	private createFormGroup(): FormGroup {
		let fg = new FormGroup({});
		return fg;
	}

	private saveCurrent(): void {
		if (this.gridRowFormGroup?.valid) {
			let rawVal = this.gridRowFormGroup.getRawValue();
			this.editedRowObject.updateWithRawObject(rawVal);
			if (this._onSaveRowHandler) {
				this._onSaveRowHandler(this.editedRowObject, this, this.createEventProcessingContext());
			}
			if (this._isNew) {
				this._gridData.unshift(this.editedRowObject);
				this.updateValidators();
			}
			else {
				let gridItem = this._gridData[this._editedRowIndex] as CustomPDObject;
				gridItem.assign(this.editedRowObject);
			}
			this.closeEditor();
		}
	}

	private closeEditor(): void {
		/*if (this._editedRowIndex === undefined) {
			return;
		}*/
		this._grid.closeRow(this._isNew ? undefined : this._editedRowIndex);

		this._isNew = false;
		this._editedRowIndex = undefined;
		this._editedColIndex = undefined;
		this.gridRowFormGroup = undefined;
		this.editedRowObject = undefined;
		this._pdObjectEditContext.unregisterRelationObject(this.id);

		this.docClickListener(false);
	}

	private onDocumentClick(e: Event): void {
		if (
			!this._interactionService.isDialogVisible &&
			!this._popupManagerService.popupsVisible &&
			this.gridRowFormGroup &&
			this.gridRowFormGroup.valid &&
			document.body.contains(e.target as Element) &&
			!((e.target as Element).matches(`[id="${this.gridId}"] tbody *, [id="${this.gridId}"] .k-grid-toolbar .k-button`))
		) {
			this.saveCurrent();
		}
	}

	getReadonlyTitle(): Observable<string> {
		return this._localizationService.getSystemString('kendo-ui.components.pd-readonly-template.title');
	}

	getRelationContextForEdit(): IRelationContext {
		let relationPath: string;
		if (this.relationContext) {
			relationPath = this.relationContext.path + '.' + this.relationContext.index + '.' + this.propertyName;
		} else {
			relationPath = this.propertyName;
		}
		return { source: this.pdObject, path: relationPath };
	}

	private docClickListener(active: boolean): void {
		if (active) {
			if (this._docClickListener !== undefined) {
				this._docClickListener(); // unlisten
				this._docClickListener = undefined; // nach ausführen verschwindet die Funktion nicht
			}
			this._docClickListener = this._renderer.listen("document", "click", this.onDocumentClick.bind(this));
		} else {
			if (this._docClickListener !== undefined) {
				this._docClickListener(); // unlisten
				this._docClickListener = undefined; // nach ausführen verschwindet die Funktion nicht
			}
		}
	}
}
