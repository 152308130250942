import { Inject, inject, Injectable, InjectFlags, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { IUIFormSpec } from '@otris/ng-core-types';

export const IFormSpecProviderToken = new InjectionToken<IFormSpecProvider[]>('IFormSpecProviderToken');

export const IRootFormSpecProviderToken = new InjectionToken<IRootFormSpecProvider>(
	'IRootFormSpecProviderToken',
	{ factory: () => new RootFormSpecProvider(inject(IFormSpecProviderToken, InjectFlags.Optional)), providedIn: 'root' }
);

@Injectable()
export abstract class IFormSpecProvider {
	abstract getFormSpec(className: string): IUIFormSpec | undefined;
}

@Injectable()
export abstract class IRootFormSpecProvider extends IFormSpecProvider {
	abstract registerFormSpec(className: string, formSpec: IUIFormSpec): void;
}

@Injectable()
export class RootFormSpecProvider implements IRootFormSpecProvider {

	private _registeredFormSpecs: Map<string, IUIFormSpec> = new Map();

	constructor(@Inject(IFormSpecProviderToken) @Optional() private _formSpecProviders: IFormSpecProvider[]) {

	}

	getFormSpec(className: string): IUIFormSpec {
		if (this._registeredFormSpecs.has(className)) {
			return this._registeredFormSpecs.get(className);
		}

		if (this._formSpecProviders) {
			for (let provider of this._formSpecProviders) {
				let formSpec$ = provider.getFormSpec(className);
				if (formSpec$) {
					return formSpec$;
				};				
			}
		}

		throw new Error(`FormSpec for class '${className}' not found.`);
	}

	registerFormSpec(className: string, formSpec: IUIFormSpec): void {
		/*if (this._registeredFormSpecs.has(className)) {
			throw new Error(`FormSpec for class '${className}' already registered.`);
		}*/
		this._registeredFormSpecs.set(className, formSpec);
	}
}

