import { Directive, Input, TemplateRef } from '@angular/core';
import { UIAbstractDirective } from '@otris/ng-core';

@Directive({
	selector: '[otrisPDPanelHeader]'
})
export class PDPanelHeaderDirective extends UIAbstractDirective {
	@Input('otrisPDPanelHeader') _id: string;

	get id(): string {
		return this._id;
	}

	constructor(template: TemplateRef<any>) {
		super(template);
	}
}
