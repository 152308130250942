import { ASTToken, IGrammarParser } from "../../services/grammar-parser-factory.service";
import { EMPTY, Observable, map, of } from "rxjs";
import { IPDAccessServiceToken, PDObject } from "@otris/ng-core-shared";
import { IEventProcessingContext, IPDAccessService, IDataProvider } from "@otris/ng-core-types";
import { Injector } from "@angular/core";
import { CustomContextExprAction } from "./custom-context-expressions-actions";

type Constructor<T> = { new (...args: any[]): T };

function typeGuard<T>(o: any, className: Constructor<T>): o is T {
	return o instanceof className;
 }

export type DataProviderOperationReturnType = 'PDObject' | 'PDObjectArray' | 'CallOperationResult';

export class DataProviderOperationExpressionHandler {

	static createDataProviderOperation(parser: IGrammarParser, expr: string,
		ctx: IEventProcessingContext, injector: Injector,
		returnType: DataProviderOperationReturnType): Observable<any> {
		
		let handler = new DataProviderOperationExpressionHandler(parser, expr, ctx, injector);
		return handler.createDataProviderOperation(returnType);
	}

	private static readonly DataProviderOperationExprToken = 'DataProviderOperationExpr';

	private _dataProvider: IDataProvider

	get dataProvider(): IDataProvider {
		return this._dataProvider;
	}

	private _astToken: ASTToken;

	private constructor(parser: IGrammarParser, dataProviderExpr: string,
		private _ctx: IEventProcessingContext, private _injector: Injector) {
		// todo: prüfen, ob '\n' noch gebraucht wird
		let adaptExpr = (expr: string) => {
			return expr.trim() + '\n';
		}

		this._astToken = parser.parse(adaptExpr(dataProviderExpr), DataProviderOperationExpressionHandler.DataProviderOperationExprToken);
		if (!this._astToken) {
			console.warn(`Invalid data provider expression '${dataProviderExpr}'.`)
		}
	}

	private test$: Observable<PDObject>;

	private createDataProviderOperation(returnType: DataProviderOperationReturnType): Observable<any> {
		if (!this._astToken) {
			return EMPTY;
		}

		let action = new DataProviderOperationExpressionAction(this._astToken, this._ctx, this._injector); 
		return action.evaluate().pipe(
			map(res => {
				switch (returnType) {
					case 'PDObject':
						if (res instanceof PDObject) {
							return res;
						}
						return undefined;
					case 'PDObjectArray':
						if (Array.isArray(res)) {
							return res.filter(obj => obj instanceof PDObject);
						}
						return [];
					case 'CallOperationResult':
						/*let x: ICallOperationResult<PDObject>;
						if (res instanceof ICallOperationResult<PDObject>) {

						}
						return res;*/
						return undefined;
				}
			})
		);
	}

	//private test(): Observable<PDObject> {
	//	return of(new JafWebPDObject(undefined));
	//}


	/*private createUIStateProvider(parser: IGrammarParser): void {
		this._uiStateProvider = (src, ctx) => {

		}
	}*/
}

// todo: separate Datei
class DataProviderOperationExpressionAction {
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext, private _injector: Injector) {
		if (this._astToken.type !== 'DataProviderOperationExpr') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): Observable<any> {
		let pdAccessServiceToken = this._astToken.children.find(t => t.type === 'PDAccessService'); // todo Konstante		
		if (pdAccessServiceToken) {
			let action = new PDAccessServiceAction(pdAccessServiceToken, this._ctx, this._injector);
			return action.evaluate();
		}
		return EMPTY; // todo: prüfen
	}
}

// todo: separate Datei
class PDAccessServiceAction {	
	//private _pdAccessService: IPDAccessService;

	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext, _injector: Injector) {
		if (this._astToken.type !== 'PDAccessService') { // todo: Konstante
			throw new Error('todo');
		}
		//this._pdAccessService = _injector.get(IPDAccessServiceToken);
	}

	evaluate(): Observable<any> {
		let getExtentToken = this._astToken.children.find(t => t.type === 'PDAccessService_GetExtent'); // todo Konstante
		let getRelationObjectsToken = this._astToken.children.find(t => t.type === 'PDAccessService_GetRelationObjects'); // todo Konstante
		// Todo: weitere Methoden
		if (getExtentToken) {
			let getExtentAction = new PDAccessServiceGetExtentAction(getExtentToken, this._ctx);
			return getExtentAction.evaluate();			
		}
		else if (getRelationObjectsToken) {
			let getRelationObjectsAction = new PDAccessServiceGetRelationObjectsAction(getRelationObjectsToken, this._ctx);
			return getRelationObjectsAction.evaluate();
		}
		else {
			throw new Error('todo');
		}
	}
}

// todo: separate Datei
class PDAccessServiceGetExtentAction {	
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
		if (this._astToken.type !== 'PDAccessService_GetExtent') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): Observable<PDObject[]> {
		let classNameToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_ClassName'); // todo Konstante
		let filterToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_Filter'); // todo Konstante
		if (!classNameToken) {
			throw new Error('todo');
		}
		let filter: string;
		if (filterToken) {
			let filterAction = new PDAccessServiceParamFilterAction(filterToken, this._ctx);
			filter = filterAction.evaluate();
		}
		let classNameAction = new PDAccessServiceParamClassNameAction(this._astToken.children[2]);
		return this._ctx.pdAccessService.getExtent(classNameAction.evaluate(), filter).pipe(
			map(res => res as PDObject[])
		);
	}
}

// todo: separate Datei
class PDAccessServiceGetRelationObjectsAction {	
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
		if (this._astToken.type !== 'PDAccessService_GetRelationObjects') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): Observable<PDObject[]> {
		// Base-Object
		let baseObjectToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_BaseObject'); // todo Konstante
		if (!baseObjectToken) {
			throw new Error('todo');
		}
		let baseObjectAction = new  PDAccessServiceParamBaseObjectAction(baseObjectToken, this._ctx);
		let baseObject = baseObjectAction.evaluate();
		if (!baseObject) {
			return of([]);
		}
		
		// RelationName
		let relationNameToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_Relation'); // todo Konstante
		if (!relationNameToken) {
			throw new Error('todo');
		}
		let relationAction = new PDAccessServiceParamRelationAction(relationNameToken);
		let relationName = relationAction.evaluate();

		// Filter
		let filter: string;
		let paramFilterToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_Filter'); // todo Konstante
		if (paramFilterToken) {
			let paramFilterAction = new PDAccessServiceParamFilterAction(paramFilterToken, this._ctx);
			filter = paramFilterAction.evaluate();
		}

		// Sort
		let sort: string;
		let paramSortToken = this._astToken.children.find(t => t.type === 'PDAccessService_Param_Sort'); // todo Konstante
		if (paramSortToken) {
			let paramSortAction = new PDAccessServiceParamSortAction(paramSortToken);
			sort = paramSortAction.evaluate();
		}

		return this._ctx.pdAccessService.getRelationObjects(baseObject, relationName, filter, sort).pipe(
			map(res => res as PDObject[])
		);
	}
}

// todo: separate Datei
class PDAccessServiceParamBaseObjectAction {	
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
		if (this._astToken.type !== 'PDAccessService_Param_BaseObject') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): PDObject | undefined {
		let customContextExprToken = this._astToken.children.find(t => t.type === 'CustomContextExpr'); // todo Konstante
		let baseObj: PDObject;
		if (customContextExprToken) {
			let customContextExprAction = CustomContextExprAction.create(customContextExprToken, this._ctx);
			let res = customContextExprAction.evaluate();
			if (res instanceof PDObject) {
				baseObj = res;
			}
		}
		return baseObj;
	}
}

// todo: separate Datei
class PDAccessServiceParamClassNameAction {	
	constructor(private _astToken: ASTToken) {
		if (this._astToken.type !== 'PDAccessService_Param_ClassName') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): string {
		let backendClassToken = this._astToken.children.find(t => t.type === 'BackendClassName'); // todo Konstante
		return backendClassToken.text;
	}
}

// todo: separate Datei
class PDAccessServiceParamRelationAction {	
	constructor(private _astToken: ASTToken) {
		if (this._astToken.type !== 'PDAccessService_Param_Relation') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): string {
		let backendRelationNameToken = this._astToken.children.find(t => t.type === 'BackendRelationName'); // todo Konstante
		return backendRelationNameToken.text;
	}
}

// todo: separate Datei
class PDAccessServiceParamFilterAction {	
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
		if (this._astToken.type !== 'PDAccessService_Param_Filter') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): string {
		let filterToken = this._astToken.children.find(t => t.type === 'FilterExpr'); // todo Konstante
		if (!filterToken) {
			throw new Error('todo');
		}
		let filterExprAction = new FilterExprAction(filterToken, this._ctx);
		return filterExprAction.evaluate();
	}
}

// todo: separate Datei
class FilterExprAction {	
	constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
		if (this._astToken.type !== 'FilterExpr') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): string {
		let backendfilterToken = this._astToken.children.find(t => t.type === 'BackendFilterExpr'); // todo Konstante
		if (!backendfilterToken) {
			throw new Error('todo');
		}
		return backendfilterToken.text.replaceAll('`', '\'');
	}
}

// todo: separate Datei
class PDAccessServiceParamSortAction {	
	constructor(private _astToken: ASTToken) {
		if (this._astToken.type !== 'PDAccessService_Param_Sort') { // todo: Konstante
			throw new Error('todo');
		}
	}

	evaluate(): string {
		let backendSortExprToken = this._astToken.children.find(t => t.type === 'BackendSortExpr'); // todo Konstante
		return backendSortExprToken.text;
	}
}
