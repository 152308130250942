import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { LocalizationService, LocalizationServiceToken } from '@otris/ng-core';
import { PDObject } from '@otris/ng-core-shared';
import { ICssStyle } from '@otris/ng-core-types';
import { Observable, forkJoin, of } from 'rxjs';
import  { map, switchMap } from 'rxjs/operators';

// fa-exclamation
@Component({
	selector: 'otris-pd-error-indicator',
	template: `
		<div class="image error-indicator " kendoTooltip filter="span" [ngStyle]="customStyle">
			<span class="fa fa-fw fa-exclamation-triangle" title="{{errorMessage | async}}"></span>
		</div>
	`,
	styles: [`
		:host {
			display: flex;
			justify-content: center;
			align-items: center;
			/*align-self: flex-start;*/
		}
		.image {
			cursor: help;
		}
		.error-indicator {
			/*margin-left: 0.5em;*/
			/*padding-top: 1px;*/
			color: red;
		}
	`]
})
export class PDErrorIndicatorComponent implements OnInit {

	//@Input() margin: string;

	@Input() relatedFormControl: AbstractControl;

	@Input() pdObject: PDObject;

	@Input() propertyName: string;

	@Input() customErrorMessage$: Observable<string>;

	@Input() customStyle: ICssStyle;

	get errorMessage(): Observable<string> {
		if (this.customErrorMessage$) {
			return this.customErrorMessage$;
		}

		let ctrl = this.relatedFormControl;
		if (ctrl && ctrl.errors) {
			let errMsgs: Observable<string>[] = [];
			if (ctrl.errors.required) {
				let msgKey = ctrl instanceof UntypedFormArray ? 'kendo-ui.components.pd-error-indicator.required-relation' :
					'kendo-ui.components.pd-error-indicator.required';
				let requiredErrMsg$ = this.localizationService.getSystemString(msgKey);
				errMsgs.push(requiredErrMsg$);
			}
			if (ctrl.errors.maxlength) {
				let maxLengthErrMsg$ = this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
					switchMap(length => {
						return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.max-length', { value: length });
					})
				);
				errMsgs.push(maxLengthErrMsg$);
			}
			if (ctrl.errors.validateEmail === true) {
				let validateEmailErrMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.validate-email');
				errMsgs.push(validateEmailErrMsg$);
			}
			if (ctrl.errors.objectReferenceTextFieldMaxLength) {
				let objRefMaxLengthErrMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.objectReference-text-field-max-length',
					{ value : ctrl.errors.objectReferenceTextFieldMaxLength.value });
					errMsgs.push(objRefMaxLengthErrMsg$);
			}
			if (ctrl.errors.multiSelectTextFieldMaxLength) {
				let multiSelectMaxLengthErrMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.multi-select-text-field-max-length',
					{ value : ctrl.errors.multiSelectTextFieldMaxLength.value });
					errMsgs.push(multiSelectMaxLengthErrMsg$);
			}
			if (ctrl.errors.toNRelationArrayLength) {
				if (ctrl.errors.toNRelationArrayLength.minLength) {
					let toNRelationArrayLengthMinErrMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.to-n-relation-array-length-min',
					{ value : ctrl.errors.toNRelationArrayLength.minLength });
					errMsgs.push(toNRelationArrayLengthMinErrMsg$);
				}
			}

			if (ctrl.errors.customErrorIds) {
				if (Array.isArray(ctrl.errors.customErrorIds))
				{
					errMsgs.push(...ctrl.errors.customErrorIds.map(item => this.localizationService.getString(item)));
				}
			}

			/* funktioniert nicht, wird über errors.required abgehandelt
			if (ctrl.errors.requiredTrue === true) {
				let errMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.required-true');
				errMsgs.push(errMsg$);
			}*/
			if (errMsgs.length > 0) {
				return forkJoin(errMsgs).pipe(
					map(res => {
						let msg = '';
						res.forEach(s => {
							if (msg.length > 0) {
								msg += ' '
							}
							msg += s;
						});
						return msg;
					})
				);
			}
			
			/*if (ctrl.errors.required) {
				return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.required');
			}
			if (ctrl.errors.maxlength) {
				return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
					switchMap(length => {
						return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.max-length', { value: length });
					})
				);
			}*/
		}
		return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.unknown');
	}

	constructor(@Inject(LocalizationServiceToken) private localizationService: LocalizationService) { }

	ngOnInit() {
		/*this.localizationService.changeHandler.pipe(
			switchMap(r => this.localizationService.getSystemString('kendo-ui.components.mandatory-indicator.title').map(trans => this.title = trans))
		).subscribe();*/
	}

}
