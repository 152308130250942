import { ChangeDetectorRef, Component, HostBinding, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, defer, EMPTY, forkJoin, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { MultiSelectComponent, PreventableEvent, RemoveTagEvent } from '@progress/kendo-angular-dropdowns';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';
import { TextAreaComponent, TextBoxComponent } from '@progress/kendo-angular-inputs';

import {
	EventProcessingContextManagerService,
	FormHandlerService,
	IPDObjectEditContext,
	IPDObjectEditContextToken,
	IPopupManagerService,
	IPopupManagerServiceToken,
	MainUIService,
	MultiSelectRelationWidgetInfo,
	PDLabeledControlComponent,
	IChangeDetectionController,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET,
	IComponent,
	IComponentUIState,
	ICustomChoiceProviderResult,
	IEnumerationItem,
	IEventProcessingContext,
	IInteractionService,
	ILocalizationService,
	IMultiSelectComponent,
	IPDAccessService,
	IPDChoiceSpec,
	IPDListColumnInfo,
	IPDObject,
	IRelationComponent,
	ISelectObjectOptions,
	ISelectPDObjectsResult,
	IToolBarButtonSpec,
	IToolBarItemResult,
	IToolBarItemSpec,
	MultiSelectRelationWidgetTypeET,
	MultiSelectValueTypeET,
	ToolBarItemTypeET,
	TypeET,
	MultilineTextFieldResizingType,
	CustomChoiceProvider,
	MultiSelectComponentEventName
} from '@otris/ng-core-types';
import {
	CustomPDObject,
	IInteractionServiceToken,
	IPDAccessServiceToken,
	PDObject,
	ServiceLocator,
	IPDClassToken
} from '@otris/ng-core-shared'
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * Beschreibt ein Objekt, welches in der Auswahl steht oder ausgewählt wurde.
 */
interface IChoiceData {
	id: string;
	ergName: string;
	value: PDObject | string | IEnumerationItem;
	isCustomPDObject?: boolean;
	itemNotInChoice?: boolean;
	isNew?: boolean;
	className?: string;
	classErgName?: string;
}

enum ToolBarButtonIdET {
	Select = 'idSelect',
	New = 'idNew',
	SelectTextTemplate = 'idSelectTextTemplate'
}

export function multiSelectTextFieldMaxLengthValidator(maxLength: number, textField: TextAreaComponent | TextBoxComponent): ValidatorFn {
	return (): ValidationErrors | null => {
		const textFieldMaxLength = textField.value?.length > maxLength;
		return textFieldMaxLength ? { multiSelectTextFieldMaxLength: { value: maxLength } } : null;
	};
 }


/**
	Das PDMultiSelect ist ein Widget welches PDObjects oder Strings entgegennimmt, um daraus
	eine Mehrfachselektion zu realisieren. Diese Selektion erfolgt über eine einfache Dropdownlist
	oder über eine Overlay, indem direkt mehrere Objekte selektiert werden können. Letzteres ist
	für Strings-Input nicht implementiert.
	Todos:
	- Falls ein CustomChoiceProvider angegeben ist, sollte es nicht notwendig sein eine choiceSpec anzugeben
 */
@Component({
	selector: 'otris-pd-multi-select',
	template: `
		@switch (multiSelectRelationWidgetType) {
			@case (multiSelectRelationWidgetTypeET.SelectionList) {
				<otris-pd-labeled-control-frame
					[labeledControl]="this"
					(toolBarButtonClick)="onToolBarButtonClick($event)"
					[pdObject]="pdObject"
					[relatedFormControl]="this.control"
					[id]="getId() + '.multiselect'"
				>
					@if (isChangeable) {
						<kendo-multiselect #kendoMultiSelect class="multi-select"
							[textField]="'ergName'"
							[valueField]="'value'"
							[filterable]="false"
							[disabled]="isDisabled"
							[value]="selectedValues"
							(valueChange)="onDropDownValueChange($event)"
							(open)="onOpen($event)"
							(removeTag)="onRemoveItem($event)"
							[id]="getId() + '.multiselect2'"
							[clearButton]="showClearButton"
							[showStickyHeader]="showStickyHeader"
						>
							<ng-template kendoMultiSelectTagTemplate let-dataItem>
								<ng-container *ngTemplateOutlet="tagTemplate; context: { $implicit: dataItem }">
								</ng-container>
							</ng-template>

							<kendo-multiselect-messages
								[noDataText]="noDataTextMessage"
								[clearTitle]="clearTitleMessage"
							/>
						</kendo-multiselect>
						@if (!isDisabled) {
							<otris-tool-bar
								class="toolbar"
								(buttonClick)="onToolBarButtonClick($event)"
								[itemSpecs]="toolBarItems"
							/>
						}
					} @else {
						<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
					}
				</otris-pd-labeled-control-frame>
			} <!-- *ngSwitchCase="multiSelectRelationWidgetTypeET.SelectionList" -->

			@case (multiSelectRelationWidgetTypeET.TextField) {
				<otris-pd-labeled-control-frame
					[labeledControl]="this"
					[pdObject]="pdObject"
					[relatedFormControl]="this.control"
					(toolBarButtonClick)="onToolBarButtonClick($event)"
				>
					@if (isChangeable) {
						<div class="input-text">
							@if (textFieldMultiline) {
								@if (!isHidden) {
									<kendo-textarea #textField
										[value]="selectionAsText"
										(valueChange)="onTextboxChange($event)"
										(keyup)="onKeyUp($event)"
										(keydown)="onKeyDown($event)"
										class="kendo-textarea-style"
										[resizable]="textFieldResizingType"
										[rows]="textFieldRows"
										flow="horizontal"
									>
										@if (multiSelectWidgetInfo?.textFieldSpec?.showClearButton) {
											<kendo-textarea-suffix>
												<button
													kendoButton
													type="button"
													class="textarea-clear-button"
													fillMode="clear"
													[svgIcon]="closeSVG"
													(click)="onTextboxChange(undefined)"
												>
												</button>
											</kendo-textarea-suffix>
										}
									</kendo-textarea>
								}
							} @else {
								<kendo-textbox
									#textField
									[value]="selectionAsText"
									[clearButton]="multiSelectWidgetInfo?.textFieldSpec?.showClearButton"
									(valueChange)="onTextboxChange($event)"
									(keyup)="onKeyUp($event)"
									(keydown)="onKeyDown($event)"
								/>
							}
						</div>
					} @else {
						<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
					}
					</otris-pd-labeled-control-frame>
			}
			@case (multiSelectRelationWidgetTypeET.DropDownList) {
				<otris-pd-labeled-control-frame
					[labeledControl]="this"
					(toolBarButtonClick)="onToolBarButtonClick($event)"
					[pdObject]="pdObject"
					[relatedFormControl]="this.control"
				>
					@if (isChangeable) {
						<kendo-multiselect
							#kendoMultiSelect
							class="multi-select"
							[data]="filteredChoiceData"
							[textField]="'ergName'"
							[valueField]="'value'"
							[value]="selectedValues"
							(valueChange)="onDropDownValueChange($event)"
							[checkboxes]="showCheckboxes"
							[autoClose]="false"
							[filterable]="true"
							[disabled]="isDisabled"
							[readonly]="isReadonly || canChange===false"
							[clearButton]="showClearButton"
							[loading]="isLoading"
							(filterChange)="onFilterChange($event)"
							(removeTag)="onRemoveItem($event)"
							(open)="onOpen($event)"
							[showStickyHeader]="showStickyHeader"
						>
							<ng-template kendoMultiSelectTagTemplate let-dataItem>
								<ng-container *ngTemplateOutlet="tagTemplate; context: { $implicit: dataItem }">
								</ng-container>
							</ng-template>

							<ng-template kendoMultiSelectItemTemplate let-dataItem>
								<span>{{ dataItem.ergName }}</span>
								@if (dataItem.itemNotInChoice) {
									<span class="multi-select-item-icon"><i class="fa fa-lock"></i></span>
								}
							</ng-template>

							<kendo-multiselect-messages
								[noDataText]="noDataTextMessage"
								[clearTitle]="clearTitleMessage"
							>
							</kendo-multiselect-messages>
						</kendo-multiselect>
						@if (!isDisabled && toolBarItems?.length>0) {
							<otris-tool-bar
								class="toolbar"
								(buttonClick)="onToolBarButtonClick($event)"
								[itemSpecs]="toolBarItems"
							/>
						}
					} @else {
						<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
					}
				</otris-pd-labeled-control-frame>
			}
		}
		<ng-template #tagTemplate let-dataItem>
			<div class="tag-template">
				@if (dataItem.isCustomPDObject && dataItem.isNew) {
					<div class="tag-template-icon">
						<i class="fa fa-fw fa-star"></i>
					</div>
				}
				@if (dataItem.isCustomPDObject && !dataItem.isNew) {
					<div class="tag-template-icon">
						<i class="fa fa-fw fa-cube"></i>
					</div>
				}
				@if (!dataItem.isCustomPDObject) {
					<div class="tag-template-icon">
						<i class="fa fa-fw fa-bars"></i>
					</div>
				}
				<!-- Debug Anzeige <div>{{dataItem.value.active}}</div> -->
				<div class="tag-text">{{dataItem.ergName}}</div>
				@if (dataItem.isCustomPDObject) {
					<div class="tag-template-button"
						(click)="onEditCustomObjectClick(dataItem.value)"
						(mouseenter)="onTagTemplateButtonEnter()"
						(mouseleave)="onTagTemplateButtonLeave()"
					>
						<i class="fa fa-fw fa-pencil"></i>
					</div>
				}
			</div>
		</ng-template>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		:host {
			display: flex;
		}
		.multi-select {
			flex: 1;
		}
		.toolbar {
			height: 100%;
		}
		.tag-template {
			display: flex;
		}
		.tag-template-icon {
			margin-right: 0.5em;
		}
		.tag-template-icon > i {
			vertical-align: middle;
		}
		.tag-template-button {
			margin-left: 0.5em;
			color: gray;
		}
		.tag-template-button:hover {
			color: unset;
		}
		.tag-template-button > i {
			vertical-align: middle;
		}
		.tag-text {
			white-space: break-spaces;
			margin: 0.125em 0.3em 0.125em 0;
		}
		.multi-select-item-icon {
			margin-left: auto;
		}
		.textarea-clear-button {
			background: none;
			border: none;
			color: grey;
		}
		.input-text {
			flex: 1;
			display: flex;
		}
		.textarea-clear-button:hover {
			color: black;
		}
	`]
})
export class PDMultiSelectComponent extends PDLabeledControlComponent implements IMultiSelectComponent {

	protected closeSVG = xIcon;

	private _kendoMultiSelect: MultiSelectComponent;

	@ViewChild('kendoMultiSelect')
	set kendoMultiSelect(element: MultiSelectComponent) {
		if (this._popupDisabled || this.multiSelectRelationWidgetType == MultiSelectRelationWidgetTypeET.SelectionList) {
			element?.wrapper.nativeElement.querySelector('.k-input-inner').setAttribute('disabled', '');
		}
		this._kendoMultiSelect = element;
	};

	//@ViewChild('textField') textFieldComponent: TextAreaComponent | TextBoxComponent;

	@ViewChild('textField')
	set textFieldComponent(comp: TextAreaComponent | TextBoxComponent) {
		if (comp != this._textFieldComponent) {
			this._textFieldComponent = comp;
			this.updateValidators();
		}
	}

	get textFieldComponent(): TextAreaComponent | TextBoxComponent {
		return this._textFieldComponent;
	}

	private _textFieldComponent: TextAreaComponent | TextBoxComponent;

	get kendoMultiSelect(): MultiSelectComponent {
		return this._kendoMultiSelect;
	}

	@Input() showCheckboxes = true;

	@Input() selectEnabled = true;

	/**
	 * Die zur Auswahlstehenden Objekte
	 */
	private _choiceData: IChoiceData[] = [];

	private _choiceDataValid = false;

	private get choiceDataValid(): boolean {
		return this._choiceDataValid;
	}

	private set choiceDataValid(val: boolean) {
		if (val != this._choiceDataValid) {
			this._choiceDataValid = val;
			if (!val) {
				this._choiceData = [];
				this._choiceDataMap.clear();
			}
		}
	}

	private _choiceFilter: string;

	private _choiceDataMap: Map<string, IChoiceData> = new Map<string, IChoiceData>();

	private _choiceUpdatedSubject$: Subject<void> = new Subject();

	private _choiceUpdatedSubjectUiState$: Subject<void> = new Subject();

	private _choiceUpdated$: Observable<void>;

	get choiceUpdated$(): Observable<void> {
		if (!this._choiceUpdated$) {
			this._choiceUpdated$ = this._choiceUpdatedSubject$.asObservable();
		}
		return this._choiceUpdated$;
	}

	protected filteredChoiceData: GroupResult[] | IChoiceData[] = [];

	/**
	 * liefert alle Selektierten Objekte des Multiselect
	 */
	protected get selectedValues(): IChoiceData[] {
		return this._selectedValues;
	}

	/**
	 * Überschreibt die bestehende Selektion mit dem übergebenen Array.
	 * Aktualisiert das control und das PDObject mit onSelectedValuesChange().
	 */
	protected set selectedValues(values: IChoiceData[]) {
		this._selectedValues = values;
		if (values.length === 0) {
			// Wenn Einzelne entfernt werden, wird bereits removeCreatedObject() aufgerufen
			this.mainUIService.clearCreatedObjects();
		}
		this.onSelectedValuesChange();
	}

	private _selectedValues: IChoiceData[] = []; // umbenennen in _selectedItems

	private get customPDObjects(): IChoiceData[] {
		return this._selectedValues.filter(val => val.isCustomPDObject)
	}

	protected get multiSelectWidgetInfo(): MultiSelectRelationWidgetInfo {
		if (this.pdItemSpec.widgetInfo instanceof MultiSelectRelationWidgetInfo) {
			return <MultiSelectRelationWidgetInfo>this.pdItemSpec.widgetInfo;
		}
		return undefined;
	}

	/**
	 * Spezifikation der Auswahlobjekte. Ein Multiselect kann mehrere Unterschiedliche haben.
	 */
	private _choiceSpecs: IPDChoiceSpec[] = [];

	private _groupChoiceData = false;

	multiSelectRelationWidgetTypeET = MultiSelectRelationWidgetTypeET;

	get multiSelectRelationWidgetType(): MultiSelectRelationWidgetTypeET {
		let wi = this.multiSelectWidgetInfo;
		return wi ? wi.type : MultiSelectRelationWidgetTypeET.DropDownList;
	}

	toolBarItems: IToolBarItemSpec[];

	private _choiceDataAction$: Observable<IChoiceData[]>;

	canChange: boolean;

	get isChangeable(): boolean {
		return this.isReadonly !== true && this.canChange !== false;
	}

	private get mainUIService(): MainUIService {
		return ServiceLocator.injector.get(MainUIService);
	}

	private _enumName: string;

	//private _enum: AbstractEnumeration;

	get isContainerComponent(): boolean {
		return false;
	}

	private _popupDisabled = false;

	noDataTextMessage = "No Data Found";
	clearTitleMessage = "Clear";

	get showClearButton(): boolean {
		return this.multiSelectWidgetInfo?.showClearButton ?? true;
	}

	protected isLoading = false;

	private _classErgNames: Map<string, string> = new Map();

	private _subClasses: Map<string, string[]> = new Map();

	@HostBinding('attr.ot-selected-values') get getSelectedValuesForHtml() {
		const valuesStringArray: string[] = [];
		this._selectedValues?.forEach(selection => {
			const selectionValue = selection.value;
			switch(this._valueType) {
				case MultiSelectValueTypeET.PDObject:
					valuesStringArray.push((selectionValue as PDObject).objectId.oid);
					break;
				case MultiSelectValueTypeET.String:
					valuesStringArray.push(selectionValue as string);
					break;
				case MultiSelectValueTypeET.EnumerationItem:
					const valueEnumConst = (selectionValue as IEnumerationItem).enumConst;
					valuesStringArray.push(valueEnumConst === 'string' ? valueEnumConst as string : valueEnumConst.toString());
					break;
				default:
					valuesStringArray.push('Unknown type');
					break;
			}
		})
		return valuesStringArray.length > 0 ? valuesStringArray.join(';') : '';
	}

	protected get selectionAsText(): string {
		if (this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.TextField) {
			throw new Error(`selectionAsText is only accessible in widget mode 'TextField'`)
		}
		return this.customPDObjects.length === 1 ? this.customPDObjects[0].ergName : '';
	}

	textFieldRows = 5;

	textFieldMultiline = false;

	textFieldResizingType: MultilineTextFieldResizingType = 'none';

	showStickyHeader = false;

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IPopupManagerServiceToken) private popupManagerService: IPopupManagerService,
		@Inject(IPDObjectEditContextToken) private pdObjectEditContext: IPDObjectEditContext,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		private _changeDetectionController: IChangeDetectionController,
		private _cdref: ChangeDetectorRef
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		
		//console.log(`ngOnInit: ${this.control.pristine}`)
		//this.control.markAsPristine();

		let wi = this.multiSelectWidgetInfo;
		if (wi) {

			if (wi.selectionChangedHandler) {
				this.addSubscription(this.selectionChanged$.subscribe(res => {
					wi.selectionChangedHandler(this, res, this.createEventProcessingContext());
				}));
			}
			if (wi.customChoiceFilterProvider) {
				this.customChoiceFilterProvider = wi.customChoiceFilterProvider;
			}
			if (wi.customChoiceProvider) {
				this.setCustomChoiceProviderImpl(wi.customChoiceProvider, false);
				//this.customChoiceProvider = wi.customChoiceProvider;
			}
			if (wi.showCheckboxes !== undefined) {
				this.showCheckboxes = wi.showCheckboxes === true;
			}
			if (wi.selectAction !== undefined) {
				this.selectEnabled = wi.selectAction === true;
			}
			if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.TextField) {
				this.textFieldMultiline = wi.textFieldSpec?.multiline === true ||
					typeof(wi.textFieldSpec?.multiline) === 'object';
				if (typeof(wi.textFieldSpec?.multiline) === 'object') {
					if (wi.textFieldSpec?.multiline?.rows > 0) {
						this.textFieldRows = wi.textFieldSpec.multiline.rows;
					}
					if (wi.textFieldSpec?.multiline?.resizingType) {
						this.textFieldResizingType = wi.textFieldSpec.multiline.resizingType;
					}
				}
			}
			if (wi.showStickyHeader !== undefined) {
				this.showStickyHeader = wi.showStickyHeader === true;
			}
		}

		this.addSubscription(this.formGroup.valueChanges.subscribe(v => {
			if (v.hasOwnProperty(this.propertyName) && !v[this.propertyName]) {
				//this.selectedValues = this._emptySelectedValues;
				if (this.kendoMultiSelect) {
					this.kendoMultiSelect.reset();
				}
			}
		}));

		this.toolBarItems = [];
		if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.SelectionList) {
			if (this.selectEnabled) {
				this.toolBarItems.push(
					<IToolBarButtonSpec>{
						id: ToolBarButtonIdET.Select, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-list',
						shortDescriptionId: 'system.kendo-ui.components.pd-multi-select.tool-bar-button-select'
						}
				);
			}
		}

		if (wi?.newAction) {
			this.toolBarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-star',
					shortDescriptionId: 'system.kendo-ui.components.pd-multi-select.tool-bar-button-new'
				}
			);
		}

		if (wi?.textTemplateSelection) {
			let tbItemSelectTemplate: IToolBarButtonSpec = {
				type: ToolBarItemTypeET.Button,
				id: ToolBarButtonIdET.SelectTextTemplate,
				shortDescriptionId: 'system.kendo-ui.components.pd-multi-select.button-text-template-selection.short-description',
				iconClass: 'fa fa-lg fa-list',
			}
			this.addToolbarItems('start', tbItemSelectTemplate);
			this.toolbarButtonClick.pipe(
				switchMap(item => {
					if (item.id === ToolBarButtonIdET.SelectTextTemplate) {
						return this.selectTextTemplate();
					}
					return of(undefined);
				})
			).subscribe();
		}

		/*if (this.multiSelectRelationWidgetType == MultiSelectRelationWidgetTypeET.TextField) {
			if (wi?.textFieldSpec?.multiline)
			this.textFieldRows =
		}*/

		// this._hasChoiceGroups = this.choiceSpecs.length > 1;

		let langChangeHandler$ = this.localizationService.changeHandler.pipe(
			switchMap(() => {
				if (this._valueType === MultiSelectValueTypeET.EnumerationItem) {
					// Das Enum muss erst mit updateChoiceImpl() aktualisiert werden, da updateChoiceSpecs sonst
					// die Werte löscht.
					return of(undefined);
				}
				return this.updateChoiceSpecs()
			}), // In den specs stehen eventuell Sprachgesteuerte Werte.
			tap(() => this.choiceDataValid = false),
			switchMap(() => {
				if (
					this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList &&
					this._valueType === MultiSelectValueTypeET.EnumerationItem
				) {
					return this.updateChoiceImpl();
				}
				return of(undefined);
			}),
			tap(() => {
				this.updateSelectedItemsErgName();
			}),
			// Widget
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-multi-select.no-data-found-message',
				'kendo-ui.components.pd-multi-select.clear-title-message'
			])),
			tap(trans => {
				this.noDataTextMessage = trans[0]
				this.clearTitleMessage = trans[1]
			})
		)
		this.addSubscription(langChangeHandler$.subscribe());

		// todo: id statt propertyName verwenden?
		this.addSubscription(this.pdObjectEditContext.getRelationObjectCreated(this.propertyName).subscribe(
			data => {
				if (data[2] == this.pdObject.objectId.oid) {
					let obj = data[1];
					let wi = this.multiSelectWidgetInfo;
					obj.remoteCreationParams = wi.editingSpec.remoteCreationParams;
					let newItem: IChoiceData = {
						id: obj.oid,
						className: obj.getClassName(),
						isNew: true,
						isCustomPDObject: true,
						ergName: obj.getStatus(wi.editingSpec.objectInfo),
						value: obj
					};
					this._selectedValues.push(newItem);
					this._selectedValues = [...this.selectedValues]; // Trigger cd
					this.onSelectedValuesChange();
					//this._createdPDObjects.push(newItem);
					//this.updateSelectedAndCreatedValues();
					//this.onSelectedValuesChange();
					//this.updateChoiceData();
					//this.onItemsChanged(true);
					this.mainUIService.addCreatedObject(obj);
				}
			}
		));

		this.addSubscription(this.pdObjectEditContext.getRelationObjectEdited(this.id).subscribe(
			data => {
				if (data[2] == this.pdObject.objectId.oid) {
					let custObj = data[1];
					let item = this.customPDObjects.find(i => {
						return (i.value as CustomPDObject).objectId.oid === custObj.objectId.oid;
					})
					if (item) {
						(item.value as CustomPDObject).assign(custObj);
						item.ergName = custObj.getStatus(wi.editingSpec.objectInfo)
					}
				}
			}
		));

		this.applyAccessRights();
		this.updateToolbar();
		this.updateChoiceSpecs().subscribe();
	}

	ngAfterViewInit() {
		//console.log(`ngAfterViewInit: ${this.control.pristine}`);

		super.ngAfterViewInit();

		//console.log(`ngAfterViewInit: ${this.control.pristine}`);
	}

	ngOnDestroy() {
		this.removeAllCreatedItems();
		this._selectedValues = [];

		if (this._choiceDataAction$) {
			this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
		}

		/*if (this.subscription) {
			this.subscription.unsubscribe();
		}*/
		super.ngOnDestroy();
	}

	onEditCustomObjectClick(custObj: CustomPDObject): void {
		this.editItem(custObj);
	}

	onTagTemplateButtonEnter(): void {
		this._popupDisabled = true;
	}

	onTagTemplateButtonLeave(): void {
		this._popupDisabled = false;
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		this._enumName = undefined;
		if (this.pdObject && this.propertyName) {
			let type = this.pdObject.metaData.getPropertyType(this.propertyName);
			switch (type) {
				case TypeET.StringArray:
					this._valueType = MultiSelectValueTypeET.String;
					// ...
					// todo
					break;
				case TypeET.RelationToN:
					this._valueType = MultiSelectValueTypeET.PDObject;
					this.updateChoiceData(false);
					this.updateSelectedValues();
					break;
				case TypeET.EnumItemArray:
					this._valueType = MultiSelectValueTypeET.EnumerationItem;
					this._enumName = this.pdObject.metaData.getEnumPropertyEnumName(this.propertyName);
					if (!this._enumName) {
						throw new Error(`No enumName defined for property '${this.propertyName}'`);
					}
					this.isLoading = true;
					this.updateChoiceImpl().pipe(
						tap(() => {
							this.isLoading = false
						})
					).subscribe()
					break;
				default:
					throw new Error(`Inappropriate property type ${type}`);
			}
		}
		else {
			this._valueType = undefined;
		}

		// this.updateSelectedValues();
		// this.updateCustomPDObjectItems();
	}

	protected onTextboxChange(val: string): void {
		if (Array.isArray(this.pdObject[this.propertyName]) &&
			(this.pdObject[this.propertyName].length > 1 ||
			(this.pdObject[this.propertyName].length === 1 &&
			!(this.pdObject[this.propertyName][0] instanceof CustomPDObject)))) {
			return;
		}

		let ctrl = this.control;
		if (ctrl) {
			ctrl.updateValueAndValidity();
		}

		if (!val) {
			this.pdObject[this.propertyName] = [];
		}
		else {
			let wi = this.multiSelectWidgetInfo;
			if (this.pdObject[this.propertyName].length === 1) {
				this.pdObject[this.propertyName][0][wi.editableRelationProperty] = val;
			}
			else {
				let pdClass = ServiceLocator.injector.get(IPDClassToken);
				let newObj = pdClass.createInstance(wi.editingSpec.className as string) as CustomPDObject;
				newObj.remoteCreationParams = wi.editingSpec.remoteCreationParams;
				newObj[wi.editableRelationProperty] = val;
				this.pdObject[this.propertyName] = [newObj];
			}
		}
		//this.updateSelectedValues();
		// this.updateCustomPDObjectItems();
	}

	protected onKeyUp(args: any): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(false);
	}

	protected onKeyDown(args: any): void {
		if (args.key === 'Tab' || args.key === 'Enter') {
			return;
		}
		this._changeDetectionController.disableChangeDetection(true);
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		//this.applyAccessRights();
		this.updateToolbar();
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		if (this.multiSelectRelationWidgetType == MultiSelectRelationWidgetTypeET.TextField) {
			return super.getCustomValidators(systemValidators).pipe(
				map(res => {
					let wi = this.multiSelectWidgetInfo;
					if (this.textFieldComponent && wi?.textFieldSpec?.maxTextLength) {
						let vals = res ? res : [];
						vals.push(multiSelectTextFieldMaxLengthValidator(wi?.textFieldSpec?.maxTextLength, this.textFieldComponent));
						return vals;
					}
					return res;
				})
			);
		}
		return of(undefined);
	}

	protected determineDefaultUIState(ctx: IEventProcessingContext, ...states: IComponentUIState[]): IComponentUIState {
		let state: IComponentUIState = {};
		return state = super.determineDefaultUIState(ctx, state, ...states);
	}

	/**
	 * Wird aufgerufen, wenn die selektierten Einträge in der DropDownListe verändert werden
	 * @param vals
	 */
	protected onDropDownValueChange(vals: IChoiceData[]): void {
		this.selectedValues = vals;
	}

	private onSelectedValuesChange(): void {
		//this._selectedAndCreatedValues = [...this._selectedValues, ...this._createdPDObjects];
		let ctrl = this.control;
		if (ctrl) {
			let rawVals = this._selectedValues?.length > 0 ?
				this._selectedValues.map(v => {
					return this._valueType == MultiSelectValueTypeET.EnumerationItem ?
						(v.value as IEnumerationItem).enumConst : v.value
				}) :
				null;
			ctrl.markAsDirty(); // Todo: führt zu ExpressionChangedAfterChecked Error (ng-pristine)
			ctrl.setValue(rawVals);
			this.pdObject.pdObjectRaw[this.propertyName] = rawVals !== null ? rawVals : [];
		}

		let params: PDObject[] | string[] | IEnumerationItem[];
		switch (this._valueType) {
			case MultiSelectValueTypeET.String:
				params = this._selectedValues.map(v => <string>v.value);
				break;
			case MultiSelectValueTypeET.PDObject:
				params = this._selectedValues.map(v => <PDObject>v.value);
				break;
			case MultiSelectValueTypeET.EnumerationItem:
				params = this._selectedValues.map(v => <IEnumerationItem>v.value);
				break;
		}
		this._selectionChangedSubject$.next(params);
		this._selectionChangedUIStateSubject$.next(params);
	}

	/**
	 * Updated selektierte Objekte in der Liste.
	 * Z.B stehen im pdObject neue Objekte, die noch nicht in der Ausgewähltenliste sind.
	 * Wird bei: Änderungen am PDobject, ChoiceProvider, state oder language aufgerufen.
	 * Nicht, wenn der User etwas auswählt.
	 * KOMMENTAR ÜBERARBEITEN
	 *
	 * Schreibt ebenso in das PDObject und in das Ctrl mit set selectedValues() am Ende.
	 */
	private updateSelectedValues() {
		if (!this.pdObject || !this.propertyName) {
			this.selectedValues = [];
			return;
		}

		let newSelection: IChoiceData[] = [];
		let sel = this.pdObject.pdObjectRaw[this.propertyName]; // Zu selektierende Objekte aus pdObjekt

		// Ermittelt aus dem PDObject alle Auswahlobjekte und packt diese in die selectedValues
		if (Array.isArray(sel)) {
			switch (this._valueType) {
				case MultiSelectValueTypeET.String:
					if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
						// Vergleicht die String direkt, um Neue zu erkennen
						for (let itemString of sel) {
							if (this._choiceDataMap.has(itemString)) {
								newSelection.push(this._choiceDataMap.get(itemString));
							}
						}
					}
					break;

				case MultiSelectValueTypeET.PDObject:

					// Die choiceSpec ist eventuell zu diesem Zeitpunkt noch nicht geladen.
					if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.SelectionList &&
						this._choiceSpecs.length <= 0
					) {
						return;
					}

					// TODO: Bitte beschreiben welchen Grund das hat.
					// 1. Durch alle Auswahlobjekte gehen, die nicht zur Auswahl stehen.
					// 2. Die gefilterten Objekte aus der Auswahl herausnehmen.
					// 3. Aus der _choiceDataMap entfernen
					this._choiceData.filter(d => d.itemNotInChoice).forEach(d => {
						let pos = this._choiceData.indexOf(d);
						this._choiceData.splice(pos, 1);
						this._choiceDataMap.delete(d.id);
					});

					this.removeAllCreatedItems();

					for (let relObj of sel.map(o => o as PDObject)) {
						if (relObj instanceof CustomPDObject) {
							let wi = this.multiSelectWidgetInfo;
							newSelection.push({
								id: relObj.oid,
								value: relObj,
								isCustomPDObject: true,
								ergName: relObj.getStatus(wi.editingSpec.objectInfo),
								isNew: relObj.isNew
							});
							this.mainUIService.addCreatedObject(relObj);
						}
						else {
							if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.SelectionList) {
								if (this._choiceSpecs.length == 1) {
									let data: IChoiceData = {
										id: relObj.oid,
										ergName: relObj.getStatus(this.getRelationInfo(relObj)),
										value: relObj,
										className: this._choiceSpecs[0].className
									};
									newSelection.push(data);
								}
							}
							else if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
								if (!this._choiceDataMap.has(relObj.oid)) {
									let clsName = this.determineChoiceDataClassName(relObj.getClassName());
									let relObjData: IChoiceData = {
										id: relObj.oid,
										itemNotInChoice: true,
										isCustomPDObject: false,
										value: relObj,
										ergName: relObj.getStatus(this.getRelationInfo(relObj)),
										className: clsName,
										classErgName: this.determineClassErgName(clsName)
									};
									this._choiceData.unshift(relObjData)
									this._choiceDataMap.set(relObj.oid, relObjData);
								}
								newSelection.push(this._choiceDataMap.get(relObj.oid));
							}
						}
					}
					break;

				case MultiSelectValueTypeET.EnumerationItem:
					if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
						for (let enumValue of sel) {
							let itemId = typeof(enumValue) === 'number' ? enumValue.toString() : enumValue;
							if (this._choiceDataMap.has(itemId)) {
								newSelection.push(this._choiceDataMap.get(itemId));
							}
						}
					}
					break;
			}
		}
		// FIXME: Aus Develop raus - Wegen Merge
		// if (newSelection.length == 0 && !this._selectedValues) {
		// 	return;
		// }
		this.selectedValues = newSelection;
	}

	private updateSelectedItemsErgName(): void {
		if (
			// Warum nicht bei Dropdowns?
			// this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.SelectionList ||
			this._selectedValues.length == 0 ||
			this._choiceSpecs.length != 1 // todo - warum todo?
		) {
			return;
		}
		this._selectedValues.forEach(val => {
			switch (this._valueType) {
				case MultiSelectValueTypeET.PDObject:
					if (val.value instanceof CustomPDObject) {
						val.ergName = (<PDObject>val.value).getStatus(this.multiSelectWidgetInfo.editingSpec.objectInfo);
						break;
					}
					val.ergName = (<PDObject>val.value).getStatus(this.getRelationInfo(val.value as PDObject));
					break;
				case MultiSelectValueTypeET.String:
					val.ergName = <string>val.value;
					break;
				case MultiSelectValueTypeET.EnumerationItem:
					val.ergName = (<IEnumerationItem>val.value).ergName;
					break;
			}
		});
	}

	/**
	 * Aktualisiert die zur auswahlstehenden Objekte
	 */
	private updateChoiceData(updateSelectedValues: boolean, data?: IChoiceData[]): void {
		if (this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.DropDownList) {
			if (updateSelectedValues) {
				this.updateSelectedValues();
			}
			return;
		}

		if (data) {
			this._choiceData = data;
		}

		// Aktuell selektierte Items ggf. in Choice integrieren, falls sie dort (noch) nicht enthalten sind
		switch (this._valueType) {
			case MultiSelectValueTypeET.PDObject: {
				this._choiceData = this._choiceData.filter(d => !d.itemNotInChoice);
				let currentRelObjs: PDObject[] = this.propertyName ? this.pdObject?.pdObjectRaw[this.propertyName] : undefined;
				if (Array.isArray(currentRelObjs) && currentRelObjs.length > 0) {
					// relation objects vorhanden
					currentRelObjs.filter(relObj => !(relObj instanceof CustomPDObject)).forEach(relObj => {
						let relObjChoiceData = this._choiceData.find(d => (d.value as PDObject).objectId.oid === relObj.objectId.oid);
						if (!relObjChoiceData) {
							// ChoiceData für relation object noch nicht vorhanden
							let clsName = this.determineChoiceDataClassName(relObj.getClassName());
							this._choiceData.unshift({
								id: relObj.oid,
								itemNotInChoice: true,
								isCustomPDObject: false,
								value: relObj,
								ergName: relObj.getStatus(this.getRelationInfo(relObj)),
								className: clsName,
								classErgName: this.determineClassErgName(clsName)
							});
						}
					});
				}
				break;
			}
		}

		this._choiceDataMap.clear();
		this._choiceData.reduce(
			(p, c) => {
				p.set(c.id, c);
				return p;
			},
			this._choiceDataMap
		);

		this.updateFilteredChoiceData();
		if (updateSelectedValues) {
			this.updateSelectedValues();
		}
		this._choiceUpdatedSubject$.next();
	}

	/**
	 * Aktualisiert die Spezifikationen der Auswahlobjekte. Ein Multiselect kann mehrere Unterschiedliche haben.
	 */
	// Ist nun nicht mehr async!
	private updateChoiceSpecs(): Observable<void> {
		return new Observable<IPDChoiceSpec[]>(observer => {
			// ChoiceSpecs aktualisieren
			this._choiceSpecs = [];
			if (this.multiSelectWidgetInfo?.choiceSpec) {
				this._choiceSpecs = Array.isArray(this.multiSelectWidgetInfo.choiceSpec) ?
					this.multiSelectWidgetInfo.choiceSpec :
					[this.multiSelectWidgetInfo.choiceSpec];
			}
			if (this._customChoiceProvider) {
				let res = this._customChoiceProvider(this, this.createEventProcessingContext());
				let customChoiceSpecs = res.map((r, i) => <IPDChoiceSpec>{
					className: r.className,
					relationInfo: r.objectInfo,
					sortExpr: r.sortExpr
				});
				this._choiceSpecs.push(...customChoiceSpecs.filter(custSpec => !this._choiceSpecs.find(spec => spec.className === custSpec.className)));
			}
			observer.next(this._choiceSpecs)
			observer.complete()
		}).pipe(
			map(specs => {
				// SubClasses aktualisieren
				let choiceClassNames = specs.map(s => s.className);

				// Wenn bereits alle bekannt sind, dann keinen Request absetzen
				if (choiceClassNames.length === this._subClasses.size) {
					const classNamesNotFetched = choiceClassNames.filter(className => {
						return !this._subClasses.has(className);
					})
					if (classNamesNotFetched.length === 0) {
						return; // of(undefined);
					}
				}

				this._subClasses = new Map();
				this.pdMeta.getSubClasses(choiceClassNames).forEach(val => {
					this._subClasses.set(val[0], val[1]);
				});
				this.updateChoiceData(true);
				this.updateSelectedItemsErgName();
				/*return this.pdAccessService.getSubClasses(choiceClassNames).pipe(
					map(res => {
						res.forEach(val => this._subClasses.set(val[0], val[1]));
						this.updateChoiceData(true);
						this.updateSelectedItemsErgName();
					}),
				);*/
			})
		);
	}

	private updateChoiceImpl(): Observable<boolean> {
		if (this.multiSelectRelationWidgetType != MultiSelectRelationWidgetTypeET.DropDownList) {
			return throwError(() => 'Invalid operation call.');
		}

		let action$: Observable<IChoiceData[]>;
		switch (this._valueType) {
			case MultiSelectValueTypeET.PDObject:
				action$ = this.updateChoicePDObject();
				break;

			case MultiSelectValueTypeET.String:
				action$ = this.updateChoiceString();
				break;

			case MultiSelectValueTypeET.EnumerationItem:
				action$ = this.updateChoiceEnumerationItem();
				break;
		}
		if (action$) {
			return defer(
				() => {
					if (this._choiceDataAction$) {
						this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
						//console.log(`Pending async action finished for MultiSelectComponent, id=${this.id}, compNumber=${this.componentNumber}`);
					}
					this.asyncActionManagerService.notifyActionStarted(action$);
					//console.log(`Async action started for MultiSelectComponent, id=${this.id}, compNumber=${this.componentNumber}`);
					this._choiceDataAction$ = action$;
					return action$;

				}).pipe(
					map(res =>{
						this.updateChoiceData(true, res);
						this.choiceDataValid = true;
						return true;
					}),
					finalize(() => {
						this.asyncActionManagerService.notifyActionFinished(action$);
						//console.log(`Async action finished for MultiSelectComponent, id=${this.id}, compNumber=${this.componentNumber}`);
						this._choiceDataAction$ = undefined;
					})
				);
		}

		this.updateChoiceData(true, []);
		this.choiceDataValid = false;
		this._classErgNames = new Map();
		this._subClasses = new Map();
		return of(false);
	}

	updateChoice(clearSelection = true): Observable<void> {
		if (clearSelection) {
			this.selectedValues = [];
		}
		this.choiceDataValid = false;
		this.isLoading = true;
		return this.updateChoiceImpl().pipe(
			map(() => undefined),
			finalize(() => this.isLoading = false)
		);
	}

	onFilterChange(value: string): void {
		this._choiceFilter = !value ? undefined : value;
		this.updateFilteredChoiceData();
	}

	onOpen(args: PreventableEvent): void {
		if (this.isLoading || this._popupDisabled ||
			this.multiSelectRelationWidgetType == MultiSelectRelationWidgetTypeET.SelectionList) {
			args.preventDefault();
			return;
		}
		if (!this.choiceDataValid) {
			args.preventDefault();
			this.isLoading = true;
			this.updateChoiceImpl().pipe(
				tap(() => {
					this._kendoMultiSelect.toggle(true);
				}),
				finalize(() => this.isLoading = false)
			).subscribe()
		}
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.Select:
				this.selectObjects();
				return;

			case ToolBarButtonIdET.New:
				this.createItem();
				return;

			/*case ToolBarButtonIdET.SelectTextTemplate:
				this.selectTextTemplate();
				return;*/
		}
		super.onToolBarButtonClick(item);
	}

	onRemoveItem(evt: RemoveTagEvent) {
		// todo
		let itemsToRemove: IChoiceData[] = Array.isArray(evt.dataItem) ? evt.dataItem : [evt.dataItem];
		itemsToRemove.forEach(i => {
			//let pos = this._createdPDObjects.indexOf(i);
			let pos = this._selectedValues.indexOf(i);
			if (pos >= 0) {
				let custObj = this._selectedValues[pos].value as CustomPDObject;
				if (custObj.isNew) {
					this.mainUIService.removeCreatedObject(custObj);
				}
				this._selectedValues.splice(pos, 1);
			}
			/*else {
				pos = this._selectedValues.indexOf(i);
				if (pos >= 0) {
					this._selectedValues.splice(pos, 1);
				}
			}*/
		});
		this.onSelectedValuesChange();
		//this.onItemsChanged(true);
		//this.updateChoiceData();
		evt.preventDefault();
	}

	private updateFilteredChoiceData() {
		let data = this._choiceData;
		if (data.length > 0 && this._choiceFilter) {
			let filter = this._choiceFilter.toLowerCase();
			data = data.filter(d => d.ergName.toLowerCase().indexOf(filter) !== -1);
		}
		this.filteredChoiceData = this._groupChoiceData ? groupBy(data, [{ field: "classErgName" }]) : data;
		this._choiceUpdatedSubjectUiState$.next();

	}

	/**
	 * Wird über updateChoice() aufgerufen, wen der Typ PDObject ist.
	 * Wird bei: Änderungen am PDObject, ChoiceProvider oder state aufgerufen.
	 * Nicht, wenn der User etwas auswählt.
	 */
	private updateChoicePDObject(): Observable<IChoiceData[]> | undefined {
		let choiceClassNames: string[];
		let data$: Observable<[string, string, PDObject[]][]>;
		if (this._customChoiceProvider) {
			let customRes = this._customChoiceProvider(this, this.createEventProcessingContext());
			choiceClassNames = customRes.map(res => res.className);
			//this._hasChoiceGroups = customRes.length > 1 && customRes.findIndex(item => item.className !== customRes[0].className) != -1;
			// Erstelle ein Observable-Array-Tupel aus dem customChoiceProvider-Data
			let extents = customRes.map(r => r.data.pipe(map(d => <[string, string, PDObject[]]>[r.className, r.objectInfo, d])));
			data$ = forkJoin(extents);
		}
		else if (this._choiceSpecs.length > 0) {
			choiceClassNames = this._choiceSpecs.map(s => s.className);
			let getExtentCalls: Observable<[string, string, PDObject[]]>[] = [];
			this._choiceSpecs.forEach((spec, iSpec) => {
				let filterExpr = this.getChoiceFilterExpression(spec);
				let call: Observable<[string, string, PDObject[]]> = this.pdAccessService.getExtent(spec.className, filterExpr, spec.sortExpr, spec.properties).pipe(
					map(res =>
						<[string, string, PDObject[]]>[spec.className, this.getRelationInfo(spec.className), res]
					)
				);
				getExtentCalls.push(call);
			});
			data$ = forkJoin(getExtentCalls);
		}

		if (data$) {
			let getClassErgNames$ = choiceClassNames.map(cls => this.metaDataService.getClassErgName(cls).pipe(
				map(erg => <[string, string]>[cls, erg]))
			);

			return forkJoin(getClassErgNames$).pipe(
				switchMap(ergNames => {
					this._classErgNames = ergNames.reduce(
						(map, val) => {
							map.set(val[0], val[1]);
							return map;
						},
						new Map<string, string>()
					);
					return data$.pipe(
						map(results => {
							let result: IChoiceData[] = [];
							for (let res of results) {
								for (let obj of res[2]) {
									let classErgName = this._classErgNames.has(res[0]) ? this._classErgNames.get(res[0]) : res[0];
									let data: IChoiceData = {
										id: obj.oid,
										ergName: obj.getStatus(res[1]),
										value: obj,
										className: res[0],
										classErgName: classErgName,
										isCustomPDObject: false
									};
									result.push(data);
									//this._choiceDataMap.set(obj.objectId.oid, data);
								}
							}
							return result;
						}),
						tap((res) => {
							this._groupChoiceData = res.length > 1 && res.findIndex(item => item.className !== res[0].className) != -1;
						})
					);
				})
			);
		}
		return undefined;
	}

	private updateChoiceString(): Observable<IChoiceData[]> | undefined {
		if (this._customChoiceProvider) {
			let res = this._customChoiceProvider(this, this.createEventProcessingContext());
			if (res.length == 1) {
				return res[0].data.pipe(
					map(data => {
						if (data.findIndex(v => typeof(v) !== 'string') !== -1) {
							throwError(() => `Invalid custom choice provider result. Data item ist not of type string.`);
						}
						return (<string[]>data).map(s => {
							let data: IChoiceData = { id: s, ergName: s, value: s }
							return data;
						});
					})
				);
			}
		}
		return undefined;
	}

	private updateChoiceEnumerationItem(): Observable<IChoiceData[]> | undefined {
		return this.pdAccessService.getEnumeration(this._enumName, this.localizationService.currentLanguageIndex).pipe(
			map(res => {
				return res.choice.map(i => {
					let data: IChoiceData = { id: i.enumConst.toString(), ergName: i.ergName, value: i };
					return data;
				});
			})
		);
	}

	private getChoiceFilterExpression(spec: IPDChoiceSpec): string | undefined {
		if (this._customChoiceFilterProvider) {
			let res = this._customChoiceFilterProvider(this, this.createEventProcessingContext());
			if (this._choiceSpecs.length == 1) {
				if (typeof (res) === 'string') {
					return res;
				}
			}
			else if (res instanceof Map && res.has(spec.className)) {
				return res.get(spec.className);
			}
		}

		return spec.filterExpr;
	}

	/**
	 * Öffnet ein Popup zur Mehrfachselektion
	 */
	private selectObjects() {
		// todo: bei SelectionList _choiceSpecs.length == 1 an zentraler Stelle prüfen
		if (this._choiceSpecs.length != 1) {
			return;
		}

		let className = this._choiceSpecs[0].className;
		let listSpec = (this.multiSelectWidgetInfo && this.multiSelectWidgetInfo.selectionListSpec) ? this.multiSelectWidgetInfo.selectionListSpec : undefined;
		let options: ISelectObjectOptions = {
			width: listSpec && listSpec.width ? listSpec.width : '60vw',
			height: listSpec && listSpec.width ? listSpec.width : '90vh',
			//columnInfos: listSpec && listSpec.columns ? listSpec.columns : undefined,
			sortExpr: listSpec?.sortExpr ?? this._choiceSpecs[0].sortExpr
		}

		if (this._selectionListColumnProvider) {
			options.columnInfos = this._selectionListColumnProvider();
		}
		else if (listSpec && listSpec.columns) {
			options.columnInfos = listSpec.columns;
		}

		if (this._customChoiceFilterProvider) {
			let filter = this._customChoiceFilterProvider(this, this.createEventProcessingContext());
			if (typeof (filter) === 'string') {
				options.filterExpr = filter;
			}
		}
		else if (this._choiceSpecs[0].filterExpr) {
			options.filterExpr = this._choiceSpecs[0].filterExpr;
		}

		let evalResult: (res: ISelectPDObjectsResult) => void =
			(res) => {
				if (res.selection) {
					this.selectedValues = res.selection.map(
						obj => {
							if (obj instanceof CustomPDObject) {
								// Bringt seine eigenen Werte mit. vgl. ngOnInit - pdObjectEditContext.getRelationObjectCreated
								return <IChoiceData>{
									id: obj.oid,
									className: obj.getClassName(),
									isNew: true,
									isCustomPDObject: true,
									ergName: obj.getStatus(this.multiSelectWidgetInfo.editingSpec.objectInfo),
									value: obj
								}
							}
							return <IChoiceData>{
								id: obj.oid,
								className: className,
								ergName: obj.getStatus(this.getRelationInfo(obj)),
								value: obj as PDObject
							}
						}
					);
					/*if (this._selectedValues) {
						for (let obj of this._selectedValues) {
							if (newSelection.findIndex(s => (<PDObject>s.value).objectId.oid === (<PDObject>obj.value).objectId.oid) == -1) {
								newSelection.splice(0, 0, obj);
							}
						}
					}
					this.selectedValues = newSelection;*/
				}
			};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectsResult> =
			(title) => {
				let selectedObjs = this._selectedValues.map(v => v.value as PDObject);
				if (this._customChoiceProvider) {
					let res = this._customChoiceProvider(this, this.createEventProcessingContext());
					if (res && res.length == 1) {
						options.choiceObjects = <Observable<PDObject[]>>res[0].data;
						return this.interactionService.selectPDObjects(className, title, selectedObjs, options).pipe(
							tap(res => evalResult(res))
						); // todo: mit evalResult zusammenfassen
					}
					return of(<ISelectPDObjectsResult>{ selection: [] })
				}
				else if (listSpec && listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this.pdAccessService, this.createEventProcessingContext());
					return this.interactionService.selectPDObjects(className, title, selectedObjs, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
				else {
					return this.interactionService.selectPDObjects(className, title, selectedObjs, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
			};

		if (listSpec) {
			if (listSpec.title) {
				let title = this.localizationService.getFromErgNameData(listSpec.title);
				showSelectDialog(title).subscribe();
				return;
			}
			else if (listSpec.titleId) {
				this.localizationService.getString(listSpec.titleId, { value: className }).pipe(
					switchMap(title => showSelectDialog(title))
				).subscribe();
				return;
			}
		}

		this.metaDataService.getClassErgName(className).pipe(
			switchMap(erg => {
				return this.localizationService.getSystemString('kendo-ui.components.pd-multi-select.selection-list-title', { value: erg }).pipe(
					switchMap(title => showSelectDialog(title))
				)
			})
		).subscribe();
	}

	/**
	 * Öffnet ein Overlay-Formular mit der angegebenen formSpec aus der widgetInfo (editingSpec)
	 */
	private createItem(): void {
		let wi = this.multiSelectWidgetInfo;
		if (!wi?.editingSpec?.urlCreate) {
			throw new Error('No valid editing spec.');
		}

		if (Array.isArray(wi.editingSpec.className)) {
			// todo; Klasse auswählen
			return;
		}
		let clsName = wi.editingSpec.className
		let url = [];
		url.push(...wi.editingSpec.urlCreate);
		url.push(this.propertyName);
		url.push(clsName);
		url.push(this.pdObject.objectId.oid);
		// propertyName entspricht der relationRole, diese wird im PDObjectResolver dazu benutzt das Beziehungsobjekt zu erzeugen
		// todo: Mechanismus notwendig, falls Beziegungsklasse eine Vererbungshierarchie aufweist
		//this.popupManagerService.showPopup(wi.editingSpec.outletName, url, this.route.parent, 'Popup_' + this.propertyName);
		this.popupManagerService.showPopup(wi.editingSpec.outletName, url, this.pdObjectEditContext.baseRoute, 'Popup_' + this.propertyName);
	}

	/**
	 * Öffnet ein Overlay-Formular mit der angegebenen formSpec aus der widgetInfo (editingSpec)
	 */
	private editItem(custObj: CustomPDObject): void {
		let wi = this.multiSelectWidgetInfo;
		if (!wi?.editingSpec?.urlEdit) {
			throw new Error('No valid editing spec.');
		}

		/*if (Array.isArray(wi.editingSpec.className)) {
			// todo; Klasse auswählen
			return;
		}
		let clsName = wi.editingSpec.className*/

		let url = [];
		url.push(...wi.editingSpec.urlEdit);
		//url.push(this.propertyName);
		//let propPath = this.relationContext ? `${this.relationPath}.${this.propertyName}` : this.propertyName;
		url.push(this.id);
		url.push(custObj.oid);
		url.push(this.pdObject.objectId.oid);
		this.popupManagerService.showPopup(wi.editingSpec.outletName, url, this.pdObjectEditContext.baseRoute, 'Popup_' + this.propertyName);
	}

	/**
	 * Alle Neuanlagen entfernen. customPDObjects sind Neuanlagen.
	 */
	private removeAllCreatedItems(): void {
		if (this._valueType !== MultiSelectValueTypeET.PDObject) {
			return;
		}
		this.customPDObjects.forEach(custObj => {
			this.mainUIService.removeCreatedObject(custObj.value as CustomPDObject);
		});
	}

	private getRelationInfo(objOrClass: IPDObject | string): string {
		let classname = typeof(objOrClass) === 'string' ? objOrClass : this.determineChoiceDataClassName(objOrClass.getClassName());
		let spec = this._choiceSpecs.find(s => s.className === classname);
		if (spec) {
			return spec.relationInfoProvider ? spec.relationInfoProvider(this.localizationService) :
				(spec.relationInfo ? spec.relationInfo : 'n.a.');
		}
		return 'n.a.';
	}

	private determineChoiceDataClassName(className: string): string {
		if (this._subClasses.has(className)) {
			return className;
		}

		for (let key of this._subClasses.keys()) {
			if (this._subClasses.get(key).includes(className)) {
				return key;
			}
		}
		return 'n.a.';
	}

	private determineClassErgName(className: string): string {
		if (className && this._classErgNames.has(className)) {
			return this._classErgNames.get(className);
		}
		return 'n.a.';
	}

	private selectTextTemplate(): Observable<void> {
		let wi = this.multiSelectWidgetInfo;
		if (!wi) {
			return EMPTY;
		}
		let options: ISelectObjectOptions = {
			width: '70vw',
			height: '90vh'
		};
		if (wi.textTemplateSelection.filterExpr) {
			options.filterExpr = wi.textTemplateSelection.filterExpr;
		}
		if (wi.textTemplateSelection.filterExprProvider) {
			options.filterExpr = wi.textTemplateSelection.filterExprProvider(this.createEventProcessingContext());
		}
		if (wi.textTemplateSelection.selectionListSpec.columns)  {
			options.columnInfos = wi.textTemplateSelection.selectionListSpec.columns;
		}
		if (wi.textTemplateSelection.selectionListSpec.sortExpr)  {
			options.sortExpr = wi.textTemplateSelection.selectionListSpec.sortExpr;
		}
		let title = wi.textTemplateSelection.selectionListSpec.title ?
			this.localizationService.getFromErgNameData(wi.textTemplateSelection.selectionListSpec.title) :
			'Datensatz auswählen'; // todo!
		return this.interactionService.selectPDObjects(wi.textTemplateSelection.className, title, undefined, options).pipe(
			tap(res => {
				if (res.selection) {
					let separator = wi.textTemplateSelection.separator ?? '\n\n';
					let newSelText = res.selection.reduce(
						(text, vorlage) => {
							if (text) {
								text += separator;
							}
							text += vorlage.pdObjectRaw[wi.textTemplateSelection.property];
							return text;
						},
						this.selectionAsText
					);
					this.onTextboxChange(newSelText);
					this.control.markAsDirty();
					this.control.updateValueAndValidity();
					this._cdref.detectChanges();
				}
			}),
			map(() => {})
		);
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		// todo: neuer UIState canChange!
		this.canChange = (relAccessRights && relAccessRights.change !== undefined) ? relAccessRights.change : undefined;
	}

	private updateToolbar(): void {
		if (this.multiSelectRelationWidgetType != MultiSelectRelationWidgetTypeET.SelectionList || !this.toolBarItems) {
			return;
		}

		let tbItemSelect = this.toolBarItems.find(item => item.id == ToolBarButtonIdET.Select);
		if (tbItemSelect) {
			tbItemSelect.disabled = this.canChange === false;
		}
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.MultiSelect;
	}

	reset(): void {
		super.reset();

		// todo: prüfen
		this.removeAllCreatedItems();
		this.selectedValues = [];
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._selectionChangedUIStateSubject$.subscribe(() => {
			callback(this); // Was ist "this"? Sollte ObjectRef sein an der Stelle
		});
		if (res) {
			res.add(sub);
			// return res;
		} else {
			res = sub;
		}
		res.add(this._choiceUpdatedSubjectUiState$.subscribe(() => callback(this)));
		return sub;
	}

	subscribeComponentEvents(callback: (source: IComponent, eventName: MultiSelectComponentEventName) => void): Subscription | undefined {
		let res = super.subscribeComponentEvents(callback);
		let sub = this._selectionChangedSubject$.subscribe(() => {
			callback(this, 'selectionChanged');
		});
		sub.add(this._choiceUpdatedSubject$.subscribe(() => {
			callback(this, 'choiceUpdated');
		}));
		if (res) {
			sub.add(res);
		}
		return sub;
	}

	/**
	 * Beschreibt den Typ für ALLE Objekte in der Liste.
	 */
	private _valueType: MultiSelectValueTypeET;

	/**
	 * Liefert den Typ für ALLE Objekte in der Liste.
	 */
	get valueType(): MultiSelectValueTypeET {
		return this._valueType;
	}

	get selection(): (string | PDObject | IEnumerationItem)[] | undefined {
		let retSelection: (string | PDObject | IEnumerationItem)[] = [];
		if (this._selectedValues) {
			let selection =  this._selectedValues.map(s => s.value);
			retSelection.push(...selection)
		}
		return retSelection;
	}

	set selection(objs: (string | PDObject | IEnumerationItem)[] | undefined) {

		if (!objs || objs.length == 0) {
			this.selectedValues = [];
			return;
		}

		let newSelection: IChoiceData[] = [];

		switch (this._valueType) {
			case MultiSelectValueTypeET.String:
				if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
					for (let itemString of objs) {
						if (this._choiceDataMap.has(<string>itemString)) {
							newSelection.push(this._choiceDataMap.get(<string>itemString));
						}
					}
				}
				break;

			case MultiSelectValueTypeET.PDObject:
				for (let obj of <PDObject[]>objs) {
					switch (this.multiSelectRelationWidgetType) {
						case MultiSelectRelationWidgetTypeET.DropDownList:
							{
								if (this._choiceDataMap.has(obj.objectId.oid)) {
									newSelection.push(this._choiceDataMap.get(obj.objectId.oid));
								}
								break;
							}

						case MultiSelectRelationWidgetTypeET.SelectionList:
							if (this._choiceSpecs.length == 1) { // todo
								newSelection.push(<IChoiceData>{
									className: this._choiceSpecs[0].className, ergName: obj.getStatus(this.getRelationInfo(obj)), value: obj
								});
							}
							break;
					}
				}
				break;

			// Hier "Einfach" den Technischen bezeichner speichern?
			case MultiSelectValueTypeET.EnumerationItem:
				if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
					for (let item of objs) {
						let key = (<IEnumerationItem>item).enumConst.toString();
						if (this._choiceDataMap.has(key)) {
							newSelection.push(this._choiceDataMap.get(key));
						}
					}
				}
				break;
		}
		this.selectedValues = newSelection;
	}

	private _selectionChangedSubject$: BehaviorSubject<PDObject[] | string[] | IEnumerationItem[] | undefined> = new BehaviorSubject(undefined);

	private _selectionChanged$: Observable<PDObject[] | string[] | IEnumerationItem[] | undefined>;

	/**
	 * Liefert ein Observable, welches auf Änderungen der Ausgewählten Objekte reagiert.
	 */
	get selectionChanged$(): Observable<PDObject[] | string[] | IEnumerationItem[] | undefined> {
		if (!this._selectionChanged$) {
			this._selectionChanged$ = this._selectionChangedSubject$.asObservable();
		}
		return this._selectionChanged$;
	}

	private _selectionChangedUIStateSubject$: BehaviorSubject<PDObject[] | string[] | IEnumerationItem[] | undefined> = new BehaviorSubject(undefined);

	/**
	 * Callback-Funktion übergeben, welches die Auswahlliste mit Daten befüllt.
	 */
	set customChoiceProvider(cb: CustomChoiceProvider) {
		this.setCustomChoiceProviderImpl(cb, true);
	}

	private _customChoiceProvider: CustomChoiceProvider;

	private setCustomChoiceProviderImpl(cb: CustomChoiceProvider, updateChoiceSpecs: boolean
	): void {
		if (this._customChoiceProvider != cb) {
			this._customChoiceProvider = cb;
			this.choiceDataValid = false;
			if (updateChoiceSpecs) {
				this.updateChoiceSpecs().subscribe();
			}
		}
	}

	notifyCustomChoiceChanged(clearSelection = true): void {
		this.choiceDataValid = false;
		if (clearSelection) {
			this.selectedValues = [];
		}
		this.updateChoiceSpecs().subscribe();
	}

	/**
	 * Callback-Funktion übergeben, welche die Auswahlliste filtert.
	 */
	set customChoiceFilterProvider(cb: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>) {
		this._customChoiceFilterProvider = cb;
		this.choiceDataValid = false;
	}

	private _customChoiceFilterProvider: (source: IRelationComponent, ctx: IEventProcessingContext) => string | undefined | Map<string, string>;

	/**
	 * Callback-Funktion übergeben, die bestimmt welche Spalten in der Popup-Auswahlliste angezeigt werden sollen.
	 */
	set selectionListColumnProvider(cb: () => IPDListColumnInfo[]) {
		this._selectionListColumnProvider = cb;
	}

	private _selectionListColumnProvider: () => IPDListColumnInfo[];

	get choiceObjects(): (PDObject | string | IEnumerationItem)[] {
		if (this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.DropDownList || this._choiceDataMap.size == 0) {
			return [];
		}
		return Array.from(this._choiceDataMap.values()).map(item => item.value);
	}
}
