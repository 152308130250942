import { Grammars, IToken, TokenError } from "ebnf";
import { IGrammarParserOptions } from "ebnf/dist/Grammars/types";
import { ASTToken, ASTTokenError, IGrammarParser } from "../services/grammar-parser-factory.service";

/*let testGrammar2: string = `
	UIStateExpr ::= 'test' EOL
	EOL ::= [#x0A#x0D]+	
`;*/

/*let testGrammar: string = `
UIStateExpr ::= ExpressionGroup EOL

Expression ::= CustomContextExpr | ComponentExpr

BracketExpr ::= "(" BracketExpr ConditionalExpr ")" | Expression

ConditionalExpr ::= WS LogicalOperator WS ExpressionGroup

LogicalOperator ::= "&&" | "||"

ExpressionGroup ::= Expression ConditionalExpr? | BracketExpr ConditionalExpr?

CustomContextExpr ::= "custCtx" PointToken CustomContextItem PointToken Assertion

CustomContextItem ::= "principal"

ComponentExpr ::= TextComponentExpr

ComponentBaseExpr ::= ComponentToken PointToken ComponentId

TextComponentExpr ::= ComponentBaseExpr PointToken TextComponentProperty PointToken Assertion

TextComponentProperty ::= "text"

Assertion ::= ObjectAssertion | StringAssertion

ObjectAssertion ::= "isNull" | "isNotNull"

StringAssertion ::= "isEmpty" | "isNotEmpty"

PointToken ::= "."

ComponentToken ::= "comp"

ComponentId ::= ([A-Za-z_])([A-Za-z0-9_])* 

EOL ::= [#x0A#x0D]+

WS ::= (#x09 | #x20)*
`;*/


export class MenduzEbnfParser implements IGrammarParser {
	
	private _parser: Grammars.W3C.Parser;
	
	constructor(grammar: string) {
		let options: IGrammarParserOptions = {
			debugRulesParser: false,
			debug: false,
			keepUpperRules: false,
		};
		this._parser = new Grammars.W3C.Parser(grammar, options);
	}

	parse(expr: string, targetRule: string): ASTToken | undefined {
		let result = this._parser.getAST(expr, targetRule);
		return result ? this.createASTToken(result) : undefined;
	}

	private createASTToken(token: IToken): ASTToken {
		let astToken: ASTToken = {
			type: token.type,
			text: token.text,
			start: token.start,
			end: token.end,
			errors: token.errors ?
				token.errors.map(e => {
					return { message: e.message };
				}) : [],
			children: token.children ? token.children.map(t => this.createASTToken(t)) : []
		}
		return astToken;
	}

	/*private createASTTokenError(error: TokenError): ASTTokenError {
		return 
	}*/
}