import { Input, Component, ContentChildren, QueryList, HostBinding } from '@angular/core';
import { AppBarItemComponent, AppBarItemTypeET } from './app-bar-item.component';
//import { , AppBarItemTypeET } from './app-bar-item.directive';

@Component({
	selector: 'otris-app-bar',
	template: `
		<kendo-appbar position="top" [themeColor]="themeColor">
			@for (item of appBarItems; track item) {
				@switch (item.itemType) {
					@case (appBarItemTypeET.Spacer) {
						<kendo-appbar-spacer>
						</kendo-appbar-spacer>
					}
					@case (appBarItemTypeET.Separator) {
						<span class="k-appbar-separator"></span>
					}
					@case (appBarItemTypeET.Section) {
						<kendo-appbar-section
							(mouseenter)="item.raiseMouseEnter()"
							(mouseleave)="item.raiseMouseLeave()"
							[id]="item.id"
						>
							<ng-container *ngTemplateOutlet="item.content"></ng-container>
						</kendo-appbar-section>
					}
				}
			}

			<!--
			<ng-container *ngFor="let item of appBarItems">
				<ng-container [ngSwitch]="item.type">
					<kendo-appbar-spacer *ngSwitchCase="appBarItemTypeET.Spacer">
					</kendo-appbar-spacer>
					<kendo-appbar-section *ngSwitchCase="appBarItemTypeET.Section">
						<ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
					</kendo-appbar-section>
				</ng-container>
			</ng-container>
			-->
		</kendo-appbar>
	`,
	styles: []
})
export class AppBarComponent {

	@Input() themeColor: 'light' | 'dark' | 'inherit' = 'light';

	@HostBinding('class') class = 'otris-app-bar';

	//@ContentChildren(AppBarItemDirective) appBarItems: AppBarItemDirective[];

	@ContentChildren(AppBarItemComponent) appBarItems: QueryList<AppBarItemComponent>;

	appBarItemTypeET = AppBarItemTypeET;

	constructor() { }
}
