import { HostBinding, Directive } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
	ICssStyle, IFormStatus, FlexDirectionET, IPDContainerComponent, VerticalSizeTypeET, IToNRelationItemSpec, ITo1RelationItemSpec, ComponentTypeET, IComponent
} from '@otris/ng-core-types';
import * as widgetETModule from '@otris/ng-core-types';
import { PDObject } from '@otris/ng-core-shared';
import { UIAbstractComponent } from '../ui-abstract/ui-abstract.component';
import { UIItemSpec, GridLayoutSpec, FlexLayoutSpec, UIContainerSpec } from '../../model/pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';
import { EventProcessingContextManagerService } from '../../services/event-processing-context-manager.service';
import { PDLikeComponent } from '../pd-like/pd-like.component';
import { Observable, Subject, filter } from 'rxjs';

export type PDContainerItem = {
	spec: UIItemSpec,
	pdObject: PDObject,
	fg: UntypedFormGroup
}

@Directive()
export abstract class PDContainerComponent extends PDLikeComponent implements IPDContainerComponent {

	// todo: wieder rausgenommen, Simon prüfen
	//@HostBinding('style.flex') styleFlex = '1 1 auto';
	//@HostBinding('style.align-self') styleAlignSelf = 'stretch';

	@HostBinding('style.background-color') styleBackgroundColor: string;
	@HostBinding('style.border-radius') styleBorderRadius: string;

	@HostBinding('style.position') get position() {
		if (this.containerSpec.verticalSize === VerticalSizeTypeET.Scrollable) {
			return 'relative';
		} else {
			return 'static';
		}
	}

	widgetET = (widgetETModule as any).WidgetET;

	private _itemSpecs: any[];

	protected abstract get childComponents(): UIAbstractComponent[];

	get propertyName(): string | undefined {
		return undefined
	}

	protected get propertyRelationPath(): string | undefined {
		return undefined;
	}

	get control(): AbstractControl | undefined {
		return undefined
	}

	get isContainerComponent(): boolean {
		return true;
	}

	private _componentsChangedSubject$: Subject<ComponentTypeET> = new Subject();

	protected get componentsChangedSubject$(): Subject<ComponentTypeET> {
		return this._componentsChangedSubject$;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	abstract getComponents(type?: ComponentTypeET): IComponent[];

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		for (let child of this.childComponents) {
			let childStat = child.getFormControlStatus();
			if (!childStat.pristine) {
				stat.pristine = false;
			}
			if (childStat.touched) {
				stat.touched = true;
			}
			if (!childStat.valid) {
				stat.valid = false;
			}
		}
		return stat;
	}

	getToNRelationItemSpec(spec: UIItemSpec): IToNRelationItemSpec | undefined {
		if (!(spec instanceof UIContainerSpec)) {
			throw new Error('Invalid operation call: getToNRelationItemSpec()');
		}
		return (spec as UIContainerSpec).toNRelationItemSpec;
	}

	getTo1RelationItemSpec(spec: UIItemSpec): ITo1RelationItemSpec | undefined {
		if (!(spec instanceof UIContainerSpec)) {
			throw new Error('Invalid operation call: getTo1RelationItemSpec()');
		}
		return (spec as UIContainerSpec).to1RelationItemSpec;
	}

	protected createControl(): AbstractControl | undefined {
		return undefined;
	}

	protected checkIfItemIncluded(spec: UIItemSpec): boolean {
		/*if (spec.omitItem !== undefined) {
			return !spec.omitItem;
		}
		if (spec.omitItemHandler) {
			return !spec.omitItemHandler(this.createEventProcessingContext());
		}*/
		if (spec.omitItemSpec) {
			if (spec.omitItemSpec.value !== undefined) {
				return !spec.omitItemSpec.value;
			}
			if (spec.omitItemSpec.handler) {
				return !spec.omitItemSpec.handler(this.createEventProcessingContext());
			}
			if (spec.omitItemSpec.expr) {
				/*let exprOptions: ExpressionOptions = {
					foreignComp: foreignComp,
					sourceComp: source
				}*/
				return !this.formExpressionGrammarProcessor.evaluateConditionExpression(
					spec.omitItemSpec.expr, this.createEventProcessingContext());
			}
		}
		return true;
	}

	get itemSpecs(): PDContainerItem[] {
		if (!this._itemSpecs) {
			return [];
		}
		return this._itemSpecs;
	}

	protected get containerSpec(): UIContainerSpec {
		return <UIContainerSpec>this.uiItemSpec;
	}

	protected onPDObjectChanged(): void {
		// Asynchron alleine geht nicht, da dann z.B. beim Laden nur für das Panel auf 1. Ebene das PDObject direkt gesetzt wird.
		// Das führt zu Problemen bzgl. des isLoading-Mechanismus.
		// ggf. muss der Codeteil zunächst synchron und dann nochmal asynchron aufgerufen werden?
		// Es ist aktuell nicht mehr klar, warum ein asynchroner Aufruf notwendig ist, ggf. hat es mit den Relation-Panels zu tun.
		/*setTimeout(() => {
			for (let spec of this.itemSpecs) {
				spec.pdObject = this.pdObject;
			}
		}, 0);*/

		/*for (let spec of this.itemSpecs) {
			spec.pdObject = this.pdObject;
		}*/
		this.updateItemSpecs();
	}

	protected onFormGroupChanged(): void {
		super.onFormGroupChanged();
		this.updateItemSpecs();
	}

	updateItemSpecs(recreate?: boolean): void {
		if (this.formGroup && this.pdObject) {
			if (!this._itemSpecs || recreate) {
				this._itemSpecs = [];
				this.containerSpec.items.forEach(i => {
					if (this.checkIfItemIncluded(i)) {
						this._itemSpecs.push({ spec: i, pdObject: this.pdObject, fg: this.formGroup });
					}
				});
			}
			else {
				for (let spec of this.itemSpecs) {
					spec.pdObject = this.pdObject;
					spec.fg = this.formGroup;
				}
			}
		}
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.containerSpec.backgroundColor) {
			this.styleBackgroundColor = this.containerSpec.backgroundColor;
		}
		if (this.containerSpec.borderRadius) {
			this.styleBorderRadius = this.containerSpec.borderRadius;
		}

		// todo: pruefen, ob man das Stretchen des Containers nicht besser loesen kann
		// bis dahin wird erstmal als Default flex maeßig gestretched, falls der Parent-Container ein Flex-Container ist
		if (!this.flexStyle && this.uiItemSpec.parentContainer && this.uiItemSpec.parentContainer.layout instanceof FlexLayoutSpec) {
			this.flexStyle = '1 1';
		}

		/* todo: Simon prüfen
			if (this.containerSpec.layout instanceof FlexLayoutSpec) {
			let flexLayout = <FlexLayoutSpec>this.containerSpec.layout;
			if (flexLayout.alignSelf) {
				this.styleAlignSelf = flexLayout.alignSelf;
			}
			if (flexLayout.flex) {
				this.styleFlex = flexLayout.flex;
			}
		}*/
	}

	getContainerStyle(): ICssStyle {
		let style = <ICssStyle>new Object();
		// style.height = '100%'; 100p fix

		// wieder rausgenommen!!!
		//style.flex = '1 1 auto'; // todo: evtl. in die abgeleiteten Klassen verschieben

		// todo: Simon prüfen
		style.flex = '1 1 auto' // 100p fix: Sollte eher eine Ausnahme sein, dass es nicht passt

		/*if (this.containerSpec.backgroundColor) {
			style['background-color'] = this.containerSpec.backgroundColor;
		}*/

		if (this.containerSpec.verticalSize === VerticalSizeTypeET.Scrollable) {
			style['position'] = 'absolute';
			style['left'] = '0';
			style['right'] = '0';
			style['top'] = '0';
			style['max-height'] = '100%';
			style['overflow-y'] = 'auto';
			style['padding-right'] = '0.5em';
		}

		// todo: was ist das Default-Layout für einen Container?
		let layout = this.containerSpec.layout ? this.containerSpec.layout :
			(this.containerSpec.layoutProvider ? this.containerSpec.layoutProvider(this, this.createEventProcessingContext()) : undefined);

		if (layout?.rawStyles) {
			for (const [key, value] of Object.entries(layout.rawStyles)) {
				style[key] = value;
			}
		}
		if (layout instanceof GridLayoutSpec) {
			let gridLayout = <GridLayoutSpec>layout;
			style.display = 'grid';
			if (gridLayout.columns) {
				let colSpecs = "";
				gridLayout.columns.forEach(c => {
					if (colSpecs.length > 0) {
						colSpecs += " ";
					}
					colSpecs += c.width;
				});
				style['grid-template-columns'] = colSpecs;
			}
			if (gridLayout.rows) {
				let rowSpecs = "";
				gridLayout.rows.forEach(r => {
					if (rowSpecs.length > 0) {
						rowSpecs += " ";
					}
					rowSpecs += r.height;
				});
				style['grid-template-rows'] = rowSpecs;
			}
			if (gridLayout.gridGap) {
				style['grid-gap'] = gridLayout.gridGap;
			}
			if (gridLayout.alignItems) {
				style['align-items'] = gridLayout.alignItems;
			}
			if (gridLayout.justifyItems) {
				style['justify-items'] = gridLayout.justifyItems;
			}
		}
		else if (layout instanceof FlexLayoutSpec) {
			let flexLayout = <FlexLayoutSpec>layout;
			style.display = 'flex';
			if (flexLayout.direction) {
				switch (flexLayout.direction) {
					case FlexDirectionET.Column:
						style['flex-direction'] = 'column';
						//style['flex'] = '1 1 auto';
						break;

					case FlexDirectionET.Row:
						style['flex-direction'] = 'row';
						break;
				}
			}
			if (flexLayout.gap) {
				style['gap'] = flexLayout.gap;
			}
			if (flexLayout.wrap) {
				style['flex-wrap'] = flexLayout.wrap;
			}
			if (flexLayout.justifyContent) {
				style['justify-content'] = flexLayout.justifyContent;
			}
			if (flexLayout.alignContent) {
				style['align-content'] = flexLayout.alignContent;
			}
			if (flexLayout.alignItems) {
				style['align-items'] = flexLayout.alignItems;
			}
		}
		return style;
	}

	getComponentsChanged(...types: ComponentTypeET[]): Observable<ComponentTypeET> {
		return this._componentsChangedSubject$.asObservable().pipe(
			filter(t => types.length == 0 || types.includes(t))
		);
	}
}
