import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	AfterContentInit,
	Input,
	HostBinding,
	Host,
	Directive,
	TemplateRef,
	Output,
	EventEmitter,
	inject,
	NgZone
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import {
	IFormStatus,
	IComponent,
	ComponentTypeET,
	IRelationContext,
	IEventProcessingContext,
	IComponentUIState,
	IPDObject,
	CustomEvent,
	UIStateProvider,
	IUIStateProviderRegistration,
	IModuleStateService,
	IPDMeta,
	IPDContainerComponent,
	ForeignComponentEventSpec,
	IFormExpressionGrammarProcessor,
	CommandParameter,
	IAsyncActionManagerService
} from '@otris/ng-core-types';
import { IInteractionServiceToken, IModuleStateServiceToken, IPDAccessServiceToken, IPDClassToken, ServiceLocator } from '@otris/ng-core-shared';
import { UIItemSpec, FlexItemPlacement, GridItemPlacement, WidgetInfo, UIContainerSpec, PDItemSpec } from '../../model/pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';
import { GlobalTemplateProviderService } from '../../services/global-template-provider.service';
import { EventProcessingContextManagerService } from '../../services/event-processing-context-manager.service';
import { IPDMetaToken } from '../../services/pd-meta';
import { FormExpressionGrammarProcessorToken } from '../../services/form-expression-grammar-processor.service';
import { LocalizationService, LocalizationServiceToken } from '../../services/localization.service';
import { IAsyncActionManagerServiceToken } from '../../services/async-action-manager.service';


export const PD_COMPONENT_READONLY_TEMPLATE_ID = 'PDComponentReadonlyTemplateId';

interface IUIStateProviderRegistrationCallback {
	cancelUIStateProvider(registration: IUIStateProviderRegistration, updateUIState: boolean, updateChilds: boolean): void;
}

class UIStateProviderRegistration implements IUIStateProviderRegistration {

	//private _childRegistrations: IUIStateProviderRegistration[] = [];

	constructor(private _callback: IUIStateProviderRegistrationCallback, private _providerHost?: IComponent) {}

	cancel(updateUIState = true, updateChilds = true): void {
		this._callback.cancelUIStateProvider(this, updateUIState, updateChilds);
		//this._cancelHandler(this, updateChilds);
		//this._childRegistrations.forEach(cr => cr.cancel());
	}

	/*addChildRegistration(registration: IUIStateProviderRegistration): void {
		this._childRegistrations.push(registration);
	}*/

	get providerHost(): IComponent | undefined {
		return this._providerHost;
	}
}

@Directive()
export abstract class UIAbstractComponent implements IUIStateProviderRegistrationCallback, OnInit, AfterViewInit, AfterContentInit, OnDestroy, IComponent {

	private _formGroup: UntypedFormGroup;

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}

	@Input()
	set formGroup(fg: UntypedFormGroup) {
		if (fg != this._formGroup) {
			this._formGroup = fg;
			this.onFormGroupChanged();
		}
	}

	@Input() uiItemSpec: UIItemSpec;

	@Input() relationContext: IRelationContext;

	@Input() parentContainer: IPDContainerComponent;

	private _idPostfix: string;

	@Input()
	set idPostfix(val: string) {
		this._idPostfix = val;
	}

	get idPostfix(): string {
		return this._idPostfix;
	}

	@Input() initialUIState: IComponentUIState;

	@Output() uiStateChanged = new EventEmitter<[IComponentUIState, IComponentUIState]>();

	/*@Input() set id(newId: string){
		this._id = newId;
	}*/

	@HostBinding('id') get cssId(): string {
		return this.getId();
	}

	@HostBinding('attr.otris-component') otrisFieldComponent = '';

	@HostBinding('style.display') get styleDisplay(): string | undefined {
		return this.isHidden ? 'none' : undefined;
	}

	@HostBinding('class.otris-uistate-hidden') get classHidden() {
		return this.isHidden;
	}

	@HostBinding('class.otris-uistate-disabled') get classDisabled() {
		return this.isDisabled;
	}

	@HostBinding('class.otris-uistate-readonly') get classReadonly() {
		return this.isReadonly;
	}

	@HostBinding('class.otris-uistate-mandatory') get classMandatory() {
		return this.isMandatory;
	}

	@HostBinding('style.margin-right') styleMarginRight: string;

	@HostBinding('style.margin-bottom') styleMarginBottom: string;

	@HostBinding('style.margin-left') styleMarginLeft: string;

	@HostBinding('style.margin-top') styleMarginTop: string;

	@HostBinding('style.padding-right') stylePaddingRight: string;

	@HostBinding('style.padding-bottom') stylePaddingBottom: string;

	@HostBinding('style.padding-left') stylePaddingLeft: string;

	@HostBinding('style.padding-top') stylePaddingTop: string;

	@HostBinding('style.flex') flexStyle: string;

	@HostBinding('style.align-self') alignSelfStyle: string;

	@HostBinding('style.width') widthStyle: string;

	@HostBinding('style.min-width') minWidthStyle: string;

	@HostBinding('style.max-width') maxWidthStyle: string;

	@HostBinding('style.min-height') minHeightStyle: string;

	@HostBinding('style.max-height') maxHeightStyle: string;

	@HostBinding('style.height') heightStyle: string;

	@HostBinding('style.grid-column-start') gridColumnStartStyle: string;

	@HostBinding('style.grid-column-end') gridColumnEndStyle: string;

	@HostBinding('style.grid-row-start') gridRowStartStyle: string;

	@HostBinding('style.grid-row-end') gridRowEndStyle: string;

	private _subscriptions: Subscription;

	get customData(): any {
		return this.uiItemSpec.customData;
	}

	private static componentCounter: number = 0;

	protected readonly componentNumber: number = ++UIAbstractComponent.componentCounter;

	private _readonlyTemplate: TemplateRef<any>;

	get readonlyTemplate(): TemplateRef<any> | undefined {
		return this._readonlyTemplate;
	}

	get readonlyTemplateContext(): { comp: UIAbstractComponent } {
		return { comp: this };
	}

	abstract get isContainerComponent(): boolean;

	protected getRelatedObject(): IPDObject | undefined {
		return undefined;
	}

	private _defaultUIStateProviderRegistered = false;

	private _initialized = false;

	private _pdMeta = inject(IPDMetaToken);

	get pdMeta(): IPDMeta {
		return this._pdMeta;
	}

	protected get initialized(): boolean {
		return this._initialized;
	}

	private ngZone = inject(NgZone);

	get isRelationItemHostComponent(): boolean {
		return false;
	}

	private _expressionDataUIStateProvider: UIStateProvider;

	get foreignComponentEvents(): ForeignComponentEventSpec[] | undefined {
		return this.uiItemSpec.foreignComponentEvents;
	}

	private _formExpressionGrammarProcessor = inject(FormExpressionGrammarProcessorToken);

	protected get formExpressionGrammarProcessor(): IFormExpressionGrammarProcessor {
		return this._formExpressionGrammarProcessor;
	}

	private _asyncActionManagerService = inject(IAsyncActionManagerServiceToken);

	protected get asyncActionManagerService(): IAsyncActionManagerService {
		return this._asyncActionManagerService;
	}

	constructor(
		protected router: Router,
		protected route: ActivatedRoute,
		protected formHandler: FormHandlerService,
		protected eventProcessingContextManagerService: EventProcessingContextManagerService) {
		let templateProviderService = ServiceLocator.injector.get(GlobalTemplateProviderService);
		let directive = templateProviderService.getTemplate(PD_COMPONENT_READONLY_TEMPLATE_ID);
		if (directive) {
			this._readonlyTemplate = directive.template;
		}
	}

	ngOnInit() {
		console.debug(`Component.ngOnInit() [id: '${this.id ? this.id : 'no id'}', compNumber: '${this.componentNumber}', compType: '${this.componentType}']`);

		this._initialized = true;

		if (this.uiItemSpec.initialUIStateProvider) {
			this.initialUIState = this.uiItemSpec.initialUIStateProvider(this, this.createEventProcessingContext());
		}
		if (this.uiItemSpec?.initialUIStateExpressionData) {
			let provider = this.formExpressionGrammarProcessor.evaluateUIStateExpression(this.uiItemSpec.initialUIStateExpressionData);
			let uiState = provider(this, this.createEventProcessingContext());
			if (this.initialUIState) {
				this.initialUIState.disabled = this.initialUIState.disabled || uiState.disabled;
				this.initialUIState.readonly = this.initialUIState.readonly || uiState.readonly;
				this.initialUIState.mandatory = this.initialUIState.mandatory || uiState.mandatory;
				this.initialUIState.hidden = this.initialUIState.hidden || uiState.hidden;
			}
			else {
				this.initialUIState = uiState;
			}
		}

		let ctx = this.createEventProcessingContext();
		if (this.uiItemSpec.parentContainer && (!this.isPDContainer() || !this.uiItemSpec.parentContainer.disableChildItemMarginsInContainer)) {
			let childMargin = this.uiItemSpec.parentContainer.childItemMargin ??
				(this.uiItemSpec.parentContainer.propertyValueProvider ? this.uiItemSpec.parentContainer.propertyValueProvider('childItemMargin', this, ctx) : undefined);
			if (typeof(childMargin) === 'string') {
				this.styleMargin = childMargin;
			}
			let childMarginTop = this.uiItemSpec.parentContainer.childItemMarginTop ??
				(this.uiItemSpec.parentContainer.propertyValueProvider ? this.uiItemSpec.parentContainer.propertyValueProvider('childItemMarginTop', this, ctx) : undefined);
			if (typeof(childMarginTop) === 'string') {
				this.styleMarginTop = childMarginTop;
			}
			let childMarginRight = this.uiItemSpec.parentContainer.childItemMarginRight ??
				(this.uiItemSpec.parentContainer.propertyValueProvider ? this.uiItemSpec.parentContainer.propertyValueProvider('childItemMarginRight', this, ctx) : undefined);
			if (typeof(childMarginRight) === 'string') {
				this.styleMarginRight = childMarginRight;
			}
			let childMarginBottom = this.uiItemSpec.parentContainer.childItemMarginBottom ??
				(this.uiItemSpec.parentContainer.propertyValueProvider ? this.uiItemSpec.parentContainer.propertyValueProvider('childItemMarginBottom', this, ctx) : undefined);
			if (typeof(childMarginBottom) === 'string') {
				this.styleMarginBottom = childMarginBottom;
			}
			let childMarginLeft = this.uiItemSpec.parentContainer.childItemMarginLeft ??
				(this.uiItemSpec.parentContainer.propertyValueProvider ? this.uiItemSpec.parentContainer.propertyValueProvider('childItemMarginLeft', this, ctx) : undefined);
			if (typeof(childMarginLeft) === 'string') {
				this.styleMarginLeft = childMarginLeft;
			}
		}

		let width = this.uiItemSpec.width ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('width', this, ctx) : undefined);
		if (typeof(width) === 'string') {
			this.widthStyle = width;
		}
		if (this.uiItemSpec.height) {
			this.heightStyle = this.uiItemSpec.height;
		}
		let minWidth = this.uiItemSpec.minWidth ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('minWidth', this, ctx) : undefined);
		if (typeof(minWidth) === 'string') {
			this.minWidthStyle = minWidth;
		}
		let maxWidth = this.uiItemSpec.maxWidth ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('maxWidth', this, ctx) : undefined);
		if (typeof(maxWidth) === 'string') {
			this.maxWidthStyle = maxWidth;
		}
		let minHeight = this.uiItemSpec.minHeight ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('minHeight', this, ctx) : undefined);
		if (typeof(minHeight) === 'string') {
			this.minHeightStyle = minHeight;
		}
		let maxHeight = this.uiItemSpec.maxHeight ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('maxHeight', this, ctx) : undefined);
		if (typeof(maxHeight) === 'string') {
			this.maxHeightStyle = maxHeight;
		}
		let margin = this.uiItemSpec.margin ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('margin', this, ctx) : undefined);
		if (typeof(margin) === 'string') {
			this.styleMargin = margin;
		}
		let marginLeft = this.uiItemSpec.marginLeft ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('marginLeft', this, ctx) : undefined);
		if (typeof(marginLeft) === 'string') {
			this.styleMarginLeft = marginLeft;
		}
		let marginTop = this.uiItemSpec.marginTop ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('marginTop', this, ctx) : undefined);
		if (typeof(marginTop) === 'string') {
			this.styleMarginTop = marginTop;
		}
		let marginRight = this.uiItemSpec.marginRight ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('marginRight', this, ctx) : undefined);
		if (typeof(marginRight) === 'string') {
			this.styleMarginRight = marginRight;
		}
		let marginBottom = this.uiItemSpec.marginBottom ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('marginBottom', this, ctx) : undefined);
		if (typeof(marginBottom) === 'string') {
			this.styleMarginBottom = marginBottom;
		}
		let padding = this.uiItemSpec.padding ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('padding', this, ctx) : undefined);
		if (typeof(padding) === 'string') {
			this.stylePadding = padding;
		}
		let paddingLeft = this.uiItemSpec.paddingLeft ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('paddingLeft', this, ctx) : undefined);
		if (typeof(paddingLeft) === 'string') {
			this.stylePaddingLeft = paddingLeft;
		}
		let paddingTop = this.uiItemSpec.paddingTop ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('paddingTop', this, ctx) : undefined);
		if (typeof(paddingTop) === 'string') {
			this.stylePaddingTop = paddingTop;
		}
		let paddingRight = this.uiItemSpec.paddingRight ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('paddingRight', this, ctx) : undefined);
		if (typeof(paddingRight) === 'string') {
			this.stylePaddingRight = paddingRight;
		}
		let paddingBottom = this.uiItemSpec.paddingBottom ?? (this.uiItemSpec.propertyValueProvider ? this.uiItemSpec.propertyValueProvider('paddingBottom', this, ctx) : undefined);
		if (typeof(paddingBottom) === 'string') {
			this.stylePaddingBottom = paddingBottom;
		}

		if (this.uiItemSpec.placement instanceof FlexItemPlacement) {
			let placement = <FlexItemPlacement>this.uiItemSpec.placement;
			if (placement.flex) {
				this.flexStyle = placement.flex;
			}
			if (placement.align) {
				this.alignSelfStyle = placement.align;
			}
		}
		else if (this.uiItemSpec.placement instanceof GridItemPlacement) {
			let placement = <GridItemPlacement>this.uiItemSpec.placement;
			if (placement.col) {
				this.gridColumnStartStyle = placement.col.toString();
			}
			if (placement.colspan) {
				this.gridColumnEndStyle = "span " + placement.colspan.toString();
			}
			if (placement.row) {
				this.gridRowStartStyle = placement.row.toString();
			}
			if (placement.rowspan) {
				this.gridColumnEndStyle = "span " + placement.rowspan.toString();
			}
		}
		/*if (!this.uiItemSpec.visible) {
			this.visible = false;
		}*/

		if (this.hasId()) {
			this.formHandler.registerComponent(this);
			//this.cssId = this.getId();
		}

		let localizationService: LocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
		this.addSubscription(localizationService.changeHandler.subscribe(() => this.onLanguageChanged()));

		// UIState Expressions
		if (this.uiItemSpec?.uiStateExpressionData) {
			this._expressionDataUIStateProvider =  this.formExpressionGrammarProcessor.evaluateUIStateExpression(this.uiItemSpec.uiStateExpressionData);
		}

		// Component-Events
		if (this.uiItemSpec.componentEvents) {
			this.addSubscription(this.subscribeComponentEvents(
				(source, event) => {
					let compEvent = this.uiItemSpec.componentEvents.find(compEvt => compEvt.eventName === event);
					if (compEvent) {
						compEvent.eventHandler(source, event, this.createEventProcessingContext());
					}
				}
			));
		}
	}

	ngAfterViewInit() {
		 this.ngZone.runOutsideAngular(() =>
			 setTimeout(() => {
				this._defaultUIStateProviderRegistered = true;
				this.registerUIStateProvider((source: IComponent, ctx: IEventProcessingContext) => this.determineDefaultUIState(ctx), true, this);
			})
		);
	}

	ngAfterContentInit() {
	}

	ngOnDestroy() {
		console.debug(`Component.ngOnDestroy() [id: '${this.id ? this.id : 'no id'}', compNumber: '${this.componentNumber}', compType: '${this.componentType}']`);

		this._uiStateProviders.forEach((v, k) => k.cancel(false, false))

		if (this._subscriptions) {
			this._subscriptions.unsubscribe();
		}
		this.formHandler.unregisterComponent(this);
	}

	abstract getFormControlStatus(): IFormStatus;

	scrollIntoView(): void {};

	protected onFormGroupChanged(): void {}

	protected getIdFromPropertyName(prop: string): string | undefined {
		if (!prop) {
			return undefined;
		}

		/*let to1RelationPath: string;
		if (this.uiItemSpec instanceof PDItemSpec) {
			to1RelationPath = (this.uiItemSpec as PDItemSpec).to1RelationItemSpec?.relationProperty;
		}*/
		let ctrlId: string;
		if (this.relationContext) {
			ctrlId = this.relationContext.path;
			ctrlId = this.relationContext.isMultiple ?
				`${ctrlId}.${this.relationContext.index}.` :
				`${ctrlId}.`;
			/*if (to1RelationPath) {
				ctrlId += `${to1RelationPath}.`;
			}*/
			ctrlId += prop;
		}
		else {
			//ctrlId = to1RelationPath ? `${to1RelationPath}.${prop}` : prop;
			ctrlId = prop;
		}
		return this._idPostfix ? ctrlId + `.${this.idPostfix}` : ctrlId;
	}

	protected addSubscription(sub: Subscription): void {
		if (!this._subscriptions) {
			this._subscriptions = sub;
		}
		else {
			this._subscriptions.add(sub);
		}
	}

	/**
	 * Liefert die Id in Abhänigkeit von der componentNumber zurück, wenn hasId() false ist
	 * Liefert die Id, wenn diese bereits gesetzt ist
	 * Liefert die Id in Abhänigkeit von dem relationContext und der componentNumber zurück, wenn ein relationContext gesetzt ist
	 * Ansonsten liefert die Id von uiItemSpec
	 */
	getId(): string {
		let relationPath: string;
		if (this.relationContext) {
			relationPath = `${this.relationContext.path}${this.relationContext.isMultiple ? '.' + this.relationContext.index : ''}`;
		}
		if (!this.hasId()) {
			return `${relationPath ? relationPath + '.' : ''}unknown${this._idPostfix ? '.' + this._idPostfix : ''}`;
		}
		if (relationPath) {
			if (this.uiItemSpec?.id) {
				return `${relationPath}.${this.uiItemSpec.id}${this._idPostfix ? '.' + this._idPostfix : ''}`;
			}
			return `${relationPath}.${ComponentTypeET[this.componentType]}.${this.componentNumber}${this._idPostfix ? '.' + this._idPostfix : ''}`;
		}
		let id = this.uiItemSpec?.id ? this.uiItemSpec?.id : ComponentTypeET[this.componentType] + '.' + this.componentNumber;
		return `${id}${this._idPostfix ? '.' + this._idPostfix : ''}`;
	}

	get hasErrors(): boolean {
		let stat = this.getFormControlStatus();
		return !stat.valid;
	}

	/**
	 * Liefert true zurück, wenn _id, uiItemSpec.id oder realtionContext gesetzt wurde
	 */
	hasId(): boolean {
		if (this.uiItemSpec?.id || this.relationContext) {
			//(this.uiItemSpec instanceof PDItemSpec && (this.uiItemSpec as PDItemSpec).to1RelationItemSpec)) { // Todo to1RelationItemSpec nach PDComponent
			return true;
		}
		return false;
	}

	protected onLanguageChanged() {}

	protected convertLengthString(value: string): [string, string, string, string] {
		if (value) {
			let tempArray = value.split(' ');
			switch (tempArray.length) {
				case 1: {
					return [tempArray[0], tempArray[0], tempArray[0], tempArray[0]];
				}
				case 2: {
					return [tempArray[0], tempArray[1], tempArray[0], tempArray[1]];
				}
				case 3: {
					return [tempArray[0], tempArray[1], tempArray[2], tempArray[1]];
				}
				case 4: {
					return [tempArray[0], tempArray[1], tempArray[2], tempArray[3]];
				}
			}
		}
		return [undefined, undefined, undefined, undefined];
	}

	protected updateValidators(ctrl?: AbstractControl): void { }


	protected get widgetInfo(): WidgetInfo {
		return this.uiItemSpec.widgetInfo
	}

	protected isPDContainer(): boolean {
		return this.uiItemSpec instanceof UIContainerSpec;
	}

	protected determineDefaultUIState(ctx: IEventProcessingContext, ...states: IComponentUIState[]): IComponentUIState {

		if (this._expressionDataUIStateProvider) {
			states.push(this._expressionDataUIStateProvider(this, this.createEventProcessingContext()));
		}

		let cb = this.uiItemSpec?.updateUIStateHandler;
		if (cb) {
			let uiState = cb(this, this.getCurrentUIState(), this.createEventProcessingContext());
			if (uiState) {
				states.push(uiState);
			}
		}
		states.push({
			readonly: (this.widgetInfo && this.widgetInfo.guiReadonly) ? true : undefined
		});

		let state: IComponentUIState = {};
		states.forEach(s => {
			state.readonly = state.readonly || s.readonly ? true : false; // todo undefined durch false ersetzen?
			state.disabled = state.disabled || s.disabled ? true : false;
			state.hidden = state.hidden || s.hidden ? true : false;
			state.mandatory = state.mandatory || s.mandatory ? true : false;
		})
		return state;
	}

	cancelUIStateProvider(registration: IUIStateProviderRegistration, updateUIState: boolean, updateChilds: boolean): void {
		if (!this._uiStateProviders.has(registration)) {
			throw new Error('UIStateProvider not registered');
		}
		this._uiStateProviders.delete(registration);
		if (updateUIState) {
			this.updateUIState(!updateChilds);
		}
	}

	private _uiStateProviders: Map<IUIStateProviderRegistration, UIStateProvider> = new Map();

	/*getUIStateProvider(key: IUIStateProviderRegistration): UIStateProvider {
		if (!this._uiStateProviders.has(key)) {
			throw new Error('Key for UIStateProvider not found.');
		}
		return this._uiStateProviders.get(key);
	}*/

	/*get uiStateProviderKeys(): IUIStateProviderRegistration[] {
		return Array.from(this._uiStateProviders.keys());
	}*/

	protected createChildComponentUIStateProviders(childs: Iterable<IComponent>): void {
		for (let child of childs) {
			child.registerUIStateProvider((source) => {
				let provider = this;
				provider.updateUIState();
				return {
					readonly: provider.uiState.readonly ? true : undefined,
					disabled: provider.uiState.disabled ? true : undefined,
					hidden: provider.uiState.hidden ? true : undefined,
					mandatory: provider.uiState.mandatory ? true : undefined
				}
			}, true, this);
		};
	}

	createEventProcessingContext(): IEventProcessingContext {
		let ctx: IEventProcessingContext = {
			relatedObject: this.getRelatedObject(),
			formHandler: this.formHandler,
			pdAccessService: ServiceLocator.injector.get(IPDAccessServiceToken),
			interactionService: ServiceLocator.injector.get(IInteractionServiceToken),
			pdClass: ServiceLocator.injector.get(IPDClassToken),
			localizationService: ServiceLocator.injector.get(LocalizationServiceToken),
			moduleStateService: ServiceLocator.injector.get(IModuleStateServiceToken),
			formExpressionGrammarProcessor: this._formExpressionGrammarProcessor,
			asyncActionManagerService: this._asyncActionManagerService
		};
		ctx.customContext = this.eventProcessingContextManagerService.customContext;
		return ctx;
	}

	set styleMargin(value: string) {
		let tuple = this.convertLengthString(value);
		this.styleMarginTop = tuple[0];
		this.styleMarginRight = tuple[1];
		this.styleMarginBottom = tuple[2];
		this.styleMarginLeft = tuple[3];
	}

	set stylePadding(value: string) {
		let tuple = this.convertLengthString(value);
		this.stylePaddingTop = tuple[0];
		this.stylePaddingRight = tuple[1];
		this.stylePaddingBottom = tuple[2];
		this.stylePaddingLeft = tuple[3];
	}


	//
	// IComponent
	//

	registerUIStateProvider(provider: UIStateProvider, updateChilds = true, providerHost?: IComponent): IUIStateProviderRegistration {
		let registration = new UIStateProviderRegistration(this, providerHost);
		this._uiStateProviders.set(registration, provider);
		this.updateUIState(!updateChilds);
		return registration;
	}

	get id(): string | undefined {
		return this.getId();
	}

	get mandatoryCustomActivated(): boolean {
		return this._mandatoryCustomActivated;
	}

	set mandatoryCustomActivated(val: boolean) {
		this._mandatoryCustomActivated = val;
	}

	protected _mandatoryCustomActivated: boolean = false;

	get isMandatory(): boolean {
		return this.uiState.mandatory ? true : false;
	}

	protected getCurrentUIState() : IComponentUIState {
		return {
			disabled: this.isDisabled,
			hidden: this.isHidden,
			mandatory: this.isMandatory,
			readonly: this.isReadonly
		};
	}

	updateUIState(onlySelf = true): void {
		let state: IComponentUIState = {
			readonly: this._defaultUIStateProviderRegistered ? false : this.initialUIState?.readonly ?? false,
			disabled: this._defaultUIStateProviderRegistered ? false : this.initialUIState?.disabled ?? false,
			hidden: this._defaultUIStateProviderRegistered ? false : this.initialUIState?.hidden ?? false,
			mandatory: this._defaultUIStateProviderRegistered ? false : this.initialUIState?.mandatory ?? false
		}
		if (this._uiStateProviders.size > 0) {
			let ctx = this.createEventProcessingContext();
			this._uiStateProviders.forEach(val => {
				let uiState = val(this, ctx);
				state = {
					readonly: uiState.readonly ? true : state.readonly,
					disabled: uiState.disabled ? true : state.disabled,
					hidden: uiState.hidden ? true : state.hidden,
					mandatory: uiState.mandatory ? true : state.mandatory
				};
			});
		}
		this.setUIState(state);
	}

	subscribeComponentEvents(callback: (source: IComponent, eventName: string) => void): Subscription | undefined {
		return undefined;
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		return undefined;
	}

	abstract get componentType(): ComponentTypeET;

	abstract reset(): void;

	get isDisabled(): boolean {
		return this.uiState.disabled ? true : false;
	}

	get isEnabled(): boolean {
		return !this.isDisabled;
	}


	get isHidden(): boolean {
		//return false; // TEST
		return this.uiState.hidden ? true : false;
	}

	/**
	 * Ist die Komponente bearbeitbar?
	 * Für die Komponenten ObjectReferences und Multiselect gibt es einen
	 * weiteren Attribut "canChange", dieser sollte ggf. auch beachtet werden.
	 */
	get isReadonly(): boolean {
		return this.uiState.readonly ? true : false;
	}

	get uiState(): IComponentUIState {
		return this._uiState;
	}

	protected setUIState(state: IComponentUIState) {
		if (state.disabled != this._uiState.disabled || state.mandatory != this._uiState.mandatory ||
			state.readonly != this._uiState.readonly || state.hidden != this._uiState.hidden) {
			let oldState = this._uiState;
			this._uiState = state;
			this.updateValidators();
			this.onUIStateChanged(oldState, this._uiState);
		}
	}

	protected onUIStateChanged(oldState: IComponentUIState, newState: IComponentUIState): void {
		this.uiStateChanged.emit([oldState, newState]);
		this._uiStateChangedSubject$.next([oldState, newState]);
	}

	private _uiState: IComponentUIState = {}

	handleCustomEvent(customEvent: CustomEvent<any>): void {
		if (this.uiItemSpec?.customEventHandler) {
			this.uiItemSpec.customEventHandler(customEvent, this, this.createEventProcessingContext());
		}
	}

	private _uiStateChangedSubject$: Subject<[IComponentUIState, IComponentUIState]> = new Subject();

	private _uiStateChanged$: Observable<[IComponentUIState, IComponentUIState]>;

	get uiStateChanged$(): Observable<[IComponentUIState, IComponentUIState]> {
		if (!this._uiStateChanged$) {
			this._uiStateChanged$ = this._uiStateChangedSubject$.asObservable();
		}
		return this._uiStateChanged$;
	}

	/*protected getCommandNamesImpl(): string[] {
		return [];
	}

	get commandNames(): string[] {
		return this.getCommandNamesImpl();
	}*/

	executeCommand(name: string, params: CommandParameter): void {}
}
