import { CustomPDObject } from "@otris/ng-core-shared";
import { AbstractEnumeration, ICustomPDObject, ICustomPDObjectFragment, IPDClass, IPDObject } from "@otris/ng-core-types";

export abstract class PDClassBase implements IPDClass {

	private _classDefaultValues: Map<string, ICustomPDObjectFragment> = new Map();

	abstract createInstance(className: string, relationPath?: string[], oid?: string, isNew?: boolean, metaData?: any): IPDObject; // todo: Typ MetaData!

	createEnumInstance(obj: CustomPDObject, propName: string, value: number | string): AbstractEnumeration | undefined {
		return obj.createEnumInstance(propName, value);
	}

	abstract initCustomPDObject(obj: ICustomPDObject): void;

	registerDefaultValues(defaultValues: ICustomPDObjectFragment): void {
		if (this._classDefaultValues.has(defaultValues.className)) {
			console.warn(`Default values for class ${defaultValues.className} already registered.`);
		}
		this._classDefaultValues.set(defaultValues.className, defaultValues);
	}

	getDefaultValues(className: string): ICustomPDObjectFragment | undefined {
		return this._classDefaultValues.get(className);
	}
}