import { Component, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDComponent, LabelWidgetInfo, FormHandlerService, EventProcessingContextManagerService } from "@otris/ng-core";
import { ComponentTypeET, ICssStyle } from '@otris/ng-core-types';

@Component({
	selector: 'otris-pd-label',
	template: `
		<div class="root-container">
			<div [ngStyle]="customStyle">{{label}}</div>
				@if (hasShortDescription) {
					<otris-pd-short-description
						class="short-description"
						[highlighted]="mouseIsOver"
						[pdObject]="pdObject"
						[pdComponent]="this"
					/>
				}
		</div>
	`,
	styles: [`
		.root-container {
			display: flex;
		}
		.short-description {
			margin-left: 0.5em;
		}
	`]
})
export class PDLabelComponent extends PDComponent {

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	//@Input() staticLabel: string;

	private _customStyle: ICssStyle;

	@Input() set customStyle(style: ICssStyle) {
		this._customStyle = style;
	}

	get customStyle(): ICssStyle {
		return this._customStyle;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		//this.staticLabel = this.label;
		let wi = this.labelWidgetInfo;
		if (wi) {
			/*if (wi.labelId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(wi.labelId).subscribe(res => this.staticLabel = res);
			}
			else if (this.labelWidgetInfo.label) {
				this.staticLabel = wi.label;
			}*/
			if (wi.styleSpec && !this.customStyle) {
				this.customStyle = wi.styleSpec.style;
			}
		}
	}

	/*protected hasId(): boolean {
		return true;
	}*/

	getId(): string | undefined {
		if (super.getId()) {
			return super.getId();
		}
		return "label." + this.componentNumber;
	}

	/*protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.labelWidgetInfo && this.labelWidgetInfo.labelId && this.pdObject) {
			this.pdObject.metaData.getStringFromId(this.labelWidgetInfo.labelId).subscribe(res => this.staticLabel = res);
		}
	}*/

	/*get staticLabel(): string {
		if (this.labelWidgetInfo) {
			if (this.labelWidgetInfo.labelId && this.pdObject) {
				return this.pdObject.metaData.getStringFromId(this.labelWidgetInfo.labelId);
			}
			else if (this.labelWidgetInfo.label) {
				return this.labelWidgetInfo.label;
			}
		}

		return this.label;
	}*/

	private get labelWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof LabelWidgetInfo ?
			<LabelWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}
}
