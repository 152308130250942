import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { PDLabeledControlComponent, FormHandlerService, EventProcessingContextManagerService, LocalizationServiceToken } from '@otris/ng-core';
import { ComponentTypeET, IInputSwitchComponent, ILocalizationService } from '@otris/ng-core-types';

@Component({
	selector: 'otris-pd-input-switch',
	template: `
		<otris-pd-labeled-control-frame 
			[labeledControl]="this" 
			(toolBarButtonClick)="onToolBarButtonClick($event)" 
			[pdObject]="pdObject" 
			[relatedFormControl]="this.control"
			[id]="getId() + '.inputSwitch'"
		>
			@if (!isReadonly) {
				<!-- todo: k-display-inline-flex ist nur workaround -->
				<kendo-switch class="k-display-inline-flex switch" [onLabel]="onLabel$ | async" [offLabel]="offLabel$ | async"
					(valueChange)="onValueChange($event)" [formControl]="formControl"></kendo-switch>
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		.switch {
			margin: auto 0;
			flex: 1;
		}
	`]
})
export class PDInputSwitchComponent extends PDLabeledControlComponent implements IInputSwitchComponent {

	@Input() onLabel: string;

	@Input() offLabel: string;

	onLabel$: Observable<string>;

	offLabel$: Observable<string>;

	get isContainerComponent(): boolean {
		return false;
	}
	
	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangedSubject$ = new BehaviorSubject(this.control ? this.control.value : undefined);
		this.localizationService.changeHandler.subscribe(lang => this.updateLabels());
		this.updateLabels();
	}

	onValueChange(event: any) {
		/*if (this.hasId) {
			this.formHandler.notifyCheckChanged(this);
		}*/
		//this._currentValue = this.checked;
		this._valueChangedSubject$.next(this.checked);
	}

	private updateLabels(): void {
		this.onLabel$ = this.onLabel ? of(this.onLabel) :
			this.localizationService.getSystemString('kendo-ui.components.pd-input-switch.on-label');
		this.offLabel$ = this.onLabel ? of(this.offLabel) :
			this.localizationService.getSystemString('kendo-ui.components.pd-input-switch.off-label');
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.InputSwitch;
	}

	//----------------------------------------------------------------------------------------------
	// IValueComponent
	//----------------------------------------------------------------------------------------------

	/*
	private _currentValue: boolean = false;

	get value(): any {
		return this._currentValue;
	}

	set value(val: any) {
		if (this.control && typeof (val) === 'boolean') {
			//this._currentValue = val;
			this.control.setValue(val);
		}
	}
	*/

	//----------------------------------------------------------------------------------------------
	// IInputSwitchComponent
	//----------------------------------------------------------------------------------------------

	get checked(): boolean {
		//return this.formGroup.get(this.propertyName).value;
		return this.control ? this.control.value : false;
	}

	private _valueChangedSubject$: BehaviorSubject<boolean>;// = new Subject();

	private _valueChanged$: Observable<boolean>;

	get valueChanged(): Observable<boolean> {
		if (!this._valueChanged$) {
			this._valueChanged$ = this._valueChangedSubject$.asObservable();
		}
		return this._valueChanged$;
	}
}
