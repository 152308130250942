import { Injectable, TemplateRef, Inject, InjectionToken } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap, tap } from "rxjs/operators";
import { DialogService, DialogCloseResult, DialogAction, DialogRef, DialogSettings } from '@progress/kendo-angular-dialog';
import {
	IInteractionService, DialogResultET, SelectionModeET, ISelectPDObjectResult, ISelectPDObjectsResult, ISelectObjectOptions, IActionButton,
	ILocalizationService, IDialogSettings, IPDObject
} from '@otris/ng-core-types';
import {
	IErrorHandler, IErrorHandlerToken, ServiceLocator, PDObject
} from '@otris/ng-core-shared'
import { PDObjectSelectionComponent, ISelectionResult } from '../components/pd-object-selection/pd-object-selection.component';
import { LocalizationServiceToken } from '@otris/ng-core';


//IInteractionServiceToken = new InjectionToken<IInteractionService>('IInteractionServiceToken');

@Injectable()
export class InteractionService implements IInteractionService {

	private openDialogs: DialogRef[] = [];

	private closeTitle = 'Close';

	get isDialogVisible(): boolean {
		return this.openDialogs.length > 0;
	}

	constructor(
		private dialogService: DialogService,
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService
	) {
		errorHandler.errorOccurred$.subscribe(() => {
			this.openDialogs.forEach(d => d.close());
			this.openDialogs = [];
		});

		this.localizationService.changeHandler.pipe(
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.interaction-dialog.close-title',
			])),
			tap(trans => {
				this.closeTitle = trans[0];
			})
		).subscribe();
	}

	showOkMessage(title: string, message: string, settings?: IDialogSettings): Observable<void> {
		let dlgSettings = this.mergeDialogSettings(<DialogSettings>
			{
				title: title,
				content: message,
				actions: [
					{ text: 'Ok', themeColor: "primary", cssClass: "otris-action-button-ok" }
				],
				closeTitle: this.closeTitle
			}, settings);
		let dialog = this.dialogService.open(dlgSettings);
		return dialog.result.pipe(map(res => { }));
	}

	showConfirmMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET> {

		let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);

		return forkJoin([
			localizationService.getSystemString('kendo-ui.components.dialog.ok'),
			localizationService.getSystemString('kendo-ui.components.dialog.cancel')
		]).pipe(
			switchMap(res => {
				let dlgSettings = this.mergeDialogSettings(<DialogSettings>
					{
						title: title,
						content: message,
						actions: [
							{ text: res[0], themeColor: "primary", id: DialogResultET.Ok, cssClass: "otris-action-button-ok" },
							{ text: res[1], id: DialogResultET.Cancel, cssClass: "otris-action-button-cancel" }
						],
						closeTitle: this.closeTitle
					}, settings);
				let dialog = this.dialogService.open(dlgSettings);
				return dialog.result.pipe(
					map(res => {
						if (!(res instanceof DialogCloseResult)) {
							return (<any>res).id;
						}
						return DialogResultET.Cancel;
					})
				);
			})
		);
	}

	showYesNoMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET> {

		let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);

		return localizationService.getSystemStrings([
			'kendo-ui.components.dialog.yes',
			'kendo-ui.components.dialog.no'
		]).pipe(
			map(trans => {
				return this.mergeDialogSettings(<DialogSettings>
					{
						title: title,
						content: message,
						actions: [
							{ text: trans[0], themeColor: "primary", id: DialogResultET.Yes, cssClass: "otris-action-button-yes" },
							{ text: trans[1], id: DialogResultET.No, cssClass: "otris-action-button-no" }
						],
						closeTitle: this.closeTitle
					}, settings);
			}),
			switchMap(dlgSettings => this.dialogService.open(dlgSettings).result),
			map(res => {
				if (!(res instanceof DialogCloseResult)) {
					return (<any>res).id;
				}
				return DialogResultET.No;
			})
		)

		// let dlgSettings = this.mergeDialogSettings(<DialogSettings>
		// 	{
		// 		title: title,
		// 		content: message,
		// 		actions: [
		// 			{ text: 'Ja', themeColor: "primary", id: DialogResultET.Yes },
		// 			{ text: 'Nein', id: DialogResultET.No }
		// 		]
		// 	}, settings);
		// let dialog = this.dialogService.open(dlgSettings);
		// return dialog.result.pipe(
		// 	map(res => {
		// 		if (!(res instanceof DialogCloseResult)) {
		// 			return (<any>res).id;
		// 		}
		// 		return DialogResultET.No;
		// 	})
		// );
	}

	selectPDObject(className: string, title: string, preSelectedObject?: PDObject, options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectResult> {
		let dialog = this.dialogService.open({
			title: title,
			content: PDObjectSelectionComponent,
			closeTitle: this.closeTitle
		});

		let comp: PDObjectSelectionComponent = dialog.content.instance;
		comp.className = className;
		comp.selectionMode = SelectionModeET.Single;
		comp.customContent = customContent;
		comp.options = options;
		comp.preSelectedObject = preSelectedObject;
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				let selectedObject: PDObject;
				let result = <ISelectionResult>res;
				if (result && result.selection && !Array.isArray(result.selection)) {
					selectedObject = result.selection;
				}
				return <ISelectPDObjectResult>{ selection: selectedObject };
			})
		);
	}

	selectPDObjects(className: string, title: string, preSelectedObjects?: PDObject[], options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectsResult> {
		let dialog = this.dialogService.open({
			title: title,
			content: PDObjectSelectionComponent,
			closeTitle: this.closeTitle
		});

		let comp: PDObjectSelectionComponent = dialog.content.instance;
		comp.className = className;
		comp.selectionMode = SelectionModeET.Multiple;
		comp.customContent = customContent;
		comp.options = options;
		comp.preSelectedObjects = preSelectedObjects;
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				let selectedObjects: PDObject[];
				let result = <ISelectionResult>res;
				if (result && result.selection && Array.isArray(result.selection)) {
					selectedObjects = result.selection;
				}
				return <ISelectPDObjectsResult>{ selection: selectedObjects };
			})
		);
	}

	showCustomMessage(title: string, buttons: IActionButton[], content: TemplateRef<any>): Observable<DialogResultET> {
		let dialog = this.dialogService.open({
			title: title,
			content: content,
			actions: buttons,
			closeTitle: this.closeTitle
		});
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				if (!(res instanceof DialogCloseResult)) {
					return (<any>res).id;
				}
				return DialogResultET.Cancel;
			})
		);
	}

	private mergeDialogSettings(dlgSettings: DialogSettings, settings: IDialogSettings): DialogSettings {
		if (settings) {
			if (settings.height) {
				dlgSettings.height = settings.height;
			}
			if (settings.width) {
				dlgSettings.width = settings.width;
			}
			if (settings.minHeight) {
				dlgSettings.minHeight = settings.minHeight;
			}
			if (settings.minWidth) {
				dlgSettings.minWidth = settings.minWidth;
			}
			if (settings.maxHeight) {
				dlgSettings.maxHeight = settings.maxHeight;
			}
			if (settings.maxWidth) {
				dlgSettings.maxWidth = settings.maxWidth;
			}
		}
		return dlgSettings;
	}
}
