import { Injectable } from "@angular/core";
import { IPrivacyNgModuleSettingsService, IModuleDependencies } from "@otris/privacy-ng-lib"
import { Version } from '@otris/ng-core-shared';
import { environment } from '../../environments/environment';
import pkgJson from '../../../../../package.json';
import angularCorePkgJson from '@angular/core/package.json';
import otrisPrivacyNgLibPkgJson from '@otris/privacy-ng-lib/package.json';
import otrisJafWebPkgJson from '@otris/jaf-web/package.json';
import otrisNgCoreSharedPkgJson from '@otris/ng-core-shared/package.json';
import otrisNgCorePkgJson from '@otris/ng-core/package.json';
import otrisKendoUIPkgJson from '@otris/kendo-ui/package.json';

@Injectable()
export class LandingPageSettingsService extends IPrivacyNgModuleSettingsService {

	// Neu
	get moduleRequestMethod(): string {
		return 'Optionen::handleModuleRequest';
	}
	get moduleRequestMethodParams(): string[] {
		return ['Custom', 'landing-page', 'false'];  // todo: ohne -extension?
	}
	get customModuleRequestMethod(): string {
		return 'Optionen::handleModuleRequest';
	}
	get customModuleRequestMethodParams(): string[] {
		return ['Custom', 'landing-page', 'false'];
	}

	// Alt
	//
	// get initParamsRequestMethod(): string {
	// 	return 'Optionen::handleModuleRequest'; // todo
	// }
	//
	// get initParamsRequestMethodParams(): string[] {
	// 	return ['Custom', 'landing-page'];  // todo: ohne -extension?
	// }
	//
	// get customRequestMethod(): string {
	// 	return 'Optionen::handleModuleRequest';
	// }
	//
	// get customRequestMethodParams(): string[] {
	// 	return ['Custom', 'landing-page'];
	// }


	get themeId(): string | undefined {
		return undefined;
	}

	get moduleVersion(): Version {
		return new Version(pkgJson.version);
	}

	get moduleVersionHash(): string | null {
		let pkgVersion = pkgJson.version;
		if (pkgVersion.includes('+')) {
			return pkgVersion.slice(pkgVersion.indexOf('+') + 1);
		}
		return null;
	}

	get dependencies(): IModuleDependencies {
		return <IModuleDependencies>{
			privacyNgLibVersion: otrisPrivacyNgLibPkgJson.version,
			otrisJafWebVersion: otrisJafWebPkgJson.version,
			otrisNgCoreSharedVersion: otrisNgCoreSharedPkgJson.version,
			otrisNgCoreVersion: otrisNgCorePkgJson.version,
			otrisKendoUIVersion: otrisKendoUIPkgJson.version,
			angularCoreVersion: angularCorePkgJson.version
		};
	}

	get productionVersion(): boolean {
		return environment.production;
	}
}
