import { AfterViewInit, ChangeDetectorRef, Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomPDObject, IPDClassToken, PDObject } from '@otris/ng-core-shared';
import { IPDObject, IRelationContext, IToNRelationSpec, IToNRelationItemContainer, NewRelationObjectPositionT, IComponent, IPDClass, IPDComponent, IAsyncActionManagerService } from '@otris/ng-core-types';
import { FormHandlerService } from '../services/form-handler.service';
import { finalize, Observable, Subject } from "rxjs";
import { IAsyncActionManagerServiceToken } from "../services/async-action-manager.service";

@Directive({
	selector: '[otrisToNRelationItemContainer]'
})
export class ToNRelationItemContainerDirective implements OnInit, AfterViewInit, OnDestroy {

	@Input('otrisToNRelationItemContainer') relationSpec: IToNRelationSpec;

	@Input('relItemContainerItemIndex') relationItemIndex = -1;

	@Input('relItemContainerItemCount') relationItemCount = 0;

	@Input('relItemContainerFormGroup') formGroup: UntypedFormGroup;

	@Input('relItemContainerPDObject') pdObject: PDObject;

	@Input('relItemContainerBaseObject') baseObject: PDObject;

	@Input('relItemContainerRelationContext') relationContext: IRelationContext;

	@Input('relItemContainerHostComponent') hostComponent: IComponent;

	private get relationFormArray(): UntypedFormArray | undefined {
		return this.formGroup.get(this.relationSpec.relationProperty) as UntypedFormArray;
	}

	private _relationItemFormGroup: UntypedFormGroup;

	get relationItemFormGroup(): UntypedFormGroup {
		return this._relationItemFormGroup;
	}

	// todo ggf. ToNRelationItemContainer umbenennen
	get toNRelationItemContainer(): IToNRelationItemContainer {
		return {
			relationSpec: this.relationSpec,
			hostId: this.hostId,
			host: this.hostComponent,
			baseObject: this.baseObject,
			relationItemIndex: this.relationItemIndex,
			relationItemCount: this.relationItemCount,
			createRelationObject: () => this.createRelationObject(this.relationSpec.newRelationObjectPosition ?? 'end'),
			deleteRelationObject: () => this.deleteRelationObject()
		};
	}

	private get hostId(): string | undefined {
		if (!this.relationObjectExisting) {
			return undefined;
		}
		let id = this.relationContext ? this.relationContext.path + '.' : '';
		id += this.relationSpec.relationProperty + '.' + this.relationItemIndex;
		return id;
	}

	private get relationObjectExisting(): boolean {
		return !!this.pdObject;
	}

	private _relationObjectCreatedSubject$: Subject<IPDComponent> = new Subject();

	private _relationObjectCreated$: Observable<IPDComponent>;

	get relationObjectCreated$(): Observable<IPDComponent> {
		if (!this._relationObjectCreated$) {
			this._relationObjectCreated$ = this._relationObjectCreatedSubject$.asObservable();
		}
		return this._relationObjectCreated$;
	}

	constructor(
		private cdref: ChangeDetectorRef,
		private formHandler: FormHandlerService,
		@Inject(IPDClassToken) private pdClass: IPDClass,
		@Inject(IAsyncActionManagerServiceToken) private asyncActionManagerService: IAsyncActionManagerService,
	) {
	}

	ngOnInit(): void {
		if (this.relationObjectExisting) {
			this.createControl();
			this.formHandler.registerComponent(this.hostComponent, this.hostId);
		}
	}

	ngAfterViewInit(): void {
		if (this.relationObjectExisting && this.relationSpec.relationObjectCreatedHandler) {
			const asyncCallRelationObjectCreatedHandler$ = new Observable((subscriber) => {
				this.relationSpec.relationObjectCreatedHandler(this.toNRelationItemContainer, this.pdObject, this.hostComponent.createEventProcessingContext());
				this.formHandler.updateUIState();
				subscriber.complete();
			}).pipe(
				finalize(() => this.asyncActionManagerService.notifyActionFinished(asyncCallRelationObjectCreatedHandler$))
			);
			this.asyncActionManagerService.notifyActionStarted(asyncCallRelationObjectCreatedHandler$);
			asyncCallRelationObjectCreatedHandler$.subscribe();
		}
	}

	ngOnDestroy(): void {
		 if (this.relationObjectExisting) {
			let formArray = this.relationFormArray;
			let index = formArray.controls.findIndex(c => c === this.relationItemFormGroup)
			formArray.removeAt(index);
			this.formHandler.unregisterComponent(this.hostComponent, this.hostId);
		 }
	}

	getRelationContext(): IRelationContext  {
		if (!this.relationObjectExisting) {
			throw new Error('getRelationContext() not accessible in this condition.');
		}
		let relationPath: string;
		if (this.relationContext) {
			if (this.relationContext.isMultiple) {
				relationPath = this.relationContext.path + '.' + this.relationContext.index + '.' + this.relationSpec.relationProperty;
			} else {
				relationPath =  this.relationContext.path + '.' + this.relationSpec.relationProperty
			}
		} else {
			relationPath = this.relationSpec.relationProperty;
		}
		return { source: this.pdObject, path: relationPath, isMultiple: true, index: this.relationItemIndex.toString() };
	}


	private createControl(): void {
		let formArray = this.relationFormArray;
		if (!formArray) {
			formArray = new UntypedFormArray([]);
			this.formGroup.addControl(this.relationSpec.relationProperty, formArray);
		}
		this._relationItemFormGroup = new UntypedFormGroup({
			_className: new UntypedFormControl(this.pdObject.metaData.className),
			_objectId: new UntypedFormControl(this.pdObject.objectId),
			_metaData: new UntypedFormControl(this.pdObject.metaData),
			_isNew: new UntypedFormControl(this.pdObject instanceof CustomPDObject ? this.pdObject.isNew : false),
			_unusedRawProperties: new UntypedFormControl(this.pdObject.unusedRawProperties),
			_auxiliaryProperties: new UntypedFormControl(this.pdObject.auxiliaryProperties)
		});
		formArray.push(this._relationItemFormGroup);
	}

	private createRelationObject(pos: NewRelationObjectPositionT): IPDObject {
		let relObj = this.relationSpec.createRelationObjectCallback(this.pdClass);
		if (!relObj) {
			throw new Error('Relation object could not be created.');
		}
		let relObjs = <IPDObject[]>this.baseObject[this.relationSpec.relationProperty];
		let index: number;
		switch (pos) {
			case 'before':
				index = this.relationItemIndex;
				break;
			case 'after':
				index = this.relationItemIndex + 1;
				break;
			default:
				index = relObjs.length;
				break;
		}
		relObjs.splice(index, 0, relObj);
		setTimeout(() => {
			this.cdref.detectChanges();
			let compRelItem = this.formHandler.getRelationItemHostComponent(relObj);
			if (compRelItem) {
				compRelItem.scrollIntoView();
				this._relationObjectCreatedSubject$.next(compRelItem);
			}
			this.formHandler.updateUIState();
		});
		return relObj;
	}

	private deleteRelationObject(): void {
		let relObjs = <IPDObject[]>this.baseObject[this.relationSpec.relationProperty];
		if (this.relationItemIndex < 0 || this.relationItemIndex >= relObjs.length) {
			throw new Error('Invalid relation object index');
		}
		relObjs.splice(this.relationItemIndex, 1);
	}
}
