import { Injectable, Inject } from '@angular/core';
import { Observable, AsyncSubject, EMPTY, of } from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import { IPDAccessService } from '@otris/ng-core-types';
import {
	IPDAccessServiceToken, IAuthService, ILoginResult, IAuthProvider, AuthenticationMode
} from '@otris/ng-core-shared';

@Injectable()
export class AuthService implements IAuthService {

	private _loginOccurredSubject$: AsyncSubject<boolean> = new AsyncSubject();

	get loginOccurred$(): Observable<boolean> {
		return this._loginOccurredSubject$.asObservable();
	}

	private _authProvider: IAuthProvider;

	constructor(
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService
	) { }

	registerAuthProvider(provider: IAuthProvider): void {
		if (this._authProvider) {
			throw new Error('Auth provider already registered.')
		}
		this._authProvider = provider;
	}

	get isAuthenticated(): boolean {
		return this._authProvider ? this._authProvider.isAuthenticated : false;
	}

	get payload(): object | undefined {
		return this._authProvider ? this._authProvider.payload : undefined;
	}

	login(user: string, pwd: string, principal: string): Observable<ILoginResult> {

		// Login sollte immer standfinden, evtl. möchte man sich mit einem anderen User anmelden oder die Session ist nicht
		// mehr gültig.
		// if (this._isLoggedIn) {
		// 	return of({success: true});
		// 	// return of(true);
		// }

		//this._isLoggingIn = true;
		// 'anonymous'
		return this.logout().pipe(
			switchMap(() => this.pdAccessService.changeUser(user, pwd, principal)),
			map(res => res.errorCode === 0 ? { success: true } : { success: false, errorMessage: res.msg, errorCode: res.errorCode }),
			tap(res => {
				this._isLoggedIn = res.success;
				this._loginFailed = !this._isLoggedIn;
				if (this._isLoggedIn)
				{
					this._user = user;
					this._loginOccurredSubject$.next(true);
					this._loginOccurredSubject$.complete();
				}
			})
		);
		// return this.pdAccessService.changeUser(user, pwd).pipe(
		// 	map(res => res.errorCode === 0 ? { success: true } : { success: false, errorMessage: res.msg, errorCode: res.errorCode }),
		// 	tap(res => {
		// 		this._isLoggedIn = res.success;
		// 		this._loginFailed = !this._isLoggedIn;
		// 		if (this._isLoggedIn)
		// 		{
		// 			this._user = user;
		// 			this._loginOccurredSubject$.next(true);
		// 			this._loginOccurredSubject$.complete();
		// 		}
		// 	})
		// )
	}

	logout(): Observable<void> {
		if (!this.isLoggedIn) {
			return of(undefined);
		}
		this._user = undefined;
		this._isLoggedIn = false;
		this._loginFailed = false;
		this._loginOccurredSubject$ = new AsyncSubject();
		return this.pdAccessService.disconnect().pipe(
			tap(res => {
				console.log(`AuthService.logout(${res})`);
				//this._isLoggedIn = false;
			}),
			map(res => {})
		)
	}

	get isAuthenticatedAndLoggedIn() : boolean {
		// switch (this._authenticationMode) {
		// 	case AuthenticationMode.Anonymous:
		// 	case AuthenticationMode.Login:
		// 		return this.isLoggedIn;

		// 	case AuthenticationMode.ADAuthentication:
		// 		return this.isLoggedIn && this.isAuthenticated;
		// }
		// return false;

		if (this._authenticationMode === AuthenticationMode.ADAuthentication) {
			return this.isLoggedIn && this.isAuthenticated;
		}
		return this.isLoggedIn;
	}

	get authenticationMode(): AuthenticationMode {
		return this._authenticationMode;
	}

	set authenticationMode(mode: AuthenticationMode) {
		this._authenticationMode = mode;
	}

	private _authenticationMode: AuthenticationMode;

	get isLoggedIn(): boolean {
		return this._isLoggedIn;
	}

	private _isLoggedIn: boolean = false;

	get loginFailed(): boolean {
		return this._loginFailed;
	}

	private _loginFailed: boolean = false;

	get user(): string | undefined {
		return this._user;
	}

	private _user: string;
}
