import { Directive, Input } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PDObject, PropertyAccessRightET, ServiceLocator } from "@otris/ng-core-shared";
import { ErgNameData, IComponent, IComponentUIState, IEventProcessingContext, IFormStatus, ILocalizationService, IPDComponent, IPDObject, ITo1RelationItemSpec, LanguageCodeET } from "@otris/ng-core-types";
import { Observable, of, Subscription } from "rxjs";
import { EventProcessingContextManagerService } from "../../services/event-processing-context-manager.service";
import { FormHandlerService } from "../../services/form-handler.service";
import { UIAbstractComponent } from "../ui-abstract/ui-abstract.component";
import { PDItemSpec } from "../../model/pd-layout";
import { IMetaDataService } from "../../services/meta-data.service";
import { LocalizationServiceToken } from "../../services/localization.service";

@Directive()
export abstract class PDLikeComponent extends UIAbstractComponent implements IPDComponent {

	private _metaDataChangedSubscription: Subscription;

	protected get metaDataService(): IMetaDataService {
		return ServiceLocator.injector.get(IMetaDataService);
	}

	protected get defaultValue(): any | undefined {
		return undefined;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();

		// Todo: Wofür braucht man das?
		this.route.data.subscribe((data: { pdObject: PDObject }) => {
			if (data.pdObject) {
				this.pdObject = data.pdObject;
			}
		});

		if (!this._pdObject) {
			throw new Error(`No PDObject set.`);
		}

		if (this.propertyRelationPath) {
			this.updateRelationObject();
			if (this.formGroup.contains(this.propertyRelationPath)) {
				this._relationObjectFormGroup = this.formGroup.get(this.propertyRelationPath) as UntypedFormGroup;
			}
			else {
				this._relationObjectFormGroup = new UntypedFormGroup({
					_className: new UntypedFormControl(this._relationObject.metaData.className),
					_objectId: new UntypedFormControl(this._relationObject.objectId),
					_metaData: new UntypedFormControl(this._relationObject.metaData),
					_isNew: new UntypedFormControl(false)
				});
				this.formGroup.addControl(this.propertyRelationPath, this._relationObjectFormGroup);
			}
		}
		let ctrl = this.createControl();
		if (ctrl) {
			if (this.propertyRelationPath) {
				this._relationObjectFormGroup.addControl(this.propertyName, ctrl);
			}
			else {
				if (this.formGroup.contains(this.propertyName)) {
					console.debug(`FormControl with name '${this.propertyName}' already exists in FormGroup. Will be overwritten with a new control.`);
					this.formGroup.removeControl(this.propertyName);
				}
				this.formGroup.addControl(this.propertyName, ctrl);
			}

			ctrl.valueChanges.subscribe(val => {
				this.onControlValueChanges(val);
			});
		}
		this.onPDObjectChanged();

		if (this.uiItemSpec instanceof PDItemSpec) {
			let pdItemSpec = this.uiItemSpec as PDItemSpec;
			if (pdItemSpec.label && !this.customLabel) {
				this.customLabel = pdItemSpec.label;
			}
			else if (pdItemSpec.labelId && !this.customLabelId) {
				this.customLabelId = pdItemSpec.labelId;
			}
		}

		this.updateLabel();
	}

	ngOnDestroy() {
		if (this.propertyRelationPath) {
			this.formGroup.removeControl(this.propertyRelationPath);
		}
		else {
			if (this.propertyName && this.formGroup.contains(this.propertyName)) {
				this.formGroup.removeControl(this.propertyName);
			}
		}
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		let ctrl = this.control;
		if (ctrl && !ctrl.disabled) {
			stat.pristine = ctrl.pristine;
			stat.touched = ctrl.touched;
			stat.valid = ctrl.valid;
		}
		return stat;
	}

	protected abstract createControl(): AbstractControl | undefined;

	protected onPDObjectChanging() {
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
			this._metaDataChangedSubscription = undefined;
		}
	}

	/**
	 * Wird aufgerufen, sobald sich das PDObject ändert. Zum Beispiel,
	 * wenn aus dem local storage etwas geladen wird oder
	 * das Formular zurückgesetzt wird.<
	 */
	protected onPDObjectChanged() {
		if (!this._pdObject) {
			return;
		}
		let ctrl = this.control;
		if (ctrl) {
			this.setControlValue(this.pdObject.pdObjectRaw[this.propertyName] !== undefined ? this.pdObject.pdObjectRaw[this.propertyName] : this.defaultValue);
			if (!ctrl.valid) {
				ctrl.markAsDirty();
			}
		}
		this.applyAccessRights();

		this.onLanguageChanged();
		this._metaDataChangedSubscription = this.pdObject.metaData.metaDataChanged$.subscribe(
			() => this.onMetaDataChanged()
		);
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		this.updateLabel();
		if (this.uiItemSpec?.shortDescription) {
			let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
			switch (localizationService.currentLanguage.code) {
				case LanguageCodeET.de:
					this._shortDescription = this.uiItemSpec.shortDescription.de;
					break;
				case LanguageCodeET.en:
					this._shortDescription = this.uiItemSpec.shortDescription.en;
					break;
			}
			this._hasShortDescription = !!this._shortDescription;
		}
		else if (this.uiItemSpec?.shortDescriptionId) {
			let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
			localizationService.getStringFromId(this.uiItemSpec.shortDescriptionId).subscribe(
				res => {
					this._shortDescription = res;
					this._hasShortDescription = !!res && res !== this.uiItemSpec.shortDescriptionId;
				}
			);
		}
		else if (this.uiItemSpec.shortDescriptionIdHandler) {
			const localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
			const id = this.uiItemSpec.shortDescriptionIdHandler(this, this.createEventProcessingContext())
			localizationService.getStringFromId(id).subscribe(
				res => {
					this._shortDescription = res;
					this._hasShortDescription = !!res && res !== id;
				}
			);
		}
		else {
			if (this.propertyName && this.pdObject.metaData.hasShortDescription(this.propertyName)) {
				this.pdObject.metaData.getShortDescription(this.propertyName).subscribe(
					res => {
						this._shortDescription = res;
						this._hasShortDescription = !!res &&
							res !== this.pdObject.metaData.getShortDescriptionId(this.propertyName)
					}
				);
			}
		}
	}

	protected onMetaDataChanged(): void {
		this.applyAccessRights();
	}

	protected onControlValueChanges(val: any) {}

	protected onUIStateChanged(oldState: IComponentUIState, newState: IComponentUIState): void {
		super.onUIStateChanged(oldState, newState);
		if (oldState.disabled !== newState.disabled) {
			let ctrl = this.control;
			if (!ctrl) {
				return;
			}

			if (this.isDisabled) {
				ctrl.disable();
			}
			else {
				ctrl.enable();
			}
		}
	}

	protected setControlValue(value: any): void {
		let ctrl = this.control;
		if (ctrl && ctrl.value !== value) {
			ctrl.setValue(value);
		}
	}

	protected applyAccessRights(): void {
		if (!this.propertyName) {
			return;
		}
		this.updateValidators();
	}

	protected determineDefaultUIState(ctx: IEventProcessingContext, ...states: IComponentUIState[]): IComponentUIState {
		let state: IComponentUIState = {};
		if (this.propertyName) {
			let metaData = this.pdObject.metaData;
			let canWrite = metaData.getPropertyAccessRight(PropertyAccessRightET.Write, this.propertyName);
			state.readonly = canWrite === false ? true : undefined;
			let mandatory = metaData.isMandatory(this.propertyName);
			state.mandatory = mandatory ? true : false;
		}
		return super.determineDefaultUIState(ctx, state, ...states);
	}

	protected updateValidators(ctrl?: AbstractControl): void {
		if (!ctrl) {
			ctrl = this.control;
			if (!ctrl) {
				return;
			}
		}
		let validators = this.pdObject.metaData.getValidators(this.propertyName);
		if (this.isMandatory) {
			if (!validators) {
				validators = [];
			}
			if (!validators.includes(Validators.required)) {
				validators.push(Validators.required);
			}
		}

		if (this.uiItemSpec.customValidators?.length > 0) {
			if (!validators) {
				validators = [];
			}
			let ctx = this.createEventProcessingContext();
			validators.push(...this.uiItemSpec.customValidators.map(f => f(this, ctx)));
		}

		this.getCustomValidators(validators).subscribe(
			res => {
				if (res) {
					if (!validators) {
						validators = [];
					}
					validators.push(...res);
				}
				ctrl.clearValidators();
				if (validators) {
					ctrl.setValidators(validators);
				}
				ctrl.updateValueAndValidity();
			}
		);
	}

	protected getCustomValidators(systemValidators: ValidatorFn[]): Observable<ValidatorFn[] | undefined> {
		return of(undefined);
	}

	// todo: weg
	/*protected getLabelImpl(): string {
		if (this._useCustomLabel && this._customLabel) {
			return this._customLabel;
		}
		return this._label;
	}*/

	protected updateLabel(): void {
		this.updateLabelImpl().subscribe(res => this._label = res);
	}

	protected updateLabelImpl(): Observable<string> {
		let localizationService: ILocalizationService = ServiceLocator.injector.get(LocalizationServiceToken);
		if (this.customLabel) {
			switch (localizationService.currentLanguage.code) {
				case LanguageCodeET.de:
					return of(this.customLabel.de);
					break;
				case LanguageCodeET.en:
					return of(this.customLabel.en);
			}
		}
		else if (this.customLabelId) {
			return this.pdObject ? this.pdObject.metaData.getStringFromId(this.customLabelId) : of ('n.a.')
		}
		else if (this.propertyName) {
			return this.pdObject.metaData.getPropertyErgname(this.propertyName);
		}
		return of('n.a.');
	}

	private updateRelationObject(): void {
		if (!this._pdObject || !this.propertyRelationPath) {
			return;
		}
		let relObj = this._pdObject[this.propertyRelationPath]
		if (relObj !== undefined && !(relObj instanceof PDObject)) {
			throw new Error('Relation object not of type PDObject');
		}
		this._relationObject = relObj;
	}

	//
	// IPDComponent
	//

	reset(): void {
		let ctrl = this.control;
		if (ctrl && this.pdObject && this.propertyName) {
			ctrl.reset(this.pdObject.pdObjectRaw[this.propertyName] !== undefined ? this.pdObject.pdObjectRaw[this.propertyName] : this.defaultValue);
		}
	}

	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			if (this.initialized) {
				this.onPDObjectChanging();
			}
			this._pdObject = val;
			if (this.initialized) {
				this.updateRelationObject();
				this.onPDObjectChanged();
			}
		}
	}

	get pdObject(): PDObject {
		if (this.propertyRelationPath && !this._relationObject) {
			this.updateRelationObject();
		}
		return this.propertyRelationPath ? this._relationObject : this._pdObject;
	}

	protected get relationBaseObject(): PDObject | undefined {
		return this.propertyRelationPath ? this._pdObject : undefined;;
	}

	private _pdObject: PDObject;

	private _relationObject: PDObject;

	private _relationObjectFormGroup: UntypedFormGroup;

	protected get relationObjectFormGroup(): UntypedFormGroup | undefined {
		return this._relationObjectFormGroup
	}

	/*protected get relationSpec(): ITo1RelationItemSpec | undefined {
		return this.uiItemSpec instanceof PDItemSpec ? (this.uiItemSpec as PDItemSpec).to1RelationItemSpec : undefined;
	}*/

	protected abstract get propertyRelationPath(): string | undefined;

	protected getRelatedObject(): IPDObject | undefined {
		return this.pdObject;
	}

	abstract get propertyName(): string | undefined;

	abstract get control(): AbstractControl | undefined;

	get shortDescription(): string | undefined {
		return this._shortDescription;
	}

	private _shortDescription: string;

	private _hasShortDescription = false;

	get hasShortDescription(): boolean {
		return this._hasShortDescription;
		/*if (this.uiItemSpec?.shortDescriptionId && this._shortDescription === this.uiItemSpec.shortDescriptionId) {
			return false;
		}*/

		/*if (this._shortDescription) {
			return true;
		}
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.hasShortDescription(this.propertyName);
		}
		return false;*/
	}

	get label(): string {
		return this._label;
		//return this.getLabelImpl();
	}

	private _label: string = "n.a.";

	@Input()
	set customLabel(val: ErgNameData) {
		if (this._customLabel != val) {
			this._customLabel = val;
			this.updateLabel();
		}
	}

	get customLabel(): ErgNameData {
		return this._customLabel;
	}

	private _customLabel: ErgNameData;

	@Input()
	set customLabelId(val: string) {
		if (this._customLabelId != val) {
			this._customLabelId = val;
			this.updateLabel();
		}
	}

	get customLabelId(): string {
		return this._customLabelId;
	}

	private _customLabelId: string;

	/*get useCustomLabel(): boolean {
		return this._useCustomLabel;
	}

	set useCustomLabel(value: boolean) {
		this._useCustomLabel = value;
	}
	private _useCustomLabel = true;*/
}
