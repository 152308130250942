import { IEventProcessingContext } from "@otris/ng-core-types";
import { ASTToken } from "../../services/grammar-parser-factory.service";


export class CustomContextExprAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): CustomContextExprAction {
		if (astToken.type !== 'CustomContextExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new CustomContextExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): any {
		let customContextItemToken = this._astToken.children.find(t => t.type === 'CustomContextItem'); // todo Konstante
		let customContextItemQuotedToken = this._astToken.children.find(t => t.type === 'CustomContextItemQuoted'); // todo Konstante
		if (customContextItemToken) {
			let customContextItemAction = CustomContextItemAction.create(customContextItemToken, this._ctx);
			return customContextItemAction.evaluate();
		}
		if (customContextItemQuotedToken) {
			let customContextItemQuotedAction = CustomContextItemQuotedAction.create(customContextItemQuotedToken, this._ctx);
			return customContextItemQuotedAction.evaluate();
		}
		throw new Error('todo');
		//return this._ctx.customContext?.evaluateExpression(customContextItemToken.text) ?? undefined;
	}
}

export class CustomContextItemAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): CustomContextItemAction {
		if (astToken.type !== 'CustomContextItem') { // todo: Konstante
			throw new Error('todo');
		}
		return new CustomContextItemAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): any {
		return this._ctx.customContext?.evaluateExpression(this._astToken.text) ?? undefined;
	}
}

export class CustomContextItemQuotedAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): CustomContextItemQuotedAction {
		if (astToken.type !== 'CustomContextItemQuoted') { // todo: Konstante
			throw new Error('todo');
		}
		return new CustomContextItemQuotedAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(): any {
		let custExpr = this._astToken.text.substring(1, this._astToken.text.length - 1);
		return this._ctx.customContext?.evaluateExpression(custExpr) ?? undefined;
		/*let customContextItemToken = this._astToken.children.find(t => t.type === 'CustomContextItem'); // todo Konstante
		if (customContextItemToken) {
			let customContextItemAction = CustomContextItemAction.create(customContextItemToken, this._ctx);
			return customContextItemAction.evaluate();
		}
		throw new Error('todo');*/
		//return this._ctx.customContext?.evaluateExpression(this._astToken.text) ?? undefined;
	}
}
