import { Inject, Injectable } from "@angular/core";
import { IModuleStateServiceToken } from "@otris/ng-core-shared";
import { IBusyProvider, IModuleStateService } from "@otris/ng-core-types";
import { Observable, delay, finalize, of, switchMap, tap } from "rxjs";

@Injectable()
export class BusyStateService implements IBusyStateService, IBusyProvider {

	constructor(@Inject(IModuleStateServiceToken) private _moduleStateService: IModuleStateService) {
		this._moduleStateService.registerBusyProvider(this);
	}

	get busy(): boolean {
		return this.isBusy;
	}

	get isBusy(): boolean {
		return this._busyCounter > 0 || this._actions$.length > 0;
	}

	setBusy(): void {
		this._busyCounter++;
	}

	setIdle(): void {
		if (this._busyCounter == 0) {
			throw new Error('Busy state is already idle.');
		}
		this._busyCounter--;
	}

	suspendBusyState(): void {
		if (this.busyStateSuspended) {
			throw new Error('Busy state is already suspended.');
		}
		this._suspendedCounter = this._busyCounter;
		/*if (this._busyCounter == 0) {
			return;
		}*/
		//this._busyStateSuspended = true;
		//this._suspendedCounter = this._busyCounter;
		this._busyCounter = 0;
	}

	resumeBusyState(): void {
		if (!this.busyStateSuspended) {
			throw new Error('Busy state is not suspended.');
		}
		//this._busyStateSuspended = false;
		this._busyCounter = this._suspendedCounter;
		this._suspendedCounter = -1;
	}

	registerAction(action$: Observable<any>): Observable<any> {
		let adaptedAction$ = of(undefined).pipe(
			//delay(5000), // TEST
			tap(() => {
				this._actions$.push(adaptedAction$);
			}),
			switchMap(() => {
				return action$;
			}),
			finalize(() => {
				let pos = this._actions$.indexOf(adaptedAction$);
				this._actions$.splice(pos, 1);
			})
		);
		return adaptedAction$;
	}

	private get busyStateSuspended(): boolean {
		return this._suspendedCounter >= 0;
	}

	private _busyCounter = 0;

	private _suspendedCounter = -1;

	private _actions$: Observable<any>[] = [];
}

/**
 * setBusy() und setIdle() müssen immer paarweise aufgerufen werden.
 * Nach einem Aufruf von suspendBusyState() muss immer direkt ein Aufruf von resumeBusyState() erfolgen
 */
@Injectable({ providedIn: 'root', useClass: BusyStateService })
export abstract class IBusyStateService {
	abstract get isBusy(): boolean;

	abstract setBusy(): void;

	abstract setIdle(): void;

	abstract suspendBusyState(): void;

	abstract resumeBusyState(): void;

	abstract registerAction(action$: Observable<any>): Observable<any>;
}
