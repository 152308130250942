import { Inject, Injectable, InjectionToken, LOCALE_ID, inject } from "@angular/core";
import { IInteractionServiceToken, IModuleStateServiceToken, IPDAccessServiceToken, IPDClassToken, ServiceLocator } from "@otris/ng-core-shared";
import { DynamicLocalizationData, ErgName, ErgNameData, IAppModuleLocalizations, IEventProcessingContext, ILanguage, ILocalizationService, IModuleLocalizations, LanguageCodeET } from "@otris/ng-core-types";
import { BehaviorSubject, Observable, Subject, forkJoin, map, of, switchMap } from "rxjs";
import { FormExpressionGrammarProcessorToken } from "./form-expression-grammar-processor.service";
import { TranslateService } from "@ngx-translate/core";
import { IAsyncActionManagerServiceToken } from "./async-action-manager.service";

export const LocalizationServiceToken = new InjectionToken<LocalizationService>(
	'LocalizationServiceToken',
	{
		providedIn: 'root',
		factory: () => new LocalizationServiceImpl(inject(TranslateService), inject(LOCALE_ID))
	}
);

@Injectable()
export abstract class LocalizationService implements ILocalizationService {
	abstract allLanguages: ILanguage[];
	abstract activatedLanguages: ILanguage[];
	abstract currentLanguage: ILanguage | undefined;
	abstract currentLanguageIndex: number;
	abstract changeHandler: Observable<ILanguage>;
	abstract loadDynamicLocalizations(
		dataProvider: (param: object) => Observable<DynamicLocalizationData[] | undefined>,
		dataProviderParam: object
	): Observable<boolean>;
	abstract setCurrentLanguage(lang: LanguageCodeET): void;
	abstract setActivatedLanguages(langs: LanguageCodeET[], defaultLang: LanguageCodeET): void;
	abstract getEnumLiteralErgName(enumName: string, literal: number | string): Observable<string>;
	abstract getPropertyLabel(cls: string, prop: string): Observable<string>;
	abstract getPropertyShortDescription(cls: string, prop: string): Observable<string>;
	abstract getPropertyShortDescriptionId(cls: string, prop: string): string;

	/**
	 * Liefert die Übersetzung von Klassen-Strings aus - Schema: "app.class.${cls}.strings.${id}"
	 *
	 * @param cls Klasse - Beispiel: "VerfahrenGemeldet", "AVContainer"
	 * @param id String id
	 */
	abstract getClassString(cls: string, id: string, lang?: LanguageCodeET): Observable<string>;

		/**
	 * Liefert die Übersetzung von Klassen-Strings aus - Schema: "app.class.${cls}.strings.${id}"
	 * und reagiert auf Sprachänderungen.
	 *
	 * @param cls Klasse - Beispiel: "VerfahrenGemeldet", "AVContainer"
	 * @param id String id
	 */
	abstract getClassStringWithUpdate(cls: string, id: string): Observable<string>;

	abstract getClassErgName(cls: string): Observable<string>;

	/**
	 * Liefert die Übersetzung von einem system string (ng-core, privacy-ng-lib Ebene).
	 *
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemString(id: string, paramsObj?: Object): Observable<string>;

	/**
	 * Liefert die Übersetzung von einem system string (ng-core, privacy-ng-lib Ebene).
	 * Reagiert zusätzlich auf Sprachänderung.
	 *
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemStringWithUpdates(ids: string, paramsObjs?: Object): Observable<string>;

	/**
	 * Liefert die Übersetzungen von system strings (ng-core, privacy-ng-lib Ebene).
	 * Wobei die Reihenfolge der jeweiligen Argumenten passen müssen.
	 * (paramsObj[2] für ids[2] etc.)
	 *
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]>;

	abstract getString(id: string, paramsObj?: Object): Observable<string>;

	/**
	 * Liefert die Übersetzung von strings mit optionaler paramsObj.
	 * Wobei die Reihenfolge der jeweiligen Argumenten passen müssen.
	 * (paramsObj[2] für ids[2] etc.)
	 *
	 * @param ids Array mit vollständigen i18n Pfad
	 * @param paramsObjs Array mit Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]>;

	/**
	 * Liefert wie gewöhnlich ein übersetzen String. Allerdings reagiert dieser
	 * auch auf Sprachänderungen, mithilfe der changeHandler Funktion.
	 * FIXME: id param kann ja nur zu falsch-benutzung führen
	 *
	 * @param id Vollständigen i18n Pfad OHNE '.strings.'
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringWithUpdates(id: string, paramsObjs?: Object): Observable<string>;

	abstract get activatedLanguagesChanged$(): Observable<LanguageCodeET[]>;

	abstract set appModuleLocalizations(value: IAppModuleLocalizations);

	/**
	 * Liefert die Übersetzung eines string mit optionaler paramsObj.
	 *
	 * @param id Vollständiger i18n Pfad
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringFromId(id: string): Observable<string>;

		/**
	 * Liefert die Übersetzung eines string mit optionaler paramsObj. Allerdings
	 * reagiert dieser auch auf Sprachänderungen, mithilfe der changeHandler Funktion.
	 *
	 * @param id Vollständiger i18n Pfad
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringFromIdWithUpdates(id: string): Observable<string>;

	abstract getFromErgNameData(ergName: ErgNameData): string;

	abstract getFromErgName(ergName: ErgName): string;
}

@Injectable()
export class LocalizationServiceImpl implements LocalizationService {

	private _changeHandler: BehaviorSubject<ILanguage> = new BehaviorSubject<ILanguage>(undefined);

	private _allLanguages: Map<LanguageCodeET, ILanguage> = new Map<LanguageCodeET, ILanguage>();

	private _activatedLanguages: LanguageCodeET[] = null;

	private _dynamicLocalizations: Map<string, DynamicLocalizationData>;

	get allLanguages(): ILanguage[] {
		return Array.from(this._allLanguages.values());
	}

	getLanguage(lang: LanguageCodeET): ILanguage {
		return this._allLanguages.get(lang);
	}

	get activatedLanguages(): ILanguage[] {
		if (this._activatedLanguages !== null) {
			return [...this._allLanguages.values()].filter(l => this._activatedLanguages.includes(l.code));
		}
		return [];
	}

	private _currentLanguage: LanguageCodeET;
	get currentLanguage(): ILanguage | undefined {
		return this._allLanguages.get(this._currentLanguage);
	}

	get changeHandler(): Observable<ILanguage> {
		return this._changeHandler.asObservable();
	}

	get currentLanguageIndex(): number {
		switch (this.currentLanguage?.code) {
			case LanguageCodeET.de:
				return 0;
			case LanguageCodeET.en:
				return 1;
			default:
				throw new Error('Unknown currentLanguageIndex.');
		}
	}

	private _activatedLanguagesChanged$: Observable<LanguageCodeET[]>;

	private _activatedLanguagesChangedSubject$ = new Subject<LanguageCodeET[]>();

	private _appModuleLocalizations: IAppModuleLocalizations;

	private _formExpressionGrammarProcessor = inject(FormExpressionGrammarProcessorToken);

	//private _asyncActionManagerService = inject(IAsyncActionManagerServiceToken);

	constructor(
		private translate: TranslateService,
		@Inject(LOCALE_ID) public localeId: string
	) {
		this._allLanguages.set(LanguageCodeET.de, <ILanguage>{ code: LanguageCodeET.de, shortName: 'de' });
		this._allLanguages.set(LanguageCodeET.en, <ILanguage>{ code: LanguageCodeET.en, shortName: 'en' });
		//this._allLanguages.set(LanguageCodeET.fr, <ILanguage>{ code: LanguageCodeET.fr, shortName: 'fr' });

		let defaultLang = LanguageCodeET.en;
		let browserLang = navigator.language; // "de" "de-at" ... möglich
		if (browserLang) {

			// Wenn Bindestrich nur vorderen Teil nehmen
			if (browserLang.includes("-")) {
				let pos = browserLang.indexOf('-');
				if (pos > 0) {
					browserLang = browserLang.substr(0, pos);
				}
			}

			let lang = Array.from(this._allLanguages.values()).find(l => l.shortName === browserLang);
			if (lang) {
				defaultLang = lang.code
			}
		}
		this.setActivatedLanguages(Array.from(this._allLanguages.keys()), defaultLang);
	}

	loadDynamicLocalizations(
		dataProvider: (param: object) => Observable<DynamicLocalizationData[] | undefined>,
		dataProviderParam: object
	): Observable<boolean> {
		if (this._dynamicLocalizations) {
			return of(false);
		}
		this._dynamicLocalizations = new Map();
		return dataProvider(dataProviderParam).pipe(
			map(res => {
				if (!res) {
					return false;
				}
				res.reduce((p, c) => {
					p.set(c.id, c);
					return p;
				}, this._dynamicLocalizations);
				return true;
			})
		);
	}

	/**
	 * Bitte Unterschied zwischen setActivatedLanguages und setCurrentLanguage beschreiben
	 */
	setActivatedLanguages(langs: LanguageCodeET[], defaultLang: LanguageCodeET): void {
		if (langs.length == 0) {
			throw new Error('Error in LocalizationService.setActivatedLanguages(). Details: No languages specified.');
		}
		if (defaultLang) {
			if (!langs.includes(defaultLang)) {
				defaultLang = langs[0];
			}
		} else {
			defaultLang = this.currentLanguage ? this.currentLanguage.code : langs[0];
		}
		this._activatedLanguages = [];
		for (let lang of langs) {
			if (!this._allLanguages.has(lang)) {
				throw new Error('Error in LocalizationService.setActivatedLanguages(). Details: Unknown language.');
			}
			this._activatedLanguages.push(lang);
		}

		let langForDefault = this.activatedLanguages.find(l => l.code === defaultLang);
		// Fallback auf Deutsch, wenn gewünschte Sprache nicht aktiv ist.
		if (!langForDefault) {
			defaultLang = LanguageCodeET.de;
		}
		this.translate.setDefaultLang(defaultLang);
		let newLangs = [];
		this.activatedLanguages.filter(l => l.code !== defaultLang).map(l => l.shortName).forEach(l => {
			if (!this.translate.langs.includes(l)) {
				newLangs.push(l);
			}
		});
		if (newLangs.length > 0) {
			this.translate.addLangs(newLangs);
		}
		this.setCurrentLanguage(defaultLang);
		this._activatedLanguagesChangedSubject$.next(this._activatedLanguages);
	}

	setCurrentLanguage(langCode: LanguageCodeET): void {
		if (langCode != this._currentLanguage) {
			if (this._activatedLanguages.includes(langCode)) {
				let lang = this._allLanguages.get(langCode);
				this._currentLanguage = langCode;
				this.localeId = lang.shortName;
				this.translate.use(lang.shortName).subscribe(
					() => {
						this._changeHandler.next(lang);
					}
				);
			}
			else {
				throw new Error('Error in LocalizationService.setCurrentLanguage(). Details: Invalid language code.');
			}
		}
	}

	getPropertyLabel(cls: string, prop: string): Observable<string> {
		if (!cls || !prop) {
			return of('');
		}
		//return this.translate.get(`app.class.${cls}.properties.${prop}.label`).map(res => <string>res);
		return this.getTranslation(`app.class.${cls}.properties.${prop}.label`);
	}

	getPropertyShortDescription(cls: string, prop: string): Observable<string> {
		if (!cls || !prop) {
			return of('');
		}
		return this.getTranslation(this.getPropertyShortDescriptionId(cls, prop));
	}

	getPropertyShortDescriptionId(cls: string, prop: string): string {
		return `app.class.${cls}.properties.${prop}.short-description`;
	}

	getEnumLiteralErgName(enumName: string, literal: number | string): Observable<string> {
		if (!enumName) {
			return of('');
		}
		let path = 'app.enum.' + enumName + '.' + literal.toString();
		//return this.translate.get(path).map(res => <string>res);
		return this.getTranslation(path);
	}

	getClassString(cls: string, id: string, lang?: LanguageCodeET): Observable<string> {
		if (!cls || !id) {
			return of('');
		}
		//return this.translate.get(`app.class.${cls}.strings.${id}`).map(res => <string>res);
		return this.getTranslation(`app.class.${cls}.strings.${id}`, lang);
	}

	getClassStringWithUpdate(cls: string, id: string): Observable<string> {
		if (!cls || !id) {
			return of('');
		}
		return this.changeHandler.pipe(
			switchMap(() => this.getTranslation(`app.class.${cls}.strings.${id}`))
		)
	}

	getClassErgName(cls: string): Observable<string> {
		if (!cls) {
			return of('');
		}
		return this.getTranslation(`app.class.${cls}.title`).pipe(map(res => <string>res));
	}

	getSystemStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]> {
		let observableBatch: Observable<string>[] = [];

		for (let i = 0; i < ids.length; i++) {
			if (paramsObjs && paramsObjs[i]) {
				//observableBatch.push(this.translate.get(`system.${ids[i]}`, paramsObjs[i]));
				observableBatch.push(this.getTranslation(`system.${ids[i]}`, paramsObjs[i]));
			} else {
				observableBatch.push(this.getTranslation(`system.${ids[i]}`));
			}
		}

		return forkJoin(
			observableBatch
		)
	}

	getSystemString(id: string, paramsObj?: Object): Observable<string> {
		if (!id) {
			return of('');
		}
		let path = 'system.' + id;
		//return this.translate.get(path, paramsObj).map(res => <string>res);
		return this.getTranslation(path, paramsObj);
	}

	getSystemStringWithUpdates(id: string, paramsObjs?: Object): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getSystemString(id, paramsObjs))
		);
	}

	getString(id: string, paramsObj?: Object): Observable<string> {
		if (!id) {
			return of('');
		}
		if (id.startsWith('system.')) {
			return this.getSystemString(id.substr(7), paramsObj);
		}
		if (id.startsWith('app.')) {
			let idRest = id.substr(4);
			if (idRest.startsWith('class.')) {
				let pos = idRest.indexOf('.', 6);
				if (pos > 0) {
					let cls = idRest.substring(6, pos);
					pos = idRest.indexOf('.strings.');
					if (pos > 0) {
						let str = idRest.substr(pos + 9);
						return this.getClassString(cls, str);
						//return this.getClassString(idRest.substring(6, pos), idRest.substr(pos + 1));
					}
				}
			}
			else if (idRest.startsWith('strings.')) {
				return this.getTranslation(id, paramsObj);
				//return this.translate.get(id, paramsObj).map(res => <string>res);
			}
		}
		return this.getTranslation(id, paramsObj);
		//return of(id);
	}

	getStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]> {

		let observableBatch: Observable<string>[] = [];

		for(let i = 0; i < ids.length; i++) {
			if (paramsObjs && paramsObjs[i]) {
				observableBatch.push(this.getTranslation(ids[i], paramsObjs[i]));
			} else {
				observableBatch.push(this.getTranslation(ids[i]));
			}
		}

		return forkJoin(
			observableBatch
		)
	}

	getStringWithUpdates(id: string, paramsObjs?: Object): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getString(id, paramsObjs))
		);
	}

	getStringFromId(id: string, paramsObjs?: Object[]): Observable<string> {
		return this.getTranslation(id, paramsObjs);
	}

	getStringFromIdWithUpdates(id: string, paramsObjs?: Object[]): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getTranslation(id, paramsObjs))
		);
	}

	getFromErgNameData(ergName: ErgNameData): string {
		switch (this.currentLanguage.code) {
			case LanguageCodeET.de:
				return ergName.de;
			case LanguageCodeET.en:
				return ergName.en;
		}
	}

	getFromErgName(ergName: ErgName): string {
		return ergName.find(n => n[0] == this.currentLanguage.code)[1]
	}

	get activatedLanguagesChanged$(): Observable<LanguageCodeET[]> {
		if (!this._activatedLanguagesChanged$) {
			this._activatedLanguagesChanged$ = this._activatedLanguagesChangedSubject$.asObservable();
		}
		return this._activatedLanguagesChanged$;
	}

	set appModuleLocalizations(appModuleLocs: IAppModuleLocalizations) {
		this._appModuleLocalizations = appModuleLocs;
		this._changeHandler.next(this.currentLanguage);
	}

	private getTranslation(id: string, paramsObj?: Object, lang?: LanguageCodeET): Observable<string> {
		let integratePramsObj = (trans: string) => {
			if (paramsObj) {
				Object.keys(paramsObj).forEach(k => {
					let regExp = new RegExp(`{{${k}}}`, 'g');
					trans = trans.replace(regExp, paramsObj[k]);
				});
			}
			return trans;
		};

		if (this._dynamicLocalizations?.has(id)) {
			let ctx: IEventProcessingContext = {
				relatedObject: undefined,
				formHandler: undefined,
				pdAccessService: ServiceLocator.injector.get(IPDAccessServiceToken),
				interactionService: ServiceLocator.injector.get(IInteractionServiceToken),
				pdClass: ServiceLocator.injector.get(IPDClassToken),
				localizationService: this,
				moduleStateService: ServiceLocator.injector.get(IModuleStateServiceToken),
				formExpressionGrammarProcessor: this._formExpressionGrammarProcessor
				//asyncActionManagerService: this._asyncActionManagerService
			}
			for (let dynTrans of this._dynamicLocalizations.get(id).translations) {
				if (this._formExpressionGrammarProcessor.evaluateConditionExpression(dynTrans.condition, ctx)) {
					return of(integratePramsObj(this.getFromErgNameData(dynTrans.translation)));
				}
			}
		}

		const translate = (translations?: ErgName) => {
			if (translations) {
				const trans = translations.find(t => t[0] === (lang ? this.getLanguage(lang) : this.currentLanguage.shortName));
				if (trans) {
					/*let returnTrans = trans[1];
					if (paramsObj) {
						Object.keys(paramsObj).forEach(k => {
							let regExp = new RegExp(`{{${k}}}`, 'g');
							returnTrans = returnTrans.replace(regExp, paramsObj[k]);
						});
					}
					return of(returnTrans);*/
					return of(integratePramsObj(trans[1]));
				}
			}
			return this.translate.get(id, paramsObj).pipe(map(res => <string>res));
		};

		const translateModuleItem = (systemModuleLoc: IModuleLocalizations, parts: string[]) => {
			if (parts.length >= 3) {
				let category = parts[0];

				// Submodul
				if (category !== 'components' && category !== 'services') {
					if (systemModuleLoc.subModules) {
						let subModuleLoc = systemModuleLoc.subModules.find(m => m.module === category);
						if (subModuleLoc) {
							return translateModuleItem(subModuleLoc, parts.slice(1));
						}
					}
					return translate();
				}

				let partName = parts[1];
				let itemName = parts.slice(2).reduce((res, cur) => {
					if (res.length > 0) {
						res += '.';
					}
					res += cur;
					return res;
				}, '');
				switch (category) {
					case 'components':
						if (systemModuleLoc.components) {
							let comp = systemModuleLoc.components.find(c => c.component === partName);
							if (comp && comp.items) {
								let item = comp.items.find(i => i.id === itemName)
								return translate(item ? item.translations : undefined);
							}
						}
						break;

					case 'services':
						if (systemModuleLoc.services) {
							let service = systemModuleLoc.services.find(item => item.service === partName);
							if (service && service.items) {
								let item = service.items.find(i => i.id === itemName)
								return translate(item ? item.translations : undefined);
							}
						}
						break;
				}
			}
			return translate();
		};

		if (this._appModuleLocalizations) {
			const parts = id.split('.');
			if (parts.length > 0) {
				switch (parts[0]) {
					case 'system':
						if (parts.length >= 5 && this._appModuleLocalizations.systemModuleLocs) {
							const module = parts[1];
							const systemModuleLoc: IModuleLocalizations | undefined = this._appModuleLocalizations.systemModuleLocs.find(m => m.module === module);
							if (systemModuleLoc) {
								return translateModuleItem(systemModuleLoc, parts.slice(2));
							}
						}
						break;

					case 'app':
						if (this._appModuleLocalizations.appLocs && parts.length >= 3) {
							switch (parts[1]) {
								case 'class':
									{
										const classes = this._appModuleLocalizations.appLocs.classes;
										const classLoc = classes ? classes.find(c => c.name === parts[2]) : undefined;
										if (classLoc && parts.length >= 4) {
											switch (parts[3]) {
												case 'title':
													return translate(classLoc.title);

												case 'properties':
													if (classLoc.properties  && parts.length >= 6) {
														const prop = classLoc.properties.find(p => p.name === parts[4]);
														if (prop && prop.locs) {
															switch (parts[5]) {
																case 'label':
																	return translate(prop.locs.label);

																case 'short-description':
																	return translate(prop.locs.shortDescription);
															}
														}
													}
													break;

												case 'strings':
													if (classLoc.strings && parts.length >= 5) {
														const str = classLoc.strings.find(s => s.id === parts[4]);
														return translate(str ? str.translations : undefined);
													}
													break;

											}
										}
										break;
									}

								case 'enum':
									{
										const enumLocs = this._appModuleLocalizations.appLocs.enums;
										if (enumLocs && parts.length >= 4) {
											const enumLoc = enumLocs.find(e => e.name === parts[2]);
											const locs = (enumLoc && enumLoc.locs) ? enumLoc.locs.find(l => l.id === parts[3]) : undefined;
											return translate(locs ? locs.translations : undefined);
										}
										break;
									}

								case 'strings':
									{
										const strings = this._appModuleLocalizations.appLocs.strings;
										if (strings && parts.length >= 5) {
											const str = strings.find(s => s.component === parts[2]);
											const item = (str && str.items) ? str.items.find(s => s.id === parts[3]) : undefined;
											if (item && item.locs) {
												switch (parts[4]) {
													case 'label':
														return translate(item.locs.label);
													case 'short-description':
														return translate(item.locs.shortDescription);
												}
											}
										}
										break;
									}

								case 'module':
								{
									const module = parts[2];
									const moduleLoc: IModuleLocalizations | undefined = this._appModuleLocalizations.appLocs?.module?.find(m => m.module === module);
									if (moduleLoc) {
										return translateModuleItem(moduleLoc, parts.slice(3));
									}
									break;
								}
							}
						}
				}
			}
		}

		return translate();
	}
}
