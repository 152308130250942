import { Pipe, PipeTransform } from '@angular/core';
import { TypeET } from '@otris/ng-core-types';
@Pipe({
	name: 'typeET_to_kendoGridFilter'
})
export class TypeETToKendoGridFilterPipe implements PipeTransform {
	transform(value: TypeET): "text" | "numeric" | "boolean" | "date" {
		if (!value) {
			return undefined;
		}
		switch (value) {
			case TypeET.Boolean:
				return "boolean";
			case TypeET.Date:
			case TypeET.DateTime:
			case TypeET.Time:
				return "date";
			case TypeET.Integer:
			//case TypeET.Float:
				return "numeric";
			case TypeET.Boolean:
				return "boolean";
			default:
				return "text";
		}
	}
}
