import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ErgName, ILocalizationService, LanguageCodeET, TypeET } from '@otris/ng-core-types';
import { LocalizationServiceToken } from '../services/localization.service';
@Pipe({
	name: 'ergName_to_LocalizedString'
})
export class ErgNameToLocalizedStringPipe implements PipeTransform {

	constructor(@Inject(LocalizationServiceToken) private _localizationService: ILocalizationService) {}

	transform(value: ErgName): string {
		if (value) {
			let ergName = value.find(en => en[0] == this._localizationService.currentLanguage.code);
			if (ergName) {
				return ergName[1];
			}
		}
		return undefined
	}
}
