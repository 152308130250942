import { InjectionToken, Injectable, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IModuleStateServiceToken } from "@otris/ng-core-shared";
import { IAsyncActionManagerService, IBusyProvider, IModuleStateService } from "@otris/ng-core-types";

export const IAsyncActionManagerServiceToken = new InjectionToken<IAsyncActionManagerService>(
	'IAsyncActionManagerToken'/*,
	{
		providedIn: 'root',
		factory: () => new AsyncActionManagerService()
	}*/
);

@Injectable()
export class AsyncActionManagerService implements IAsyncActionManagerService, IBusyProvider, OnDestroy {
	private _actions: Observable<any>[] = [];

	private _rawActions: Observable<any>[] = [];

	private _namedRawActions: Map<string, Observable<any>> = new Map();

	private _actionFinishedSubject$: Subject<any> = new Subject();

	private _actionFinished$: Observable<any>;

	private _monitoringEnabled = false;

	get monitoringEnabled(): boolean {
		return this._monitoringEnabled;
	}

	set monitoringEnabled(flag: boolean) {
		this._monitoringEnabled = flag;
	}

	constructor(
		@Inject(IModuleStateServiceToken) private _moduleStateService: IModuleStateService,
	) {
		this._moduleStateService.registerBusyProvider(this);
	}

	ngOnDestroy() {
		this._moduleStateService.unregisterBusyProvider(this);
	}

	notifyActionStarted(action: Observable<any>, actionName?: string): void {
		if (!this._rawActions.includes(action)) {
			this._rawActions.push(action);
		}
		if (actionName) {
			if (this._namedRawActions.has(actionName)) {
				console.warn(`Raw action with name ${actionName} already registered!`)
			}
			else {
				this._namedRawActions.set(actionName, action);
			}
		}
		if (!this._monitoringEnabled) {
			return;
		}
		if (this._actions.includes(action)) {
			throw new Error('AsyncActionManagerService.notifyActionFinished(): action already registered');
		}

		this._actions.push(action);
	}

	notifyActionFinished(action: Observable<any>, actionName?: string): void {
		//if (!this._monitoringEnabled) {
		//	return;
		//}
		let indexRaw = this._rawActions.indexOf(action);
		if (indexRaw >= 0) {
			this._rawActions.splice(indexRaw, 1);
		}
		if (actionName) {
			if (!this._namedRawActions.has(actionName)) {
				console.warn(`Raw action with name ${actionName} not registered.`);
			}
			else {
				this._namedRawActions.delete(actionName);
			}
		}

		let index = this._actions.indexOf(action);
		if (index == -1) {
			//throw new Error('AsyncActionManagerService.notifyActionFinished(): action not found');
			return;
		}
		this._actions.splice(index, 1);
		this._actionFinishedSubject$.next(action);
	}

	hasPendingAction(action: string): boolean {
		return this._namedRawActions.has(action);
	}

	get hasActionsInProgress(): boolean {
		return this._actions.length > 0;
	}

	get hasRawActions(): boolean {
		return this._rawActions.length > 0;
	}

	get actionsInProgress(): Observable<any>[] {
		return [...this._actions];
	}

	get actionFinished$(): Observable<Observable<any>> {
		if (!this._actionFinished$) {
			this._actionFinished$ = this._actionFinishedSubject$.asObservable();
		}
		return this._actionFinished$;
	}

	get busy(): boolean {
		return this.hasRawActions;
	}
}
