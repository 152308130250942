import { Component, Input, Output, EventEmitter, ViewChildren, ChangeDetectorRef, Inject, QueryList, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { forkJoin, Subject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RelationTabWidgetInfo, PDRelationTabSpec, FormHandlerService, EventProcessingContextManagerService, PDLikeComponent, LocalizationServiceToken } from '@otris/ng-core';
import {
	ComponentTypeET, IToolBarItemSpec, IToolBarButtonSpec,
	ToolBarItemTypeET, IInteractionService, IToolBarItemResult, DialogResultET, IRelationContext,
	IComponent, ICssStyle, IFormStatus, IPDRelationTabComponent, CreateRelationObjectCallback,
	ILocalizationService, IPDClass, IPanelComponent, IComponentUIState, IPDComponent
} from '@otris/ng-core-types';
import {
	PDObject,
	IInteractionServiceToken,
	CustomPDObject,
	IPDClassToken,
} from '@otris/ng-core-shared'

// Das darf man wegen Rekursion nicht importieren!
//import { PDPanelComponent } from '../pd-panel/pd-panel.component';

enum ToolBarButtonIdET {
	New = 'idNew',
	Delete = 'idDelete'
}

interface IRelationObjectSpec {
	id: string;
	obj: PDObject;
	formGroup: UntypedFormGroup;
}

@Component({
	selector: 'otris-pd-relation-tab',
	template: `
		<div class="root-container">
			<div class="header">
				@for (relObj of relationObjects; track relObj; let i = $index) {
  				<div [ngClass]="{'otris-pd-relation-tab-selected-tab-item': relObj===selectedObject,
						'otris-pd-relation-tab-unselected-tab-item': relObj!==selectedObject,
						'tab-item': !isDisabled, 'tab-item-disabled': isDisabled}"
				 		(click)="onTabItemClick(relObj)"
					>
						<div class="tab-item-text" kendoTooltip filter="span" [showOn]="(hasTabHeaderShortInfo(relObj, i) | async) ? 'hover' : 'none'">
							<span [title]="getTabHeaderShortInfo(relObj, i) | async">{{getTabHeaderText(relObj, i) | async}}</span>
						</div>
					</div>
				}
				<div style="margin-left: 0.5em;"></div>
				@if (!isDisabled && hasShortDescription) {
					<otris-pd-short-description
						[highlighted]="mouseIsOver"
						[pdObject]="pdObject"
						[pdComponent]="this"
					/>
				}
				@if (!isDisabled && relationFormArray && relationFormArray.errors) {
					<otris-pd-error-indicator
						[relatedFormControl]="relationFormArray"
						[propertyName]="propertyName"
						[pdObject]="pdObject"
					/>
				}
				<otris-tool-bar 
					class="toolbar"
					(buttonClick)="onToolBarButtonClick($event)"
					[itemSpecs]="toolbarItems"
				/>
			</div>
			@if (pdRelationTabSpec.content) {
				<div class="panel-container otris-pd-relation-tab-panel-container" [ngStyle]="panelContainerStyle">
					@for (relObjSpec of relationObjectSpecs; track relObjSpec) {
  					<otris-pd-panel #panel
							[ngClass]="{'panel-hidden': relObjSpec.obj!==selectedObject}"
							class="panel"
							[uiItemSpec]="pdRelationTabSpec.content"
							[formGroup]="relObjSpec.formGroup"
							[pdObject]="relObjSpec.obj"
							[relationContext]="getRelationContext(relObjSpec)"
							[idPostfix]="'relationTab.' + relObjSpec.id"
						/>
					}
					@if (relationObjectSpecs.length == 0) {
						<div class="no-tabs-message">
							<span>{{noTabsMessage | async}}</span>
						</div>
					}
				</div>
			}
		</div>
	`,
	styles: [`
		:host {
			overflow-y: hidden; /* Chrome bugfix */
		}

		.root-container {
			display: flex;
			flex-direction: column;

			height: 100%;
		}
		.header {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			/*overflow: auto;*/
			border-bottom: 1px solid darkgrey;
		}
		.tab-item {
			cursor: pointer;
		}
		.tab-item:hover {
			background-color: gainsboro;
		}
		.tab-item-disabled {
			color: grey;
		}
		.tab-item-text {
			margin: 0.5em 1em;
			user-select: none;
		}
		/*.selected-tab-item {
			border-bottom: 0.25em solid #ff6358;
		}*/
		.toolbar {
			margin: 0 0 0 auto;
		}
		.panel-container {
			flex: 1 1 auto;
			/* display: grid; IE11 Fix and center fix */
			display: flex;
			justify-content: center;
			align-items: center;

			margin-top: 0.5em;
			padding: 0.5em;
		}
		.panel {
			/*grid-row: 1;
			grid-column: 1;*/
			flex: 1;
			align-self: stretch;
		}
		.panel-hidden {
			display: none;
		}
		.no-tabs-message {
			/* margin: auto; IE11 Fix and center fix */
			color: darkgrey;
			font-size: 2em;
		}
	`]
})
export class PDRelationTabComponent extends PDLikeComponent implements IPDRelationTabComponent {

	/*private _initialized = false;

	get initialized(): boolean {
		return this._initialized;
	}

	set initialized(val: boolean) {
		this._initialized = val;
	}*/

	@Input() objCreator: new () => PDObject;

	@Input()
	set maxTabCount(count: number) {
		if (Number.isInteger(count) && count > 0) {
			this._maxTabCount = count;
		}
	}

	get maxTabCount(): number {
		return this._maxTabCount;
	}

	private _maxTabCount: number = 10;

	@Input()
	set minTabCount(count: number) {
		if (Number.isInteger(count) && count >= 0) {
			this._minTabCount = count;
		}
	}

	get minTabCount(): number {
		return this._minTabCount;
	}

	private _minTabCount: number = 0;

	@Output() relationObjectCreated = new EventEmitter<string>();

	@Output() relationObjectDeleting = new EventEmitter<PDObject>();

	@Output() relationObjectDeleted = new EventEmitter();

	@Output() selectedItemChanged = new EventEmitter<string>();

	@ViewChildren('panel') panelComponents: QueryList<IPanelComponent>; // todo: IPDPanelComponent

	get panelComponent(): IPanelComponent {
		return this._panelComponent;
	}

	private _panelComponent: IPanelComponent;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	mouseIsOver: boolean = false;

	toolbarItems: IToolBarItemSpec[];

	private _relationObjectCreatedSubject$: Subject<string> = new Subject();

	private _relationObjectCreated$: Observable<string>;

	get relationObjectCreated$(): Observable<string> {
		if (!this._relationObjectCreated$) {
			this._relationObjectCreated$ = this._relationObjectCreatedSubject$.asObservable();
		}
		return this._relationObjectCreated$;
	}

	private _relationObjectDeletingSubject$: Subject<PDObject> = new Subject();

	private _relationObjectDeleting$: Observable<PDObject>;

	get relationObjectDeleting$(): Observable<PDObject> {
		if (!this._relationObjectDeleting$) {
			this._relationObjectDeleting$ = this._relationObjectDeletingSubject$.asObservable();
		}
		return this._relationObjectDeleting$;
	}

	private _relationObjectDeletedSubject$: Subject<void> = new Subject();

	private _relationObjectDeleted$: Observable<void>;

	get relationObjectDeleted$(): Observable<void> {
		if (!this._relationObjectDeleted$) {
			this._relationObjectDeleted$ = this._relationObjectDeletedSubject$.asObservable();
		}
		return this._relationObjectDeleted$;
	}

	private _selectedItemChangedSubject$: Subject<string> = new Subject();

	private _selectedItemChanged$: Observable<string>;

	get selectedItemChanged$(): Observable<string> {
		if (!this._selectedItemChanged$) {
			this._selectedItemChanged$ = this._selectedItemChangedSubject$.asObservable();
		}
		return this._selectedItemChanged$;
	}

	private _relationFormArray: UntypedFormArray;

	get relationFormArray(): UntypedFormArray {
		return this._relationFormArray;
	}

	get relationObjectSpecs(): IRelationObjectSpec[] {
		return this._relationObjectSpecs;
	}

	private _relationObjectSpecs: IRelationObjectSpec[] = [];

	//private _relationObjects: PDObject[] = [];

	/*get relationObjects(): PDObject[] {
		return this._relationObjects;
	}*/

	get relationObjects(): PDObject[] {
		return this.pdObject ? this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property] : [];
	}

	private _selectedObject: PDObject;

	get selectedObject(): PDObject {
		return this._selectedObject;
	}

	private setSelectedObject(obj: PDObject) {
		if (this._selectedObject !== obj) {
			if (!obj) {
				this._selectedObject = undefined;
				this.updateToolbar();
			}
			else {
				let spec = this._relationObjectSpecs.find(s => s.obj === obj);
				if (!spec) {
					return;
				}
				this._selectedObject = obj;
				this.updateToolbar();

				// für alle Panels ausser dem selektierten systemVisibel = false
				//this.panelComponents.forEach(pc => pc.systemVisible = pc.pdObject === obj);
				this.changeDetector.detectChanges();
				this.selectedItemChanged.emit(spec.id);
				this._selectedItemChangedSubject$.next(spec.id);
				this.updateUIState(false);
			}
		}
	}

	get relationTabWidgetInfo(): RelationTabWidgetInfo {
		return this.widgetInfo instanceof RelationTabWidgetInfo ? <RelationTabWidgetInfo>this.widgetInfo : undefined;
	}

	get pdRelationTabSpec(): PDRelationTabSpec {
		return this.uiItemSpec instanceof PDRelationTabSpec ? <PDRelationTabSpec>this.uiItemSpec : undefined;
	}

	set createRelationObjectCallback(cb: CreateRelationObjectCallback) {
		this._createRelationObjectCallback = cb;
	}

	private _createRelationObjectCallback: CreateRelationObjectCallback;

	get panelContainerStyle(): ICssStyle | undefined {
		let wi = this.relationTabWidgetInfo;
		return wi ? wi.tabPanelStyle : undefined;
	}

	/*get shortDescription(): string | undefined {
		return this._shortDescription;
	}

	private _shortDescription: string;

	get hasShortDescription(): boolean {
		if (this.pdObject && this.pdRelationTabSpec) {
			return this.pdObject.metaData.hasShortDescription(this.pdRelationTabSpec.property);
		}
		return false;
	}*/

	/*get mandatoryIndicatorVisible(): boolean {
		return this.isMandatory && !this.isDisabled && !this.isReadonly && !this.getFormControlStatus().valid;
	}*/

	/*get hasShortDescriptionAsync(): Observable<boolean> {
		return this.get
	}*/

	noTabsMessage = this.localizationService.getStringWithUpdates('system.kendo-ui.components.pd-relation-tab.no-tab-message');

	private get canCreateTab(): boolean {
		let wi = this.relationTabWidgetInfo;
		return !wi || wi.canCreateTab;
	}

	private get canDeleteTab(): boolean {
		let wi = this.relationTabWidgetInfo;
		return !wi || wi.canDeleteTab;
	}

	get isContainerComponent(): boolean {
		return true;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private changeDetector: ChangeDetectorRef,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService,
		@Inject(IPDClassToken) private pdClass: IPDClass,
		eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		//this.initialized = true;
		this.toolbarItems = [];
		if (this.canCreateTab) {
			this.toolbarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-plus',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-new'
				}
			);
		}
		if (this.canDeleteTab) {
			this.toolbarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.Delete, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-times',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-delete'
				}
			);
		}

		let wi = this.relationTabWidgetInfo;
		if (wi) {
			if (this.relationTabWidgetInfo.maxTabCount !== undefined) {
				this.maxTabCount = this.relationTabWidgetInfo.maxTabCount;
			}
			if (this.relationTabWidgetInfo.minTabCount !== undefined) {
				this.minTabCount = this.relationTabWidgetInfo.minTabCount;
			}
			if (wi.createRelationObjectCallback) {
				this._createRelationObjectCallback = wi.createRelationObjectCallback;
			}
		}
		// this.onPDObjectChanged(); // todo: prüfen // super.ngOnInit ruft es ebenso auf
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		if (this.relationObjects.length > 0) {
			this.setSelectedObject(this.relationObjects[0]);
		}

		setTimeout(() => this.updateToolbar());

		let registerPanelUIStateProviders = (panels: Iterable<IPanelComponent>) => {
			for (let pc of panels) {
				pc.registerUIStateProvider(() => {
					let state: IComponentUIState = {
						hidden: pc.pdObject !== this.selectedObject
					};
					return state;
				}, true, this)
			}
		};

		this.panelComponents.changes.subscribe(res => {
			//let comps = Array.from(res as QueryList<IPanelComponent>);
			this.createChildComponentUIStateProviders(res as QueryList<IPanelComponent>);
			registerPanelUIStateProviders(res as QueryList<IPanelComponent>);
		});
		this.createChildComponentUIStateProviders(this.panelComponents);
		registerPanelUIStateProviders(this.panelComponents);
	}

	ngOnDestroy() {
		/*if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
		}*/
		super.ngOnDestroy();
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.panelComponents) {
			this.panelComponents.forEach(pc => {
				let panelStat = pc.getFormControlStatus();
				if (!panelStat.pristine) {
					stat.pristine = false;
				}
				if (panelStat.touched) {
					stat.touched = true;
				}
				if (!panelStat.valid) {
					stat.valid = false;
				}
			});
		}
		return stat;
	}

	getTabHeaderText(relObj: PDObject, index: number): Observable<string> {
		let wi = this.relationTabWidgetInfo;
		if (!wi || !wi.tabHeaderProvider) {
			return of(index.toString());
		}
		let header = wi.tabHeaderProvider(index, relObj, this.localizationService);
		return header.text$.pipe(
			map(text => {
				if (Number.isInteger(header.maxVisibleTextLength) && header.maxVisibleTextLength > 3 && text.length > header.maxVisibleTextLength) {
					text = text.substr(0, header.maxVisibleTextLength - 3) + '...';
				}
				return text;
			})
		);
	}

	getTabHeaderShortInfo(relObj: PDObject, index: number): Observable<string | undefined> {
		let wi = this.relationTabWidgetInfo;
		if (!wi || !wi.tabHeaderProvider) {
			return of(undefined);
		}
		let header = wi.tabHeaderProvider(index, relObj, this.localizationService);
		return header.text$.pipe(
			map(text => {
				if (Number.isInteger(header.maxVisibleTextLength) && header.maxVisibleTextLength > 3 && text.length > header.maxVisibleTextLength) {
					return text;
				}
				return undefined;
			})
		);
	}

	hasTabHeaderShortInfo(relObj: PDObject, index: number): Observable<boolean> {
		return this.getTabHeaderShortInfo(relObj, index).pipe(
			map(info => !!info)
		);
	}

	getRelationContext(relObjSpec: IRelationObjectSpec): IRelationContext {
		let relationPath: string;
		if (this.relationContext) {
			if (this.relationContext.isMultiple) {
				relationPath = this.relationContext.path + '.' + this.relationContext.index + '.' + this.propertyName ;
			} else {
				relationPath =  this.relationContext.path + '.' + this.propertyName
			}
		} else {
			relationPath = this.propertyName;
		}
		return { source: relObjSpec.obj, path: relationPath, isMultiple: true, index: relObjSpec.id };
	}

	getComponent<T extends IComponent>(id: string, type?: ComponentTypeET): T {
		if (this.selectedObject) {
			let activeSpec = this.relationObjectSpecs.find(spec => spec.obj == this.selectedObject);
			if (activeSpec) {
				let idAdapted = this.relationPath + '.' + activeSpec.id + '.' + id;
				return <T>this.formHandler.getComponent(idAdapted, type);
			}
		}
		return undefined;
	}

	getComponentFromTab<T extends IComponent>(tabId: string, id: string, type?: ComponentTypeET): T {
		let spec = this.relationObjectSpecs.find(s => s.id === tabId);
		if (spec) {
			let idAdapted = this.relationPath + '.' + tabId + '.' + id;
			return <T>this.formHandler.getComponent(idAdapted, type);
		}
		return undefined;
	}

	getComponentFromAllTabs<T extends IComponent>(id: string, type?: ComponentTypeET): T[] {
		let comps = [];
		this.relationObjectSpecs.forEach(spec => {
			let idAdapted = this.relationPath + '.' + spec.id + '.' + id;
			comps.push(this.formHandler.getComponent(idAdapted, type));
		});
		return comps;
	}

	getComponentFromSameTab<T extends IComponent>(relatedComp: IPDComponent, id: string, type?: ComponentTypeET): T {
		let relatedSpec = this.relationObjectSpecs.find(spec => spec.obj == relatedComp.pdObject);
		if (relatedSpec) {
			let idAdapted = this.relationPath + '.' + relatedSpec.id + '.' + id;
			return <T>this.formHandler.getComponent(idAdapted, type);
		}
		return undefined;
	}

	getTabIdForComponent(comp: IComponent): string | undefined {
		if (comp.id.startsWith(this.relationPath)) {
			let parts = comp.id.split('.');
			if (parts.length >= 3) {
				let tabId = parts[parts.length - 2];
				if (this.relationObjectSpecs.find(s => s.id === tabId)) {
					return tabId;
				}
			}
		}
		return undefined;
	}

	getTabIdFromIndex(tabIndex: number): string | undefined {
		if (tabIndex < 0 || tabIndex >= this.relationObjectSpecs.length) {
			return undefined;
		}
		return this.relationObjectSpecs[tabIndex].id;
	}

	removeTab(tabId: string): boolean {
		let spec = this.relationObjectSpecs.find(s => s.id === tabId);
		if (spec) {
			this.deleteRelationObjectImpl(spec.obj);
			return true;
		}
		return false;
	}

	removeAllTabs(): void {
		while (this.relationObjectSpecs.length > 0) {
			this.deleteRelationObjectImpl(this.relationObjectSpecs[0].obj);
		}

		/*this.relationObjectSpecs.forEach(spec => {
			this.deleteRelationObjectImpl(spec.obj);
		});*/
	}

	createNewTab(): string {
		return this.createRelationObject();
	}

	recreateTabs(): void {
		this.resetRelationObjects();
	}

	getTabPanel(obj: PDObject): any { // todo: IPDPanelComponent
		//return this._relationObjectSpecs.find(spec => spec.obj === obj);
		return this.panelComponents ? this.panelComponents.find(c => c.pdObject === obj) : undefined;
	}

	onTabItemClick(item: PDObject) {
		if (!this.isDisabled) {
			this.setSelectedObject(item);
		}
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.New:
				this.createRelationObject();
				break;
			case ToolBarButtonIdET.Delete:
				this.deleteRelationObject();
				break;
		}
	}

	protected createControl(): AbstractControl | undefined {
		return undefined;
	}

	protected onPDObjectChanged() {
		if (!this.initialized) {
			return;
		}
		super.onPDObjectChanged();
		this.resetRelationObjects();
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		this.updateToolbar();
	}

	protected onUIStateChanged(oldState: IComponentUIState, newState: IComponentUIState): void {
		super.onUIStateChanged(oldState, newState);
		this.updateToolbar();
	}

	private resetRelationObjects(): void {
		if (!this.initialized) {
			return;
		}
		this.formHandler.suspendUIStateUpdates();
		let relationObjCreatedCB = this.relationTabWidgetInfo?.relationObjectCreatedCallback;
		if (this._relationFormArray) {
			while (this._relationFormArray.length > 0) {
				this._relationFormArray.removeAt(0);
			}
			if (this.formGroup.get(this.pdRelationTabSpec.property)) {
				this.formGroup.removeControl(this.pdRelationTabSpec.property);
			}
		}
		this._relationObjectSpecs = [];
		//this._relationObjects = [];
		this.setSelectedObject(undefined);
		this.changeDetector.detectChanges();
		if (this.pdRelationTabSpec && this.pdRelationTabSpec.property &&
			Array.isArray(this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property])) {
			let relObjs: Array<PDObject> = this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property];
			//this._relationObjects = [...relObjs];
			this._relationFormArray = new UntypedFormArray([], this.isMandatory ? Validators.required : undefined);
			if (this.pdObject.metaData.isMandatory(this.pdRelationTabSpec.property)) {
				this._relationFormArray.setValidators(Validators.required);
			}
			this.formGroup.addControl(this.pdRelationTabSpec.property, this._relationFormArray);
			let index = 0;
			for (let relObj of this.relationObjects) {
				let relObjFormGroup = new UntypedFormGroup({
					//_className: new FormControl((<CustomPDObject>relObj).getClassName())
					_className: new UntypedFormControl((<CustomPDObject>relObj).metaData.className),
					_objectId: new UntypedFormControl((<CustomPDObject>relObj).objectId),
					_metaData: new UntypedFormControl((<CustomPDObject>relObj).metaData),
					_isNew: new UntypedFormControl((<CustomPDObject>relObj) instanceof CustomPDObject ? (<CustomPDObject>relObj).isNew : false),
					_unusedRawProperties: new UntypedFormControl((<CustomPDObject>relObj).unusedRawProperties),
					_auxiliaryProperties: new UntypedFormControl((<CustomPDObject>relObj).auxiliaryProperties)
				});
				this._relationFormArray.push(relObjFormGroup);
				this._relationObjectSpecs.push({ id: index.toString(), obj: relObj, formGroup: relObjFormGroup });
				index++;
			}
			this.changeDetector.detectChanges();
			for (let relObj of this.relationObjects) {
				this.setSelectedObject(relObj);
				/*if (this.isDisabled) {
					let panel = this.getTabPanel(relObj);
					panel.systemDisabled = true;
				}*/
				let spec = this._relationObjectSpecs.find(s => s.obj === relObj);
				this.relationObjectCreated.emit(spec.id);
				this._relationObjectCreatedSubject$.next(spec.id);
				if (relationObjCreatedCB) {
					relationObjCreatedCB(this, relObj, spec.id, this.createEventProcessingContext());
				}
			}
			if (this.relationObjects.length > 0) {
				this.setSelectedObject(this.relationObjects[0]);
			}
		}
		this.updateToolbar();
		this.formHandler.resumeUIStateUpdates(true);
	}

	private get relationPath(): string {
		if (this.relationContext) {
			if (this.relationContext.isMultiple) {
				return this.relationContext.path + '.' + this.relationContext.index + '.' + this.pdRelationTabSpec.property;
			} else {
				return this.relationContext.path + '.' + this.pdRelationTabSpec.property;
			}
		}
		return this.pdRelationTabSpec.property;
		// alt: return this.relationContext ? this.relationContext.path + '.' + this.pdRelationTabSpec.property : this.pdRelationTabSpec.property;
	}

	private createRelationObject(): string {
		if (!this._createRelationObjectCallback && !this.objCreator) {
			throw new Error('createRelationObjectCallback and objCreator are undefined');
		}

		this.formHandler.suspendUIStateUpdates();
		let index = 0;
		while (this._relationObjectSpecs.find(item => item.id === index.toString())) {
			index++;
		}
		let relObj = this._createRelationObjectCallback ? this._createRelationObjectCallback(this.pdClass) as PDObject : new this.objCreator();
		this.relationObjects.push(relObj);
		let relObjFormGroup = new UntypedFormGroup({
			//_className: new FormControl((<CustomPDObject>relObj).getClassName())
			_className: new UntypedFormControl((<CustomPDObject>relObj).metaData.className),
			_objectId: new UntypedFormControl((<CustomPDObject>relObj).objectId),
			_metaData: new UntypedFormControl((<CustomPDObject>relObj).metaData),
			_isNew: new UntypedFormControl((<CustomPDObject>relObj) instanceof CustomPDObject ? (<CustomPDObject>relObj).isNew : false),
			_unusedRawProperties: new UntypedFormControl((<CustomPDObject>relObj).unusedRawProperties),
			_auxiliaryProperties: new UntypedFormControl((<CustomPDObject>relObj).auxiliaryProperties)
		});
		this._relationFormArray.push(relObjFormGroup);
		let tabId = index.toString();
		this._relationObjectSpecs.push({ id: tabId, obj: relObj, formGroup: relObjFormGroup });
		this.changeDetector.detectChanges();
		this.setSelectedObject(relObj);
		this.relationObjectCreated.emit(tabId);
		this._relationObjectCreatedSubject$.next(tabId);
		let relationObjCreatedCB = this.relationTabWidgetInfo?.relationObjectCreatedCallback;
		if (relationObjCreatedCB) {
			relationObjCreatedCB(this, relObj, tabId, this.createEventProcessingContext());
		}
		this.updateToolbar();
		this.formHandler.resumeUIStateUpdates(true);
		return tabId;
	}

	private deleteRelationObject(): void {
		forkJoin(
			[
				this.localizationService.getSystemString('kendo-ui.components.pd-relation-tab.remove-record-title'),
				this.localizationService.getSystemString('kendo-ui.components.pd-relation-tab.remove-record')
			]
		).pipe(
			switchMap(res => this.interactionService.showConfirmMessage(res[0], res[1])),
			tap(res => {
				if (res === DialogResultET.Ok) {
					this.deleteRelationObjectImpl(this._selectedObject);
				}
			})
		).subscribe();
	}

	private deleteRelationObjectImpl(relObj: PDObject): void {
		let index = this.relationObjects.indexOf(relObj);
		if (index >= 0) {
			this.relationObjectDeleting.emit(relObj);
			this._relationObjectDeletingSubject$.next(relObj);
			this.relationObjects.splice(index, 1);
			this._relationFormArray.removeAt(index);
			this._relationObjectSpecs.splice(index, 1);
			if (relObj === this._selectedObject) {
				this.setSelectedObject(this.relationObjects.length > 0 ? this.relationObjects[0] : undefined);
			}
			this.relationObjectDeleted.emit();
			this._relationObjectDeletedSubject$.next();
			this.updateToolbar();
		}
	}

	private updateToolbar(): void {
		if (!this.toolbarItems) {
			return;
		}
		for (let tbItem of this.toolbarItems) {
			tbItem.disabled = this.isDisabled || this.isReadonly;
		}
		if (!this.isDisabled && !this.isReadonly) {
			let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
			if (this.canCreateTab) {
				let tbItemNew = this.toolbarItems.find(item => item.id == ToolBarButtonIdET.New);
				if (this.relationObjects.length >= this.maxTabCount) {
					tbItemNew.disabled = true;
				}
				else {
					tbItemNew.disabled = (relAccessRights && relAccessRights.create !== undefined) ? !relAccessRights.create : undefined;
				}
			}
			if (this.canDeleteTab) {
				let tbItemDelete = this.toolbarItems.find(item => item.id == ToolBarButtonIdET.Delete);
				if (!this._selectedObject || this.relationObjects.length <= this.minTabCount) {
					tbItemDelete.disabled = true;
				}
				else {
					tbItemDelete.disabled = (relAccessRights && relAccessRights.delete !== undefined) ? !relAccessRights.delete : undefined;
				}
			}
		}
	}

	public getId(): string | undefined {
		if (this.pdRelationTabSpec) {
			let prop = this.pdRelationTabSpec.property;
			if (this.propertyRelationPath) {
				prop = `${this.propertyRelationPath}.${prop}`;
			}
			return this.getIdFromPropertyName(prop);
			//return this.getIdFromPropertyName(this.pdRelationTabSpec.property);
		}
		return super.getId();
	}

	hasId(): boolean {
		if (this.pdRelationTabSpec?.property) {
			return true;
		}
		return super.hasId();
	}

	//
	// IComponent overrides
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RelationTab;
	}

	/*protected onDisabledChanged() {
		super.onDisabledChanged();
		if (this.panelComponents) {
			this.panelComponents.forEach(comp => comp.systemDisabled = this.isDisabled);
		}
		this.updateToolbar();
	}*/

	/*protected onVisibleChanged() {
		super.onVisibleChanged();
		if (this.panelComponents) {
			this.panelComponents.forEach(comp => comp.systemVisible = this.isVisible);
		}
		this.updateToolbar();
	}*/

	reset(): void {
		/*while (this._relationFormArray.length > 0) {
			this._relationFormArray.removeAt(0);
		}
		if (this.formGroup.get(this.pdRelationTabSpec.property)) {
			this.formGroup.removeControl(this.pdRelationTabSpec.property);
		}*/

		//this._relationObjects = [];
		//this._relationObjectSpecs = [];
		this._selectedObject = undefined;
		this.resetRelationObjects();
	}

	get propertyName(): string | undefined {
		return this.pdRelationTabSpec ? this.pdRelationTabSpec.property : undefined;
	}

	get propertyRelationPath(): string | undefined {
		return undefined;
	}

	get control(): AbstractControl | undefined {
		return undefined; // todo: relationFormArray zurückgeben?
	}

	updateUIState(onlySelf = true): void {
		super.updateUIState(onlySelf);
		if (!onlySelf && this.selectedObject) {
			this.panelComponents.find(pc => pc.pdObject === this.selectedObject).updateUIState(false);
		}
	}

	/*registerUIStateProvider(provider: UIStateProvider, providerHost?: IComponent): IUIStateProviderRegistration {
		let registration = super.registerUIStateProvider(provider, providerHost);
		if (this.selectedObject) {
			let selectedTab = this.panelComponents.find(pc => pc.pdObject === this.selectedObject);
			let ctx = this.createEventProcessingContext();
			let childReg = selectedTab.registerUIStateProvider((src) => {
				let state = provider(src, ctx);
				return {
					readonly: state.readonly ? true : undefined,
					disabled: state.disabled ? true : undefined
					// ...
					// todo
				}
			}, this);
			registration.addChildRegistration(childReg);
		}
		return registration;
	}*/
}
