import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//import { PDLabeledControlComponent } from '../../../otris-ng-core/components/pd-labeled-control/pd-labeled-control.component';
import { PDLabeledControlComponent, FormHandlerService,
	NumericTextFieldWidgetInfo, EventProcessingContextManagerService, LocalizationServiceToken } from '@otris/ng-core';

//import { FormHandlerService } from '../../../otris-ng-core-shared/services/form-handler.service';
//import { ComponentTypeET } from '../../../otris-ng-core-shared/model/types';
import { ComponentTypeET, IComponent, ILocalizationService, INumericTextFieldComponent } from '@otris/ng-core-types';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from "rxjs/operators";

//[min]="0" [max]="100" [autoCorrect]="autoCorrect"
@Component({
	selector: 'otris-pd-numeric-text-field',
	template: `
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
		>
			@if (!isReadonly) {
				<kendo-numerictextbox
					class="numeric-text-box"
					[formControl]="formControl"
					[step]="step"
					[min]="min"
					[max]="max"
					[decimals]="decimals"
					[format]="format"
					[spinners]="showSpinners"
					[autoCorrect]="true"
					[readonly]="isReadonly"
				>
					<kendo-numerictextbox-messages
						[increment]="incrementMessage"
						[decrement]="decrementMessage"
					/>
				</kendo-numerictextbox>
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		:host{
			flex: 1 1 auto;
		}
		/*
		.input-container {
			margin-top: 0.5em;
			display: flex;
		}*/
		.numeric-text-box {
			flex: 1 1 auto;
		}
	`]
})
export class PDNumericTextFieldComponent extends PDLabeledControlComponent implements INumericTextFieldComponent {

	incrementMessage = "Increase value"
	decrementMessage = "Decrease value"

	get numericTextFieldWidgetInfo(): NumericTextFieldWidgetInfo | undefined {
		let wi = this.labeledControlWidgetInfo;
		if (wi instanceof NumericTextFieldWidgetInfo) {
			return <NumericTextFieldWidgetInfo>wi;
		}
		return undefined;
	}

	get showSpinners(): boolean {
		let wi = this.numericTextFieldWidgetInfo;
		return !wi || !(wi.showSpinners !== true)
	}

	get min(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.min) ? wi.min : 0;
	}

	get max(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.max) ? wi.max : 1;
	}

	get step(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.step) ? wi.step : 0.1;
	}

	get format(): string {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.format) ? wi.format : 'n2';
	}

	get decimals(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.decimals) ? wi.decimals : 2;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	protected override get defaultValue(): number | undefined {
		return typeof(this.pdItemSpec.defaultValue) === 'number' ? this.pdItemSpec.defaultValue : undefined;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
	) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangesSubject$ = new BehaviorSubject(this.control?.value);
		if (this.control?.value) {
			this._valueChangesUIStateSubject$.next(this.control.value)
		}

		let wi = this.numericTextFieldWidgetInfo;
		if (wi) {
			if (wi.valueChangesHandler) {
				this.addSubscription(this.valueChanges$.subscribe(res => {
					wi.valueChangesHandler(this, res, this.createEventProcessingContext());
				}));
			}
		}

		this.localizationService.changeHandler.pipe(
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-numeric-text-field.increment-message',
				'kendo-ui.components.pd-numeric-text-field.decrement-message'
			])),
			tap(trans => {
				this.incrementMessage = trans[0];
				this.decrementMessage = trans[1];
			})
		).subscribe();
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._valueChangesUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	//protected onReadonlyChanged(): void {}

	protected onControlValueChanges(val: any) {
		if (this.pdObject && this.propertyName) {
			this.pdObject.pdObjectRaw[this.propertyName] = val;
		}
		super.onControlValueChanges(val);
		if (this._valueChangesSubject$) {
			this._valueChangesSubject$.next(this.value);
			this._valueChangesUIStateSubject$.next(this.value);
		}
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.NumericText;
	}

	//
	// Interface INumericTextFieldComponent
	//

	get value(): number | undefined {
		return this.control.value ?? undefined;
	}

	set value(val: number | undefined) {
		this.control.setValue(val);
	}

	private _valueChangesSubject$: BehaviorSubject<number | undefined>;

	private _valueChanges$: Observable<number | undefined>;

	get valueChanges$(): Observable<number | undefined> {
		if (!this._valueChanges$) {
			this._valueChanges$ = this._valueChangesSubject$.asObservable();
		}
		return this._valueChanges$;
	}

	private _valueChangesUIStateSubject$ = new BehaviorSubject<number | undefined>(undefined);
}
