import { ComponentUIStateExpression, ExpressionOptions, IComponent, IComponentUIState, IEventProcessingContext, IFormHandlerService, UIStateProvider } from "@otris/ng-core-types";
import { ASTToken, IGrammarParser } from "../../services/grammar-parser-factory.service";
import { ExpressionGroupAction } from "./condition-expression-actions";

// todo umbenennen UIStateExpressionAction?
// oder zusätzliche Klasse UIStateExpressionAction?
export class UIStateExpressionHandler /*implements IGrammarRule*/ {

	static createUIStateProvider(parser: IGrammarParser, expr: ComponentUIStateExpression, options?: ExpressionOptions): UIStateProvider {
		//let astToken = parser.parse()
		let handler = new UIStateExpressionHandler(parser, expr, options);
		return handler.uiStateProvider;
	}

	private static readonly UIStateExprToken = 'UIStateExpr'

	private _uiStateProvider: UIStateProvider

	get uiStateProvider(): UIStateProvider {
		return this._uiStateProvider;
	}

	private _astTokenHidden: ASTToken;

	private _astTokenDisabled: ASTToken;

	private _astTokenReadonly: ASTToken;

	private _astTokenMandatory: ASTToken;

	private constructor(parser: IGrammarParser, uiStateExpr: ComponentUIStateExpression, options?: ExpressionOptions) { // private _astToken: ASTToken

		// todo: prüfen, ob '\n' noch gebraucht wird
		let adaptExpr = (expr: string) => {
			return expr.trim() + '\n';
		}

		if (uiStateExpr.hidden) {
			this._astTokenHidden = parser.parse(adaptExpr(uiStateExpr.hidden), UIStateExpressionHandler.UIStateExprToken);
			if (!this._astTokenHidden) {
				console.warn(`Invalid expression '${uiStateExpr.hidden}' for UIState 'hidden'.`)
			}
		}
		if (uiStateExpr.disabled) {
			this._astTokenDisabled = parser.parse(adaptExpr(uiStateExpr.disabled), UIStateExpressionHandler.UIStateExprToken);
			if (!this._astTokenDisabled) {
				console.warn(`Invalid expression '${uiStateExpr.disabled}' for UIState 'disabled'.`)
			}
		}
		if (uiStateExpr.mandatory) {
			this._astTokenMandatory = parser.parse(adaptExpr(uiStateExpr.mandatory), UIStateExpressionHandler.UIStateExprToken);
			if (!this._astTokenMandatory) {
				console.warn(`Invalid expression '${uiStateExpr.mandatory}' for UIState 'mandatory'.`)
			}
		}
		if (uiStateExpr.readonly) {
			this._astTokenReadonly = parser.parse(adaptExpr(uiStateExpr.readonly), UIStateExpressionHandler.UIStateExprToken);
			if (!this._astTokenReadonly) {
				console.warn(`Invalid expression '${uiStateExpr.readonly}' for UIState 'readonly'.`)
			}
		}
		this.createUIStateProvider(parser);
	}

	private createUIStateProvider(parser: IGrammarParser, options?: ExpressionOptions): void {
		this._uiStateProvider = (src, ctx) => {
			let state: IComponentUIState = {};
			if (this._astTokenDisabled) {
				let action = UIStateExpressionAction.create(this._astTokenDisabled, ctx); 
				state.disabled = action.evaluate(options);
			}
			if (this._astTokenReadonly) {
				let action = UIStateExpressionAction.create(this._astTokenReadonly, ctx); 
				state.readonly = action.evaluate(options);
			}
			if (this._astTokenHidden) {
				let action = UIStateExpressionAction.create(this._astTokenHidden, ctx); 
				state.hidden = action.evaluate(options);
			}
			if (this._astTokenMandatory) {
				let action = UIStateExpressionAction.create(this._astTokenMandatory, ctx); 
				state.mandatory = action.evaluate(options);
			}
			return state;
		}
	}

	/*private evaluateUIStateExpr(formHandler: IFormHandlerService, token: ASTToken): boolean {
		if (token.children.length != 1) {
			throw new Error('todo');
		}

		let exprGroup = ExpressionGroupAction.create(token.children[0]);
		return exprGroup.evaluate(formHandler);
	}*/
}

// todo: separate Datei
export class UIStateExpressionAction {
	static create(astToken: ASTToken, ctx: IEventProcessingContext): UIStateExpressionAction {
		if (astToken.type !== 'UIStateExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new UIStateExpressionAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(options?: ExpressionOptions): boolean {
		if (this._astToken.children.length != 1) {
			throw new Error('todo');
		}
		
		let exprGroup = ExpressionGroupAction.create(this._astToken.children[0], this._ctx);
		return exprGroup.evaluate(options);
	}
}
