import { InjectionToken } from '@angular/core';
import { Observable } from "rxjs";
import { Params, UrlTree } from '@angular/router';

export const IErrorHandlerToken = new InjectionToken('IErrorHandler');

export interface IError {
	message?: string,
	details?: string,
	canReconnect?: boolean
}

export interface IErrorHandler {
	handleError(error: IError): void;

	createErrorUrlTree(error: IError): UrlTree | undefined;

	addNavigationQueryParam(key: string, value: any): void;

	removeNavigationQueryParam(key: string): void;

	mergeNavigationQueryParams(queryParams: Params): Params;

	clearNavigationQueryParams(): void;

	removeErrorQueryParams(queryParams: Params): Params;

	errorOccurred$: Observable<IError>;

	errorOccurred: boolean;
}
