import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	DropDownWidgetInfo,
	EventProcessingContextManagerService,
	FormHandlerService,
	PDLabeledControlComponent,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	AbstractEnumeration,
	ComponentTypeET,
	IComponent,
	IDropDownComponent,
	IEnumerationItem,
	ILocalizationService,
	IPDAccessService,
	MultiSelectValueTypeET,
	TypeET
} from '@otris/ng-core-types';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { IPDAccessServiceToken } from "@otris/ng-core-shared";

/**
 * Bei einem string array gleicht ergName dem value
 */
interface IChoiceData {
	ergName: string;
	value: IEnumerationItem | string;
}

/**
 * [formControl]="formGroup.controls[propertyName]"
 */
@Component({
	selector: 'otris-pd-drop-down',
	template: `
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
			[disableFirstElementClick]="true"
		>
			@if (!isReadonly) {
				<!-- https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/ -->
				<!-- kendo-dropdownlist: [data]: Array<string> oder Array<{string, T}> [valueField]=T(von data) [data]=string(von Data) -->
				<!-- [valueField] scheinbar ohne Funktion bei dieser Art von Data-Binding -->
				<!-- [disabled]="!isEnabled" ist nötig da kein direktes Databinding mit formctrl besteht  -->
				<kendo-dropdownlist #dropDownList
					class="dropdown"
					[data]="choice"
					[textField]="'ergName'"
					[valueField]="'value'"
					[valuePrimitive]="false"
					[value]="selectedValue"
					(valueChange)="onValueChange($event)"
					[disabled]="isDisabled"
					(selectionChange)="onSelectionChange($event)"
					[id]="getId() + '.dropdown'"
				/>
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		.dropdown {
			flex: 1 1 auto;
		}
	`]
})
export class PDDropDownComponent extends PDLabeledControlComponent implements IDropDownComponent, AfterViewChecked {
	// choice: IEnumerationItem[];
	choice: IChoiceData[];
	private _customChoiceProvider: () => Observable<string[]>;

	@ViewChild('dropDownList') kendoDropDownList: DropDownListComponent;

	selectedValue: IChoiceData;

	private _selectedItem: string | IEnumerationItem;

	get selectedItem(): string | IEnumerationItem | undefined {
		return this._selectedItem;
	}

	@Input()
	set selectedItem(item: string | IEnumerationItem | undefined) {
		if (this._selectedItem != item) {
			this._selectedItem = item;
			let ctrl = this.control;
			if (ctrl) {
				ctrl.setValue(item);
				ctrl.markAsDirty();
			}
			this.updateSelectedValue();
		}
	}

	set customChoiceProvider(cb: () => Observable<string[]>) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private get abstractEnumeration(): Observable<AbstractEnumeration | undefined> {
		if (!this.pdObject || !this.propertyName) {
			return of(undefined);
		}

		if (this.isEnumChoice) {
			const enumPropName = this.propertyName + "Object";
			const enumObject = this.pdObject.pdObjectRaw[enumPropName];
			if (enumObject && !(enumObject instanceof AbstractEnumeration)) {
				throw Error('Property is not of type AbstractEnumeration');
			}
			if (!enumObject) {
				const enumName = this.pdObject.metaData.getEnumPropertyEnumName(this.propertyName);
				if (!enumName) {
					throw new Error(`No enumName defined for property '${this.propertyName}'`);
				}
				return this.pdAccessService.getEnumeration(enumName, this.localizationService.currentLanguageIndex).pipe(
					tap(enumObject => {
						this.pdObject[this.propertyName + "Object"] = enumObject;
					})
				);
			}
			return of(<AbstractEnumeration>this.pdObject.pdObjectRaw[enumPropName]);
		}

		// Das ist falsch - EnumItemArrayChoice ist für eine Mehrfachauswahl von Enums!
		// Wird aber evtl. noch irgendwo verwendet?
		if (this.isEnumItemArrayChoice) {
			const enumName = this.pdObject.metaData.getEnumPropertyEnumName(this.propertyName);
			if (!enumName) {
				throw new Error(`No enumName defined for property '${this.propertyName}'`);
			}
			return this.pdAccessService.getEnumeration(enumName, this.localizationService.currentLanguageIndex);
		}
		throw new Error('Property is not type EnumItemArray')
	}

	private get isEnumItemArrayChoice(): boolean {
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.EnumItemArray; // privacy
		}
		return false;
	}

	private get isEnumChoice(): boolean {
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.Enum; // client
		}
		return false;
	}

	private get isStringChoice(): boolean {
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.String;
		}
		return false;
	}

	get dropDownWidgetInfo(): DropDownWidgetInfo | undefined {
		let wi = this.labeledControlWidgetInfo;
		if (wi instanceof DropDownWidgetInfo) {
			return <DropDownWidgetInfo>wi;
		}
		return undefined;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	constructor(
		router: Router,
		route: ActivatedRoute,
		formHandler: FormHandlerService,
		private cdref: ChangeDetectorRef,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(LocalizationServiceToken) private localizationService: ILocalizationService,
	) {
		super(
			router,
			route,
			formHandler,
			eventProcessingContextManagerService
		);
	}

	ngOnInit() {
		super.ngOnInit();

		this._selectedItemChangedSubject$ = new BehaviorSubject(this.control?.value);
		if (this.control?.value) {
			this._selectedItemChangedUIStateSubject$.next(this.control.value)
		}

		if (!this.isEnumChoice && !this.isStringChoice && !this.isEnumItemArrayChoice) {
			throw Error('Property is not a type of String, Enum or EnumItemArray');
		}
		if (this.isEnumChoice) {
			const enumPropName = this.propertyName + "Object";
			const enumObj = this.pdObject.pdObjectRaw[enumPropName];
			if (enumObj && !(this.pdObject.pdObjectRaw[enumPropName] instanceof AbstractEnumeration)) {
				throw Error('Property is not a type of AbstractEnumeration');
			}
		}
		this.updateChoice();

		let wi = this.dropDownWidgetInfo;
		if (wi) {
			if (wi.selectedItemChangedHandler) {
				this.addSubscription(this.selectedItemChanged$.subscribe(res => {
					wi.selectedItemChangedHandler(this, res, this.createEventProcessingContext());
				}));
			}
		}
		if (this.kendoDropDownList?.wrapper) {
			this.kendoDropDownList.wrapper.nativeElement.setAttribute('aria-labelledby', this.id + ".pdLabeledControlFrame");
		}
	}

	ngAfterViewChecked() {
		if (this.kendoDropDownList?.wrapper) {
			this.kendoDropDownList.wrapper.nativeElement.setAttribute('aria-labelledby', this.id + ".pdLabeledControlFrame");
		}
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();

		//this.updateChoice();
		if (this.isEnumChoice || this.isEnumItemArrayChoice) {
			this.abstractEnumeration.pipe(
				switchMap(res => {
					return res.updateItemErgNames(this.localizationService.currentLanguageIndex);
				}),
				tap(() => {
					this.updateChoice();
					let ctrl = this.control;
					if (ctrl) {
						let tmp = ctrl.value;
						ctrl.setValue(undefined);
						this.cdref.detectChanges();
						ctrl.setValue(tmp);
						this.cdref.detectChanges();
					}
				})
			).subscribe()
			return;
		}
		this.updateChoice();
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		this.updateSelectedValue();
		this.updateSelectedItem();
	}

	subscribeUIStateEvents(callback: (source: IComponent) => void): Subscription | undefined {
		let res = super.subscribeUIStateEvents(callback);
		let sub = this._selectedItemChangedUIStateSubject$.subscribe(() => {
			callback(this);
		});
		if (res) {
			res.add(sub);
			return res;
		}
		return sub;
	}

	//protected onReadonlyChanged(): void {}

	onValueChange(val: IChoiceData): void {
		if (val != this.selectedValue) {
			this.selectedValue = val;
			this.updateSelectedItem();
			let ctrl = this.control;
			if (ctrl) {
				if (this.isEnumItemArrayChoice) {
					ctrl.setValue((this.selectedItem as IEnumerationItem)?.enumConst ?? null);
				} else {
					ctrl.setValue(this.selectedItem ?? null);
				}
			}
			if (this.isEnumItemArrayChoice) {
				this.pdObject.pdObjectRaw[this.propertyName] = (this.selectedItem as IEnumerationItem)?.enumConst ?? null;
			} else {
				this.pdObject.pdObjectRaw[this.propertyName] = this.selectedItem ?? null;
			}
			if (this._selectedItemChangedSubject$) {
				this._selectedItemChangedSubject$.next(this.selectedItem);
				this._selectedItemChangedUIStateSubject$.next(this.selectedItem);
			}
		}
	}

	// Todo: Mit Telerik klären was genau der Unterschied zw. onValueChange() und onSelectionChange() ist!
	onSelectionChange(value: any): void {
		//console.log(`onSelectionChange: ${value}`);
	}

	updateChoice() {
		if (this.isEnumChoice || this.isEnumItemArrayChoice) {
			this.abstractEnumeration.pipe(
				tap(res => {
					if (res) {
						this.choice = [];
						res.choice.forEach(obj => {
							this.choice.push(<IChoiceData>{ergName: obj.ergName, value: obj});
							//TODO: Behandelt nicht den customChoiceProvider
						});
						this.updateSelectedValue();
					}
				})
			).subscribe();
		} else if (this.isStringChoice) {
			//TODO: Behandelt nur den _customChoiceProvider
			if (this._customChoiceProvider) {
				this._customChoiceProvider().subscribe(str => {
					if (str) {
						this.choice = [];
						str.forEach(s => {
							this.choice.push(<IChoiceData>{ergName: s, value: s});
						});
						this.updateSelectedValue();
					}
				})
			}
		}
	}

	private updateSelectedValue(): void {
		let ctrl = this.control;
		if (!ctrl) {
			return;
		}

		let newVal: IChoiceData;
		if (ctrl.value && this.choice) {
			if (this.isEnumChoice) {
				newVal = this.choice.find(c => (<IEnumerationItem>c.value).enumConst === (<IEnumerationItem>ctrl.value).enumConst);
			}
			else if (this.isEnumItemArrayChoice) {
				newVal = this.choice.find(c => (<IEnumerationItem>c.value).enumConst === ctrl.value);
			}
			else if (this.isStringChoice) {
				newVal = this.choice.find(c => c.value === ctrl.value);
			}
		}
		if (this.selectedValue != newVal) {
			this.selectedValue = newVal;
		}
	}

	private updateSelectedItem(): void {
		this._selectedItem = this.selectedValue ? this.selectedValue.value : undefined;
	}

	reset(): void {
		//this._selectedItem = undefined;
		//this.selectedValue = undefined;
		super.reset();
		this.updateSelectedValue();
		this.updateSelectedItem();
	}

	get componentType(): ComponentTypeET {
		return ComponentTypeET.DropDownList;
	}

	private _selectedItemChangedSubject$: BehaviorSubject<string | IEnumerationItem | undefined>;

	private _selectedItemChanged$: Observable<string | IEnumerationItem | undefined>;

	get selectedItemChanged$(): Observable<string | IEnumerationItem | undefined> {
		if (!this._selectedItemChanged$) {
			this._selectedItemChanged$ = this._selectedItemChangedSubject$.asObservable();
		}
		return this._selectedItemChanged$;
	}

	private _selectedItemChangedUIStateSubject$ = new BehaviorSubject<string | IEnumerationItem | undefined>(undefined);
}
