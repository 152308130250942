import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UITemplateDirective } from './directives/ui-template.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { ISchema, JsonSchemaToken, SchemaType } from './model/types';

import * as appLocalizationsSchema from './schemas/app-localizations-schema.json';
import * as appModuleLocalizationsSchema from './schemas/app-module-localizations-schema.json';
import * as classLocalizationsSchema from './schemas/class-localizations-schema.json';
import * as componentLocalizationsSchema from './schemas/component-localizations-schema.json';
import * as enumLocalizationsSchema from './schemas/enum-localizations-schema.json';
import * as ergNameSchema from './schemas/erg-name-schema.json';
import * as itemLocalizationSchema from './schemas/item-localization-schema.json';
import * as serviceLocalizationsSchema from './schemas/service-localizations-schema.json';
import * as systemModuleLocalizationsSchema from './schemas/system-module-localizations-schema.json';
import * as uiItemLocalizationSchema from './schemas/ui-item-localization-schema.json';
import * as classMetaDataSchema from './schemas/class-meta-data-schema.json';
import * as propertyMetaDataSchema from './schemas/property-meta-data-schema.json';
import * as relationAccessRightsSchema from './schemas/relation-access-rights-schema.json.json';
import * as relationMetaDataSchema from './schemas/relation-meta-data-schema.json';
import * as propertyAccessRightsSchema from './schemas/property-access-rights-schema.json';
// import * as typeEtEnumSchema from './schemas/type-et-emum-schema.json';
import * as pdDocumentSchema from './schemas/pd-document-schema.json';
import * as dokumentenuploadDataSchema from './schemas/dokumentenupload-data-schema.json';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		UITemplateDirective,
		SafeHtmlPipe,
		SafeStylePipe
	],
	exports: [
		UITemplateDirective,
		SafeHtmlPipe,
		SafeStylePipe
	],
	providers: [
		SafeStylePipe,

		// ERROR: Error during template compile of 'otris-ng-core-shared.module.ts' Unsupported bundled module reference.
		// { provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.object, data: appLocalizationsSchema }, multi: true },

		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(appLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(appModuleLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(classLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(componentLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(enumLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(ergNameSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(itemLocalizationSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(serviceLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(systemModuleLocalizationsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(uiItemLocalizationSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(classMetaDataSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(propertyMetaDataSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(relationAccessRightsSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(relationMetaDataSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(propertyAccessRightsSchema) }, multi: true },
		// { provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(typeEtEnumSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(pdDocumentSchema) }, multi: true },
		{ provide: JsonSchemaToken, useValue: <ISchema>{ type: SchemaType.string, data: JSON.stringify(dokumentenuploadDataSchema) }, multi: true },
	]
})
export class OtrisNgCoreSharedModule { }
