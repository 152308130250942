import { ComponentTypeET, ExpressionOptions, IComponent, IEnumerationItem, IEventProcessingContext, IFormHandlerService, IObjectReferenceComponent, IPDObject, IRadioButtonGroupComponent, ITextFieldComponent } from "@otris/ng-core-types";
import { ASTToken } from "../../services/grammar-parser-factory.service";

// todo: separate Datei
export class ComponentExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): ComponentExprAction {
		if (astToken.type !== 'ComponentExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new ComponentExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {
	}

	evaluate(options?: ExpressionOptions): any {
		let textFieldComp = this._astToken.children.find(t => t.type === 'TextFieldComponentExpr'); // todo Konstante
		let objectRefComp = this._astToken.children.find(t => t.type === 'ObjectReferenceComponentExpr'); // todo Konstante
		let radioButtonGroupComp = this._astToken.children.find(t => t.type === 'RadioButtonGroupComponentExpr'); // todo Konstante
		
		if (textFieldComp) {
			let action = TextFieldComponentExprAction.create(textFieldComp, this._ctx);
			return action.evaluate(options);
		}
		else if (objectRefComp) {
			let action = ObjectReferenceComponentExprAction.create(objectRefComp, this._ctx);
			return action.evaluate(options);
		}
		else if (radioButtonGroupComp) {
			let action = RadioButtonGroupComponentExprAction.create(radioButtonGroupComp, this._ctx);
			return action.evaluate(options);
		}
		else {
			throw new Error('todo');
		}
	}
}

// todo: separate Datei
export class TextFieldComponentExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): TextFieldComponentExprAction {
		if (astToken.type !== 'TextFieldComponentExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new TextFieldComponentExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {}

	// todo
	evaluate(options?: ExpressionOptions): string {
		let compIdToken = this._astToken.children.find(t => t.type === 'ComponentId'); // todo Konstante
		let propertyToken = this._astToken.children.find(t => t.type === 'TextFieldComponentProperty'); // todo Konstante
		if (!compIdToken || !propertyToken) {
			throw new Error('todo');
		}
		let componentIdAction = ComponentIdAction.create(compIdToken, this._ctx);
		let comp = componentIdAction.evaluate(options);
		if (comp.componentType !== ComponentTypeET.TextField) {
			throw new Error('todo');
		}
		let propertyAction = TextFieldComponentPropertyAction.create(propertyToken);
		return propertyAction.evaluate(comp as ITextFieldComponent);
	}
}

export class TextFieldComponentPropertyAction {

	static create(astToken: ASTToken): TextFieldComponentPropertyAction {
		if (astToken.type !== 'TextFieldComponentProperty') { // todo: Konstante
			throw new Error('todo');
		}
		return new TextFieldComponentPropertyAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {}

	evaluate(comp: ITextFieldComponent): string | undefined {
		switch (this._astToken.text) {
			case 'text': { // todo: Konstante
				return comp.text;
			}

			default:
				throw new Error('todo');
		}
	}
}

// todo: separate Datei
export class ObjectReferenceComponentExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): ObjectReferenceComponentExprAction {
		if (astToken.type !== 'ObjectReferenceComponentExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new ObjectReferenceComponentExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {}

	evaluate(options?: ExpressionOptions): IPDObject | string | undefined {
		let compIdToken = this._astToken.children.find(t => t.type === 'ComponentId'); // todo Konstante
		let propertyToken = this._astToken.children.find(t => t.type === 'ObjectReferenceComponentProperty'); // todo Konstante
		if (!compIdToken || !propertyToken) {
			throw new Error('todo');
		}
		//let comp = formHandler.getComponent(compIdToken.text); // Todo: keine Exception bei undefined!
		let componentIdAction = ComponentIdAction.create(compIdToken, this._ctx);
		let comp = componentIdAction.evaluate(options);
		if (!comp || comp.componentType !== ComponentTypeET.ObjectReference) {
			throw new Error('todo');
		}
		
		let propertyAction = ObjectReferenceComponentPropertyAction.create(propertyToken);
		return propertyAction.evaluate(comp as IObjectReferenceComponent);
	}
}

export class ObjectReferenceComponentPropertyAction {

	static create(astToken: ASTToken): ObjectReferenceComponentPropertyAction {
		if (astToken.type !== 'ObjectReferenceComponentProperty') { // todo: Konstante
			throw new Error('todo');
		}
		return new ObjectReferenceComponentPropertyAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {}

	evaluate(comp: IObjectReferenceComponent): IPDObject | string | undefined {
		switch (this._astToken.text) {
			case 'selection': { // todo: Konstante
				return comp.selection;
			}
			case 'selectionAsText': { // todo: Konstante
				return comp.selectionAsText;
			}
			default:
				throw new Error('todo');
		}
	}
}

export class RadioButtonGroupComponentExprAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): RadioButtonGroupComponentExprAction {
		if (astToken.type !== 'RadioButtonGroupComponentExpr') { // todo: Konstante
			throw new Error('todo');
		}
		return new RadioButtonGroupComponentExprAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {}

	evaluate(options?: ExpressionOptions): string | boolean | IEnumerationItem | undefined {
		let compIdToken = this._astToken.children.find(t => t.type === 'ComponentId'); // todo Konstante
		let propertyToken = this._astToken.children.find(t => t.type === 'RadioButtonGroupComponentProperty'); // todo Konstante
		if (!compIdToken || !propertyToken) {
			throw new Error('todo');
		}
		//let comp = formHandler.getComponent(compIdToken.text); // Todo: keine Exception bei undefined!
		let componentIdAction = ComponentIdAction.create(compIdToken, this._ctx);
		let comp = componentIdAction.evaluate(options);
		if (!comp || comp.componentType !== ComponentTypeET.RadioButtonGroup) {
			throw new Error('todo');
		}
		
		let propertyAction = RadioButtonGroupComponentPropertyAction.create(propertyToken);
		return propertyAction.evaluate(comp as IRadioButtonGroupComponent);
	}
}

export class RadioButtonGroupComponentPropertyAction {

	static create(astToken: ASTToken): RadioButtonGroupComponentPropertyAction {
		if (astToken.type !== 'RadioButtonGroupComponentProperty') { // todo: Konstante
			throw new Error('todo');
		}
		return new RadioButtonGroupComponentPropertyAction(astToken);
	}
	
	private constructor(private _astToken: ASTToken) {}

	evaluate(comp: IRadioButtonGroupComponent): string | boolean | IEnumerationItem | undefined {
		switch (this._astToken.text) {
			case 'selection': { // todo: Konstante
				return comp.selection ? comp.selection.value : undefined;
			}
			default:
				throw new Error('todo');
		}
	}
}

export class ComponentIdAction {

	static create(astToken: ASTToken, ctx: IEventProcessingContext): ComponentIdAction {
		if (astToken.type !== 'ComponentId') { // todo: Konstante
			throw new Error('todo');
		}
		return new ComponentIdAction(astToken, ctx);
	}
	
	private constructor(private _astToken: ASTToken, private _ctx: IEventProcessingContext) {}

	evaluate(options?: ExpressionOptions): IComponent {
		let predefinedCompId = 	this._astToken.children.find(t => t.type === 'PredefinedComponentId'); // todo Konstante
		if (predefinedCompId) {
			switch (predefinedCompId.text) {
				case 'foreign': // todo: Konstante
					if (!options?.foreignComp) {
						throw new Error('todo');
					}
					return options.foreignComp;
				case 'source': // todo: Konstante
					if (!options?.sourceComp) {
						throw new Error('todo');
					}
					return options.sourceComp;
				default:
					throw new Error('todo');
			}
		}
		else {
			let compId: string;
			if (this._astToken.text.startsWith('[')) {
				let pos = this._astToken.text.indexOf(']');
				if (pos === -1) {
					throw new Error('todo');	
				}
				let relation = this._astToken.text.substring(1, pos);
				compId = `${relation}.${this._astToken.text.substring(pos + 1)}`;
			}
			else {
				compId = this._astToken.text
			}

			let comp = this._ctx.formHandler.getComponent(compId);
			if (!comp) {
				throw new Error('todo');
			}
			return comp;
		}
	}
}

