import { Directive, Input, TemplateRef } from '@angular/core';
import { UIAbstractDirective } from './ui-abstract.directive';

@Directive({
	selector: '[otrisComponentGroupNoRelationObject]'
})
export class ComponentGroupNoRelationObjectDirective extends UIAbstractDirective {
	@Input('otrisComponentGroupNoRelationObject') _id: string;

	get id(): string {
		return this._id;
	}

	constructor(template: TemplateRef<any>) {
		super(template);
	}
}
