import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import {
	PDComponent, RadioButtonWidgetInfo, UIRadioButtonGroupSpec, PDRadioButtonSpec,
	FormHandlerService,
	EventProcessingContextManagerService,
	LocalizationServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET, AbstractEnumeration, IEnumerationItem, ICssStyle, IRadioButtonComponent,
	ItemTypeET, RadioButtonGroupDataTypeET, RadioButtonBulletLocationET, UIStateProvider, IUIStateProviderRegistration, IComponent,
	IPanelComponent,
	ILocalizationService,
	LanguageCodeET
} from '@otris/ng-core-types';
import { PDRadioButtonGroupService } from '../../services/pd-radio-button-group.service';

// checked
// (change)="onChange($event)"
@Component({
	selector: 'otris-pd-radio-button',
	template: `
		@if (!isReadonly) {
			@if (radioButtonSpec?.content) {
				<div class="content-container">
					<input type="radio"
						[name]="radioButtonGroup"
						[value]="radioButtonValue"
						kendoRadioButton
						[formControl]="formControl"
						(change)="onChange($event)"
						[id]="radioButtonId"
					/>
					<label 
						class="k-radio-label custom-input-label" 
						[ngStyle]="radioStyle"
						[for]="radioButtonId"
					></label>
					<otris-pd-panel 
						#panel
						[uiItemSpec]="radioButtonSpec.content"
						[formGroup]="formGroup"
						[pdObject]="pdObject"
						[relationContext]="relationContext"
						[idPostfix]="'radioButton'"
					/>
				</div>
			} @else {
				<div class="content-container">
					<input type="radio"
						[name]="radioButtonGroup"
						[value]="radioButtonValue"
						kendoRadioButton
						(change)="onChange($event)"
						[formControl]="formControl"
						[id]="radioButtonId"
					/>
					<label class="k-radio-label label" [for]="radioButtonId">{{label}}</label>
				</div>
			}
		} @else {
			@if (value === true) {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		}
	`,
	styles: [`
		.content-container {
			display: flex;
		}
		.custom-input-label {
			padding: 0 0 0 0.5em;
		}
		.label {
			margin: auto 0 auto 0.5em;
		}
	`]
})
export class PDRadioButtonComponent extends PDComponent implements IRadioButtonComponent {

	@ViewChild('panel') set panelComponent(comp: IPanelComponent) {
		if (comp != this._panelComponent) {
			this._panelComponent = comp;
			if (this._panelComponent) {
				this.createChildComponentUIStateProviders([this._panelComponent]);
			}
		}
	}

	get panelComponent(): IPanelComponent {
		return this._panelComponent;
	}

	private _panelComponent: IPanelComponent;


	private static counter: number = 0;

	private _radioButtonId: string;

	radioButtonGroup: string;

	/*private _rbLabel: string;

	get rbLabel(): string {
		return this._rbLabel;
	}*/

	get bulletLocation(): RadioButtonBulletLocationET {
		let widgetInfo = this.radioButtonWidgetInfo;
		if (widgetInfo && widgetInfo.bulletLocation !== undefined) {
			return widgetInfo.bulletLocation;
		}
		return RadioButtonBulletLocationET.Center;
	}

	private _radioButtonValue: IEnumerationItem | boolean | string;

	get radioButtonValue(): IEnumerationItem | boolean | string {
		return this._radioButtonValue;
	}

	set radioButtonValue(val: IEnumerationItem | boolean | string) {
		if (this._radioButtonValue !== val) {
			this._radioButtonValue = val;
			this.updateChecked();
		}
	}

	get radioStyle(): ICssStyle {
		let sAlign = 'center';
		switch (this.bulletLocation) {
			case RadioButtonBulletLocationET.Start:
				sAlign = 'flex-start';
				break;
			case RadioButtonBulletLocationET.Center:
				sAlign = 'center';
				break;
			case RadioButtonBulletLocationET.End:
				sAlign = 'flex-end';
				break;
		}
		return <ICssStyle>{
			'align-self': sAlign
		}
	};

	private get radioButtonGroupSpec(): UIRadioButtonGroupSpec {
		let groupSpec = <UIRadioButtonGroupSpec>this.uiItemSpec.getAncestorOfType(ItemTypeET.UIRadioButtonGroup);
		if (!groupSpec) {
			throw new Error('No radio button group spec found');
		}
		return groupSpec;
	}

	get isContainerComponent(): boolean {
		return false;
	}

	protected override get defaultValue(): IEnumerationItem | boolean | string | undefined {
		if (this.pdItemSpec.defaultValue === true) {
			return this.radioButtonValue;
		}
		return undefined;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private radioButtonGroupService: PDRadioButtonGroupService,
		eventProcessingContextManagerService: EventProcessingContextManagerService,
		@Inject(LocalizationServiceToken) private _localizationService: ILocalizationService, private cdref: ChangeDetectorRef) {
		super(router, route, formHandler, eventProcessingContextManagerService);
		this._radioButtonId = "pdRadioButton_" + PDRadioButtonComponent.counter.toString();
		PDRadioButtonComponent.counter++;
		radioButtonGroupService.registerRadioButton(this);
	}

	ngOnInit() {
		this.radioButtonGroup = this.radioButtonGroupService.groupName;
		let rbWidgetInfo = this.radioButtonWidgetInfo;
		let rbGroupSpec = this.radioButtonGroupSpec;
		//if (rbGroupSpec && rbWidgetInfo) {
			switch (rbGroupSpec.dataType) {
				case RadioButtonGroupDataTypeET.Enum: {
					if (!(typeof(rbWidgetInfo.value) === 'number' || typeof(rbWidgetInfo.value) === 'string')) {
						throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
					}
					let obj = this.pdObject.pdObjectRaw[rbGroupSpec.property + "Object"];
					if (obj instanceof AbstractEnumeration) {
						let abstrEnum = <AbstractEnumeration>obj;
						let enumItem = abstrEnum.getItem(rbWidgetInfo.value);
						this.radioButtonValue = enumItem;
					}
					break;
				}

				case RadioButtonGroupDataTypeET.Boolean:
					if (typeof(rbWidgetInfo.value) !== 'boolean') {
						throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
					}
					this.radioButtonValue = rbWidgetInfo.value;
					break;

				case RadioButtonGroupDataTypeET.String:
					if (typeof(rbWidgetInfo.value) !== 'string') {
						throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
					}
					this.radioButtonValue = rbWidgetInfo.value;
			}
		//}
		//this.updateRadioButtonValue();
		super.ngOnInit();
		this.onLanguageChanged();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	get radioButtonId(): string {
		return this._radioButtonId;
	}

	onChange(evt: any) {
		if (evt.target.checked) {
			this.checked = true;
		}
	}

	/*private updateRadioButtonValue(): void {
		let rbWidgetInfo = this.radioButtonWidgetInfo;
		let rbGroupSpec: UIRadioButtonGroupSpec = this.radioButtonGroupSpec;
		switch (rbGroupSpec.dataType) {
			case RadioButtonGroupDataTypeET.Enum: {
				if (!(typeof(rbWidgetInfo.value) === 'number' || typeof(rbWidgetInfo.value) === 'string')) {
					throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
				}
				let obj = this.pdObject.pdObjectRaw[rbGroupSpec.property + "Object"];
				if (obj instanceof AbstractEnumeration) {
					let abstrEnum = <AbstractEnumeration>obj;
					let enumItem = abstrEnum.getItem(rbWidgetInfo.value);
					this.radioButtonValue = enumItem;
				}
				break;
			}

			case RadioButtonGroupDataTypeET.Boolean:
				if (typeof(rbWidgetInfo.value) !== 'boolean') {
					throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
				}
				this.radioButtonValue = rbWidgetInfo.value;
				break;

			case RadioButtonGroupDataTypeET.String:
				if (typeof(rbWidgetInfo.value) !== 'string') {
					throw new Error(`Invalid value type '${typeof(rbWidgetInfo.value)}' in widget info.`);
				}
				this.radioButtonValue = rbWidgetInfo.value;
		}
	}*/

	private updateChecked(): void {
		let ctrl = this.control;
		if (this.radioButtonValue !== undefined && ctrl?.value !== null && ctrl?.value !== undefined) {
			//if (typeof(this.radioButtonValue) === 'string' || typeof(this.radioButtonValue) === 'boolean') {
				this.checked = ctrl.value === this.radioButtonValue;
			/*}
			else {
				this.checked = (ctrl.value as IEnumerationItem).enumConst === this.radioButtonValue.enumConst;
			}*/
		}
		else {
			this.checked = false;
		}
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		//this.checked = val === this.radioButtonValue;
		this.updateChecked();
	}

	protected setControlValue(value: any): void {
		if (value === undefined || value === null) {
			super.setControlValue(undefined);
			return;
		}
		if (this.radioButtonGroupSpec.dataType === RadioButtonGroupDataTypeET.Enum) {
			if ((this.radioButtonValue as IEnumerationItem).enumConst === (value as IEnumerationItem).enumConst) {
				super.setControlValue(this.radioButtonValue);
				return;
			}
		}
		else if (this.radioButtonValue === value) {
			super.setControlValue(value);
		}
		/*let ctrl = this.control;
		if (ctrl && ctrl.value !== value) {
			ctrl.setValue(value);
		}*/
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		// TEST
		//this.updateRadioButtonValue();
		this.updateChecked();
		/*let ctrl = this.control;
		if (ctrl && ctrl.value !== null && ctrl.value !== undefined) {
			this.updateChecked();
			//this.checked = ctrl.value === this.radioButtonValue;
		}*/
	}

	protected updateLabelImpl(): Observable<string> {
		let rbGroupSpec: UIRadioButtonGroupSpec = this.radioButtonGroupSpec;
		let rbWidgetInfo = this.radioButtonWidgetInfo;
		if (!rbGroupSpec || !rbWidgetInfo) {
			super.updateLabelImpl();
		}

		if (!this.customLabel && !this.customLabelId) {
			switch (rbGroupSpec.dataType) {
				case RadioButtonGroupDataTypeET.Enum:
					if (typeof(rbWidgetInfo.value) === 'number') {
						let obj = this.pdObject.pdObjectRaw[rbGroupSpec.property + "Object"];
						if (obj instanceof AbstractEnumeration) {
							let abstrEnum = <AbstractEnumeration>obj;
							return this.metaDataService.getEnumLiteralErgName(abstrEnum.name, rbWidgetInfo.value);
						}
					}
					break;

				case RadioButtonGroupDataTypeET.Boolean:
					if (typeof(rbWidgetInfo.value) === 'boolean') {
						return of(rbWidgetInfo.value ? 'true' : 'false');
					}
					break;

				case RadioButtonGroupDataTypeET.String:
					if (typeof(rbWidgetInfo.value) === 'string') {
						return of(rbWidgetInfo.value);
					}
					break;
			}
		}
		return super.updateLabelImpl();
	}

	public getId(): string | undefined {
		if (this.pdItemSpec.property && this.radioButtonValue !== undefined) {
			let sVal = this.valueAsString;
			if (!sVal) {
				return undefined;
			}
			let prop = this.pdItemSpec.property;
			if (this.propertyRelationPath) {
				prop = `${this.propertyRelationPath}.${prop}`;
			}
			return this.getIdFromPropertyName(prop) + '.' + sVal;
		}
		return super.getId();
	}

	get radioButtonSpec(): PDRadioButtonSpec {
		return this.uiItemSpec instanceof PDRadioButtonSpec ? <PDRadioButtonSpec>this.uiItemSpec : undefined;
	}

	get radioButtonWidgetInfo(): RadioButtonWidgetInfo {
		if (this.uiItemSpec.widgetInfo instanceof RadioButtonWidgetInfo) {
			return <RadioButtonWidgetInfo>(this.uiItemSpec.widgetInfo);
		}
		throw new Error('No widget info defined for radio button.');
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RadioButton;
	}

	reset(): void {
		super.reset();
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}

	//----------------------------------------------------------------------------------------------
	// IRadioButtonComponent
	//----------------------------------------------------------------------------------------------

	private _checkedChanged: Subject<IRadioButtonComponent> = new Subject();

	get checkedChanged(): Observable<IRadioButtonComponent> {
		return this._checkedChanged.asObservable();
	}

	get value(): IEnumerationItem | boolean | string {
		return this.radioButtonValue;
	}

	get valueAsString(): string | undefined {
		if (this.radioButtonValue === undefined) {
			return undefined;
		}
		let rbGroupSpec = this.radioButtonGroupSpec;
		if (rbGroupSpec) {
			switch (rbGroupSpec.dataType) {
				case RadioButtonGroupDataTypeET.Enum:
					return (this.radioButtonValue as IEnumerationItem).enumConst.toString();
				case RadioButtonGroupDataTypeET.Boolean:
					return this.radioButtonValue.toString();
				case RadioButtonGroupDataTypeET.String:
					return this.radioButtonValue as string;
			}
		}
		return undefined;
	}

	get checked(): boolean {
		return this._checked;
	}

	set checked(flag: boolean) {
		if (flag !== this._checked) {
			this._checked = flag;
			let ctrl = this.control;
			if (ctrl) {
				if (flag) {
					ctrl.setValue(this.radioButtonValue);
				}
				else if (ctrl.value === this.radioButtonValue) {
					ctrl.setValue(undefined);
				}
			}
			this._checkedChanged.next(this);
		}
	}

	private _checked: boolean = false;

	// todo: muss noch getestet werden. Wird das noch gebraucht? Ist doch dasselbe wie checked?
	check(): void {
		let ctrl = this.control;
		if (ctrl) {
			ctrl.setValue(this.radioButtonValue); // todo: auch bei checked
			this.checked = true;
			//ctrl.setValue(this.pdObject.pdObjectRaw[this.propertyName]);
		}
	}
}
