import {
	Component, OnInit, AfterViewInit, AfterContentInit, OnDestroy, Input, Inject,
	ElementRef, ViewChild, ContentChild, ChangeDetectorRef, Output, EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
	SelectableSettings, SelectionEvent, GridDataResult, PageChangeEvent,
	GridComponent, DetailTemplateDirective, RowArgs, SortSettings, PagerSettings
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription, Observable } from "rxjs";
import {filter, switchMap, tap} from "rxjs/operators";
import {
	IPDObjectRaw, SelectionModeET, TypeET, IPDAccessService, IToolBarItemResult, IPDListColumnInfo, IToolBarItemSpec, IToolBarButtonSpec, ToolBarItemTypeET, LanguageCodeET
} from '@otris/ng-core-types';
import {
	PDObject,
	IPDAccessServiceToken,
	IErrorHandler,
	IErrorHandlerToken,
	IError
} from '@otris/ng-core-shared';
import { IPDRootColumnProviderToken, IPDColumnProviderService, LocalizationServiceToken, LocalizationService } from '@otris/ng-core';
//import * as ngCoreTypesModule from '@otris/ng-core-types'
/*enum ToolBarButtonIdET {
	New = 'idNew',
	Edit = 'idEdit',
	Delete = 'idDelete',
	Refresh = "idRefresh"
}*/

export enum ListTypeET {
	List,
	SelectionList
}

export enum ListDataSourceET {
	Query,
	ObjectArray
}

/*enum ItemViewTypeET {
	Embedded,
	Popup
}*/

/*export interface INewActionSpec {
	outlet: string,
	url: string
}*/

export enum PDListToolBarButtonId {
	//New = 'idNew',
	//View = 'idView',
	//Edit = 'idEdit',
	//Delete = 'idDelete',
	Refresh = 'idRefresh'
	//ConfigureColumns = 'idConfigureColumns',
	//Export = 'idExport'
}

export type PDListToolBarConfig = {
	//editButton?: boolean;
	//deleteButton?: boolean;
	refreshButton?: boolean;
	//configureColumnsButton?: boolean;
	//exportButton?: boolean;
}

export class PDListToolBarButtonClickEvent {

	private _cancel = false;

	get cancel(): boolean {
		return this._cancel;
	}

	set cancel(val: boolean) {
		this._cancel = val;
	}

	get result(): IToolBarItemResult {
		return this._result;
	}

	constructor(private _result: IToolBarItemResult) {}
}

@Component({
	selector: 'otris-pd-list',
	templateUrl: './pd-list.component.html',
	styles: [`
		:host {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto; /* multiselect fix */
			/*min-width: min-content;*/
			/*max-width: max-content;*/ /* #42068 */
		}

		/*.root-splitter {
			display: flex;
		}*/
		/*.pd-list-container {
			height: 100%;
			display: grid;
			grid-template-columns: minmax(100px, 1fr) max-content;
			grid-template-rows: minmax(100px, 1fr);
		}
		.list-area {
			grid-column: 1;
		}
		.details-area {
			grid-column: 2;
		}*/
	`],
	providers: [
	]
})
export class PDListComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

	@Input() className: string;

	@Input() columnInfos: IPDListColumnInfo[];

	@Input() listType: ListTypeET = ListTypeET.List;

	@Input() showSearchControl: boolean = true;

	@Input() selectionMode: SelectionModeET = SelectionModeET.Single;

	@Input() staticFilterExpr: string;

	@Input() previewPanelDefaultSize: string = '35%';

	//@Input() objects$: Observable<PDObject[]>;

	@Input()
	set objects$(val: Observable<PDObject[]>) {
		if (this._objects$ != val) {
			this._objects$ = val;
			this.loadData(true);
		}
	}

	@Input() autoSize = false;

	private _objects$: Observable<PDObject[]>;

	private _objects: PDObject[];

	private _filteredObjects: PDObject[] = [];

	private _selectedDataMap: Map<string, PDObject> = new Map<string, PDObject>();

	get sortExpr(): string {
		return this._sortExpr;
	}

	@Input()
	set sortExpr(val: string) {
		this._sortExpr = val;
		this.sortDescriptors = [];
		if (!val) {
			return;
		}
		for (let expr of val.split(',')) {
			if (expr.length == 0)
				continue;
			let firstChar = expr.charAt(0);
			let descriptor = <SortDescriptor>{};
			descriptor.dir = firstChar == '-' ? 'desc' : 'asc';
			descriptor.field = firstChar == '-' || firstChar == '+' ? expr.substr(1) : expr;
			this.sortDescriptors.push(descriptor);
		}
	}

	private _sortExpr: string;

	@Input() toolbarConfig: PDListToolBarConfig;

	@Input() resetOnRefresh = true;

	@Input() preSelectedObjects: PDObject[];

	@Input() preSelectedObject: PDObject;

	@Output() toolBarButtonClick = new EventEmitter<PDListToolBarButtonClickEvent>();

	@ViewChild('kendoGrid') gridViewChild: GridComponent;

	@ContentChild(DetailTemplateDirective) detailTemplateDirective: DetailTemplateDirective;

	listeTypeET = ListTypeET;

	get selectedObject(): PDObject | undefined {
		return this.selectionMode == SelectionModeET.Single ? this.selection as PDObject : undefined;
		//return this.selectionMode == SelectionModeET.Single && this._selection.length == 1 ? this.data.get(this._selection[0]) : undefined;
	}

	get selectedObjects(): PDObject[] {
		let res = this.selection;
		return Array.isArray(res) ? res : [];
		//return this.selectionMode == SelectionModeET.Multiple ? this._selection.map(oid => this.data.get(oid)) : [];
	}

	get selection(): PDObject | PDObject[] | undefined {
		switch (this.selectionMode) {
			case SelectionModeET.Single:
				//return this._selection.length == 1 ? this.data.get(this._selection[0]) : undefined;
				return this._selectedDataMap.size == 1 ? this._selectedDataMap.get(this._selection[0]) : undefined;
			case SelectionModeET.Multiple:
				//return this._selection.map(oid => this.data.get(oid))
				return Array.from(this._selectedDataMap.values());
		}
		return undefined;
	}

	protected _selection: string[] = []; // todo: IPDObject oder so wg. OID

	protected data: Map<string, PDObject> = new Map<string, PDObject>();

	get selectionSettings(): SelectableSettings {
		return <SelectableSettings>{
			checkboxOnly: this.selectionMode == SelectionModeET.Multiple,
			mode: this.selectionMode == SelectionModeET.Single ? 'single' : 'multiple'
		};
	}

	protected selectionKeySelector(context: RowArgs): any {
		return context.dataItem.oid;
	}

	//private readonly itemViewTypeParam = "itemViewType";

	//private readonly listIdParam = "PDListComponent-Id";

	private static listCounter: number = 0;

	private readonly listId: number = ++PDListComponent.listCounter;

	dataResult: GridDataResult;

	loading: boolean = false;

	pageSize: number = 100;

	private pageIndex: number = 0;

	skip: number = 0;

	sortDescriptors: SortDescriptor[] = [];

	private queryFilterExpr: string;

	private fullTextSearchExpr: string;

	private subscription: Subscription;

	typeET = TypeET;

	selectionModeET = SelectionModeET;

	toolbarItems: IToolBarItemSpec[] = [];

	//private rootUrl: string;

	previewPanelSize: string = "0";

	previewPanelMaxSize: string = "0%";

	previewPaneResizable: boolean = false;

	//private hasPreviewObject: boolean = false;

	listDataSourceET = ListDataSourceET;

	//dataSource: ListDataSourceET = ListDataSourceET.Query;
	private get dataSource(): ListDataSourceET {
		return this._objects$ ? ListDataSourceET.ObjectArray : ListDataSourceET.Query;
	}

		// todo: konfigurierbar machen
	get sortSettings(): SortSettings | boolean {
		switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <SortSettings>{ allowUnsort: true, mode: 'multiple', showIndexes: true };
			default:
				return <SortSettings>{ allowUnsort: true, mode: 'single' };
		}
		/*switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <SortSettings>{ allowUnsort: true, mode: 'multiple', showIndexes: true };
			default:
				return false;
		}*/
	}

	// todo: konfigurierbar machen
	get pagerSettings(): PagerSettings | boolean {
		return <PagerSettings>{
			buttonCount: 5,
			info: true
		};
		/*switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <PagerSettings>{ buttonCount: 5 };
			default:
				return false;
		}*/
	}

	pagerOfMessage = "of";
	pagerItemsMessage = "items";
	noRecordsMessage = "No records available.";

	getColHeader(colInfo: IPDListColumnInfo): string {
		if (typeof(colInfo.header) === 'string') {
			return colInfo.header;
		}
		switch (this.localizationService.currentLanguage.code) {
			case LanguageCodeET.de:
				return colInfo.header.de;
			case LanguageCodeET.en:
				return colInfo.header.en;
		}
		return "n.a.";
	}

	constructor(private router: Router, private route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		//@Inject(IPDListColumnProviderToken) private columnInfoProvider: IPDListColumnProvider,
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler,
		@Inject(IPDRootColumnProviderToken) private columnProvider: IPDColumnProviderService,
		@Inject(LocalizationServiceToken) private localizationService: LocalizationService,
		private cdRef: ChangeDetectorRef) {

		/*let rootTree = this.router.parseUrl(route.snapshot.pathFromRoot
			.map(v => v.url.map(segment => segment.toString()).join('/'))
			.join('/'));*/
		//this.rootUrl = router.routerState.snapshot.url;
		this.subscription = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
				let pos1 = event.url.indexOf('/view/');
				let oid;
				if (pos1 > 0) {
					oid = event.url.substr(pos1 + 6);
					/*let pos2 = event.url.indexOf('/', pos1 + 6);
					if (pos2 > 0) {
						oid = event.url.substring(pos1 + 6, pos2);
					}*/
				}

				if (oid) { // todo: ggf. pathFromRoot mit einbeziehen!
					//this.hasPreviewObject = true;
					this.previewPanelMaxSize = "80%"; // todo
					this.previewPanelSize = this.previewPanelDefaultSize;
					this.previewPaneResizable = true;
					this._selection = [oid];
				}
				else {
					//this.hasPreviewObject = false;
					this.previewPaneResizable = false;
					this.previewPanelSize = "0";
					this._selection = [];
				}
				//
				// Preview Panel ggf. schliesen
				//
				//let rootTree = this.router.parseUrl(this.rootUrl);
				/*let currentTree = this.router.parseUrl(event.url);
				let rootUrlSegments = UrlTreeAnalyzer.determineUrlSegments(rootTree);
				let currentUrlSegments = UrlTreeAnalyzer.determineUrlSegments(currentTree);

				if (rootUrlSegments.length == currentUrlSegments.length) {
					// Test
					//this.previewPaneResizable = false;
					//this.previewPanelSize = "0";
				}*/

				/*let newUrl = event.url;
				// URL anpassen wegen Klammer-Bug bei angular auxiliary routes
				if (!newUrl.includes('//') && newUrl.includes('(')) {
					newUrl = newUrl.replace('(', '');
					newUrl = newUrl.replace(')', '');
				}
				let rootUrlAdapted = this.rootUrl;
				if (!rootUrlAdapted.includes('//') && rootUrlAdapted.includes('(')) {
					rootUrlAdapted = rootUrlAdapted.replace('(', '');
					rootUrlAdapted = rootUrlAdapted.replace(')', '');
				}
				if (newUrl == rootUrlAdapted) {
					this.previewPaneResizable = false;
					this.previewPanelSize = "0";
				}*/
			});

		/*this.subscription.add(
			this.childComponentCloser.getCloseHandler(this.listIdParam, this.listId.toString()).subscribe(
				params => {
					switch (+params.params[this.itemViewTypeParam]) {
						case ItemViewTypeET.Popup:
							{
								let outlets = new Object();
								outlets[this.newActionSpec.outlet] = null;
								if (params.result && params.result.success && params.result.isNew) {
									let msg = this.hasPreviewObject ?
										'Soll der Datensatz zur weiteren Bearbeitung angezeigt und der aktuell bearbeitete Datensatz geschlossen werden?' :
										'Soll der Datensatz zur weiteren Bearbeitung angezeigt werden?';
									this.interactionService.showConfirmMessage("Datensatz bearbeiten", msg)
										.subscribe(res => {
											if (res === DialogResultET.Ok) {
												this.pdAccessService.getObject<T>(params.result.realObjectId).subscribe(res => this.edit(res));
											}
										});
								}
								router.navigate([{ outlets: outlets }], { relativeTo: this.route.parent });
								return;
							}
						case ItemViewTypeET.Embedded:
							this.hasPreviewObject = false;
							this.router.navigate(["."], { relativeTo: params.childRoute.parent.parent });
							return;
					}
				}
			)
		);*/
	}

	ngOnInit() {
		if (this.staticFilterExpr) {
			this.queryFilterExpr = this.staticFilterExpr;
		}
		if (!this.columnInfos) {
			this.columnInfos = this.columnProvider.getColumns(this.className);
		}
		this.loadData(true);

		if (this.toolbarConfig && this.toolbarConfig.refreshButton === true) {
			this.toolbarItems.push(
				 <IToolBarButtonSpec>{
					id: PDListToolBarButtonId.Refresh,
					type: ToolBarItemTypeET.Button,
					iconClass: 'fa fa-lg fa-fw fa-refresh',
					shortDescriptionId: 'system.kendo-ui.components.pd-list.tool-bar-button-refresh',
				}
			);
	  }

		this.localizationService.changeHandler.pipe(
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-list.pager-of-message',
				'kendo-ui.components.pd-list.pager-items-message',
				'kendo-ui.components.pd-list.no-records-message'
			])),
			tap(trans => {
				this.pagerOfMessage = trans[0],
				this.pagerItemsMessage = trans[1],
				this.noRecordsMessage = trans[2]
			})
		).subscribe();

		if (this.listType == ListTypeET.SelectionList) {
			switch (this.selectionMode) {
				case SelectionModeET.Multiple:
					if (this.preSelectedObjects) {
						this.preSelectedObjects.forEach(obj => {
							if (!this._selection.includes(obj.oid)) {
								!this._selection.push(obj.oid);
							}
							if (!this._selectedDataMap.has(obj.oid)) {
								this._selectedDataMap.set(obj.oid, obj);
							}
						});
					}
					break;
				
				case SelectionModeET.Single:
					if (this.preSelectedObject) {
						this._selection.splice(0, Infinity, this.preSelectedObject.oid);
						if (!this._selectedDataMap.has(this.preSelectedObject.oid)) {
							this._selectedDataMap.clear();
							this._selectedDataMap.set(this.preSelectedObject.oid, this.preSelectedObject);
						}
					}
					break;
			}
		}
	}

	ngAfterViewInit() {
		if (this.detailTemplateDirective) {
			// todo: muss anders gelöst werden!
			//this.gridViewChild.detailTemplate = this.detailTemplateDirective;
			this.cdRef.detectChanges();
		}
	}

	ngAfterContentInit() {
	}

	ngOnDestroy() {
		console.log("PDListComponent.ngOnDestroy()");
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	autoFitColumns(): void {
		this.gridViewChild.autoFitColumns();
	}

	private loadData(reset: boolean): void {
		if (reset) {
			this.pageIndex = 0;
			this.skip = 0;
		}
		switch (this.dataSource) {
			case ListDataSourceET.Query:
				this.loadDataByQuery();
				break;

			case ListDataSourceET.ObjectArray:
				{
					if (this._objects$) {
						let selectPage: () => void =
							() => {
								let start = this.pageIndex * this.pageSize;
								let end = start + this.pageSize;
								let objs: PDObject[] = [];
								if (start < this._filteredObjects.length) {
									if (end > this._filteredObjects.length) {
										end = this._filteredObjects.length;
									}
									objs = this._filteredObjects.slice(start, end);
								}
								this.data = objs.reduce(
									(map, obj) => {
										map.set(obj.objectId.oid, obj);
										return map;
									}, new Map<string, PDObject>());
								this.dataResult = { data: objs.map(d => d.pdObjectRaw), total: this._filteredObjects.length };
							};

						if (reset || !this._objects) {
							this.loading = true;
							this._objects$.subscribe(
								res => {
									this._objects = res;
									this.updateFilteredObjects();
									this.sortFilteredObjects();
									selectPage();
								},
								() => { this.loading = false; },
								() => { this.loading = false; }
							);
						}
						else {
							selectPage();
						}
					}
					break;
				}
		}
		/*if (this.listType == ListTypeET.SelectionList && this.preSelectedOids) {
			this.preSelectedOids.forEach(oid => {
				if (!this._selection.includes(oid)) {
					!this._selection.push(oid);
				}
				if (!this._selectedDataMap.has(oid) && this.data.has(oid)) {
					this._selectedDataMap.set(oid, this.data.get(oid));	
				}
			});
		}*/
	}

	private updateFilteredObjects(): void {
		if (this.dataSource !== ListDataSourceET.ObjectArray) {
			return;
		}

		if (this.fullTextSearchExpr && this.columnInfos && this.columnInfos.length > 0) {
			let stringColInfos = this.columnInfos.filter(ci => ci.type === TypeET.String && ci.fullTextSearchRelevant !== false);
			let searchExpr = this.fullTextSearchExpr.toLowerCase();
			this._filteredObjects = this._objects.filter(obj => {
				for (let colInfo of stringColInfos) {
					if ((obj.pdObjectRaw[colInfo.field] as string).toLowerCase().includes(searchExpr))	{
						return true;
					}
				}
				return false;
			});
		}
		else {
			this._filteredObjects = [...this._objects];
		}
		//this.sortFilteredObjects();
	}

	private sortFilteredObjects(): void {
		if (this.dataSource !== ListDataSourceET.ObjectArray) {
			return;
		}
		if (this.sortDescriptors) {
			let sortDescs = this.sortDescriptors.filter(s => s.dir);
			if (sortDescs.length > 0) {
				let sortDesc = sortDescs[0];
				this._filteredObjects.sort((obj1, obj2) => {
					if (obj1.pdObjectRaw[sortDesc.field] === obj2.pdObjectRaw[sortDesc.field]) {
						return 0;
					}
					if (sortDesc.dir === 'asc') {
						return obj1.pdObjectRaw[sortDesc.field] < obj2.pdObjectRaw[sortDesc.field] ? -1 : 1;
					}
					else {
						return obj1.pdObjectRaw[sortDesc.field] < obj2.pdObjectRaw[sortDesc.field] ? 1 : -1;
					}
				});
				return;
			}
		}
		this.updateFilteredObjects();
	}

	private loadDataByQuery(): void {
		this.loading = true;
		const sub = this.pdAccessService.getExtentByPage(this.className, this.pageIndex, this.pageSize, false, this.queryFilterExpr,
			this.sortExpr, this.columnInfos.map(ci => ci.field))
			.subscribe(
				res => {
					this.dataResult = { data: res.data.map(d => d.pdObjectRaw), total: res.itemCount };
					this.data = res.data.reduce((map, obj) => {
						map.set(obj.objectId.oid, obj as PDObject);
						return map;
					}, new Map<string, PDObject>());
					this.loading = false;
				},
				err => {
					this.errorHandler.handleError(<IError>{ details: `Error in PDListComponent.loadDataByQuery(). Details: ${err}` } );
					this.loading = false;
				}
			);
	}

	/*private updateFilterExpr() {
		let newFilter: string = undefined;
		if (expr) {
			expr = expr.trim();
			if (expr.length == 0) {
				newFilter = undefined;
			}
			else if (this.columnInfos && this.columnInfos.length > 0) {
				newFilter = "";
				for (let colInfo of this.columnInfos.filter(ci => ci.type == TypeET.String)) {
					if (newFilter.length > 0) {
						newFilter += " || ";
					}
					//let attrName = this.pdMeta.getAttributeNameFromTSProperty(colInfo.field);
					let attrName = colInfo.field;
					newFilter += attrName + "=" + "'*" + expr + "*'";
				}
			}
		}

		if (newFilter != this.filterExpr) {
			console.log(`new filter: ${newFilter}`);
			this.filterExpr = newFilter;
			this.loadData(true);
		}
	}*/

	onPageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.pageIndex = event.skip / this.pageSize;
		this.loadData(false);
	}

	onSortChange(sort: SortDescriptor[]): void {
		this.sortDescriptors = sort;
		if (this.dataSource == ListDataSourceET.ObjectArray) {
			this.sortFilteredObjects();
			this.loadData(true);
			return;
		}

		this._sortExpr = "";
		if (sort) {
			for (let expr of sort.filter(s => s.dir)) {
				if (this._sortExpr.length > 0) {
					this._sortExpr += ',';
				}
				this._sortExpr += expr.dir == 'asc' ? '+' : '-';
				//this.sortExpr += this.pdMeta.getAttributeNameFromTSProperty(expr.field);
				this._sortExpr += expr.field;
			}
		}
		this.loadData(true);
	}

	onSearchExpressionChanged(expr: string) {
		if (expr) {
			expr = expr.trim();
		}
		this.fullTextSearchExpr = expr ? expr : undefined;

		if (this.dataSource == ListDataSourceET.ObjectArray) {
			this.updateFilteredObjects();
			this.sortFilteredObjects();
			this.loadData(true);
			return;
		}

		let newFilter: string = undefined;
		if (expr) {
			if (expr.length == 0) {
				newFilter = undefined;
			}
			else if (this.columnInfos && this.columnInfos.length > 0) {
				newFilter = "";
				for (let colInfo of this.columnInfos.filter(ci => ci.type === TypeET.String && ci.fullTextSearchRelevant !== false)) {
					if (newFilter.length > 0) {
						newFilter += " || ";
					}
					//let attrName = this.pdMeta.getAttributeNameFromTSProperty(colInfo.field);
					let attrName = colInfo.field;
					newFilter += attrName + "=" + "'*" + expr + "*'";
				}
			}
		}

		if (this.staticFilterExpr) {
			newFilter = newFilter ? '(' + this.staticFilterExpr + ') && (' + newFilter + ')' : this.staticFilterExpr;
		}

		if (newFilter != this.queryFilterExpr) {
			this.queryFilterExpr = newFilter;
			this.loadData(true);
		}
	}

	private showDetails(objectId: string | undefined) {
		/*this.hasPreviewObject = !!objectId;
		this.previewPanelMaxSize = "80%";
		this.previewPanelSize = objectId ? this.previewPanelDefaultSize : '0';
		this.previewPaneResizable = !!objectId;*/

		/*let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Embedded;
		queryParams[this.listIdParam] = this.listId.toString();
		if (this.objCreator.name != this._baseObjCreator.name) {
			queryParams[PDClassNameQueryParamName] = this.objCreator.name;
		}*/
		let path = objectId ? ['view', objectId] : ['.'];
		this.router.navigate(path, { relativeTo: this.route });
	}

	/*private edit(obj: T) {
		this.hasPreviewObject = true;
		this.previewPanelMaxSize = "80%";
		this.previewPanelSize = "50%";
		this.previewPaneResizable = true;
		let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Embedded;
		queryParams[this.listIdParam] = this.listId.toString();
		this.router.navigate(["edit", obj.id], { relativeTo: this.route, queryParams: queryParams });
	}*/

	/*private createNew(className: string | undefined) {
		let outlets = new Object();
		outlets[this.newActionSpec.outlet] = [this.newActionSpec.url];

		let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Popup;
		queryParams[this.listIdParam] = this.listId.toString();
		if (className) {
			queryParams[GlobalQueryParamsET.PDObjectResolver_ClassName] = className;
		}
		this.router.navigate([{ outlets: outlets }], { relativeTo: this.route.parent, queryParams: queryParams });
	}*/

	refresh() {
		this.loadData(this.resetOnRefresh);
	}

	onToolBarButtonClick(result: IToolBarItemResult): void {

		let args = new PDListToolBarButtonClickEvent(result);
		this.toolBarButtonClick.emit(args);
		if (args.cancel) {
			return;
		}

		switch (result.id) {
			/*case ToolBarButtonIdET.Edit:
				if (this.selectedObject) {
					this.edit(this.selectedObject);
				}
				break;
			case ToolBarButtonIdET.New:
				this.createNew(item.tag);
				break;*/
			case PDListToolBarButtonId.Refresh:
				this.refresh();
				break;
		}
	}

	onSelectionChange(e: SelectionEvent) {
		if (this.listType == ListTypeET.SelectionList) {

			if (e.selectedRows.length >= 0) {
				e.selectedRows.forEach(selection => {
					this._selectedDataMap.set(selection.dataItem.oid, this.data.get(selection.dataItem.oid));
				});
			}
			if (e.deselectedRows.length >= 0) {
				e.deselectedRows.forEach(selection => {
					this._selectedDataMap.delete(selection.dataItem.oid);
				})
			}
		}
	}
}
