import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PDLabeledControlComponent, FormHandlerService, 
	EventProcessingContextManagerService } from '@otris/ng-core';
import { ComponentTypeET } from '@otris/ng-core-types';


@Component({
	selector: 'otris-pd-auto-complete',
	template: `
		<otris-pd-labeled-control-frame 
			[labeledControl]="this" 
			(toolBarButtonClick)="onToolBarButtonClick($event)" 
			[relatedFormControl]="this.control" 
			[pdObject]="pdObject"
			[id]="getId() + '.labeledControlFrame'"
		>
			<kendo-autocomplete 
				[data]="choice" 
				[placeholder]="'todo'" 
				[formControl]="formControl">
			</kendo-autocomplete>
		</otris-pd-labeled-control-frame>
	`,
	styles: []
})
export class PDAutoCompleteComponent extends PDLabeledControlComponent {

	// TEST
	choice: Array<string> = [
		"Albania",
		"Andorra",
		"Armenia",
		"Austria",
		"Azerbaijan",
		"Belarus",
		"Belgium",
		"Bosnia & Herzegovina",
		"Bulgaria",
		"Croatia",
		"Cyprus",
		"Czech Republic",
		"Denmark",
		"Estonia",
		"Finland",
		"France",
		"Georgia",
		"Germany",
		"Greece",
		"Hungary",
		"Iceland",
		"Ireland",
		"Italy",
		"Kosovo",
		"Latvia",
		"Liechtenstein",
		"Lithuania",
		"Luxembourg",
		"Macedonia",
		"Malta",
		"Moldova",
		"Monaco",
		"Montenegro",
		"Netherlands",
		"Norway",
		"Poland",
		"Portugal",
		"Romania",
		"Russia",
		"San Marino",
		"Serbia",
		"Slovakia",
		"Slovenia",
		"Spain",
		"Sweden",
		"Switzerland",
		"Turkey",
		"Ukraine",
		"United Kingdom",
		"Vatican City"
	];

	get isContainerComponent(): boolean {
		return false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, eventProcessingContextManagerService: EventProcessingContextManagerService) {
		super(router, route, formHandler, eventProcessingContextManagerService);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}
}
