import { ICssStyle } from './types';

export enum ToolBarItemTypeET {
	Button,
	DropDownButton
}

export enum EnabledWhenET {
	Always,
	ItemsSelected,
	SingleItemSelected,
	MultipleItemsSelected,
}

export interface IToolBarItemResult {
	id?: string;
	tag?: any;
}

export interface IToolBarItemSpec {
	id?: string;
	type: ToolBarItemTypeET;
	disabled?: boolean;
	enabledWhen?: EnabledWhenET;
	hidden?: boolean;
	tag?: any;
	shortDescription?: string;
	shortDescriptionId?: string;
	text?: string;
	textId?: string;
	/**
	 * Bei true wird das Event an die Eltern-Elemente weiter gegeben.
	 */
	dontStopPropagation?: boolean;
};

export interface IToolBarButtonSpec extends IToolBarItemSpec {
	//text?: string;
	//textId?: string;
	iconClass?: string;
	style?: ICssStyle;
};

export interface IToolBarDropDownButtonSpec extends IToolBarItemSpec {
	//text?: string;
	//textId?: string;
	iconClass?: string;
	items: IToolBarDropDownButtonItem[];
	style?: ICssStyle;
}

export interface IToolBarDropDownButtonItem {
	id?: string;
	text?: string;
	textId?: string;
	tag?: any;
	dontStopPropagation?: boolean;
}

export interface IToolbarSpec {
	items?: IToolBarItemSpec[];
}

export class ToolbarSpec {
	private _items: IToolBarItemSpec[] = [];

	/*constructor(spec: IToolbarSpec) {
		if (spec.items) {
			this._items = spec.items;
		}
	}*/

	constructor(items?: IToolBarItemSpec[]) {
		if (items) {
			this._items = items;
		}
	}

	get items(): IToolBarItemSpec[] {
		return this._items;
	}

	addItems(position: 'start' | 'end', ...items: IToolBarItemSpec[]): void {
		switch (position) {
			case 'start':
				this._items.splice(0, 0, ...items);
				break;
			case 'end':
				this._items.push(...items);
				break;
		}
	}

}

