import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'otris-kendo-dialog-container',
	template: `
		<div kendoDialogContainer></div>
	`,
	styles: [`
		kendo-dialog-titlebar.k-window-titlebar {
			:is(div).k-window-title {
				margin-right: 1em;
			}
		}
		.k-dialog-titlebar {
			background-color: #00b0ff;
		}
	`]
})
export class KendoDialogContainerComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}


