import { Observable } from 'rxjs';
import { AbstractEnumeration } from '../model/abstract-enumeration';
import { IPDObject, IPDObjectId, IPDObjectRaw } from '../model/i-pd-object';
import { IDataProvider } from './i-data-provider.service';


//export const IPDAccessServiceToken = new InjectionToken<IPDAccessService>('IPDAccessService');

export interface IErrorInfoResult {
	retCode: number;
	_errMsgs: string[];
}

export interface ICallOperationResult<T extends IPDObject> {
	result: number;
	outStrs: string | string[];
	outObjs: T | T[];
	errInfo?: IErrorInfoResult;
}

export interface IGetExtentByPageResult {
	itemCount: number;
	data: IPDObject[];
}

/**
 * Service der die Kommunikation zum janus Server ermöglicht. Eine
 * Anmeldung an den Server wird vorher benötigt.
 */
export abstract class IPDAccessService implements IDataProvider {
	/**
	 * Anmeldung an den Janus Server
	 * @param login Benutzername
	 * @param pwd Passwort
	 */
	abstract changeUser(login: string, pwd: string, principal: string): Observable<{msg: string, errorCode: number}>;

	/**
	 * Abmeldung des Benutzer
	 */
	abstract disconnect(): Observable<boolean>;

	abstract getObject(id: string, attrs?: string[]): Observable<IPDObject>;

	/**
	 * Holt die gewünschten Objekte vom Server mit den angegebenen OIDs.
	 * Entspricht der Funktion `PDClass.ptrs`
	 * @param ids OID x:yz
	 * @param attrs Nur angegebene Attribute kommen vom Server zurück
	 */
	abstract getObjects(ids: string[], attrs?: string[]): Observable<IPDObject[]>;

	/**
	 * Holt alle Objekte mit der angegebene Klasse vom Server
	 */
	abstract getExtent(
		pdclass: string | number,
		filter?: string, sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]>;

	/**
	 * Holt ein Teil (page size) aller Objekte mit der angegebene Klasse vom Server.
	 */
	abstract getExtentByPage(
		pdclass: string | number,
		pageIndex: number,
		pageSize: number,
		ignoreErrors?: boolean,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IGetExtentByPageResult>;

	/**
	 * Anfrage an den janus Server, der die angegebene Operation ausführen soll
	 * @param opName Janus Server-Operation
	 * @param inParams Zusätzliche Parameter die an den Server übergeben werden können
	 * @param inObjs Zusätzliche Objekte die an den Server übergeben werden können
	*/
	abstract callOperation(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>>;

	abstract callOperationTyped<T extends IPDObject>(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		pdObjectCreator: (rawObj: IPDObjectRaw) => T,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<T>>;

	/**
	 * Besorgt sich alle PDObjects aus der Beziehung eines PDObject.
	 * @param objWrapper Objekt, dessen Beziehungen abgefragt werden sollen
	 * @param relationName Beziehungsname
	 */
	abstract getRelationObjects(
		objWrapper: IPDObject,
		relationName: string,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]>;

	/**
	* Ruft eine Serveroperation auf ein PDObject aus.
	* @param objWrapper PDObject auf dem die Operation ausgeführt werden soll
	* @param opName Name der Server-Operation
	* @param inParams String(s) als Parameter
	* @param inObjs PDObject(s) als Parameter
	* @param successRetCode Hiermit kann ein bestimmter Rückgabewert als Erfolg definiert werden. Default ist 1.
	* @param [handleErrors=true] Bei false muss man sich selbst um den Fehler kümmern.
	*/
	abstract callObjectOperation(
		objWrapper: IPDObject,
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>>;

	abstract downloadObjectDocument(objWrapper: IPDObject, attr: string): Observable<void>;

	/**
	 * Liefert mithilfe des `PDClass.getGlobalEnumConst` ein Enum mit allen Ausprägungen.
	 * @param enumName Name des Enums.
	 * @param langIndex In welcher Sprache das Enum sein soll. Vom Server werden alle Sprachen ermittelt - die Implementierung filtert es allerdings wieder.
	 * @param includeDeleted [WIRD NICHT GENUTZT AKTUELL] Ausprägungen können zur löschung gekennzeichnet werden in privacy. Im standard werden diese
	 * nicht geliefert.
	 */
	abstract getEnumeration(enumName: string, langIndex: number, includeDeleted?: boolean): Observable<AbstractEnumeration>;

	abstract createPDObjectRaw(oid: string, className: string): IPDObjectRaw;

	abstract getSubClasses(): Observable<[string, string[]][]>;
}
