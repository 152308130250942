import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ILoginResult {
	success: boolean,
	errorMessage?: string,
	errorCode?: number
}

export interface IAuthProvider {
	//isAuthenticated(): boolean;
	isAuthenticated: boolean;

	payload: object;
}

export enum AuthenticationMode {
	Anonymous,
	Login,
	ADAuthentication
};

@Injectable()
export abstract class IAuthService {

	abstract login(user: string, pwd: string, principal: string): Observable<ILoginResult>;

	abstract logout(): Observable<void>;

	abstract registerAuthProvider(provider: IAuthProvider): void;

	abstract get isAuthenticated(): boolean;

	//abstract get authenticatedChanged$(): Observable<boolean>;

	abstract get user(): string | undefined;

	abstract get isLoggedIn(): boolean;

	abstract get loginFailed(): boolean;

	abstract get isAuthenticatedAndLoggedIn() : boolean;

	abstract get authenticationMode(): AuthenticationMode;

	abstract set authenticationMode(mode: AuthenticationMode);

	abstract get payload(): object | undefined;

	// abstract keepSessionAlivePing()
}
