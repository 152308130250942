import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AbstractEnumeration, ICallOperationResult, IGetExtentByPageResult, IPDAccessService, IPDObject, IPDObjectId, IPDObjectRaw } from '@otris/ng-core-types';
import { IPDAccessImplementationToken, PDAccessMethodNotImplementedError, IPDAccessImplementationService } from '@otris/ng-core-shared'
import { catchError, Observable, throwError } from 'rxjs';


@Injectable()
export class PDAccessImplementationsHandlerService extends IPDAccessService {

	constructor(@Inject(IPDAccessImplementationToken) private _pdAccessImplementations: IPDAccessImplementationService[]) {
		super();
		this._pdAccessImplementations.sort((a, b) => a.priority == b.priority ? 0 : (a.priority > b.priority ? -1 : 1))
	}

	changeUser(login: string, pwd: string, principal: string): Observable<{msg: string, errorCode: number}> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].changeUser(login, pwd, principal).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	disconnect(): Observable<boolean> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].disconnect().pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getObject(id: string, attrs?: string[]): Observable<IPDObject> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getObject(id, attrs).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getObjects(ids: string[], attrs?: string[]): Observable<IPDObject[]> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getObjects(ids, attrs).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getExtent(
		pdclass: string | number,
		filter?: string, sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getExtent(pdclass, filter, sort, attrs).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getExtentByPage(
		pdclass: string | number,
		pageIndex: number,
		pageSize: number,
		ignoreErrors?: boolean,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IGetExtentByPageResult> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getExtentByPage(pdclass, pageIndex, pageSize, ignoreErrors, filter, sort, attrs).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	callOperation(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].callOperation(opName, inParams, inObjs, successRetCode, handleErrors).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	callOperationTyped<T extends IPDObject>(
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		pdObjectCreator: (rawObj: IPDObjectRaw) => T,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<T>> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].callOperationTyped(opName, inParams, inObjs, pdObjectCreator, successRetCode, handleErrors).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getRelationObjects(
		objWrapper: IPDObject,
		relationName: string,
		filter?: string,
		sort?: string,
		attrs?: string[]
	): Observable<IPDObject[]> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getRelationObjects(objWrapper, relationName, filter, sort, attrs).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	callObjectOperation(
		objWrapper: IPDObject,
		opName: string, inParams: string | string[] | undefined,
		inObjs: IPDObjectId | IPDObjectId[] | undefined,
		successRetCode?: number,
		handleErrors?: boolean
	): Observable<ICallOperationResult<IPDObject>> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].callObjectOperation(objWrapper, opName, inParams, inObjs, successRetCode, handleErrors).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	downloadObjectDocument(objWrapper: IPDObject, attr: string): Observable<void> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].downloadObjectDocument(objWrapper, attr).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	getEnumeration(enumName: string, langIndex: number, includeDeleted?: boolean): Observable<AbstractEnumeration> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getEnumeration(enumName, langIndex, includeDeleted).pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}

	createPDObjectRaw(oid: string, className: string): IPDObjectRaw {
		let call = (index: number) => {
			try {
				return this._pdAccessImplementations[index].createPDObjectRaw(oid, className);
			}
			catch (err) {
				if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
					return call(index + 1);
				}
				throw err;
			}
		}
		return call(0);
	}

	getSubClasses(): Observable<[string, string[]][]> {
		let call$ = (index: number) => {
			return this._pdAccessImplementations[index].getSubClasses().pipe(
				catchError(err => {
					if (err instanceof PDAccessMethodNotImplementedError && index < this._pdAccessImplementations.length - 1) {
						return call$(index + 1);
					}
					return throwError(() => err);
				})
			);		
		}
		return call$(0);
	}
}