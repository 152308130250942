@switch (objectReferenceType) {
	@case (objectReferenceTypeET.SelectionList) {
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
		>
			@if (isChangeable) {
				<div class="input-text">
					<!--
					<kendo-textbox
						[readonly]="true"
						[value]="selectedValue?.ergName"
					/> 
					-->
					<div class="selection-list-value-container">
						@if (selectedValue) {
							<ng-container *ngTemplateOutlet="selectedValueTemplate; context: { $implicit: selectedValue }">
							</ng-container>
						}
					</div>
					<!--@if (selectedValue) {
						<button
							kendoButton
							type="button"
							class="textarea-clear-button"
							fillMode="clear"
							[svgIcon]="closeSVG"
							(click)="onTextboxChange(undefined)"
						>
						</button>
					}-->
						<!-- Somit jetzt gelöst? #49063 Man müsste die Struktur umbauen, da der Text sonst über das "X" läuft -->
						<!-- <ng-container *ngIf="selectedValue">
							<span (click)="onClickClearSelectionList()" role="button" tabindex="-1" class="selection-list-clear k-icon k-clear-value k-i-close ng-star-inserted" title="clear"></span>
						</ng-container> -->
				</div>
				@if (!isDisabled) {
					<otris-tool-bar
						class	="toolbar"
						(buttonClick)="onToolBarButtonClick($event)"
						[itemSpecs]="toolBarItems"
					/>
				}
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>
	}
	@case (objectReferenceTypeET.TextField) {
		<otris-pd-labeled-control-frame [labeledControl]="this" [pdObject]="pdObject" [relatedFormControl]="this.control">
			@if (isChangeable) {
				<div class="input-text">
					@if (objectReferenceWidgetInfo?.textFieldSpec?.multiline) {
						@if (!isHidden) {
							<kendo-textarea #textField
								[value]="selectedValue?.ergName"
								(valueChange)="onTextboxChange($event)"
								(keyup)="onKeyUp($event)"
								(keydown)="onKeyDown($event)"
								class="kendo-textarea-style"
								resizable="auto"
								flow="horizontal"
							>
								@if (objectReferenceWidgetInfo?.textFieldSpec?.showClearButton) {
									<kendo-textarea-suffix>
										<button
											kendoButton
											type="button"
											class="textarea-clear-button"
											fillMode="clear"
											[svgIcon]="closeSVG"
											(click)="onTextboxChange(undefined)"
										>
										</button>
									</kendo-textarea-suffix>
								}
							</kendo-textarea>
						}
					} @else {
						<kendo-textbox
							#textField
							[value]="selectedValue?.ergName"
							[clearButton]="objectReferenceWidgetInfo?.textFieldSpec?.showClearButton"
							(valueChange)="onTextboxChange($event)"
							(keyup)="onKeyUp($event)"
							(keydown)="onKeyDown($event)"
						/>
					}
				</div>
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>
	}
	@case (objectReferenceTypeET.DropDownList) {
		<otris-pd-labeled-control-frame
			[labeledControl]="this"
			(toolBarButtonClick)="onToolBarButtonClick($event)"
			[pdObject]="pdObject"
			[relatedFormControl]="this.control"
			[disableFirstElementClick]="true"
		>
			@if (isChangeable) {
				<kendo-dropdownlist
					#kendoDropDownList
					class="drop-down-list"
					[data]="filteredChoiceData"
					[textField]="'ergName'"
					[valueField]="'value'"
					[filterable]="objectReferenceWidgetInfo?.filteringEnabled"
					(filterChange)="onFilterChange($event)"
					[(value)]="selectedValue"
					[disabled]="isDisabled"
					[defaultItem]="noSelectionItem$ | async"
					[loading]="isLoading"
					(open)="onOpen($event)"
					[showStickyHeader]="showStickyHeader"
				>
					<ng-template kendoDropDownListItemTemplate let-dataItem>
						@if (dataItem.type === "noSelectionItem") {
							<span class="no-entry-value">{{ dataItem.ergName }}</span>
						} @else {
							<span>{{ dataItem.ergName }}</span>
						}

						@if (dataItem.type=='relationObject') {
							<span class="drop-down-list-item-icon"><i class="fa fa-lock"></i></span>
						}
						@if (dataItem.type=='newObject') {
							<span class="drop-down-list-item-icon"><i class="fa fa-star"></i></span>
						}
					</ng-template>

					<ng-template kendoDropDownListValueTemplate let-dataItem>
						<ng-container *ngTemplateOutlet="selectedValueTemplate; context: { $implicit: dataItem }">
						</ng-container>
					</ng-template>

					<kendo-dropdownlist-messages [noDataText]="noDataTextMessage"/>
				</kendo-dropdownlist>
				@if (!isDisabled && toolBarItems?.length > 0) {
					<otris-tool-bar class="toolbar"
						(buttonClick)="onToolBarButtonClick($event)"
						[itemSpecs]="toolBarItems"
					/>
				}
			} @else {
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			}
		</otris-pd-labeled-control-frame>
	}
}
<ng-template #readonly>
	<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
</ng-template>

<ng-template #selectedValueTemplate let-dataItem>
	@if (dataItem.type === 'choiceObject') {
		<span class="selected-value-template-icon">
			<i class="fa fa-fw fa-bars"></i>
		</span>
	}
	@if (dataItem.type === 'newObject') {
		<span class="selected-value-template-icon">
			<i class="fa fa-fw fa-star"></i>
		</span>
	}
	<span class="selected-value-template-text">{{dataItem.ergName}}</span>
	@if (dataItem.type === 'newObject') {
		<span class="selected-value-template-button"
			(click)="onEditCustomObjectClick($event, dataItem.value)"
			(mouseenter)="onSelectedValueTemplateButtonEnter()"
			(mouseleave)="onSelectedValueTemplateButtonLeave()"
		>
			<i class="fa fa-fw fa-pencil"></i>
		</span>
		@if (objectReferenceType === objectReferenceTypeET.SelectionList) {
			<span class="selected-value-template-button"
				(click)="onTextboxChange(undefined)"
			>
				<i class="fa fa-fw fa-times"></i>
			</span>
		}
	}
</ng-template>
