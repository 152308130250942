import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import { NgxSpinnerModule } from 'ngx-spinner';

import { IModuleStateServiceToken, OtrisNgCoreSharedModule } from '@otris/ng-core-shared';

import { PDFormHeaderTemplateDirective } from './directives/pd-form-header-template.directive';
import { UIContentComponent } from './components/ui-content/ui-content.component';
import { UISpinnerComponent } from './components/ui-spinner/ui-spinner.component';

import { ToNRelationItemContainerDirective } from './directives/to-n-relation-item-container.directive';
import { ErgNameToLocalizedStringPipe } from './pipes/erg-name-to-localized-string.pipe';
import { ToNRelationItemDirective } from './directives/to-n-relation-item.directive';
import { To1RelationItemDirective } from './directives/to-1-relation-item.directive';
import { AfterValueChangedDirective } from './directives/after-value-changed.directive';
import { ModuleStateService } from './services/module-state.service';
import { ComponentGroupHeaderDirective } from './directives/component-group-header.directive';
import { ComponentGroupNoRelationObjectDirective } from './directives/component-group-no-relation-object.directive';
import { Observable, delay, of, tap } from 'rxjs';
import { FormExpressionGrammarProcessorToken } from './services/form-expression-grammar-processor.service';
import { IFormExpressionGrammarProcessor } from '@otris/ng-core-types';
import { IFormSpecBuilder } from './services/i-form-spec-builder.service';
import { FormSpecBuilder } from './services/form-spec-builder.service';

registerLocaleData(localeDe, 'de')


export function initializeNgCoreModule(grammarProcessor: IFormExpressionGrammarProcessor) {
	return (): Observable<void> => {
		return grammarProcessor.loadGrammar();
	}
 }

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		OtrisNgCoreSharedModule,
		NgxSpinnerModule
	],
	declarations: [
		PDFormHeaderTemplateDirective,
		ToNRelationItemContainerDirective,
		ToNRelationItemDirective,
		To1RelationItemDirective,
		AfterValueChangedDirective,
		UIContentComponent,
		UISpinnerComponent,
		ErgNameToLocalizedStringPipe,
		ComponentGroupHeaderDirective,
		ComponentGroupNoRelationObjectDirective,

	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		OtrisNgCoreSharedModule,
		PDFormHeaderTemplateDirective,
		ToNRelationItemContainerDirective,
		ToNRelationItemDirective,
		To1RelationItemDirective,
		AfterValueChangedDirective,
		UIContentComponent,
		UISpinnerComponent,
		ErgNameToLocalizedStringPipe,
		ComponentGroupHeaderDirective,
		ComponentGroupNoRelationObjectDirective,

	],
	providers: [
		{ provide: IModuleStateServiceToken, useClass: ModuleStateService },
		{ provide: APP_INITIALIZER, useFactory: initializeNgCoreModule, deps: [FormExpressionGrammarProcessorToken], multi: true},
		{ provide: IFormSpecBuilder, useClass: FormSpecBuilder }
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class OtrisNgCoreModule {
}
