import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { TypeET,
	AbstractEnumeration,
	IEnumerationItem,
	IPDClass,
	IPDObjectRaw,
	IPDObject,
	IPDObjectId,
	ICustomPDObject,
	UnusedRawProperties,
	AuxiliaryProperties,
	ICustomPDObjectFragment,
	CustomPDObjectFragmentProperties
} from '@otris/ng-core-types';
import { IPDAccessServiceToken, IPDClassToken } from './types';
import { getJsonProperty } from '../decorators/json-property.decorator';
import { DefaultPDObjectMeta } from './default-pd-object-meta';
import { IPDObjectMeta } from './pd-object-meta';
import { ServiceLocator } from '../services/service-locator';
import { IMetaObjectService } from '../services/meta-object.service';

/*export interface IPDObjectId {
	low: number;
	high: number;
	oid: string;
}

export interface IPDObjectRaw {
	oid: string;
	[propName: string]: any;
	callOperation(...args: any[]): any;
	getExtent(...args: any[]): any;
}*/

// Neu


export class EmptyPDObjectRaw implements IPDObjectRaw {
	get oid(): string {
		return undefined;
	}

	get classname(): string {
		return undefined;
	}

	callOperation() { }
	getExtent() { }
}

// type UnusedRawProperties = {[propName: string]: any;};

export abstract class PDObject implements IPDObject {

	get objectId(): IPDObjectId {
		return this._objectId
	}

	private _objectId: IPDObjectId;

	get oid(): string | undefined {
		return this.objectId ? this.objectId.oid : undefined;
	}

	// Todo: nach CustomPDObject?
	private _unusedRawProperties: UnusedRawProperties = {};

	get unusedRawProperties(): UnusedRawProperties {
		return this._unusedRawProperties;
	}

	set unusedRawProperties(unusedRawProperties: UnusedRawProperties) {
		this._unusedRawProperties = unusedRawProperties;
	}

	private _auxiliaryProperties: AuxiliaryProperties = {};

	get auxiliaryProperties(): AuxiliaryProperties {
		return this._auxiliaryProperties;
	}

	set auxiliaryProperties(auxiliaryProperties: AuxiliaryProperties) {
		this._auxiliaryProperties = auxiliaryProperties;
	}

	abstract get pdObjectRaw(): IPDObjectRaw;

	get metaData(): IPDObjectMeta {
		return this._metaData;
	}

	private _metaData: IPDObjectMeta;

	abstract getClassName(): string;

	static createPDObjectIdFromString(oid: string): IPDObjectId {
		oid = oid.trim();
		let parts = oid.split(':');
		if (parts.length !== 2) {
			throw new Error('Invalid oid');
		}
		let low = Number.parseInt(parts[1].trim());
		let high = Number.parseInt(parts[0].trim());
		return {
			low: low,
			high: high,
			oid: `${high}:${low}`
		};
	}

	static createPDObjectIdFromOid(low: number, high: number): IPDObjectId {
		return {
			low: low,
			high: high,
			oid: `${high}:${low}`
		};
	}

	constructor(_objectId: string | { low: number, high: number } | undefined, metaData?: IPDObjectMeta) {
		this._metaData = metaData ? metaData : this.getMetaDataObject();

		if (_objectId) {
			let oidLow, oidHigh;
			if (typeof _objectId === 'string') {
				let pos = _objectId.indexOf(':');
				if (pos > 0) {
					oidHigh = _objectId.substr(0, pos);
					oidLow = _objectId.substr(pos + 1);
				}
			}
			else {
				oidLow = _objectId.low;
				oidHigh = _objectId.high;
			}
			this._objectId = <IPDObjectId>{ low: oidLow, high: oidHigh, oid: oidHigh.toString() + ':' + oidLow.toString() };
		}
	}

	toJson(): string {
		return JSON.stringify(this.pdObjectRaw, (k, v) => this.jsonReplacer(k, v));
	}

	updateWithRawObject(rawObj: any): void {
		this.updateWithRawObjectImpl(rawObj);
	}

	private updateWithRawObjectImpl(rawObj: any, relationPath?: string[]): void {
		this.adaptRawObject(rawObj, relationPath);
		Object.assign(this.pdObjectRaw, rawObj);
	}

	/**
	 * Liefert ein Attribute welches aus dem pdObjectRaw stammt.
	 */
	protected getPropertyValue(propName: string): any {
		return this.pdObjectRaw[propName];
	}

	getStatus(template: string): string {
		if (!template) {
			return '<n.a.>';
		}
		let datePipe = <DatePipe>ServiceLocator.injector.get(DatePipe);
		//let abstractEnumPipe = <AbstractEnumerationPipe>ServiceLocator.injector.get(AbstractEnumerationPipe);
		//let structurePipe = <StructurePipe>ServiceLocator.injector.get(StructurePipe);
		let regExp = /%a((\w*\.)*\w*)%/;
		let result: RegExpExecArray;
		while ((result = regExp.exec(template)) !== null) {
			// let obj = this.pdObjectRaw;
			let propName: string = result[1];
			/*if (propName.includes(".")) {
				// Child-Objekte berücksichtigen
				let objProps = propName.split(".");
				for (let i = 0; i < objProps.length; i++) {
					if (i == objProps.length - 1) {
						propName = objProps[i];
					}
					else {
						obj = obj[objProps[i]];
						if (!obj) {
							template = template.replace(result[0], '');
							continue;
						}
					}
				}
			}*/

			let type = this.metaData.getPropertyType(propName);
			let propValue;
			switch (type) {
				case TypeET.Date:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'dd. MMM yyyy');
					break;
				case TypeET.Time:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'HH:mm:ss');
					break;
				case TypeET.DateTime:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'dd. MMM yyyy HH:mm:ss');
					break;
				/*case TypeET.Enum:
					propValue = abstractEnumPipe.transform(this[propName]);
					break;
				case TypeET.Structure:
					propValue = structurePipe.transform(this[propName]);
					break;*/
				default:
					propValue = this.getPropertyValue(propName);
			}
			template = template.replace(result[0], propValue);
		}
		return template;
	}

	protected getMetaDataObject(): IPDObjectMeta {
		return DefaultPDObjectMeta.instance;
	}

	protected adaptRawObject(rawObj: any, relationPath?: string[]): void {
		let pdClass = <IPDClass>ServiceLocator.injector.get(IPDClassToken);
		for (let prop in rawObj) {
			if (rawObj.hasOwnProperty(prop)) {
				switch (this.metaData.getPropertyType(prop)) {
					case TypeET.RelationTo1:
						if (rawObj[prop]?.hasOwnProperty('_className')) {
							let relObjRaw = rawObj[prop];
							let newRelPath = relationPath ? [...relationPath, prop] : [prop];
							let newObj = pdClass.createInstance(relObjRaw['_className'], newRelPath, relObjRaw['_objectId'], relObjRaw['_isNew'], relObjRaw['_metaData']);
							newObj.updateWithRawObject(relObjRaw);
							rawObj[prop] = newObj;
						}
						break;

					case TypeET.RelationToN: {
						if (Array.isArray(rawObj[prop])) {
							let relObjs: IPDObject[] = [];
							for (let relObj of rawObj[prop]) {
								if (relObj instanceof PDObject) {
									relObjs.push(relObj);
								}
								else if (relObj.hasOwnProperty('_className')) {
									let newRelPath = relationPath ? [...relationPath, prop] : [prop];
									let newObj = pdClass.createInstance(relObj['_className'], newRelPath, relObj['_objectId'], relObj['_isNew'], relObj['_metaData']);
									newObj.updateWithRawObject(relObj);
									relObjs.push(newObj);
								}
							}
							rawObj[prop] = relObjs;
						}
						break;
					}
				}
			}
		}
	}

	addUnusedRawProperty(key: string, value: any): void {
		this._unusedRawProperties[key] = value;
	}

	addAuxiliaryProperty(key: string, value: any): void {
		// todo: Exception, falls key schon exisitiert
		this._auxiliaryProperties[key] = value;
	}

	getAuxiliaryProperty(key: string): any {
		return this._auxiliaryProperties[key];
	}

	// To JSON
	jsonReplacer(key: string, value: any): any {
		if (value instanceof PDObject) {
			let obj = {};
			for (let prop in value) {
				// Todo: _defaultValues kommt aus CustomPDObject!!! weitere Props von CustomPDObject ausnehmen?
				/*if (prop === '_metaData' || prop === '_rawObject' || prop === '_pdObjectRaw' || prop === '_resetCallbackSubject$' ||
					prop === '_resetCallback$' || prop === '_defaultValues' || !value.hasOwnProperty(prop)) {
					continue;
				}*/
				let propsToIgnore = this.getJsonPropsToIgnore();
				if (propsToIgnore.includes(prop) || !value.hasOwnProperty(prop)) {
					continue;
				}
				if ((prop === '_unusedRawProperties' || prop === '_auxiliaryProperties') && value[prop]) {
					for (const [key, objValue] of Object.entries(value[prop])) {
						if (objValue instanceof Map) {
							obj[key] = { dataType: 'Map', value: [...objValue]}
						} else {
							obj[key] = objValue;
						}
					}
					continue;
				}
				let valueAdapted = value[prop]; // this durch value ersetzt
				if (prop === '_objectId') {
					valueAdapted = valueAdapted ? (<IPDObjectId>valueAdapted).oid : undefined;
				}
				else {
					let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
					let type = value.metaData.getPropertyType(propAdapted); // this durch value ersetzt
					switch (type) {
						case TypeET.RelationTo1:
							{
								if (!(valueAdapted instanceof CustomPDObject)) {
									let relObj = <PDObject>valueAdapted;
									valueAdapted = (relObj && relObj.objectId) ? relObj.objectId.oid : null;
								}
								break;
							}
						case TypeET.RelationToN:
							{
								let relObjs = <PDObject[]>valueAdapted;
								if (relObjs) {
									valueAdapted = relObjs.map(o => {
										if (o instanceof CustomPDObject) {
											return o;
										}
										return o.objectId ? o.objectId.oid : null
									});
									break;
								}
								valueAdapted = [];
								break;
								// valueAdapted = relObjs ? relObjs.map(o => o.objectId ? o.objectId.oid : null) : [];
								// break;
							}
						case TypeET.Enum:
							{
								let e = <AbstractEnumeration>valueAdapted;
								//if (!Array.isArray(e.value)) {
									//let enumItem = <IEnumerationItem>e.value;
									valueAdapted = e.valueRaw;
									//valueAdapted = enumItem ? enumItem.enumConst : (e.rawValueType === 'number' ? -1 : '');
								//}
								break;
							}
						/*case TypeET.EnumItemArray:
							{
								if (Array.isArray(valueAdapted)) {
									valueAdapted = valueAdapted.map(i => (<IEnumerationItem>i).ergName)
								}
								break;
							}*/
						case TypeET.Date:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									valueAdapted = d.getFullYear().toString() + "-" + month + "-" + day; // + "T00:00:00.000Z";
								}
								else {
									valueAdapted = null;
								}
								break;
							}
						case TypeET.Time:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let hours = d.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let minutes = d.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let seconds = d.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let millis = d.getMilliseconds().toLocaleString(undefined, { minimumIntegerDigits: 3 });
									valueAdapted = + hours + ':' + minutes + ':' + seconds + '.' + millis;
								}
								else {
									valueAdapted = null;
								}
								break;
							}
						case TypeET.DateTime:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let hours = d.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let minutes = d.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let seconds = d.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let millis = d.getMilliseconds().toLocaleString(undefined, { minimumIntegerDigits: 3 });
									valueAdapted = d.getFullYear().toString() + "-" + month + "-" + day + 'T' + hours + ':' + minutes + ':'
										':' + seconds + '.' + millis;
								}
								else {
									valueAdapted = null;
								}
								break;
							}
					}
				}

				let jsonProp = getJsonProperty(value, prop); // this durch value ersetzt
				if (typeof(jsonProp) === 'string') {
					obj[jsonProp] = valueAdapted !== undefined ? valueAdapted : null;
				}
				else {
					obj[prop] = valueAdapted !== undefined ? valueAdapted : null;
				}
			}
			return obj;
		}
		return value;
	}

	getPropertyValueFromPath(path: string): any {
		let pos = path.indexOf('.');
		if (pos >= 0) {
			let relProp = path.substring(0, pos);
			let relObj = this[relProp];
			if (Array.isArray(relObj)) {
				let posIndex = path.indexOf('.', pos + 1);
				let index = Number.parseInt(posIndex < 0 ? path.substring(pos + 1) : path.substring(pos + 1, posIndex));
				if (index < 0 && index >= relObj.length) {
					return undefined;
				}
				relObj = relObj[index];
				if (posIndex < 0) {
					return relObj;
				}
				pos = posIndex;
			}
			if (relObj instanceof PDObject) {
				return relObj.getPropertyValueFromPath(path.substring(pos + 1));
			}
			return relObj ? relObj[path.substring(pos + 1)] : undefined;
		}
		return this[path];
	}

	/**
	 * Ermittelt den Typ des Attributes, mittels Attribut-Pfad
	 * @param path Ursprung ist das Objekt selbst - This.
	 */
	getPropertyTypeFromPath(path: string): TypeET | undefined {
		let pos = path.lastIndexOf('.');
		if (pos < 0) {
			return this.metaData.getPropertyType(path);
		}
		let obj = this.getPropertyValueFromPath(path.substring(0, pos));
		if (obj instanceof PDObject) {
			return obj.metaData.getPropertyType(path.substring(pos + 1))
		}
		return undefined;
	}

	equals(other: IPDObject): boolean {
		if (!other) {
			return false;
		}

		if (!this.objectId && !other.objectId) {
			return true;
		}
		if (this.objectId && other.objectId) {
			return this.objectId.oid === other.objectId.oid;
		}

		return false; // Dürfte nie erreicht werden
	}

	protected getJsonPropsToIgnore(): string[] {
		return [
			'_metaData'
		];
	}

	/**
	 * Prüft zwei PDObject-Arrays auf Gleichheit anhand der OID
	 * ODER anhand deren Inhalt, wenn es sich um CustomPDObjects handelt!
	 */
	// FIXME: Interface benutzen?
	static compareArrays(array1: PDObject[], array2: PDObject[]): boolean {
		if (!array1 && !array2) {
			return true;
		}
		if (array1 && array2 && array1.length === array2.length) {

			// Sort Array
			array1 = PDObject.sortArray(array1);
			array2 = PDObject.sortArray(array2);

			for (let i = 0; i < array1.length; i++) {
				const res = array1[i].equals(array2[i]);
				if (!res) {
					return false;
				}
			}
			return true;
		}

		return false;
	}

	/**
 * Sortiert nach dem oid-string
 */
	static sortArray(array: PDObject[]): PDObject[] {
		return array.sort((a, b) => {
			if (a.objectId.oid < b.objectId.oid) return -1;
			if (a.objectId.oid > b.objectId.oid) return 1;
			return 0;
		});
	}

	/**
	 * Vergleicht zwei UnusedRawProperties - Im Grunde ist es eine deepEquals-Funktion für Objekte
	 */
	static compareUnusedRawProperties(props1: UnusedRawProperties, props2: UnusedRawProperties) {
		if (props1 === props2) {
			return true;
		}

		const keys1 = Object.keys(props1);
		const keys2 = Object.keys(props2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
		 if (!PDObject.compareUnusedRawProperties(props1[key], props2[key])) {
			return false;
		 }
		}

		return true;
	}
}

export class CustomPDObjectFragment implements ICustomPDObjectFragment {

	constructor(private _metaData: IPDObjectMeta) {
	}

	get metaData(): IPDObjectMeta {
		return this._metaData;
	}

	get className(): string {
		return this._metaData.className;
	}

	setProperty(name: string, value: any): void {
		let propName = name.startsWith('_') ? name : '_' + name;
		let propNameWO = name.substring(1);
		switch (this.metaData.getPropertyType(propNameWO)) {
			case TypeET.String:
				this._properties[propName] = typeof(value) === 'string' ? value : undefined;
				break;

			case TypeET.Integer:
			case TypeET.Double:
				this._properties[propName] = typeof(value) === 'number' ? value : undefined;
				break;

			case TypeET.Boolean:
				if (typeof(value) === 'boolean') {
					this._properties[propName] = value;
				}
				break;

			case TypeET.Enum:
				this._properties[propName] = typeof(value) === 'string' || typeof(value) === 'number'?
					value : undefined;
				break;

			case TypeET.RelationTo1:
				this._properties[propName] = value instanceof PDObject ? value : undefined;
				break;

			//case ...
		}
	}

	get properties(): CustomPDObjectFragmentProperties {
		return this._properties;
	}

	private _properties: CustomPDObjectFragmentProperties = {};
}


export abstract class CustomPDObject extends PDObject implements ICustomPDObject {

	private _resetCallbackSubject$: Subject<CustomPDObject> = new Subject<CustomPDObject>();

	private _resetCallback$: Observable<CustomPDObject>;

	get resetCallback$(): Observable<CustomPDObject> {
		if (!this._resetCallback$) {
			this._resetCallback$ = this._resetCallbackSubject$.asObservable();
		}
		return this._resetCallback$;
	}

	private static _objectCounter: number = 0;

	static get objectCounter(): number {
		return CustomPDObject._objectCounter;
	}

	get objectIndex(): number {
		return this._objectIndex
	}

	private _objectIndex: number;

	get pdObjectRaw(): IPDObjectRaw {
		return this;
	}

	private _className: string;

	// wegem get pdObjectRaw() notwendig
	get classname(): string {
		return this._className;
	}

	protected _metaObjectId: string;

	private _rawObject: IPDObjectRaw;

	get isNew(): boolean {
		return this._isNew;
	}

	private _remoteCreationParams: object;

	get remoteCreationParams(): object {
		return this._remoteCreationParams
	}

	set remoteCreationParams(params: object) {
		this._remoteCreationParams = params;
	}

	//private _defaultValues: Map<string, any> = new Map();

	private _defaultValues: CustomPDObjectFragment;

	set defaultValues(vals: CustomPDObjectFragment) {
		if (vals.className !== this.classname) {
			throw new Error(`Invalid default values. Expected class: '${this.classname}', provided class: '${vals.className}'`);
		}
		if (vals.metaData != this.metaData) {
			throw new Error(`Invalid default values. Metadata doesn't fit`);
		}
		this._defaultValues = vals;
		this.applyDefaultValues();
	}

	get defaultValues(): CustomPDObjectFragment {
		return this._defaultValues;
	}

	constructor(_objectId: string | { low: number, high: number } | undefined, private _isNew: boolean = true, metaData?: IPDObjectMeta) {
		super(_objectId, metaData);
		this._className = this.getClassName();
		this._objectIndex = ++CustomPDObject._objectCounter;
		if (this.objectId?.low > 0) {
			this._isNew = false;
			this._rawObject = ServiceLocator.injector.get(IPDAccessServiceToken).createPDObjectRaw(this.objectId.oid, this._className)
		}
		this.reset();
	}

	protected getMetaDataObject(): IPDObjectMeta {
		return ServiceLocator.injector.get(IMetaObjectService).getMetaObject(this.getClassName()/*, this.getMetaObjectId()*/);
	}

	reset(): void {
		this.metaData.propertyNames.forEach(prop => this[prop] = undefined);
		this.applyDefaultValues();
		this._resetCallbackSubject$.next(this);
	}

	clone(): CustomPDObject {
		return this.cloneImpl();
	}

	protected getJsonPropsToIgnore(): string[] {
		let res = super.getJsonPropsToIgnore();
		return [
			...res,
			'_rawObject',
			'_resetCallbackSubject$',
			'_resetCallback$',
			'_defaultValues'
		];
	}

	private applyDefaultValues(): void {
		if (this._defaultValues) {
			for (const [key, value] of Object.entries(this._defaultValues.properties)) {
				let propNameWO = key.startsWith('_') ? key.substring(1) : key;
				switch (this.metaData.getPropertyType(propNameWO)) {
					case TypeET.String:
					case TypeET.Double:
					case TypeET.Integer:
					case TypeET.Boolean:
						this.pdObjectRaw[key] = value;
						break;
					case TypeET.Enum:
						if (typeof(value) === 'string' || typeof(value) === 'number') {
							let enumProp = `${propNameWO}Object`;
							let enumObj: AbstractEnumeration = this.pdObjectRaw[enumProp];
							enumObj.value = enumObj.getItem(value);
						}
						break;
				}
			}
		}
	}

	private cloneImpl(relationPath?: string[]): CustomPDObject {
		let pdClass = ServiceLocator.injector.get(IPDClassToken);
		let newObj: any;
		try {
			// Todo: clonen von checklisten macht aktuell Probleme!
			newObj = pdClass.createInstance(this._className, relationPath, this.oid, this.isNew, this.metaData);
		}
		catch (err) {
			return undefined;
		}

		for (let prop in this) {
			if (!this.hasOwnProperty(prop)) {
				continue;
			}

			if (prop === '_objectIndex') {
				continue;
			}

			let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
			let value: any = this[prop];
			let type = this.metaData.getPropertyType(propAdapted);
			switch (type) {
				case TypeET.RelationTo1:
					if (value instanceof CustomPDObject) {
						let newRelPath = relationPath ? [...relationPath, prop] : [prop];
						value = (value as CustomPDObject).cloneImpl(newRelPath);
					}
					break;

				case TypeET.RelationToN:
					if (Array.isArray(value)) {
						let newRelPath = relationPath ? [...relationPath, prop] : [prop];
						let relObjs = <PDObject[]>value;
						value = relObjs.map(o => o instanceof CustomPDObject ? o.cloneImpl(newRelPath) : o);
					}
					break;

				case TypeET.Enum:
				{
					value = value.clone();
					break;
				}
			}
			newObj[prop] = value;
		}
		return newObj;
	}

	assign(origin: CustomPDObject) {
		let pdClass = ServiceLocator.injector.get(IPDClassToken);
		for (let prop in origin) {
			if (!origin.hasOwnProperty(prop)) {
				continue;
			}


			let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
			let value: any = origin[prop];
			let type = origin.metaData.getPropertyType(propAdapted);
			switch (type) {
				case TypeET.RelationTo1:
					if (value instanceof CustomPDObject) {
						value = (value as CustomPDObject).clone()
					}
					break;

				case TypeET.RelationToN:
					if (Array.isArray(value)) {
						let relObjs = <PDObject[]>value;
						value = relObjs.map(o => o instanceof CustomPDObject ? o.clone() : o);
					}
					break;

				case TypeET.Enum: {
					value = value.clone();
				}
			}
			this[prop] = value;
		}
	}

	callOperation(...args: any[]): any {
		if (this._rawObject) {
			return this._rawObject.callOperation(...args);
		}
	}

	getExtent() { }

	override equals(other: IPDObject): boolean {
		if (!other) { // this ist eh defined, sonst wären wir nicht hier
			return false;
		}
		let props1 = this.metaData.propertyNames.sort();
		let props2 = other.metaData.propertyNames.sort();

		if (props1.length === props2.length) { 	// Länge gleich?
			for(let i = 0; i < props1.length; i++) {
				if (props1[i] !== props2[i]) { // Attribut-Namen gleich?
					return false;
				}
				let propVal1 = this.pdObjectRaw[props1[i]];
				let propVal2 = other.pdObjectRaw[props1[i]];
				// Nicht alle Values müssen gesetzt sein bei einem Objekt - Wegen "propVal1.equals" Prüfung hier dazu gekommen
				if (propVal1 !== undefined && propVal2 !== undefined) {
					switch (this.metaData.getPropertyType(props1[i])) {
						case TypeET.String: // undefined/null ist ungleich Primitiv z.B. "", 0, false
						case TypeET.Boolean:
						case TypeET.Integer:
						case TypeET.Double:
						case TypeET.Date: // wie string
						case TypeET.Time: // wie string
						case TypeET.DateTime: // wie string
						case TypeET.EnumItemArray: // wie String
							if (propVal1 !== propVal2)
								return false;
							break;

						case TypeET.StringArray:
							// return true, wenn alle Werte true liefern in der Funktion
							if (propVal1.length !== propVal2.length &&
									!propVal1.every((val, index) => val === propVal2[index]))
								return false;
							break;

						case TypeET.RelationTo1:
							if (!propVal1.equals(propVal2))
								return false;
							break;

						case TypeET.RelationToN:
							if (!PDObject.compareArrays(propVal1, propVal2))
								return false;
							break;

						default:
							throw new Error(`Unsupported Type ${this.metaData.getPropertyType(props1[i])} for equality check.`);
					}
				} else if (propVal1 !== propVal2) {
					// Eins von beiden hat einen Wert, der andere ist undefined
					return false;
				}
			}
			return true; // Alles gleich
		}
		return false; //Länge != 0
	}

	/**
	 * Integriert die remoteCreationParams in das eigentlich Objekt "this". Dabei werden vorhandene Werte von dem remote
	 * object überschrieben. remoteCreationParams bleibt davon unangetastet, auch wenn die Werte am Ende doppelt erscheinen
	 * in der JSON.
	 * Die Funktion geht rekursiv in weitere customPDObjects rein
	 */
	integrateRemoteCreationParams(): void {
		// Objekt selbst
		for (const param in this.remoteCreationParams) {
			this[param] = this.remoteCreationParams[param];
		}

		// Weitere CustomPDObject Member
		for (const prop in this) {
			if (!this.hasOwnProperty(prop)) {
				continue;
			}

			let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
			let value: any = this[prop];
			let type = this.metaData.getPropertyType(propAdapted);
			switch (type) {
				case TypeET.RelationTo1:
					if (value instanceof CustomPDObject) {
						value.integrateRemoteCreationParams();
					}
					break;

				case TypeET.RelationToN:
					if (Array.isArray(value)) {
						value.forEach(obj => {
							if (obj instanceof CustomPDObject) {
								obj.integrateRemoteCreationParams();
							}
						})
					}
					break;
			}
		}
	}

	isEmpty(): boolean | undefined {
		return undefined
	}

	createEnumInstance(enumProp: string, value: string | number): AbstractEnumeration | undefined {
		return undefined;
	}
}

export abstract class PDObjectRawWrapper extends PDObject {
	constructor(private _pdObjectRaw: IPDObjectRaw) {
		super(_pdObjectRaw.oid);
	}

	get pdObjectRaw(): IPDObjectRaw {
		return this._pdObjectRaw;
	}

	protected getPropertyValue(propName: string): any {
		if (this.hasOwnProperty(propName)) {
			return this[propName];
		}
		return super.getPropertyValue(propName);
	}

	protected getJsonPropsToIgnore(): string[] {
		let res = super.getJsonPropsToIgnore();
		return [
			...res,
			'_pdObjectRaw'
		];
	}
}

