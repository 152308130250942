import {
	Directive,
	Input,
	HostListener,
	OnDestroy,
	Output,
	EventEmitter,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Directive({
	selector: "[otrisAfterValueChanged]",
})
export class AfterValueChangedDirective implements OnDestroy {
	@Output()
	public otrisAfterValueChanged: EventEmitter<any> = new EventEmitter<any>();

	@Input()
	public valueChangeDelay = 1000;

	private stream: Subject<any> = new Subject<any>();
	private subscription: Subscription;

	constructor() {
		this.subscription = this.stream
			.pipe(
				debounceTime(this.valueChangeDelay),
				distinctUntilChanged(),
			)
			.subscribe((value: any) => this.otrisAfterValueChanged.next(value));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	@HostListener("valueChange", ["$event"])
	public onValueChange(value: any): void {
		this.stream.next(value);
	}
}
